import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { useState } from 'react'
import classNames from 'classnames'
import { Button, SpinnerLoader, Switch, Typography } from 'shared/ui'
import { useCampaignViewContext } from 'pages/campaigns/ui/CampaignView/context/context'
import { CampaignRoutes } from 'pages/campaigns/types'
import styles from './styles.module.scss'

export const Actions = observer(() => {
  const { onSave, isPublish, onPublish, isEdit, disabledSave } = useCampaignViewContext()

  const navigate = useNavigate()
  const onCancel = () => {
    navigate(`/${CampaignRoutes.root}/${CampaignRoutes.all}`)
  }

  const [loadingSave, setLoadingSave] = useState(false)
  const handleSave = async () => {
    setLoadingSave(true)
    await onSave()
    setLoadingSave(false)
  }

  const [loadingPublish, setLoadingPublish] = useState(false)
  const handlePublish = async () => {
    setLoadingPublish(true)
    await onPublish()
    setLoadingPublish(false)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.left}>
        <Button
          size={'medium'}
          contained={'primary'}
          text={isEdit ? 'Save changes' : 'Save'}
          onClick={handleSave}
          loading={loadingSave}
          disabled={disabledSave}
        />
        <Button size={'medium'} contained={'secondary'} text={'Cancel'} onClick={onCancel} />
      </div>
      <div className={styles.right}>
        <Typography ariaLabel={'draft'} variant={'body-md-regular'}>
          Draft
        </Typography>
        <div className={classNames(styles.wrapSwitch, { [styles.loading]: loadingPublish })}>
          {loadingPublish && (
            <div className={styles.wrapLoader}>
              <SpinnerLoader size={16} />
            </div>
          )}
          <div className={styles.switch}>
            <Switch value={isPublish} onChange={handlePublish} />
          </div>
        </div>
        <Typography ariaLabel={'publish'} variant={'body-md-regular'}>
          Publish
        </Typography>
      </div>
    </div>
  )
})
