import { observer } from 'mobx-react-lite'
import { StepsCommonLayout } from 'pages/settings/pages/compliance/ui/StepsCommonLayout/StepsCommonLayout'
import {
  CallingStepsProvider,
  useCallingStepsContext,
} from 'pages/settings/pages/compliance/pages/calling/context/callingStepsContext'
import { useComplianceLayerContext } from 'pages/settings/pages/compliance/context'
import { useProgressInfoTitle } from 'pages/settings/pages/compliance/pages/calling/hooks/useProgressInfoTitle'
import { useLayoutTitle } from 'pages/settings/pages/compliance/pages/calling/hooks/useLayoutTitle'
import { useTotalSteps } from 'pages/settings/pages/compliance/pages/calling/hooks/useTotalSteps'
import { useLayoutStep } from 'pages/settings/pages/compliance/pages/calling/hooks/useLayoutStep'

export const CallingLayoutContent = observer(() => {
  const { closeSetting, decreaseStep } = useCallingStepsContext()

  return (
    <StepsCommonLayout
      progressTitle={useProgressInfoTitle()}
      totalSteps={useTotalSteps()}
      title={useLayoutTitle()}
      settingsStep={useLayoutStep()}
      closeSetting={closeSetting}
      decreaseStep={decreaseStep}
    />
  )
})

export const CallingStepsLayout = () => {
  const complianceLayerStore = useComplianceLayerContext()

  return (
    <CallingStepsProvider complianceLayerStore={complianceLayerStore}>
      <CallingLayoutContent />
    </CallingStepsProvider>
  )
}
