import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import type { IResponsePowerDialer } from 'entities/PowerDialer/api/types'

type IHandlerEvent = (data: IResponsePowerDialer) => void

export const EventPowerDialerUpdated = {
  subscribe: (handler: IHandlerEvent) => websocket.on(WebsocketEvent.PdCampaignsUpdated, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.PdCampaignsUpdated, handler),
}

export const useEventPowerDialerUpdated = (handler: IHandlerEvent) =>
  useEffect(() => {
    EventPowerDialerUpdated.subscribe(handler)

    return () => {
      EventPowerDialerUpdated.unsubscribe(handler)
    }
  }, [])
