import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { toastStore } from 'shared/ui'
import { ModalType } from 'shared/ui/Modal/store/types'
import { CHAR_MAX_LENGTH } from 'shared/constants/limits'
import { logger } from 'shared/lib'
import { RenameModalContent } from 'widgets/RenameModal/ui/RenameModalContent'

export class RenameModalStore {
  constructor({
    title,
    name = '',
    withPostfix = false,
    onSave,
    element = '',
    isRenamedToast = false,
    isEditExtensionDisabled = false,
    maxNameLength = CHAR_MAX_LENGTH,
    ...modalProps
  }: {
    title?: string
    name?: string
    withPostfix?: boolean
    onSave: (name: string) => Promise<void> | void
    element?: string
    isRenamedToast?: boolean
    isEditExtensionDisabled?: boolean
    maxNameLength?: number
  } & Partial<Pick<ModalType, 'onClose' | 'secondaryAction' | 'id'>>) {
    this.initName = name
    this.name = name
    this.element = element
    this.onSave = onSave
    this.id = modalProps.id || nanoid()
    this.isEditExtensionDisabled = isEditExtensionDisabled
    this.isRenamedToast = isRenamedToast
    this.secondaryAction = modalProps.secondaryAction
    this.maxNameLength = maxNameLength

    if (withPostfix) {
      this.setPostfix(name)
    }

    modalStore.addModal({
      title: title || 'Rename',
      width: 480,
      pureContent: true,
      ModalContent: RenameModalContent,
      ModalContentProps: { store: this, withPostfix },
      ...modalProps,
      id: this.id,
    })
    makeAutoObservable(this)
  }
  id = ''
  initName = ''
  name = ''
  postfix: string | null = null
  element = ''
  isEditExtensionDisabled = false
  onSave: ((name: string) => Promise<void> | void) | null = null
  loadingSave = false
  isRenamedToast = false
  secondaryAction: ModalType['secondaryAction'] | null = null
  maxNameLength = CHAR_MAX_LENGTH

  get isNameLengthError() {
    return this.name.length > this.maxNameLength
  }

  get disabledSave() {
    if (this.postfix === this.name.trim()) {
      return true
    }

    const noEditedName = this.initName === this.name

    return !this.name.trim() || noEditedName || this.isNameLengthError
  }
  setPostfix = (name: string) => {
    const parts = name.split('.')

    if (parts.length > 1) {
      const postfix = parts.pop()
      this.postfix = postfix ? `.${postfix}` : null
    }
  }

  onCloseModal = () => {
    modalStore.closeModal(this.id)
  }

  setName = (value: string) => {
    const updatedValue = this.postfix && !value.length ? this.postfix : value

    this.name = updatedValue
  }

  handleSave = async () => {
    try {
      this.loadingSave = true
      if (this.onSave) {
        await this.onSave(this.name)
        if (this.isRenamedToast) {
          toastStore.add({
            type: 'success',
            title: `${this.element} renamed`,
          })
        }

        this.onCloseModal()
      }
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loadingSave = false
      })
    }
  }
}
