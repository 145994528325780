export enum ITriggerCreateFieldEnum {
  name = 'name',
  smsMessage = 'smsMessage',
  ringlessMessage = 'ringlessMessage',
  integration = 'integration',
  sendFrom = 'sendFrom',
  conversion_id = 'conversion_id',
  phoneField = 'phoneField',
}

export type ITriggerCreateField = `${ITriggerCreateFieldEnum}`

export enum EnumTypeTrigger {
  Sms = 'sms',
  Ringless = 'ringless',
}

export enum EnumModeTrigger {
  Create = 'create',
  Edit = 'edit',
}
