import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { alertIconsByType, IAlertProps } from 'shared/ui/Alert/types'
import { getAriaLabel, highlightUrls } from 'shared/lib'
import { IconButton } from 'shared/ui/IconButton'
import { Icon } from 'shared/ui/Icon'
import styles from './styles.module.scss'

export const Alert: FC<IAlertProps> = observer(
  ({
    item,
    className,
    onClose,
    actions,
    action,
    marginTop,
    marginBottom,
    rightAction,
    renderBottom,
    marginInline,
  }) => {
    const { type, title, desc, hideIcon } = item
    const ariaLabel = 'Alert'

    return (
      <div
        style={{ marginTop, marginBottom, marginInline }}
        className={className}
        aria-label={getAriaLabel('Alert')}
      >
        <div
          className={classNames(styles.item, styles[type], !title && styles.noTitle)}
          aria-label={getAriaLabel(ariaLabel, 'item')}
        >
          <div className={styles.left} aria-label={getAriaLabel(ariaLabel, 'left')}>
            <div className={styles.leftContent}>
              {!hideIcon && (
                <div className={styles.iconType} aria-label={getAriaLabel(ariaLabel, 'icon')}>
                  <Icon icon={alertIconsByType[type]} />
                </div>
              )}

              <div className={styles.content} aria-label={getAriaLabel(ariaLabel, 'content')}>
                {title && (
                  <div className={styles.title} aria-label={getAriaLabel(ariaLabel, 'title')}>
                    {title}
                  </div>
                )}
                {desc && (
                  <div className={styles.desc} aria-label={getAriaLabel(ariaLabel, 'desc')}>
                    {highlightUrls(desc)}
                  </div>
                )}
                {actions && (
                  <div className={styles.actions} aria-label={getAriaLabel(ariaLabel, 'actions')}>
                    {actions}
                  </div>
                )}
              </div>
            </div>
            {action}
          </div>
          {rightAction}

          {onClose && (
            <div className={styles.iconClose} aria-label={getAriaLabel(ariaLabel, 'iconClose')}>
              <IconButton
                icon={'close'}
                size={'small'}
                variant={'icon'}
                color={'transparent'}
                fontSize={16}
                onClick={onClose}
                ariaLabel='Alert_close'
              />
            </div>
          )}
        </div>

        {renderBottom && (
          <div className={styles.box} aria-label={getAriaLabel(ariaLabel, 'box')}>
            {renderBottom(item)}
          </div>
        )}
      </div>
    )
  }
)
