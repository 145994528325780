import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import dayjs from 'dayjs'
import { TokenResult } from '@stripe/stripe-js'
import { AxiosError } from 'axios'
import * as configcat from 'configcat-js'
import { logger, numberFormat } from 'shared/lib'
import { showToast } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import {
  authStore,
  type IParamsRegisterAnswers,
  type IParamsSignUpOrganization,
  type IParamsValidateMaxMind,
} from 'entities/Auth'
import { AuthApi } from 'entities/Auth/api'
import type { IParamsSignUpAnswers, IResponseGoogleRegister } from 'entities/Auth/store/types'
import type {
  IParamsUpdateUsersProfilesUiSettings,
  IResponseUser,
  IResponseUsersProfilesUiSettings,
} from 'entities/Users/api/types'
import { BillingApi } from 'entities/Billing'
import { UsersApi } from 'entities/Users'
import { channelsProfile } from 'entities/Users/channels/profile'
import { websocket } from 'entities/WebSocket'
import { IntegrationsApi } from 'entities/Integrations'
import { RegisterStepEnum } from 'widgets/Register/store/type'
import { StepSignUpStore } from 'widgets/Register/ui/StepSignUp/store/stepSignUpStore'
import { Step1Store } from 'widgets/Register/ui/Step1/store/step1Store'
import { Step2Store } from 'widgets/Register/ui/Step2/store/step2Store'
import { Step3Store } from 'widgets/Register/ui/Step3/store/step3Store'
import { Step4Store } from 'widgets/Register/ui/Step4/store/step4Store'

export class RegisterStore {
  private _step1Store: Step1Store = new Step1Store()
  private _step2Store: Step2Store = new Step2Store()
  private _step3Store: Step3Store = new Step3Store()
  private _step4Store: Step4Store = new Step4Store()
  private _stepSignUpStore: StepSignUpStore = new StepSignUpStore()
  private _step: RegisterStepEnum = RegisterStepEnum.stepSignUp
  private _loading = false
  private _preloading = false
  private _inProgress = false
  private _profileChanelSubscribed = false
  private _profileId = 0

  private _multiOrganizationRegistration = false
  private _crmId = 0
  private _terms = true
  private _city = ''
  private _region = ''
  private _noCardTest = false
  private _timezone = ''
  private _newBilling = true

  // Infusionsoft
  private _leadSource = ''
  private _gaSource = ''
  private _gaMedium = ''
  private _gaTerm = ''
  private _gaContent = ''
  private _gaCampaign = ''
  private _gaReferurl = ''
  private _gaIp = ''

  // UTM
  private _utmCampaign = ''
  private _utmContent = ''
  private _utmMedium = ''
  private _utmSource = ''

  // STRIPE
  private _token = ''
  private _plan = ''

  // Handle completed profile with oid
  private _disposeHasOid: IReactionDisposer | null = null

  // Coupon
  private _disposeCoupon: IReactionDisposer | null = null
  private _couponTitle = ''

  constructor(authData?: IResponseGoogleRegister) {
    makeAutoObservable(this)
    this.init()
    this.reactionHasOid()
    this.reactionCoupon()
    if (authData) this.handleGoogleAuth(authData)
  }

  get couponTitle() {
    return this._couponTitle
  }

  get inProgress() {
    return this._inProgress
  }

  get preloading() {
    return this._preloading
  }

  get profileChanelSubscribed() {
    return this._profileChanelSubscribed
  }

  get stepSignUpStore() {
    return this._stepSignUpStore
  }

  get step1Store() {
    return this._step1Store
  }

  get step2Store() {
    return this._step2Store
  }

  get step3Store() {
    return this._step3Store
  }

  get step4Store() {
    return this._step4Store
  }

  get step() {
    return this._step
  }

  get loading() {
    return this._loading
  }

  get emailStorageKey() {
    return 'signUpEmail'
  }

  get steps() {
    return [
      RegisterStepEnum.stepSignUp,
      RegisterStepEnum.step1,
      RegisterStepEnum.step2,
      RegisterStepEnum.step3,
      RegisterStepEnum.step4,
    ]
  }

  get posthogSessionId() {
    return window.posthog?.get_session_id() || ''
  }

  get sessionId() {
    return this.posthogSessionId
  }

  get hasNextStep() {
    return this.step !== RegisterStepEnum.step4
  }

  get hasPrevStep() {
    return this.step !== RegisterStepEnum.stepSignUp
  }

  get progressPercent() {
    switch (this._step) {
      case RegisterStepEnum.stepSignUp:
        return 0
      case RegisterStepEnum.step1:
        return 20
      case RegisterStepEnum.step2:
        return 40
      case RegisterStepEnum.step3:
        return 60
      case RegisterStepEnum.step4:
        return 80
      default:
        return 0
    }
  }

  get coupon() {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get('c') || ''
  }

  get hasCoupon() {
    return !!this.coupon
  }

  get registerAnswerStep() {
    switch (this._step) {
      case RegisterStepEnum.stepSignUp:
        return '0'
      case RegisterStepEnum.step1:
        return '1'
      case RegisterStepEnum.step2:
        return '2'
      case RegisterStepEnum.step3:
        return '3'
      case RegisterStepEnum.step4:
        return '4'
      default:
        return '0'
    }
  }

  get numberVendorKey() {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('twilio')) return 'twilio-integration'
    if (searchParams.has('sinch')) return 'sinch'
    return ''
  }

  get profileId() {
    return this._profileId
  }

  initSignUpEmail() {
    const email = localStorage.getItem(this.emailStorageKey)
    if (email) this.stepSignUpStore.setEmail(email)
  }

  handleGoogleAuth = async (data: IResponseGoogleRegister) => {
    this.stepSignUpStore.googleAuthCallback(data, null)
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  setCouponTitle = (value: string) => {
    this._couponTitle = value
  }

  initStartStep = async () => {
    let startStep = RegisterStepEnum.stepSignUp

    if (authStore.hasOid === false) {
      startStep = RegisterStepEnum.step1
      try {
        const { data } = await UsersApi.getUsersProfilesUiSettings('registration-steps')
        await this.initAnswers(data)

        if (this.step1Store.isCompletedStep) startStep = RegisterStepEnum.step2
        if (this.step2Store.isCompletedStep) startStep = RegisterStepEnum.step3
        if (this.step3Store.isCompletedStep) startStep = RegisterStepEnum.step4
      } catch (e) {
        logger.error(e)
      }
    }

    this._step = startStep
  }

  initAnswers = async (data: IResponseUsersProfilesUiSettings) => {
    this.step1Store.initAnswers(data)
    this.step2Store.initAnswers(data)
    this.step4Store.initAnswers(data)
    this.step3Store.initAnswers(data)
  }

  updateAnswers = async () => {
    const answers: IParamsSignUpAnswers = {
      email: this.stepSignUpStore.email,
      ...this._step1Store.payload,
      ...this._step2Store.payload,
      ...this._step3Store.payload,
      ...this._step4Store.payload,
    }

    const data: IParamsUpdateUsersProfilesUiSettings = {
      source_type: 'registration-steps',
      items: {
        answers,
      },
    }

    try {
      await UsersApi.updateUsersProfilesUiSettings(data)
    } catch (error) {
      logger.error(error)
    }
  }

  handleSinch = async (id: number) => {
    if (!window.Config?.config_cat_key || this.numberVendorKey || !uiStore.setSearchParams) return

    const configCatClient = configcat.getClient(
      window.Config.config_cat_key,
      configcat.PollingMode.AutoPoll
    )

    const user = new configcat.User(`${id}`)

    const sinchSignUpV2 = await configCatClient.getValueAsync('sinchSignUpV2', false, user)
    if (!sinchSignUpV2) return

    const params = new URLSearchParams(window.location.search)
    params.append('sinch', '')

    uiStore.setSearchParams(params.toString())
  }

  initProfile = async () => {
    if (!authStore.hasToken) return

    try {
      const {
        data: { email, organization_in_progress, id },
      } = await UsersApi.getUsersProfile()

      this._profileId = id
      this._inProgress = organization_in_progress
      this.stepSignUpStore.setEmail(email)
      channelsProfile.subscribeChannels(id)
      this.handleSinch(id)
      this._profileChanelSubscribed = true
    } catch (error) {
      logger.error(error)
    }
  }

  initTwilio = async () => {
    const searchParams = new URLSearchParams(window.location.search)
    const installationSid = searchParams.get('installationSid')
    const accountSid = searchParams.get('accountSid')

    if (accountSid && installationSid) {
      const {
        data: {
          owner: { email, first_name, last_name },
        },
      } = await IntegrationsApi.getTwilioAccount({
        installation_sid: installationSid,
        account_sid: accountSid,
      })

      if (email) this.stepSignUpStore.setEmail(email)
      if (first_name) this.step4Store.setFirstName(first_name)
      if (last_name) this.step4Store.setLastName(last_name)
    }
  }

  init = () => {
    this._newBilling = window.Config?.new_billing_registration === '1'
    this._timezone = dayjs.tz.guess()

    const utmJson = sessionStorage.getItem('utm')
    const utmData = utmJson ? JSON.parse(utmJson) : null
    this._utmCampaign = utmData?.utm_campaign || ''
    this.initSignUpEmail()
    this.initTwilio()
    this._stepSignUpStore.setHandleNextStep(this.handleNextStep)
  }

  logAnswers = async (data?: IResponseUser) => {
    try {
      const payload: IParamsRegisterAnswers = {
        step: this.registerAnswerStep,
        email: this.stepSignUpStore.email,
        session_id: this.sessionId,
        posthog_session_id: this.posthogSessionId,
        profile_id: this.profileId,
        user_id: data?.id || 0,
        coupon_applied: this.hasCoupon ? 'yes' : 'no',
        ...this.step1Store.answers,
        ...this.step2Store.answers,
        ...this.step3Store.answers,
      }

      await AuthApi.logRegisterAnswers(payload)
    } catch (error) {
      logger.error(error)
    }
  }

  handleNextStep = () => {
    if (!this.hasNextStep) {
      this.handleSignUp()
      return
    }

    this.logAnswers()
    this.updateAnswers()

    const nextStepIdx = this.steps.indexOf(this.step) + 1
    const nextStep = this.steps[nextStepIdx]
    this._step = nextStep
  }

  reset = () => {
    this.stepSignUpStore.reset()
    this.step1Store.reset()
    this.step2Store.reset()
    this.step3Store.reset()
    this.step4Store.reset()
  }

  handleLogout = async () => {
    try {
      this._preloading = true
      await authStore.logout()
      localStorage.setItem(this.emailStorageKey, this.stepSignUpStore.email)
    } catch (e) {
      logger.error(e)
    } finally {
      window.location.reload()
    }
  }

  handlePrevStep = () => {
    if (!this.hasPrevStep) return
    const prevStepIdx = this.steps.indexOf(this.step) - 1
    const prevStep = this.steps[prevStepIdx]

    if (prevStep === RegisterStepEnum.stepSignUp) this.handleLogout()

    this._step = prevStep
  }

  get stripePayload() {
    return {
      token: this._token,
      plan: this._plan,
      coupon: this.coupon,
    }
  }

  get utmPayload() {
    return {
      utm_campaign: this._utmCampaign,
      utm_content: this._utmContent,
      utm_medium: this._utmMedium,
      utm_source: this._utmSource,
    }
  }

  get infusionsoftPayload() {
    return {
      lead_source: this._leadSource,
      ga_source: this._gaSource,
      ga_medium: this._gaMedium,
      ga_term: this._gaTerm,
      ga_content: this._gaContent,
      ga_campaign: this._gaCampaign,
      ga_referurl: this._gaReferurl,
      ga_ip: this._gaIp,
    }
  }

  get maxMindPayload(): IParamsValidateMaxMind {
    const payload: IParamsValidateMaxMind = {
      email: this.stepSignUpStore.email,
      number: this.step4Store.number,
    }

    if (this.step4Store.smsCode) payload.sms_code = this.step4Store.smsCode

    return payload
  }

  get registerPayload(): IParamsSignUpOrganization {
    return {
      // Initial data
      multiorganization_registration: this._multiOrganizationRegistration,
      crm_id: this._crmId,
      terms: this._terms,
      city: this._city,
      region: this._region,
      noCardTest: this._noCardTest,
      timezone: this._timezone,
      new_billing: this._newBilling,
      number_vendor_key: this.numberVendorKey,
      email: this.stepSignUpStore.email,
      // Stripe
      ...this.stripePayload,
      // Infusionsoft
      ...this.infusionsoftPayload,
      // UTM
      ...this.utmPayload,
      // Step 1
      ...this.step1Store.payload,
      // Step 2
      ...this._step2Store.payload,
      // Step 3
      ...this.step3Store.payload,
      // Step 4
      ...this._step4Store.payload,
    }
  }

  private _onSubmitCard: (() => Promise<TokenResult | undefined>) | null = null

  setOnSubmit = (onSubmit: () => Promise<TokenResult | undefined>) => {
    this._onSubmitCard = onSubmit
  }

  onAddCard = async () => {
    if (this._step4Store.cardError) return
    if (this._onSubmitCard) {
      try {
        const res = await this._onSubmitCard()
        if (!res || res?.error) {
          runInAction(() => {
            this._step4Store.setCardError(
              res?.error.message || 'Please provide another credit card'
            )
          })
          return
        }

        this._token = res.token.id
        this._step4Store.setCardError(null)
      } catch (e) {
        logger.error(e)
      }
    }
  }

  handleSignUp = async () => {
    try {
      this.updateAnswers()
    } catch (e) {
      logger.error(e)
    }

    try {
      runInAction(() => {
        this._loading = true
      })

      await this.onAddCard()
      await AuthApi.validateMaxMind(this.maxMindPayload)
      await authStore.signUpOrganization(this.registerPayload)
      localStorage.removeItem(this.emailStorageKey)
      this.step4Store.closeMaxMindModal()
    } catch (error) {
      if (error instanceof AxiosError) {
        logger.error(error)
        runInAction(() => {
          this._loading = false
        })
        if (error.response?.status === 500) this.step4Store.setShowRetryError(true)
        const data = error.response?.data
        if (!data) return
        if (data.message === 'code_required' || data.message === 'code_incorrect') {
          this.step4Store.triggerMaxMindModal(data.message === 'code_incorrect')
          return
        }

        const errorKeys = Object.keys(data)
        errorKeys.map((errorKey) => {
          const error = data[errorKey]?.[0] || data[errorKey]

          if (typeof error === 'string') {
            if (errorKey === 'number') {
              this.step4Store.phoneInputStore.setNumberRequestError(error)
            } else {
              showToast({
                type: 'error',
                title: error,
              })
            }
          }
        })
      }
    }
  }

  handleCoupon = async () => {
    try {
      const { data } = await BillingApi.getCoupon(this.coupon)

      let discountText = ''
      let durationText = ''

      if (data.amount_off) {
        discountText +=
          data.currency === 'usd'
            ? numberFormat({
                value: data.amount_off / 100,
                minimumFractionDigits: 2,
                currency: 'USD',
              })
            : `${data.amount_off / 100} ${data.currency}`
      } else if (data.percent_off) {
        discountText += `${data.percent_off}%`
      }

      if (data.duration_in_months) {
        durationText +=
          data.duration_in_months > 1 ? ` for ${data.duration_in_months} months` : ' for 1 month'
      } else if (data.duration) {
        durationText += data.duration === 'once' ? ' the first month' : ` ${data.duration}`
      }

      this.setCouponTitle(`Coupon applied! Enjoy ${discountText} off ${durationText}.`)
    } catch (e) {
      logger.error(e)
    }
  }

  reactionCoupon = () => {
    this._disposeCoupon?.()
    this._disposeCoupon = reaction(
      () => this.hasCoupon,
      (hasCoupon) => {
        if (hasCoupon) this.handleCoupon()
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionHasOid = () => {
    this._disposeHasOid?.()
    this._disposeHasOid = reaction(
      () => authStore.hasToken,
      async (hasToken) => {
        if (hasToken) {
          websocket.connect(localStorage.getItem('token'))
          try {
            this._preloading = true
            await this.initProfile()
            await this.initStartStep()
          } catch (e) {
            logger.error(e)
          } finally {
            this._preloading = false
          }
        }
      },
      {
        fireImmediately: true,
      }
    )
  }
}
