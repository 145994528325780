// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fwdeK3CRiFSp5t1b6bOa{margin-bottom:20px}.JyNDQCrt0OqIXV5Nwlk7{padding:8px}", "",{"version":3,"sources":["webpack://./src/pages/contacts/ui/ContactsMenu/ui/SegmentMenuItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,WAAA","sourcesContent":[".segments {\n  margin-bottom: 20px;\n}\n\n.empty {\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"segments": "fwdeK3CRiFSp5t1b6bOa",
	"empty": "JyNDQCrt0OqIXV5Nwlk7"
};
export default ___CSS_LOADER_EXPORT___;
