import { makeAutoObservable, runInAction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { numbersStore, PhoneApi } from 'entities/Phone'
import { type IResponseNumberPool } from 'entities/Phone/api/types'
import { GetNumberPFTModalContent } from 'entities/Phone/ui/GetNumberPFTModal/ui/GetNumberPFTModalContent'
import { GetNumberPFTModalActions } from 'entities/Phone/ui/GetNumberPFTModal/ui/GetNumberPFTModalActions'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import { conversationStore } from 'entities/Conversation'
import { ConversationRoutes } from 'entities/ExportRouters'

export class GetNumberPFTModalStore {
  private _idModal = 'getNumberPFTModal'
  private _loadingNumbers = true
  private _loadingUpdate = false
  private _number: IResponseNumberPool | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get disabled() {
    return this._loadingNumbers || !this._number || this._loadingUpdate
  }

  get loadingNumbers() {
    return this._loadingNumbers
  }

  get number() {
    return this._number
  }

  open = async () => {
    await Promise.all([
      usersStore.initPromise,
      subscriptionStore.initPromise,
      organizationStore.initPromise,
    ])
    if (
      usersStore.user?.isRequirePftNumber &&
      !subscriptionStore.isAgencyWithoutSubscription &&
      !organizationStore.isTwilioNumberVendor &&
      !usersStore.user?.inboxesCount
    ) {
      this.loadNumbers()

      modalStore.addModal({
        id: this._idModal,
        showHeader: false,
        showCloseButton: false,
        disabledOnAllClose: true,
        disabledOnEscClose: true,
        disabledOverlayClose: true,
        width: 480,
        paddingContent: '40px 24px 16px 24px',
        ModalContentProps: {
          store: this,
        },
        ModalActions: GetNumberPFTModalActions,
        ModalContent: GetNumberPFTModalContent,
      })
    }
  }

  closeModal = () => {
    modalStore.removeModal(this._idModal)
    this.dispose()
  }

  dispose = () => {}

  loadNumbers = async () => {
    try {
      runInAction(() => {
        this._loadingNumbers = true
      })

      const { data } = await PhoneApi.getNumbersFromPool({
        limit: 1,
      })

      if (data.length) {
        this._number = data[0]
      }
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loadingNumbers = false
      })
    }
  }

  updateNumber = async () => {
    if (!this._number) return

    try {
      runInAction(() => {
        this._loadingUpdate = true
      })

      conversationStore.setModeNotOpen(true)

      const { data } = await PhoneApi.updateNumberPtf({
        pft_number: this._number.formatted_number,
      })

      await window.publicApiVue?.fetchMe()
      await Promise.all([usersStore.fetchUsersMe(), numbersStore.fetchOrganizationNumber()])

      numbersStore.addItem(data.pft_number)

      const isConversationsPage = window.location.pathname.startsWith(`/${ConversationRoutes.root}`)
      if (isConversationsPage) {
        uiStore.changeRoute({
          path: `/${ConversationRoutes.root}/null`,
          query: 'woohoo',
        })
      }

      this.closeModal()
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loadingUpdate = false
      })
    }
  }
}
