import { makeAutoObservable } from 'mobx'
import { IParamsCreateMessage } from '../api/types'

class GlobalApiMessageStore {
  constructor() {
    makeAutoObservable(this)
  }
  private _extraCreateMessageData: Partial<IParamsCreateMessage> | null = null
  getExtraCreateMessageData = () => {
    return this._extraCreateMessageData
  }
  setExtraCreateMessageData = (extraMessageData: typeof this._extraCreateMessageData) => {
    this._extraCreateMessageData = extraMessageData
  }
}

export const globalMessageApiStore = new GlobalApiMessageStore()
