import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Tabs, Typography } from 'shared/ui'
import { getCDNAssetURL } from 'shared/lib'
import callingCnamCallerId from 'shared/assets/images/compliance/callingCnamCallerId.png'
import callingStirShaken from 'shared/assets/images/compliance/callingStirShaken.png'
import callingVoiceIntegrity from 'shared/assets/images/compliance/callingVoiceIntegrity.png'
import styles from './styles.module.scss'

const TAB_CONTENT = {
  voiceIntegrity: {
    label: 'Voice Integrity',
    description: 'Remediate “Spam Likely” labels on your calls.',
    image: callingVoiceIntegrity,
  },
  stirShaken: {
    label: 'STIR / SHAKEN',
    description: 'Increase answer rates by showing “Caller Verified”.',
    image: callingStirShaken,
  },
  cnamCallerId: {
    label: 'CNAM Caller ID',
    description: 'Increase answer rates by showing “Caller Verified”.',
    image: callingCnamCallerId,
  },
}

export const AddOnModalContentFirstStep = observer(() => {
  const [activeTabKey, setActiveTabKey] = useState<keyof typeof TAB_CONTENT>('voiceIntegrity')
  const activeTab = TAB_CONTENT[activeTabKey]

  return (
    <div>
      <div className={styles.header}>
        <Typography ariaLabel='trustedCalling' variant='heading-lg'>
          Trusted Calling
        </Typography>
        <Typography ariaLabel='trustedCalling' variant='body-rg-regular'>
          $10.00 / month
        </Typography>
      </div>

      <Tabs
        variant={'simple'}
        gap={8}
        activeTabKey={activeTabKey}
        handleSelectTab={(tab) => setActiveTabKey(tab.key as keyof typeof TAB_CONTENT)}
        tabs={Object.keys(TAB_CONTENT).map((key) => ({
          key,
          name: TAB_CONTENT[key as keyof typeof TAB_CONTENT].label,
        }))}
      />

      <Typography
        ariaLabel='trustedCalling'
        variant='body-rg-regular'
        color='var(--content-primary-tertiary)'
        marginTop='40px'
        marginBottom='34px'
        tag='div'
      >
        {activeTab.description}
      </Typography>

      <img src={getCDNAssetURL(activeTab.image)} alt={activeTabKey} className={styles.image} />
    </div>
  )
})
