import { makeAutoObservable, reaction, runInAction, type IReactionDisposer } from 'mobx'
import React, { ReactNode } from 'react'
import { dropDownStore, type IDropdownItem } from 'shared/ui'
import { IntegrationsApi, integrationsStore, IParamsIntegration } from 'entities/Integrations'
import { Integration, IntegrationKey } from 'entities/Integrations/model/Integration'
import { IIntegrationDropdownItem } from 'widgets/IntegrationDropdown/store/types'
import { MergeField } from 'widgets/MergeField/types/MergeField'
import {
  CustomIntegrationLabel,
  IntegrationLabel,
} from 'widgets/IntegrationDropdown/ui/IntegrationLabel'

export class IntegrationDropdownStore {
  private _selectedIntegrationItem: IIntegrationDropdownItem | null = null
  private _search = ''
  private _integrationsLoading = true
  private _integrationDropdownMap: Map<number, IIntegrationDropdownItem> = new Map()

  private _dropdownId: string | null = null

  private _phoneFieldLoading = false
  private _phoneFieldDropdownItems: IDropdownItem[] = []
  private _selectedPhoneField: IDropdownItem | null = null

  private _disposeIntegrationSelectedReaction: IReactionDisposer | null = null

  private _phoneFieldError: ReactNode = null
  private _integrationError: ReactNode = null

  clearIntegrationError: () => void
  clearPhoneFieldError: () => void

  constructor({
    clearIntegrationError,
    clearPhoneFieldError,
  }: {
    clearIntegrationError: () => void
    clearPhoneFieldError: () => void
  }) {
    makeAutoObservable(this)

    this.clearIntegrationError = clearIntegrationError
    this.clearPhoneFieldError = clearPhoneFieldError
  }

  init = async () => {
    runInAction(() => {
      this._integrationsLoading = true
    })

    await Promise.all([integrationsStore.fetchIntegrations()])

    this.mapIntegrationsIntoDropdown()

    this.reactionIntegrationSelected()

    runInAction(() => {
      this._integrationsLoading = false
    })
  }

  reset = () => {
    this._disposeIntegrationSelectedReaction?.()
  }

  reactionIntegrationSelected = () => {
    this._disposeIntegrationSelectedReaction?.()
    this._disposeIntegrationSelectedReaction = reaction(
      () => this._selectedIntegrationItem?.id,
      () => this.mapPhoneFieldsIntoDropdown()
    )
  }

  mapIntegrationsIntoDropdown = () => {
    integrationsStore.getListOfIntegrations(this._allowedIntegrationsForChoose).forEach((item) => {
      this.addIntegrationItemDropdown(item)
    })
  }

  addIntegrationItemDropdown = (integration: Integration) => {
    const dropdownItem: IIntegrationDropdownItem = {
      id: integration.id,
      label: integration.name,
      iconL: integration.icon,
      labelRight: integration.is_connected ? '' : 'Reconnect',
      labelContent: <IntegrationLabel integration={integration} />,
      data: integration,
    }

    this._integrationDropdownMap.set(integration.id, dropdownItem)
  }

  setIntegration = (item: IIntegrationDropdownItem) => {
    this._selectedIntegrationItem = item

    this.hideDropDown()
  }

  setIntegrationSearch = (value: string) => {
    this._search = value
  }

  mapPhoneFieldsIntoDropdown = async () => {
    runInAction(() => {
      this._phoneFieldDropdownItems = []
      this._selectedPhoneField = null
    })
    const selectedIntegration: Integration | null = this.selectedIntegrationItem?.data || null

    if (selectedIntegration) {
      try {
        runInAction(() => {
          this._phoneFieldLoading = true
        })

        const { data } = await IntegrationsApi.getIntegrationsByKeyFields(selectedIntegration.key)
        runInAction(() => {
          data.forEach((responseMergeField) => {
            const integrationMergeField = new MergeField(responseMergeField)
            if (integrationMergeField.name.match(/phone/i)) {
              this.addPhoneFieldItemDropdown(integrationMergeField)
            }
          })
        })
      } catch (e) {
        console.error(e)
      } finally {
        runInAction(() => {
          this._phoneFieldLoading = false
        })
      }
    }
  }

  addPhoneFieldItemDropdown = (phoneField: MergeField) => {
    const phoneFieldDropdownItem: IDropdownItem = {
      id: phoneField.id,
      label: phoneField.label,
      value: phoneField.name,
    }

    this._phoneFieldDropdownItems.push(phoneFieldDropdownItem)
  }

  setPhoneField = (item: IDropdownItem) => {
    this._selectedPhoneField = item

    this.hideDropDown()
  }

  setDropdownId = (id: string) => {
    this._dropdownId = id
  }

  hideDropDown = () => {
    if (this._dropdownId) {
      dropDownStore.hide(this._dropdownId)
    }
  }

  get integrationItems(): IIntegrationDropdownItem[] {
    const customItem: IIntegrationDropdownItem = {
      id: 'custom',
      label: 'Custom',
      iconL: 'lightning2',
      labelContent: <CustomIntegrationLabel />,
    }
    return [...this.integrations, customItem].filter((item) => {
      return item.label.toLowerCase().includes(this._search.toLowerCase())
    })
  }

  get selectedIntegrationItem() {
    return this._selectedIntegrationItem
  }

  get integrations() {
    return Array.from(this._integrationDropdownMap.values())
  }

  get integrationItemsCount() {
    return this.integrations.length
  }

  get search() {
    return this._search
  }

  get integrationsLoading() {
    return this._integrationsLoading
  }

  get phoneFieldsItems() {
    return this._phoneFieldDropdownItems
  }

  get phoneFieldLoading() {
    return this._phoneFieldLoading
  }

  get selectedPhoneField() {
    return this._selectedPhoneField
  }

  get _allowedIntegrationsForChoose(): Array<IntegrationKey> {
    return [
      IntegrationKey.activecampaign,
      IntegrationKey.hubspot,
      IntegrationKey.infusionsoft,
      IntegrationKey.salesforce,
    ]
  }

  get params(): IParamsIntegration {
    return {
      integration_id: this._selectedIntegrationItem?.id ?? null,
      service_property: this._selectedPhoneField?.value ?? null,
    }
  }

  setIntegrationError = (error: ReactNode) => {
    this._integrationError = error
  }

  setPhoneFieldError = (error: ReactNode) => {
    this._phoneFieldError = error
  }

  get integrationError() {
    return {
      message: this._integrationError,
    }
  }

  get phoneFieldError() {
    return {
      message: this._phoneFieldError,
    }
  }
}
