// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sYcIpQBCzzaEt3VALzJJ{color:var(--content-primary-primary);font-size:13px;font-weight:400;line-height:16px;padding:4px;border-top:1px solid var(--gray-20)}.Y83_fZgRHC8T5BAKqVxl{display:flex;padding:8px 12px;cursor:pointer;border-radius:4px}.Y83_fZgRHC8T5BAKqVxl:hover{background:var(--background-neutral-subtle)}.Mm9phvW7ufkcEHgLdYWG{margin-left:8px}", "",{"version":3,"sources":["webpack://./src/pages/contacts/ui/ImportContacts/ui/MapFieldsModalContent/MapFieldsTable/ImportContactDropdown/ImportContactDropdownActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,oCAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,WAAA,CACA,mCAAA,CAEJ,sBACI,YAAA,CACA,gBAAA,CACA,cAAA,CACA,iBAAA,CAEA,4BACI,2CAAA,CAGR,sBACI,eAAA","sourcesContent":[".container {\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 16px;\n    padding: 4px;\n    border-top: 1px solid var(--gray-20);\n}\n.dropdownAction {\n    display: flex;\n    padding: 8px 12px;\n    cursor: pointer;\n    border-radius: 4px;\n\n    &:hover {\n        background: var(--background-neutral-subtle);\n    }\n}\n.dropdownActionText {\n    margin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sYcIpQBCzzaEt3VALzJJ",
	"dropdownAction": "Y83_fZgRHC8T5BAKqVxl",
	"dropdownActionText": "Mm9phvW7ufkcEHgLdYWG"
};
export default ___CSS_LOADER_EXPORT___;
