import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { CollapseWithIcon, Icon, Tooltip, Typography } from 'shared/ui'
import { HUBSPOT_CHANNEL_INFO_LINK } from 'shared/constants/links'
import { Integration } from 'entities/Integrations'
import { useIntegrationStoreMemo } from 'pages/settings/pages/integrations/pages/integrationSettings/hooks/useIntegrationStoreMemo'
import { IntegrationHubspotSettingsChannels } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsChannels/IntegrationHubspotSettingsChannels'
import { IntegrationHubspotSettingsProperty } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsProperty/IntegrationHubspotSettingsProperty'
import { IntegrationHubspotSettingsTimeline } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsTimeline/IntegrationHubspotSettingsTimeline'
import { IntegrationSettingsUsers } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSettingsUsers/IntegrationSettingsUsers'
import { IntegrationSettingsWebhook } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSettingsWebhook/IntegrationSettingsWebhook'
import { IntegrationHubspotSettingsSync } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsSync/IntegrationHubspotSettingsSync'
import { IntegrationHubspotSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsStore'
import { IntegrationHubspotSettingsLists } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsLists/IntegrationHubspotSettingsLists'
import styles from './styles.module.scss'

type IIntegrationHubspotSettingsProps = {
  integration: Integration
}

export const IntegrationHubspotSettings: FC<IIntegrationHubspotSettingsProps> = observer(
  ({ integration }) => {
    const {
      collapseStore,
      syncStore,
      webhookStore,
      userStore,
      timelineStore,
      propertyStore,
      channelsStore,
      listsStore,
    } = useIntegrationStoreMemo(IntegrationHubspotSettingsStore, integration)

    return (
      <div>
        <CollapseWithIcon
          opened={collapseStore.opened('webhook')}
          onClick={collapseStore.onToggle('webhook')}
          icon={'webhookOutline'}
          title={'Webhook'}
          label={`Send ${webhookStore.integrationName} contacts into your Salesmsg account`}
        >
          <IntegrationSettingsWebhook store={webhookStore} withLeadOwner />
        </CollapseWithIcon>
        <CollapseWithIcon
          opened={collapseStore.opened('users')}
          onClick={collapseStore.onToggle('users')}
          icon={'userLine'}
          title={'Users'}
          subtitle={userStore.userInfo.subtitle}
          label={userStore.userInfo.label}
        >
          <IntegrationSettingsUsers store={userStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          opened={collapseStore.opened('sync')}
          onClick={collapseStore.onToggle('sync')}
          icon={'arrowHorizontalOutline'}
          title={'Contact syncing'}
          label={syncStore.getLabel}
        >
          <IntegrationHubspotSettingsSync store={syncStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          opened={collapseStore.opened('inbox')}
          onClick={collapseStore.onToggle('inbox')}
          icon={'messageSmileCircle'}
          title={'Help Desk & Inbox '}
          label={"Send, receive, and manage conversations in HubSpot's Help Desk or Inbox"}
          subtitle={
            <>
              <Tooltip
                label={
                  <div style={{ wordBreak: 'break-word' }}>
                    {
                      <div>
                        <div>Discover the power of HubSpot Help Desk & Inbox</div>
                        <a
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          href={HUBSPOT_CHANNEL_INFO_LINK}
                          rel={'noopener noreferrer'}
                          target={'_blank'}
                          className={styles.link}
                        >
                          Learn more
                        </a>
                      </div>
                    }
                  </div>
                }
                placement={'top'}
              >
                <Icon fontSize={14} icon={'question'} tertiary />
              </Tooltip>
              <Typography variant='body-xs-medium' ariaLabel='BetaLabel' className={styles.badge}>
                Beta
              </Typography>
            </>
          }
        >
          <IntegrationHubspotSettingsChannels store={channelsStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          opened={collapseStore.opened('property')}
          onClick={collapseStore.onToggle('property')}
          icon={'workflowOutline'}
          title={'Property mapping'}
          label={`${propertyStore.mappedLength} properties mapped`}
        >
          <IntegrationHubspotSettingsProperty store={propertyStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          className={styles.withoutBorder}
          opened={collapseStore.opened('timeline')}
          onClick={collapseStore.onToggle('timeline')}
          icon={'listLayout'}
          title={'Timeline events'}
          label={'Track of every interaction directly in your HubSpot'}
        >
          <IntegrationHubspotSettingsTimeline store={timelineStore} />
        </CollapseWithIcon>
        <CollapseWithIcon
          className={styles.withoutBorder}
          opened={collapseStore.opened('lists')}
          onClick={collapseStore.onToggle('lists')}
          icon={'users2'}
          title={'HubSpot lists'}
          label={'Import and manage your HubSpot lists to keep your contacts organized'}
        >
          <IntegrationHubspotSettingsLists store={listsStore} />
        </CollapseWithIcon>
      </div>
    )
  }
)
