import { logger } from 'shared/lib'
import { usersStore } from 'entities/Users'
import { configStore } from 'entities/Config'
import { organizationSettingsStore, userSettingsStore } from 'entities/Settings'
import { multiOrganizationStore, organizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'
import { numbersStore } from 'entities/Phone'
import { billingStore } from 'entities/Billing'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { attachmentStore } from 'entities/Attachment'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { complianceStore } from 'entities/Compliance'

export function getAppData() {
  const notBlockerRequest = [
    usersStore.init,
    organizationStore.init,
    billingStore.fetchBillingStates,
    subscriptionStore.init,
    userPermissionsStore.fetchUserPermissions,
    usersStore.fetchUsersBusinessHours,
    usersStore.initUsersOrganization,
    configStore.init,
    userSettingsStore.fetchUsersSettings,
    featureFlagsStore.fetchFeatureFlags,
    multiOrganizationStore.fetchMultiOrganizations,
    attachmentStore.loadUploadInfo,
    organizationSettingsStore.fetchOrganizationSettings,
    numbersStore.fetchOrganizationNumber,
    numbersStore.fetchNumberVendorIntegrations,
    numbersStore.fetchNumbers,
    billingStore.fetchAutorecharge,
    billingStore.applyCoupon,
    billingStore.fetchBillingAreaCodes,
    complianceStore.fetchSummary,
    complianceStore.fetchOrganizationBusinessProfile,
    complianceStore.fetchComplianceServicesList,
    complianceStore.fetchOrganizationTollFree,
  ]

  Promise.all(notBlockerRequest.map((req) => req())).catch(logger.error)
}
