import { mapStackTrace } from 'sourcemapped-stacktrace'
import { CanceledError } from 'axios'
import { logger } from 'shared/lib'
import { eventLogStore } from 'entities/EventLog/store'
import { dataLogStore } from 'entities/DataLog/inbox'

export const mapStackTraceError = async (stack: string) => {
  stack = !stack ? '' : stack

  return new Promise((resolve) => {
    mapStackTrace(stack, function (mappedStack) {
      resolve(mappedStack.join('\n'))
    })
  })
}

export const errorLogger = (err: Error) => {
  try {
    if (err instanceof CanceledError) {
      return
    }

    dataLogStore.setItem({
      logLevel: 'error',
      message: err.message,
      details: {
        trace: err.stack,
      },
    })

    if (!err.stack) return

    const disabledSegmentEventLog = true

    if (disabledSegmentEventLog) {
      logger.error(`Error - ${err.message} | ${err.stack}`)
    } else {
      mapStackTraceError(err.stack).then((stack) => {
        const payload = {
          app: 'REACT',
          origin: window.location.origin,
          href: window.location.href,
          message: err.message,
          stack: stack,
        }

        logger.error(`Error - ${err.message} | ${stack}`)

        eventLogStore.logEvent('ERROR_JS', payload)
      })
    }
  } catch (e) {
    logger.error(e)
  }
}

const handleErrorGlobal = (event: ErrorEvent) => {
  if (event.error) {
    errorLogger(event.error)
  }
}

export const globalWatchError = () => {
  window.addEventListener('error', handleErrorGlobal)
}

export const removeGlobalWatchError = () => {
  window.removeEventListener('error', handleErrorGlobal)
}
