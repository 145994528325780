import { Route, Routes } from 'react-router-dom'
import { CleanupPage } from 'pages/contacts/pages/cleanup/ui/cleanupPage'
import { CleanupLayout } from 'pages/contacts/pages/cleanup/layout/CleanupLayout'

export const CleanupRouter = () => {
  return (
    <Routes>
      <Route element={<CleanupLayout />}>
        <Route path={'/'} element={<CleanupPage />} />
      </Route>
    </Routes>
  )
}
