import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { toastStore } from 'shared/ui'
import { logger } from 'shared/lib'
import { AdminOrganizationsApi, IResponseAdminOrganizationById } from 'entities/Admin'
import { AdminSendOptOutContactReportAction } from 'pages/admin/pages/organizations/modals/AdminSendOptOutContactReport/AdminSendOptOutContactReportAction'
import { AdminSendOptOutContactReportContent } from 'pages/admin/pages/organizations/modals/AdminSendOptOutContactReport/AdminSendOptOutContactReportContent'

class AdminSendOptOutContactReportStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''

  month = uiStore.dayjs().format('MM')
  year = uiStore.dayjs().format('YYYY')
  email = ''
  emailError = ''
  isOwner = false

  organization: IResponseAdminOrganizationById | null = null

  loading = false

  get disabledSubmit() {
    return !this.email && !this.isOwner
  }

  reset = () => {
    this.modalId = ''
    this.organization = null
    this.email = ''
    this.month = uiStore.dayjs().format('MM')
    this.year = uiStore.dayjs().format('YYYY')
    this.isOwner = false
  }

  setMonth = (value: string) => {
    this.month = value
  }

  setYear = (value: string) => {
    this.year = value
  }

  setIsOwner = (isOwner: boolean) => {
    if (isOwner && this.emailError) {
      this.emailError = ''
    }
    this.isOwner = isOwner
  }

  setEmail = (email: string) => {
    this.email = email
  }

  setEmailError = (error: string) => {
    this.emailError = error
  }

  onCheckEmail = () => {
    if (!this.isOwner && !this.email) {
      this.emailError = 'The email field is required when not sending to Owner.'
    }
  }

  onSubmit = async () => {
    try {
      if (!this.organization?.id) return
      runInAction(() => {
        this.loading = true
      })
      await AdminOrganizationsApi.createContactReport({
        id: this.organization.id,
        data: {
          owner: this.isOwner,
          emails: this.email || undefined,
          month: this.month,
          year: this.year,
        },
      })
      modalStore.closeModal(this.modalId)
      toastStore.add({
        id: nanoid(),
        type: 'success',
        title: 'Contact report was sent successfully',
      })
    } catch (e) {
      runInAction(() => {
        if (e instanceof AxiosError) {
          if (e.response?.data?.email?.length) {
            this.emailError = e.response?.data?.email[0]
          }
          if (e.response?.data.message?.includes('Email')) {
            this.emailError = 'Please enter a valid e-mail address.'
          }
        }
      })

      logger.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  onOpenModal = (organization: IResponseAdminOrganizationById) => {
    this.reset()
    this.organization = organization
    this.modalId = nanoid()
    modalStore.addModal({
      id: this.modalId,
      title: 'Send opt out contact report',
      ModalContent: AdminSendOptOutContactReportContent,
      width: 480,
      ModalActions: AdminSendOptOutContactReportAction,
    })
  }
}

export const adminSendOptOutContactReportStore = new AdminSendOptOutContactReportStore()
