import { createContext, useContext, ReactNode, FC } from 'react'
import { type ContactsPageStore } from 'pages/contacts/store/contactsPageStore'

export const ContactsPageContext = createContext<ContactsPageStore | null>(null)

export const useContactsPageContext = () => {
  const context = useContext(ContactsPageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ContactsPageContext')

  return context
}

type IProviderContactsPageProps = {
  value: ContactsPageStore
  children: ReactNode
}

export const ProviderContactsPage: FC<IProviderContactsPageProps> = ({ value, children }) => {
  return <ContactsPageContext.Provider value={value}>{children}</ContactsPageContext.Provider>
}
