import { makeAutoObservable, IReactionDisposer, reaction, runInAction } from 'mobx'
import { StripeCardElementChangeEvent, TokenResult } from '@stripe/stripe-js'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { IDropdownItem, showToast } from 'shared/ui'
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from 'shared/constants/auth'
import { uiStore } from 'shared/store/uiStore'
import { getIsValidAuthNumber } from 'shared/helpers/getIsValidAuthNumber'
import { logger } from 'shared/lib'
import { AuthApi } from 'entities/Auth/api'
import {
  AuthRoutesEnum,
  type IResponseAgencyInviteToken,
  type IParamsAcceptAgencyInvite,
  authStore,
} from 'entities/Auth'
import { ConversationRoutes } from 'entities/ExportRouters'
import { CountryEnum, FieldsEnum } from './type'

export class AgencyInviteStore {
  private _lastName = ''
  private _firstName = ''
  private _number = ''
  private _plan = ''
  private _payNow = false
  private _trial = false
  private _country: IDropdownItem = {
    label: 'United States',
    id: CountryEnum.US,
    iconL: 'flagUSA',
    labelRight: '+1',
  }
  private _email = ''
  private _organizationName = ''
  private _inviteOrganizationName = ''
  private _password = ''
  private _token = ''
  private _cardToken = ''
  private _timezone: string | null = null
  private _loading = false
  private _preloading = true
  private _activeField: FieldsEnum | null = null
  private _showRetryAlert = false
  private _cardComplete = false
  private _cardError: string | null = null

  // Password validation
  private _disposePasswordValidation: IReactionDisposer | null = null
  private _disposePasswordValidationTrigger: IReactionDisposer | null = null
  private _passwordError: string | null = null
  private _passwordValidationTrigger: string | null = null

  // Number validation
  private _disposeNumberValidation: IReactionDisposer | null = null
  private _numberError: string | null = null
  private _numberRequestError: string | null = null
  private _numberValidationTrigger: string | null = null

  constructor(token: string) {
    makeAutoObservable(this)
    this._token = token
    this.reactionPasswordValidation()
    this.reactionPasswordValidationTrigger()
    this.reactionNumberValidation()
    this.validateToken()
  }

  get countries(): IDropdownItem[] {
    return [
      {
        label: 'United States',
        id: CountryEnum.US,
        iconL: 'flagUSA',
        labelRight: '+1',
      },
      {
        label: 'Canada',
        id: CountryEnum.CA,
        iconL: 'flagCanada',
        labelRight: '+1',
      },
    ]
  }

  get disabledAccept() {
    return (
      !this._firstName ||
      !this._lastName ||
      !this.isValidNumber ||
      !this.isValidPassword ||
      (!this._cardComplete && !this.payNow)
    )
  }

  get email() {
    return this._email
  }

  get payNow() {
    return this._payNow
  }

  get cardError() {
    return this._cardError
  }

  get organizationName() {
    return this._organizationName
  }

  get inviteOrganizationName() {
    return this._inviteOrganizationName
  }

  get isValidPassword() {
    return (
      this._password.length <= MAX_PASSWORD_LENGTH && this._password.length >= MIN_PASSWORD_LENGTH
    )
  }

  get passwordLength() {
    return this.password.length
  }

  get isMaxPassword() {
    return this.passwordLength === MAX_PASSWORD_LENGTH
  }

  get loading() {
    return this._loading
  }

  get numberRequestError() {
    return this._numberRequestError
  }

  get preloading() {
    return this._preloading
  }

  get password() {
    return this._password
  }

  get passwordError() {
    return this._passwordError
  }

  get timezone() {
    return this._timezone || dayjs.tz.guess()
  }

  get payload(): IParamsAcceptAgencyInvite {
    return {
      invitation_id: this._token,
      first_name: this._firstName,
      last_name: this._lastName,
      email: this._email,
      organization_name: this._organizationName,
      password: this._password,
      password_confirmation: '',
      plan: this._plan,
      pay_now: this._payNow,
      token: this._cardToken,
      trial: this._trial,
      number: this._number,
      terms: true,
      lead_source: '',
      ga_source: '',
      ga_medium: '',
      ga_term: '',
      ga_content: '',
      ga_campaign: '',
      ga_referurl: '',
      ga_ip: '',
      country: 'US',
      city: '',
      region: '',
    }
  }

  get showRetryAlert() {
    return this._showRetryAlert
  }

  get firstName() {
    return this._firstName
  }

  get firstNameLength() {
    return this.firstName.length
  }

  get isMaxFirstNameLength() {
    return this.firstNameLength === MAX_FIRST_NAME_LENGTH
  }

  get isMaxLastNameLength() {
    return this.lastNameLength === MAX_LAST_NAME_LENGTH
  }

  get numberError() {
    return this._numberError
  }

  get lastName() {
    return this._lastName
  }

  get lastNameLength() {
    return this.lastName.length
  }

  get number() {
    return this._number
  }

  get isValidNumber() {
    return getIsValidAuthNumber(this.number)
  }

  get isActiveLastNameField() {
    return this._activeField === FieldsEnum.lastName
  }

  get isActiveFirstNameField() {
    return this._activeField === FieldsEnum.firstName
  }

  get isActivePasswordField() {
    return this._activeField === FieldsEnum.password
  }

  get country() {
    return this._country
  }

  clearCardError() {
    this._cardError = null
  }

  setCardError(value: string | null) {
    this._cardError = value
  }

  onChangeCard = (event: StripeCardElementChangeEvent) => {
    this._cardComplete = event.complete
    this._cardError = event.error?.message || null
  }

  setShowRetryError = (value: boolean) => {
    this._showRetryAlert = value
  }

  triggerNumberValidation = () => {
    this._numberValidationTrigger = nanoid()
  }

  focusFirstNameField = () => {
    this.setActiveField(FieldsEnum.firstName)
  }

  focusLastNameField = () => {
    this.setActiveField(FieldsEnum.lastName)
  }

  blurFirstNameField = () => {
    this.blurActiveField(FieldsEnum.firstName)
  }

  blurLastNameField = () => {
    this.blurActiveField(FieldsEnum.lastName)
  }

  focusPasswordField = () => {
    this.setActiveField(FieldsEnum.password)
  }

  blurPasswordField = () => {
    this.blurActiveField(FieldsEnum.password)
  }

  blurActiveField = (value: FieldsEnum) => {
    if (this._activeField !== value) return
    this._activeField = null
  }

  setActiveField = (value: FieldsEnum) => {
    this._activeField = value
  }

  setFirstName = (value: string) => {
    if (value.length > MAX_FIRST_NAME_LENGTH) return
    this._firstName = value
  }

  setLastName = (value: string) => {
    if (value.length > MAX_LAST_NAME_LENGTH) return
    this._lastName = value
  }

  setOrganizationName = (value: string) => {
    this._organizationName = value
  }

  setNumber = (value: string) => {
    if (this._numberRequestError) this.setNumberRequestError('')
    this._number = value
  }

  setNumberError = (value: string) => {
    this._numberError = value
  }

  setNumberRequestError = (value: string) => {
    this._numberRequestError = value
  }

  setCountry = (value: IDropdownItem) => {
    this._country = value
  }

  initData(data: IResponseAgencyInviteToken) {
    this._email = data.email
    this._inviteOrganizationName = data.organization_name
    this._plan = data.plan_id
    this._payNow = !!data.pay_now
    this._trial = !!data.trial
  }

  private _onSubmitCard: (() => Promise<TokenResult | undefined>) | null = null

  setOnSubmit = (onSubmit: () => Promise<TokenResult | undefined>) => {
    this._onSubmitCard = onSubmit
  }

  onAddCard = async () => {
    if (this._cardError) return
    if (this._onSubmitCard) {
      try {
        const res = await this._onSubmitCard()
        if (!res || res?.error) {
          runInAction(() => {
            this.setCardError(res?.error.message || 'Please provide another credit card')
          })
          return
        }

        this._cardToken = res.token.id
        this.setCardError(null)
      } catch (e) {
        logger.error(e)
      }
    }
  }

  validateToken = async () => {
    try {
      this._preloading = true
      const { data } = await AuthApi.validateAgencyInviteToken({ token: this._token })
      this.initData(data)
    } catch (error) {
      logger.error(error)
      uiStore.changeRoute({
        path: `/${AuthRoutesEnum.login}`,
      })
    } finally {
      this._preloading = false
    }
  }

  acceptInvite = async () => {
    try {
      this.setLoading(true)
      const {
        data: { valid },
      } = await AuthApi.validateAgencyInvite(this.payload)
      if (!valid) return
      await this.onAddCard()
      await authStore.acceptAgencyInvite(this.payload)
      if (authStore.isLoggedIn) {
        window.stop()
        window.location.href = `/${ConversationRoutes.root}`
      }
    } catch (error) {
      logger.error(error)
      if (error instanceof AxiosError) {
        if (error.response?.status === 500) this.setShowRetryError(true)
        const data = error.response?.data
        if (!data) return

        const errorKeys = Object.keys(data)
        errorKeys.map((errorKey) => {
          const error = data[errorKey]?.[0] || data[errorKey]

          if (typeof error === 'string') {
            if (errorKey === 'number') {
              this.setNumberRequestError(error)
            } else {
              showToast({
                type: 'error',
                title: error,
              })
            }
          }
        })
      }
    } finally {
      this.setLoading(false)
    }
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  setPassword = (value: string) => {
    if (value.length > MAX_PASSWORD_LENGTH) return
    this._password = value
  }

  triggerPasswordValidation = () => {
    this._passwordValidationTrigger = nanoid()
  }

  reactionPasswordValidation = () => {
    this._disposePasswordValidation?.()
    this._disposePasswordValidation = reaction(
      () => [this._passwordValidationTrigger, this.isValidPassword],
      ([trigger, isValid]) => {
        if (isValid) this._passwordError = ''
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionPasswordValidationTrigger = () => {
    this._disposePasswordValidationTrigger?.()
    this._disposePasswordValidationTrigger = reaction(
      () => this._passwordValidationTrigger,
      (value) => {
        if (value && !this.isValidPassword) {
          this._passwordError = `Password must contain at least ${MIN_PASSWORD_LENGTH} characters`
        }
        if (this.isValidPassword) {
          this._passwordError = ''
          if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionNumberValidation = () => {
    this._disposeNumberValidation?.()
    this._disposeNumberValidation = reaction(
      () => [this._numberValidationTrigger, this.isValidNumber],
      ([trigger, isValid]) => {
        this._numberError = trigger && !isValid ? 'Please enter a valid phone number' : null
        if (isValid) this._numberValidationTrigger = null
      },
      {
        fireImmediately: true,
      }
    )
  }
}
