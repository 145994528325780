import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CanceledError, type CancelTokenSource } from 'axios'
import { TableStore } from 'shared/ui/Table'
import { logger } from 'shared/lib'
import { PowerDialer } from 'entities/PowerDialer/model/PowerDialer'
import { PowerDialerApi } from 'entities/PowerDialer/api/powerDialer'
import type {
  IParamsGetPowerDialerList,
  IResponsePowerDialer,
  IResponsePowerDialerList,
} from 'entities/PowerDialer/api/types'
import { FiltersAndSearchStore } from 'widgets/FiltersAndSearch'

export class PowerDialerListStore {
  filtersAndSearchStore
  tableStore

  private _initialLoading = true
  private _loading = true
  private _itemsMap = new Map<number, PowerDialer>()

  private _limit = 10
  private _page = 1
  private _total = 0

  private _disposeResetPage: IReactionDisposer | null = null
  private _disposeLoadPowerDialerList: IReactionDisposer | null = null
  private _cancelTokenSource: CancelTokenSource | null = null

  constructor() {
    makeAutoObservable(this)
    this.filtersAndSearchStore = new FiltersAndSearchStore({
      getFilters: () => PowerDialerApi.getFilters().then(({ data }) => data),
    })
    this.tableStore = new TableStore<PowerDialer>({
      saveColumns: this._saveColumns,
      getColumns: this._getColumns(),
      sortBy: 'created_at',
      sortOrder: 'desc',
    })
    this.init()
    this._setupResetPageReaction()
    this._setupLoadPowerDialerListReaction()
  }

  init = () => {
    this.loadData()
  }

  setItem = (response: IResponsePowerDialer) => {
    const powerDialer = new PowerDialer(response)
    this._itemsMap.set(powerDialer.id, powerDialer)
  }

  setItems = (items: Array<IResponsePowerDialer>) => {
    items.forEach((item) => {
      this.setItem(item)
    })
  }

  setLoading = (value: boolean) => {
    this._loading = value
    this._initialLoading = false
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this._page = page
    this._limit = limit
  }

  onDelete = (id: number) => {
    let noLoading = true
    this._itemsMap.delete(id)
    if (this._itemsMap.size === 0 && this._page > 1) {
      this._page -= 1
      noLoading = false
    }
    void this.loadData(noLoading)
  }

  resetPageAndFilters = () => {
    this._page = 1
    this.filtersAndSearchStore.reset()
  }

  updateListItem = (updatedPowerDialer: IResponsePowerDialer) => {
    const updatedPowerDialerId = updatedPowerDialer.id
    if (this._itemsMap.has(updatedPowerDialerId)) {
      this._itemsMap.set(updatedPowerDialerId, new PowerDialer(updatedPowerDialer))
    }
  }

  private initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  private loadData = async (noLoading?: boolean) => {
    try {
      this.initCancelTokenSource()
      runInAction(() => {
        if (!noLoading) {
          this._loading = true
        }
      })
      const { data } = await PowerDialerApi.getPowerDialerList(this.paramsGetItems, {
        cancelToken: this._cancelTokenSource?.token,
      })
      this._setData(data)
    } catch (e) {
      this.setLoading(e instanceof CanceledError)
    } finally {
      this.setLoading(false)
    }
  }

  private _setData = ({ data, meta }: IResponsePowerDialerList) => {
    this._itemsMap.clear()
    this.setItems(data)
    this._total = meta.total
  }

  private _saveColumns = async (items: string[]) => {
    try {
      await PowerDialerApi.saveColumns({
        source_type: 'pd-campaigns-table-columns',
        items,
      })
    } catch (e) {
      logger.error(e)
    }
  }

  private _getColumns = async () => {
    try {
      const { data } = await PowerDialerApi.getColumns()

      if (!data.data) return

      return data.data.items
    } catch (e) {
      logger.error(e)
    }
  }

  private _setupLoadPowerDialerListReaction = () => {
    this._disposeLoadPowerDialerList?.()

    this._disposeLoadPowerDialerList = reaction(
      () => this.paramsGetItems,
      () => this.loadData(),
      {
        delay: 500,
      }
    )
  }

  private _setupResetPageReaction = () => {
    this._disposeResetPage?.()

    this._disposeResetPage = reaction(
      () => this.paramsWithoutPage,
      () => {
        this._loading = true
        this._page = 1
      }
    )
  }

  get loading(): boolean {
    return this._loading
  }

  get initialLoading(): boolean {
    return this._initialLoading
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  get empty() {
    return !this._loading && !this._itemsMap.size && !this.filtersAndSearchStore.hasSearchParams
  }

  get noSearchResults() {
    return !this._loading && !this._itemsMap.size && this.filtersAndSearchStore.hasSearchParams
  }

  get paramsGetItems(): IParamsGetPowerDialerList {
    return {
      ...this.paramsWithoutPage,
      page: this._page,
    }
  }

  get paramsWithoutPage(): Omit<IParamsGetPowerDialerList, 'page'> {
    return {
      limit: this._limit,
      sortBy: this.tableStore.sortBy,
      sortOrder: this.tableStore.sortOrder,
      search: this.filtersAndSearchStore.search,
      filtersList: this.filtersAndSearchStore.noEmptyFilters,
    }
  }

  get total() {
    return this._total
  }

  get page() {
    return this._page
  }

  get limit() {
    return this._limit
  }

  dispose = () => {
    this._disposeResetPage?.()
    this._disposeLoadPowerDialerList?.()
    this._cancelTokenSource?.cancel()

    this.filtersAndSearchStore.reset()
    this._itemsMap.clear()
  }
}
