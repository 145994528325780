// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rpOTlxFdeun08jfwRAB5{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable/ui/LinkContact/ui/LinkContactModalActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,OAAA","sourcesContent":[".wrap {\n    display: flex;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "rpOTlxFdeun08jfwRAB5"
};
export default ___CSS_LOADER_EXPORT___;
