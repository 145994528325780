import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Box, EmptyCell, IColumn, Table, Typography, variantActionsProps } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import { IResponseGetHubspotLists } from 'entities/Integrations'
import { type IntegrationHubspotSettingsListsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsListsStore'
import { useEventIntegrationListSyncFinished } from 'pages/settings/pages/integrations/pages/integrationSettings/events/EventIntegrationListSyncFinished'
import { HubspotListDropdown } from './HubspotListDropdown'
import { getPowerHubspotListActions } from './getPowerHubspotListActions'
import styles from './styles.module.scss'

type IIntegrationHubspotSettingsListsProps = {
  store: IntegrationHubspotSettingsListsStore
}

export const IntegrationHubspotSettingsLists = observer(
  ({ store }: IIntegrationHubspotSettingsListsProps) => {
    const {
      lists,
      allContactslists,
      setHubspotList,
      isLoading,
      onRemoveList,
      setAllLists,
      isAllContactslistsEmpty,
      isImportedListsEmpty,
      refreshData,
    } = store

    useEventIntegrationListSyncFinished(refreshData)

    const getRows = () => {
      return lists.map((list) => {
        return {
          ...list,
          actionsProps: {
            ...variantActionsProps.table,
            actions: getPowerHubspotListActions(list, () => onRemoveList(list.list_id)),
          },
        }
      })
    }

    const COLUMNS: IColumn<IResponseGetHubspotLists>[] = useMemo(
      () => [
        {
          field: 'list',
          name: 'HubSpot List',
          width: '60%',
          renderRowCell: ({ name }) => (
            <div className={styles.cellWrapper}>
              <Typography
                variant={'body-md-regular'}
                ellipsis
                ariaLabel={'ListName'}
                tooltipProps={{ label: name }}
              >
                {name}
              </Typography>
            </div>
          ),
        },
        {
          field: 'size',
          name: 'Number of contacts',
          renderRowCell: (row) => (
            <div className={styles.row}>
              <Typography variant={'body-md-regular'} ariaLabel={'ListSize'}>
                {row.size || 0}
              </Typography>
            </div>
          ),
        },
        {
          field: 'synced_at',
          name: 'Sync date',
          renderRowCell: ({ synced_at }) =>
            synced_at ? (
              <span aria-label='HubspotListSyncDate'>
                {uiStore.dayjs(synced_at).format(DayjsFormats.fullWithAtDash2)}
              </span>
            ) : (
              <EmptyCell />
            ),
        },
      ],
      []
    )

    return (
      <>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant={'body-lg-medium'} ariaLabel={'Lists'}>
            Lists
          </Typography>
          <HubspotListDropdown
            allLists={allContactslists}
            setList={setHubspotList}
            setAllLists={setAllLists}
            isListsEmpty={isAllContactslistsEmpty}
            isImportedListsEmpty={isImportedListsEmpty}
          />
        </Box>
        {lists.length > 0 && (
          <div className={styles.listsViewWrap}>
            <div className={classNames(styles.table, { [styles.loading]: isLoading })}>
              <Table
                columns={COLUMNS}
                rows={getRows()}
                scrollbarProps={{
                  autoHeight: true,
                  autoHeightMin: 160,
                  autoHeightMax: 610,
                  viewClassName: styles.viewScrollClassName,
                }}
                noPagination
              />
            </div>
          </div>
        )}
        {!lists.length && (
          <div className={styles.noListsWrap} aria-label={'empty_placeholder'}>
            No HubSpot lists
          </div>
        )}
      </>
    )
  }
)
