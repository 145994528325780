import { observer } from 'mobx-react-lite'

import { useCampaignDetailContext } from 'pages/campaigns/ui/detail/CampaignDetail/context/context'
import styles from './styles.module.scss'
import { OverviewStep } from './OverviewStep'
import { Step } from './Step'

export const Steps = observer(() => {
  const { campaign } = useCampaignDetailContext()
  return (
    <div className={styles.wrap}>
      <OverviewStep />
      {campaign.steps?.map((step) => (
        <Step key={step.id} step={step} />
      ))}
    </div>
  )
})
