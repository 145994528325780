import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CancelTokenSource } from 'axios'
import { ReactNode } from 'react'
import { IDropdownItem } from 'shared/ui'
import { logger } from 'shared/lib'
import {
  campaignActiveFilter,
  CampaignApi,
  type IParamsGetList,
  type IShortResponseCampaign,
} from 'entities/Campaign'

export class CampaignSelectStore {
  private _disposeParams
  constructor() {
    makeAutoObservable(this)

    this.loadData()
    this._disposeParams = reaction(
      () => this.params,
      () => this.loadData(),
      {
        delay: 500,
      }
    )
  }

  dispose = () => {
    this._disposeParams()
  }

  page = 1
  total = 0
  limit = 20
  search = ''
  loading = false
  loadingMore = false

  selectedItemId: null | number = null
  selectedItem: IDropdownItem | null = null

  cancelTokenSource: CancelTokenSource | null = null

  error: ReactNode = null

  private _dropDownItems: IDropdownItem[] = []

  get dropDownItems(): IDropdownItem[] {
    return this._dropDownItems
  }

  private initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  loadData = async () => {
    try {
      this.initCancelTokenSource()
      runInAction(() => {
        if (this.page === 1) {
          this.loading = true
          this._dropDownItems = []
        }
      })
      const { data } = await CampaignApi.getList(this.params, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })

      if (data) {
        runInAction(() => {
          if (this.page === 1) {
            this._dropDownItems = data.data.map(this.responseToDropdownItem)
          } else {
            this._dropDownItems = [
              ...this.dropDownItems,
              ...data.data.map(this.responseToDropdownItem),
            ]
          }

          this.total = data.meta.total
        })
      }
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
        this.loadingMore = false
      })
    }
  }

  get params(): IParamsGetList {
    return {
      page: this.page,
      limit: this.limit,
      search: this.search || '',
      filters: campaignActiveFilter,
    }
  }

  private responseToDropdownItem = (response: IShortResponseCampaign): IDropdownItem => {
    return {
      id: response.id,
      label: response.name,
    }
  }

  setDropdownItem = (id: number, item: IDropdownItem) => {
    this.selectedItemId = id
    this.selectedItem = item
  }

  onChangeOpen = (value: boolean) => {
    if (!value) {
      this.setSearch('')
    }

    if (value && !this._dropDownItems.length) {
      this.loadData()
    }
  }

  setSearch = (value: string) => {
    this.page = 1
    this.total = 1
    this.search = value
  }

  onLoadMore = () => {
    if (this.total <= this.dropDownItems.length) return
    if (this.loadingMore) return
    this.loadingMore = true
    this.page += 1
  }
}
