import classNames from 'classnames'
import { ReactNode, useEffect, useRef, useState, FC, ComponentType } from 'react'
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2'
import { getAriaLabel } from 'shared/lib'
import styles from './style.module.scss'

export type IScrollbarProps = ScrollbarProps & {
  children: ReactNode
  hideTrack?: boolean
  viewClassName?: string
  scrollToTOpTrigger?: string
}

export const Scrollbar: FC<IScrollbarProps> = ({
  children,
  hideTrack,
  scrollToTOpTrigger,
  viewClassName,
  ...props
}) => {
  const ref = useRef<Scrollbars>(null)
  const [show, setShow] = useState(true)
  const callbackMouseover = () => {
    setShow(false)
  }
  const callbackMouseout = () => {
    setShow(true)
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.container.addEventListener('mouseover', callbackMouseover)
      ref.current.container.addEventListener('mouseout', callbackMouseout)
    }

    return () => {
      if (ref.current) {
        ref.current.container.removeEventListener('mouseover', callbackMouseover)
        ref.current.container.removeEventListener('mouseout', callbackMouseout)
      }
    }
  }, [])

  useEffect(() => {
    if (scrollToTOpTrigger) {
      ref.current?.scrollToTop()
    }
  }, [scrollToTOpTrigger])

  const renderView = (props: ComponentType) => {
    return (
      <div
        className={classNames(styles.view, viewClassName)}
        {...props}
        aria-label={getAriaLabel('Scrollbar', 'view')}
      />
    )
  }

  const renderTrackHorizontal = (props: ComponentType) => {
    return (
      <div
        {...props}
        aria-label={getAriaLabel('Scrollbar', 'trackHorizontal')}
        className={classNames(styles.trackHorizontal, {
          [styles.hiddenTrack]: hideTrack,
        })}
      />
    )
  }

  const renderThumbHorizontal = (props: ComponentType) => {
    return (
      <div
        {...props}
        aria-label={getAriaLabel('Scrollbar', 'ThumbHorizontal')}
        className={styles.thumbHorizontal}
      />
    )
  }

  const renderTrackVertical = (props: ComponentType) => {
    return (
      <div
        {...props}
        aria-label={getAriaLabel('Scrollbar', 'TrackVertical')}
        className={classNames(styles.trackVertical, {
          [styles.hiddenTrack]: hideTrack,
        })}
      />
    )
  }
  const renderThumbVertical = (props: ComponentType) => {
    return (
      <div
        {...props}
        aria-label={getAriaLabel('Scrollbar', 'ThumbVertical')}
        className={styles.thumbVertical}
      />
    )
  }

  return (
    <Scrollbars
      ref={ref}
      autoHide={show}
      autoHideTimeout={300}
      autoHideDuration={200}
      style={{ width: '100%', height: props.height || '100%' }}
      renderView={renderView}
      renderTrackHorizontal={renderTrackHorizontal}
      renderThumbHorizontal={renderThumbHorizontal}
      renderTrackVertical={renderTrackVertical}
      renderThumbVertical={renderThumbVertical}
      className={styles.scrollbars}
      aria-label={getAriaLabel('Scrollbar')}
      {...props}
    >
      {children}
    </Scrollbars>
  )
}
