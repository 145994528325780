import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { logger } from 'shared/lib'
import { AdminComplianceApi, IResponseAdminTollFreeByID } from 'entities/Admin/compliance'
import { IResponseAdminOrganizationById } from 'entities/Admin'
import { ReviewContent } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/ReviewContent/ReviewContent'
import { RejectContent } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/RejectContent/RejectContent'
import { adminTollFreeBusinessContactStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/BusinessContact/store/adminTollFreeBusinessContactStore'
import { adminTollFreeCampaignStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/Campaign/store/adminTollFreeCampaignStore'
import { adminTollFreeBusinessInfoStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/BusinessInfo/store/adminTollFreeBusinessInfoStore'

class AdminTollFreeDetailStore {
  constructor() {
    makeAutoObservable(this)
  }
  id: number | null = null
  loading = false

  idReviewModal = ''
  idRejectModal = ''

  organizationById: IResponseAdminOrganizationById | null = null
  data: IResponseAdminTollFreeByID | null = null

  get isHasUnsavedChanges() {
    return (
      adminTollFreeBusinessInfoStore.hasChanges ||
      adminTollFreeBusinessContactStore.hasChanges ||
      adminTollFreeCampaignStore.hasChanges
    )
  }

  get disabledReview() {
    return (
      adminTollFreeBusinessInfoStore.isEdit ||
      adminTollFreeBusinessContactStore.isEdit ||
      adminTollFreeCampaignStore.isEdit
    )
  }

  get isActions() {
    return adminTollFreeCampaignStore.isForReview
  }

  reset = () => {
    this.data = null
    adminTollFreeBusinessInfoStore.reset()
    adminTollFreeBusinessContactStore.reset()
    adminTollFreeCampaignStore.reset()
    this.organizationById = null
  }

  getData = async (noReset?: boolean) => {
    try {
      if (!this.id) {
        return
      }
      runInAction(() => {
        if (noReset) {
          return
        }
        this.reset()
        this.loading = true
      })

      const { data } = await AdminComplianceApi.getTollFreeById(this.id)
      this.setData(data)
    } catch (e) {
      if (this.id) {
        adminTollFreeBusinessInfoStore.setBusinessProfile(this.id)
        adminTollFreeBusinessContactStore.setBrand(this.id)
        adminTollFreeCampaignStore.setCampaign(this.id)
      }

      logger.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setData = (data: IResponseAdminTollFreeByID) => {
    this.data = data
    adminTollFreeBusinessInfoStore.setBusinessProfile(this.id || 0, data.business_data)
    adminTollFreeBusinessContactStore.setBrand(this.id || 0, data.contact_person_data)
    adminTollFreeCampaignStore.setCampaign(this.id || 0, data)
    this.setOrganization(data.organization)
  }

  setId = (id: number) => {
    this.id = id
    this.getData()
  }

  onReject = async (reason: string) => {
    try {
      if (!this.data?.profile_data.id) {
        return
      }
      await AdminComplianceApi.createRejectTollFree({
        reason,
        profile_id: this.data?.profile_data.id,
      })
      await this.getData(true)
      modalStore.closeModal(this.idRejectModal)
    } catch (e) {
      logger.error(e)
    }
  }

  onReview = async () => {
    try {
      if (!this.data?.profile_data.id) {
        return
      }

      if (this.data?.number.verified_status === 'Denied') {
        if (this.data?.profile_data.is_parent) {
          await AdminComplianceApi.resubmitTollFree({
            profile_id: this.data.profile_data.id,
            number_id: this.data.number.id,
          })
        } else {
          await AdminComplianceApi.resubmitTollFree({
            profile_id: this.data.profile_data.id,
          })
        }
      } else {
        await AdminComplianceApi.createPublishTollFree(this.data?.profile_data.id)
      }

      await this.getData(true)
      modalStore.closeModal(this.idReviewModal)
    } catch (e) {
      logger.error(e)
    }
  }

  onOpenRejectModal = () => {
    this.idRejectModal = nanoid()
    modalStore.addModal({
      id: this.idRejectModal,
      title: 'Manual reject',
      pureContent: true,
      ModalContent: RejectContent,
      width: 480,
    })
  }

  onOpenReviewModal = () => {
    this.idReviewModal = nanoid()
    modalStore.addModal({
      id: this.idReviewModal,
      title: 'Review stage',
      pureContent: true,
      ModalContent: ReviewContent,
      width: 360,
    })
  }

  setOrganization = (organizationById: IResponseAdminOrganizationById) => {
    this.organizationById = organizationById
  }
}

export const adminTollFreeDetailStore = new AdminTollFreeDetailStore()
