import { makeAutoObservable } from 'mobx'
import type { IResponseComplianceSummary } from 'entities/Compliance'

export class Summary {
  private _organization_id: IResponseComplianceSummary['organization_id'] | null = null
  private _has_tax_id: IResponseComplianceSummary['has_tax_id'] | null = null
  private _target_country: IResponseComplianceSummary['target_country'] | null = null
  private _numbers_type: IResponseComplianceSummary['numbers_type'] | null = null
  private _banners_closed: IResponseComplianceSummary['banners_closed'] = false

  constructor(response: IResponseComplianceSummary) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseComplianceSummary) => {
    this._organization_id = response.organization_id
    this._has_tax_id = response.has_tax_id
    this._target_country = response.target_country
    this._numbers_type = response.numbers_type
    this._banners_closed = response.banners_closed
  }

  get organization_id() {
    return this._organization_id
  }

  get has_tax_id() {
    return this._has_tax_id
  }

  get target_country() {
    return this._target_country
  }

  get numbers_type() {
    return this._numbers_type
  }

  get isNumberAll() {
    return this._numbers_type === 'all'
  }

  get isRequiredTollFree() {
    const isCanadaTarget = this._target_country === 'CA'
    const numbersType = this._numbers_type

    if (!this._organization_id) return false
    if (isCanadaTarget) return numbersType !== 'local'

    return numbersType === 'toll_free'
  }

  get isBannersClosed() {
    return this._banners_closed
  }
}
