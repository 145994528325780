import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, TextareaWithLabel, TextFieldWithLabel } from 'shared/ui'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { useCallingStepsContext } from 'pages/settings/pages/compliance/pages/calling/context/callingStepsContext'
import { UseCaseSelect } from 'pages/settings/pages/compliance/ui/UseCaseSelect/UseCaseSelect'
import styles from './styles.module.scss'

export const CompanyInfo = observer(() => {
  const { voiceIntegrityStore } = useCallingStepsContext()

  const {
    useCaseAsItems,
    useCaseValue,
    companySize,
    setUseCase,
    callsPerDay,
    notes,
    loading,
    isSubmitDisabled,
    loadUseCases,
    setCompanySize,
    setCallsPerDay,
    setNotes,
    submitVoiceIntegrity,
  } = voiceIntegrityStore

  useEffect(() => {
    loadUseCases()
  }, [])

  return (
    <div>
      <PageDescription>Provide info about your company and calling usage</PageDescription>
      <Box marginTop={5}>
        <UseCaseSelect items={useCaseAsItems} value={useCaseValue || ''} setValue={setUseCase} />
        <TextFieldWithLabel
          marginBottom={3}
          marginTop={3}
          labelProps={{
            marginBottom: 4,
            questionTooltipProps: {
              label: 'Total number of employees',
            },
            label: {
              text: 'Company size',
            },
          }}
          textFieldProps={{
            value: companySize,
            onChange: setCompanySize,
            InputProps: {
              placeholder: 'e.g. 20',
            },
            className: styles.selectPadding,
          }}
        />
        <TextFieldWithLabel
          marginBottom={3}
          labelProps={{
            marginBottom: 4,
            questionTooltipProps: {
              label: 'Estimated daily call volume ',
            },
            label: {
              text: 'Average calls per day',
            },
          }}
          textFieldProps={{
            value: callsPerDay,
            onChange: setCallsPerDay,
            InputProps: {
              placeholder: 'e.g. 100',
            },
            className: styles.selectPadding,
          }}
        />

        <TextareaWithLabel
          labelProps={{
            label: { text: 'Notes', secondaryText: '(optional)' },
            marginBottom: 0,
          }}
          value={notes}
          errorType='bottomText'
          placeholder='Additional information about your use case'
          onChange={setNotes}
        />

        <NextButton
          text='Submit for review'
          loading={loading}
          disabled={isSubmitDisabled || loading}
          onClick={submitVoiceIntegrity}
        />
      </Box>
    </div>
  )
})
