import { observer } from 'mobx-react-lite'
import { Button, Dropdown, DropdownCard, DropdownContent, Typography } from 'shared/ui'
import { type IParamsContactsOptOut } from 'entities/Contacts/api/types'
import { successOpIinHandler } from 'entities/Contacts/lib/successOpIinHandler'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { selectedModalStore } from 'widgets/SelectContactsModal/store/SelectedModalStore'
import { contactsOptInModalStore } from 'widgets/ContactsOptInModal'
import { PowerDialerActionButton } from 'widgets/PowerDialer'
import { WorkflowEnrollAction } from 'widgets/WorkflowEnroll'
import { ChatbotEnrollAction } from 'widgets/ChatbotEnroll'
import { PowerDialerEditorAction } from 'widgets/PowerDialer/ui/PowerDialerEditor'
import { useContactsTableContext } from 'pages/contacts/ui/ContactsTable/context/contactsTableContext'

export const LeftContent = observer(() => {
  const contactsTableStore = useContactsTableContext()
  const {
    selectedContacts,
    search,
    total,
    expandedParams,
    contacts,
    activeContact,
    setInActionId,
    loadContacts,
    checkClosePanelAfterDelete,
    tableStore,
    segment,
  } = contactsTableStore
  const { bulkAllMode, selectedIds, resetSelected } = tableStore
  const { openValidateModal, openDeleteModal, deleteContacts } = selectedModalStore
  const { salesmsg_power_dialer_v2 } = featureFlagsStore

  const deleteCallback = () => {
    setInActionId(null)
  }

  const successDeleteCallback = () => {
    checkClosePanelAfterDelete()
    resetSelected()
    loadContacts()
  }

  const resetInActionId = () => {
    setInActionId(null)
  }

  const opIinHandler = (opt_outs: IParamsContactsOptOut['opt_outs']) => {
    activeContact && successOpIinHandler(activeContact, opt_outs)

    contacts.forEach((contact) => {
      if (bulkAllMode || selectedIds.includes(contact.id)) {
        successOpIinHandler(contact, opt_outs)
      }
    })
  }

  const isRequestAccess = !userPermissionsStore.getItem('can_access_phone_checker')

  const contactsData = {
    total: contactsTableStore.total,
    contactsParams: contactsTableStore.expandedParams,
    bulkAll: bulkAllMode,
    selectedIds: selectedIds.map(Number),
  }

  return (
    <>
      <Button
        text='Validate'
        icon='check1'
        typeBtn='contained'
        contained='tertiary'
        size='small'
        onClick={() => {
          openValidateModal({
            bulkAllMode,
            selectedIds: selectedIds as number[],
            search,
            filtersList: segment?.filtersList,
          })
        }}
        disabled={isRequestAccess}
        tooltipProps={
          isRequestAccess
            ? {
                placement: 'top',
                label: [
                  'You don’t have permission',
                  <br key={'br'} />,
                  <Typography
                    ariaLabel={'desc'}
                    key={'desc'}
                    tag={'div'}
                    variant={'body-sm-regular'}
                    color={'var(--content-primary-tertiary-inverted)'}
                    marginTop={2}
                  >
                    Request your Admin
                  </Typography>,
                ],
              }
            : undefined
        }
      />
      <Button
        text='Manage tags'
        icon='tag'
        typeBtn='contained'
        contained='tertiary'
        size='small'
        onClick={() => {
          contactsTableStore.contactsTagsModalStore.onOpen({
            ids: selectedIds as number[],
            isBulkAll: bulkAllMode,
            callback: loadContacts,
            searchParams: expandedParams.search,
            filtersList: expandedParams.filtersList,
          })
        }}
      />
      <WorkflowEnrollAction contactsData={contactsData} variant={'button'} />
      <ChatbotEnrollAction contactsData={contactsData} variant={'button'} />
      {!salesmsg_power_dialer_v2 && <PowerDialerActionButton contactsData={contactsData} />}
      <Dropdown
        ariaLabel={'LeftActions'}
        iconButtonProps={{ icon: 'moreHorizontal', color: 'tertiary', ariaLabel: 'LeftActions' }}
        placement='bottom-start'
        customComponent={(onCloseMenu) => (
          <DropdownContent>
            {salesmsg_power_dialer_v2 && (
              <PowerDialerEditorAction
                variant={'dropdownItem'}
                onClick={onCloseMenu}
                contactsData={contactsData}
              />
            )}
            <DropdownCard
              item={{
                id: 0,
                label: 'Opt-in status',
                iconL: 'toggle',
                onClick: () => {
                  contactsOptInModalStore.handleOpen(selectedContacts, {
                    reset: resetInActionId,
                    successHandler: opIinHandler,
                    isBulkAll: bulkAllMode,
                    filtersList: expandedParams.filtersList,
                    search,
                  })
                  onCloseMenu()
                },
              }}
            />
            <DropdownCard
              item={{
                id: 1,
                label: 'Delete',
                iconL: 'delete',
                onClick: () => {
                  const selectedIdsLength = bulkAllMode ? total : selectedIds.length

                  openDeleteModal({
                    selectedIdsLength,
                    primaryCallback: () => {
                      deleteContacts({
                        bulkAllMode,
                        ids: selectedIds as number[],
                        search,
                        filtersList: expandedParams?.filtersList,
                        successCallback: successDeleteCallback,
                        finallyCallback: deleteCallback,
                      })
                    },
                    onCloseCallback: resetInActionId,
                  })
                  onCloseMenu()
                },
              }}
            />
          </DropdownContent>
        )}
      />
    </>
  )
})
