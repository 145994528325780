import { Route, Routes } from 'react-router'
import { CallingStepsLayout } from 'pages/settings/pages/compliance/pages/calling/layout/CallingStepsLayout'
import { EnableVoiceIntegrity } from 'pages/settings/pages/compliance/pages/calling/ui/EnableVoiceIntegrity/EnableVoiceIntegrity'
import { CompanyInfo } from 'pages/settings/pages/compliance/pages/calling/ui/CompanyInfo/CompanyInfo'
import { EnableStirShaken } from 'pages/settings/pages/compliance/pages/calling/ui/EnableStirShaken/EnableStirShaken'
import { EnableCnamCallerId } from 'pages/settings/pages/compliance/pages/calling/ui/EnableCnamCallerId/EnableCnamCallerId'
import { Configure } from 'pages/settings/pages/compliance/pages/calling/ui/Configure/Configure'
import { CallingRoutesSteps } from './type'

export const CallingStepsRouter = () => {
  return (
    <Routes>
      <Route element={<CallingStepsLayout />}>
        <Route path={CallingRoutesSteps.enableVoiceIntegrity} element={<EnableVoiceIntegrity />} />
        <Route path={CallingRoutesSteps.companyInfo} element={<CompanyInfo />} />
        <Route path={CallingRoutesSteps.enableStirShaken} element={<EnableStirShaken />} />
        <Route path={CallingRoutesSteps.enableCnamCallerId} element={<EnableCnamCallerId />} />
        <Route path={CallingRoutesSteps.configure} element={<Configure />} />
      </Route>
    </Routes>
  )
}
