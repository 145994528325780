// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fCdsofbk0wO0nk5MZbQY{padding:16px 0}.zmdlu4mu_P3LhVwDNtnm{height:calc(100vh - 450px)}._dkYsEP4Nk0qGXqZRFo8{border-bottom:1px solid var(--border-neutral-default)}.Mc0Yv0DgpydLoSbsnYWr{display:flex;border-bottom:1px solid var(--border-neutral-default)}._006OqMVLlucFpjijOib{flex-direction:column;justify-content:center}.yoYUKWVvCdq90B7MLyZq{padding:16px 8px;margin-bottom:0}.qdAkdnPeWFPwGTQXJOwj{line-height:16px;margin-bottom:6px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.qdAkdnPeWFPwGTQXJOwj:last-child{margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/pages/contacts/ui/ImportContacts/ui/MapFieldsModalContent/MapFieldsTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CAEJ,sBACI,0BAAA,CAEJ,sBACI,qDAAA,CAEJ,sBACI,YAAA,CACA,qDAAA,CAEJ,sBACI,qBAAA,CACA,sBAAA,CAEJ,sBACI,gBAAA,CACA,eAAA,CAEJ,sBACI,gBAAA,CACA,iBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAEJ,iCACI,eAAA","sourcesContent":[".tableContainer {\n    padding: 16px 0;\n}\n.bodyContainer {\n    height: calc(100vh - 450px);\n}\n.header {\n    border-bottom: 1px solid var(--border-neutral-default);\n}\n.row {\n    display: flex;\n    border-bottom: 1px solid var(--border-neutral-default);\n}\n.columnHeaderCell {\n    flex-direction: column;\n    justify-content: center;\n}\n.previewCell {\n    padding: 16px 8px;\n    margin-bottom: 0;\n}\n.previewCellValue {\n    line-height: 16px;\n    margin-bottom: 6px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n.previewCellValue:last-child {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "fCdsofbk0wO0nk5MZbQY",
	"bodyContainer": "zmdlu4mu_P3LhVwDNtnm",
	"header": "_dkYsEP4Nk0qGXqZRFo8",
	"row": "Mc0Yv0DgpydLoSbsnYWr",
	"columnHeaderCell": "_006OqMVLlucFpjijOib",
	"previewCell": "yoYUKWVvCdq90B7MLyZq",
	"previewCellValue": "qdAkdnPeWFPwGTQXJOwj"
};
export default ___CSS_LOADER_EXPORT___;
