import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CancelTokenSource } from 'axios'
import { debounce } from 'lodash'
import { logger } from 'shared/lib'
import {
  Integration,
  IntegrationsApi,
  type IResponseIntegration,
  type IResponseUserIntegration,
} from 'entities/Integrations'
import { CATEGORY_LABELS } from 'entities/Integrations/lib/integrationsDescription'
import { ManageIntegrationConnectionStore } from 'features/ManageIntegrationConnection'
import { integrationSortOrder } from 'pages/settings/pages/integrations/lib/integrationSortOrder'
import { IntegrationWebhookStore } from 'pages/settings/pages/integrations/store/IntegrationWebhookStore'

export class IntegrationListStore {
  private _isCategoriesLoading = false
  private _categories: string[] = []
  private _selectedCategory = ''

  private _search = ''
  private _debouncedSearch = ''

  private _isIntegrationListLoading = false
  private _integrationsMap: Map<string, Integration> = new Map()

  private _cancelTokenSource: CancelTokenSource | null = null
  private _disposeFetchIntegrations: IReactionDisposer | null = null

  private _manageIntegrationConnectionStore = new ManageIntegrationConnectionStore()
  private _integrationWebhookStore = new IntegrationWebhookStore()

  constructor() {
    makeAutoObservable(this)

    this._reactionFetchIntegrations()
    void this._init()
  }

  private _reactionFetchIntegrations = () => {
    this._disposeFetchIntegrations?.()

    this._disposeFetchIntegrations = reaction(
      () => [this.selectedCategory, this._debouncedSearch],
      () => {
        void this._fetchIntegrations(this.selectedCategory, this._debouncedSearch)
      }
    )
  }

  private _init = async () => {
    await this._fetchCategories()
    this.setActiveCategory(this._categories[0] || '')
  }

  private _clearReactions = () => {
    this._disposeFetchIntegrations?.()
  }

  private _initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _mapIntegrations = (responseIntegrations: IResponseIntegration[]) => {
    this._integrationsMap = new Map()
    responseIntegrations
      .sort(
        (a, b) =>
          (integrationSortOrder[a.key] ?? Infinity) - (integrationSortOrder[b.key] ?? Infinity)
      )
      .forEach((integrationResponse) => {
        const integration = new Integration(integrationResponse)
        this._integrationsMap.set(integration.key, integration)
      })
  }

  private _fetchIntegrations = async (category?: string, search?: string) => {
    try {
      this._initCancelTokenSource()

      runInAction(() => {
        this._isIntegrationListLoading = true
      })

      const {
        data: { data: integrations },
      } = await IntegrationsApi.getIntegrations(
        { category, search },
        {
          cancelToken: this._cancelTokenSource?.token,
        }
      )

      this._mapIntegrations(integrations)
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._isIntegrationListLoading = false
      })
    }
  }

  private _fetchCategories = async () => {
    try {
      runInAction(() => {
        this._isCategoriesLoading = true
      })

      const {
        data: { data },
      } = await IntegrationsApi.getIntegrationsCategory()
      runInAction(() => {
        this._categories = data
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._isCategoriesLoading = false
      })
    }
  }

  setActiveCategory = (categoryKey: string) => {
    this._selectedCategory = categoryKey
    this._search = ''
    this._debouncedSearch = ''
  }

  private _setDebouncedSearch = debounce((search: string) => {
    this._debouncedSearch = search
  }, 500)

  setSearch = (search: string) => {
    this._search = search
    this._setDebouncedSearch(search)
  }

  onDisconnect = async (integration: Integration) => {
    await this._manageIntegrationConnectionStore.disconnect(integration)
  }

  onViewWebhook = async (integration: Integration) => {
    this._integrationWebhookStore.openIntegrationWebhookModal(integration)
  }

  syncUserIntegration = (userIntegrationResponse: IResponseUserIntegration) => {
    this._integrationsMap
      .get(userIntegrationResponse.integration.key)
      ?.syncUserIntegration(userIntegrationResponse)
  }

  get isIntegrationListLoading() {
    return this._isIntegrationListLoading
  }

  get integrationList() {
    return Array.from(this._integrationsMap.values()).filter((integration) => integration.is_active)
  }

  get isEmptyList() {
    return (
      !this.isCategoriesLoading && !this.isIntegrationListLoading && !this.integrationList.length
    )
  }

  get categories(): {
    key: string
    label: string
  }[] {
    return this._categories.map((key) => ({
      key,
      label: CATEGORY_LABELS[key] ?? key,
    }))
  }

  get selectedCategory() {
    return this._selectedCategory
  }

  get isCategoriesLoading() {
    return this._isCategoriesLoading
  }

  get search() {
    return this._search
  }

  get currentListTitle() {
    if (this._search !== '') {
      return 'Search results'
    }
    return CATEGORY_LABELS[this._selectedCategory]
  }

  get availableIntegrationsForWebhook() {
    return this._integrationWebhookStore.availableIntegrationsForWebhook
  }

  dispose = () => {
    this._cancelTokenSource?.cancel()
    this._clearReactions()
    this._integrationWebhookStore.dispose()
  }
}
