import { useEffect } from 'react'
import { useBlocker } from 'react-router'
import { useParams, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import {
  ChatbotButtonGroup,
  ChatbotCancelButton,
  ChatbotDialog,
  ChatbotLayout,
  ChatbotLayoutContent,
  ChatbotLayoutFooter,
  ChatbotLayoutHeader,
  ChatbotLayoutWrapper,
  ChatbotPublishButton,
  ChatbotSaveButton,
  DialogLayout,
  RootLayout,
} from 'pages/chatbot/ui'
import {
  ChatbotDialogProvider,
  ChatbotProvider,
  useChatbotContext,
  useChatbotDialogContext,
} from 'pages/chatbot/context'
import { ChatbotBody, ChatbotHeader } from 'pages/chatbot/ui/ChatbotEdit'
import { useChatbotKnowledgeBaseUpdate } from 'pages/chatbot/events/useChatbotKnowledgeBaseUpdate'
import { ChatbotEditHead } from './ChatbotEditHead'

const ChatbotEdit = observer(() => {
  const { rag } = featureFlagsStore
  const { start } = useChatbotDialogContext()
  const {
    loaded,
    enrollment,
    isDraggingCondition,
    initResources,
    loadChatbot,
    confirmUpdateChatbot,
    checkChanges,
    knowledgeBaseStore: { updateKnowledgeBase, initKnowledgeBase },
  } = useChatbotContext()

  const { state } = useLocation()
  const { chatbotId } = useParams()
  const blocker = useBlocker(() => {
    const hasChanges = checkChanges()

    return hasChanges
  })

  useEffect(() => {
    if (blocker.state === 'blocked') confirmUpdateChatbot(blocker)
  }, [blocker.state])

  useEffect(() => {
    initResources()
    if (rag) initKnowledgeBase()
    enrollment.initFilters()

    if (!chatbotId) return

    loadChatbot(+chatbotId).then((loaded) => {
      if (loaded && state?.test) start()
    })
  }, [])

  useChatbotKnowledgeBaseUpdate(updateKnowledgeBase, rag)

  return (
    <>
      {!loaded ? (
        <SpinnerLoader />
      ) : (
        <ChatbotLayout>
          <ChatbotLayoutWrapper>
            <ChatbotLayoutHeader>
              <ChatbotEditHead />
              <ChatbotHeader />
            </ChatbotLayoutHeader>
            <ChatbotLayoutContent isDraggingCondition={isDraggingCondition}>
              <ChatbotBody />
            </ChatbotLayoutContent>
          </ChatbotLayoutWrapper>
          <ChatbotLayoutFooter>
            <ChatbotButtonGroup>
              <ChatbotSaveButton />
              <ChatbotCancelButton />
            </ChatbotButtonGroup>
            <ChatbotButtonGroup>
              <ChatbotPublishButton />
            </ChatbotButtonGroup>
          </ChatbotLayoutFooter>
        </ChatbotLayout>
      )}
    </>
  )
})

const ChatbotEditDialog = observer(() => {
  const { loaded } = useChatbotContext()
  const { started } = useChatbotDialogContext()

  if (!loaded) return null

  return (
    <DialogLayout active={started}>
      <ChatbotDialog />
    </DialogLayout>
  )
})

export const ChatbotEditPage = () => (
  <ChatbotProvider>
    <ChatbotDialogProvider>
      <RootLayout>
        <ChatbotEdit />
        <ChatbotEditDialog />
      </RootLayout>
    </ChatbotDialogProvider>
  </ChatbotProvider>
)
