import { makeAutoObservable, reaction, type IReactionDisposer } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalType, ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import { type PageLayoutStore } from 'shared/layout'
import { ContactsApi } from 'entities/Contacts'
import { ContactsTagsModalStore } from 'widgets/ContactsTagsModal'
import { History } from 'pages/contacts/pages/history/model/History'
import { HistoryModalContent } from 'pages/contacts/pages/history/ui/HistoryModalContent'
import { ImportContactsStore } from 'pages/contacts/ui/ImportContacts'

type IIModalProps = {
  title: string
  width: number
  desc?: ModalType['desc']
  ModalContent?: ModalType['ModalContent']
}

export class HistoryPageStore {
  private _importContactsStore = new ImportContactsStore()
  private _historiesMap: Map<number, History> = new Map()
  private _deletedHistoriesMap: Map<number, History> = new Map()
  private _modalId = ''
  private _disposeChangePagination: IReactionDisposer | null = null
  private _contactsTagsModalStore = new ContactsTagsModalStore()

  constructor(private _pageLayoutStore: PageLayoutStore) {
    makeAutoObservable(this)

    this._importContactsStore.setConfig({
      addItem: (item: History) => {
        this.addHistory(item)
      },
      reset: () => {
        this.resetHistories()
      },
    })

    this.reactionChangePagination()
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get importContactsStore() {
    return this._importContactsStore
  }

  get contactsTagsModalStore() {
    return this._contactsTagsModalStore
  }

  get historyData() {
    return Array.from(this._historiesMap.values())
  }

  get contactsImportModuleStore() {
    return this._importContactsStore.contactsImportModuleStore
  }

  get totalHistory() {
    return this.contactsImportModuleStore.totalHistory
  }

  get pagination() {
    return this.contactsImportModuleStore.paginationData
  }

  get isLoading() {
    return this.contactsImportModuleStore.loading
  }

  get isNoImportsYet() {
    return !this.isLoading && !this._historiesMap.size
  }

  get pendingId() {
    const pendingHistory = this.historyData.find(
      (history) => history.createdAt && history.finishedAt === null
    )

    return this.historyData.length === 1 && pendingHistory ? pendingHistory.id : null
  }

  checkIsExistHistory = (id: number) => {
    return this._historiesMap.has(id)
  }

  dispose = () => {
    this.contactsImportModuleStore.dispose()
    this._historiesMap.clear()
    this._deletedHistoriesMap.clear()
    this._disposeChangePagination?.()
  }

  getDuplicateReasons = (id: number) => {
    return this._historiesMap.get(id)?.duplicateReasons || []
  }

  getSkippedReasons = (id: number) => {
    return this._historiesMap.get(id)?.skippedReasons || []
  }

  addHistory = (history: History) => {
    this._historiesMap.set(history.id, history)
  }

  getImportContactProgress = async (id: number) => {
    await this.contactsImportModuleStore.getImportContactProgress(id, (history) => {
      this.addHistory(history)
    })
  }

  resetHistories = () => {
    this._historiesMap.clear()
  }

  loadImportHistory = async () => {
    await this.contactsImportModuleStore.loadImportHistory(
      () => {
        this.resetHistories()
      },
      (history) => {
        this.addHistory(history)
      }
    )
  }

  deleteHistoryLocal = (id: number) => {
    this._historiesMap.delete(id)
  }

  deleteHistory = (history: History) => {
    this._deletedHistoriesMap.set(history.id, history)

    const timeoutId = setTimeout(async () => {
      await ContactsApi.deleteImportHistory(history.id)
      this._deletedHistoriesMap.delete(history.id)
      this.contactsImportModuleStore.setTotal(this.totalHistory - 1)
    }, 5000)

    this.deleteHistoryLocal(history.id)

    const toastId = nanoid()

    toastStore.add({
      id: toastId,
      title: 'Import record deleted',
      type: 'info',
      action: {
        text: 'Undo',
        onAction: () => {
          clearTimeout(timeoutId)
          const deletedHistory = this._deletedHistoriesMap.get(history.id)
          if (deletedHistory) {
            this.addHistory(deletedHistory)

            const sortedHistories = this.historyData.sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            })

            this._historiesMap = new Map(sortedHistories.map((history) => [history.id, history]))
            this._deletedHistoriesMap.delete(history.id)
            toastStore.remove(toastId)
          }
        },
      },
    })
  }

  handleClose = () => {
    modalStore.removeModal(this._modalId)
  }

  openModal = (props: IIModalProps) => {
    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.DEFAULT,
      showHeader: true,
      showCloseButton: false,
      showCloseIcon: true,
      ...props,
    })
  }

  openDuplicateModal = async (id: number) => {
    this._modalId = 'duplicate_modal'

    this.openModal({
      title: 'Duplicate numbers',
      width: 520,
      ModalContent: () => <HistoryModalContent data={this.getDuplicateReasons(id)} />,
    })
  }

  openSkippedModal = async (id: number) => {
    this._modalId = 'skipped_modal'

    this.openModal({
      title: 'Skipped numbers',
      width: 520,
      ModalContent: () => <HistoryModalContent data={this.getSkippedReasons(id)} />,
    })
  }

  changePagination = (page: number, limit: number) => {
    this.contactsImportModuleStore.changePagination(page, limit)
  }

  reactionChangePagination = () => {
    this._disposeChangePagination?.()
    this._disposeChangePagination = reaction(
      () => this.contactsImportModuleStore.paginationData,
      () => {
        this.loadImportHistory()
      }
    )
  }
}
