import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { toastStore } from 'shared/ui'
import { logger } from 'shared/lib'
import { campaignActiveFilter, CampaignApi } from 'entities/Campaign'
import { EnrollInCampaignModalStore } from 'entities/Campaign/ui/EnrollInCampaignModal/store/EnrollInCampaignModalStore'
import { type IContactsDataParams } from 'entities/Contacts/api/types'

export class EnrollInCampaignActionStore {
  constructor() {
    makeAutoObservable(this)
  }

  loading = false
  setLoading = (value: boolean) => {
    this.loading = value
  }

  modalId = nanoid()

  onAction = async (params: IContactsDataParams) => {
    try {
      this.setLoading(true)
      const { data } = await CampaignApi.getList({
        search: '',
        limit: 1,
        page: 1,
        filters: campaignActiveFilter,
      })
      if (!data.meta.total) {
        toastStore.add({
          type: 'error',
          title: 'No active campaigns',
        })
        return
      }
      new EnrollInCampaignModalStore(params)
    } catch (e) {
      logger.error(e)
    } finally {
      this.setLoading(false)
    }
  }
}
