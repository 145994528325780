import { ReactNode, FC } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import {
  Dropdown,
  DropdownGroup,
  DropdownTextInput,
  type IDropdownItem,
  DropdownContent,
} from '../Dropdown'
import { type ITextFieldProps, makeTextFieldIcon } from '../TextField'
import { Error } from '../Error'

interface ISearchDropdownInputProps {
  items: IDropdownItem[]
  tipTitle?: string
  search: string
  width?: number
  errorMessageValue?: string
  textFieldProps?: ITextFieldProps
  customDropdownItem?: (item: IDropdownItem, parent?: IDropdownItem) => ReactNode
  onChangeSearch: (value: string) => void
  onSetValue: (item: IDropdownItem, parent?: IDropdownItem) => void
  isNoResults?: boolean
  noResultsMessage?: ReactNode
  onClose?: () => void
}

export const SearchDropdownInput: FC<ISearchDropdownInputProps> = ({
  items,
  tipTitle,
  search,
  width,
  errorMessageValue,
  textFieldProps,
  onChangeSearch,
  onSetValue,
  isNoResults = false,
  noResultsMessage,
  onClose,
}) => {
  return (
    <>
      <Dropdown
        {...(width ? { width } : {})}
        margin={0}
        placement='bottom'
        onClose={onClose}
        triggerComponent={(open) => (
          <DropdownTextInput
            dropdownTextInputClassName={styles.textInput}
            errorMessageValue={errorMessageValue}
            withFocus={false}
            textFieldProps={{
              className: classNames(styles.input, {
                [styles.inputError]: errorMessageValue && !open,
              }),
              value: search,
              onChange: onChangeSearch,
              InputProps: {
                placeholder: 'Search',
                ...textFieldProps?.InputProps,
              },
              leftActions: [
                makeTextFieldIcon({
                  icon: 'search',
                  color: 'var(--content-primary-tertiary)',
                  fontSize: 16,
                }),
              ],
              ...textFieldProps,
            }}
          />
        )}
        customComponent={(onClose) => (
          <DropdownContent noPadding>
            <DropdownTextInput
              textFieldProps={{
                value: search,
                onChange: onChangeSearch,
                InputProps: {
                  placeholder: 'Search',
                },
                className: styles.dropdownSearchInput,
                leftActions: [
                  makeTextFieldIcon({
                    icon: 'search',
                    color: 'var(--content-primary-tertiary)',
                    fontSize: 16,
                  }),
                ],
              }}
              ariaLabel='searchEin'
            />
            {!isNoResults ? (
              <DropdownGroup
                title={!items.length && tipTitle ? tipTitle : ''}
                items={items}
                onChange={(item) => {
                  onClose()
                  onSetValue(item)
                }}
              />
            ) : (
              <div>{noResultsMessage}</div>
            )}
          </DropdownContent>
        )}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: () => {
                  return [0, -40]
                },
              },
            },
          ],
        }}
      />
      <Error type='text' error={errorMessageValue} size='small' wrapClassName={styles.error} />
    </>
  )
}
