import { makeAutoObservable, reaction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import { TableStore } from 'shared/ui'
import { logger } from 'shared/lib'
import {
  type Campaign,
  CampaignApi,
  type ICampaignExecutionFilter,
  type ICreateCampaignStep,
  type IParamsExecutions,
  type IResponseExecutions,
} from 'entities/Campaign'
import { ContactsTagsModalStore } from 'widgets/ContactsTagsModal'
import type { IOpenContactsDetails } from 'widgets/ContactsDetails'
import { ContactsDetailsManageStore } from 'widgets/ContactsDetails/store'
import { CampaignExecution } from './CampaignExecution'

export class CampaignContactsStatisticsTableStore {
  tableStore = new TableStore<CampaignExecution>({
    element: 'contact',
    withoutDefaultManageColumns: true,
  })
  contactsTagsModalStore = new ContactsTagsModalStore()
  private _contactsDetailsManageStore = new ContactsDetailsManageStore()
  _disposeParamsGetItems
  _disposeParamsWithoutPage
  constructor(private campaign: Campaign, private _step?: ICreateCampaignStep) {
    makeAutoObservable(this)
    this.loadData()
    this._disposeParamsGetItems = reaction(
      () => this.paramsGetItems,
      () => this.loadData(),
      {
        delay: 500,
      }
    )
    this._disposeParamsWithoutPage = reaction(
      () => this.paramsWithoutPage,
      () => {
        this.setLoading(true)
        this.page = 1
      }
    )
  }

  dispose = () => {
    this._disposeParamsGetItems()
    this._disposeParamsWithoutPage()
  }

  filterState: ICampaignExecutionFilter | null = null
  setFilterState = (value: typeof this.filterState) => {
    this.filterState = value
  }

  get paramsWithoutPage(): Omit<IParamsExecutions, 'page'> {
    return {
      id: this.campaign.id,
      limit: this.limit,
      'filters[state]': this.filterState || undefined,
      'filters[min_steps_count]': this._step?.order || undefined,
    }
  }

  get paramsGetItems(): IParamsExecutions {
    return {
      ...this.paramsWithoutPage,
      page: this.page,
    }
  }

  limit = 25
  page = 1
  onPaginationModelChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }
  search = ''
  setSearch = (value: string) => {
    this.search = value
  }
  total = 0
  scrollToTopTrigger = ''

  loading = true
  setLoading = (value: boolean) => {
    this.loading = value
  }

  private _cancelTokenSource: CancelTokenSource | null = null
  private initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }
  private loadData = async () => {
    try {
      this.initCancelTokenSource()
      this.setLoading(true)
      const { data } = await CampaignApi.getExecutions(this.paramsGetItems, {
        cancelToken: this._cancelTokenSource?.token,
      })
      this._setData(data)
    } catch (e) {
      logger.error(e)
      this.setLoading(e instanceof CanceledError)
    } finally {
      this.setLoading(false)
    }
  }

  itemsMap: Map<number, CampaignExecution> = new Map()

  get items() {
    return Array.from(this.itemsMap.values())
  }

  private _setData({ data, meta }: IResponseExecutions) {
    this.itemsMap.clear()
    data.forEach((item) => {
      this.itemsMap.set(item.id, new CampaignExecution(item))
    })
    this.total = meta.total
  }

  onSuccessDelete = () => {
    this.tableStore.unselectAllIds()
    void this.loadData()
  }

  get onOpenContactsDetails(): IOpenContactsDetails {
    return {
      disabled: this._contactsDetailsManageStore.disabled,
      open: (data) => {
        this._contactsDetailsManageStore.onOpenContactDetails(
          {
            contactId: data.contactId,
          },
          'campaign',
          false
        )
      },
    }
  }

  get isNoData() {
    return !this.loading && !this.itemsMap.size && !this.search
  }

  get variant(): 'overview' | 'step' {
    if (this._step) {
      return 'step'
    }
    return 'overview'
  }

  get isShowReply() {
    return this.variant === 'overview' && !!this.campaign.settings?.unenroll_on_response
  }

  get contactsDetailsStore() {
    return this._contactsDetailsManageStore.contactsDetailsStore
  }
}
