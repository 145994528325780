import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { uiStore } from 'shared/store/uiStore'
import { Actions, variantActionsProps } from 'shared/ui'
import { contactsStore } from 'entities/Contacts'
import { BroadcastsRoutes } from 'entities/ExportRouters'
import { EnrollInCampaignAction } from 'entities/Campaign/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { selectedModalStore } from 'widgets/SelectContactsModal/store/SelectedModalStore'
import { contactsOptInModalStore } from 'widgets/ContactsOptInModal'
import { type ContactsTagsModalStore } from 'widgets/ContactsTagsModal'
import { PowerDialerActionButton, PowerDialerActionDropdownItem } from 'widgets/PowerDialer'
import { PowerDialerEditorAction } from 'widgets/PowerDialer/ui/PowerDialerEditor'
import { ChatbotEnrollAction } from 'widgets/ChatbotEnroll'
import { WorkflowEnrollAction } from 'widgets/WorkflowEnroll'

type ContactStatisticBulkActionsProps = {
  search: string
  selectedContactIds: (string | number)[]
  onSuccessDelete: () => void
  contactsTagsModalStore: ContactsTagsModalStore
  withNewActions?: boolean
}

export const ContactStatisticBulkActions: FC<ContactStatisticBulkActionsProps> = observer(
  ({ search, selectedContactIds, onSuccessDelete, contactsTagsModalStore, withNewActions }) => {
    const { openValidateModal, openDeleteModal, deleteContacts } = selectedModalStore
    const selectedIds = selectedContactIds.map(Number)

    const { campaigns, salesmsg_power_dialer_v2, chatbot, workflows } = featureFlagsStore

    const onManageTags = () => {
      contactsTagsModalStore.onOpen({
        ids: selectedIds as number[],
        searchParams: search,
      })
    }

    const onSendBroadcast = () => {
      uiStore.changeRoute({
        path: `${BroadcastsRoutes.root}/${BroadcastsRoutes.create}`,
        options: {
          state: {
            contact_ids: selectedIds,
          },
        },
      })
    }

    const onValidate = () => openValidateModal({ selectedIds: selectedIds as number[], search })

    const onOptIn = async () => {
      const contacts = await contactsStore.getByIds(selectedIds as number[])
      if (contacts) {
        contactsOptInModalStore.handleOpen(contacts, {
          isBulkAll: false,
          search,
        })
      }
    }

    const onDelete = () => {
      const selectedIdsLength = selectedIds.length

      void openDeleteModal({
        selectedIdsLength,
        primaryCallback: () =>
          deleteContacts({
            bulkAllMode: false,
            ids: selectedIds as number[],
            search,
            successCallback: onSuccessDelete,
          }),
      })
    }

    const contactsData = {
      selectedIds,
    }

    return (
      <Actions
        {...variantActionsProps.headerSmall}
        showItems={4}
        actions={[
          {
            icon: 'tag',
            text: 'Manage tags',
            onAction: onManageTags,
          },
          {
            icon: 'broadcasts',
            text: 'Send broadcast',
            onAction: onSendBroadcast,
          },
          withNewActions &&
            chatbot && {
              buttonComponent: (
                <ChatbotEnrollAction contactsData={contactsData} variant={'button'} />
              ),
              dropdownItemComponent: (onCloseMenu) => (
                <ChatbotEnrollAction
                  variant={'dropdownItem'}
                  onClick={onCloseMenu}
                  contactsData={contactsData}
                />
              ),
            },
          withNewActions &&
            (salesmsg_power_dialer_v2
              ? {
                  buttonComponent: (
                    <PowerDialerEditorAction variant={'button'} contactsData={contactsData} />
                  ),
                  dropdownItemComponent: (onCloseMenu) => (
                    <PowerDialerEditorAction
                      variant={'dropdownItem'}
                      onClick={onCloseMenu}
                      contactsData={contactsData}
                    />
                  ),
                }
              : {
                  buttonComponent: <PowerDialerActionButton contactsData={contactsData} />,
                  dropdownItemComponent: (onCloseMenu) => (
                    <PowerDialerActionDropdownItem onClick={onCloseMenu} />
                  ),
                }),
          withNewActions &&
            campaigns && {
              buttonComponent: (
                <EnrollInCampaignAction variant={'button'} contactsData={contactsData} />
              ),
              dropdownItemComponent: (onCloseMenu) => (
                <EnrollInCampaignAction
                  variant={'dropdownItem'}
                  onClick={onCloseMenu}
                  contactsData={contactsData}
                />
              ),
            },
          {
            icon: 'check1',
            text: 'Validate',
            onAction: onValidate,
          },
          withNewActions &&
            workflows && {
              buttonComponent: (
                <WorkflowEnrollAction contactsData={contactsData} variant={'button'} />
              ),
              dropdownItemComponent: (onCloseMenu) => (
                <WorkflowEnrollAction
                  variant={'dropdownItem'}
                  onClick={onCloseMenu}
                  contactsData={contactsData}
                />
              ),
            },
          {
            icon: 'toggle',
            text: 'Opt-in status',
            onAction: onOptIn,
          },
          {
            icon: 'delete',
            text: 'Delete',
            onAction: onDelete,
          },
        ]}
      />
    )
  }
)
