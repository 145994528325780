// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lpSalimAvanQuz7HPFt8{display:flex;align-items:center;gap:8px;margin-right:24px}", "",{"version":3,"sources":["webpack://./src/widgets/PowerDialer/ui/PowerDialerEditor/ui/Actions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,iBAAA","sourcesContent":[".dropdownLabel {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownLabel": "lpSalimAvanQuz7HPFt8"
};
export default ___CSS_LOADER_EXPORT___;
