import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { FullPageWrapper, Icon, SpinnerLoader, Typography } from 'shared/ui'
import DragAndDropFile from 'shared/ui/DragAndDropFile/DragAndDropFile'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBase/context/knowledgeBaseViewContext'
import { DocumentsTable } from 'pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/ui/DocumentsList/DocumentsTable'
import { KnowledgeBaseNotifications } from 'pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/ui/KnowledgeBaseNotifications/KnowledgeBaseNotifications'
import { KnowledgeBaseEmpty } from 'pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/ui/KnowledgeBaseEmpty/KnowledgeBaseEmpty'
import { KnowledgeBaseHeader } from 'pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/ui/KnowledgeBaseHeader/KnowledgeBaseHeader'
import styles from './styles.module.scss'

export const KnowledgeBase = observer(() => {
  const {
    isUploadDisabled,
    isPageSpinnerShown,
    documentsStore: { isEmpty, onUploadDocuments },
    isTypeCollapse,
  } = useKnowledgeBaseViewContext()

  const getDropZoneOverlayInfo = () => {
    return (
      <div className={styles.dropZoneInfo}>
        <Icon
          fontSize={24}
          icon='upload'
          color={'var(--content-brand-primary)'}
          padding='0 0 20px 0'
        />
        <Typography
          variant={'body-rg-medium'}
          ariaLabel={'DragAndDropOverlayHeader'}
          marginBottom={'4px'}
        >
          Drop file here to upload
        </Typography>
        <Typography
          variant={'body-md-regular'}
          ariaLabel={'DragAndDropOverlayHeader'}
          color={'tertiary'}
        >
          PDF, MD, TXT, DOC, DOCX, HTML, CSV, XLS, XLSX
        </Typography>
      </div>
    )
  }

  if (isPageSpinnerShown) {
    return <SpinnerLoader />
  }

  const getContent = () => {
    if (isEmpty) {
      return <KnowledgeBaseEmpty />
    } else {
      return <DocumentsTable />
    }
  }

  return (
    <FullPageWrapper
      className={classNames(styles.container, {
        [styles['container--typeCollapse']]: isTypeCollapse,
      })}
    >
      <div
        className={classNames(styles.content, {
          [styles.isEmptyContent]: isEmpty,
        })}
      >
        {(!isEmpty || isTypeCollapse) && <KnowledgeBaseHeader />}
        <KnowledgeBaseNotifications />
        {getContent()}
        {!isUploadDisabled && (
          <DragAndDropFile
            dropZoneOverlayInfo={getDropZoneOverlayInfo()}
            onDrop={(files: File[]) => onUploadDocuments(files)}
            className={styles.dropZoneContainer}
          />
        )}
      </div>
    </FullPageWrapper>
  )
})
