import React, { useEffect, useMemo, useState } from 'react'
import { Navigate, useParams } from 'react-router'
import { SpinnerLoader } from 'shared/ui'
import { logger } from 'shared/lib'
import type { IResponseKeyword } from 'entities/Keywords/api/type'
import { KeywordsApi } from 'entities/Keywords/api/keywords'
import { KeywordsRoutes } from 'pages/keywords'
import { KeywordViewStore } from 'pages/keywords/store'
import { EditKeywordView } from './EditKeywordView'

const SuccessContent = ({
  keywordResponse,
  isDuplicate,
}: {
  keywordResponse: IResponseKeyword
  isDuplicate?: boolean
}) => {
  const store = useMemo(() => new KeywordViewStore(keywordResponse, isDuplicate), [])
  return <EditKeywordView store={store} />
}

type IEditKeyword = {
  isDuplicate?: boolean
}

export const EditKeyword = ({ isDuplicate }: IEditKeyword) => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [keywordResponse, setKeywordResponse] = useState<IResponseKeyword | null>(null)

  useEffect(() => {
    if (id) {
      KeywordsApi.getKeywordById(id)
        .then((res) => {
          if (res) {
            setKeywordResponse(res.data)
          }
        })
        .catch(logger.error)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [id])

  if (isLoading) {
    return <SpinnerLoader />
  }
  if (keywordResponse) {
    return <SuccessContent keywordResponse={keywordResponse} isDuplicate={isDuplicate} />
  }
  return <Navigate to={`/${KeywordsRoutes.root}`} />
}
