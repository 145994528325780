import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch } from 'shared/ui'
import { IntegrationSyncDropdown } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSyncDropdown/IntegrationSyncDropdown'
import { InboxesTeamSelect } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/ui/IntegrationHubspotSettingsSync/InboxesTeamSelect'
import { type IntegrationHubspotSettingsSyncStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsSyncStore'
import { TitledRow } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/TitledRow/TitledRow'
import styles from './styles.module.scss'

const HUBSPOT_CHECKBOXES = [
  {
    title: 'Contact ownership sync',
    label: 'Update the contact owner in HubSpot if the contact owner is updated on Salesmsg.',
    field: 'update_owner',
  },
  {
    title: 'Contact property sync',
    label: 'If contact is updated in Salesmsg, then update contact in HubSpot',
    field: 'update_contacts',
  },
  {
    title: 'Contact creation',
    label:
      "If contact doesn't exist in HubSpot, then create a new contact if contact is added to Salesmsg",
    field: 'create_non_existing_contacts',
  },
] as const

type IIntegrationHubspotSettingsSyncProps = {
  store: IntegrationHubspotSettingsSyncStore
}

export const IntegrationHubspotSettingsSync: React.FC<IIntegrationHubspotSettingsSyncProps> =
  observer(({ store }) => {
    const { isTwoWaySync, setSyncType, syncType, setCheckbox, getCheckbox, selectItems } = store

    return (
      <div className={styles.root}>
        <IntegrationSyncDropdown
          integrationIcon={'integrationHubspot'}
          isTwoWaySync={isTwoWaySync}
          syncType={syncType}
          setSyncType={setSyncType}
          selectItems={selectItems}
        />
        {isTwoWaySync &&
          HUBSPOT_CHECKBOXES.map(({ field, title, label }) => (
            <TitledRow key={field} title={title} label={label} verticalAlign={'start'}>
              <Switch size='middle' value={getCheckbox(field)} onChange={setCheckbox(field)} />
            </TitledRow>
          ))}
        <TitledRow
          title={'Fallback inbox'}
          label={'If HubSpot contact owner is not recognized, send from this inbox'}
          align={'normal'}
        >
          <InboxesTeamSelect />
        </TitledRow>
      </div>
    )
  })
