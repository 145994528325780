import { createContext, useContext, ReactNode, FC } from 'react'
import { ContactsPageLayoutStore } from 'pages/contacts/store/contactsPageLayoutStore'

export const ContactsPageLayoutContext = createContext<ContactsPageLayoutStore | null>(null)

export const useContactsPageLayoutContext = () => {
  const context = useContext(ContactsPageLayoutContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ContactsPageLayoutContext')

  return context
}

type IProviderContactsPageLayoutProps = {
  value: ContactsPageLayoutStore
  children: ReactNode
}

export const ProviderContactsPageLayout: FC<IProviderContactsPageLayoutProps> = ({
  value,
  children,
}) => {
  return (
    <ContactsPageLayoutContext.Provider value={value}>
      {children}
    </ContactsPageLayoutContext.Provider>
  )
}
