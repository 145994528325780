import { observer } from 'mobx-react-lite'
import { Icon } from 'shared/ui'
import { useCampaignDetailContext } from 'pages/campaigns/ui/detail/CampaignDetail/context/context'
import { StepUi } from './StepUi/StepUi'

export const OverviewStep = observer(() => {
  const { activeStep, setActiveStep } = useCampaignDetailContext()
  return (
    <StepUi
      title={'Overview'}
      isActive={activeStep === null}
      setActiveStep={() => setActiveStep(null)}
      leftContent={<Icon icon={'lines'} fontSize={12} />}
    />
  )
})
