import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import type {
  IParamsCreateCampaign,
  IParamsEnrolmentCampaign,
  IParamsExecutions,
  IParamsGetList,
  IParamsSaveColumns,
  IResponseCreateCampaign,
  IResponseEnrolmentCampaign,
  IResponseExecutions,
  IResponseGetById,
  IResponseGetFilters,
  IResponseGetList,
  IResponseSaveColumns,
} from './types'

class Api {
  create(params: IParamsCreateCampaign): Promise<AxiosResponse<IResponseCreateCampaign>> {
    return baseApi.post('campaign/campaigns', params)
  }

  update(
    id: number,
    params: IParamsCreateCampaign
  ): Promise<AxiosResponse<IResponseCreateCampaign>> {
    return baseApi.put(`campaign/campaigns/${id}`, params)
  }

  patch(
    id: number,
    params: Partial<IParamsCreateCampaign>
  ): Promise<AxiosResponse<IResponseCreateCampaign>> {
    return baseApi.patch(`campaign/campaigns/${id}`, params)
  }

  getList(
    params: IParamsGetList,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseGetList>> {
    return baseApi.post('campaign/campaigns/list', params, { ...options })
  }

  getFilters(): Promise<AxiosResponse<IResponseGetFilters>> {
    return baseApi.get('campaign/campaigns/filters')
  }

  enrolmentCampaign({
    id,
    data,
  }: IParamsEnrolmentCampaign): Promise<AxiosResponse<IResponseEnrolmentCampaign>> {
    return baseApi.post(`campaign/campaigns/${id}/enroll`, data)
  }

  saveColumns = (params: IParamsSaveColumns): Promise<AxiosResponse<IResponseSaveColumns>> => {
    return baseApi.post('users/ui-settings/sync', params)
  }
  getColumns = (): Promise<AxiosResponse<IResponseSaveColumns>> => {
    return baseApi.get('users/ui-settings/campaigns-table-columns')
  }

  getById(id: number | string): Promise<AxiosResponse<IResponseGetById>> {
    return baseApi.get(`campaign/campaigns/${id}`)
  }

  getExecutions(
    { id, ...params }: IParamsExecutions,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseExecutions>> {
    return baseApi.get(`campaign/campaigns/${id}/executions`, {
      params,
      ...options,
    })
  }

  delete(id: number): Promise<AxiosResponse<void>> {
    return baseApi.delete(`campaign/campaigns/${id}`)
  }
}

export const CampaignApi = new Api()
