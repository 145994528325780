import { FC, useState, ReactNode } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Icon, type IIconsVector } from 'shared/ui/Icon'
import { IconButton } from 'shared/ui/IconButton'
import { Typography } from 'shared/ui/Typography'
import { Button } from './Button/Button'
import styles from './styles.module.scss'

export enum EnumAlertBannerVariant {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  InfoLight = 'info-light',
  Success = 'success',
}

export type IAlertBannerProps = {
  variant?: EnumAlertBannerVariant
  title: ReactNode
  actionPrimary?: {
    text: string
    onAction?: () => void
  }
  actionSecondary?: {
    text: string
    onAction?: () => void
  }
  actionClose?: {
    onAction?: () => void
    closeId?: string
    showCloseBtn?: boolean
  }
  className?: string
  ariaLabel: string
}

export const AlertBanner: FC<IAlertBannerProps> = observer(
  ({ title, variant, className, ariaLabel, actionPrimary, actionSecondary, actionClose }) => {
    const { closeId, showCloseBtn } = actionClose || {}
    const showClose = Boolean(closeId) || showCloseBtn

    const isCloseLocalStorage = closeId
      ? Boolean(window.localStorage.getItem(`alert_banner_hide_${closeId}`))
      : false
    const [isClose, setIsClose] = useState(isCloseLocalStorage)

    const onClose = () => {
      actionClose?.onAction?.()

      if (closeId) {
        window.localStorage.setItem(`alert_banner_hide_${closeId}`, '1')
      }
      setIsClose(true)
    }

    if (isClose) return null

    variant = variant || EnumAlertBannerVariant.Info
    let icon: IIconsVector = 'infoCircle'
    let color = 'var(--content-primary-primary-inverted)'
    title = title || ''

    if (variant === EnumAlertBannerVariant.Info) {
      icon = 'infoCircle'
      color = 'var(--content-primary-primary-inverted)'
    }
    if (variant === EnumAlertBannerVariant.Warning) {
      icon = 'alertTriangleSmall'
      color = 'var(--yellow-100)'
    }
    if (variant === EnumAlertBannerVariant.Error) {
      icon = 'failed'
      color = 'var(--content-primary-primary-inverted)'
    }
    if (variant === EnumAlertBannerVariant.InfoLight) {
      icon = 'infoCircle'
      color = 'var(--light-blue-100)'
    }
    if (variant === EnumAlertBannerVariant.Success) {
      icon = 'check2'
      color = 'var(--content-primary-primary-inverted)'
    }

    return (
      <div
        className={classnames(styles.wrap, styles[`wrap--${variant}`], className)}
        aria-label={`AlertBanner${ariaLabel}`}
      >
        <div className={styles.box}>
          <div className={styles.icon}>
            <Icon icon={icon} />
          </div>
          <Typography
            variant={'body-md-medium'}
            className={styles.label}
            ariaLabel={`${ariaLabel}_title`}
          >
            {title}
          </Typography>
        </div>
        <div className={classnames(styles.box, styles['box--right'])}>
          <div className={styles.actions}>
            {actionPrimary && (
              <Button
                variantBanner={variant}
                variant={'primary'}
                text={actionPrimary.text}
                onClick={() => {
                  actionPrimary.onAction?.()
                }}
              />
            )}

            {actionSecondary && (
              <Button
                variantBanner={variant}
                variant={'secondary'}
                text={actionSecondary.text}
                onClick={() => {
                  actionSecondary.onAction?.()
                }}
              />
            )}

            {showClose && (
              <div className={styles.close}>
                <IconButton
                  iconProps={{
                    icon: 'close',
                    fontSize: 16,
                    color: color,
                  }}
                  variant={'icon'}
                  size={'mini'}
                  color={'transparent'}
                  ariaLabel='AlertBanner_close'
                  onClick={onClose}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)
