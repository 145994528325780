import { observer } from 'mobx-react-lite'
import { Table, Typography } from 'shared/ui'
import { adminTenDLCDetailStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/adminTenDLCDetailStore'
import styles from './styles.module.scss'
import { columns } from './columns'

export const TenDLCLogTable = observer(() => {
  const {
    tenDlcLogsStore: { tenDlcLogsAsRow, total, limit, onPaginationModelChange },
  } = adminTenDLCDetailStore

  if (!tenDlcLogsAsRow.length) return null

  return (
    <div className={styles.wrap}>
      <Typography variant={'heading-sm'} marginBottom={20} tag={'div'} ariaLabel={'10DLC log'}>
        10DLC log
      </Typography>

      <Table
        scrollbarProps={{
          autoHeightMax: 445,
          autoHeight: true,
        }}
        columns={columns}
        rows={tenDlcLogsAsRow}
        rowsCount={total}
        limit={limit}
        onChangePagination={onPaginationModelChange}
      />
    </div>
  )
})
