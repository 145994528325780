// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UBf2PjOpUERDcwtkK3_U{position:relative;display:flex;flex-grow:1;margin-left:16px}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/detail/CampaignContactsStatisticsTable/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":[".wrapTable {\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  margin-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTable": "UBf2PjOpUERDcwtkK3_U"
};
export default ___CSS_LOADER_EXPORT___;
