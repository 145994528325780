// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wV59dJNPy2HnEk6eHk6L{position:relative;display:flex;width:100%;height:100%;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/pages/powerDialer/pages/list/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA","sourcesContent":[".listPageContainer {\n  position: relative;\n  display: flex;\n  width: 100%;\n  height: 100%;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listPageContainer": "wV59dJNPy2HnEk6eHk6L"
};
export default ___CSS_LOADER_EXPORT___;
