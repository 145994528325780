import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CanceledError, type CancelTokenSource } from 'axios'
import { nanoid } from 'nanoid'
import isEqual from 'lodash/isEqual'
import { TableStore } from 'shared/ui/Table'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { toastStore } from 'shared/ui'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { DayjsFormats, getBlob, getContentType, getFileWithTruncatedName, logger } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { errorHandler } from 'shared/api'
import { ExtendedFilesAccept } from 'shared/constants/accept'
import {
  KnowledgeBaseApi,
  KnowledgeBaseDocument,
  KnowledgeBaseDocumentStatus,
  type IParamsKnowledgeBaseDocuments,
  type IResponseKnowledgeBaseDocuments,
} from 'entities/KnowledgeBase'
import { downloadWithToast } from 'features/FileDownload'

import {
  FORMAT_NOT_SUPPORTED,
  MAX_FILE_SIZE,
  MAX_FILES,
  STORAGE_IS_FULL,
  UPLOAD_LIMIT_EXCEEDED,
  UPLOAD_SIZE_ERROR,
} from 'pages/settings/pages/knowledgeBase/lib/constants'

import { getDescriptionForDeleteModal } from 'pages/settings/pages/knowledgeBase/lib/getDescriptionForDeleteModal'
import type { KnowledgeBaseNotificationStore } from './knowledgeBaseNotificationStore'
import type { KnowledgeBaseStore } from './knowledgeBaseStore'

export class KnowledgeBaseDocumentsStore {
  private _total = 0
  private _page = 1
  private _limit = 10

  private _documentsMap = new Map<number, KnowledgeBaseDocument>()
  private _isDocumentsLoading = false
  private _isDocumentsIndexingRetryLoading = false

  private _isDocumentsUploading = false
  private _uploadInProgressIds = new Set<number>()
  private _uploadedCount = 0

  private readonly _initialParams: IParamsKnowledgeBaseDocuments | null = null

  private _cancelTokenSource: CancelTokenSource | null = null
  private _disposeLoadKnowledgeBaseDocuments: IReactionDisposer | null = null

  tableStore = new TableStore<KnowledgeBaseDocument>({
    element: 'document',
    withoutDefaultManageColumns: true,
  })

  constructor(
    private knowledgeBaseStore: KnowledgeBaseStore,
    private notificationStore: KnowledgeBaseNotificationStore
  ) {
    this._initialParams = this._knowledgeBaseDocumentsRequestParams
    this._reactionLoadKnowledgeBaseDocuments()
    makeAutoObservable(this)
  }

  private _reactionLoadKnowledgeBaseDocuments = () => {
    this._disposeLoadKnowledgeBaseDocuments?.()
    this._disposeLoadKnowledgeBaseDocuments = reaction(
      () => this._knowledgeBaseDocumentsRequestParams,
      () => this.loadDocuments()
    )
  }

  private _initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _setDocuments = ({ data, meta }: IResponseKnowledgeBaseDocuments) => {
    this._documentsMap.clear()
    data.forEach((documentResponse) => {
      this._documentsMap.set(documentResponse.id, new KnowledgeBaseDocument(documentResponse))
    })
    this._page = meta.current_page
    this._total = meta.total
  }

  loadDocuments = async () => {
    runInAction(() => {
      this._isDocumentsLoading = true
    })
    this._initCancelTokenSource()

    try {
      const { data } = await KnowledgeBaseApi.getKnowledgeBaseDocuments(
        this._knowledgeBaseDocumentsRequestParams,
        this._cancelTokenSource ? { cancelToken: this._cancelTokenSource.token } : undefined
      )

      this._setDocuments(data)
    } catch (error) {
      runInAction(() => {
        this._isDocumentsLoading = error instanceof CanceledError ? false : false
      })
      logger.error(error)
    } finally {
      runInAction(() => {
        this._isDocumentsLoading = false
      })
    }
  }

  removeDocument = (id: number) => {
    this._documentsMap.delete(id)
  }

  upsertDocument = (document: KnowledgeBaseDocument) => {
    if (this._isInitialParams) {
      this._documentsMap.set(document.id, document)
    }
  }

  handleUploadingDocument = (document: KnowledgeBaseDocument, isUploadCompleted = false) => {
    this.upsertDocument(document)

    if (isUploadCompleted) {
      if (this._uploadInProgressIds.has(document.id)) {
        this._uploadInProgressIds.delete(document.id)
      }
    } else {
      this._uploadInProgressIds.add(document.id)
      this._uploadedCount++
      return
    }

    if (this._isUploadFinished) {
      void this.loadDocuments()
      toastStore.add({
        type: 'success',
        title: `${this._uploadedCount} file(s) uploaded`,
      })
      this._uploadedCount = 0
    }
  }

  onRetryIndexation = async (ids: number[]) => {
    runInAction(() => {
      this._isDocumentsIndexingRetryLoading = true
    })
    try {
      await KnowledgeBaseApi.retryDocumentsIndexation(ids)
    } catch (e) {
      logger.error(e)
      toastStore.add({
        type: 'error',
        title: 'Something went wrong',
      })
    } finally {
      runInAction(() => {
        this._isDocumentsIndexingRetryLoading = false
      })
    }
  }

  onUploadDocuments = async (files: File[]) => {
    const knowledgeBase = this.knowledgeBaseStore.knowledgeBase
    runInAction(() => {
      this._isDocumentsUploading = true
    })
    try {
      if (!knowledgeBase) {
        toastStore.add({
          type: 'warning',
          title: 'Knowledge base not found',
        })
        return
      }

      if (files.length > MAX_FILES) {
        this.notificationStore.addWarning(UPLOAD_LIMIT_EXCEEDED)
        return
      }
      if (
        knowledgeBase.documentsCount + files.length > MAX_FILES ||
        this._documentsMap.size + files.length > MAX_FILES
      ) {
        this.notificationStore.addError(STORAGE_IS_FULL)
        return
      }
      const hasExceededSizeLimit = files.some((file) => file.size > MAX_FILE_SIZE)
      if (hasExceededSizeLimit) {
        this.notificationStore.addError(UPLOAD_SIZE_ERROR)
        return
      }

      const validFiles: { file: File; contentType: string }[] = []
      const invalidFiles: File[] = []

      for (const file of files) {
        const contentType = await getContentType(file)
        if (ExtendedFilesAccept.includes(contentType)) {
          validFiles.push({ file, contentType })
        } else {
          invalidFiles.push(file)
        }
      }

      if (invalidFiles.length === files.length) {
        this.notificationStore.addError(FORMAT_NOT_SUPPORTED)
        return
      }
      if (invalidFiles.length > 0) {
        const validCount = validFiles.length
        const invalidCount = invalidFiles.length
        this.notificationStore.addWarning(
          `${validCount} files from ${files.length} are being uploaded. The format of ${invalidCount} file isn’t supported by the platform and can’t be uploaded.`
        )
      }

      if (validFiles.length) {
        const uploadPromises = validFiles.map(async ({ file, contentType }) => {
          try {
            const preparedFile = getFileWithTruncatedName(file)
            const { data } = await KnowledgeBaseApi.getUploadDocumentLink({
              name: preparedFile.name,
              content_type: contentType,
            })
            const { link, document: docResponse } = data

            void KnowledgeBaseApi.uploadDocument(link.url, preparedFile)

            const document = new KnowledgeBaseDocument(docResponse)
            this.handleUploadingDocument(document)
          } catch (e) {
            const { type, error } = await errorHandler<{ [key: string]: string | string[] }>(
              e as Error
            )
            if (type === 'axios-error' && error.response?.data?.message) {
              const errorMessage = Array.isArray(error.response.data.message)
                ? error.response.data.message[0]
                : error.response.data.message
              this.notificationStore.addError(errorMessage)
            }
          }
        })

        await Promise.all(uploadPromises)
      }
    } catch (err) {
      this.notificationStore.addError('Unknown error during upload.')
    } finally {
      runInAction(() => {
        this._isDocumentsUploading = false
      })
    }
  }

  onDeleteDocuments = async (ids: number[]) => {
    if (!this.knowledgeBaseStore.knowledgeBase) {
      toastStore.add({
        type: 'warning',
        title: 'Knowledge base not found',
      })
      return
    }
    const deleteModalId = nanoid()

    const handleCloseDeleteModal = () => {
      modalStore.removeModal(deleteModalId)
    }

    const handleDeleteDocuments = async () => {
      try {
        await KnowledgeBaseApi.delete(ids)

        toastStore.add({
          type: 'success',
          title: ids.length > 1 ? ids.length + ' files deleted' : 'File deleted',
        })

        ids.forEach((id) => {
          const doc = this._documentsMap.get(id)
          if (doc) {
            this.tableStore.toggleSelected(doc, false)
          }
          this.removeDocument(id)
        })

        void Promise.all([this.knowledgeBaseStore.loadKnowledgeBase(), this.loadDocuments()])
      } catch (e) {
        logger.error(e)
      } finally {
        handleCloseDeleteModal()
      }
    }

    modalStore.addModal({
      id: deleteModalId,
      showHeader: true,
      showCloseButton: false,
      showCloseIcon: true,
      width: 280,
      type: ModalTypeList.ALERT,
      title: ids.length > 1 ? `Delete ${ids.length} files?` : 'Delete file?',
      desc: getDescriptionForDeleteModal(ids, this.knowledgeBaseStore.knowledgeBase),
      primaryAction: {
        text: 'Delete',
        onAction: handleDeleteDocuments,
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: handleCloseDeleteModal,
      },
      onClose: handleCloseDeleteModal,
    })
  }

  onDownloadDocument = async (id: number) => {
    await downloadWithToast(
      async () => {
        const { data } = await KnowledgeBaseApi.getDownloadLink(id)

        const document = this._documentsMap.get(id)
        const name = document?.name || String(id)

        const blob = await getBlob(data.link)
        if (!blob) {
          throw new Error('Failed to download Blob from ' + data.link)
        }

        return { blob, name }
      },
      () => this.onDownloadDocument(id)
    )
  }

  onBulkDownloadDocuments = async (ids: number[]) => {
    await downloadWithToast(
      async () => {
        const { data } = await KnowledgeBaseApi.getBulkDownloadLink(ids)

        const name =
          ids.length === 1
            ? this._documentsMap.get(ids[0])?.name || 'file'
            : 'Salesmsg_' + uiStore.dayjs(new Date()).format(DayjsFormats.downloadFile)

        const blob = await getBlob(data.link)
        if (!blob) {
          throw new Error('Failed to download Blob from ' + data.link)
        }

        return { blob, name }
      },
      () => this.onBulkDownloadDocuments(ids)
    )
  }

  onPaginationChange = (page: number, limit: number) => {
    this._page = page
    this._limit = limit
  }

  private get _knowledgeBaseDocumentsRequestParams(): IParamsKnowledgeBaseDocuments {
    return {
      page: this._page,
      limit: this._limit,
    }
  }

  private get _isInitialParams() {
    return isEqual(this._initialParams, this._knowledgeBaseDocumentsRequestParams)
  }

  private get _isUploadFinished() {
    return this._uploadInProgressIds.size === 0 && this._uploadedCount !== 0
  }

  get documents() {
    return Array.from(this._documentsMap.values()).sort(
      (a, b) =>
        Number(b.getIsUploading()) - Number(a.getIsUploading()) ||
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
  }

  get isDocumentsLoading() {
    return this._isDocumentsLoading
  }

  get isDocumentsUploading() {
    return this._isDocumentsUploading
  }

  get page() {
    return this._page
  }

  get limit() {
    return this._limit
  }

  get total() {
    return this._total
  }

  get isEmpty() {
    return !this._isDocumentsLoading && !this._documentsMap.size
  }

  get documentIdsToRetry() {
    return this.documents
      .filter((document) => document.status === KnowledgeBaseDocumentStatus.IndexationFailed)
      .map((document) => document.id)
  }

  get isDocumentsIndexingRetryLoading() {
    return this._isDocumentsIndexingRetryLoading
  }

  dispose = () => {
    this._disposeLoadKnowledgeBaseDocuments?.()
  }
}
