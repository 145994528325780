import { makeAutoObservable, runInAction } from 'mobx'
import { IDropdownItem, showToast } from 'shared/ui'
import {
  IntegrationsApi,
  IParamsSetHubspotContactsLists,
  IResponseGetHubspotContactsList,
  IResponseGetHubspotLists,
} from 'entities/Integrations'

export class IntegrationHubspotSettingsListsStore {
  private _isLoading = false
  private _lists: IResponseGetHubspotLists[] = []
  private _allContactsList: IResponseGetHubspotContactsList[] = []

  constructor() {
    makeAutoObservable(this)
  }

  init() {
    Promise.all([this.getAllContactsList(), this.getHubspotLists()])
  }

  get lists() {
    return this._lists
  }

  get isLoading() {
    return this._isLoading
  }

  get allContactslists() {
    const ids = new Set(this._lists.map((item) => item.list_id))
    return this._allContactsList.filter((item) => !ids.has(item.listId))
  }

  get isAllContactslistsEmpty() {
    return this.allContactslists.length === 0
  }

  get isImportedListsEmpty() {
    return this.lists.length === 0
  }

  getAllContactsList = async () => {
    try {
      const { data } = await IntegrationsApi.getHubspotContactsLists()

      this.setAllContacts(data)
    } catch (e) {
      console.log(e)
    }
  }

  getHubspotLists = async () => {
    try {
      runInAction(() => {
        this._isLoading = true
      })
      const { data } = await IntegrationsApi.getHubspotLists()

      this.setLists(data)
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._isLoading = false
      })
    }
  }

  getMappedLists = () =>
    this._lists.map((list) => ({
      id: list.list_id,
      name: list.name,
      size: list.size ? Number(list.size) : 0,
    }))

  fetchSetHubspotContactsLists = async (data: IParamsSetHubspotContactsLists['lists']) => {
    try {
      await IntegrationsApi.setHubspotContactsLists({ lists: [...data] })

      runInAction(() => {
        this.getHubspotLists()
      })
    } catch (e) {
      console.log(e)
    }
  }

  setHubspotList = async (listsItem: IDropdownItem) => {
    const data = this.getMappedLists()

    data.unshift({
      id: Number(listsItem.id),
      name: listsItem.label,
      size: listsItem.value ? Number(listsItem.value) : 0,
    })

    this.fetchSetHubspotContactsLists(data)
  }

  setAllContacts(data: IResponseGetHubspotContactsList[]) {
    this._allContactsList = data
  }

  setLists(data: IResponseGetHubspotLists[]) {
    this._lists = data
  }

  setAllLists = async () => {
    const data = this.allContactslists.map((list) => ({
      id: Number(list.listId),
      name: list.name,
      size: Number(list.size),
    }))

    this.fetchSetHubspotContactsLists([...this.getMappedLists(), ...data])
  }

  onRemoveList = async (id: number) => {
    const data = this.getMappedLists().filter((list) => list.id !== id)

    await this.fetchSetHubspotContactsLists(data)
    showToast({
      type: 'success',
      title: 'Lists removed',
    })
  }

  refreshData = async () => {
    await this.getHubspotLists()
    showToast({
      type: 'success',
      title: 'List synced',
    })
  }
}
