import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Switch } from 'shared/ui'
import { TitledRow } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/TitledRow/TitledRow'
import type { IntegrationSalesforceSettingsPropertyStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/store/IntegrationSalesforceSettingsPropertyStore'
import { IntegrationSettingsPropertyTable } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSettingsPropertyTable/IntegrationSettingsPropertyTable'
import styles from './styles.module.scss'

type IIntegrationSalesforceSettingsPropertyProps = {
  store: IntegrationSalesforceSettingsPropertyStore
}

export const IntegrationSalesforceSettingsProperty: FC<IIntegrationSalesforceSettingsPropertyProps> =
  observer(({ store }) => {
    const { rows, loading, numberEnabled, setNumberEnabled, integrationName } = store

    return (
      <div className={styles.root}>
        <TitledRow
          title={'Use custom phone number property'}
          label={'Update custom phone number for Lead and Contact'}
          verticalAlign={'start'}
        >
          <Switch size='middle' value={numberEnabled} onChange={setNumberEnabled} />
        </TitledRow>

        <IntegrationSettingsPropertyTable
          rows={rows}
          loading={loading}
          integrationName={integrationName}
          integrationIcon={'salesforce'}
        />
      </div>
    )
  })
