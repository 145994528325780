import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { TableStore } from 'shared/ui/Table'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import type {
  IParamsGetTriggers,
  IResponseGetTriggers,
  IResponseTrigger,
  ITriggerCategoryType,
} from 'entities/Trigger/api/type'
import { ITrigger } from 'entities/Trigger/model/Trigger'
import { TriggerApi } from 'entities/Trigger/api/trigger'
import { integrationsStore } from 'entities/Integrations'

type ISetIsTriggerActionShown = (isShown: boolean) => void

export type ITriggerListStoreProps = {
  type: ITriggerCategoryType
  setIsTriggerActionShown: ISetIsTriggerActionShown
}

export class TriggerListStore {
  type: ITriggerCategoryType

  private _disposeLoadTriggers: IReactionDisposer | null = null
  private _disposeTriggerActionShown: IReactionDisposer | null = null

  private _deleteTriggerBulkModalId = 'deleteTriggerBulkModal'

  private _tableStore = new TableStore<ITrigger>({
    element: 'trigger',
    withoutDefaultManageColumns: true,
    sortBy: 'created_at',
    sortOrder: 'desc',
  })

  constructor({ type, setIsTriggerActionShown }: ITriggerListStoreProps) {
    makeAutoObservable(this)
    this.type = type

    this.reactionLoadTriggers()
    this.reactionTriggerActionShown(setIsTriggerActionShown)
  }

  page = 1
  limit: number | null = 10
  total = 0
  isLoading = true
  isFirstLoading = true

  triggersMap: Map<number, ITrigger> = new Map()

  initTriggersLoad = async () => {
    await integrationsStore.fetchIntegrations()
    void this.loadTriggers()
  }

  get tableStore() {
    return this._tableStore
  }

  loadTriggers = async () => {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const { data } = await TriggerApi.getTriggers(this.paramsGetTriggers)
      this.setGetTriggersResponse(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.isLoading = false
        if (this.isFirstLoading) this.isFirstLoading = false
      })
    }
  }

  setGetTriggersResponse = ({ data, meta }: IResponseGetTriggers) => {
    this.triggersMap.clear()
    this._tableStore.setRows([])

    this.setTriggerMap(data)
    this._tableStore.setRows(this.triggers)

    this.page = meta.current_page
    this.limit = meta.per_page
    this.total = meta.total
  }

  setTriggerMap = (triggersResponse: IResponseTrigger[]) => {
    triggersResponse.forEach((triggerResponse) => {
      try {
        this.triggersMap.set(triggerResponse.id, new ITrigger(triggerResponse))
      } catch (e) {
        console.error(e)
      }
    })
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }

  reactionLoadTriggers = () => {
    this._disposeLoadTriggers?.()
    this._disposeLoadTriggers = reaction(
      () => this.paramsGetTriggers,
      () => this.loadTriggers()
    )
  }

  reactionTriggerActionShown = (setIsTriggerActionShown: ISetIsTriggerActionShown) => {
    this._disposeTriggerActionShown?.()
    this._disposeTriggerActionShown = reaction(
      () => this.isLoading,
      () => setIsTriggerActionShown(!this.isTriggersEmpty)
    )
  }

  clearReactions = () => {
    this._disposeLoadTriggers?.()
    this._disposeTriggerActionShown?.()
  }

  bulkDelete = () => {
    const bulkAll = this._tableStore.bulkAllMode
    const ids = this._tableStore.selectedIds as number[]
    const idsLength = ids.length

    const handleDelete = async () => {
      this.isLoading = true
      modalStore.removeModal(this._deleteTriggerBulkModalId)

      try {
        await TriggerApi.deleteBulkTriggers(ids, bulkAll)
        toastStore.add({
          title: bulkAll ? 'All triggers deleted' : `Trigger${idsLength > 1 ? 's' : ''} deleted`,
          type: 'success',
        })

        this._tableStore.resetSelected()
        this.triggersMap.clear()
        this.loadTriggers()
      } catch (error) {
        runInAction(() => {
          this.isLoading = false
          toastStore.add({
            title: 'Something went wrong. Please try again.',
            type: 'error',
          })
        })
      }
    }

    const handleCancel = () => {
      modalStore.removeModal(this._deleteTriggerBulkModalId)
    }

    modalStore.addModal({
      id: this._deleteTriggerBulkModalId,
      showHeader: true,
      showCloseButton: false,
      showCloseIcon: true,
      zIndex: 2000,
      width: 280,
      type: ModalTypeList.ALERT,
      title: bulkAll
        ? 'Delete all triggers?'
        : `Delete ${idsLength} trigger${idsLength > 1 ? 's' : ''}?`,
      desc: "The triggers' unique Webhook URL will be lost forever. If you choose to re-create the triggers in the future you will receive new unique Webhook URLs to use.",
      primaryAction: {
        text: 'Delete',
        onAction: handleDelete,
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: handleCancel,
      },
      onClose: handleCancel,
    })
  }

  get paramsGetTriggers(): IParamsGetTriggers {
    return {
      type: this.type,
      length: this.limit || undefined,
      sortBy: this.tableStore.sortBy,
      sortOrder: this.tableStore.sortOrder,
      page: this.page,
    }
  }

  get triggers() {
    return Array.from(this.triggersMap.values())
  }

  get isTriggersEmpty() {
    return !this.triggers.length && !this.isLoading
  }
}
