import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import { DayjsFormats } from 'shared/lib'
import { IColumn, IIconsVector, IStatusProps, Status } from 'shared/ui'
import { ITenDlcLogAsRow } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/tenDlcLogsStore'

const statusIntentMap: Record<string, IStatusProps['intent']> = {
  approved: 'positive',
  rejected: 'negative',
  reviewed: 'informative',
  published: 'purple',
}

const statusIconMap: Record<string, IIconsVector> = {
  approved: 'check2',
  rejected: 'failed',
  reviewed: 'clock',
  published: 'clock',
}

export const columns: IColumn<ITenDlcLogAsRow>[] = [
  {
    field: 'part',
    name: '10DLC part',
  },
  {
    field: 'action',
    name: 'Action',
    renderRowCell: (row) => (
      <Status
        title={row.statusTitle}
        intent={statusIntentMap[row.status]}
        icon={statusIconMap[row.status]}
        emphasis={'high'}
      />
    ),
  },
  {
    field: 'actionBy',
    name: 'Action by',
    renderRowCell: (row) => capitalize(row.actionBy),
  },
  {
    field: 'date',
    name: 'Date',
    renderRowCell: (row) => dayjs(row.date).format(DayjsFormats.slash),
  },
]
