import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { IDropdownItem } from 'shared/ui'
import { logger } from 'shared/lib'
import {
  IIntegrationKey,
  Integration,
  IntegrationsApi,
  type IResponseMergeField,
} from 'entities/Integrations'
import { MergeField } from 'widgets/MergeField/types/MergeField'
import { IntegrationWebhookModalContent } from 'pages/settings/pages/integrations/pages/integrationList/ui/IntegrationWebhookModal/IntegrationWebhookModalContent'

const ALLOWED_INTEGRATIONS_FOR_LEAD_OWNER: Set<IIntegrationKey> = new Set([
  'hubspot',
  'infusionsoft',
])

const ALLOWED_INTEGRATIONS_FOR_WEBHOOK: Set<IIntegrationKey> = new Set([
  'hubspot',
  'salesforce',
  'activecampaign',
  'infusionsoft',
])

export class IntegrationWebhookStore {
  private _modalId = ''
  private _integration: Integration | null = null

  private _fieldsLoading = true
  private _phoneFields: MergeField[] = []

  private _selectedPhoneField = ''
  private _sendFromLeadOwner = false

  constructor() {
    makeAutoObservable(this)
  }

  openIntegrationWebhookModal = (integration: Integration) => {
    this._modalId = nanoid()
    this.initIntegrationWebhookState(integration)

    modalStore.addModal({
      id: this._modalId,
      width: 480,
      title: `${integration.name} Webhook`,
      type: ModalTypeList.DEFAULT,
      ModalContent: IntegrationWebhookModalContent,
      ModalContentProps: {
        integrationWebhookStore: this,
      },
      showCloseButton: false,
      paddingContent: '0 24px 16px 24px',
      onClose: this._onIntegrationWebhookModalClose,
    })
  }

  private _onIntegrationWebhookModalClose = () => {
    modalStore.removeModal(this._modalId)
    this._resetIntegrationWebhookState()
  }

  initIntegrationWebhookState = (integration: Integration) => {
    this._resetIntegrationWebhookState()
    this._integration = integration
    void this._fetchIntegrationFields()
  }

  private _resetIntegrationWebhookState = () => {
    this._phoneFields = []
    this._selectedPhoneField = ''
    this._sendFromLeadOwner = false
    this._integration = null
  }

  private _addPhoneFields = (responseMergeField: IResponseMergeField) => {
    const integrationMergeField = new MergeField(responseMergeField)
    if (integrationMergeField.name.match(/phone/i)) {
      this._phoneFields.push(integrationMergeField)
    }
  }

  private _fetchIntegrationFields = async () => {
    if (!this._integration) return

    runInAction(() => {
      this._fieldsLoading = true
    })

    try {
      const { data } = await IntegrationsApi.getIntegrationsByKeyFields(this._integration.key)

      data.forEach(this._addPhoneFields)

      runInAction(() => {
        this._selectedPhoneField = this._defaultIntegrationPhoneField
      })
    } catch (error) {
      logger.error(error)
    } finally {
      runInAction(() => {
        this._fieldsLoading = false
      })
    }
  }

  handleSendFromLeadOwnerToggle = () => {
    this._sendFromLeadOwner = !this._sendFromLeadOwner
  }

  handleSelectedPhoneFieldChange = (phoneField: string) => {
    this._selectedPhoneField = phoneField
  }

  private get _defaultIntegrationPhoneField() {
    if (this._integration?.key === 'infusionsoft') {
      return 'Phone1'
    }
    return 'phone'
  }

  get webhookUrl() {
    const webhookBaseUrl = this._integration?.connected?.webhook?.url
    if (!webhookBaseUrl) return ''

    const leadOwnerParam = this._sendFromLeadOwner ? '&from=lead-owner' : ''

    return `${webhookBaseUrl}?to=${this._selectedPhoneField}${leadOwnerParam}`
  }

  get integrationName() {
    return this._integration?.name || ''
  }

  get availableIntegrationsForWebhook() {
    return ALLOWED_INTEGRATIONS_FOR_WEBHOOK
  }

  get canSendFromLeadOwner() {
    if (!this._integration) return false
    return ALLOWED_INTEGRATIONS_FOR_LEAD_OWNER.has(this._integration.key)
  }

  get fieldsLoading() {
    return this._fieldsLoading
  }

  get selectedPhoneField() {
    return this._selectedPhoneField
  }

  get sendFromLeadOwner() {
    return this._sendFromLeadOwner
  }

  get phoneFieldsItems() {
    return this._phoneFields.map((phoneField: MergeField): IDropdownItem => {
      return {
        id: phoneField.name,
        label: phoneField.label,
      }
    })
  }

  dispose = () => {
    modalStore.removeModal(this._modalId)
    this._resetIntegrationWebhookState()
  }
}
