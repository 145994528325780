import { makeAutoObservable, reaction, runInAction, type IReactionDisposer } from 'mobx'
import { logger } from 'shared/lib'
import { organizationSettingsStore } from 'entities/Settings'
import { type IResponseTenDlcLimits } from '../api/type'
import { TenDlcApi } from '../api/tenDlc'

export class TenDlcLimitsStore {
  private _limits: IResponseTenDlcLimits | null = null
  private _limitsLoading = false
  private _disposeTenDLC: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)

    this.reactionTenDLC()
  }

  fetchLimits = async () => {
    try {
      this._limitsLoading = true

      const { data } = await TenDlcApi.getTenDlcLimits()

      runInAction(() => (this._limits = data))
    } catch (error) {
      logger.error(error)
    } finally {
      runInAction(() => (this._limitsLoading = false))
    }
  }

  get currentAmount() {
    return this._limits?.currentAmount
  }

  get dlcPlan() {
    return this._limits?.dlcPlan
  }

  get currentPlan() {
    return this._limits?.dlcPlanTypes[this._limits?.dlcPlan.planTypeId]
  }

  get isOutOfSegments() {
    if (!this.currentAmount || !this.currentPlan) return false

    return this.currentAmount.segments >= this.currentPlan.segments_to
  }

  get isPlanStarter() {
    return this._limits?.dlcPlan.planTypeId === 1
  }

  get isPlanStandard() {
    return this._limits?.dlcPlan.planTypeId === 2
  }

  get isPlanLowVolumeStandard() {
    return this._limits?.dlcPlan.planTypeId === 3
  }

  get isPlanSoleProprietorship() {
    return this._limits?.dlcPlan.planTypeId === 4
  }

  dispose = () => {
    this._disposeTenDLC?.()
  }

  reactionTenDLC = () => {
    this._disposeTenDLC?.()
    this._disposeTenDLC = reaction(
      () => organizationSettingsStore.hasTenDLCLimits,
      (value) => {
        if (value) this.fetchLimits()
      }
    )
  }
}

export const tenDlcLimitsStore = new TenDlcLimitsStore()
