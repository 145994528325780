// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mhNAUkV6xBn5_rvOitk7 .X0YjvHGniqHWXaezkYbN{padding:0}", "",{"version":3,"sources":["webpack://./src/widgets/PowerDialer/ui/PowerDialerEditor/hooks/styles.module.scss"],"names":[],"mappings":"AAAA,4CACE,SAAA","sourcesContent":[".tooltipContainer .linkButton {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipContainer": "mhNAUkV6xBn5_rvOitk7",
	"linkButton": "X0YjvHGniqHWXaezkYbN"
};
export default ___CSS_LOADER_EXPORT___;
