import { createContext, useContext, useEffect, useMemo, type PropsWithChildren } from 'react'
import { observer } from 'mobx-react-lite'
import { PowerDialerListStore } from 'pages/powerDialer/pages/list/store/PowerDialerListStore'
import { usePowerDialerListEvent } from 'pages/powerDialer/pages/list/events/usePowerDialerListEvent'

export const PowerDialerListContext = createContext<PowerDialerListStore | null>(null)

export const usePowerDialerListContext = () => {
  const context = useContext(PowerDialerListContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with PowerDialerListContext')

  return context
}

export const PowerDialerListProvider = observer(({ children }: PropsWithChildren) => {
  const store = useMemo(() => new PowerDialerListStore(), [])

  useEffect(() => store.dispose, [])

  usePowerDialerListEvent(store)

  return <PowerDialerListContext.Provider value={store}>{children}</PowerDialerListContext.Provider>
})
