import { createContext, useContext } from 'react'
import { CampaignOverviewStore } from '../store/CampaignOverviewStore'

export const CampaignOverviewContext = createContext<CampaignOverviewStore | null>(null)

export const useCampaignOverviewContext = () => {
  const context = useContext(CampaignOverviewContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CampaignOverviewContext')

  return context
}
