import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import { ActionItem, toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import { IntegrationsApi, type IResponseGetHubspotLists } from 'entities/Integrations'

const makeSyncAction = (listId: number): ActionItem => ({
  icon: 'refresh',
  text: 'Refresh',
  onAction: async () => {
    try {
      await IntegrationsApi.resyncHubspotLists(listId)
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data) {
          toastStore.add({
            type: 'error',
            title: e.response?.data.message,
          })
        }
      }
      logger.error(e)
    }
  },
})

const makeDeleteAction = (onRemoveList: () => void): ActionItem => {
  const onDelete = () => {
    const id = nanoid()
    modalStore.addModal({
      id,
      type: ModalTypeList.ALERT,
      title: 'Delete synced hubspot list?',
      desc: 'This action cannot be undone',
      primaryAction: {
        text: 'Delete',
        onAction: () => {
          onRemoveList()
          modalStore.closeModal(id)
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(id)
        },
      },
    })
  }
  return {
    icon: 'delete',
    text: 'Remove list',
    onAction: onDelete,
  }
}

export const getPowerHubspotListActions = (
  hubspotList: IResponseGetHubspotLists,
  onRemoveList: () => void
): ActionItem[] => {
  const syncAction = makeSyncAction(hubspotList.id)
  const deleteAction = makeDeleteAction(onRemoveList)

  return [syncAction, deleteAction]
}
