// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iBddGNHqAFGO107_ZhnG{display:flex;flex-direction:column;gap:20px;min-height:420px;align-items:flex-start;container-name:container-root;container-type:inline-size;--table-bottom-color: var(--border-neutral-default);--table-row-hover-color: transparent;--titled-row-action-width: 100px}.iBddGNHqAFGO107_ZhnG td:nth-child(2){max-width:344px}@container container-root (max-width: 600px){.iBddGNHqAFGO107_ZhnG td:nth-child(2){max-width:260px}}.tMeBCn_kwB98gsCyJh85{display:flex;align-items:center;width:100%;height:32px}.mx8esm2TG8Q5N6FJZwOw{display:flex;align-items:center;gap:8px;margin-left:auto}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/ui/IntegrationSalesforceSettingsProperty/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,gBAAA,CACA,sBAAA,CACA,6BAAA,CACA,0BAAA,CACA,mDAAA,CACA,oCAAA,CACA,gCAAA,CAEA,sCACE,eAAA,CAGF,6CACE,sCACE,eAAA,CAAA,CAKN,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,gBAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  min-height: 420px;\n  align-items: flex-start;\n  container-name: container-root;\n  container-type: inline-size;\n  --table-bottom-color: var(--border-neutral-default);\n  --table-row-hover-color: transparent;\n  --titled-row-action-width: 100px;\n\n  td:nth-child(2) {\n    max-width: 344px;\n  }\n\n  @container container-root (max-width: 600px) {\n    td:nth-child(2) {\n      max-width: 260px;\n    }\n  }\n}\n\n.footer {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 32px;\n}\n\n.control {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "iBddGNHqAFGO107_ZhnG",
	"footer": "tMeBCn_kwB98gsCyJh85",
	"control": "mx8esm2TG8Q5N6FJZwOw"
};
export default ___CSS_LOADER_EXPORT___;
