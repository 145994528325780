import { nanoid } from 'nanoid'
import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { toastStore } from 'shared/ui'
import { CampaignApi, type IParamsEnrolmentCampaign } from 'entities/Campaign'
import { CampaignSelectStore } from 'entities/Campaign/ui'
import { type IContactsDataParams } from 'entities/Contacts/api/types'
import { EnrollInCampaignModal } from '../EnrollInCampaignModal'

export class EnrollInCampaignModalStore {
  campaignSelectStore
  constructor(private contactsDataParams: IContactsDataParams) {
    makeAutoObservable(this)
    this.campaignSelectStore = new CampaignSelectStore()
    this.openModal()
  }

  dispose = () => {
    this.campaignSelectStore.dispose()
  }

  get params(): IParamsEnrolmentCampaign | null {
    if (!this.campaignSelectStore.selectedItemId) return null
    return {
      id: this.campaignSelectStore.selectedItemId,
      data: {
        apply_filters: this.checkCampaignCriteria,
        contact_ids: this.contactsDataParams.selectedIds,
      },
    }
  }

  get contactCount() {
    return (
      (this.contactsDataParams.bulkAll
        ? this.contactsDataParams.total
        : this.contactsDataParams.selectedIds.length) || 0
    )
  }

  modalId = nanoid()

  openModal = () => {
    modalStore.addModal({
      id: this.modalId,
      title: 'Enroll in Campaign',
      pureContent: true,
      width: 480,
      ModalContent: EnrollInCampaignModal,
      ModalContentProps: { store: this },
      onClose: this.onClose,
    })
  }

  onClose = () => {
    this.dispose()
    modalStore.removeModal(this.modalId)
  }

  loading = false
  setLoading = (value: boolean) => {
    this.loading = value
  }
  get disabled() {
    return !this.params
  }
  onEnroll = async () => {
    if (!this.params) return
    try {
      this.setLoading(true)
      await CampaignApi.enrolmentCampaign(this.params)

      const contactText = `Contact${this.contactCount > 1 ? 's' : ''}`

      toastStore.add({
        type: 'success',
        title: this.checkCampaignCriteria
          ? `${contactText} will be enrolled if it matches enrollment criteria`
          : `${contactText} enrolled in campaign`,
      })
      this.onClose()
    } catch (e) {
      console.log(e)
    } finally {
      this.setLoading(false)
    }
  }

  checkCampaignCriteria = false
  setCheckCampaignCriteria = () => {
    this.checkCampaignCriteria = !this.checkCampaignCriteria
  }
}
