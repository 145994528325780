import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Breadcrumbs, ContainerHeader, type ITabItem, Tabs } from 'shared/ui'
import { integrationsStore } from 'entities/Integrations'
import { ContactsUnlinkedTable } from 'pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable'
import { useCleanupPageContext } from 'pages/contacts/pages/cleanup/context/cleanupContext'
import { ContactsDuplicateTable } from 'pages/contacts/pages/cleanup/ui/ContactsDuplicateTable'
import styles from './styles.module.scss'

export const ContactsCleanupContent = observer(() => {
  const contactsCleanupStore = useCleanupPageContext()
  const { state } = useLocation()
  const {
    contactsUnlinkedTable,
    activeTab,
    isChangeToUnlinked,
    setIsChangeToUnlinked,
    handleActiveTab,
    resetPaginationPage,
    pageLayoutStore,
  } = contactsCleanupStore
  const { duplicateCount } = contactsCleanupStore
  const tabs: ITabItem[] = [
    {
      name: 'Duplicates',
      key: 'duplicate',
      count: duplicateCount,
    },
    {
      name: 'Unlinked',
      key: 'unlink',
      count: contactsUnlinkedTable.total,
    },
  ]

  useEffect(() => {
    contactsCleanupStore.init()

    if (!integrationsStore.hasIntegrations) {
      integrationsStore.fetchIntegrations()
    }
    if (isChangeToUnlinked) {
      handleActiveTab(tabs[1].key)
      setIsChangeToUnlinked(false)
    } else {
      setIsChangeToUnlinked(state === 'isUnlinked')
    }

    return () => {
      handleActiveTab(tabs[0].key)
    }
  }, [])

  return (
    <div className={styles.contentContainer}>
      <Breadcrumbs />
      <ContainerHeader
        title={'Contact cleanup'}
        titleIconProps={{
          icon: 'lineCollapseExpandLeft',
          fontSize: 20,
          color: 'var(--content-primary-tertiary)',
        }}
        titleIconAction={pageLayoutStore.handleToggleCollapse}
      />
      <div className={styles.tabs}>
        <Tabs
          tabs={tabs}
          activeTabKey={activeTab}
          handleSelectTab={(tab) => {
            handleActiveTab(tab.key)
            resetPaginationPage()
          }}
        />
      </div>
      <div style={{ display: 'flex', height: '100%' }}>
        {activeTab === 'unlink' && (
          <ContactsUnlinkedTable store={contactsCleanupStore.contactsUnlinkedTable} />
        )}
        {activeTab === 'duplicate' && (
          <ContactsDuplicateTable store={contactsCleanupStore.contactsDuplicateTable} />
        )}
      </div>
    </div>
  )
})
