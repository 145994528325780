import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DropdownContent } from 'shared/ui'
import { FilterEditor, type FilterEditorStore } from 'widgets/FilterEditor'
import { type ContactsFilterStore } from 'pages/contacts/ui/ContactsFilters'
import { SegmentModalStore } from 'pages/contacts/ui/ContactsMenu/ui/SegmentModal'
import styles from './styles.module.scss'

type IFiltersContentProps = {
  parentsDropdownId: string[]
  onCloseMenu: () => void
  segmentModalStore: SegmentModalStore
  contactsFilterStore: ContactsFilterStore
  filterEditorStore: FilterEditorStore
  onClearFilters: () => void
}

export const FiltersContent: FC<IFiltersContentProps> = observer(
  ({
    parentsDropdownId,
    onCloseMenu,
    segmentModalStore,
    filterEditorStore,
    onClearFilters,
    contactsFilterStore,
  }) => {
    const { onOpen, segmentBox, segment, hasFilters } = contactsFilterStore
    const isNewSegment = segment?.id === -1

    const openCreateModal = () => {
      segmentModalStore?.openNewSegmentModal()
    }

    const openEditSegmentModal = () => {
      onCloseMenu()
      segmentModalStore?.editItem({
        afterEdit: () => segmentBox.setHasChanges(false),
      })
      onOpen(false)
    }

    const disabled =
      !segmentBox.hasChanges ||
      segment?.hasHubspotFilter ||
      !filterEditorStore.groups.isReady ||
      filterEditorStore.isHubspotList

    return (
      <DropdownContent>
        <div className={styles.container}>
          <FilterEditor store={filterEditorStore} parentsDropdownId={parentsDropdownId} />
          <div className={styles.actions}>
            {isNewSegment && (
              <>
                <Button
                  typeBtn='text'
                  text='Create segment'
                  onClick={openCreateModal}
                  disabled={disabled}
                  tooltipProps={
                    filterEditorStore.isHubspotList
                      ? {
                          label: 'HubSpot lists cannot be saved as segments',
                          disableInteractive: true,
                          placement: 'top',
                        }
                      : undefined
                  }
                />
                <div className={styles.verticalDivider}></div>
                <Button
                  typeBtn='text'
                  contained={'secondary'}
                  text='Clear'
                  disabled={!hasFilters}
                  onClick={onClearFilters}
                />
              </>
            )}
            {!isNewSegment && (
              <>
                <Button
                  typeBtn='text'
                  contained='secondary'
                  text='Save changes'
                  onClick={openEditSegmentModal}
                  disabled={disabled}
                />
                <Button
                  typeBtn='text'
                  contained='secondary'
                  text='Save as new'
                  disabled={disabled}
                  onClick={openCreateModal}
                />
              </>
            )}
            <Button typeBtn='text' contained='secondary' text='Hide' onClick={onCloseMenu} />
          </div>
        </div>
      </DropdownContent>
    )
  }
)
