import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { type SegmentModalStore } from '../store/segmentModalStore'
import { ModalActions } from './ModalActions'

type INewSegmentActionsProps = {
  segmentModalStore: SegmentModalStore
}

export const NewSegmentActions: FC<INewSegmentActionsProps> = observer(({ segmentModalStore }) => {
  return (
    <ModalActions
      successText='Create'
      successAction={() => {
        segmentModalStore.onActionNew()
      }}
      segmentModalStore={segmentModalStore}
    />
  )
})
