import { makeAutoObservable } from 'mobx'
import { type IResponseOrganizationTollFree, type IResponseTollFree } from 'entities/Compliance'
import { TollFreeProfile } from 'entities/Compliance/model/OrganizationTollFree/TollFreeProfile'

export class OrganizationTollFree {
  private _itemsMap: Map<number, TollFreeProfile> = new Map()

  constructor(response: IResponseOrganizationTollFree) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponseOrganizationTollFree) => {
    response.data.forEach((item) => {
      this.addItem(item)
    })
  }

  addItems = (responseItems: IResponseTollFree[]) => {
    responseItems.forEach((item) => {
      this.addItem(item)
    })
  }

  addItem = (responseItem: IResponseTollFree) => {
    this._itemsMap.set(responseItem.id, new TollFreeProfile(responseItem))
  }

  deleteItem = (id: number) => {
    this._itemsMap.delete(id)
  }

  getItem = (id: number) => {
    return this._itemsMap.get(id)
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  get numberCount() {
    return this.items.reduce((acc, item) => acc + item.numbers.length, 0)
  }

  get isStatusEmpty() {
    return !this.items.length
  }

  get isStatusInUnderReview() {
    if (!this.items.length) return false

    return this.items.every((item) => item.isStatusInUnderReview)
  }

  get isStatusInProgress() {
    return this.items.some((item) => item.isStatusInProgress)
  }

  get isStatusInCarrierReview() {
    return this.items.some((item) => item.isStatusInCarrierReview)
  }

  get isStatusVerified() {
    if (!this.items.length) return false

    return this.items.every((item) => item.isStatusVerified)
  }

  get isStatusPendingReview() {
    return this.items.some((item) => item.isStatusPendingReview)
  }

  get isStatusRejected() {
    return this.items.some((item) => item.isStatusRejected)
  }

  get isStatusBlocked() {
    return this.items.some((item) => item.isStatusBlocked)
  }
}
