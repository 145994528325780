import { useMemo, useEffect } from 'react'
import type { Integration } from 'entities/Integrations'

type IIntegrationStore = {
  init: (integration: Integration) => void
  dispose?: () => void
}

export const useIntegrationStoreMemo = <
  Store extends IIntegrationStore,
  Params extends [Integration, ...unknown[]]
>(
  StoreClass: new (...args: Params) => Store,
  ...args: Params
) => {
  const memoStore = useMemo(() => new StoreClass(...args), [])
  const integration = args[0]

  useEffect(() => {
    memoStore.init(integration)
  }, [integration.isConnected])

  // unmount hook
  useEffect(
    () => () => {
      memoStore.dispose?.()
    },
    []
  )

  return memoStore
}
