// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QhioHXpB9zwcjBDfvWBf{padding:4px;border-top:1px solid var(--border-neutral-default)}.xTA2K1YEnXD0aQJkymVY{color:var(--gray-60);font-size:12px;font-weight:400;padding:12px 0;display:flex;justify-content:center}.jDsG1Ok9eRpHJlOhY7fa{color:var(--content-primary-primary);font-size:13px;line-height:16px;font-weight:400;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSettingsPropertyDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,kDAAA,CAGF,sBACE,oBAAA,CACA,cAAA,CACA,eAAA,CACA,cAAA,CACA,YAAA,CACA,sBAAA,CAGF,sBACE,oCAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":[".dropdownFooter {\n  padding: 4px;\n  border-top: 1px solid var(--border-neutral-default);\n}\n\n.noResults {\n  color: var(--gray-60);\n  font-size: 12px;\n  font-weight: 400;\n  padding: 12px 0;\n  display: flex;\n  justify-content: center;\n}\n\n.addButton {\n  color: var(--content-primary-primary);\n  font-size: 13px;\n  line-height: 16px;\n  font-weight: 400;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownFooter": "QhioHXpB9zwcjBDfvWBf",
	"noResults": "xTA2K1YEnXD0aQJkymVY",
	"addButton": "jDsG1Ok9eRpHJlOhY7fa"
};
export default ___CSS_LOADER_EXPORT___;
