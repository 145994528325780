import { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Options } from '@popperjs/core/lib/types'
import { Button, Dropdown, DropdownContent, layoutStore } from 'shared/ui'
import { Conversation } from 'entities/Conversation/model/Conversation'
import {
  ButtonClose,
  ButtonMakePriority,
  ButtonMarkAsPending,
  ButtonMarkAsUnread,
  ButtonOptInStatus,
} from 'widgets/ConversationCard'
import { ConversationAssignDropdownContent } from 'widgets/ConversationAssignDropdown'
import { useConversationListContext } from 'widgets/ConversationList/context'

type PropsDropDown = {
  conversation: Conversation
  onChangeOpen?: (isOpen: boolean) => void
}

export const ConversationDropdown: FC<PropsDropDown> = observer(
  ({ conversation, onChangeOpen }) => {
    const store = useConversationListContext()
    const [showAssignDropdown, setShowAssignDropdown] = useState(false)

    const onCloseAssignDropdown = () => setShowAssignDropdown(false)

    return (
      <Dropdown
        ariaLabel={'ConversationDropdown'}
        width={224}
        icon={'moreHorizontal'}
        iconButtonProps={{
          variant: 'contained',
          color: 'tertiary2',
          transparentInactive: true,
          ariaLabel: 'ConversationDropdown_trigger',
        }}
        placement={'bottom-start'}
        withToggleTriggerOpen
        onChangeOpen={onChangeOpen}
        customComponent={(onCloseMenu, dropdownId) => (
          <DropdownContent>
            <ButtonClose
              conversation={conversation}
              clickCallback={onCloseMenu}
              onMouseEnter={onCloseAssignDropdown}
            />
            <ButtonMarkAsUnread
              conversation={conversation}
              clickCallback={onCloseMenu}
              onMouseEnter={onCloseAssignDropdown}
            />
            {!conversation.isGroup && (
              <Dropdown
                ariaLabel={'ConversationDropdownGroup'}
                show={showAssignDropdown}
                onChangeOpen={setShowAssignDropdown}
                parentsDropdownId={[dropdownId]}
                width={layoutStore.isXsMobileView ? 204 : 192}
                placement={layoutStore.isXsMobileView ? 'top' : 'right-start'}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: ({ placement }: { placement: Options['placement'] }) => {
                          if (placement === 'top' || placement === 'bottom') {
                            return [-10, 0]
                          }
                          if (placement === 'right-start') {
                            return [-4, -4]
                          }
                          if (placement === 'right-end') {
                            return [4, -4]
                          }
                          return [-4, -4]
                        },
                      },
                    },
                  ],
                }}
                triggerComponent={() => (
                  <Button
                    fontWeight={400}
                    typeBtn='menuItem'
                    icon='userLine'
                    text='Assign'
                    iconRightProps={{
                      icon: 'chevronRight',
                    }}
                    fullWidth
                    onMouseEnter={() => setShowAssignDropdown(true)}
                  />
                )}
                customComponent={(onCloseMenuChild) => (
                  <ConversationAssignDropdownContent
                    store={store.conversationAssignDropdownStore}
                    conversationId={conversation.id}
                    show={showAssignDropdown}
                    onCloseMenu={() => {
                      onCloseMenuChild()
                      onCloseMenu()
                    }}
                  />
                )}
              />
            )}
            <ButtonMakePriority
              conversation={conversation}
              clickCallback={onCloseMenu}
              onMouseEnter={onCloseAssignDropdown}
            />
            <ButtonOptInStatus
              contactsIds={[conversation.contact_id]}
              clickCallback={onCloseMenu}
              onMouseEnter={onCloseAssignDropdown}
            />
            <ButtonMarkAsPending
              conversation={conversation}
              clickCallback={onCloseMenu}
              onMouseEnter={onCloseAssignDropdown}
            />
          </DropdownContent>
        )}
      />
    )
  }
)
