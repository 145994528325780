import classNames from 'classnames'
import { FC, useMemo } from 'react'
import { AttachmentLoader } from 'shared/ui/Loader/AttachmentLoader/AttachmentLoader'
import { AudioPlayer } from 'shared/ui/AudioPlayer'
import { getAriaLabel } from 'shared/lib'
import { ImageCard, VideoCard } from 'entities/Attachment'
import { VCardCard } from 'entities/VCard'
import { Attachment } from 'entities/Attachment/model/Attachment'
import styles from './styles.module.scss'
import { RemovableAttachment } from './RemovableAttachment/RemovableAttachment'
import { AttachmentCardContent } from './AttachmentCardContent/AttachmentCardContent'

type IAttachmentCardProps = {
  attachment?: Attachment
  file?: File
  loading?: boolean
  onRemoveAttachment?: () => void
  onCardClick?: () => void
  isGray?: boolean
  noSource?: boolean
  medium?: boolean
  fromAttachMedia?: boolean
  hideVideo?: boolean
  noLink?: boolean
  fullWidth?: boolean
  isConversationsHistoryView?: boolean
  imgFitType?: 'cover' | 'contain'
  isNoteMode?: boolean
}

export const AttachmentCard: FC<IAttachmentCardProps> = ({
  attachment,
  onCardClick,
  isGray,
  file,
  loading,
  onRemoveAttachment,
  fromAttachMedia,
  medium,
  hideVideo,
  noLink,
  fullWidth,
  isConversationsHistoryView,
  imgFitType,
  isNoteMode,
}) => {
  const isAudio = attachment?.type === 'audio' || file?.type.includes('audio')
  const isVideo = attachment?.type === 'video' || file?.type.includes('video')
  const isImage =
    attachment?.type === 'image' ||
    attachment?.type === 'gif' ||
    attachment?.type === 'previewGif' ||
    file?.type.includes('image')

  const handleCardClick = () => {
    if (attachment?.type === 'attachment') {
      const link = attachment?.source_short || attachment?.source

      if (link) {
        const a = document.createElement('a')

        a.href = link
        a.target = '_blank'
        a.click()
      }
    } else {
      if (isAudio) return

      onCardClick?.()
    }
  }

  const mediaContent = useMemo(() => {
    const url = attachment?.source || (file && URL.createObjectURL(file))

    if (attachment?.type === 'vcard' && !!attachment?.vCard)
      return <VCardCard vCard={attachment.vCard} isGray={isGray} />

    if (isImage)
      return (
        <ImageCard
          medium={medium}
          fullWidth={fullWidth}
          loading={loading}
          file={file}
          attachment={attachment}
          imgFitType={imgFitType}
          onCardClick={onCardClick}
        />
      )

    if (isVideo)
      return (
        <VideoCard
          medium={medium}
          fullWidth={fullWidth}
          loading={loading}
          file={file}
          attachment={attachment}
          onCardClick={onCardClick}
          hideVideo={hideVideo}
          noLink={noLink}
        />
      )

    if (!fromAttachMedia && isAudio)
      return (
        <>
          <AttachmentCardContent attachment={attachment} file={file} fromAudioCard />
          <div className={styles.wrapPlayer}>
            <AudioPlayer
              url={url}
              id={url}
              fromAttachMedia={fromAttachMedia}
              widthWave={134}
              fileName={attachment?.name || file?.name}
              hideTranscriptionButton={isConversationsHistoryView}
            />
          </div>
        </>
      )

    return (
      <button className={styles.card} onClick={handleCardClick} data-name={'attachmentCard'}>
        {isAudio && fromAttachMedia ? (
          <AudioPlayer
            url={url}
            id={url}
            fromAttachMedia={fromAttachMedia}
            widthWave={118}
            hideTranscriptionButton={isConversationsHistoryView}
          />
        ) : (
          <AttachmentCardContent attachment={attachment} file={file} />
        )}
        {loading && <AttachmentLoader />}
      </button>
    )
  }, [loading, attachment, file, isGray, fromAttachMedia, isAudio])

  return (
    <div
      className={classNames(styles.commonWrap, {
        [styles.audio]: isAudio && !fromAttachMedia,
        [styles.attachment]: fromAttachMedia && attachment?.type === 'attachment',
        [styles.vcard]: fromAttachMedia && attachment?.type === 'vcard',
        [styles.videoAttachment]: attachment?.type === 'video',
        [styles.conversationsHistoryView]: isConversationsHistoryView,
        [styles.noteMode]: isNoteMode,
      })}
      aria-label={getAriaLabel('AttachmentCard')}
    >
      {onRemoveAttachment ? (
        <RemovableAttachment
          onRemove={onRemoveAttachment}
          isGray={isImage || isVideo}
          isNoteMode={isNoteMode}
        >
          {mediaContent}
        </RemovableAttachment>
      ) : (
        mediaContent
      )}
    </div>
  )
}
