import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { ISalesForceSettingsDict } from 'entities/Integrations'
import { IntegrationCustomSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCustomSettingsStore'

const KEY = 'enable_timelines'

export class IntegrationSalesforceSettingsTimelineStore {
  private _logActivity = false
  private _disposeCustomSettingStatus: IReactionDisposer | null = null

  constructor(private _customSetting: IntegrationCustomSettingsStore<ISalesForceSettingsDict>) {
    makeAutoObservable(this)
  }

  init = () => {
    this._reactionCustomSettingStatus()
  }

  dispose = () => {
    this._reactionCustomSettingStatus()
  }

  private _reactionCustomSettingStatus = () => {
    this._disposeCustomSettingStatus?.()
    this._disposeCustomSettingStatus = reaction(
      () => this._customSetting.status,
      (status) => {
        if (status.isSuccess) {
          this._logActivity = Boolean(this._customSetting.getValue(KEY))
        }
      }
    )
  }

  get logActivity() {
    return this._logActivity
  }

  onLogActivityToggle = () => {
    runInAction(() => {
      this._logActivity = !this.logActivity
      this._customSetting.patchData([
        {
          key: KEY,
          value: this._logActivity ? 1 : 0,
        },
      ])
    })
  }
}
