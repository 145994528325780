import classNames from 'classnames'
import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Switch } from 'shared/ui'
import { type IntegrationHubspotSettingsTimelineStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsTimelineStore'
import { TitledRow } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/TitledRow/TitledRow'
import styles from './styles.module.scss'

type IIntegrationHubspotSettingsTimelineProps = { store: IntegrationHubspotSettingsTimelineStore }

export const IntegrationHubspotSettingsTimeline: FC<IIntegrationHubspotSettingsTimelineProps> =
  observer(({ store }) => {
    const {
      dailyBundles,
      timelineAssociations,
      onTimelineAssociationsToggle,
      onDailyBundlesToggle,
      onLogeChange,
      getLog,
    } = store

    return (
      <div className={classNames(styles.root, { [styles.expanded]: dailyBundles })}>
        <TitledRow
          title={'Log activities in HubSpot'}
          label={'Create activity in HubSpot for every message, call, voicemail, note'}
          tooltip={
            'Enable this option to log each activity as a separate timeline event in HubSpot. If disabled, activities will be bundled into a single daily recap at 9 PM EST.'
          }
          verticalAlign={'start'}
        >
          <Switch size='middle' value={dailyBundles} onChange={onDailyBundlesToggle} />
        </TitledRow>
        {dailyBundles && (
          <div className={styles.settings}>
            <TitledRow title={'Log calls'}>
              <Switch
                size='middle'
                value={getLog('log_calls')}
                onChange={onLogeChange('log_calls')}
              />
            </TitledRow>
            <TitledRow title={'Log messages'}>
              <Switch
                size='middle'
                value={getLog('log_messages')}
                onChange={onLogeChange('log_messages')}
              />
            </TitledRow>
            <TitledRow title={'Log notes'}>
              <Switch
                size='middle'
                value={getLog('log_notes')}
                onChange={onLogeChange('log_notes')}
              />
            </TitledRow>
            <TitledRow title={'Log voicemail'}>
              <Switch
                size='middle'
                value={getLog('log_voicemails')}
                onChange={onLogeChange('log_voicemails')}
              />
            </TitledRow>
          </div>
        )}

        <TitledRow
          title={'Log activities to all associated objects'}
          label={'New activity will be created in associated objects'}
          verticalAlign={'start'}
        >
          <Switch
            size='middle'
            value={timelineAssociations}
            onChange={onTimelineAssociationsToggle}
          />
        </TitledRow>
      </div>
    )
  })
