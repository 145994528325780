import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'

export const ConversationCloseButton = observer(() => {
  const store = useConversationMessagesContext()
  const icon = store.isClosed ? 'back' : 'check1'
  const titleContent = store.isClosed ? 'Reopen' : 'Close'
  const tooltipLabel = store.isClosed ? 'Reopen conversation' : 'Close conversation'

  const handleClose = async () => {
    await (store.isClosed
      ? store.conversationListStore?.onOpen
      : store.conversationListStore?.onManualClose)?.(store.conversationId)
  }

  return (
    <Button
      size='medium'
      typeBtn='text'
      contained='tertiary'
      icon={icon}
      aria-label={'ConversationCloseButton'}
      tooltipProps={{
        placement: 'bottom',
        label: tooltipLabel,
        disableInteractive: true,
      }}
      titleContent={titleContent}
      onClick={handleClose}
    />
  )
})
