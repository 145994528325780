import axios, { CanceledError, type CancelTokenSource } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { debounce } from 'lodash'
import { logger } from 'shared/lib'
import { type IResponseQuickLinkTemplate, QuickLinkApi } from 'entities/QuickLink/api'
import { type IQuickLinkTemplate, QuickLinkTemplate } from 'entities/QuickLink'

const PER_PAGE = 50

export class QuickLinkListStore {
  private _total = 0
  private _page = 1
  private _limit = PER_PAGE
  private _term = ''

  private _loading = true
  private _initialLoading = true
  private _loadingScheduled = false

  private _cancelTokenSource: CancelTokenSource | null = null
  private _quickLinkTemplates = new Map<number, IQuickLinkTemplate>()

  constructor() {
    makeAutoObservable(this)
  }

  get term() {
    return this._term
  }

  get hasMore() {
    return this._loading || this._page * PER_PAGE < this._total
  }

  get loading() {
    return this._loading
  }

  get initialLoading() {
    return this._initialLoading
  }

  get loadingScheduled() {
    return this._loadingScheduled
  }

  get quickLinkTemplates() {
    return Array.from(this._quickLinkTemplates.values())
  }

  get isEmpty() {
    return !this._quickLinkTemplates.size
  }

  get noTerm() {
    return !this._term.length
  }

  init = () => this.search('', true)

  search = (term: string, immediate = false) => {
    this._loadingScheduled = true
    this._term = term
    this._page = 1

    const makeSearch = async () => {
      runInAction(() => this._quickLinkTemplates.clear())

      const {
        data: { data, current_page, total },
      } = await QuickLinkApi.getLinkTemplates(
        {
          page: this._page,
          length: this._limit,
          search: this._term.trim() || null,
        },
        { cancelToken: this._cancelTokenSource?.token }
      )

      runInAction(() => {
        this._total = total
        this._page = current_page

        this._setQuickLinkTemplates(data)
      })
    }

    immediate ? this._tryMakeRequest(makeSearch) : this._tryMakeRequestDelay(makeSearch)
  }

  loadMore = (immediate = false) => {
    this._loadingScheduled = true

    const makeLoadMore = async () => {
      const {
        data: { data, current_page, total },
      } = await QuickLinkApi.getLinkTemplates(
        {
          page: this._page + 1,
          length: this._limit,
          search: this._term.trim() || null,
        },
        { cancelToken: this._cancelTokenSource?.token }
      )

      runInAction(() => {
        this._total = total
        this._page = current_page

        this._setQuickLinkTemplates(data)
      })
    }

    immediate ? this._tryMakeRequest(makeLoadMore) : this._tryMakeRequestDelay(makeLoadMore)
  }

  refresh = (immediate = true) => {
    this._page = 1

    this.search(this._term, immediate)
  }

  dispose = () => {
    this._cancelTokenSource?.cancel()
  }

  private _setQuickLinkTemplates = (templates: IResponseQuickLinkTemplate[]) => {
    templates.forEach((template) =>
      this._quickLinkTemplates.set(template.id, new QuickLinkTemplate(template))
    )
  }

  private _initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _tryMakeRequest = async (action: () => Promise<void>) => {
    runInAction(() => {
      this._loading = true
      this._initCancelTokenSource()
    })

    try {
      await action()
      runInAction(() => {
        this._loading = false
        this._initialLoading = false
        this._loadingScheduled = false
      })
    } catch (error) {
      runInAction(() => {
        this._loading = error instanceof CanceledError
      })

      logger.error(error)
    }
  }

  private _tryMakeRequestDelay = debounce(this._tryMakeRequest, 500)
}
