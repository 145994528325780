// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RpKRcNs6pfbvyKUtDtyS{display:flex;flex-direction:column;flex:1}.ha5sXpRO7AobW_Kzn3X2{flex:1;position:relative}.kT7hFZtGhx54gwM5OlEV{padding:4px 8px;border-radius:6px;background:var(--background-neutral-default)}._Y79NCzEhxusfKfSMTvD{padding:0 16px;margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/pages/contacts/pages/history/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,MAAA,CAEJ,sBACI,MAAA,CACA,iBAAA,CAEJ,sBACI,eAAA,CACA,iBAAA,CACA,4CAAA,CAEJ,sBACI,cAAA,CACA,iBAAA","sourcesContent":[".historyContainer {\n    display: flex;\n    flex-direction: column; \n    flex: 1;\n}\n.tableContainer {\n    flex: 1;\n    position: relative;\n}\n.rowCell {\n    padding: 4px 8px;\n    border-radius: 6px;\n    background: var(--background-neutral-default);\n}\n.alertContainer {\n    padding: 0 16px;\n    margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"historyContainer": "RpKRcNs6pfbvyKUtDtyS",
	"tableContainer": "ha5sXpRO7AobW_Kzn3X2",
	"rowCell": "kT7hFZtGhx54gwM5OlEV",
	"alertContainer": "_Y79NCzEhxusfKfSMTvD"
};
export default ___CSS_LOADER_EXPORT___;
