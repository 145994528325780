import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { Call, Device } from '@twilio/voice-sdk'
import { nanoid } from 'nanoid'
import { AxiosResponse } from 'axios'
import { links } from 'shared/constants/links'
import { toastStore } from 'shared/ui'
import { NODE_ENV } from 'shared/config'
import { logger, writeTextToClipboard } from 'shared/lib'
import { sentEventSentry } from 'shared/lib/sentEventSentry'
import type {
  ICallStoreSignal,
  IConnectTwilioExtraParams,
  IResponseTwilioContact,
  IResponseTwilioInbox,
} from 'entities/Call'
import { CallApi } from 'entities/Call/api/call'
import { conversationStore } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users/store/users'
import { UsersApi } from 'entities/Users/api/users'
import { DeviceItemsEnum, DeviceSettingsStore } from 'entities/DeviceSettings'
import { Contact } from 'entities/Contacts/model/Contact'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { ActiveCallingStore } from 'entities/Call/store/activeCallingStore'

export class CallStore {
  private activeCallingStore
  private _device: Device | null = null
  private _connect: Call | null = null
  private _token: string | null = null
  private _loading = true
  private _disabled = true
  private _contactTo: Contact | null = null
  private _contactFrom: Inbox | null = null
  private _status: Call.State = Call.State.Closed
  private _started = false
  private _direction: Call.CallDirection | null = null

  private _isRecord = false
  private _isRecordLoading = false

  private _streams: MediaStream[] = []
  private _signal: ICallStoreSignal | null = null

  deviceSettingsStore = new DeviceSettingsStore({
    items: [DeviceItemsEnum.audioOutput, DeviceItemsEnum.audioInput, DeviceItemsEnum.recordAuto],
  })

  private _disposeStatus: IReactionDisposer | null = null
  private _disposeSelectedAudioInput: IReactionDisposer | null = null
  private _disposeSelectedAudioOutput: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)
    this.activeCallingStore = new ActiveCallingStore(this)

    this.reactionStatus()
  }

  get hasActiveCall() {
    return this.activeCallingStore.hasActiveCall
  }

  reset = () => {
    this._direction = null
    this._status = Call.State.Closed
    this.stopStreams()
    this.deviceSettingsStore.removeStream()
  }

  initDevice = async () => {
    try {
      const checkMicrophone = await this.checkMicrophone()

      sentEventSentry({
        event: '[WEB] Check Microphone',
        name: 'calling',
        level: 'log',
        options: {
          enableMicrophone: checkMicrophone,
        },
      })

      if (!checkMicrophone) return

      runInAction(() => {
        this._disabled = false
      })

      const response = await CallApi.createVoiceToken()

      sentEventSentry({
        event: '[Twilio:Calling] token_created',
        name: 'calling',
        level: 'log',
      })

      await usersStore.initPromise

      runInAction(async () => {
        this._token = response.data.token
        this._isRecord = usersStore.isRecordAutomatically

        this.destroyDevice()

        this._device = new Device(this._token, {
          tokenRefreshMs: 60000 * 30,
          logLevel: 3,
          codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
        })

        this.eventsDevice()

        await this._device.register()
      })
    } catch (e) {
      logger.error(e)

      sentEventSentry({
        event: `[Twilio:Calling]: - ${e}`,
        name: 'calling',
        level: 'error',
      })
    }
  }

  destroyDevice = () => {
    this._device?.destroy()
  }

  initDevices = async () => {
    if (!this._device?.audio) return

    const outputDevices = Array.from(this._device.audio.availableOutputDevices.values())

    if (outputDevices.length) {
      await this.setOutputDevice(outputDevices[0].deviceId)
    }
  }

  checkMicrophone = (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      try {
        const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true })

        this.stopStreamInputTrack(stream)
        this.addStream(stream)

        resolve(true)
      } catch (e) {
        resolve(false)
      }
    })
  }

  setInputDevice = async (id: string) => {
    if (!this._device?.audio) return

    try {
      await this._device.audio.unsetInputDevice()
      await this._device.audio.setInputDevice(id)
    } catch (e) {
      logger.error(e)
    }
  }

  setOutputDevice = async (id: string) => {
    if (!this._device?.audio) return

    try {
      await this._device.audio.speakerDevices.set(id)
      await this._device.audio.ringtoneDevices.set(id)
    } catch (e) {
      logger.error(e)
    }
  }

  updateConnect = (connect?: Call) => {
    this._connect = connect ? connect : this._connect

    if (this._connect) {
      this._status = this._connect.status()
      this._direction = this._connect.direction
    }
  }

  hideCall = async () => {
    this.reset()
    await this._device?.audio?.unsetInputDevice()
  }

  // events device
  eventsDevice = () => {
    if (this._device) {
      this._device.on('error', this.eventDeviceError)
      this._device.on('incoming', this.eventDeviceIncoming)
      this._device.on('registered', this.eventDeviceRegistered)
      this._device.on('unregistered', this.eventDeviceUnregistered)
      this._device.on('tokenWillExpire', this.eventDeviceTokenWillExpire)
      this._device?.audio?.on('deviceChange', this.eventDeviceAudioDeviceChange)
    }
  }

  eventDeviceError = async () => {
    this.updateConnect()
    this.log('[Twilio] - eventDeviceError')

    sentEventSentry({
      event: '[Twilio:Calling] Device Error',
      name: 'calling',
      level: 'error',
    })
  }

  eventDeviceIncoming = async (connect: Call) => {
    sentEventSentry({
      event: '[Twilio:Calling] Device Incoming',
      name: 'calling',
      level: 'log',
    })

    this.updateConnect(connect)
    this.eventsConnect()

    const isAway = usersStore.isAwayStatus

    this.handleMuteIncomingNotification(isAway || this.hasActiveCall)

    const contactResponse: IResponseTwilioContact = JSON.parse(
      connect.customParameters.get('contact') || ''
    )
    const inboxResponse: IResponseTwilioInbox = JSON.parse(
      connect.customParameters.get('inbox') || ''
    )

    const [contactTo, contactFrom] = await Promise.all([
      contactsStore.getById(contactResponse.id, true),
      inboxesStore.getById(inboxResponse.id, true),
    ])

    if (!contactTo) return
    if (contactFrom?.type !== 'inbox') return

    this.handleMuteIncomingNotification(contactFrom.isMuted || isAway || this.hasActiveCall)

    runInAction(() => {
      this._contactTo = contactTo
      this._contactFrom = contactFrom
      this._signal = {
        key: nanoid(),
        name: 'outgoing',
      }
    })

    this.log('[Twilio] - eventDeviceIncoming')
  }

  eventDeviceRegistered = async () => {
    this.updateConnect()
    this.log('[Twilio] - eventDeviceRegistered')

    sentEventSentry({
      event: '[Twilio:Calling] Device Registered',
      name: 'calling',
      level: 'log',
    })

    await this.initDevices()

    runInAction(() => {
      this._loading = false
    })
  }

  eventDeviceUnregistered = async () => {
    this.updateConnect()
    this.log('[Twilio] - eventDeviceUnregistered')

    sentEventSentry({
      event: '[Twilio:Calling] Device Unregistered',
      name: 'calling',
      level: 'log',
    })
  }

  eventDeviceTokenWillExpire = async () => {
    this.updateConnect()
    this.log('[Twilio] - eventDeviceTokenWillExpire')

    sentEventSentry({
      event: '[Twilio:Calling] Device TokenWillExpire',
      name: 'calling',
      level: 'log',
    })
  }

  eventDeviceAudioDeviceChange = async () => {
    this.updateConnect()
    this.log('[Twilio] - eventDeviceAudioDeviceChange')

    sentEventSentry({
      event: '[Twilio:Calling] Device AudioDeviceChange',
      name: 'calling',
      level: 'log',
    })
  }

  // events connect
  eventsConnect = () => {
    if (!this._connect) return

    this._connect.on('cancel', this.eventConnectCancel)
    this._connect.on('accept', this.eventConnectAccept)
    this._connect.on('disconnect', this.eventConnectDisconnect)
    this._connect.on('error', this.eventConnectError)
    this._connect.on('mute', this.eventConnectMute)
    this._connect.on('reconnected', this.eventConnectReconnected)
    this._connect.on('reject', this.eventConnectReject)
    this._connect.on('ringing', this.eventConnectRinging)
    this._connect.on('sample', this.eventConnectSample)
    this._connect.on('warning', this.eventConnectWarning)
  }

  eventConnectWarning = (warningName: string, warningData: string) => {
    if (!this._connect) return

    const params = {
      warning: warningName,
      details: warningData,
    }

    this.log('[Twilio] - eventConnectWarning')

    sentEventSentry({
      event: '[Twilio:Calling] Event ConnectWarning',
      name: 'calling',
      level: 'warning',
    })

    return CallApi.updateVoiceCallBySidQualityLog(this._connect.parameters.CallSid, params)
      .then((response: AxiosResponse) => {
        return response.data.success
      })
      .catch((e) => {
        return e.response
      })
  }

  eventConnectCancel = (connect: Call) => {
    this.updateConnect(connect)
    this.hideCall()
    this.log('[Twilio] - eventConnectCancel')

    sentEventSentry({
      event: '[Twilio:Calling] Event ConnectCancel',
      name: 'calling',
      level: 'log',
    })
  }

  eventConnectAccept = (connect: Call) => {
    this.activeCallingStore.sendCallingEvent(true)
    this.updateConnect(connect)
    this.log('[Twilio] - eventConnectAccept')

    sentEventSentry({
      event: '[Twilio:Calling] Event ConnectAccept',
      name: 'calling',
      level: 'log',
    })
  }

  eventConnectDisconnect = (connect: Call) => {
    this.activeCallingStore.sendCallingEvent(false)
    this.updateConnect(connect)
    this.log('[Twilio] - eventConnectDisconnect')
    this.hideCall()

    sentEventSentry({
      event: '[Twilio:Calling] Event ConnectDisconnect',
      name: 'calling',
      level: 'log',
    })
  }

  eventConnectError = () => {
    this.updateConnect()
    this.log('[Twilio] - eventConnectError')

    sentEventSentry({
      event: '[Twilio:Calling] Event ConnectError',
      name: 'calling',
      level: 'error',
    })
  }

  eventConnectMute = (status: string, connect: Call) => {
    this.updateConnect(connect)
    this.log('[Twilio] - eventConnectMute')

    sentEventSentry({
      event: '[Twilio:Calling] Event ConnectMute',
      name: 'calling',
      level: 'log',
    })
  }

  eventConnectReconnected = (connect: Call) => {
    this.updateConnect(connect)
    this.log('[Twilio] - eventConnectReconnected')

    sentEventSentry({
      event: '[Twilio:Calling] Event Reconnected',
      name: 'calling',
      level: 'log',
    })
  }

  eventConnectReject = () => {
    this.updateConnect()
    this.log('[Twilio] - eventConnectReject')

    sentEventSentry({
      event: '[Twilio:Calling] Event Reject',
      name: 'calling',
      level: 'log',
    })
  }

  eventConnectRinging = () => {
    this.updateConnect()
    this.log('[Twilio] - eventConnectRinging')

    sentEventSentry({
      event: '[Twilio:Calling] Event Ringing',
      name: 'calling',
      level: 'log',
    })
  }

  eventConnectSample = () => {
    this.log('[Twilio] - eventConnectSample')

    sentEventSentry({
      event: '[Twilio:Calling] Event Sample',
      name: 'calling',
      level: 'log',
    })
  }

  log = (title: string) => {
    if (NODE_ENV !== 'production') {
      console.log(title, this._status)
    }
  }

  // other methods
  connectTwilio = async (conversationId: number, extraParams?: IConnectTwilioExtraParams) => {
    if (this._status !== Call.State.Closed) return console.warn('Connection already opened')

    this.setStartedCall(true)

    const conversation = await conversationStore.getById({
      id: conversationId,
    })

    if (!conversation) return

    const contactFrom = await inboxesStore.getById(conversation.inbox_id)
    const contactTo = await contactsStore.getById(conversation.contact_id)

    if (contactFrom?.type !== 'inbox') return
    if (!contactTo) return

    if (contactFrom?.isAircall || contactFrom?.isCallViaAircall) {
      if (contactTo?.number) {
        writeTextToClipboard(contactTo.number).then(() => {
          window.open('https://phone.aircall.io/keyboard', '_blank')
        })

        this.setStartedCall(false)
      }

      return
    }

    runInAction(() => {
      this._contactTo = contactTo
      this._contactFrom = contactFrom
      this._signal = {
        key: nanoid(),
        name: 'incoming',
      }
    })

    const isAllowed = await this.checkMicrophone()
    if (!isAllowed) {
      toastStore.add({
        type: 'error',
        title: 'Microphone access required',
        desc: 'To enable calling, please allow Salesmsg to access your microphone.',
        action: {
          link: links.enableMicrophone,
          text: 'Learn more',
        },
      })

      this.setStartedCall(false)

      return
    }

    return new Promise<void>(async (resolve) => {
      if (!this._device) return resolve()
      if (!contactTo?.number) return resolve()

      const connect = await this._device.connect({
        params: {
          To: contactTo.number,
          ConversationId: String(conversation.id),
          ...(extraParams?.powerDialerId && { PowerDialerId: String(extraParams.powerDialerId) }),
        },
      })

      runInAction(() => {
        this._connect = connect
      })

      this.eventsConnect()

      const dispose = reaction(
        () => this._status,
        (status) => {
          if (status === Call.State.Closed) {
            dispose()
            this.setStartedCall(false)
            resolve()
          }
        }
      )
    })
  }

  makeCall = async ({
    type,
    payload,
  }: {
    type: 'phoneNumber'
    payload: {
      phoneNumber: string
      inboxId: number
      numberId: number
    }
  }) => {
    if (type === 'phoneNumber') {
      const { phoneNumber, numberId, inboxId } = payload
      const contact = await contactsStore.getContactByNumber(phoneNumber, true)
      if (contact) {
        const conversation = await conversationStore.createConversation({
          contact_id: contact.id,
          team_id: inboxId,
          number_id: numberId || undefined,
        })

        if (conversation) {
          return callStore.connectTwilio(conversation.id)
        }
      }
    }
  }

  disconnectTwilio = (isClientDisconnect = true) => {
    if (isClientDisconnect) {
      if (this._status === Call.State.Pending) {
        this._connect?.reject()
      }
    }

    this._device?.disconnectAll()
    return this.hideCall()
  }

  acceptTwilio = () => {
    this._connect?.accept()
    this.updateConnect()
  }

  sendDigitsTwilio = (value: string) => {
    this._connect?.sendDigits(value)
  }

  testOutputDevice = () => {
    if (!this._device?.audio) return

    this._device.audio.ringtoneDevices.test()
  }

  stopStreamInputTrack = (stream?: MediaStream | null) => {
    if (!stream) return

    stream.getAudioTracks().forEach((track) => {
      track.stop()
    })
  }

  stopStreamInputDeviceTrack = () => {
    this.stopStreamInputTrack(this._device?.audio?.inputStream)
  }

  addStream = (stream: MediaStream) => {
    this._streams.push(stream)
  }

  stopStreams = () => {
    this._streams.forEach((stream) => {
      this.stopStreamInputTrack(stream)
    })

    const stream = this._connect?.getLocalStream()

    this.stopStreamInputTrack(stream)

    this._streams = []
  }

  handleMute = (status: boolean) => {
    if (!this._connect) return

    this._connect?.mute(status)
  }

  handleMuteIncomingNotification = (status: boolean) => {
    if (!this._device) return

    try {
      this._device.audio?.incoming(status)

      if (status) {
        // TODO: https://github.com/twilio/twilio-voice.js/issues/222
        this._device?._soundcache.get(Device.SoundName.Incoming).stop()
      } else {
        this._device?._soundcache.get(Device.SoundName.Incoming).play()
      }
    } catch (e) {
      logger.error(e)
    }
  }

  handleChangeRecord = async () => {
    try {
      runInAction(() => {
        this._isRecord = !this._isRecord
        this._isRecordLoading = true
      })

      await UsersApi.updateUsersToggleRecordAutomatically({
        isRecordAutomatically: this._isRecord,
      })

      runInAction(() => {
        usersStore.isRecordAutomatically = this._isRecord
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._isRecordLoading = false
      })
    }
  }

  setStartedCall = (value: boolean) => {
    this._started = value
  }

  reactionSelectedAudioOutput = () => {
    this._disposeSelectedAudioOutput?.()
    this._disposeSelectedAudioOutput = reaction(
      () => this.deviceSettingsStore.device.audioOutput,
      (value) => {
        if (!value) return
        if (this._status !== Call.State.Closed) return

        this.setOutputDevice(value.deviceId)
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionSelectedAudioInput = () => {
    this._disposeSelectedAudioInput?.()
    this._disposeSelectedAudioInput = reaction(
      () => this.deviceSettingsStore.device.audioInput,
      (value) => {
        if (!value) return
        if (this._status !== Call.State.Closed) return

        this.deviceSettingsStore.removeStream()
        this.setInputDevice(value.deviceId)
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionStatus = () => {
    this._disposeStatus?.()
    this._disposeStatus = reaction(
      () => this._status,
      async (value) => {
        if (value === Call.State.Open) {
          this.setStartedCall(true)
        }
        if (value === Call.State.Closed) {
          this.setStartedCall(false)
        }
      }
    )
  }

  get isIncoming() {
    return this._direction === Call.CallDirection.Incoming
  }

  get isOutgoing() {
    return this._direction === Call.CallDirection.Outgoing
  }

  get isStatusClosed() {
    return this._status === Call.State.Closed
  }

  get isAcceptVoice() {
    if (this.isIncoming) {
      return this._status === Call.State.Open
    }

    return this.isOutgoing
  }

  get isShowCall() {
    if (inboxesStore.selectInbox?.type !== 'inbox') return true

    return inboxesStore.selectInbox?.isNumberOutboundCalls
  }

  get isStatusClose() {
    return this._status === Call.State.Closed
  }

  get isBusy() {
    return this._started || this._status !== Call.State.Closed
  }

  get device() {
    return this._device
  }

  get connect() {
    return this._connect
  }

  get loading() {
    return this._loading
  }

  get disabled() {
    return this._disabled
  }

  get contactTo() {
    return this._contactTo
  }

  get contactFrom() {
    return this._contactFrom
  }

  get status() {
    return this._status
  }

  get isRecord() {
    return this._isRecord
  }

  get isRecordLoading() {
    return this._isRecordLoading
  }

  get signal() {
    return this._signal
  }

  get startedCall() {
    return this._started
  }
}

export const callStore = new CallStore()
