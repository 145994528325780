import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import {
  IParamsAutorecharge,
  IParamsUpdateBillingCredits,
  IResponseBillingAdditionalCreditsPrice,
  IResponseBillingApplyCoupon,
  IResponseBillingAutorecharge,
  IResponseBillingSources,
  IResponsePlans,
  IResponseUpdateBillingCredits,
  IResponseBillingStates,
  IResponseBillingAreaCodes,
  IResponseAdditionalCreditPrice,
  IResponseBillingSource,
  IParamsCreateSource,
  IResponseCoupon,
} from './types'

class Api {
  getBillingAdditionalCreditPrice(): Promise<
    AxiosResponse<IResponseBillingAdditionalCreditsPrice>
  > {
    return baseApi.get('billing/additional-credit-price')
  }

  getBillingSources(): Promise<AxiosResponse<IResponseBillingSources>> {
    return baseApi.get('core/billing/sources')
  }
  createSource(params: IParamsCreateSource): Promise<AxiosResponse<IResponseBillingSource>> {
    return baseApi.put('core/billing/sources', params)
  }

  applyCoupon(code: string): Promise<AxiosResponse<IResponseBillingApplyCoupon>> {
    return baseApi.post('core/billing/coupons/' + code)
  }

  getBillingAutoRecharge(): Promise<AxiosResponse<IResponseBillingAutorecharge>> {
    return baseApi.get('core/billing/auto-recharge')
  }

  updateBillingAutoRecharge(
    params: IParamsAutorecharge
  ): Promise<AxiosResponse<IResponseBillingAutorecharge>> {
    return baseApi.post('core/billing/auto-recharge', params)
  }

  updateBillingCredits(
    params: IParamsUpdateBillingCredits
  ): Promise<AxiosResponse<IResponseUpdateBillingCredits>> {
    return baseApi.post('billing/credits', params)
  }

  getPlans(): Promise<AxiosResponse<IResponsePlans>> {
    return baseApi.get('billing/plans')
  }

  getBillingStates(): Promise<AxiosResponse<IResponseBillingStates>> {
    return baseApi.get('billing/states')
  }

  getBillingAreaCodes(): Promise<AxiosResponse<IResponseBillingAreaCodes>> {
    return baseApi.get('billing/area-codes')
  }
  getAdditionalCreditPrice(): Promise<AxiosResponse<IResponseAdditionalCreditPrice>> {
    return baseApi.get('billing/additional-credit-price')
  }

  getCoupon(coupon: string): Promise<AxiosResponse<IResponseCoupon>> {
    return baseApi.post(`billing/coupons/${coupon}`)
  }
}

export const BillingApi = new Api()
