import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { getCDNAssetURL, logger } from 'shared/lib'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { ComplianceApi } from 'entities/Compliance'
import PresentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { type ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'

export class CommonOptInContentStore {
  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    makeAutoObservable(this)
  }
  private _onlineFormUrlLocalError = ''
  private _onlineFormUrl = ''
  private _isCorrectOnlineFormUrl = false
  private _noPublishedOnlineFormUrl = false
  private _termsAndPrivacyAgreement = false
  private _similarityDomainsWarning = ''

  get onlineFormUrlLocalError() {
    return this._onlineFormUrlLocalError
  }

  get onlineFormUrl() {
    return this._onlineFormUrl
  }

  get isCorrectOnlineFormUrl() {
    return this._isCorrectOnlineFormUrl
  }

  get noPublishedOnlineFormUrl() {
    return this._noPublishedOnlineFormUrl
  }

  get termsAndPrivacyAgreement() {
    return this._termsAndPrivacyAgreement
  }

  get similarityDomainsWarning() {
    return this._similarityDomainsWarning
  }

  setIsCorrectOnlineFormUrl = (isCorrect: boolean) => {
    this._isCorrectOnlineFormUrl = isCorrect
  }

  setOnlineFormUrlLocalError = (error: string) => {
    this._onlineFormUrlLocalError = error
  }

  get isOnlineFormUrl() {
    return (
      (this.onlineFormUrl.length && !this.onlineFormUrlLocalError) || this.noPublishedOnlineFormUrl
    )
  }

  resetSimilarityDomainsWarningAddError = (error: string) => {
    this.setSimilarityDomainsWarning('')
    this.setOnlineFormUrlLocalError(error)
  }

  checkEmailValidation = async (onlineFormUrl: string) => {
    const errorText = 'Please enter a valid url (e.g. https://www.example.com/)'
    try {
      const { data } = await ComplianceApi.onlineFormUrlCheck({ onlineFormUrl })

      if (!data.reachable) {
        this.resetSimilarityDomainsWarningAddError(errorText)

        return
      }
      if (!this.noPublishedOnlineFormUrl && data.reachable) {
        this.setIsCorrectOnlineFormUrl(data.reachable)
      }

      const { website_url } = this._complianceLayerStore.businessInformation || {}

      if (!data.domain_check && website_url) {
        this.setSimilarityDomainsWarning(
          "It looks like your Online Form URL domain doesn't match your business website domain"
        )
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const { message } = error.response?.data || {}

        if (message) {
          this.resetSimilarityDomainsWarningAddError(message)
        }
      }
      logger.error(error)
    }
  }

  setSimilarityDomainsWarning = (error: string) => {
    this._similarityDomainsWarning = error
  }

  checkEmail = (url: string) => {
    this.setSimilarityDomainsWarning('')
    this.setOnlineFormUrlLocalError('')

    this.checkEmailValidation(url)
  }

  setNoPublishedOnlineFormUrl = (value: boolean) => {
    this._noPublishedOnlineFormUrl = value
  }

  setOnlineFormUrl = (value: string, isCorrect = false) => {
    this.setIsCorrectOnlineFormUrl(isCorrect)

    this._onlineFormUrl = value
  }

  handleNoPublishedOnlineFormUrl = (value: boolean) => {
    if (!this.noPublishedOnlineFormUrl) {
      this._onlineFormUrl = ''
      this.setIsCorrectOnlineFormUrl(false)
      this._onlineFormUrlLocalError = ''
      this._similarityDomainsWarning = ''
    }

    this.setNoPublishedOnlineFormUrl(value)
  }

  setTermsAndPrivacyAgreement = () => {
    this._termsAndPrivacyAgreement = !this.termsAndPrivacyAgreement
  }

  openApprovedExampleModal = (urls: string[], name: string[]) => {
    const attachments = urls.map(
      (url, index) => new Attachment({ imageUrl: getCDNAssetURL(url), name: name[index] })
    )

    PresentationStore.setAttachments(attachments)
  }
}
