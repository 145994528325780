import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useUnsavedChanges } from 'shared/hooks'
import { subscriptionStore } from 'entities/Subscription'
import { TrialContactsLimitAlert } from 'entities/Subscription/ui/TrialContactsLimitAlert'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { ContactsTable } from 'pages/contacts/ui/ContactsTable'
import { ContactsFilters } from 'pages/contacts/ui/ContactsFilters'
import { CleanupAlert } from 'pages/contacts/ui/CleanupAlert'
import { ContactsContainerActions } from 'pages/contacts/ui/ContactsContainerActions'
import { AddFirstContactContent } from 'pages/contacts/ui/AddFirstContactContent/AddFirstContactContent'
import { useContactsPageContext } from 'pages/contacts/context/contactsPageContext'
import styles from './styles.module.scss'

export const ContactsContent = observer(() => {
  const contactsPageStore = useContactsPageContext()
  const {
    contactsMenuStore,
    contactsTableStore,
    segmentModalStore,
    contactsFilterStore,
    importContactsStore,
  } = contactsPageStore
  const { isEmptyContactsList, isNoContactYet, noEmptyFilters, setActiveContact, getTotalAmount } =
    contactsTableStore
  const { isNeedSaveChangesModal, onToggle, segmentBox, filterEditorStore } = contactsFilterStore
  const { activeSegment } = contactsMenuStore
  const { getAskIfSaveModal } = segmentModalStore
  const { isTrial } = subscriptionStore

  useEffect(() => {
    setActiveContact(null)

    if (isTrial) {
      getTotalAmount()
    }
  }, [])

  useUnsavedChanges(isNeedSaveChangesModal && segmentBox.hasChanges, (blocker) =>
    getAskIfSaveModal({
      onToggle,
      blocker,
      disabled: !filterEditorStore.groups.isReady,
      filtersList: noEmptyFilters,
    })
  )

  if (isNoContactYet && !activeSegment) {
    return (
      <AddFirstContactContent
        contactsTableStore={contactsTableStore}
        importContactsStore={importContactsStore}
      />
    )
  }

  const iconType = activeSegment ? 'filter' : 'search'
  const label = activeSegment ? 'No matching contacts' : 'No results found'

  return (
    <div className={styles.contentContainer}>
      <ContactsContainerActions
        contactsMenuStore={contactsMenuStore}
        contactsTableStore={contactsTableStore}
        importContactsStore={importContactsStore}
      />
      <CleanupAlert />
      <TrialContactsLimitAlert totalAmount={contactsTableStore.totalAmount} />
      <ContactsFilters
        contactsFilterStore={contactsFilterStore}
        segmentModalStore={segmentModalStore}
      />
      <div style={{ display: 'flex', height: '100%' }}>
        {isEmptyContactsList ? (
          <NoResultsFound label={label} icon={iconType} />
        ) : (
          <ContactsTable store={contactsTableStore} />
        )}
      </div>
    </div>
  )
})
