import { makeAutoObservable } from 'mobx'
import { CHAR_MAX_LENGTH } from 'shared/constants/limits'

type INameInputStoreProps = {
  focusOnMount?: boolean
  limit?: number
}

export class NameInputStore {
  focusOnMount = false
  limit = CHAR_MAX_LENGTH
  constructor(props?: INameInputStoreProps) {
    if (props?.focusOnMount) {
      this.focusOnMount = props.focusOnMount
    }
    if (props?.limit) {
      this.limit = props.limit
    }
    makeAutoObservable(this)
  }

  name = ''
  setName = (value: typeof this.name) => {
    this.name = value
  }

  error = ''
  setError = (value: typeof this.error) => {
    this.error = value
  }

  init = (name: string) => {
    this.setName(name.slice(0, this.limit))
  }
}
