import { observer } from 'mobx-react-lite'
import { SwitchAction } from 'shared/ui/SwitchAction'
import { DividerCustom } from 'shared/ui'
import { IKeyword } from 'entities/Keywords'
import { IResponseKeyword } from 'entities/Keywords/api/type'

type IKeywordActionProps = {
  keyword: IKeyword
  toggleKeywordStatus: (trigger: IKeyword) => Promise<IResponseKeyword>
}

export const KeywordSwitchAction = observer(
  ({ keyword, toggleKeywordStatus }: IKeywordActionProps) => (
    <>
      <SwitchAction
        size={'xSmall'}
        active={keyword.status === 'active'}
        onTrigger={() =>
          toggleKeywordStatus(keyword).then((updatedKeyword) => keyword.syncOrigin(updatedKeyword))
        }
        ariaLabel='Keyword'
        label={keyword.status === 'active' ? 'Active' : 'Disabled'}
      />
      <DividerCustom variant={'dropdown'} />
    </>
  )
)

export const KeywordSwitch = observer(({ keyword, toggleKeywordStatus }: IKeywordActionProps) => {
  return (
    <SwitchAction
      size={'middle'}
      active={keyword.status === 'active'}
      onTrigger={() =>
        toggleKeywordStatus(keyword).then((updatedKeyword) => keyword.syncOrigin(updatedKeyword))
      }
      ariaLabel='Keyword'
    />
  )
})
