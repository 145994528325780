import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { IntegrationSettingsPropertyDropdown } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSettingsPropertyDropdown/IntegrationSettingsPropertyDropdown'
import { IntegrationSettingsPropertyTable } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSettingsPropertyTable/IntegrationSettingsPropertyTable'
import { type IntegrationHubspotSettingsPropertyStore } from '../../store/IntegrationHubspotSettingsPropertyStore'
import styles from './styles.module.scss'

type IIntegrationHubspotSettingsPropertyProps = { store: IntegrationHubspotSettingsPropertyStore }

export const IntegrationHubspotSettingsProperty: FC<IIntegrationHubspotSettingsPropertyProps> =
  observer(({ store }) => {
    const {
      rows,
      showSaveControl,
      saveNewProperties,
      clearNewProperties,
      loading,
      customPropertyStore,
      integrationName,
    } = store

    return (
      <div className={styles.root}>
        <IntegrationSettingsPropertyTable
          rows={rows}
          loading={loading}
          integrationName={integrationName}
          integrationIcon={'hubspot'}
        />
        <div className={styles.footer}>
          <IntegrationSettingsPropertyDropdown store={customPropertyStore} rows={rows} />
          {showSaveControl && (
            <div className={styles.control}>
              <Button
                text='Cancel'
                typeBtn='contained'
                contained='secondary'
                size='medium'
                onClick={clearNewProperties}
              />
              <Button
                text='Save'
                typeBtn='contained'
                contained='primary'
                size='medium'
                onClick={saveNewProperties}
              />
            </div>
          )}
        </div>
      </div>
    )
  })
