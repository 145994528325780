import { logger } from 'shared/lib/logger'

export const writeTextToClipboard = async (text: string) => {
  try {
    await window.navigator.clipboard.writeText(text)
  } catch (error) {
    const textarea = document.createElement('textarea')
    document.body.appendChild(textarea)
    textarea.innerText = text
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
    logger.error(error)
  }
}
