import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { NameInput } from 'features/createOrEdit/NameInput'
import { useCampaignViewContext } from 'pages/campaigns/ui/CampaignView/context/context'
import styles from './styles.module.scss'

export const Header = observer(() => {
  const { nameInputStore, onSettings, isEdit } = useCampaignViewContext()
  return (
    <div className={styles.wrap}>
      <div className={styles.wrapLabel}>
        <Typography ariaLabel={'newCampaign'} variant={'body-xs-regular'} className={styles.label}>
          {isEdit ? 'Edit' : 'New'} campaign
        </Typography>
      </div>
      <div className={styles.wrapActions}>
        <NameInput store={nameInputStore} placeholder={'Campaign name'} />
        <Button
          size={'medium'}
          contained={'secondary'}
          icon={'settings'}
          text={'Settings'}
          onClick={onSettings}
        />
      </div>
    </div>
  )
})
