import { observer } from 'mobx-react-lite'
import { MouseEvent, FC } from 'react'
import { Box, Button } from 'shared/ui'
import { SegmentModalStore } from 'pages/contacts/ui/ContactsMenu/ui/SegmentModal'

type IModalActionsProps = {
  successText: string
  successAction: () => void
  segmentModalStore: SegmentModalStore
}

export const ModalActions: FC<IModalActionsProps> = observer(
  ({ successText, successAction, segmentModalStore }) => {
    const { disabledCreating } = segmentModalStore

    const closeModal = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      segmentModalStore.closeModal()
    }

    const onSuccessAction = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      successAction()
      segmentModalStore.closeModal()
    }

    return (
      <Box display='flex' gap={1}>
        <Button text='Cancel' typeBtn='contained' contained='secondary' onClick={closeModal} />
        <Button
          text={successText}
          disabled={disabledCreating}
          typeBtn='contained'
          onClick={onSuccessAction}
        />
      </Box>
    )
  }
)
