import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { Campaign, type ICreateCampaignStep } from 'entities/Campaign'
import { CampaignStepStore } from './store/CampaignStepStore'
import { CampaignStepContext } from './context/context'
import { CampaignStepUi } from './ui/CampaignStepUi'

export type IStepTableProps = {
  activeStep: ICreateCampaignStep
  campaign: Campaign
}

export const CampaignStep = observer(({ activeStep, campaign }: IStepTableProps) => {
  const store = useMemo(() => new CampaignStepStore(campaign, activeStep), [activeStep])

  useEffect(() => {
    return store.dispose
  }, [])
  return (
    <CampaignStepContext.Provider value={store}>
      <CampaignStepUi />
    </CampaignStepContext.Provider>
  )
})
