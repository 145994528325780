import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { AxiosOptions } from 'shared/api'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { ConversationsApi } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import { usersStore } from 'entities/Users'
import { inboxesStore } from 'entities/Inbox'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { numbersStore } from 'entities/Phone'
import { conversationTemplate } from 'entities/Conversation/templates/conversationTemplate'
import {
  IParamsConversationCreate,
  IResponseConversation,
  IResponseConversationDraftMessage,
} from 'entities/Conversation/api/types'
import { IResponseUser } from 'entities/Users/api/types'
import { ConversationDraftMessage } from 'entities/Conversation/model/ConversationDraftMessage'
import { type Contact } from 'entities/Contacts/model/Contact'
import { IConversationSignal } from 'entities/Conversation/store/types'
import { Conversation } from '../model/Conversation'

export class ConversationsStore {
  private _itemsMap: Map<number, Conversation> = new Map()
  private _currentItemId: number | null = null
  private _prevItemId: number | null = null
  private _nextItemId: number | null = null
  private _itemsDraftMap: Map<number, ConversationDraftMessage> = new Map()
  private _modeNotOpen = false
  private _signal: IConversationSignal | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get currentItem() {
    if (this._currentItemId === null) return

    return this.getItem(this._currentItemId)
  }

  get isNewConversation() {
    return this._currentItemId === 0
  }

  get itemsList() {
    return Array.from(this._itemsMap.values())
  }

  get isEmpty() {
    return this._itemsMap.size === 0
  }

  get currentItemId() {
    return this._currentItemId
  }

  get prevItemId() {
    return this._prevItemId
  }

  get nextItemId() {
    return this._nextItemId
  }

  get modeNotOpen() {
    return this._modeNotOpen
  }

  get signal() {
    return this._signal
  }

  setSignal = (signal: IConversationSignal) => {
    this._signal = signal

    if (signal.type === 'open_new_conversation') {
      return this.handleOpenNewConversation(signal.payload)
    }

    if (signal.type === 'open_exist_conversation') {
      return this.handleOpenExistingConversation(signal.payload)
    }

    if (signal.type === 'open_group_conversation') {
      this.reset()
      this.resetCurrentItem()
      uiStore.changeRoute({
        path: `/conversations/${signal.payload.conversation.id}`,
      })
    }
  }

  clearSignal = () => {
    this._signal = null
  }

  setCurrentItemId = (id: number | null) => {
    if (id) this._prevItemId = id

    this._currentItemId = id
  }

  setModeNotOpen = (status: boolean) => {
    this._modeNotOpen = status
  }

  setNextItemId = (id: number | null) => {
    this._nextItemId = id
  }

  private _updateContacts = (item: IResponseConversation) => {
    const participants = Array.isArray(item.participants) ? item.participants : []

    contactsStore.addItems([...(item.contact ? [item.contact] : []), ...participants])
  }

  private addItem = (item: IResponseConversation) => {
    const conversation = new Conversation(item)

    this._itemsMap.set(item.id, conversation)

    this._updateContacts(item)

    if (item.owner) {
      usersStore.addItem(item.owner)
    }

    if (item?.recent_message?.user) {
      usersStore.addItem(item.recent_message.user)
    }

    if (item.number) {
      numbersStore.addItem(item.number)
    }

    return conversation
  }

  addItems = (conversations: IResponseConversation[]) => {
    conversations.forEach((item) => {
      this.addItem(item)
      this.addItemDraft(item.draft_message)
    })
  }

  getItem = (id?: number) => {
    if (typeof id !== 'number') return

    return this._itemsMap.get(id)
  }

  deleteItem = (id: number) => {
    this._itemsMap.delete(id)
  }

  hasItem = (id: number) => {
    return this._itemsMap.has(id)
  }

  getItemDraft = (id: number) => {
    return this._itemsDraftMap.get(id)
  }

  addItemDraft = (item?: IResponseConversationDraftMessage | null) => {
    if (!item) return

    this._itemsDraftMap.set(item.conversation_id, new ConversationDraftMessage(item))
  }

  deleteItemDraft = (id: number) => {
    this._itemsDraftMap.delete(id)
  }

  cancelDebounceOnCreateDraft = () => {
    if (this._currentItemId) {
      const conversation = this.getItem(this._currentItemId)

      conversation?.cancelDebounceOnCreateDraft()
    }
  }

  updateItem = (data: IResponseConversation) => {
    const conversation = this.getItem(data.id)

    if (conversation) {
      conversation.syncOrigin({
        ...conversation.origin,
        ...data,
      })

      this._updateContacts(data)
    } else {
      this.addItem(data)
    }
  }

  resetCurrentItem = () => {
    if (!this.isNewConversation) {
      this._currentItemId = null
    }
  }

  reset = () => {
    this._itemsMap.clear()
    this._itemsDraftMap.clear()
    this._nextItemId = null
    this._modeNotOpen = false
  }

  onRead = async (id: number) => {
    try {
      const conversation = conversationStore.getItem(id)

      if (!conversation?.isUnread && !conversation?.isUnreadManual) return

      const { data } = await ConversationsApi.updateByIdRead(id)
      this.addItems([data])
    } catch (e) {
      console.error(e)
    }
  }

  onUnread = async (id: number) => {
    try {
      const { data } = await ConversationsApi.updateByIdUnread(id)
      this.addItems([data])
    } catch (e) {
      console.error(e)
    }
  }

  getById = async ({
    id,
    update = false,
    isModalError = false,
    isFetch = false,
    options,
  }: {
    id: number
    update?: boolean
    isModalError?: boolean
    isFetch?: boolean
    options?: AxiosOptions
  }) => {
    try {
      let conversation

      if (!isFetch) conversation = this.getItem(id)

      if (!conversation) {
        const { data } = await ConversationsApi.getById(id, options)

        conversation = new Conversation(data)

        if (update) {
          this.updateItem(data)
        }

        const users: IResponseUser[] = []
        const contacts = [...(data.contact ? [data.contact] : []), ...data.participants]

        if (data?.owner) {
          users.push(data.owner)
        }

        if (data?.recent_message?.user) {
          users.push(data.recent_message.user)
        }

        usersStore.addItems(users)
        contactsStore.addItems(contacts)
      }

      return conversation
    } catch (e) {
      console.error(e)
      if (e instanceof AxiosError) {
        if (e.response?.data.message === 'Unauthorized action.' && isModalError) {
          modalStore.addModal({
            disabledOnAllClose: true,
            type: ModalTypeList.INFO,
            title: 'Content unavailable',
            id: nanoid(),
            desc: "We're sorry, but you don't currently have access to this content or it was deleted. Please contact the user who shared the link with you to request access.",
          })
        }
      }
    }
  }

  createConversation = async ({
    contact_id,
    participants,
    team_id,
    number_id,
  }: IParamsConversationCreate) => {
    try {
      const params = {
        contact_id: contact_id,
        participants: participants,
        team_id: team_id,
        number_id: number_id,
      }

      const { data } = await ConversationsApi.create(params)

      this.updateItem(data)
      if (data.owner) usersStore.addItem(data.owner)
      contactsStore.addItems([...(data.contact ? [data.contact] : []), ...data.participants])

      return this.getItem(data.id)
    } catch (e) {
      console.error(e)
    }
  }

  createNewConversation = (contact?: Contact) => {
    const newConversation = {
      ...conversationTemplate,
      id: 0,
      contact_id: contact?.id ?? 0,
      inbox_id: inboxesStore.currentInboxId,
      user_id: usersStore.user_owner_id,
      closed_at: '',
      is_group: false,
      is_locked: true,
      last_message_at: '',
      last_inbound_at: '',
      last_read_at: '',
      group_name: null,
      participants: [],
      number_id: 0,
    } as IResponseConversation
    const newContact = contact?.origin ?? contactsStore.createEmptyContact()

    this.addItem(newConversation)
    contactsStore.updateItem(newContact)
  }

  changePriority = async (id: number, priority: boolean) => {
    try {
      await ConversationsApi.updatePriority(id, priority)
      const conversation = this.getItem(id)
      if (conversation) {
        conversation.updatePriority(priority)
      }
    } catch (e) {
      console.error(e)
    }
  }

  reassign = async (id: number, user_id: number | null) => {
    try {
      const { data } = await ConversationsApi.updateByIdReassign(id, user_id)
      this.updateItem(data)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  reassignBulk = async (
    conversations_ids: number[],
    members_ids: number[],
    team_id: number,
    reassign_all: boolean,
    conversation_filter?: string
  ) => {
    try {
      const { data } = await ConversationsApi.updateByIdReassignBulk({
        conversations_ids,
        members_ids,
        team_id,
        reassign_all,
        conversation_filter,
      })

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  handleOpenNewConversation = (contact?: Contact) => {
    this.createNewConversation(contact)
    this.setCurrentItemId(0)
    uiStore.changeRoute({
      path: '/conversations/0',
    })
  }

  handleCloseNewConversation = () => {
    conversationStore.setCurrentItemId(null)
    this.deleteItem(0)
    uiStore.changeRoute({
      path: '/conversations',
    })
  }

  handleOpenExistingConversation = async (conversation: IResponseConversation) => {
    const inboxId = conversation.inbox_id

    await Promise.all([
      inboxesStore.handleUpdateTeamInbox(inboxId),
      conversationStore.createConversation({
        contact_id: conversation.contact_id,
        team_id: inboxId,
      }),
    ])

    conversationStore.setCurrentItemId(conversation.id)
    uiStore.changeRoute({ path: `/conversations/${conversation.id}`, query: '?decline_onload=' })
  }

  get conversationInbox() {
    const inbox = inboxesStore.getItem(this.currentItem?.inbox_id)

    if (inbox?.id === 0) return inboxesStore.sortedFirstInbox

    return inboxesStore.currentInbox || inbox
  }

  get conversationContact() {
    const contactId = this.currentItem?.contact_id
    if (!contactId) return null

    const contact = contactsStore.getItem(contactId)
    if (!contact) return null

    return contact
  }

  get conversationInboxNumber() {
    const inbox =
      (this.conversationInbox instanceof Inbox && this.conversationInbox) ||
      (inboxesStore.currentInbox instanceof Inbox && inboxesStore.currentInbox)

    const numberFromCurrentConversation = numbersStore.getItem(this.currentItem?.number_id)
    const numberFromInbox =
      inbox instanceof Inbox ? numbersStore.getItem(inbox.numberId) : undefined

    return numberFromCurrentConversation || numberFromInbox
  }

  get isStatusUnverified() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusUnverified &&
        !this.conversationInboxNumber?.is_aircall
    )
  }

  get isStatusDeclined() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusDeclined &&
        !this.conversationInboxNumber?.is_aircall
    )
  }

  get isStatusBlockedNumber() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree && this.conversationInboxNumber?.isStatusBlocked
    )
  }

  get isStatusBlockedContact() {
    if (!this.conversationContact) return false

    return Boolean(this.conversationContact?.is_blocked)
  }

  get isStatusNumberBlocked() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusBlocked &&
        !this.conversationInboxNumber?.is_aircall
    )
  }

  get isStatusBlocked() {
    return Boolean(
      (this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusBlocked &&
        !this.conversationInboxNumber?.is_aircall) ||
        this.isStatusBlockedContact
    )
  }

  get isStatusPending() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        !this.conversationInboxNumber?.is_aircall &&
        (this.conversationInboxNumber?.isStatusUnderVerification ||
          this.conversationInboxNumber?.isStatusInternalReview)
    )
  }

  get isStatusVerified() {
    return Boolean(
      this.conversationInboxNumber?.isTollFree &&
        this.conversationInboxNumber?.isStatusVerified &&
        !this.conversationInboxNumber?.is_aircall
    )
  }

  get isActionDisabled() {
    const allNumberIds: number[] = []

    inboxesStore.inboxesList.forEach((inbox) => {
      if (inbox instanceof Inbox) {
        allNumberIds.push(...inbox.numberIds)
      }
    })

    return allNumberIds.every((numberId) => {
      const number = numbersStore.getItem(numberId)
      return Boolean(number?.isTollFree && !number?.isStatusVerified && !number?.is_aircall)
    })
  }

  get isMessageLocal() {
    const conversation = conversationStore.currentItem
    let inbox = inboxesStore.getItem(conversation?.inbox_id)
    if (this.isNewConversation) {
      inbox = inboxesStore.currentInbox
    }

    if (inbox?.type !== 'inbox') return false

    const number = numbersStore.getItem(inbox?.numberId)
    const contact = this.conversationContact

    if (contact?.isOptOutHard) return false
    if (number?.isShortCode) return false

    return this.isOptOut || this.isOptOutCurrentInbox
  }

  get isMessageAll() {
    const conversation = conversationStore.currentItem
    let inbox = inboxesStore.getItem(conversation?.inbox_id)
    if (this.isNewConversation) {
      inbox = inboxesStore.currentInbox
    }

    if (inbox?.type !== 'inbox') return false

    const number = numbersStore.getItem(inbox?.numberId)
    const contact = this.conversationContact

    if (!contact) return false
    if (number?.isShortCode) return this.isOptOutCurrentInbox
    if (contact?.isOptOutHard) return true

    return Boolean(
      contact?.optItems.length === contact?.optItems.filter((item) => !item.value).length
    )
  }

  get isOptOut() {
    if (!this.conversationContact) return false

    return Boolean(this.conversationContact?.isOptOut)
  }

  get isOptOutCurrentInbox() {
    if (!this.conversationContact) return false

    return Boolean(this.conversationContact?.isOptOutCurrentInbox)
  }
}

export const conversationStore = new ConversationsStore()
