import callingVoiceIntegrity from 'shared/assets/images/compliance/callingVoiceIntegrity.png'
import { getCDNAssetURL } from 'shared/lib'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { useCallingStepsContext } from 'pages/settings/pages/compliance/pages/calling/context/callingStepsContext'
import styles from './styles.module.scss'

export const EnableVoiceIntegrity = () => {
  const {
    voiceIntegrityStore: { movetoVoiceIntegritySecondStep },
  } = useCallingStepsContext()
  return (
    <div>
      <PageDescription>Remediate “Spam Likely” labels on your calls</PageDescription>

      <img
        src={getCDNAssetURL(callingVoiceIntegrity)}
        alt={'callingVoiceIntegrity_image'}
        className={styles.voiceIntegrityImage}
      />

      <NextButton text='Next' onClick={movetoVoiceIntegritySecondStep} />
    </div>
  )
}
