import { computed, observable, makeObservable } from 'mobx'
import { type IAvatarInfo } from 'shared/ui'
import { Base } from 'models'
import type {
  ICallHistory,
  ICallHistoryCall,
  ICallHistoryCallConference,
  ICallHistoryContact,
  ICallHistoryContactedUser,
  ICallHistoryConversation,
  ICallHistoryInbox,
  ICallHistoryMessage,
} from 'entities/CallHistory/api/types'
import { type IResponseEventInboxCallingNumber } from 'entities/Inbox/api/types'
import { organizationStore } from 'entities/Organization'

export class CallHistory extends Base {
  id: number
  message: ICallHistoryMessage
  conversation: ICallHistoryConversation
  call: ICallHistoryCall
  call_conference: ICallHistoryCallConference | null
  contact: ICallHistoryContact | null
  contacted_user: ICallHistoryContactedUser | null
  inbox!: ICallHistoryInbox

  constructor(item: ICallHistory) {
    super(item.message.id)
    this.id = item.message.id
    this.message = item.message
    this.conversation = item.conversation
    this.call = item.call
    this.call_conference = item.call_conference
    this.contact = item.contact
    this.contacted_user = item.contacted_user
    this.inbox = item.inbox

    makeObservable(this, {
      avatarInfo: computed,
      message: observable,
      inbox: observable,
    })
  }

  get contactAvatarInfo(): IAvatarInfo {
    return {
      image: this.contact?.photo_url || '',
      color: this.contact?.color || '',
      firstName: this.contact?.name || '',
      number: this.contact?.number,
    }
  }
  get avatarInfo(): IAvatarInfo {
    return {
      image: this.contacted_user?.photo_url || '',
    }
  }
  get participantsAvatarInfo(): IAvatarInfo {
    return {
      color: 'var(--purple-60)',
      firstName: this.call_conference?.participants.length.toString(),
    }
  }
  get isForwarded() {
    return Boolean(this.call.forwarded)
  }
  get isUnread() {
    return Boolean(this.message.unread)
  }
  get isCallConference() {
    return Boolean(this.call_conference)
  }
  get callType() {
    return this.call.type
  }
  get outcomeType() {
    return this.call.outcome
  }
  get audioUrl() {
    return this.call.record_url
  }
  get callDuration() {
    return this.call.duration || 0
  }
  get time() {
    return this.call.created_at
  }
  get participants() {
    return this.call_conference?.participants
  }
  get contactedUserName() {
    return this.contacted_user?.name
  }
  get formattedNumber() {
    return this.contact?.formatted_number
  }

  get number() {
    return this.contact?.number
  }

  get isNeedResponse() {
    return this.conversation?.is_need_response
  }

  makeAsRead = () => {
    this.message.unread = false
  }

  get isUseOrganizationCallSettings() {
    return this.inbox.number.use_organization_call_settings
  }

  get isNumberOutboundCalls() {
    if (this.isUseOrganizationCallSettings) {
      return organizationStore.organization_call?.is_active
    }

    return this.voice_outbound
  }

  get isNumberInboundCalls() {
    if (this.isUseOrganizationCallSettings) {
      return organizationStore.organization_call?.is_active_inbound
    }

    return this.voice
  }

  get voice_outbound() {
    return this.inbox.number.voice_outbound
  }

  get voice() {
    return this.inbox.number.voice
  }

  updateInboxCalling = (data: IResponseEventInboxCallingNumber) => {
    this.inbox.number.use_organization_call_settings = data.use_organization_call_settings
    this.inbox.number.voice_outbound = data.voice_outbound
    this.inbox.number.voice = data.voice
  }
}
