import { createContext, useContext, ReactNode, FC } from 'react'
import { type HistoryPageStore } from 'pages/contacts/pages/history/store/historyPageStore'

export const HistoryPageContext = createContext<HistoryPageStore | null>(null)

export const useHistoryPageContext = () => {
  const context = useContext(HistoryPageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with HistoryPageContext')

  return context
}

type IProviderHistoryPageProps = {
  value: HistoryPageStore
  children: ReactNode
}

export const ProviderHistoryPage: FC<IProviderHistoryPageProps> = ({ value, children }) => {
  return <HistoryPageContext.Provider value={value}>{children}</HistoryPageContext.Provider>
}
