import { makeAutoObservable, runInAction } from 'mobx'
import { logger } from 'shared/lib'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { userPermissionsStore } from 'entities/UsersPermissions'

export class IntegrationsAccessStore {
  private _loading = true

  constructor() {
    makeAutoObservable(this)
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    try {
      await Promise.all([featureFlagsStore.initPromise, userPermissionsStore.initPromise])
    } catch (error) {
      logger.error(error)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  get loading() {
    return this._loading
  }
}
