import { observer } from 'mobx-react-lite'
import { Button, InputCheckbox } from 'shared/ui'
import { CampaignSelect } from 'entities/Campaign/ui'
import { type EnrollInCampaignModalStore } from './store/EnrollInCampaignModalStore'
import styles from './styles.module.scss'

type IEnrollInCampaignModalProps = {
  store: EnrollInCampaignModalStore
}

export const EnrollInCampaignModal = observer(({ store }: IEnrollInCampaignModalProps) => {
  const {
    onClose,
    onEnroll,
    loading,
    disabled,
    checkCampaignCriteria,
    setCheckCampaignCriteria,
    campaignSelectStore,
  } = store

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <CampaignSelect store={campaignSelectStore} />
        <InputCheckbox
          label='Check Campaign enrollment criteria before enrolling contacts.'
          checked={checkCampaignCriteria}
          onChecked={setCheckCampaignCriteria}
        />
      </div>
      <div className={styles.actions}>
        <Button
          size='medium'
          text='Cancel'
          contained='secondary'
          disabled={loading}
          onClick={onClose}
        />
        <Button
          size='medium'
          text='Enroll'
          loading={loading}
          disabled={disabled}
          onClick={onEnroll}
        />
      </div>
    </div>
  )
})
