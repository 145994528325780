import { AxiosResponse } from 'axios'
import type { IAPiOption } from 'shared/api'
import { baseApi } from 'entities/Auth'
import type {
  IResponseGetEinData,
  IParamsOptInTypes,
  IResponseProvider,
  IResponseCountryState,
  IParamsSetBpBusinessPointsOfContact,
  IParamsSetBpBusinessTerms,
  IResponseComplianceSummary,
  IParamsSaveComplianceSummary,
  IParamsSetBpBusinessInformation,
  IResponseTenDlcVoiceIntegrity,
  IParamsSubmitLocalNumberApplication,
  IParamsSetCampaignPackageData,
  IParamsDeleteAttachment,
  IResponseOptInUpload,
  IResponseStockExchanges,
  IResponseGetComplianceServicesList,
  IResponseOrganizationBusinessProfile,
  IParamsUpdateComplianceServicesCampaign,
  IResponseComplianceServicesCampaign,
  IResponseComplianceServicesBrandPackage,
  IResponseOrganizationTollFree,
  IResponseOrganizationTollFreeFormFields,
  IParamsSetBpBusinessInformationLocation,
  IIrsEinDocument,
  IParamsUpdateVoiceIntegrity,
  IParamsUpdateShakenStirTerms,
  IResponseOrganizationShakenStir,
  IOnlineFormUrlCheck,
  IResponseOnlineFormUrlCheck,
  IOptInMethodNames,
} from 'entities/Compliance/api/complianceType'

class Api {
  getOptInTypes({ filter }: IParamsOptInTypes): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/opt-in-data/types', {
      params: {
        filter,
      },
    })
  }

  getOptInMethodNames(): Promise<AxiosResponse<IOptInMethodNames[]>> {
    return baseApi.get('compliance/enum/opt-in-methods')
  }

  getBusinessProfileData(): Promise<AxiosResponse<IResponseOrganizationBusinessProfile>> {
    return baseApi.get('organization/business-profile')
  }

  getBpStates(): Promise<AxiosResponse<IResponseCountryState[]>> {
    return baseApi.get('organization/business-profile/states')
  }

  getComplianceSummary(): Promise<AxiosResponse<IResponseComplianceSummary>> {
    return baseApi.get('ten-dlc/summary')
  }

  saveComplianceSummary(
    params: IParamsSaveComplianceSummary
  ): Promise<AxiosResponse<IResponseComplianceSummary>> {
    return baseApi.post('ten-dlc/summary', params)
  }

  setBpBusinessInformation(
    params: IParamsSetBpBusinessInformation
  ): Promise<AxiosResponse<IResponseOrganizationBusinessProfile>> {
    return baseApi.post('organization/business-profile/business-information', params)
  }

  setBpBusinessInformationLocation(
    params: IParamsSetBpBusinessInformationLocation
  ): Promise<AxiosResponse<IResponseOrganizationBusinessProfile>> {
    return baseApi.post('organization/business-profile/business-information', params)
  }

  setBpBusinessInformationEinDocument(file: File): Promise<AxiosResponse<IIrsEinDocument>> {
    const formData = new FormData()
    formData.append('irs_ein_doc', file, file.name)
    formData.append('fileName', file.name)
    formData.append('fileSize', file.size.toString())

    return baseApi.post(
      'organization/business-profile/business-information/ein-document',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  }
  deleteBpBusinessInformationEinDocument() {
    return baseApi.delete('organization/business-profile/business-information/ein-document')
  }
  setBpPointsOfContact(
    params: IParamsSetBpBusinessPointsOfContact
  ): Promise<AxiosResponse<IResponseOrganizationBusinessProfile>> {
    return baseApi.post('organization/business-profile/points-of-contact', params)
  }
  setBpTerms(
    params: IParamsSetBpBusinessTerms
  ): Promise<AxiosResponse<IResponseOrganizationBusinessProfile>> {
    return baseApi.post('organization/business-profile/terms', params)
  }
  getBusinessProfileBusinessTypes(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/business-types')
  }
  getBusinessProfileJobPositions(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/job-positions')
  }
  getBusinessProfileIndustries(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/industries')
  }
  getBusinessProfileRegionsOfOperation(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/regions-of-operation')
  }
  getBusinessProfileBusinessRegistrationTypes(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/business-registration-types')
  }
  getBusinessProfileStockExchanges(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/stock-exchanges')
  }
  getProviders(): Promise<AxiosResponse<Array<IResponseProvider>>> {
    return baseApi.get('ten-dlc/providers/list ')
  }
  getEinData({ ein }: { ein: string }): Promise<AxiosResponse<IResponseGetEinData>> {
    return baseApi.get('organization/business-profile/verify-ein', {
      params: {
        q: ein,
      },
    })
  }
  getOrganizationTollFree(): Promise<AxiosResponse<IResponseOrganizationTollFree>> {
    return baseApi.get('organization/toll-free')
  }

  getOrganizationTollFreeFormFields(): Promise<
    AxiosResponse<IResponseOrganizationTollFreeFormFields>
  > {
    return baseApi.get('organization/toll-free/form-fields')
  }

  getVoiceIntegrity(): Promise<AxiosResponse<IResponseTenDlcVoiceIntegrity>> {
    return baseApi.get('ten-dlc/voice-integrity')
  }

  updateVoiceIntegrity(
    params: IParamsUpdateVoiceIntegrity
  ): Promise<AxiosResponse<IResponseTenDlcVoiceIntegrity>> {
    return baseApi.post('ten-dlc/voice-integrity', params)
  }

  getOrganizationShakenStir(): Promise<AxiosResponse<IResponseOrganizationShakenStir>> {
    return baseApi.get('organization/shaken-stir')
  }

  updateOrganizationShakenStirTerms(
    params: IParamsUpdateShakenStirTerms
  ): Promise<AxiosResponse<IResponseOrganizationShakenStir>> {
    return baseApi.post('organization/shaken-stir/terms', params)
  }

  getComplianceServicesList(): Promise<AxiosResponse<IResponseGetComplianceServicesList>> {
    return baseApi.get('compliance/services/list')
  }

  onlineFormUrlCheck(
    params: IOnlineFormUrlCheck
  ): Promise<AxiosResponse<IResponseOnlineFormUrlCheck>> {
    return baseApi.post('compliance/services/campaign/online-form-url-check', params)
  }

  updateComplianceServicesCampaign(
    params: IParamsUpdateComplianceServicesCampaign
  ): Promise<AxiosResponse<IResponseComplianceServicesCampaign>> {
    return baseApi.post('compliance/services/campaign', params)
  }

  setCampaignPackageData(
    params: IParamsSetCampaignPackageData
  ): Promise<AxiosResponse<IResponseComplianceServicesBrandPackage>> {
    return baseApi.post('compliance/services/brand/package', params)
  }

  setCampaignOptInFiles(file: File): Promise<AxiosResponse<IResponseOptInUpload[]>> {
    return baseApi.post(
      'compliance/services/campaign/opt-in-files/upload',
      {
        image: file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  }

  deleteAttachment(params: IParamsDeleteAttachment): Promise<AxiosResponse<{ deleted: boolean }>> {
    return baseApi.delete('compliance/services/campaign/opt-in-files', { params })
  }

  submitLocalNumberApplication(
    params: IParamsSubmitLocalNumberApplication
  ): Promise<AxiosResponse> {
    return baseApi.post('ten-dlc/summary/submitted', params)
  }

  getStockExchanges(): Promise<AxiosResponse<IResponseStockExchanges[]>> {
    return baseApi.get('compliance/enum/stock-exchanges')
  }

  closeComplianceBannersSummary(): Promise<AxiosResponse<IResponseComplianceSummary>> {
    return baseApi.post('ten-dlc/summary/close-banners')
  }
}

export const ComplianceApi = new Api()
