import { makeAutoObservable, reaction, runInAction, type IReactionDisposer } from 'mobx'
import { logger } from 'shared/lib'
import { ContactsApi } from 'entities/Contacts'
import type { IResponseContactUnlinked } from 'entities/Contacts/api/types'
import { LinkContactStore } from 'pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable/ui/LinkContact'

const defaultPagination = {
  page: 1,
  length: 10,
}

export class ContactsUnlinkedTableStore {
  private _itemsMap: Map<number, IResponseContactUnlinked> = new Map()
  private _loading = false
  private _total = 0
  private _paginationData = defaultPagination
  private _disposePagination: IReactionDisposer | null = null
  private _linkContactStore = new LinkContactStore()

  constructor() {
    makeAutoObservable(this)
    this.reactionPagination()

    this._linkContactStore.setConfig({
      load: () => {
        this.load()
      },
    })
  }

  get linkContactStore() {
    return this._linkContactStore
  }

  get paginationData() {
    return this._paginationData
  }

  get loading() {
    return this._loading
  }

  get total() {
    return this._total
  }

  get noResults() {
    return this._total === 0
  }

  init = () => {
    this.load()
  }

  resetPaginationPage = () => {
    this.changePagination(1, this._paginationData.length)
  }

  reset = () => {
    this._paginationData = defaultPagination
    this._loading = false
    this._total = 0
    this._itemsMap.clear()
    this._disposePagination?.()
  }
  changePagination = (page: number, length: number) => {
    this._paginationData = {
      page,
      length,
    }
  }

  load = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data } = await ContactsApi.getContactsUnlinked(this._paginationData)

      const results = data.data || []
      const total = data.total || 0

      this.addItems(results)

      runInAction(() => {
        this._total = total
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  get list() {
    return Array.from(this._itemsMap.values())
  }

  addItems = (contacts: IResponseContactUnlinked[]) => {
    this._itemsMap.clear()

    contacts.forEach((item) => {
      this._itemsMap.set(item.id, item)
    })
  }

  reactionPagination = () => {
    this._disposePagination?.()
    this._disposePagination = reaction(
      () => this._paginationData,
      () => {
        this.load()
      }
    )
  }
}
