import { IIconsVector } from 'shared/ui'

interface IFilterIconProps {
  type: string
  key: string
  category_new: string
}

const customIcons = [
  { type: 'number', icon: 'number' },
  { type: 'text', icon: 'textInput2' },
  { type: 'url', icon: 'link' },
  { type: 'date', icon: 'calendar' },
] as Array<{ type: string; icon: IIconsVector }>
const customIconsMap = new Map(customIcons.map(({ type, icon }) => [type, icon]))

const categoryIcons = [
  { category: 'geo-location', icon: 'makerPin' },
  { category: 'phone-checker', icon: 'check2' },
] as Array<{ category: string; icon: IIconsVector }>
const categoryIconsMap = new Map(categoryIcons.map(({ category, icon }) => [category, icon]))

const typeIcons = [
  { key: 'updated_created', icon: 'calendar' },
  { key: 'date_time', icon: 'calendar' },
  { key: 'date', icon: 'calendar' },
] as Array<{
  key: string
  icon: IIconsVector
}>
const typeIconsMap = new Map(typeIcons.map(({ key, icon }) => [key, icon]))

const keyIcons = [
  { key: 'tag', icon: 'tag' },
  { key: 'first_name', icon: 'user' },
  { key: 'last_name', icon: 'user' },
  { key: 'number', icon: 'phone' },
  { key: 'email', icon: 'email' },
  { key: 'message_status', icon: 'messageCircleChecked' },
  { key: 'opt_in', icon: 'toggle' },
  { key: 'source', icon: 'toggle' },
  { key: 'keyword', icon: 'keywords' },
  { key: 'total_received_sms', icon: 'pieChart' },
  { key: 'total_received_call', icon: 'pieChart' },
  { key: 'shortcode', icon: 'hash' },
  { key: 'segments', icon: 'segment' },
  { key: 'hubspot', icon: 'plug' },
  { key: 'hubspot-lists', icon: 'plug' },
  { key: 'status', icon: 'circleDot' },
  { key: 'created_at', icon: 'calendar' },
  { key: 'owner_id', icon: 'userFilled' },
  { key: 'sent_at', icon: 'calendarArrowRight' },
  { key: 'number_id', icon: 'phone' },
  { key: 'broadcasts.status', icon: 'circleDot' },
  { key: 'broadcasts.created_at', icon: 'calendar' },
  { key: 'broadcasts.owner_id', icon: 'userFilled' },
  { key: 'broadcasts.sent_at', icon: 'calendarArrowRight' },
  { key: 'broadcasts.number_id', icon: 'phone' },
  { key: 'broadcasts_recurring_settings.type', icon: 'repeat' },
  { key: 'broadcasts_recurring_settings.status', icon: 'circleDot' },
  { key: 'updated_created', icon: 'calendar' },
  { key: 'date_time', icon: 'calendar' },
  { key: 'inbox', icon: 'inboxOpen' },
  { key: 'workflows.number_id', icon: 'phone' },
  { key: 'workflows.is_active', icon: 'circleDot' },
  { key: 'workflows.status', icon: 'circleDot' },
  { key: 'workflows.owner_id', icon: 'userFilled' },
  { key: 'workflows.integration_id', icon: 'plug' },
  { key: 'workflows.created_at', icon: 'calendar' },
  { key: 'keywords.status', icon: 'circleDot' },
  { key: 'keywords.is_active', icon: 'circleDot' },
  { key: 'keywords.created_at', icon: 'calendar' },
  { key: 'keywords.owner_id', icon: 'userFilled' },
  { key: 'campaigns.status', icon: 'circleDot' },
  { key: 'campaigns.user_id', icon: 'userFilled' },
  { key: 'pd_campaign.status', icon: 'circleDot' },
] as Array<{ key: string; icon: IIconsVector }>
const keyIconsMap = new Map(keyIcons.map(({ key, icon }) => [key, icon]))

const getCustomIcon = ({ key, type }: IFilterIconProps) =>
  key.startsWith('custom.') ? customIconsMap.get(type) ?? null : null

const getCategoryIcon = ({ category_new }: IFilterIconProps) =>
  category_new ? categoryIconsMap.get(category_new) ?? null : null

const getKeyIcon = ({ key }: IFilterIconProps) => keyIconsMap.get(key) ?? null

const getTypeIcon = ({ type }: IFilterIconProps) => typeIconsMap.get(type) ?? null

export const getFilterIcon = (props: IFilterIconProps): IIconsVector | undefined =>
  getCustomIcon(props) ??
  getCategoryIcon(props) ??
  getKeyIcon(props) ??
  getTypeIcon(props) ??
  undefined
