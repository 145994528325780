import { makeAutoObservable } from 'mobx'
import type { ICardFilter } from 'shared/ui'
import type { Campaign, ICampaignContactState } from 'entities/Campaign'
import { CampaignContactsStatisticsTableStore } from 'pages/campaigns/ui/detail/CampaignContactsStatisticsTable'

export class CampaignOverviewStore {
  campaignContactsStatisticsTableStore
  constructor(private campaign: Campaign) {
    this.campaignContactsStatisticsTableStore = new CampaignContactsStatisticsTableStore(campaign)
    makeAutoObservable(this)
  }

  dispose = () => {
    this.campaignContactsStatisticsTableStore.dispose()
  }

  filterKey: ICampaignContactState = 'enrolled'
  setFilter = (filter: ICardFilter<typeof this.filterKey>) => {
    if (filter.key === 'enrolled') {
      this.campaignContactsStatisticsTableStore.setFilterState(null)
    } else {
      this.campaignContactsStatisticsTableStore.setFilterState(filter.key)
    }
    this.filterKey = filter.key
  }
  get statistics() {
    return this.campaign.statistics
  }
  get _filters(): Array<ICardFilter<typeof this.filterKey> | false> {
    return [
      {
        key: 'enrolled',
        active: this.filterKey === 'enrolled',
        amount: this.statistics?.enrolled_contacts_count,
        percentage: this.statistics?.enrolled_contacts_percent,
        title: 'Enrolled',
      },
      {
        key: 'in_progress',
        active: this.filterKey === 'in_progress',
        amount: this.statistics?.in_progress,
        percentage: this.statistics?.in_progress_percent,
        title: 'In progress',
      },
      {
        key: 'completed',
        active: this.filterKey === 'completed',
        amount: this.statistics?.completed,
        percentage: this.statistics?.completed_percent,
        title: 'Completed',
      },
      {
        key: 'unsubscribed',
        active: this.filterKey === 'unsubscribed',
        amount: this.statistics?.unsubscribed,
        percentage: this.statistics?.unsubscribed_percent,
        title: 'Unsubscribed',
      },
      {
        key: 'stopped_by_user',
        active: this.filterKey === 'stopped_by_user',
        amount: this.statistics?.stopped_by_user,
        percentage: this.statistics?.stopped_by_user_percent,
        title: 'Stopped by user',
      },
      !!this.statistics?.failed_count && {
        key: 'failed',
        active: this.filterKey === 'failed',
        amount: this.statistics?.failed_count,
        percentage: this.statistics?.failed_percent,
        title: 'Failed',
      },
    ]
  }

  get filters(): ICardFilter<typeof this.filterKey>[] {
    const filters: ICardFilter<typeof this.filterKey>[] = []
    this._filters.forEach((filter) => {
      if (filter) {
        filters.push(filter)
      }
    })
    return filters
  }
}
