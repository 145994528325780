import { AxiosResponse } from 'axios'
import type { IParamsSwitch, IResponseSwitch } from 'entities/Auth'
import { baseApi } from 'entities/Auth/lib'
import type {
  IParamsLogin,
  IResponseLogin,
  IResponseAuthRefresh,
  IResponseLogout,
  IParamsValidateEmail,
  IResponseValidateEmail,
  IResponseCountries,
  IParamsRegister,
  IResponseValidateRegister,
  IParamsRegisterAnswers,
  IResponseRegisterAnswers,
  IResponseRegister,
  IResponseSendTwoFaCode,
  IParamsSendTwoFaCode,
  IParamsVerifyTwoFaCode,
  IResponseVerifyTwoFaCode,
} from './types'

class Api {
  login(data: IParamsLogin): Promise<AxiosResponse<IResponseLogin>> {
    return baseApi.post('2fa/auth/login', data)
  }

  logout(): Promise<AxiosResponse<IResponseLogout>> {
    return baseApi.post('auth/logout', null, { withCredentials: true })
  }

  updateAuthRefresh(): Promise<AxiosResponse<IResponseAuthRefresh>> {
    const assumeToken = sessionStorage.getItem('assume_token')
    const config = assumeToken
      ? undefined
      : {
          withCredentials: true,
        }

    return baseApi.post('auth/refresh', null, config)
  }

  switch(data: IParamsSwitch): Promise<AxiosResponse<IResponseSwitch>> {
    return baseApi.post('auth/switch', data)
  }

  validateEmail(data: IParamsValidateEmail): Promise<AxiosResponse<IResponseValidateEmail>> {
    return baseApi.post('core/validate/email', data)
  }

  getCountries(): Promise<AxiosResponse<IResponseCountries>> {
    return baseApi.get('countries')
  }

  validateRegister(data: IParamsRegister): Promise<AxiosResponse<IResponseValidateRegister>> {
    return baseApi.post('users/register/validate', data)
  }

  register(data: IParamsRegister): Promise<AxiosResponse<IResponseRegister>> {
    return baseApi.post('auth/register', data)
  }

  sendTwoFaCode(data: IParamsSendTwoFaCode): Promise<AxiosResponse<IResponseSendTwoFaCode>> {
    return baseApi.post('2fa/auth/send', data)
  }

  verifyTwoFaCode(data: IParamsVerifyTwoFaCode): Promise<AxiosResponse<IResponseVerifyTwoFaCode>> {
    return baseApi.post('2fa/auth/verify', data)
  }

  logRegisterAnswers(
    params: IParamsRegisterAnswers
  ): Promise<AxiosResponse<IResponseRegisterAnswers>> {
    return baseApi.post('profile/metadata', params)
  }
}

export const AuthApi = new Api()
