import { ReactNode } from 'react'

export const highlightUrls = (data: ReactNode) => {
  if (typeof data === 'string') {
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g

    return data.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        const href = part.startsWith('http') ? part : `https://${part}`
        return (
          <a key={index} href={href} target='_blank' rel='noreferrer'>
            {part}
          </a>
        )
      }
      return part
    })
  }
  return data
}
