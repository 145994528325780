import { Route, Routes } from 'react-router-dom'
import { HistoryPage } from 'pages/contacts/pages/history/ui/historyPage'
import { HistoryLayout } from 'pages/contacts/pages/history/layout/HistoryLayout'

export const HistoryRouter = () => {
  return (
    <Routes>
      <Route element={<HistoryLayout />}>
        <Route path={'/'} element={<HistoryPage />} />
      </Route>
    </Routes>
  )
}
