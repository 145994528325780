// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mtpfqO4vBV2E1GLY0_zg{text-decoration:none;line-height:normal}", "",{"version":3,"sources":["webpack://./src/pages/powerDialer/pages/list/ui/PowerDialerActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,kBAAA","sourcesContent":[".helpButton {\n  text-decoration: none;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpButton": "mtpfqO4vBV2E1GLY0_zg"
};
export default ___CSS_LOADER_EXPORT___;
