// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hNdYDz3a2moHIwENLgc2{display:grid;grid-template-columns:1fr 52px 1fr;align-items:center;justify-items:center}.UTbyp13G11YeNV6wOqRA{display:flex;height:120px;width:100%;align-items:center;justify-content:center;background-color:var(--background-neutral-default);border-radius:4px}.yw2Thhi6JfbsMm1j7AvB{background-color:var(--background-neutral-default);height:28px;width:28px;border-radius:50%;display:flex;align-items:center;justify-content:center}.sUHnYvnYV3kFj63WKwIx{width:100%;grid-column:span 3;margin-top:18px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSyncDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kCAAA,CACA,kBAAA,CACA,oBAAA,CAGF,sBACE,YAAA,CACA,YAAA,CACA,UAAA,CACA,kBAAA,CACA,sBAAA,CACA,kDAAA,CACA,iBAAA,CAGF,sBACE,kDAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,UAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".root {\n  display: grid;\n  grid-template-columns: 1fr 52px 1fr;\n  align-items: center;\n  justify-items: center;\n}\n\n.integrationIcon {\n  display: flex;\n  height: 120px;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--background-neutral-default);\n  border-radius: 4px;\n}\n\n.typeIcon {\n  background-color: var(--background-neutral-default);\n  height: 28px;\n  width: 28px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.select {\n  width: 100%;\n  grid-column: span 3;\n  margin-top: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "hNdYDz3a2moHIwENLgc2",
	"integrationIcon": "UTbyp13G11YeNV6wOqRA",
	"typeIcon": "yw2Thhi6JfbsMm1j7AvB",
	"select": "sUHnYvnYV3kFj63WKwIx"
};
export default ___CSS_LOADER_EXPORT___;
