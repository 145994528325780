import { makeAutoObservable } from 'mobx'
import { AxiosResponse } from 'axios'
import { IToastShow, toastStore } from 'shared/ui'
import { logger } from 'shared/lib'
import { IResponseGetExport } from 'entities/Export'

class ExportStore {
  constructor() {
    makeAutoObservable(this)
  }

  getExport = async ({
    promise,
    showDownload = true,
    preparingTitle = 'Export report',
    successTitle = 'Report exported',
  }: {
    promise: Promise<AxiosResponse<IResponseGetExport>>
    showDownload?: boolean
    preparingTitle?: string
    successTitle?: string
  }) => {
    const preparingToastId = 'preparing_id'

    toastStore.add({
      id: preparingToastId,
      type: 'loading',
      title: preparingTitle,
      desc: 'Preparing',
      noCloseButton: true,
    })

    try {
      const { data } = await promise

      toastStore.reset()

      const successToast: IToastShow = {
        type: 'success',
        title: successTitle,
        desc: 'Ready and emailed to you',
      }

      if (showDownload) {
        successToast.action = {
          text: 'Download',
          link: data.url,
        }
      }

      toastStore.add(successToast)
    } catch (error) {
      logger.error(error)
      toastStore.reset()

      toastStore.add({
        type: 'error',
        title: preparingTitle,
        desc: 'Failed',
      })
    }
  }
}

export const exportStore = new ExportStore()
