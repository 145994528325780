import React, { useEffect, useRef } from 'react'
import WaveSurfer from 'wavesurfer.js'
import { observer } from 'mobx-react-lite'
import { getBlob, logger } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { showToast } from 'shared/ui'

type Props = {
  id: string
  url: string
  width?: number
  waveform: WaveSurfer | null
  setWaveform: (wave: WaveSurfer | null) => void
  setDuration?: (value: number) => void
  playing: boolean
  setPlaying: (value: boolean) => void
  hide?: boolean
  autoplay?: boolean
  onErrorLoad?: () => void
}

export const AudioWave = observer(
  ({
    id,
    url,
    width,
    waveform,
    setWaveform,
    setDuration,
    playing,
    setPlaying,
    hide,
    autoplay,
    onErrorLoad,
  }: Props) => {
    const waveformElementRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (id !== uiStore.playAudioId && playing) {
        if (waveform) {
          waveform.pause()
          setPlaying(waveform?.isPlaying())
        }
      }
    }, [uiStore.playAudioId, playing])

    useEffect(() => {
      waveform?.destroy()
      try {
        if (!waveformElementRef.current) return

        const w = WaveSurfer.create({
          barWidth: 2,
          barRadius: 2,
          barGap: 1,
          height: 24,
          cursorWidth: 0,
          cursorColor: '#068FF9',
          container: waveformElementRef.current,
          waveColor: '#ACB3B9',
          progressColor: '#068FF9',
          autoplay,
        })
        getBlob(url, (blob) => {
          w.loadBlob(blob)
            .then(() => {
              setWaveform(w)
              setDuration && setDuration(w.getDuration())
            })
            .catch((e) => {
              showToast({
                type: 'error',
                title: 'Something went wrong. Please try again',
              })
              setWaveform(null)
              setPlaying(false)
              onErrorLoad && onErrorLoad()
              logger.error(e)
            })
          w.on('play', () => {
            uiStore.setPlayAudioId(id || url)
            setPlaying(true)
          })
          w.on('pause', () => setPlaying(false))
          w.on('interaction', (time) => {
            setDuration && setDuration(w.getDuration() - time)
          })
          w.on('audioprocess', (time) => {
            if (time === w.getDuration()) {
              setPlaying(false)
              setDuration && setDuration(w.getDuration())
            } else {
              setDuration && setDuration(w.getDuration() - time)
            }
          })
        })
      } catch (e) {
        logger.error(e)
      }

      return () => {
        waveform?.destroy()
        if (waveformElementRef.current) {
          waveformElementRef.current.innerHTML = ''
        }
      }
    }, [id])

    return (
      <div
        ref={waveformElementRef}
        style={{ width: width || '100%', display: hide ? 'none' : '' }}
      />
    )
  }
)
