import { Link } from 'react-router-dom'
import { Icon } from 'shared/ui/Icon'
import { Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'

type IBreadcrumbsUiProps = {
  path: string
  storyData?: string
  label?: string
}

export const BreadcrumbsUi = ({ path, storyData, label }: IBreadcrumbsUiProps) => {
  return (
    <div className={styles.wrap}>
      {storyData && (
        <Typography variant={'body-sm-regular'} pointer className={styles.link} ariaLabel={'story'}>
          {storyData}
        </Typography>
      )}
      <Link to={path} className={styles.link}>
        <Icon icon={'chevronLeft'} />
        <Typography variant={'body-sm-regular'} pointer ariaLabel={'Back'}>
          Back to {label}
        </Typography>
      </Link>
    </div>
  )
}
