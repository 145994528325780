import { observer } from 'mobx-react-lite'
import { links } from 'shared/constants/links'
import { Alert } from 'shared/ui'
import { IPlaneTypes } from 'entities/Compliance'
import { DropdownCards } from 'widgets/DropdownCards/ui/DropdownCards'
import { LearnMoreLink } from 'pages/settings/pages/compliance/ui/LearnMoreLink/LearnMoreLink'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'
import { summaryData } from 'pages/settings/pages/compliance/pages/localNumbers/pages/OrderSummary/lib/constants'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { RegistrationErrorAlert } from 'pages/settings/pages/compliance/pages/localNumbers/ui/RegistrationErrorAlert/RegistrationErrorAlert'

import { SummaryLine } from 'pages/settings/pages/compliance/ui/SummaryLine/SummaryLine'
import styles from './styles.module.scss'

export const OrderSummary = observer(() => {
  const {
    stepLoading,
    isSubmitDisabled,
    dropdownCardsStore,
    packageStore,
    onSubmit,
    paymentError,
    complianceLayerStore,
  } = useLocalNumbersStepsContext()

  const { complianceServiceBrand, complianceServiceCampaign } = complianceLayerStore
  const noFailedBrand = !complianceServiceBrand?.isStatusFailed
  const noFailedCampaign = !complianceServiceCampaign?.isStatusFailed
  const summaryValues =
    packageStore.activePlaneType === IPlaneTypes.STANDARD ? summaryData.public : summaryData.private

  const formatCurrency = (value: number) => {
    return `$${value.toFixed(2)}`
  }

  const getTotal = () => {
    let total = 0

    if (noFailedBrand && noFailedCampaign) {
      total += summaryValues.brandRegistrationFeeCount
      total += summaryValues.campaignVerificationFeeCount

      return formatCurrency(total)
    }

    if (complianceServiceBrand?.isStatusFailed && !complianceServiceBrand?.isInternalRejected) {
      total += summaryValues.brandRegistrationFeeCount
    }

    if (
      (complianceServiceCampaign?.isStatusFailed || complianceServiceBrand?.isStatusFailed) &&
      !complianceServiceCampaign?.isInternalRejected
    ) {
      total += summaryValues.campaignVerificationFeeCount
    }

    return formatCurrency(total)
  }

  const showBrandFee = () => {
    if (noFailedBrand && noFailedCampaign) return true

    return complianceServiceBrand?.isStatusFailed && !complianceServiceBrand?.isInternalRejected
  }

  const showCampaignFee = () => {
    if (noFailedBrand && noFailedCampaign) return true

    return (
      (complianceServiceCampaign?.isStatusFailed || complianceServiceBrand?.isStatusFailed) &&
      !complianceServiceCampaign?.isInternalRejected
    )
  }

  return (
    <div className={styles.orderSummary}>
      <PageDescription>
        {
          "The fee's below are imposed by the carrier to register your brand and use case. Salesmsg passes down these fee's with no markup."
        }
        <LearnMoreLink href={links.complianceLearnMoreLink}>Learn more</LearnMoreLink>
      </PageDescription>

      <RegistrationErrorAlert />

      {paymentError && (
        <Alert
          className={styles.alert}
          item={{
            type: 'error',
            title: 'We were unable to process your payment',
            desc: 'Please select a different card',
          }}
        />
      )}

      <div className={styles.summary}>
        {showBrandFee() && (
          <SummaryLine
            label='Brand registration fee (one-time)'
            value={summaryValues.brandRegistrationFee}
            tooltip={{
              label: 'This is a one-time carrier fee for registering your Brand',
            }}
          />
        )}
        {showCampaignFee() && (
          <SummaryLine
            label='Campaign verification fee (one-time)'
            value={summaryValues.campaignVerificationFee}
            tooltip={{
              label: 'This is a one-time carrier fee for registering your brand campaign use case',
            }}
          />
        )}
        {showCampaignFee() && (
          <SummaryLine
            label='Campaign use case fee (recurring)'
            value={summaryValues.useCaseFee}
            tooltip={{
              width: 275,
              label:
                'This is a monthly recurring carrier fee for registering your campaign use case.',
              desc: 'It will be charged after your campaign is approved',
            }}
          />
        )}
        <div className={styles.divider} />
        <SummaryLine label='Total' value={getTotal()} isTotal />
      </div>
      {dropdownCardsStore && <DropdownCards store={dropdownCardsStore} />}

      <NextButton
        loading={stepLoading}
        disabled={isSubmitDisabled || stepLoading}
        text='Submit my application'
        onClick={onSubmit}
      />
    </div>
  )
})
