import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { ComplianceApi, complianceStore } from 'entities/Compliance'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers'
import type { LocalNumbersStepsStore } from 'pages/settings/pages/compliance/pages/localNumbers/store/localNumbersStepsStore'
import { SettingsPath } from 'pages/settings/route/settingsPath'

export class ForbiddenMessageStore {
  constructor(private _localNumbersStore: LocalNumbersStepsStore) {
    makeAutoObservable(this)
  }

  private _forbiddenMessageAgreed = false

  get forbiddenMessageAgreed() {
    return this._forbiddenMessageAgreed
  }

  get isNextStepDisabled() {
    return !this._forbiddenMessageAgreed
  }

  get localNumbersStore() {
    return this._localNumbersStore
  }

  get withoutNextSteps() {
    const { noShowPackageStep, noShowOrderSummaryStep } = this.localNumbersStore

    return noShowPackageStep && noShowOrderSummaryStep
  }

  get nextStepPath() {
    const { noShowPackageStep } = this.localNumbersStore

    if (this.withoutNextSteps) {
      return `/${SettingsPath.compliance.localNumbers.root}`
    }

    if (noShowPackageStep) {
      return `/${SettingsPath.compliance.localNumbers.root}/${LocalNumbersRoutes.orderSummary}`
    }

    return `/${SettingsPath.compliance.localNumbers.root}/${LocalNumbersRoutes.package}`
  }

  get nextButtonText() {
    return this.withoutNextSteps ? 'Submit my application' : 'Next'
  }

  setForbiddenMessageAgreed = () => {
    this._forbiddenMessageAgreed = !this._forbiddenMessageAgreed
  }

  increaseStep = async () => {
    if (!this.localNumbersStore.campaignId) return
    try {
      this.localNumbersStore.stepsStore.setStepLoading(true)
      const { data } = await ComplianceApi.updateComplianceServicesCampaign({
        step: 5,
        campaignId: this.localNumbersStore.campaignId,
      })

      this.localNumbersStore.complianceLayerStore.set10DLCServiceCampaign(data)

      if (this.withoutNextSteps) {
        await ComplianceApi.submitLocalNumberApplication({
          ten_dlc_plan: this.localNumbersStore.packageStore.activePlaneType || '',
          ten_dlc_registration_submitted: true,
        })

        complianceStore.fetchComplianceServicesList()
        this.localNumbersStore.complianceLayerStore.getCampaignData()
      }

      uiStore.changeRoute({
        path: this.nextStepPath,
      })
    } catch (e) {
      logger.error(e)
    } finally {
      this.localNumbersStore.stepsStore.setStepLoading(false)
    }
  }
}
