// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sGhtTdWF9V9AmB2KvLay{padding:4px 8px;border-radius:6px;background:var(--black-10);margin-right:4px}", "",{"version":3,"sources":["webpack://./src/pages/contacts/ui/ContactsTable/ui/KeywordItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,iBAAA,CACA,0BAAA,CACA,gBAAA","sourcesContent":[".keywordContainer {\n    padding: 4px 8px;\n    border-radius: 6px;\n    background: var(--black-10);\n    margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"keywordContainer": "sGhtTdWF9V9AmB2KvLay"
};
export default ___CSS_LOADER_EXPORT___;
