import axios, { CancelTokenSource } from 'axios'
import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import { ChatbotApi, IChatbotFullData } from 'entities/Chatbot'

import { ChatbotDetails } from '../ui/ChatbotDetails'

export class ChatbotDetailsStore {
  private _cancelModelSource: CancelTokenSource | null = null
  private _chatbotDetailsModalId = 'chatbotDetailsModalId'

  constructor() {
    makeAutoObservable(this)
  }

  showCurrentDetails = (data: IChatbotFullData) => {
    const futureData = Promise.resolve(data)
    const cleanup = () => null

    this._openModal(futureData, cleanup)
  }

  showDetails = (id: number) => {
    const futureData = this._getData(id)
    const cleanup = () => this._cancelModelSource?.cancel()

    this._openModal(futureData, cleanup)
  }

  private _openModal = (futureData: Promise<IChatbotFullData | null>, cleanup: () => void) => {
    modalStore.addModal({
      id: this._chatbotDetailsModalId,
      width: 540,
      position: 'right',
      type: ModalTypeList.DEFAULT,
      ModalContentProps: { futureData, cleanup },
      ModalContent: ChatbotDetails,
      title: 'Details',
      pureContent: true,
      fullHeight: true,
    })
  }

  private _getData = async (id: number) => {
    this._cancelModelSource?.cancel()
    this._cancelModelSource = axios.CancelToken.source()

    try {
      const { data } = await ChatbotApi.getChatbot(id, {
        cancelToken: this._cancelModelSource?.token,
      })

      return data
    } catch (error) {
      logger.error(error)

      return null
    }
  }
}
