import { CSSProperties, UIEvent, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Scrollbar, SpinnerLoader } from 'shared/ui'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactDropdownCard } from 'entities/Contacts'
import {
  ContactsSearchStore,
  ContactsSearchParentStore,
  ContactsSearchVariantEnum,
  IntegrationsTabEnum,
} from 'widgets/ContactsSearch'
import styles from './styles.module.scss'

type IContactsSearchListProps = {
  store: ContactsSearchStore
  parentStore: ContactsSearchParentStore
  search?: string
  handleAddItem?: (item: Contact) => void
  maxHeight?: number | string
  minHeight?: number | string
  loadingParent?: boolean
  variant?: ContactsSearchVariantEnum
}

export const ContactsSearchList: FC<IContactsSearchListProps> = observer(
  ({
    store,
    parentStore,
    search = '',
    handleAddItem = () => {},
    maxHeight,
    minHeight,
    loadingParent = false,
    variant = ContactsSearchVariantEnum.default,
  }) => {
    const handleScroll = (e: UIEvent) => {
      const target = e.target as HTMLDivElement
      const scrollHeight = target.scrollHeight
      const scrollTop = target.scrollTop
      const offsetHeight = target.offsetHeight
      const indentBottom = 20

      if (store.loading) return
      if (store.loadingMore) return
      if (!store.hasMore) return
      if (scrollHeight <= scrollTop + offsetHeight + indentBottom) {
        store.fetch({ more: true })
      }
    }

    const stylesWrap: CSSProperties = {}

    if (maxHeight) {
      stylesWrap['maxHeight'] = maxHeight
    }

    if (minHeight) {
      stylesWrap['minHeight'] = minHeight
    }

    if (store.loading || loadingParent)
      return (
        <div className={classnames(styles.wrap, styles.loading)}>
          <SpinnerLoader />
        </div>
      )

    if (
      !store.items.length &&
      !(parentStore.activeTab === IntegrationsTabEnum.selected && parentStore.hasSelected)
    ) {
      return (
        <div className={styles.wrap}>
          <div className={styles.empty}>No results found</div>
        </div>
      )
    }

    return (
      <Scrollbar
        className={styles.wrap}
        style={stylesWrap}
        autoHeight
        autoHeightMin={0}
        autoHeightMax={255}
        onScroll={handleScroll}
      >
        {parentStore.activeTab === IntegrationsTabEnum.selected
          ? parentStore.selectedList.map((contact) => {
              return (
                <ContactDropdownCard
                  key={contact.id}
                  item={contact}
                  highlighter={search}
                  InputCheckboxProps={{
                    checked: parentStore.isSelected(contact),
                    onChecked: () => handleAddItem(contact),
                  }}
                  showIconIntegration={parentStore.activeTab === IntegrationsTabEnum.selected}
                />
              )
            })
          : store.items.map((contact) => {
              if (variant === ContactsSearchVariantEnum.checkbox) {
                return (
                  <ContactDropdownCard
                    key={contact.id}
                    item={contact}
                    highlighter={search}
                    InputCheckboxProps={{
                      checked: parentStore.isSelected(contact),
                      onChecked: () => handleAddItem(contact),
                    }}
                    showIconIntegration={parentStore.activeTab === IntegrationsTabEnum.selected}
                  />
                )
              }

              return (
                <ContactDropdownCard
                  key={contact.id}
                  item={contact}
                  highlighter={search}
                  onChange={handleAddItem}
                  showIconIntegration={parentStore.activeTab === IntegrationsTabEnum.selected}
                />
              )
            })}
        {store.loadingMore && (
          <div className={styles.loadingMore}>
            <SpinnerLoader />
          </div>
        )}
      </Scrollbar>
    )
  }
)
