import { FC, UIEventHandler } from 'react'
import { Scrollbar, SpinnerLoader } from 'shared/ui'
import { CallContact } from 'entities/Call/model/CallContact'
import { ContactCard } from './ContactCard'
import styles from './styles.module.scss'

type IContactsListProps = {
  items: CallContact[]
  onDelete?: (item: CallContact) => void
  innerRefCurrent?: HTMLDivElement
  handleScroll?: UIEventHandler
  loading?: boolean
}

export const ContactsList: FC<IContactsListProps> = ({
  items,
  innerRefCurrent,
  onDelete,
  handleScroll,
  loading,
}) => {
  return (
    <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={258} onScroll={handleScroll}>
      <div className={styles.list}>
        {items.map((item) => {
          return (
            <ContactCard
              key={item.id}
              item={item}
              innerRefCurrent={innerRefCurrent}
              onDelete={onDelete}
            />
          )
        })}
        {loading && (
          <div className={styles.loader}>
            <SpinnerLoader size={16} />
          </div>
        )}
      </div>
    </Scrollbar>
  )
}
