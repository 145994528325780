import { makeAutoObservable } from 'mobx'
import { ReactNode } from 'react'
import { AxiosError } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { Button } from 'shared/ui'
import { logger } from 'shared/lib'
import { integrationsStore } from 'entities/Integrations'
import type { ITestSMSDefaultProps } from 'widgets/TestSMS/ui/TestSmsDefault/TestSMSDefault'
import { SendTestModalContent } from 'widgets/TestSMS/ui/TestSmsWithMergeField/ui/SendTestModalContent'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { BuyCreditsModalStore } from 'widgets/BuyCreditsModal'
import { SendTestModalAction } from 'widgets/TestSMS/ui/TestSmsWithMergeField/ui/SendTestModalAction'

export class SendTestModalStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = 'sendTestModal'
  request: ITestSMSDefaultProps['request'] | null = null
  initParams: ITestSMSDefaultProps['params'] | null = null
  originalMergeFieldsTemplates: string[] = []
  originalMergeFieldsTemplatesMap: Map<string, string> = new Map()
  fieldsMap: Map<
    string,
    {
      id: string
      label: string
      placeholder: string
    }
  > = new Map()
  dataMap: Map<string, string> = new Map()
  oldDataMap: Map<string, string> = new Map()

  testPhone = ''
  errorMap: Map<string, ReactNode> = new Map()

  completed = false
  loading = false

  buyCreditsModalStore: BuyCreditsModalStore = new BuyCreditsModalStore()

  get fields() {
    return Array.from(this.fieldsMap.values())
  }

  onSuccessCancel = () => {
    this.completed = false
    this.testPhone = ''
    this.dataMap.clear()
    this.oldDataMap.clear()
    modalStore.closeModal(this.modalId)
  }

  reset = () => {
    this.originalMergeFieldsTemplatesMap.clear()
    this.originalMergeFieldsTemplates = []
    this.fieldsMap.clear()
    this.oldDataMap = new Map(this.dataMap)
    this.dataMap.clear()
  }

  openModal = ({
    request,
    params,
    mergeFieldsTemplates,
  }: {
    request: ITestSMSDefaultProps['request']
    params: ITestSMSDefaultProps['params']
    mergeFieldsTemplates: string[]
  }) => {
    this.reset()
    this.request = request
    this.initParams = params
    this.originalMergeFieldsTemplates = mergeFieldsTemplates

    mergeFieldsTemplates.forEach((template) => {
      const { mergeField } = allMergeFieldsStore.getMergeFieldInfoByTemplate(template)
      if (mergeField) {
        this.originalMergeFieldsTemplatesMap.set(template, mergeField.template)
        this.fieldsMap.set(mergeField.template, {
          id: mergeField.template,
          label: `${integrationsStore.getIntegration(mergeField.integrationKey)?.name || ''} ${
            mergeField.label
          }`,
          placeholder: mergeField.label,
        })
        const oldValue = this.oldDataMap.get(mergeField.template)
        this.dataMap.set(mergeField.template, oldValue || '')
      }
    })
    modalStore.addModal({
      id: this.modalId,
      type: ModalTypeList.DEFAULT,
      title: 'Send test',
      ModalContent: SendTestModalContent,
      ModalActions: SendTestModalAction,
      ModalContentProps: {
        store: this,
      },
    })
  }

  onSendTest = async () => {
    if (this.loading || this.completed) return
    this.errorMap.clear()
    this.dataMap.forEach((value, key) => {
      if (!value.trim()) {
        this.setError(key, 'Please complete this field to send test message')
      }
    })
    if (this.errorMap.size) {
      return
    }
    this.setLoading(true)

    try {
      if (this.request && this.initParams) {
        const { data } = await this.request({
          number_id: this.initParams?.number_id,
          media_url: this.initParams?.media_url,
          message: this.initParams?.message.replace(/{.*?}/g, (match) => {
            const key = this.originalMergeFieldsTemplatesMap.get(match)
            if (key) {
              const value = this.dataMap.get(key)
              if (value) {
                return value
              }
            }
            return match
          }),
          number: this.testPhone,
        })
        if (data?.status === 'success') {
          this.setCompleted(true)
          setTimeout(this.onSuccessCancel, 1000)
        } else {
          if (data.message === 'Insufficient balance to send this message') {
            this.setError(
              'testPhone',
              <>
                Not enough credits to test the message.
                <Button
                  text={'Buy credits'}
                  typeBtn={'link'}
                  size={'small'}
                  contained={'primary'}
                  onClick={() => {
                    this.buyCreditsModalStore.openModal()
                  }}
                />
              </>
            )
          } else {
            this.setError('testPhone', data.message)
          }
        }
      }
    } catch (e) {
      logger.error(e)
      if (e instanceof AxiosError) {
        this.setError('testPhone', e.response?.data.number?.[0])
      }
    } finally {
      this.setLoading(false)
    }
  }

  setTestPhone = (value: string) => {
    const hasPlusSign = value.startsWith('+')
    let correctFormatNumber = value.replace(/\D/g, '')

    if (hasPlusSign) {
      correctFormatNumber = '+' + correctFormatNumber
    }

    this.testPhone = correctFormatNumber
  }

  setError = (key: string, value: ReactNode) => {
    this.errorMap.set(key, value)
  }

  updateFieldValue = (id: string, value: string) => {
    this.dataMap.set(id, value)
  }

  setCompleted = (value: boolean) => {
    this.completed = value
  }
  setLoading = (value: boolean) => {
    this.loading = value
  }
}
