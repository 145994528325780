import { CSSProperties, Ref, FC } from 'react'
import classnames from 'classnames'
import { type IActionsProps } from 'shared/ui'
import { IconButton } from 'shared/ui/IconButton'
import { Typography, type ITypographyProps } from 'shared/ui/Typography'
import { type ITooltipProps } from 'shared/ui/Tooltip'
import { Icon, type IIconProps } from 'shared/ui/Icon'
import { ActionItem, Actions } from 'shared/ui/Actions/Actions'

import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type PropsSectionHeader = {
  innerRef?: Ref<HTMLDivElement>
  loading?: boolean
  title?: React.ReactNode
  titleVariant?: ITypographyProps['variant']
  customTitle?: React.ReactNode | null
  titleIconProps?: IIconProps
  titleIconAction?: () => void
  isEmoji?: boolean
  actions?: ActionItem[]
  actionsProps?: IActionsProps | null
  isBorderBottom?: boolean
  isBorderLeft?: boolean
  isBorderRight?: boolean
  variant?: 'primary' | 'transparent'
  ellipsis?: boolean
  type?: 'title' | 'container'
  container?: React.ReactElement
  paddingRight?: CSSProperties['paddingRight']
  paddingLeft?: CSSProperties['paddingLeft']
  height?: number
  paddingTop?: CSSProperties['paddingTop']
  paddingBottom?: CSSProperties['paddingBottom']
  titleCount?: number
  showCount?: boolean
  maxContentWidth?: number
  rightTitleContent?: React.ReactElement | boolean
  actionIconTooltipProps?: ITooltipProps
  actionsGap?: number
  ariaLabel?: string
  className?: string
}

const ContainerHeader: FC<PropsSectionHeader> = (props) => {
  const {
    title = '',
    titleVariant,
    customTitle = null,
    titleIconProps,
    titleIconAction,
    isBorderBottom = false,
    isBorderRight = false,
    isBorderLeft = false,
    variant = 'primary',
    innerRef,
    ellipsis,
    loading,
    actions,
    actionIconTooltipProps,
    type = 'title',
    container = '',
    paddingRight,
    paddingLeft,
    paddingTop,
    paddingBottom,
    titleCount = 0,
    showCount = false,
    maxContentWidth,
    rightTitleContent,
    actionsGap,
    actionsProps,
    height,
    ariaLabel,
    className,
  } = props

  const styleWrap = {
    ...(paddingRight !== undefined
      ? {
          paddingRight,
        }
      : null),
    ...(paddingLeft !== undefined
      ? {
          paddingLeft,
        }
      : null),
    ...(paddingTop !== undefined
      ? {
          paddingTop,
        }
      : null),
    ...(paddingBottom !== undefined
      ? {
          paddingBottom,
        }
      : null),
    height: height ? `${height}px` : 'auto',
  }

  if (type === 'container') {
    return (
      <div
        ref={innerRef}
        className={classnames(
          styles.wrap,
          styles[variant],
          {
            [styles.isBorderBottom]: isBorderBottom,
            [styles.isBorderRight]: isBorderRight,
            [styles.isBorderLeft]: isBorderLeft,
          },
          className
        )}
        style={{
          ...styleWrap,
        }}
      >
        {container}
      </div>
    )
  }

  return (
    <div
      ref={innerRef}
      className={classnames(
        styles.wrap,
        styles[variant],
        {
          [styles.isBorderBottom]: isBorderBottom,
          [styles.isBorderRight]: isBorderRight,
          [styles.isBorderLeft]: isBorderLeft,
          [styles.isCustomTitle]: !!customTitle,
        },
        className
      )}
      style={styleWrap}
    >
      <div className={styles.content} style={{ maxWidth: maxContentWidth }}>
        <div className={styles.box}>
          <div
            className={classnames(
              styles.title,
              titleIconProps && styles.titleIcon,
              rightTitleContent && styles.rightTitleContent,
              rightTitleContent && titleIconProps && styles.fullContent
            )}
            data-name='container_header_title'
            aria-label={
              ariaLabel
                ? getAriaLabel(`${ariaLabel} containerHeader`)
                : getAriaLabel('ContainerHeaderSelector')
            }
          >
            {loading ? (
              <div className={styles.skeletonTitle}></div>
            ) : (
              <>
                {titleIconProps && !Boolean(titleIconAction) && (
                  <div
                    className={classnames(styles.titleIcon)}
                    data-name='container_header_title_icon'
                  >
                    <Icon {...titleIconProps} />
                  </div>
                )}
                {titleIconProps && Boolean(titleIconAction) && (
                  <div
                    className={classnames(styles.titleActionIcon)}
                    data-name='container_header_title_icon'
                  >
                    <IconButton
                      iconProps={{
                        fontSize: titleIconProps.fontSize,
                        icon: titleIconProps.icon,
                      }}
                      tooltipProps={actionIconTooltipProps}
                      variant={'icon'}
                      size={'medium'}
                      color={'secondary'}
                      onClick={() => {
                        titleIconAction && titleIconAction()
                      }}
                      ariaLabel='ContainerHeader_titleIcon'
                    />
                  </div>
                )}
                <Typography
                  variant={titleVariant || 'body-lg-medium'}
                  ellipsis={ellipsis}
                  className={classnames({
                    [styles.customTitle]: customTitle,
                  })}
                  ariaLabel={'PageTitle'}
                >
                  {customTitle || title}
                  {showCount && <span className={styles.count}> {titleCount}</span>}
                </Typography>

                {rightTitleContent && (
                  <div className={styles.rightTitleContentWrap}> {rightTitleContent}</div>
                )}
              </>
            )}
          </div>
        </div>

        {(actions || actionsProps) && (
          <div className={styles.buttons}>
            {loading ? (
              <div className={styles.skeletonRight} />
            ) : (
              <>
                {(actions || actionsProps) && (
                  <Actions actions={actions || []} gap={actionsGap} {...actionsProps} />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export { ContainerHeader }
