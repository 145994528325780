import { observer } from 'mobx-react-lite'
import { Table, Typography } from 'shared/ui'
import { adminTenDLCDetailStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/adminTenDLCDetailStore'
import styles from './styles.module.scss'
import { columns } from './columns'

export const TenDLCLogTable = observer(() => {
  const {
    tenDlcLogsStore: { tenDlcLogsAsRow, total, limit, onPaginationModelChange },
  } = adminTenDLCDetailStore

  return (
    <div className={styles.wrap}>
      <Typography variant={'heading-sm'} marginBottom={20} tag={'div'} ariaLabel={'10DLC log'}>
        10DLC log
      </Typography>
      <div
        style={{
          height: tenDlcLogsAsRow.length * 38 + 70,
        }}
      >
        <Table
          columns={columns}
          rows={tenDlcLogsAsRow}
          rowsCount={total}
          limit={limit}
          onChangePagination={onPaginationModelChange}
        />
      </div>
    </div>
  )
})
