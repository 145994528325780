import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Button, ContainerHeader, Tabs, ITabItem, IconButton, Typography } from 'shared/ui'
import { EnumIconButtonShape } from 'shared/ui/IconButton/IconButton'
import { layoutStore } from 'shared/layout'
import { inboxesStore } from 'entities/Inbox'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { UnifiedInbox } from 'entities/Inbox/model/UnifiedInbox'
import { GroupInbox } from 'entities/Inbox/model/GroupInbox'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { ConversationList, type ConversationListStore } from 'widgets/ConversationList'
import { CallHistoryFiltersList } from 'widgets/CallHistoryFiltersList'
import { ConversationHeaderListDropdown } from 'widgets/ConversationHeaderListDropdown'
import { type CallHistoryStore } from 'widgets/CallHistory'
import { type CallModalStore } from 'widgets/CallModal'
import { type ConversationMenuStore } from 'widgets/ConversationMenu'
import { type ConversationSearchStore } from 'widgets/ConversationHeaderSearch/store'
import { type ConversationLayoutStore } from 'widgets/ConversationLayout'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'
import styles from 'widgets/ConversationLayout/ui/styles.module.scss'

type IConversationLayoutProps = {
  callHistoryStore: CallHistoryStore
  callModalStore: CallModalStore
  conversationMenuStore: ConversationMenuStore
  conversationSearchStore: ConversationSearchStore
  conversationLayoutStore: ConversationLayoutStore
  conversationListStore: ConversationListStore
  contactCreateModalStore: ContactCreateModalStore
}

export const ConversationLayout: FC<IConversationLayoutProps> = observer(
  ({
    callHistoryStore,
    callModalStore,
    conversationMenuStore,
    conversationLayoutStore,
    conversationSearchStore,
    conversationListStore,
    contactCreateModalStore,
  }) => {
    const { isMobileView, isLargeView } = layoutStore
    const { selectInbox } = inboxesStore
    const { isShouldRequestAccess } = usersStore.user
    const { selectTab, activeTab, isShowChats, isShowCalls, showChats } = conversationLayoutStore
    const tabs: ITabItem[] = [
      {
        key: 'chats',
        name: 'Chats',
        icon: 'conversation',
      },
      {
        key: 'calls',
        name: 'Calls',
        icon: 'phone',
      },
    ]

    const subtitle = useMemo(() => {
      if (selectInbox instanceof UnifiedInbox) {
        return 'All numbers'
      }
      if (selectInbox instanceof GroupInbox) {
        const formatedNumbers = inboxesStore.getFormatedNumbersByTeamIds(selectInbox.teamsIds)
        return formatedNumbers.length === 1
          ? formatedNumbers[0]
          : `${formatedNumbers.length} numbers`
      }
      if (selectInbox instanceof Inbox) {
        return selectInbox.number?.formatted_number
      }
    }, [inboxesStore.inboxesList, selectInbox])

    const customTitle = () => {
      if (
        conversationSearchStore.searchDropdownStore.hasFilters ||
        conversationSearchStore.opened
      ) {
        return (
          <Button
            typeBtn={'filter'}
            contained={'secondary'}
            text={'New filter'}
            hasCount
            count={conversationSearchStore.searchDropdownStore.filtersCount}
            icon={conversationSearchStore.opened ? 'chevronUp' : 'chevronDown'}
            size={'extraLarge'}
            badgeProps={{
              size: 'large',
            }}
            onClick={conversationSearchStore.onToggle}
            isRight
          />
        )
      }
      if (isShouldRequestAccess) {
        return 'Request access'
      }

      return (
        <button
          className={styles.titleBtn}
          onClick={() => conversationMenuStore.handleChangeCollapse(false)}
        >
          <Typography
            variant={'body-lg-medium'}
            ellipsis={true}
            className={classnames({
              [styles.customTitle]: customTitle,
            })}
            ariaLabel={'title'}
          >
            {selectInbox?.name}
          </Typography>
          <Typography
            variant={'body-md-regular'}
            ellipsis={true}
            ariaLabel={'sub-title'}
            color={'tertiary'}
          >
            {subtitle}
          </Typography>
        </button>
      )
    }

    return (
      <div className={classnames(styles.wrap)}>
        <ContainerHeader
          customTitle={customTitle()}
          titleIconProps={{
            icon: 'lineCollapseExpandLeft',
            fontSize: 20,
            color: 'var(--content-primary-tertiary)',
          }}
          ellipsis
          isEmoji={!selectInbox?.isGroupInbox && !selectInbox?.isUnifiedInbox}
          isBorderBottom
          loading={inboxesStore.loading}
          paddingLeft={16}
          ariaLabel='ActiveConversationName'
          actionIconTooltipProps={{
            label: conversationMenuStore.isCollapse ? 'Show menu' : 'Hide menu',
            disableInteractive: true,
            PopperProps: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [14, 0],
                  },
                },
              ],
            },
          }}
          actions={
            isLargeView
              ? undefined
              : [
                  {
                    iconButtonComponent: (
                      <IconButton
                        onClick={() => {
                          isShowCalls && showChats()
                          isMobileView
                            ? conversationSearchStore.onOpen()
                            : conversationSearchStore.onToggle()
                          isMobileView && conversationMenuStore.handleChangeCollapse(true)
                        }}
                        icon={'search'}
                        disabled={isShouldRequestAccess}
                        transparentBackground={isShouldRequestAccess}
                        fontSize={20}
                        shape={EnumIconButtonShape.Rounded}
                        color={'secondary'}
                        variant={'icon'}
                        tooltipProps={
                          !isShouldRequestAccess
                            ? {
                                placement: 'bottom',
                                label: 'Search',
                                disableInteractive: true,
                              }
                            : undefined
                        }
                        ariaLabel='ConversationLayout_search'
                      />
                    ),
                  },
                  {
                    iconButtonComponent: (
                      <ConversationHeaderListDropdown
                        disabled={isShouldRequestAccess}
                        ariaLabel='AddNewMessageCall'
                        onChangeCall={() => {
                          isMobileView && conversationMenuStore.handleChangeCollapse(true)
                          callModalStore.openModal()
                        }}
                        onChangeNew={() => {
                          !isMobileView && conversationMenuStore.handleChangeCollapse(false)
                          conversationStore.handleOpenNewConversation()
                        }}
                        onChangeContactCreate={() => {
                          !isMobileView && conversationMenuStore.handleChangeCollapse(false)
                          contactCreateModalStore.openModal()
                        }}
                      />
                    ),
                  },
                ]
          }
          actionsGap={8}
          titleIconAction={() => {
            conversationMenuStore.handleToggleCollapse()
          }}
        />
        {featureFlagsStore.call_history && !isShouldRequestAccess && (
          <div className={styles.tabs}>
            <Tabs
              tabs={tabs}
              activeTabKey={activeTab}
              handleSelectTab={selectTab}
              variant={'simple'}
              tabHeight={40}
              isFullWidth
            />
          </div>
        )}
        {isShowChats && <ConversationList store={conversationListStore} />}
        {isShowCalls && <CallHistoryFiltersList store={callHistoryStore} />}
      </div>
    )
  }
)
