import { makeAutoObservable } from 'mobx'
import { EnumDropdownItemVariant, type IDropdownItem } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { TenDlcApi } from 'entities/TenDlc'
import { organizationStore } from 'entities/Organization'
import { ComplianceApi, type IResponseUseCase } from 'entities/Compliance'
import type { IResponseTenDlcUseCases } from 'entities/TenDlc/api/type'
import type { LocalNumbersStepsStore } from 'pages/settings/pages/compliance/pages/localNumbers/store/localNumbersStepsStore'
import { ExampleModalContent } from 'pages/settings/pages/compliance/ui/ExampleModalContent/ExampleModalContent'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers'
import styles from 'pages/settings/pages/compliance/pages/localNumbers/pages/CampaignUseCase/ui/styles.module.scss'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { OptionItem } from 'pages/settings/pages/compliance/ui/OptionItem/OptionItem'

export const useCaseDescMinTextLength = 40
export const useCaseDescMaxTextLength = 4096

export class UseCaseStore {
  constructor(private _localNumbersStore: LocalNumbersStepsStore) {
    makeAutoObservable(this)
  }

  private _useCases: IResponseTenDlcUseCases[] = []
  private _useCaseValue: string | null = null
  private _useCaseDesc = ''

  get useCases() {
    return this._useCases
  }

  get activeUseCase() {
    return this.useCases.find((useCase) => useCase.value === this.useCaseValue)
  }

  get activeUseCaseTitle() {
    return this.activeUseCase?.title
  }

  get activeUseCaseValue() {
    return this.activeUseCase?.value
  }

  get useCaseValue() {
    return this._useCaseValue
  }

  get useCaseAsItems(): IDropdownItem[] {
    return this.useCases.map((useCase) => ({
      id: useCase.value,
      label: useCase.title || '',
      variant: EnumDropdownItemVariant.Custom,
      className: styles.customDropdown,
      renderOption: () => <OptionItem label={useCase.title} desc={useCase.description} />,
    }))
  }

  get useCaseDesc() {
    return this._useCaseDesc
  }

  get isStep1Disabled() {
    const ifWithinLimits =
      this.useCaseDesc.length <= useCaseDescMaxTextLength &&
      this.useCaseDesc.length >= useCaseDescMinTextLength

    return !this.useCaseValue || !ifWithinLimits
  }

  get localNumbersStore() {
    return this._localNumbersStore
  }

  init = (data: IResponseUseCase | null) => {
    if (!data) return

    this.setUseCase(data.useCase)
    this.setUseCaseDesc(data.description)
  }

  loadUseCases = async () => {
    try {
      const { data } = await TenDlcApi.getTenDlcUseCases()

      this._useCases = data
    } catch (e) {
      logger.error(e)
    }
  }

  setUseCase = (useCase: string) => {
    this._useCaseValue = useCase
  }

  setUseCaseDesc = (useCaseDesc: string) => {
    this._useCaseDesc = useCaseDesc
  }

  openUseCaseExampleModal = () => {
    const id = 'useCaseExampleModal'

    modalStore.addModal({
      id,
      width: 540,
      title: 'Use case description example',
      ModalContent: () => (
        <ExampleModalContent
          activeUseCase={this.activeUseCaseTitle || ''}
          example={this.activeUseCase?.explanation || ''}
        />
      ),
      primaryAction: {
        text: 'Done',
        onAction: () => modalStore.closeModal(id),
      },
      showCloseButton: false,
    })
  }

  campaignUseCaseNextClick = async () => {
    const { numberVendor } = organizationStore

    if (!this._useCaseValue || !numberVendor) return

    try {
      this.localNumbersStore.stepsStore.setStepLoading(true)

      const { data } = await ComplianceApi.updateComplianceServicesCampaign({
        step: 1,
        campaignId: this.localNumbersStore.campaignId || null,
        useCaseData: {
          useCase: this._useCaseValue,
          description: this._useCaseDesc,
          useCaseTitle: this.activeUseCaseTitle || '',
        },
        vendorKey: numberVendor?.key,
      })

      this.localNumbersStore.complianceLayerStore.set10DLCServiceCampaign(data)

      uiStore.changeRoute({
        path: `/${SettingsPath.compliance.localNumbers.root}/${LocalNumbersRoutes.messages}`,
      })
    } catch (e) {
      logger.error(e)
    } finally {
      this.localNumbersStore.stepsStore.setStepLoading(false)
    }
  }
}
