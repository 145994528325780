import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { uiStore } from 'shared/store/uiStore'
import { authStore } from 'entities/Auth'

const RootLayout = observer(() => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    uiStore.setPathName(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    uiStore.setQueryParams(location.search)
  }, [location.search])

  useEffect(() => {
    uiStore.setNavigate(navigate)

    if (window.RouterVue && !loading) {
      if (window.RouterVue.currentRoute.path !== '/') {
        uiStore.changeRoute({
          path: window.RouterVue.currentRoute.path,
          method: 'replace',
        })
      }
    }

    setLoading(true)

    const params = new URLSearchParams(search)
    const external_source = params.get('external_source')
    const close_after_login = params.get('close_after_login')
    if (external_source) {
      authStore.setExternalSource(external_source)
    }
    if (close_after_login) {
      authStore.setCloseAfterLogin(!!close_after_login)
    }
  }, [])

  return (
    <>
      <Outlet />
    </>
  )
})

export default RootLayout
