import { useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { type IMenuItemProps, Menu, PageLayout } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useMenuOutboundLayoutContext } from 'widgets/Menu/ui/MenuOutbound'
import { PowerDialerRoutes } from 'pages/powerDialer/route/type'
import { PowerDialerPageContext } from 'pages/powerDialer/context/powerDialerPageContext'
import { PowerDialerPageStore } from 'pages/powerDialer/store/powerDialerPageStore'

const items: IMenuItemProps[] = [
  {
    key: 'all_power_dialer',
    type: 'nav',
    to: `/${PowerDialerRoutes.root}`,
    label: 'All power dialer campaigns',
    icon: 'phoneRightArrows',
  },
]

export const PowerDialerLayout = observer(() => {
  const menuLayoutStore = useMenuOutboundLayoutContext()
  const pageStore = useMemo(() => new PowerDialerPageStore(menuLayoutStore.pageLayoutStore), [])

  if (featureFlagsStore.salesmsg_design_v2_new_sidebar_menu) {
    return (
      <PowerDialerPageContext.Provider value={pageStore}>
        <Outlet />
      </PowerDialerPageContext.Provider>
    )
  }

  return (
    <PowerDialerPageContext.Provider value={pageStore}>
      <PageLayout
        leftContent={<Menu title='Power Dialer' menuItems={items} />}
        store={pageStore.pageLayoutStore}
      />
    </PowerDialerPageContext.Provider>
  )
})
