import { reaction, toJS, type IReactionDisposer } from 'mobx'
import { logger } from 'shared/lib'

class StoreCache {
  private _disposers = new Set<() => void>()
  private _disposeKeys: IReactionDisposer | null = null

  create(name: string, storage: Pick<Storage, 'getItem' | 'setItem' | 'removeItem'>) {
    return <T extends object, K extends keyof T>(store: T, keys: K[]) => {
      this._disposers.add(() => storage.removeItem(name))

      const storeSerialized = storage.getItem(name)

      if (!!storeSerialized) {
        try {
          const storePayload: Partial<T> = JSON.parse(storeSerialized)

          keys.forEach((key) => {
            if (!store.hasOwnProperty(key)) return

            store[key] = storePayload[key] as T[K]
          })
        } catch (error) {
          logger.error(error)
        }
      }

      this._disposeKeys?.()
      this._disposeKeys = reaction(
        () => Object.fromEntries(keys.map((key) => [key, toJS(store[key])])),
        (store) => {
          storage.setItem(name, JSON.stringify(store))
        }
      )
    }
  }

  dispose() {
    this._disposers.forEach((dispose) => dispose())
    this._disposers.clear()
  }
}

export const storeCache = new StoreCache()
