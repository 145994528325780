// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FphWESMVHhZ7QI6n0BF9{display:flex;width:100%;justify-content:space-between}.CzPJCqkAn336OXt7gCAP,.WWI18PmzQI4Kfx2o77Pj{display:flex;gap:8px;align-items:center}.WedWGrhQPh7gyBL0H2jM{display:flex;position:relative}.WedWGrhQPh7gyBL0H2jM .DoDJ50Y8vmCG9TLCdPiF{display:flex;width:100%;height:100%;justify-content:center;align-items:center;position:absolute}.WedWGrhQPh7gyBL0H2jM.YWGVxVUP9Sck3FgjA6ul .yazXDscxU3EWxXlJh4AA{visibility:hidden}.WedWGrhQPh7gyBL0H2jM .yazXDscxU3EWxXlJh4AA{display:flex}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/CampaignView/ui/Actions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CAEA,6BAAA,CAEF,4CAEE,YAAA,CACA,OAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,iBAAA,CACA,4CACE,YAAA,CACA,UAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CAGA,iEACE,iBAAA,CAGJ,4CACE,YAAA","sourcesContent":[".wrap {\n  display: flex;\n  width: 100%;\n\n  justify-content: space-between;\n}\n.left,\n.right {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n\n.wrapSwitch {\n  display: flex;\n  position: relative;\n  .wrapLoader {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n  }\n  &.loading {\n    .switch {\n      visibility: hidden;\n    }\n  }\n  .switch {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "FphWESMVHhZ7QI6n0BF9",
	"left": "CzPJCqkAn336OXt7gCAP",
	"right": "WWI18PmzQI4Kfx2o77Pj",
	"wrapSwitch": "WedWGrhQPh7gyBL0H2jM",
	"wrapLoader": "DoDJ50Y8vmCG9TLCdPiF",
	"loading": "YWGVxVUP9Sck3FgjA6ul",
	"switch": "yazXDscxU3EWxXlJh4AA"
};
export default ___CSS_LOADER_EXPORT___;
