import { makeAutoObservable } from 'mobx'
import { Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { links } from 'shared/constants/links'
import type { IRegisterSectionProps } from 'pages/settings/pages/compliance/ui/RegisterSection/RegisterSection'
import { StatusComplianceIcons } from 'pages/settings/pages/compliance/constants/statusIcon'
import { type CompliancePageStore } from 'pages/settings/pages/compliance/store/compliancePageStore'
import { BusinessProfileNavigationStore } from './businessProfileNavigationStore'

export class BusinessProfilePageStore {
  private _businessProfileNavigationStore: BusinessProfileNavigationStore

  constructor(private _compliancePageStore: CompliancePageStore) {
    makeAutoObservable(this)

    this._businessProfileNavigationStore = new BusinessProfileNavigationStore(
      this._compliancePageStore.complianceLayerStore
    )
  }

  get businessProfile() {
    return this._compliancePageStore.complianceLayerStore.businessProfile
  }

  get bpRegisterSectionProps(): IRegisterSectionProps {
    return {
      title: 'Business profile',
      titleType: 'body-lg-medium',
      desc: 'Register your business with carriers to begin compliance approval.',
      learnMoreLink: links.complianceLearnMoreLink,
      actionButton: {
        text:
          this._businessProfileNavigationStore.formStep === 0 ? (
            'Register'
          ) : (
            <Typography ariaLabel='Continue' variant='button-medium'>
              {'Continue: '}
              <Typography
                ariaLabel='Step'
                variant='button-medium'
                color='var(--content-primary-tertiary-inverted)'
              >
                Step {this._businessProfileNavigationStore.UIFormStep}/5
              </Typography>
            </Typography>
          ),
        onClick: () => {
          uiStore.changeRoute({
            path: `/${this._businessProfileNavigationStore.nextStep}`,
          })
        },
      },
    }
  }

  get businessProfileDataStatus() {
    if (this._businessProfileNavigationStore.formStep === 0) return null
    // - 1 for design requirement for left side
    const bpCompliedPercent = ((this._businessProfileNavigationStore.UIFormStep - 1) / 5) * 100

    if (!this.businessProfile?.status) {
      return StatusComplianceIcons.condition(bpCompliedPercent)
    }

    const { status } = this.businessProfile

    if (status === 'failed') {
      return StatusComplianceIcons.reject
    }

    if (status === 'in_review') {
      return StatusComplianceIcons.pendingReview
    }

    if (status === 'under_review') {
      return StatusComplianceIcons.carrierReview
    }

    if (status === 'approved') {
      return StatusComplianceIcons.approved
    }

    return null
  }

  updateBusinessProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    this._businessProfileNavigationStore.updateBusinessProfile()
  }

  openBusinessProfileRegisterModal = (type: string) => {
    const id = 'businessProfileRegisterModal'
    modalStore.addModal({
      id,
      type: ModalTypeList.INFO,
      width: 262,
      title: 'Register your business profile',
      primaryAction: {
        text: 'Register',
        onAction: () => {
          modalStore.closeModal(id)

          uiStore.changeRoute({
            path: 'settings/organization/compliance/business-profile/company-location',
          })
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => modalStore.closeModal(id),
      },
      desc:
        type === 'BP'
          ? 'To verify your local numbers, you need first to register your business profile'
          : 'To get access to Trusted Calling add-on, you need first to register your business profile',
    })
  }
}
