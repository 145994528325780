import { observer } from 'mobx-react-lite'
import React from 'react'
import { ContainerHeader } from 'shared/ui'
import {
  PowerDialerHelpGuideAction,
  PowerDialerNewSessionAction,
} from 'pages/powerDialer/pages/list/ui/PowerDialerActions/PowerDialerActions'
import { usePowerDialerPageContext } from 'pages/powerDialer/context/powerDialerPageContext'
import { usePowerDialerListContext } from 'pages/powerDialer/pages/list/context/powerDialerListContext'

export const PowerDialerListHeader = observer(() => {
  const {
    pageLayoutStore: { titleIconProps, handleToggleCollapse },
  } = usePowerDialerPageContext()
  const { empty } = usePowerDialerListContext()
  const actions = [
    {
      iconButtonComponent: <PowerDialerHelpGuideAction />,
    },
    {
      iconButtonComponent: <PowerDialerNewSessionAction />,
    },
  ]

  if (empty) {
    return null
  }

  return (
    <ContainerHeader
      title={'Power Dialer campaigns'}
      titleVariant={'heading-md'}
      titleIconProps={titleIconProps}
      titleIconAction={handleToggleCollapse}
      paddingLeft={20}
      paddingRight={20}
      actionsGap={8}
      actions={actions}
    />
  )
})
