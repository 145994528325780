import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { EnumSpinnerLoaderPosition, SpinnerLoader, Table } from 'shared/ui'
import { useTriggerListContext } from 'widgets/TriggerList/context/context'
import { EmptyStateTriggers } from 'widgets/TriggerList/ui/EmptyStateTriggers'

import { BulkActions } from '../BulkActions'
import { getColumns } from './getColumns'
import styles from './styles.module.scss'

export const TriggersTable = observer(() => {
  const {
    initTriggersLoad,
    triggers,
    type,
    tableStore,
    isTriggersEmpty,
    onPaginationModelChange,
    isLoading,
    isFirstLoading,
    page,
    limit,
    total,
  } = useTriggerListContext()

  useEffect(() => {
    void initTriggersLoad()
  }, [])

  if (isTriggersEmpty) {
    return <EmptyStateTriggers />
  }

  if (isFirstLoading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }

  return (
    <div className={styles.wrap}>
      <Table
        withCheckbox
        withSelectAll
        store={tableStore}
        headerLeftContentProps={{
          actionsContent: <BulkActions />,
        }}
        columns={getColumns(type)}
        rows={triggers}
        rowsCount={total}
        isLoading={isLoading}
        onChangePagination={onPaginationModelChange}
        initPage={page}
        limit={limit}
        sortBy={tableStore.sortBy}
        startPaginationSize={10}
      />
    </div>
  )
})
