import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { Button, Switch, Typography } from 'shared/ui'
import { useCampaignViewContext } from 'pages/campaigns/ui/CampaignView/context/context'
import { CampaignRoutes } from 'pages/campaigns/types'
import styles from './styles.module.scss'

export const Actions = observer(() => {
  const { onSave, isPublish, setIsPublish, loadingSave, isEdit } = useCampaignViewContext()
  const navigate = useNavigate()
  const onCancel = () => {
    navigate(`/${CampaignRoutes.root}/${CampaignRoutes.all}`)
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.left}>
        <Button
          size={'medium'}
          contained={'primary'}
          text={isEdit ? 'Save changes' : 'Save'}
          onClick={onSave}
          loading={loadingSave}
        />
        <Button size={'medium'} contained={'secondary'} text={'Cancel'} onClick={onCancel} />
      </div>
      <div className={styles.right}>
        <Typography ariaLabel={'draft'} variant={'body-md-regular'}>
          Draft
        </Typography>
        <Switch value={isPublish} onChange={setIsPublish} />
        <Typography ariaLabel={'publish'} variant={'body-md-regular'}>
          Publish
        </Typography>
      </div>
    </div>
  )
})
