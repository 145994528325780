import { useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type ContactsMenuStore } from 'pages/contacts/ui/ContactsMenu'
import { ContactsMenuContext } from 'pages/contacts/ui/ContactsMenu/context/contactsMenuContext'
import { ContactsMenuContent } from './ContactsMenuContent'

type IContactsDetailsProps = {
  store: ContactsMenuStore
}

export const ContactsMenu: FC<IContactsDetailsProps> = observer(({ store }) => {
  return useMemo(() => {
    return (
      <ContactsMenuContext.Provider value={store}>
        <ContactsMenuContent />
      </ContactsMenuContext.Provider>
    )
  }, [])
})
