// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t7p_Telcwohxc681UrwW{display:flex;flex-direction:column;--titled-row-action-width: 180px}.qvvH70acYULaaZ3PilhQ{display:grid;grid-template-columns:1fr 1fr;gap:16px}.FS1muVkO5wOm6YKP60EC{width:100%;display:flex;flex-direction:column;border:1px solid var(--border-neutral-default);padding:16px;border-radius:12px}.unFbLaLRfpNnGkE3jn3G{line-height:1.5}.CjEaWLZSfIR9jFXvSAea{display:flex;gap:4px;margin-bottom:12px}.M8n2KTZccavSfeNwkcLl{display:flex;flex-direction:column;margin-top:24px;gap:36px}.HCqoZKv_NpO9AmWrXPht{width:100%}.iCVIPNwx8IfdHjIUwtYP{--dropdown-checked-margin-left: 5px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/ui/IntegrationSalesforceSettingsInstallation/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,gCAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,QAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,8CAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,QAAA,CAGF,sBACE,UAAA,CAGF,sBACE,mCAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  --titled-row-action-width: 180px;\n}\n\n.info {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 16px;\n}\n\n.block {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  border: 1px solid var(--border-neutral-default);\n  padding: 16px;\n  border-radius: 12px;\n}\n\n.label {\n  line-height: 1.5;\n}\n\n.subtitle {\n  display: flex;\n  gap: 4px;\n  margin-bottom: 12px;\n}\n\n.control {\n  display: flex;\n  flex-direction: column;\n  margin-top: 24px;\n  gap: 36px;\n}\n\n.select {\n  width: 100%;\n}\n\n.dropdown {\n  --dropdown-checked-margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "t7p_Telcwohxc681UrwW",
	"info": "qvvH70acYULaaZ3PilhQ",
	"block": "FS1muVkO5wOm6YKP60EC",
	"label": "unFbLaLRfpNnGkE3jn3G",
	"subtitle": "CjEaWLZSfIR9jFXvSAea",
	"control": "M8n2KTZccavSfeNwkcLl",
	"select": "HCqoZKv_NpO9AmWrXPht",
	"dropdown": "iCVIPNwx8IfdHjIUwtYP"
};
export default ___CSS_LOADER_EXPORT___;
