import { ActionItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { Campaign } from 'entities/Campaign'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import { CampaignRoutes } from 'pages/campaigns/types'

export const duplicateCampaign = (campaign: Campaign): ActionItem => {
  return {
    icon: 'copy',
    text: 'Duplicate',
    onAction: () => {
      eventLogStore.logEvent(
        'Campaign_Used',
        {
          event_id: 'campaign_used',
          action: 'Duplicated',
        },
        { groupId: organizationStore.id }
      )
      uiStore.changeRoute({
        path: `/${CampaignRoutes.root}/${CampaignRoutes.clone}/${campaign.id}`,
      })
    },
  }
}
