import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Dropdown,
  DropdownContent,
  DropdownTextInput,
  DropdownGroup,
  Scrollbar,
  Button,
} from 'shared/ui'
import { getIcon } from 'widgets/constants'
import type { SettingsProperty } from 'pages/settings/pages/integrations/pages/integrationSettings/model/SettingsProperty'
import type { IntegrationSettingsCustomPropertyStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationSettingsCustomPropertyStore'
import styles from './styles.module.scss'

type IIntegrationSettingsPropertyDropdownProps = {
  store: IntegrationSettingsCustomPropertyStore
  rows: SettingsProperty[]
}

export const IntegrationSettingsPropertyDropdown = observer(
  ({ store, rows }: IIntegrationSettingsPropertyDropdownProps) => {
    const {
      customFields,
      clearSearch,
      searchFields,
      createField,
      addNewProperty,
      customFieldsLoading,
    } = store
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => searchFields(searchTerm), [searchTerm])

    const handleCreate = async () => {
      const field = await createField()

      if (field) addNewProperty(field)
    }

    return (
      <Dropdown
        disabled={customFieldsLoading}
        ariaLabel='TextbotFieldDropdown'
        margin={-40}
        width={228}
        onToggle={() => {
          setSearchTerm('')
          clearSearch()
        }}
        triggerComponent={() => (
          <Button
            disabled={customFieldsLoading}
            text='Add property'
            icon='add'
            typeBtn='text'
            contained='secondary'
            size='small'
            tooltipProps={{
              width: 220,
              label: 'Custom mapped properties only sync from Salesmsg to HubSpot',
              placement: 'top',
            }}
          />
        )}
        placement='bottom'
        customComponent={(close) => (
          <DropdownContent noPadding>
            <DropdownTextInput
              textFieldProps={{
                value: searchTerm,
                onChange: setSearchTerm,
                InputProps: {
                  placeholder: 'Search',
                },
              }}
            />
            <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={300}>
              {customFields.map(({ id, fields, groupName }) => (
                <DropdownGroup
                  key={id}
                  title={groupName}
                  items={fields.map((field) => ({
                    id: field.key,
                    label: field.name,
                    iconL: getIcon(field),
                    disabled: !!rows.find(({ id }) => id === field.key),
                    onClick: () => {
                      close()
                      addNewProperty(field)
                    },
                  }))}
                  isScroll={false}
                />
              ))}
              {!customFields.length && <div className={styles.noResults}>No results found</div>}
            </Scrollbar>
            <footer className={styles.dropdownFooter}>
              <Button
                className={styles.addButton}
                typeBtn='menuItem'
                text='Add custom property'
                icon='add'
                onClick={() => {
                  close()
                  handleCreate()
                }}
              />
            </footer>
          </DropdownContent>
        )}
      />
    )
  }
)
