import { makeAutoObservable } from 'mobx'
import { Campaign, type ICreateCampaignStep } from 'entities/Campaign'
import { CampaignContactsStatisticsTableStore } from 'pages/campaigns/ui/detail/CampaignContactsStatisticsTable'

export class CampaignStepStore {
  campaignContactsStatisticsTableStore
  constructor(private campaign: Campaign, step: ICreateCampaignStep) {
    this.campaignContactsStatisticsTableStore = new CampaignContactsStatisticsTableStore(
      campaign,
      step
    )
    makeAutoObservable(this)
  }

  dispose = () => {
    this.campaignContactsStatisticsTableStore.dispose()
  }

  get total() {
    return this.campaignContactsStatisticsTableStore.total
  }
  get loading() {
    return this.campaignContactsStatisticsTableStore.loading
  }
}
