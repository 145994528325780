import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { billingStore } from 'entities/Billing'
import { SummaryLine } from 'pages/settings/pages/compliance/ui/SummaryLine/SummaryLine'
import styles from './styles.module.scss'

export const AddOnModalContentSecondStep = observer(() => {
  const { isBillingComplianceMonthly: isMonthly } = billingStore
  const count = isMonthly ? '$10.00' : '$120.00'

  return (
    <div>
      <div className={styles.header}>
        <Typography ariaLabel='trustedCalling' variant='heading-lg'>
          Trusted Calling
        </Typography>
      </div>

      <div className={styles.summary}>
        <SummaryLine
          label='Trusted Calling'
          value={isMonthly ? '$10.00 / month' : '$120.00 / year'}
        />
        <div className={styles.divider} />
        <SummaryLine label='Subtotal' value={count} />
        <div className={styles.divider} />
        <SummaryLine
          label='Total due today'
          value={count}
          tooltip={{
            width: 233,
            type: 'description',
            label: `The next charge will be ${count}`,
            // desc: 'Changes to your plan will be reflected in the next billing cycle: Jun 28, 2025',
          }}
        />
      </div>
    </div>
  )
})
