import { observer } from 'mobx-react-lite'
import React from 'react'
import { CardsFilter, Divider } from 'shared/ui'
import { CampaignContactsStatisticsTable } from 'pages/campaigns/ui/detail/CampaignContactsStatisticsTable'
import { useCampaignOverviewContext } from 'pages/campaigns/ui/detail/CampaignOverview/context/context'
import styles from './styles.module.scss'

export const CampaignOverviewUi = observer(() => {
  const { filters, filterKey, campaignContactsStatisticsTableStore, setFilter } =
    useCampaignOverviewContext()

  return (
    <>
      <CardsFilter
        items={filters}
        activeKey={filterKey}
        onActiveFilter={setFilter}
        wrapperClassName={styles.wrapper}
      />
      <Divider sx={{ marginInline: '20px', mb: 2 }} />
      <CampaignContactsStatisticsTable store={campaignContactsStatisticsTableStore} />
    </>
  )
})
