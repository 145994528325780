import { makeAutoObservable, runInAction } from 'mobx'
import { ReactNode } from 'react'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { EnumDropdownItemVariant, IDropdownItem } from 'shared/ui'
import { formatPhoneNumberNational, logger } from 'shared/lib'
import { IAlert } from 'shared/ui/Alert/types'
import { contactsStore, IContactPhone } from 'entities/Contacts'
import { integrationsStore } from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { IParamsCreateContact } from 'entities/Contacts/api/types'
import { ContactChoosePhoneModalActions } from 'widgets/ContactChoosePhoneModal/ui/ContactChoosePhoneModalActions'
import { ContactChoosePhoneModalContent } from 'widgets/ContactChoosePhoneModal/ui/ContactChoosePhoneModalContent'

type IContactChoosePhoneModalOptions = {
  contact: Contact
  callback: (item: Contact) => void
  params?: {
    forLink: boolean
    fromConversation: boolean
  }
}

export class ContactChoosePhoneModalStore {
  idModal = 'contact-choose-phone'
  options: IContactChoosePhoneModalOptions | null = null
  loading = false
  numbersMap: Map<string, IDropdownItem> = new Map()
  activeNumber = ''
  activeLabel = ''
  activeField = ''
  activeType = ''
  private _alert: IAlert | null = null

  constructor() {
    makeAutoObservable(this)
  }

  initNumbers = (template: (item: IContactPhone) => ReactNode) => {
    if (!this.options) return []

    this.options.contact.phoneNumbers.forEach((item) => {
      this.numbersMap.set(item.type, {
        id: item.type,
        label: item.field,
        labelContent: template(item),
        activeValue: this.activeType === item.type,
        value: item.number,
        variant: EnumDropdownItemVariant.CheckedRight,
      })
    })
  }

  openModal = (options: IContactChoosePhoneModalOptions) => {
    this.options = options

    modalStore.addModal({
      id: this.idModal,
      title: 'Phone number',
      ModalActions: ContactChoosePhoneModalActions,
      ModalContent: ContactChoosePhoneModalContent,
      onClose: this.closeModal,
    })
  }

  closeModal = () => {
    this.loading = false
    this.options = null
    this.numbersMap.clear()
    this.activeNumber = ''
    this.activeLabel = ''
    this.activeField = ''
    modalStore.removeModal(this.idModal)
  }

  getNumberItemByType = (type: string) => {
    return this.numbersMap.get(type)
  }

  handleChooseNumber = (type?: string | number) => {
    if (!type) return
    if (typeof type === 'number') return

    const numberItem = this.getNumberItemByType(type)

    this.activeField = numberItem?.label || ''
    this.activeNumber = numberItem?.value || ''
    this.activeLabel = formatPhoneNumberNational(numberItem?.value || '')
    this.activeType = type || ''
  }

  handleSubmit = async () => {
    if (!this.options) return

    try {
      runInAction(() => {
        this.loading = true
      })

      const item = this.options.contact
      const integrration = integrationsStore.getIntegration(item.integrationType)

      const params: IParamsCreateContact = {
        email: item.email,
        first_name: item.integrationInfo?.first_name,
        last_name: item.integrationInfo?.last_name,
        number: this.activeNumber,
        forLink: true,
        fromConversation: true,
        integration_id: integrration?.id,
        contact_integration_id: `${item.id}`,
        ...this.options.params,
      }

      const isContactExist = await contactsStore.getContactByNumber(this.activeNumber)

      if (!isContactExist) {
        this.setAlert({
          type: 'success',
          desc: 'Contact created',
        })
      }

      if (this.options.params && !this.options.params.forLink) {
        this.options.callback(item)
        this.closeModal()
        return
      }

      const contact = await contactsStore.createContact(params)

      if (contact) {
        this.options.callback(contact)
      }

      this.closeModal()
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  get numbers() {
    return Array.from(this.numbersMap.values())
  }

  get disabled() {
    return !this.activeNumber || this.loading
  }

  get alert() {
    return this._alert
  }

  setAlert = (item: IAlert) => {
    this._alert = item
  }

  resetAlert = () => {
    this._alert = null
  }
}

export const contactChoosePhoneModalStore = new ContactChoosePhoneModalStore()
