import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { authStore } from 'entities/Auth'

export default observer(() => {
  useEffect(() => {
    if (authStore.isLoggedIn) {
      if (authStore.closeAfterLogin) {
        window.close()
      } else {
        const searchParams = new URLSearchParams(window.location.search)
        const redirectUrl = searchParams.get('redirect')
        const decodedRedirectUrl = redirectUrl ? decodeURIComponent(redirectUrl) : '/'

        uiStore.changeRoute({
          path: decodedRedirectUrl,
          type: uiStore.checkRouteType(decodedRedirectUrl),
        })
        window.location.reload()
      }
    }
  }, [authStore.isLoggedIn])

  return <Outlet />
})
