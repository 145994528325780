// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TGTFuirVrSbF5LRM_KQ0{display:grid;gap:20px;--titled-row-action-width: 320px}.TGTFuirVrSbF5LRM_KQ0 ul{display:grid;padding-left:20px;list-style-type:disc}.S5_6YiRL7vWt6pxx3iwO{display:grid;grid-template-columns:1fr 108px;gap:20px}.hYpkWPB_m5qzpZ4qmMJR{padding-left:12px}.hYpkWPB_m5qzpZ4qmMJR input{color:var(--content-primary-tertiary)}.mpxwHhBlCwjl7bGOJWro{display:grid;width:100%;height:1px;background-color:var(--border-neutral-default)}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSettingsWebhook/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,gCAAA,CAEA,yBACE,YAAA,CACA,iBAAA,CACA,oBAAA,CAIJ,sBACE,YAAA,CACA,+BAAA,CACA,QAAA,CAGF,sBACE,iBAAA,CAEA,4BACE,qCAAA,CAIJ,sBACE,YAAA,CACA,UAAA,CACA,UAAA,CACA,8CAAA","sourcesContent":[".root {\n  display: grid;\n  gap: 20px;\n  --titled-row-action-width: 320px;\n\n  ul {\n    display: grid;\n    padding-left: 20px;\n    list-style-type: disc;\n  }\n}\n\n.urlForm {\n  display: grid;\n  grid-template-columns: 1fr 108px;\n  gap: 20px;\n}\n\n.input {\n  padding-left: 12px;\n\n  input {\n    color: var(--content-primary-tertiary);\n  }\n}\n\n.divider {\n  display: grid;\n  width: 100%;\n  height: 1px;\n  background-color: var(--border-neutral-default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "TGTFuirVrSbF5LRM_KQ0",
	"urlForm": "S5_6YiRL7vWt6pxx3iwO",
	"input": "hYpkWPB_m5qzpZ4qmMJR",
	"divider": "mpxwHhBlCwjl7bGOJWro"
};
export default ___CSS_LOADER_EXPORT___;
