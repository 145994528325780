import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import type { IAPiOption } from 'shared/api'
import { PhoneInputStore, type IDropdownItem } from 'shared/ui'
import { isEmail, logger } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { getEmailDomain, getUrlDomain } from 'shared/lib/getDomains'
import { ComplianceApi } from 'entities/Compliance'
import { OrganizationBusinessProfile } from 'entities/Compliance/model'
import { type BusinessProfileStepsStore } from 'pages/settings/pages/compliance/pages/businessProfile/store/businessProfileStepsStore'
import { BusinessProfileRoutes } from 'pages/settings/pages/compliance/pages/businessProfile/route/type'
import { SettingsPath } from 'pages/settings/route/settingsPath'

export class PeopleToContactStore {
  constructor(private _businessProfileStore: BusinessProfileStepsStore | null) {
    makeAutoObservable(this)
  }

  private _jobPositionListValue: IAPiOption[] = []

  private _firstName = ''
  private _lastName = ''
  private _businessTitle = ''
  private _jobPosition: number | null = null
  private _email = ''
  private _emailLocalError = ''
  private _similarityDomainsWarning: string | null = null

  private _isSecondContact = false
  private _contactsConfirmed = false

  private _firstName2 = ''
  private _lastName2 = ''
  private _businessTitle2 = ''
  private _jobPosition2: number | null = null
  private _email2 = ''
  private _emailLocalError2 = ''
  private _similarityDomainsWarning2: string | null = null

  phoneInputStore = new PhoneInputStore()
  secondPhoneInputStore = new PhoneInputStore()

  get primaryContact() {
    return {
      firstName: this._firstName,
      lastName: this._lastName,
      businessTitle: this._businessTitle,
      jobPosition: this._jobPosition,
      email: this._email,
      phoneNumber: this.phoneInputStore.number,
    }
  }

  get isPrimaryContactFilled() {
    if (!this.phoneInputStore.isValidNumber) return false
    if (this._jobPosition === null || !this._contactsConfirmed) return false

    return [this._firstName, this._lastName, this._email, this.phoneInputStore.number].every(
      (field) => field.trim().length > 0
    )
  }

  get secondaryContact() {
    return {
      firstName: this._firstName2,
      lastName: this._lastName2,
      businessTitle: this._businessTitle2,
      jobPosition: this._jobPosition2,
      email: this._email2,
      phoneNumber: this.secondPhoneInputStore.number,
    }
  }

  get isSecondaryContactFilled() {
    if (!this.secondPhoneInputStore.isValidNumber) return false
    if (this._jobPosition2 === null) return false

    return [
      this._firstName2,
      this._lastName2,
      this._email2,
      this.secondPhoneInputStore.number,
    ].every((field) => field.trim().length > 0)
  }

  get jobPositionList(): IDropdownItem[] {
    return this._jobPositionListValue.map((item) => {
      return { label: item.name || '', id: item.id }
    })
  }

  get isSecondContact() {
    return this._isSecondContact
  }

  get contactsConfirmed() {
    return this._contactsConfirmed
  }

  get isNextDisabled() {
    if (this.isSecondContact) {
      return !this.isPrimaryContactFilled || !this.isSecondaryContactFilled
    }

    return !this.isPrimaryContactFilled
  }

  get emailLocalError() {
    return this._emailLocalError
  }

  get emailLocalError2() {
    return this._emailLocalError2
  }

  get businessProfileStore() {
    return this._businessProfileStore
  }

  get similarityDomainsWarning() {
    return this._similarityDomainsWarning
  }

  get similarityDomainsWarning2() {
    return this._similarityDomainsWarning2
  }

  setSimilarityDomainsWarning = (value: string | null) => {
    this._similarityDomainsWarning = value
  }

  setSimilarityDomainsWarning2 = (value: string | null) => {
    this._similarityDomainsWarning2 = value
  }

  checkDomainSimilarity = (email: string, isFirst: boolean) => {
    const { websiteUrl } = this._businessProfileStore?.businessInfoStore || {}

    if (!websiteUrl) return

    const emailDomain = getEmailDomain(email)
    if (!emailDomain) return false

    const urlObj = new URL(websiteUrl)
    const urlDomain = getUrlDomain(urlObj)
    if (!urlDomain) return false

    const isSimilar = emailDomain === urlDomain

    const errorText = isSimilar
      ? null
      : "It looks like your business email domain doesn't match your business website domain"

    isFirst
      ? this.setSimilarityDomainsWarning(errorText)
      : this.setSimilarityDomainsWarning2(errorText)
  }

  checkIsEmail = (isFirst?: boolean) => {
    const email = isFirst ? this._email : this._email2
    const isCorrect = isEmail(email)

    if (isCorrect) {
      const { socialMediaUrl } = this.businessProfileStore?.businessInfoStore || {}

      !socialMediaUrl && this.checkDomainSimilarity(email, isFirst || false)
      isFirst ? this.setEmailLocalError('') : this.setEmailLocalError2('')

      return
    }

    const errorText = 'Please enter a valid email address'

    isFirst ? this.setEmailLocalError(errorText) : this.setEmailLocalError2(errorText)
    isFirst ? this.setSimilarityDomainsWarning(null) : this.setSimilarityDomainsWarning(null)
  }

  setEmailLocalError = (error: string) => {
    this._emailLocalError = error
  }

  setEmailLocalError2 = (error: string) => {
    this._emailLocalError2 = error
  }

  setPrimaryFirstName = (name: string) => {
    this._firstName = name
  }

  setPrimaryLastName = (name: string) => {
    this._lastName = name
  }

  setPrimaryBusinessTitle = (title: string) => {
    this._businessTitle = title
  }

  setPrimaryJobPosition = (position: number) => {
    this._jobPosition = position
  }

  setPrimaryEmail = (email: string) => {
    this.setEmailLocalError('')
    this.setSimilarityDomainsWarning(null)
    this._businessProfileStore?.deleteErrorValue('0.email')

    this._email = email
  }

  setSecondContact = (isSecond: boolean) => {
    if (!isSecond) {
      this.clearSecondaryContact()
    }

    this._isSecondContact = isSecond
  }

  toggleContactsConfirmed = () => {
    this._contactsConfirmed = !this._contactsConfirmed
  }

  setSecondaryFirstName = (name: string) => {
    this._firstName2 = name
  }

  setSecondaryLastName = (name: string) => {
    this._lastName2 = name
  }

  setSecondaryBusinessTitle = (title: string) => {
    this._businessTitle2 = title
  }

  setSecondaryJobPosition = (position: number) => {
    this._jobPosition2 = position
  }

  setSecondaryEmail = (email: string) => {
    this.setEmailLocalError2('')
    this.setSimilarityDomainsWarning2(null)
    this._businessProfileStore?.deleteErrorValue('1.email')
    this._email2 = email
  }

  clearSecondaryContact = () => {
    this._firstName2 = ''
    this._lastName2 = ''
    this._businessTitle2 = ''
    this._jobPosition2 = null
    this._email2 = ''
    this.secondPhoneInputStore.reset()
  }

  loadBusinessProfileJobPositions = async () => {
    try {
      const { data } = await ComplianceApi.getBusinessProfileJobPositions()

      this._jobPositionListValue = data
    } catch (error) {
      logger.error(error)
    }
  }

  nextStepClick = async () => {
    if (!this.primaryContact.jobPosition) return

    const pointsOfContacts = [
      {
        business_title: this.primaryContact.businessTitle,
        email: this.primaryContact.email,
        first_name: this.primaryContact.firstName,
        job_position_id: String(this.primaryContact.jobPosition),
        last_name: this.primaryContact.lastName,
        phone_number: this.primaryContact.phoneNumber,
      },
    ]

    if (this.isSecondContact) {
      pointsOfContacts.push({
        business_title: this.secondaryContact.businessTitle,
        email: this.secondaryContact.email,
        first_name: this.secondaryContact.firstName,
        job_position_id: String(this.secondaryContact.jobPosition),
        last_name: this.secondaryContact.lastName,
        phone_number: this.secondaryContact.phoneNumber,
      })
    }

    try {
      this.businessProfileStore?.setStepLoading(true)

      const { data } = await ComplianceApi.setBpPointsOfContact({
        contacts_confirmed: this.contactsConfirmed,
        points_of_contact: pointsOfContacts,
        step: 4,
      })
      const organizationBusinessProfile = new OrganizationBusinessProfile(data)
      this.businessProfileStore?.complianceLayerStore.setOrganizationBusinessProfile(
        organizationBusinessProfile
      )
      this.businessProfileStore?.complianceLayerStore.setOrganizationBusinessProfile(
        organizationBusinessProfile
      )

      uiStore.changeRoute({
        path: `/${SettingsPath.compliance.businessProfile.root}/${BusinessProfileRoutes.termsAndPrivacy}`,
      })
    } catch (e) {
      if (e instanceof AxiosError) {
        const { data } = e.response || {}
        this.businessProfileStore?.prepareFieldsErrorAndSave(data)
      }

      logger.error(e)
    } finally {
      this.businessProfileStore?.setStepLoading(false)
    }
  }
}
