import { makeAutoObservable, runInAction } from 'mobx'
import { type PageLayoutStore } from 'shared/layout'
import { ContactsApi } from 'entities/Contacts'
import { ContactsUnlinkedTableStore } from 'pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable'
import { ContactsDuplicateTableStore } from 'pages/contacts/pages/cleanup/ui/ContactsDuplicateTable'

type TabTypes = 'unlink' | 'duplicate' | string

export class CleanupPageStore {
  private _activeTab: TabTypes = 'duplicate'
  private _contactsUnlinkedTableStore = new ContactsUnlinkedTableStore()
  private _contactsDuplicateTableStore = new ContactsDuplicateTableStore(this)
  private _changeToUnlinked = false
  private _duplicate_count = 0
  private _unlinked_count = 0

  constructor(private _pageLayoutStore: PageLayoutStore) {
    makeAutoObservable(this)
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get activeTab() {
    return this._activeTab
  }

  get contactsUnlinkedTable() {
    return this._contactsUnlinkedTableStore
  }

  get contactsDuplicateTable() {
    return this._contactsDuplicateTableStore
  }

  get isChangeToUnlinked() {
    return this._changeToUnlinked
  }

  get duplicateCount() {
    return this._duplicate_count
  }

  get unlinkedCount() {
    return this._unlinked_count
  }

  subtractDuplicateCount = () => {
    this._duplicate_count -= 1
  }

  loadDuplicateCount = async () => {
    try {
      const { data } = await ContactsApi.getContactsDuplicateCount()

      runInAction(() => {
        this._duplicate_count = data.duplicate_count
        this._unlinked_count = data.unlinked_count
      })
    } catch (e) {
      console.log(e)
    }
  }

  init = () => {
    this._contactsDuplicateTableStore.init()
    this._contactsUnlinkedTableStore.init()
    this.loadDuplicateCount()
  }

  handleOpen = () => {
    if (!this._contactsDuplicateTableStore.noResults) {
      this._activeTab === 'duplicate'
      return
    }
  }

  handleActiveTab = (tab: TabTypes) => {
    this._activeTab = tab
  }

  setIsChangeToUnlinked = (value: boolean) => {
    this._changeToUnlinked = value
  }

  resetPaginationPage = () => {
    this._contactsDuplicateTableStore.resetPaginationPage()
    this._contactsUnlinkedTableStore.resetPaginationPage()
  }
}
