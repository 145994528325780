import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { SpinnerLoader } from 'shared/ui'
import { FiltersAndSearch } from 'widgets/FiltersAndSearch'
import {
  PowerDialerListProvider,
  usePowerDialerListContext,
} from 'pages/powerDialer/pages/list/context/powerDialerListContext'
import { PowerDialerListEmpty } from 'pages/powerDialer/pages/list/ui/PowerDialerListEmpty/PowerDialerListEmpty'
import { PowerDialerTable } from 'pages/powerDialer/pages/list/ui/PowerDialerTable/PowerDialerTable'
import { PowerDialerListHeader } from 'pages/powerDialer/pages/list/ui/PowerDialerListHeader/PowerDialerListHeader'
import styles from './styles.module.scss'

export const PowerDialerList = observer(() => {
  const parentRef = useRef<HTMLDivElement | null>(null)
  const { filtersAndSearchStore, initialLoading, empty } = usePowerDialerListContext()

  if (initialLoading) {
    return <SpinnerLoader />
  }

  if (empty) {
    return <PowerDialerListEmpty />
  }

  return (
    <div ref={parentRef} className={styles.listPageContainer}>
      <PowerDialerListHeader />
      <FiltersAndSearch store={filtersAndSearchStore} parent={parentRef.current} />
      <PowerDialerTable />
    </div>
  )
})

export const PowerDialerListPage = () => (
  <PowerDialerListProvider>
    <PowerDialerList />
  </PowerDialerListProvider>
)
