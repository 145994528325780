import type { IStatusProps } from 'shared/ui/Status'
import type { PowerDialerStatus } from 'entities/PowerDialer/api/types'

export const powerDialerStatusBadgeConfig: Record<PowerDialerStatus, IStatusProps> = {
  draft: {
    title: 'Draft',
    icon: 'draft',
    emphasis: 'high',
    intent: 'neutral',
  },
  in_progress: {
    title: 'In Progress',
    icon: 'refreshCircle',
    emphasis: 'high',
    intent: 'informative',
  },
  paused: {
    title: 'Paused',
    icon: 'pause',
    emphasis: 'high',
    intent: 'orange',
  },
  finished: {
    title: 'Finished',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  },
}
