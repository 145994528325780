// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NorRhRKw655Xf6s3V17a{height:auto}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/calling/store/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA","sourcesContent":[".customDropdown {\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customDropdown": "NorRhRKw655Xf6s3V17a"
};
export default ___CSS_LOADER_EXPORT___;
