import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CancelTokenSource } from 'axios'
import { isEqual } from 'lodash'
import { logger } from 'shared/lib'
import { ITollFreeStatus } from 'entities/Compliance'
import {
  AdminComplianceApi,
  IAdminTollFreeData,
  IParamsAdminGetTollFrees,
  IResponseAdminTollFreeData,
} from 'entities/Admin/compliance'
import { ISubscriptionStatus } from 'entities/Subscription'
import { DEFAULT_STATUSES } from 'pages/admin/pages/compliance/pages/TollFree/store/constants'

class AdminTollFreeTableStore {
  constructor() {
    makeAutoObservable(this)
    reaction(() => this.paramsGetData, this.loadData, { delay: 500 })
  }

  loading = false

  page = 1
  limit: number | null = 50
  total = 0

  search: string | null = null
  loadingSearch = false
  visibleColumnsIds: string[] = []

  statuses: ITollFreeStatus[] = DEFAULT_STATUSES
  subscriptionStatuses: ISubscriptionStatus[] = []

  itemsMap: Map<number, IAdminTollFreeData> = new Map()

  cancelTokenSource: CancelTokenSource | null = null

  get items() {
    return Array.from(this.itemsMap.values())
  }

  get paramsGetData(): IParamsAdminGetTollFrees {
    return {
      statuses: this.statuses,
      page: this.page,
      search: this.search || null,
      limit: this.limit,
      subscription_statuses: this.subscriptionStatuses,
    }
  }

  get isDefaultStatuses() {
    return isEqual(this.statuses, DEFAULT_STATUSES)
  }

  loadData = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })
      this.initCancelTokenSource()

      const { data } = await AdminComplianceApi.getTollFrees(this.paramsGetData, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })
      this.setData(data)
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setData = ({ data, meta }: IResponseAdminTollFreeData) => {
    this.reset()
    this.setItems(data)
    this.total = meta.total
  }

  setItems = (items: IAdminTollFreeData[]) => {
    items.forEach((item) => this.setItem(item))
  }

  setItem = (item: IAdminTollFreeData) => {
    this.itemsMap.set(item.id, item)
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  reset = () => {
    this.itemsMap.clear()
  }

  setSearch = (value: string) => {
    this.page = 1
    this.search = value
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }
  setStatuses = (values: typeof this.statuses) => {
    this.page = 1
    this.statuses = values
  }
  setVisibleColumnsIds = (ids: string[]) => {
    this.visibleColumnsIds = ids
  }
  setSubscriptionStatuses = (values: ISubscriptionStatus[]) => {
    this.page = 1
    this.subscriptionStatuses = values
  }
}

export const adminTollFreeTableStore = new AdminTollFreeTableStore()
