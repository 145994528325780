import { debounce } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import { logger } from 'shared/lib'
import { ContactFiltersApi } from 'entities/Contacts/api/contactsFilters'
import { TagsApi } from 'entities/Tags'

export type ITagItem = { key: number; label: string }

const getTags = (ids: number[]) => {
  if (!ids.length) return Promise.resolve([])

  return ContactFiltersApi.getFiltersTags({ ids, length: 100 }).then(({ data: { data } }) =>
    data.map((item) => ({
      key: item.key,
      label: item.label,
    }))
  )
}

const searchTags = (search: string, page: number, exclude: number[], cancel?: CancelTokenSource) =>
  ContactFiltersApi.getFiltersTags(
    { search, page, exclude, length: 40 },
    { cancelToken: cancel?.token }
  ).then(({ data: { data, total, current_page, per_page } }) => ({
    data: data.map((item) => ({
      key: item.key,
      label: item.label,
    })),
    page: current_page,
    perPage: per_page,
    total,
  }))

const createTag = (label: string) =>
  TagsApi.createTag({ label }).then(({ data }) => ({
    key: data.id,
    label: `${data.label} (0)`,
  }))

export class TagControl {
  private _page = 0
  private _perPage = 0
  private _total = 0
  private _term = ''
  private _loading = true
  private _initialLoading = true

  private _termTags = new Map<number, ITagItem>()
  private _selectedTags = new Map<number, ITagItem>()
  private _excludedTags = new Map<number, ITagItem>()

  private _cancelTokenSource: CancelTokenSource | undefined = undefined

  constructor() {
    makeAutoObservable(this)
  }

  get term() {
    return this._term
  }

  get hasMore() {
    return this._loading || this._page * this._perPage < this._total
  }

  get loading() {
    return this._loading
  }

  get initialLoading() {
    return this._initialLoading
  }

  get selectedItems() {
    return Array.from(this._selectedTags.values())
  }

  get selectedItemsEmpty() {
    return !this._selectedTags.size
  }

  get syncedTags() {
    return Array.from(this._excludedTags.values())
  }

  get syncedItemsEmpty() {
    return !this._excludedTags.size
  }

  get termTags() {
    return Array.from(this._termTags.values())
  }

  get termItemsEmpty() {
    return !this._termTags.size
  }

  isSelected = (key: number) => this._selectedTags.has(key)

  toggleTag = (key: number) => {
    if (this._selectedTags.has(key)) {
      this._selectedTags.delete(key)
    } else {
      const item = this._termTags.get(key) ?? this._excludedTags.get(key)
      item && this._selectedTags.set(item.key, item)
    }
  }

  syncTags = async (keys: number[]) => {
    this._initialLoading = true
    const items = await getTags(keys)

    runInAction(() => {
      items.forEach((item) => {
        this._selectedTags.set(item.key, item)
        this._excludedTags.set(item.key, item)
      })

      this._initialLoading = false
    })
  }

  createTag = async (label: string) => {
    this._loading = true
    this._term = ''

    try {
      const tag = await createTag(label)

      runInAction(() => {
        this._excludedTags.set(tag.key, tag)
        this._selectedTags.set(tag.key, tag)

        this.searchTags('', true)
      })

      return tag
    } catch (error) {
      runInAction(() => {
        this._loading = error instanceof CanceledError
      })

      logger.error(error)
    }
  }

  searchTags = async (term = '', immediate = false) => {
    this._term = term
    this._page = 1

    const makeSearch = async () => {
      runInAction(() => this._termTags.clear())

      const { data, total, page, perPage } = await searchTags(
        this._term,
        this._page,
        [...this._excludedTags.keys()],
        this._cancelTokenSource
      )

      runInAction(() => {
        this._page = page
        this._total = total
        this._perPage = perPage

        data.forEach((item) => this._termTags.set(item.key, item))
      })
    }

    immediate ? this._tryMakeRequest(makeSearch) : this._tryMakeRequestDelay(makeSearch)
  }

  loadMore = (immediate = false) => {
    const makeLoadMore = async () => {
      const { data, total, page, perPage } = await searchTags(
        this._term,
        this._page + 1,
        [...this._excludedTags.keys()],
        this._cancelTokenSource
      )

      runInAction(() => {
        this._total = total
        this._page = page
        this._perPage = perPage

        data.forEach((item) => this._termTags.set(item.key, item))
      })
    }

    immediate ? this._tryMakeRequest(makeLoadMore) : this._tryMakeRequestDelay(makeLoadMore)
  }

  clearSelection = () => {
    this._selectedTags.clear()
  }

  reset = () => {
    this._page = 0
    this._perPage = 0
    this._total = 0
    this._term = ''
    this._loading = true
    this._initialLoading = true

    this._termTags.clear()
    this._selectedTags.clear()
    this._excludedTags.clear()

    this._cancelTokenSource?.cancel()
  }

  private _tryMakeRequest = async (action: () => Promise<void>) => {
    runInAction(() => {
      this._loading = true
      this._initCancelTokenSource()
    })

    try {
      await action()
      runInAction(() => {
        this._loading = false
      })
    } catch (error) {
      runInAction(() => {
        this._loading = error instanceof CanceledError
      })

      logger.error(error)
    }
  }

  private _tryMakeRequestDelay = debounce(this._tryMakeRequest, 500)

  private _initCancelTokenSource = () => {
    if (this._cancelTokenSource) this._cancelTokenSource.cancel()

    this._cancelTokenSource = axios.CancelToken.source()
  }
}
