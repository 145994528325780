import { logger } from 'shared/lib'
import { InboxesApi } from 'entities/Inbox'
import { IParamsSendFrom, IParamsSendFromType } from 'entities/Inbox/api/types'
import { PhoneApi } from 'entities/Phone'

export interface ISendFromInfo {
  name: string
  label: string
}

const getSmartOptionLabel = (type?: IParamsSendFromType) => {
  switch (type) {
    case 'local_presence':
      return 'Local presence'
    case 'scaler':
      return 'Scaler'
    default:
      return ''
  }
}

const getSmartInboxInfo = async (sendFrom: IParamsSendFrom) => {
  const teamId = sendFrom.options.team_id
  if (!teamId) return null

  const {
    data: { name },
  } = await InboxesApi.getTeamById(teamId)
  const label = getSmartOptionLabel(sendFrom.options?.smart_option)

  return { name, label }
}

const getNumberInfo = async (sendFrom: IParamsSendFrom) => {
  const numberId = sendFrom.options?.number_id
  if (!numberId) return null

  const {
    data: { data: numbers },
  } = await PhoneApi.getNumbers({ ids: [numberId] })
  if (!numbers?.length) return null

  const [number] = numbers

  const name = number.numberable?.name
  if (!name) return null

  const label = number.formattedNumber

  return { name, label }
}

export const getSendFromInfo = async (
  sendFrom?: IParamsSendFrom | null
): Promise<ISendFromInfo | null> => {
  if (!sendFrom) return null

  try {
    switch (sendFrom.type) {
      case 'from_smart_inbox': {
        return await getSmartInboxInfo(sendFrom)
      }
      case 'from_number': {
        return await getNumberInfo(sendFrom)
      }
      default:
        return null
    }
  } catch (error) {
    logger.error(error)

    return null
  }
}
