import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { type ImportContactsStore } from 'pages/contacts/ui/ImportContacts/store/ImportContactsStore'

type IImportContactsModalErrorProps = {
  store: ImportContactsStore
}

export const ImportContactsModalError: FC<IImportContactsModalErrorProps> = observer(
  ({ store }) => {
    const { importModalError } = store

    if (!importModalError.title && !importModalError.desc) return null

    return (
      <Alert
        item={{
          type: 'alert',
          title: importModalError.title || '',
          desc: importModalError.desc || '',
        }}
      />
    )
  }
)
