import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, IRow, Table } from 'shared/ui'
import type { IAdminTenDCLsData } from 'entities/Admin/compliance'
import { ComplianceTableWrapper } from 'pages/admin/pages/compliance'
import { columns } from 'pages/admin/pages/compliance/pages/TenDLC/ui/TenDLCTable/columns'
import { adminTenDLCTableStore } from 'pages/admin/pages/compliance/pages/TenDLC'

export const TenDLCTable = observer(() => {
  const {
    total,
    limit,
    items,
    loadData,
    onPaginationModelChange,
    loading,
    page,
    visibleColumnsIds,
    setVisibleColumnsIds,
  } = adminTenDLCTableStore
  const getRowLink = (row: IRow<IAdminTenDCLsData>) => {
    return String(row.id)
  }
  useEffect(() => {
    loadData()
    if (!visibleColumnsIds.length) {
      setVisibleColumnsIds(columns.map((column) => column.field))
    }
  }, [])
  return (
    <ComplianceTableWrapper>
      <Table
        columns={columns}
        rows={items}
        rowsCount={total}
        isLoading={loading}
        onChangePagination={onPaginationModelChange}
        initPage={page}
        visibleColumnsIds={visibleColumnsIds}
        setVisibleColumnsIds={setVisibleColumnsIds}
        getRowLink={getRowLink}
        startPaginationSize={25}
        limit={limit}
        rightHeaderContent={
          <Button
            icon={'refresh'}
            text={'Refresh'}
            size={'small'}
            contained={'secondary'}
            onClick={loadData}
            style={{ marginRight: 8 }}
          />
        }
      />
    </ComplianceTableWrapper>
  )
})
