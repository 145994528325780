import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { links } from 'shared/constants/links'
import { PowerDialerEditorStore } from 'widgets/PowerDialer/ui/PowerDialerEditor/store/powerDialerEditorStore'
import { usePowerDialerAvailability } from 'widgets/PowerDialer/ui/PowerDialerEditor/hooks/usePowerDialerAvailability'
import styles from './styles.module.scss'

export const PowerDialerNewSessionAction = observer(() => {
  const { hideButton, disabled, getTooltipAvailability } = usePowerDialerAvailability()
  const availabilityLabel = getTooltipAvailability()

  if (hideButton) return null

  return (
    <Button
      text='New campaign'
      iconProps={{ icon: 'add' }}
      size='medium'
      disabled={disabled}
      onClick={() => new PowerDialerEditorStore().handleStartPowerDialerEditorFromFilters()}
      tooltipProps={{
        label: availabilityLabel,
        placement: 'top',
      }}
    />
  )
})

export const PowerDialerHelpGuideAction = observer(() => {
  return (
    <Button
      tag='a'
      text='Help guide'
      size='medium'
      contained='secondary'
      iconProps={{ icon: 'info' }}
      className={styles.helpButton}
      href={links.powerDialer}
      target='_blank'
      rel='noreferrer'
    />
  )
})
