// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zVCv2LHuQhX9ulLqlNrM{width:100%;display:grid;grid-template-columns:1fr var(--titled-row-action-width, 280px);gap:var(--titled-row-horizontal-gap, 20px)}.s05sDPIuWM_yEGnhBjda{grid-template-columns:1fr}.yffF04NnwZ5HEVVwKYCW{width:100%;display:flex;flex-direction:column;justify-content:center;gap:var(--titled-row-vertical-gap, 4px)}.HGxaVijLnlKrs5CRWlNq{width:100%;display:flex;align-items:center;gap:6px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/integrations/pages/integrationSettings/ui/TitledRow/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,+DAAA,CACA,0CAAA,CAGF,sBACE,yBAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,uCAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".root {\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr var(--titled-row-action-width, 280px);\n  gap: var(--titled-row-horizontal-gap, 20px);\n}\n\n.oneColumn {\n  grid-template-columns: 1fr;\n}\n\n.main {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: var(--titled-row-vertical-gap, 4px);\n}\n\n.header {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "zVCv2LHuQhX9ulLqlNrM",
	"oneColumn": "s05sDPIuWM_yEGnhBjda",
	"main": "yffF04NnwZ5HEVVwKYCW",
	"header": "HGxaVijLnlKrs5CRWlNq"
};
export default ___CSS_LOADER_EXPORT___;
