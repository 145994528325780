import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { authStore, type IResponseEventTokenCreated } from 'entities/Auth'
import { ConversationRoutes, SettingsRoutes, IntegrationsRoutes } from 'entities/ExportRouters'
import { IntegrationKey } from 'entities/Integrations'
import { RegisterStore } from 'widgets/Register/store/registerStore'

const TokenCreated = {
  handle: (data: IResponseEventTokenCreated, store?: RegisterStore) => {
    if (store) store.setLoading(false)
    const searchParams = new URLSearchParams(window.location.search)
    const utmSource = searchParams.get('utm_source')
    const isHubSpotAuth = utmSource === IntegrationKey.hubspot

    authStore.setSuccessAuthToken(data.token.access_token)
    window.stop()
    window.location.href = isHubSpotAuth
      ? `/${SettingsRoutes.root}/${SettingsRoutes.integrations}/${IntegrationKey.hubspot}/${IntegrationsRoutes.description}`
      : `/${ConversationRoutes.root}?${searchParams}`
  },
}

export const EventTokenCreated = {
  subscribe: (store: RegisterStore) =>
    websocket.on(WebsocketEvent.TokenCreated, (data: IResponseEventTokenCreated) =>
      TokenCreated.handle(data, store)
    ),
  unsubscribe: () => websocket.off(WebsocketEvent.TokenCreated, TokenCreated.handle),
}
