import { Modal, Toast } from 'shared/ui'
import { PresentationMode } from 'widgets/PresentationMode/ui/PresentationMode'

const OverlayLayout = () => {
  return (
    <>
      <Modal />
      <PresentationMode />
      <Toast />
    </>
  )
}

export default OverlayLayout
