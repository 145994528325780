import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Button, InputCheckbox, Tooltip } from 'shared/ui'
import { type ImportContactsStore } from 'pages/contacts/ui/ImportContacts/store/ImportContactsStore'
import styles from './styles.module.scss'

type IMapFieldsModalContentProps = {
  store: ImportContactsStore
}

export const MapFieldsModalActions: FC<IMapFieldsModalContentProps> = observer(({ store }) => {
  const {
    noProcessedCount,
    isDoNotImportRest,
    toggleDoNotImportRest,
    handleCloseAndDelete,
    openSecondModal,
  } = store

  const nextDisabled = isDoNotImportRest ? false : noProcessedCount > 0

  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <InputCheckbox
          checked={isDoNotImportRest}
          label={`Don’t import data in ${noProcessedCount} unmapped columns`}
          onChecked={toggleDoNotImportRest}
        />
      </div>
      <div>
        <Button
          className={styles.cancelButton}
          text='Cancel'
          contained='tertiary'
          onClick={handleCloseAndDelete}
        />
        <Tooltip
          label={
            nextDisabled
              ? ['Map all columns before ', <br key='line-break' />, 'going to the next step']
              : null
          }
        >
          <Button text='Next' disabled={nextDisabled} onClick={openSecondModal} />
        </Tooltip>
      </div>
    </div>
  )
})
