import { makeAutoObservable } from 'mobx'
import { IHubspotSettingsDict, Integration } from 'entities/Integrations'
import { IntegrationCustomSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCustomSettingsStore'
import { IntegrationCollapseSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCollapseSettingsStore'
import { IntegrationHubspotSettingsChannelsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsChannelsStore'
import { IntegrationHubspotSettingsPropertyStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsPropertyStore'
import { IntegrationHubspotSettingsSyncStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsSyncStore'
import { IntegrationHubspotSettingsTimelineStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/store/IntegrationHubspotSettingsTimelineStore'
import { IntegrationSettingsUsersStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationSettingsUsersStore'
import { IntegrationWebhookStore } from 'pages/settings/pages/integrations/store/IntegrationWebhookStore'
import { IntegrationHubspotSettingsListsStore } from './IntegrationHubspotSettingsListsStore'

export class IntegrationHubspotSettingsStore {
  private _collapseStore
  private _syncStore
  private _webhookStore
  private _userStore
  private _channelsStore
  private _timelineStore
  private _propertyStore
  private _customSettingsStore
  private _listsStore

  constructor(integration: Integration) {
    this._collapseStore = new IntegrationCollapseSettingsStore()
    this._webhookStore = new IntegrationWebhookStore()
    this._customSettingsStore = new IntegrationCustomSettingsStore<IHubspotSettingsDict>(
      integration
    )
    this._channelsStore = new IntegrationHubspotSettingsChannelsStore(integration)
    this._userStore = new IntegrationSettingsUsersStore(integration)
    this._syncStore = new IntegrationHubspotSettingsSyncStore(
      integration,
      this._customSettingsStore
    )
    this._propertyStore = new IntegrationHubspotSettingsPropertyStore(
      integration,
      this._customSettingsStore
    )
    this._timelineStore = new IntegrationHubspotSettingsTimelineStore(this._customSettingsStore)
    this._listsStore = new IntegrationHubspotSettingsListsStore()

    makeAutoObservable(this)
    this.init(integration)
  }

  init = (integration: Integration) => {
    this._customSettingsStore.init()
    this._webhookStore.initIntegrationWebhookState(integration)
    this._channelsStore.init()
    this._userStore.init()
    this._syncStore.init()
    this._propertyStore.init()
    this._timelineStore.init()
    this._listsStore.init()
  }

  get syncStore() {
    return this._syncStore
  }

  get webhookStore() {
    return this._webhookStore
  }

  get userStore() {
    return this._userStore
  }

  get timelineStore() {
    return this._timelineStore
  }

  get propertyStore() {
    return this._propertyStore
  }

  get channelsStore() {
    return this._channelsStore
  }

  get listsStore() {
    return this._listsStore
  }

  get collapseStore() {
    return this._collapseStore
  }
}
