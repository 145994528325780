import type { IResponseListData, ISortOrder } from 'shared/api'
import type { IParamsSendFrom, IResponseSendFrom } from 'entities/Inbox/api/types'
import type { IResponseFilterSegment } from 'entities/Segment'
import type { IResponseNumber } from 'entities/Phone/api/types'

export enum PowerDialerStatus {
  Draft = 'draft',
  InProgress = 'in_progress',
  Paused = 'paused',
  Finished = 'finished',
}

export type ContactFinalStatus = 'contacted' | 'skipped'

export type IParamsCreatePowerDialer = {
  name: string
  contacts_id: number[]
  status: PowerDialerStatus.Draft | PowerDialerStatus.InProgress
  send_from: IParamsSendFrom
}

export type IResponsePowerDialer = {
  id: number
  name: string
  status: PowerDialerStatus
  send_from: IResponseSendFrom
  number: IResponseNumber | null
  started_by: number
  contacts_id_unprocessed: number[]
  contacts_id_enrolled: number[]
  created_at: string
  updated_at: string

  enrolled: number
  answered: number
  no_answered: number
  contacted: number
  no_contacted: number
  skipped: number
}

export type IParamsGetPowerDialerList = {
  limit: number
  page: number
  search: string
  sortBy?: string
  sortOrder?: ISortOrder
  filtersList: IResponseFilterSegment[]
}

export type IResponsePowerDialerList = IResponseListData<IResponsePowerDialer>
