import { type IAvatarInfo } from 'shared/ui'
import { Base } from 'models'
import type { IResponseIntegrationContactMatch } from 'entities/Integrations'

export class MatchedContact extends Base {
  integrationVendorId: string
  first_name: string | null
  last_name: string | null
  email: string | null
  full_name: string | null
  mobile_phone: string
  phone: string
  hubspot_team_id: string | null
  photo_url: string | null

  constructor(item: IResponseIntegrationContactMatch) {
    super()
    this.integrationVendorId = item.id
    this.first_name = item.first_name
    this.last_name = item.last_name
    this.email = item.email
    this.full_name = item.full_name
    this.mobile_phone = item.numbers['Mobile Phone'] || ''
    this.phone = item.numbers.Phone || ''
    this.hubspot_team_id = item.hubspot_team_id
    this.photo_url = item.photo_url
  }

  get avatarInfo(): IAvatarInfo {
    return {
      firstName: this.first_name || this.email || this.phone || undefined,
      lastName: this.last_name || undefined,
      image: this.photo_url || undefined,
      color: this.generateColor,
      number: this.phone,
      icon: undefined,
    }
  }

  get generateColor() {
    const colors = ['#6372A5', '#1D95F2', '#10D0AD', '#E13466', '#FFC422', '#FF9423', '#A233E1']

    return colors[+this.integrationVendorId % colors.length]
  }

  get name() {
    if (this.first_name || this.last_name) {
      return `${this.first_name} ${this.last_name}`
    }

    return ''
  }
}
