// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".us7gpWcm7KOJQiipUvFh{position:relative}.us7gpWcm7KOJQiipUvFh .GNEG69fZf3Eab5v5MGLe{display:flex;align-items:center;justify-content:center;padding:24px 0}.us7gpWcm7KOJQiipUvFh .GNEG69fZf3Eab5v5MGLe .atVbMJ_TyoQNFT1M4R9A{display:flex;align-items:center;justify-content:center;height:32px;width:32px;border-radius:100%;background-color:var(--background-neutral-default)}.us7gpWcm7KOJQiipUvFh .QzpU_nFrKpl132QMetTl{display:flex;align-items:center;justify-content:space-between;padding:8px;margin-top:16px;border-radius:6px}.us7gpWcm7KOJQiipUvFh .QzpU_nFrKpl132QMetTl:hover{background:var(--background-neutral-subtle)}", "",{"version":3,"sources":["webpack://./src/pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable/ui/LinkContact/ui/LinkContactModalContent/LinkMatchingTab/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CAEA,kEACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,UAAA,CACA,kBAAA,CACA,kDAAA,CAIJ,4CACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,WAAA,CACA,eAAA,CACA,iBAAA,CAEA,kDACE,2CAAA","sourcesContent":[".wrap {\n  position: relative;\n\n  .divider {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 24px 0;\n\n    .icon {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      height: 32px;\n      width: 32px;\n      border-radius: 100%;\n      background-color: var(--background-neutral-default);\n    }\n  }\n\n  .updateWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    padding: 8px;\n    margin-top: 16px;\n    border-radius: 6px;\n\n    &:hover {\n      background: var(--background-neutral-subtle);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "us7gpWcm7KOJQiipUvFh",
	"divider": "GNEG69fZf3Eab5v5MGLe",
	"icon": "atVbMJ_TyoQNFT1M4R9A",
	"updateWrapper": "QzpU_nFrKpl132QMetTl"
};
export default ___CSS_LOADER_EXPORT___;
