import { SettingsRoutes } from 'pages/settings/route/type'
import { QuestionnaireRoutes } from 'pages/settings/pages/compliance/pages/questionnaire/route/type'
import { BusinessProfileRoutes } from 'pages/settings/pages/compliance/pages/businessProfile/route/type'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers/route/type'
import {
  TollFreeRoutes,
  TollFreeRoutesSteps,
} from 'pages/settings/pages/compliance/pages/tollFree/route/type'
import { CallingRoutesSteps } from 'pages/settings/pages/compliance/pages/calling/route/type'
import { ComplianceRoutes } from './type'

const compliance = `${SettingsRoutes.root}/${ComplianceRoutes.root}`

const complianceQuestionnaire = `${compliance}/${QuestionnaireRoutes.root}`

const complianceBusinessProfile = `${compliance}/${BusinessProfileRoutes.root}`
const complianceBusinessProfileCompanyLocation = `${complianceBusinessProfile}/${BusinessProfileRoutes.companyLocation}`

const complianceLocalNumbers = `${compliance}/${LocalNumbersRoutes.root}`
const complianceLocalNumbersUseCases = `${complianceLocalNumbers}/${LocalNumbersRoutes.campaignUseCases}`
const complianceLocalNumbersTollFreeNumbers = `${complianceLocalNumbers}/${LocalNumbersRoutes.tollFreeNumbers}`

const complianceTollFree = `${compliance}/${TollFreeRoutes.root}`
const complianceTollFreeSteps = `${complianceTollFree}/${TollFreeRoutes.steps}`
const complianceTollFreeView = (id: number) => `${complianceTollFree}/${TollFreeRoutes.view}/${id}`
const complianceTollFreeViewSteps = (id: number) =>
  `${complianceTollFree}/${TollFreeRoutes.view}/${id}/${TollFreeRoutes.steps}`
const complianceTollFreeNumbers = `${compliance}/${TollFreeRoutesSteps.numbers}`

const complianceCalling = `${compliance}/${CallingRoutesSteps.root}`
const complianceCallingView = `${complianceCalling}/${CallingRoutesSteps.view}`

export const CompliancePath = {
  compliance: {
    root: compliance,
    questionnaire: {
      root: complianceQuestionnaire,
    },
    businessProfile: {
      root: complianceBusinessProfile,
      companyLocation: complianceBusinessProfileCompanyLocation,
    },
    localNumbers: {
      root: complianceLocalNumbers,
      useCases: complianceLocalNumbersUseCases,
      tollFreeNumbers: complianceLocalNumbersTollFreeNumbers,
    },
    tollFree: {
      root: complianceTollFree,
      steps: complianceTollFreeSteps,
      view: (id: number) => complianceTollFreeView(id),
      viewSteps: (id: number) => complianceTollFreeViewSteps(id),
      numbers: complianceTollFreeNumbers,
    },
    calling: {
      root: complianceCalling,
      view: complianceCallingView,
    },
  },
}
