import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router'
import { EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import { logger } from 'shared/lib'
import { broadcastStore } from 'entities/Broadcast'
import { BroadcastsRoutes } from 'entities/ExportRouters'
import {
  IResponseBroadcastById,
  IResponseOneTimeBroadcast,
  IResponseRecurringBroadcastById,
} from 'entities/Broadcast/api/types'
import { inboxesStore } from 'entities/Inbox'
import { numbersStore } from 'entities/Phone'
import { BroadcastView } from 'widgets/BroadcastView'
import { BroadcastViewStore } from 'widgets/BroadcastView/store/broadcastViewStore'

const SuccessContent = ({
  broadcastResponse,
  isDuplicate,
}: {
  broadcastResponse: IResponseOneTimeBroadcast | IResponseRecurringBroadcastById
  isDuplicate?: boolean
}) => {
  const [store] = useState(() => new BroadcastViewStore(broadcastResponse, isDuplicate))
  return <BroadcastView store={store} />
}

type IEditBroadcastProps = {
  isDuplicate?: boolean
}

export const EditBroadcast = ({ isDuplicate }: IEditBroadcastProps) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [broadcastResponse, setBroadcastResponse] = useState<IResponseBroadcastById | null>(null)
  const [] = useState()
  useEffect(() => {
    if (id) {
      Promise.all([
        broadcastStore.getItemById(id),
        inboxesStore.fetchSmartInboxes(),
        numbersStore.fetchNumbersShortCodes(),
      ])
        .then(([res]) => {
          if (res) {
            setBroadcastResponse(res.data)
          }
        })
        .catch(logger.error)
        .finally(() => {
          setLoading(false)
        })
    }
  }, [id])

  if (loading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }
  if (broadcastResponse) {
    return <SuccessContent broadcastResponse={broadcastResponse} isDuplicate={isDuplicate} />
  }
  return <Navigate to={`/${BroadcastsRoutes.root}/${BroadcastsRoutes.all}`} />
}
