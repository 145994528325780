// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".amrpcG6YynetO1kCh8aX{padding:20px 20px 16px 20px}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/detail/CampaignOverview/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA","sourcesContent":[".wrapper {\n  padding: 20px 20px 16px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "amrpcG6YynetO1kCh8aX"
};
export default ___CSS_LOADER_EXPORT___;
