import { useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type ContactsTableStore } from 'pages/contacts/ui/ContactsTable'
import { ContactsTableContext } from 'pages/contacts/ui/ContactsTable/context/contactsTableContext'
import { ContactsTableContent } from './ContactsTableContent'

type IContactsTableProps = {
  store: ContactsTableStore
}

export const ContactsTable: FC<IContactsTableProps> = observer(({ store }) => {
  return useMemo(() => {
    return (
      <ContactsTableContext.Provider value={store}>
        <ContactsTableContent />
      </ContactsTableContext.Provider>
    )
  }, [])
})
