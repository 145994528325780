import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import { AdminOrganizationsApi, IResponseAdminOrganizationById } from 'entities/Admin'

class AdminToggleBanStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''
  organization: IResponseAdminOrganizationById | null = null

  onSuccess: (() => void) | null = null

  get isBanned() {
    return this.organization?.status === 'banned'
  }

  reset = () => {
    this.modalId = ''
    this.organization = null
  }

  onToggleBan = async () => {
    try {
      if (!this.organization?.id) return
      if (this.isBanned) {
        await AdminOrganizationsApi.unban(this.organization.id)
      } else {
        await AdminOrganizationsApi.ban(this.organization.id)
      }
      modalStore.closeModal(this.modalId)
      this.onSuccess && this.onSuccess()
    } catch (e) {
      logger.error(e)
    }
  }

  onOpenModal = (
    organization: IResponseAdminOrganizationById,
    onSuccess: typeof this.onSuccess
  ) => {
    this.reset()
    this.organization = organization
    this.modalId = nanoid()
    this.onSuccess = onSuccess
    const text = this.isBanned ? 'Unban' : 'Ban'
    modalStore.addModal({
      id: this.modalId,
      title: `${text} organization`,
      desc: [
        `Are you sure you want to ${text.toLowerCase()}`,
        <br key={'br'} />,
        <b key={'b'}>{organization.name}</b>,
      ],
      type: ModalTypeList.INFO,
      primaryAction: {
        text: `Yes, ${text} account`,
        onAction: this.onToggleBan,
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => modalStore.closeModal(this.modalId),
      },
    })
  }
}

export const adminToggleBanStore = new AdminToggleBanStore()
