import { observer } from 'mobx-react-lite'
import React from 'react'
import { Icon, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { Integration } from 'entities/Integrations/model/Integration'
import { getIntegrationDetailsDataByKey } from 'entities/Integrations/lib/integrationsDescription'
import { IntegrationStatus } from 'widgets/Integrations'
import { IntegrationListCardActions } from 'pages/settings/pages/integrations/pages/integrationList/ui/IntegrationListCard/IntegrationListCardActions'
import { SettingsRoutes } from 'pages/settings/route/type'
import styles from './styles.module.scss'

type IIntegrationListCardProps = {
  integration: Integration
}

export const IntegrationListCard = observer(({ integration }: IIntegrationListCardProps) => {
  const integrationData = getIntegrationDetailsDataByKey(integration.key)

  const onCardClick = () => {
    uiStore.changeRoute({
      path: `/${SettingsRoutes.root}/${SettingsRoutes.integrations}/${integration.key}`,
    })
  }

  return (
    <div className={styles.card} onClick={onCardClick}>
      <div className={styles.cardHeader}>
        <Icon icon={integration.icon} fontSize={26} />
        <div className={styles.cardHeaderRight}>
          <IntegrationStatus integration={integration} />
          {integration.isConnected && <IntegrationListCardActions integration={integration} />}
        </div>
      </div>
      <div className={styles.data}>
        <Typography variant='body-md-medium' ariaLabel={'Integration_name'}>
          {integration.name}
        </Typography>
        <Typography
          variant='body-md-regular'
          ariaLabel={'Integration_subheadline'}
          color='tertiary'
          className={styles.subheadline}
        >
          {integrationData?.subheadline}
        </Typography>
      </div>
    </div>
  )
})
