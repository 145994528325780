import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { FeatureRestrictedModalContent } from 'widgets/FeatureRestrictedModal/ui/FeatureRestrictedModalContent'

export class FeatureRestrictedModalStore {
  constructor(onCloseCallback?: () => void) {
    makeAutoObservable(this)
    this.openModal(onCloseCallback)
  }

  _idModal = ''
  onCloseCallback: (() => void) | null = null

  closeModal = () => {
    modalStore.removeModal(this._idModal)
  }

  openModal = (onCloseCallback?: () => void) => {
    this._idModal = nanoid()
    this.onCloseCallback = onCloseCallback || null

    modalStore.addModal({
      id: this._idModal,
      showCloseIcon: false,
      showHeader: false,
      ModalContent: FeatureRestrictedModalContent,
      closeText: 'Close',
      onClose: () => {
        this.closeModal()
        this.onCloseCallback?.()
      },
    })
  }
}
