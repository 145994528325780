import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { EmptyState, type IColumn, type IRow, layoutStore, Table } from 'shared/ui'
import { Pagination } from 'shared/ui/Table'
import { getCellForNameDefault } from 'shared/helpers/getCellForNameDefault'
import type { Contact } from 'entities/Contacts/model/Contact'
import type { ContactIntegration } from 'entities/Integrations/model/ContactIntegration'
import {
  getCellForTags,
  getCellNumber,
} from 'widgets/ContactEnrollment/ui/EnrollmentContactsTable/helpers/helpers'
import type { EnrollmentListStore } from 'widgets/ContactEnrollment/store'
import { EnrollmentActions } from '../../EnrollmentActions'
import styles from './styles.module.scss'

type IEnrollmentContactsTableProps = {
  enrollmentListStore: EnrollmentListStore
}

export const EnrollmentContactsTable: FC<IEnrollmentContactsTableProps> = observer(
  ({ enrollmentListStore }) => {
    const { isViewXXsGlobal } = layoutStore
    const { contactsTableStore: store } = enrollmentListStore

    useEffect(() => {
      if (store.total) {
        store.setFilter(enrollmentListStore.filters)
        store.loadContacts()
      }

      return () => {
        store.reset()
      }
    }, [])

    const rows = (): IRow<Contact | ContactIntegration>[] =>
      store.items.map((item) => {
        item.ariaLabel = 'ContactItemRow'

        return item
      })

    const columns: IColumn<Contact | ContactIntegration>[] = [
      {
        field: 'name',
        name: 'Name',
        width: '100%',
        renderRowCell: (row) =>
          getCellForNameDefault({
            fullName: row.fullName,
            avatarInfo: row.avatarInfo,
            width: isViewXXsGlobal ? 180 : undefined,
          }),
      },
      {
        field: 'number',
        name: 'Number',
        renderRowCell: (row) => getCellNumber(row as Contact),
      },
      {
        field: 'email',
        name: 'Email',
      },
      {
        field: 'tag',
        name: 'Tags',
        renderRowCell: (row) => getCellForTags(row as Contact),
      },
    ]

    return (
      <div className={styles.contentContainer}>
        <div className={styles.mainContainer}>
          <section className={styles.previewSection}>
            {!!store.total && (
              <Table
                rows={rows()}
                rowsCount={store.total}
                columns={columns}
                isLoading={store.loading}
                pinnedColumnNames={['name']}
                sortOrder={store.sortOrder}
                sortBy={store.sortBy}
                initPage={store.page}
                noPagination
              />
            )}

            {!store.total && (
              <EmptyState
                iconProps={{ icon: 'contacts', color: 'var(--content-neutral-primary)' }}
                text={'No matching contacts'}
              />
            )}
          </section>

          <footer className={styles.footerActions}>
            {!!store.total && (
              <Pagination
                initPage={store.page}
                rowsCount={store.total}
                limit={store.length}
                onChange={store.onChangePagination}
              />
            )}
            <EnrollmentActions />
          </footer>
        </div>
      </div>
    )
  }
)
