import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, EmptyState } from 'shared/ui'
import { Segment } from 'entities/Segment/model/Segment'
import { SegmentBox } from 'entities/Segment'
import { FilterEditorAdd, type FilterEditorStore } from 'widgets/FilterEditor'
import { FilterEditorContent } from './FilterEditor'

export interface IContactEnrollmentFiltersProps {
  filterEditorStore: FilterEditorStore
  segmentBox: SegmentBox
  onCancel: () => void
  onApply: (segment: Segment) => void
  isViewOnly?: boolean
}

export const EnrollmentFilter: FC<IContactEnrollmentFiltersProps> = observer(
  ({ onApply, onCancel, isViewOnly, filterEditorStore, segmentBox }) => {
    if (filterEditorStore.groups.count) {
      return (
        <FilterEditorContent
          filterEditorStore={filterEditorStore}
          segmentBox={segmentBox}
          onApply={onApply}
          onCancel={onCancel}
          isViewOnly={isViewOnly}
        />
      )
    }

    return (
      <>
        {!isViewOnly && (
          <>
            <FilterEditorAdd
              store={filterEditorStore}
              triggerComponent={() => {
                return (
                  <Button
                    icon={'add'}
                    contained={'secondary'}
                    size={'medium'}
                    text={'New filter'}
                  />
                )
              }}
            />
          </>
        )}

        <EmptyState
          iconProps={{ icon: 'filter', color: 'var(--content-neutral-primary)' }}
          text={'The preview will show up here'}
        />
      </>
    )
  }
)
