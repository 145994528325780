import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { AxiosError, CancelTokenSource } from 'axios'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { ComplianceApi, complianceStore, IOptInMethodTypes, IPlaneTypes } from 'entities/Compliance'
import { BillingApi } from 'entities/Billing'
import { openIntercom } from 'entities/Intercom'
import { DropdownCardsStore } from 'widgets/DropdownCards'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers/route/type'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import { UseCaseStore } from 'pages/settings/pages/compliance/pages/localNumbers/pages/CampaignUseCase/store/UseCaseStore'
import { MessagesStore } from 'pages/settings/pages/compliance/pages/localNumbers/pages/Messages/store/MessagesStore'
import { OptInMethodStore } from 'pages/settings/pages/compliance/pages/localNumbers/pages/OptInMethod/store/OptInMethodStore'
import { OptInContentStore } from 'pages/settings/pages/compliance/pages/localNumbers/pages/OptInContent/store/OptInContentStore'
import { ForbiddenMessageStore } from 'pages/settings/pages/compliance/pages/localNumbers/pages/ForbiddenMessageTopics/store/ForbiddenMessageStore'
import { PackageStore } from 'pages/settings/pages/compliance/pages/localNumbers/pages/Package/store/PackageStore'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { LocalNumbersNavigationStore } from 'pages/settings/pages/compliance/pages/localNumbers/store/localNumbersNavigationStore'

const titleMap: Record<string, string> = {
  [LocalNumbersRoutes.campaignUseCases]: 'Campaign use case',
  [LocalNumbersRoutes.messages]: 'Messages',
  [LocalNumbersRoutes.optInMethod]: 'Opt-in method',
  [LocalNumbersRoutes.optInOnlineFormContent]: 'Opt-in consent: Online Form',
  [LocalNumbersRoutes.optInPaperFormContent]: 'Opt-in consent: Paper Form',
  [LocalNumbersRoutes.optInViaTextContent]: 'Opt-in consent: via Text',
  [LocalNumbersRoutes.optInQrCodeContent]: 'Opt-in consent: QR Code',
  [LocalNumbersRoutes.forbiddenMessageTopics]: 'Forbidden message topics',
  [LocalNumbersRoutes.package]: 'Package',
  [LocalNumbersRoutes.orderSummary]: 'Order summary',
}

const startUrls = [
  LocalNumbersRoutes.campaignUseCases,
  LocalNumbersRoutes.messages,
  LocalNumbersRoutes.optInMethod,
]

export class LocalNumbersStepsStore {
  private _localNumbersNavigationStore: LocalNumbersNavigationStore
  private _useCaseStore: UseCaseStore
  private _messagesStore: MessagesStore
  private _optInMethodStore: OptInMethodStore
  private _optInConsentStore: OptInContentStore
  private _packageStore: PackageStore
  private _forbiddenMessageStore: ForbiddenMessageStore
  private _dropdownCardsStore: DropdownCardsStore

  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    makeAutoObservable(this)

    this._localNumbersNavigationStore = new LocalNumbersNavigationStore(this._complianceLayerStore)
    this._useCaseStore = new UseCaseStore(this)
    this._messagesStore = new MessagesStore(this)
    this._optInMethodStore = new OptInMethodStore(this)
    this._optInConsentStore = new OptInContentStore(this)
    this._packageStore = new PackageStore(this)
    this._forbiddenMessageStore = new ForbiddenMessageStore(this)
    this._dropdownCardsStore = new DropdownCardsStore()

    this.reactionInitCampaignData()
    this.initStores()
  }

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  get packageStore() {
    return this._packageStore
  }

  get dropdownCardsStore() {
    return this._dropdownCardsStore
  }

  get optInConsentStore() {
    return this._optInConsentStore
  }

  get messagesStore() {
    return this._messagesStore
  }

  get forbiddenMessageStore() {
    return this._forbiddenMessageStore
  }

  get optInMethodStore() {
    return this._optInMethodStore
  }

  get useCaseStore() {
    return this._useCaseStore
  }

  get localNumbersNavigationStore() {
    return this._localNumbersNavigationStore
  }

  get complianceLayerStore() {
    return this._complianceLayerStore
  }

  get isPrivateInternalRejected() {
    const { complianceServiceBrand, complianceServiceCampaign } = this._complianceLayerStore
    const internalRejected =
      complianceServiceBrand?.isInternalRejected || complianceServiceCampaign?.isInternalRejected

    return complianceServiceBrand?.isPrivateType && internalRejected
  }

  get isPrivateExternalRejected() {
    const { complianceServiceBrand, complianceServiceCampaign } = this._complianceLayerStore
    const isExternalRejected =
      complianceServiceBrand?.isExternalRejected || complianceServiceCampaign?.isExternalRejected

    return complianceServiceBrand?.isPrivateType && isExternalRejected
  }

  get isPublicInternalRejected() {
    const { complianceServiceBrand, complianceServiceCampaign } = this._complianceLayerStore
    const isInternalRejected =
      complianceServiceBrand?.isInternalRejected || complianceServiceCampaign?.isInternalRejected

    return complianceServiceBrand?.isPublicType && isInternalRejected
  }

  get noShowPackageStep() {
    return this.isPrivateInternalRejected || this.isPrivateExternalRejected
  }

  get noShowOrderSummaryStep() {
    return this.isPrivateInternalRejected || this.isPublicInternalRejected
  }

  get stepsStore() {
    return this._complianceLayerStore.stepsStore
  }

  get internalRejectedOnly() {
    const { complianceServiceCampaign, complianceServiceBrand } = this._complianceLayerStore

    const bothInternal =
      complianceServiceBrand?.isInternalRejected && complianceServiceCampaign?.isInternalRejected
    const innerBrandRejectedOnly =
      complianceServiceBrand?.isInternalRejected && !complianceServiceCampaign?.registrationError
    const innerComplianceRejectedOnly =
      complianceServiceCampaign?.isInternalRejected && !complianceServiceBrand?.registrationError

    if (bothInternal || innerBrandRejectedOnly || innerComplianceRejectedOnly) return true

    return false
  }

  get isSubmitDisabled() {
    return !this._dropdownCardsStore.activeId || !!this._dropdownCardsStore.paymentError
  }

  get stepLoading() {
    return this.complianceLayerStore.stepsStore.stepLoading
  }

  get allPaths() {
    const optInPaths: LocalNumbersRoutes[] = this._optInMethodStore.optInMethods
      .map((method) => {
        switch (method) {
          case IOptInMethodTypes.onlineForm:
            return LocalNumbersRoutes.optInOnlineFormContent
          case IOptInMethodTypes.paperForm:
            return LocalNumbersRoutes.optInPaperFormContent
          case IOptInMethodTypes.text:
            return LocalNumbersRoutes.optInViaTextContent
          case IOptInMethodTypes.qrCode:
            return LocalNumbersRoutes.optInQrCodeContent
          default:
            return null
        }
      })
      .filter(Boolean) as LocalNumbersRoutes[]

    const partOfPaths = [...startUrls, ...optInPaths, LocalNumbersRoutes.forbiddenMessageTopics]

    if (!this.noShowPackageStep) {
      partOfPaths.push(LocalNumbersRoutes.package)
    }
    if (!this.noShowOrderSummaryStep) {
      partOfPaths.push(LocalNumbersRoutes.orderSummary)
    }

    return partOfPaths
  }

  get allStepsLength() {
    // We have 6 steps + opt in. In case optInMethodStore.optInMethods.length > 0, opt in is included to the count
    return this._optInMethodStore.optInMethods.length
      ? this.allPaths.length
      : this.allPaths.length + 1
  }

  get campaignId() {
    return this._complianceLayerStore.complianceServiceCampaign?.id
  }

  get paymentError() {
    return this._dropdownCardsStore.paymentError
  }

  get priceId() {
    const { complianceServiceBrand, complianceServiceCampaign } = this.complianceLayerStore
    if (complianceServiceCampaign?.isStatusFailed && !complianceServiceBrand?.isStatusFailed) {
      return 'dlc_use_case_registration_vetting_new'
    }

    return this._packageStore.activePlaneType === IPlaneTypes.STANDARD
      ? 'a2p_brand_registration_standard_vetting_new'
      : 'a2p_brand_registration_lvs_vetting_new'
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  setStepLoading = (value: boolean) => {
    this.complianceLayerStore.stepsStore.setStepLoading(value)
  }

  getProgressInfoTitle = (pathname: string) => {
    return titleMap[pathname] || ''
  }

  decreaseStep = (path: LocalNumbersRoutes) => {
    const lastStep = this.getLastStep(path)

    if (path === LocalNumbersRoutes.orderSummary) {
      this._dropdownCardsStore.clearPaymentError()
    }
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.localNumbers.root}/${lastStep}`,
    })
  }

  getStep = (path: LocalNumbersRoutes, direction: -1 | 1): LocalNumbersRoutes | '' => {
    const allPaths = this.allPaths
    const currentIndex = allPaths.indexOf(path)
    const nextIndex = currentIndex + direction

    if (currentIndex !== -1 && nextIndex >= 0 && nextIndex < allPaths.length) {
      return allPaths[nextIndex]
    }

    return path
  }

  getLastStep = (path: LocalNumbersRoutes): LocalNumbersRoutes | '' => {
    return this.getStep(path, -1)
  }

  getNextStep = (path: LocalNumbersRoutes): LocalNumbersRoutes | '' => {
    return this.getStep(path, 1)
  }

  getSettingsStep = (path: LocalNumbersRoutes) => {
    const allPaths = this.allPaths

    return allPaths.indexOf(path) + 1
  }

  closeSetting = () => {
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.localNumbers.root}`,
    })
  }

  learnMoreButtonAction = () => {
    openIntercom()
  }

  onSubmit = async () => {
    if (
      !this._packageStore.activePlaneType ||
      !this._dropdownCardsStore.activeId ||
      !this.campaignId
    )
      return

    try {
      this.stepsStore.setStepLoading(true)

      const { data } = await BillingApi.billingDepositReplenish({
        payment_method: this._dropdownCardsStore.activeId,
        price_id: this.priceId,
        type: 'price',
      })
      if (data) {
        const response = await ComplianceApi.submitLocalNumberApplication({
          ten_dlc_plan: this._packageStore.activePlaneType,
          ten_dlc_registration_submitted: true,
        })
        const { data } = await ComplianceApi.updateComplianceServicesCampaign({
          step: 7,
          campaignId: this.campaignId,
        })

        this.complianceLayerStore.set10DLCServiceCampaign(data)

        complianceStore.fetchComplianceServicesList()
        response &&
          uiStore.changeRoute({
            path: `/${SettingsPath.compliance.localNumbers.root}`,
          })
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        const errors = e.response?.data?.errors

        if (errors?.payment_method) {
          this._dropdownCardsStore.setPaymentError('Please select a different card')
        }
      }
      logger.error(e)
    } finally {
      this.stepsStore.setStepLoading(false)
    }
  }

  initStores = () => {
    const { complianceServiceCampaign, complianceServiceBrand } = this._complianceLayerStore

    if (complianceServiceCampaign?.data) {
      this._useCaseStore.init(complianceServiceCampaign.data.useCaseData)
      this._messagesStore.init(complianceServiceCampaign.data.sampleMessagesData)
      this._optInMethodStore.init(complianceServiceCampaign.data.optInData)
      this._optInConsentStore.init(complianceServiceCampaign.data.optInData)
    }

    if (complianceServiceBrand) {
      this._packageStore.init(complianceServiceBrand)
    }
  }

  reactionInitCampaignData = () => {
    this._disposePageReaction?.()
    this._disposePageReaction = reaction(
      () => this._complianceLayerStore.complianceServiceCampaign,
      (complianceServiceCampaign) => {
        if (complianceServiceCampaign) {
          this.initStores()
        }
      }
    )
  }
}
