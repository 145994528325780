import { makeAutoObservable, runInAction, type IReactionDisposer, reaction } from 'mobx'
import { debounce, isEqual } from 'lodash'
import { uiStore } from 'shared/store/uiStore'
import { layoutStore } from 'shared/layout'
import { logger } from 'shared/lib'
import { conversationStore } from 'entities/Conversation'
import { type IParamsDeleteMessagesDraft, MessageData, MessagesApi } from 'entities/Message'
import { recentStore } from 'widgets/RecentlySent'
import { EnumMessageFieldMode } from 'widgets/MessageField'
import { type ConversationMessageFieldStore } from 'widgets/ConversationMessages/store/conversationMessageFieldStore'

export class ConversationMessageFieldDraftStore {
  private _disposeConversationDelete: IReactionDisposer | null = null
  private _disposeConversationCreate: IReactionDisposer | null = null
  private _disposeConversationUpdate: IReactionDisposer | null = null
  private _disposeModeNote: IReactionDisposer | null = null

  private _debounceOnUpdateDraft?: ReturnType<typeof debounce>
  private _debounceOnDeleteDraft?: ReturnType<typeof debounce>
  private _isSetDraft = false

  constructor(private _conversationMessageFieldStore: ConversationMessageFieldStore) {
    makeAutoObservable(this)

    this._debounceOnUpdateDraft = debounce(this._onSetDraft, 100)
    this._debounceOnDeleteDraft = debounce(this._onDeleteDraft, 100)
  }

  get conversationMessagesStore() {
    return this._conversationMessageFieldStore.conversationMessagesStore
  }

  get conversationId() {
    return this.conversationMessagesStore.conversationId
  }

  get conversation() {
    return this.conversationMessagesStore.conversation
  }

  get loadingConversation() {
    return this.conversationMessagesStore.loadingConversation
  }

  get messageFieldStore() {
    return this.conversationMessagesStore.messageFieldStore
  }

  get isScheduleMessage() {
    return this.conversationMessagesStore.isScheduleMessage
  }

  get isModeNoteObj() {
    return this.conversationMessagesStore.isModeNoteObj
  }

  get stateDraft() {
    const draft = conversationStore.getItemDraft(this.conversationId)
    const draftMessage = draft?.message
    const formDraftMessage = this.messageFieldStore.messageDraftData
    const isDifferenceDataMessage = !isEqual(draftMessage, formDraftMessage)
    const isLocalDraft = !document.hidden

    if (this.messageFieldStore.mode === 'note') return false
    if (this.loadingConversation) return false
    if (isLocalDraft && this.messageFieldStore.isEmptyDraft && !this._isSetDraft && draft)
      return 'delete'
    if (isLocalDraft && !this.messageFieldStore.isEmptyDraft && isDifferenceDataMessage)
      return 'create'
    if (!isLocalDraft && draft) return 'update'

    return 'pending'
  }

  initReactions = () => {
    this.reactionConversationDraftMessage()
  }

  disposeReactions = () => {
    this._disposeConversationDelete?.()
    this._disposeConversationCreate?.()
    this._disposeConversationUpdate?.()
    this._disposeModeNote?.()
  }

  private _onSetDraft = async () => {
    const draft_message = conversationStore.getItemDraft(this.conversationId)

    if (draft_message) {
      logger.info('UPDATE [Draft]')

      runInAction(() => {
        this._isSetDraft = true
      })

      if (!recentStore.recentAttachments.length) recentStore.checkParamsAndLoadRecent()

      const attachments = draft_message.attachments

      const messageData: MessageData = {
        message: draft_message.body,
        attachments: attachments,
        isReset: true,
        isFocus: !uiStore.isFocusingMode && !layoutStore.isMobileView,
        isReplace: true,
      }

      this.messageFieldStore.setMessageData(messageData)

      runInAction(() => {
        this._isSetDraft = false
      })
    }
  }

  private _onDeleteDraft = () => {
    const draft = conversationStore.getItemDraft(this.conversationId)

    if (draft) {
      logger.info('DELETE [Draft]')

      const params: IParamsDeleteMessagesDraft = {
        conversation_id: draft.conversation_id,
        inbox_id: draft.inbox_id,
        inbox_type: draft.inbox_type,
      }

      conversationStore.deleteItemDraft(draft.conversation_id)
      MessagesApi.deleteMessagesDraft(params)
    }
  }

  handleUpdateDraft = () => {
    this._debounceOnUpdateDraft?.()
  }

  handleSetDraft = () => {
    this._onSetDraft()
  }

  handleDeleteDraft = () => {
    this.conversation?.debounceOnCreateDraft?.cancel()

    this._debounceOnDeleteDraft?.()
  }

  handleResetDraft = () => {
    this.conversation?.debounceOnCreateDraft?.cancel()
    this._debounceOnUpdateDraft?.cancel()
    this._debounceOnDeleteDraft?.cancel()

    this._onDeleteDraft()
  }

  handleCreateDraft = () => {
    if (!this.conversation?.id) return

    if (this.messageFieldStore.isEmptyDraft || this.isScheduleMessage) {
      this.conversation?.debounceOnCreateDraft?.cancel()

      return
    }

    this.conversation?.debounceOnCreateDraft?.(this.messageFieldStore.messageDraftData)
  }

  reactionConversationDraftMessage = () => {
    this._disposeConversationDelete?.()
    this._disposeConversationCreate?.()
    this._disposeConversationUpdate?.()
    this._disposeModeNote?.()

    this._disposeConversationDelete = reaction(
      () => this.messageFieldStore.isEmptyDraft,
      () => {
        if (this.stateDraft === 'delete') {
          this.handleDeleteDraft()
        }
      }
    )

    this._disposeConversationCreate = reaction(
      () => this.messageFieldStore.messageDraftData,
      () => {
        if (this.stateDraft === 'create') {
          this.handleCreateDraft()
        }
      }
    )

    this._disposeConversationUpdate = reaction(
      () => conversationStore.getItemDraft(this.conversationId),
      async () => {
        if (this.stateDraft === 'update') {
          this.handleUpdateDraft()
        }
      }
    )

    this._disposeModeNote = reaction(
      () => this.messageFieldStore.mode,
      async (value) => {
        this.isModeNoteObj.value = value === EnumMessageFieldMode.Note
        if (value === 'note') {
          this.handleResetDraft()
        }
      }
    )
  }
}
