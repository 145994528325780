import { Divider } from '@mui/material'
import { KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Alert, Button, Error } from 'shared/ui'
import { Warning } from 'shared/ui/Warning'
import { InputPassword } from 'shared/ui/TextField/InputPresets/InputPassword'
import { IInputComponent, makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'shared/constants/auth'
import { authStore } from 'entities/Auth'
import { type LoginStore } from 'widgets/Login'

import styles from './styles.module.scss'

type IFormProps = {
  store: LoginStore
}

export const LoginForm = observer(({ store }: IFormProps) => {
  const {
    setEmail,
    email,
    password,
    setPassword,
    loading,
    emailError,
    passwordError,
    error,
    triggerEmailValidation,
    triggerPasswordValidation,
    isMaxPassword,
    signIn,
    hasAlertErrors,
    alertErrors,
  } = store

  const { googleAuthStore } = authStore

  const handleSignIn = async () => signIn(store)

  const onKeyDownEmail = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerEmailValidation()
    }
  }

  const onKeyDownPassword = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerPasswordValidation()
    }
  }

  return (
    <div className={styles.wrap}>
      {error && (
        <Alert
          className={styles.error}
          item={{
            type: 'error',
            desc: error,
          }}
        />
      )}

      <Button
        className={classNames(styles.button, styles.signIn)}
        loading={googleAuthStore.loading}
        disabled={googleAuthStore.loading || loading}
        typeBtn='outlined'
        type='button'
        icon={'google'}
        text={'Sign in with Google'}
        contained={'secondary'}
        onClick={googleAuthStore.onLogin}
        fullWidth
      />

      <div className={styles.divider}>
        <Divider>OR</Divider>
      </div>

      {hasAlertErrors && (
        <div className={styles.alertWrap}>
          {alertErrors.map((error) => (
            <Alert
              key={error}
              item={{
                type: 'alert',
                desc: error,
              }}
            />
          ))}
        </div>
      )}

      <Label
        label={{
          text: 'Email address',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, { [styles.error]: !!emailError })}
        variant='stroke'
        disabled={googleAuthStore.loading || loading}
        size='medium'
        timedError={!!emailError}
        InputProps={{ placeholder: 'name@company.com', onKeyDown: onKeyDownEmail }}
        onChange={setEmail}
        value={email}
        mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
      />
      <Error type={'text'} error={emailError} />

      <Label
        label={{
          text: 'Password',
          className: styles.inputLabel,
        }}
      />
      <TextField
        Input={InputPassword as IInputComponent}
        className={classNames(styles.textField, {
          [styles.error]: !!passwordError,
        })}
        variant='stroke'
        timedError={!!passwordError}
        maxLength={MAX_PASSWORD_LENGTH}
        InputProps={{
          placeholder: `${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters`,
          onKeyDown: onKeyDownPassword,
        }}
        disabled={googleAuthStore.loading || loading}
        size='medium'
        onChange={setPassword}
        value={password}
      />
      <Error type={'text'} error={passwordError} />
      <Warning
        text={isMaxPassword ? `Maximum password length is ${MAX_PASSWORD_LENGTH} characters` : ''}
      />

      <Button
        className={classNames(styles.button, styles.signIn)}
        typeBtn='contained'
        type='button'
        onClick={handleSignIn}
        loading={loading}
        disabled={googleAuthStore.loading || loading}
        text={'Sign in'}
        contained={'primary'}
        fullWidth
      />
    </div>
  )
})

export default LoginForm
