import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { ContactsDetails } from 'widgets/ContactsDetails'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { useContactsPageContext } from 'pages/contacts/context/contactsPageContext'
import { ContactsContent } from './ContactsContent/ContactsContent'
import styles from './styles.module.scss'

export const ContactsPage = observer(() => {
  const contactsPageStore = useContactsPageContext()
  const { contactsTableStore, contactsDetailsStore, contactsFilterStore, importContactsStore } =
    contactsPageStore
  const { tableStore } = contactsTableStore
  const { isImportAllowed, getStatusImportContact } = importContactsStore

  useEffect(() => {
    const disposeContactsFilterStore = contactsFilterStore.watchChanges()
    contactsTableStore.initReactions()
    contactsFilterStore.initPreselectFilters()

    if (!contactsFilterStore.segmentBox.segment) {
      contactsTableStore.loadContacts()
    }

    contactsDetailsGlobalStore.handleSetVariant('contacts')
    contactsDetailsGlobalStore.handleChangeCollapse(true)
    if (userPermissionsStore.getItem('can_create_contacts')) {
      !isImportAllowed && getStatusImportContact()
      importContactsStore.contactsImportModuleStore.loadImportHistory()
    }

    contactsPageStore.init()

    return () => {
      disposeContactsFilterStore()
      contactsTableStore.dispose()
      contactsTableStore.resetNewContactMode()
      tableStore.resetSelected()
      contactsFilterStore.clearSegment()
      contactsTableStore.resetSorting()
      contactsPageStore.reset()
    }
  }, [])

  return (
    <div className={styles.container}>
      <ContactsContent />
      {contactsDetailsStore ? <ContactsDetails store={contactsDetailsStore} /> : null}
    </div>
  )
})
