import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import type { IResponsePowerDialer } from 'entities/PowerDialer/api/types'

type IHandlerEvent = (data: IResponsePowerDialer) => void

export const EventPowerDialerCreated = {
  subscribe: (handler: IHandlerEvent) => websocket.on(WebsocketEvent.PdCampaignsCreated, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.PdCampaignsCreated, handler),
}

export const useEventPowerDialerCreated = (handler: IHandlerEvent) =>
  useEffect(() => {
    EventPowerDialerCreated.subscribe(handler)

    return () => {
      EventPowerDialerCreated.unsubscribe(handler)
    }
  }, [])
