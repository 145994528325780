import { makeAutoObservable, runInAction } from 'mobx'
import { ContactsApi } from 'entities/Contacts'

export class CleanupAlertStore {
  private _loading = true
  private _duplicate_count = 0
  private _duplicate_total = 0
  private _unlinked_count = 0
  private _unlinked_total = 0

  constructor() {
    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  get hasItems() {
    return this._duplicate_total || this._unlinked_total
  }

  get isExistOnlyUnlinked() {
    return !this._duplicate_total && !this._unlinked_total
  }

  get title() {
    const getPlural = (value: number) => (value > 1 ? 'contacts' : 'contact')
    const duplicatesLength = this._duplicate_count
    const unlinkedLength = this._unlinked_count
    const noDuplicates = !this._duplicate_total
    const noLinked = !this._unlinked_total

    if (!noDuplicates && noLinked) {
      return `We found ${duplicatesLength} duplicate ${getPlural(duplicatesLength)}`
    }
    if (noDuplicates && !noLinked) {
      return `We found ${unlinkedLength} unlinked ${getPlural(unlinkedLength)}`
    }

    return `We found  ${duplicatesLength} duplicate and ${unlinkedLength} unlinked contacts`
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([
      this.fetchDuplicateCount(),
      this.fetchContactsUnlinked(),
      this.fetchContactsDuplicate(),
    ])

    runInAction(() => {
      this._loading = false
    })
  }

  fetchDuplicateCount = async () => {
    try {
      const { data } = await ContactsApi.getContactsDuplicateCount()

      this._duplicate_count = data.duplicate_count
      this._unlinked_count = data.unlinked_count
    } catch (e) {
      console.log(e)
    }
  }

  fetchContactsUnlinked = async () => {
    try {
      const { data } = await ContactsApi.getContactsUnlinked({
        page: 1,
        length: 10,
      })

      this._unlinked_total = data.total
    } catch (e) {
      console.log(e)
    }
  }

  fetchContactsDuplicate = async () => {
    try {
      const { data } = await ContactsApi.getContactsDuplicate({
        page: 1,
        length: 10,
      })

      this._duplicate_total = data.total
    } catch (e) {
      console.log(e)
    }
  }
}
