import { observer } from 'mobx-react-lite'
import { Icon, Tooltip, Typography } from 'shared/ui'
import { formatKilobytes } from 'shared/lib'
import { attachmentStore, IUploadInfoKeys } from 'entities/Attachment'
import { inboxesStore } from 'entities/Inbox'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { airCallTooltip } from 'widgets/constants'
import styles from './styles.module.scss'

type IItem = {
  withoutColon?: boolean
  label: string
  key: IUploadInfoKeys | 'mms'
  desc: (size: string) => string
}

const data: IItem[] = [
  {
    label: 'Images',
    key: 'image',
    desc: (size) => `up to ${size} (jpg, png, gif)`,
  },
  {
    label: 'Video',
    key: 'video',
    desc: (size) => `up to ${size} (mp4, mov)`,
  },
  {
    label: 'Audio',
    key: 'audio',
    desc: (size) => `up to ${size} (mp3)`,
  },
  {
    label: 'Files',
    key: 'default',
    desc: (size) => `up to ${size} (pdf, doc, csv, or xls)`,
  },
  {
    label: 'vCard',
    key: 'vcard',
    desc: (size) => `up to ${size}`,
  },
]

const aircallData: IItem[] = [
  {
    label: airCallTooltip,
    withoutColon: true,
    key: 'mms',
    desc: () => '',
  },
  {
    label: 'Video',
    key: 'video',
    desc: (size) => `up to ${size} (mp4, mov)`,
  },
  {
    label: 'Files',
    key: 'default',
    desc: (size) => `up to ${size} (pdf, doc, csv, or xls)`,
  },
]

type ISizeLimitsTooltipProps = {
  isCentered?: boolean
  hideURLUpload?: boolean
}

export const SizeLimitsTooltip = observer(
  ({ isCentered, hideURLUpload }: ISizeLimitsTooltipProps) => {
    const isAircall =
      inboxesStore.currentInbox &&
      inboxesStore.currentInbox.type === 'inbox' &&
      inboxesStore.currentInbox.isAircall

    const actualData = isAircall && !featureFlagsStore.aircall_new_api ? aircallData : data
    const slotOffset = {
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [74, 0],
            },
          },
        ],
      },
    }

    return (
      <Tooltip
        label={
          <div className={styles.wrap}>
            {actualData.map((item) => (
              <div key={item.label}>
                <Typography
                  variant={'body-sm-regular'}
                  className={styles.label}
                  ariaLabel={'label'}
                >
                  {`${item.label}`}
                  {`${item.withoutColon ? ' ' : ': '}`}
                </Typography>
                <Typography variant={'body-sm-regular'} className={styles.desc} ariaLabel={'desc'}>
                  {item.key !== 'mms' &&
                    item.desc(formatKilobytes(attachmentStore.getSizeLimit(item.key)))}
                </Typography>
              </div>
            ))}
            {(isAircall && !featureFlagsStore.aircall_new_api) || hideURLUpload ? undefined : (
              <div className={styles.urlUpload}>
                <Typography
                  variant={'body-sm-regular'}
                  className={styles.label}
                  ariaLabel={'label'}
                >
                  URL upload:
                </Typography>
                <Typography variant={'body-sm-regular'} className={styles.desc} ariaLabel={'desc'}>
                  {' (jpg, png, gif, vcf)'}
                </Typography>
              </div>
            )}
          </div>
        }
        placement={'top'}
        width={280}
        slotProps={!isCentered ? slotOffset : undefined}
      >
        <div className={styles.info}>
          <Icon icon={'question'} />
        </div>
      </Tooltip>
    )
  }
)
