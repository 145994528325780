import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { FilterDropdown } from 'shared/ui'
import { getIcon } from 'widgets/constants'
import { getImportContactDropdownGroups } from 'pages/contacts/ui/ContactsTable/helpers/helpers'
import { useImportContactsContext } from 'pages/contacts/ui/ImportContacts/context/ImportContactsContext'
import { ImportContactDropdownActions } from './ImportContactDropdownActions/ImportContactDropdownActions'
import { DropdownTriggerComponent } from './DropdownTriggerComponent/DropdownTriggerComponent'

type IImportContactDropdownProps = {
  header: string
}

export const ImportContactDropdown: FC<IImportContactDropdownProps> = observer(({ header }) => {
  const { mappedFields, doNotMapNameList, fields, toggleFilter, getFieldDataByKey } =
    useImportContactsContext()

  if (!fields) {
    return null
  }

  const filterKey = mappedFields[header]
  const filter = filterKey ? getFieldDataByKey(filterKey) : null
  const filterName = filter?.name || ''
  const mappedKeys = Object.values(mappedFields)
  const filterDropdownGroups = getImportContactDropdownGroups(fields, mappedKeys)
  const isDoNotMap = doNotMapNameList.includes(header)
  const dropdownHeader = isDoNotMap ? 'Don’t import' : filterName
  const triggerComponentIcon = isDoNotMap || !filter ? 'close' : getIcon(filter)

  return (
    <FilterDropdown
      width={264}
      margin={-34}
      filterDropdownGroups={filterDropdownGroups}
      closeAfterChange
      triggerComponent={() => (
        <DropdownTriggerComponent icon={triggerComponentIcon} text={dropdownHeader} />
      )}
      additionActions={<ImportContactDropdownActions header={header} />}
      changeActiveFields={({ id }) => {
        toggleFilter({ key: String(id), header })
      }}
    />
  )
})
