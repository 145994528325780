import { makeAutoObservable } from 'mobx'
import type { ICampaignExecution } from 'entities/Campaign'
import { CampaignContact } from './CampaignContact'

export class CampaignExecution {
  contact
  constructor(public origin: ICampaignExecution) {
    this.contact = new CampaignContact(origin.contact)
    makeAutoObservable(this)
  }

  get id() {
    return this.origin.id
  }
  get steps_count() {
    return this.origin.steps_count
  }
  get contact_has_replied() {
    return this.origin.contact_has_replied
  }
}
