import { nanoid } from 'nanoid'
import { ActionItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { Campaign } from 'entities/Campaign'
import { CampaignRoutes } from 'pages/campaigns/types'

export const editCampaign = (campaign: Campaign): ActionItem => {
  const toEdit = () => {
    uiStore.changeRoute({
      path: `/${CampaignRoutes.root}/${CampaignRoutes.edit}/${campaign.id}`,
    })
  }

  const onAction = () => {
    if (campaign.hasStatistics) {
      const id = nanoid()
      modalStore.addModal({
        id,
        type: ModalTypeList.WARNING,
        title: 'Potential Statistics Gaps',
        desc: 'Editing this campaign may cause inconsistencies in statistics',
        primaryAction: {
          text: 'Edit anyway',
          onAction: () => {
            toEdit()
            modalStore.closeModal(id)
          },
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: () => {
            modalStore.closeModal(id)
          },
        },
      })
    } else {
      toEdit()
    }
  }

  return {
    icon: 'edit',
    buttonProps:
      campaign.status === 'active'
        ? {
            disabled: true,
            tooltipProps: {
              fullWidth: true,
              placement: 'top',
              label: ['You can not edit', <br key={'br'} />, 'active campaign'],
            },
          }
        : undefined,
    onAction,
    text: 'Edit',
  }
}
