import { makeAutoObservable } from 'mobx'
import { type IResponsePointOfContact } from 'entities/Compliance'
import { PointsOfContactItem } from './PointsOfContactItem'

export class PointsOfContact {
  private _itemsMap: Map<number, PointsOfContactItem> = new Map()

  constructor(response: IResponsePointOfContact[]) {
    makeAutoObservable(this)

    this.syncData(response)
  }

  syncData = (response: IResponsePointOfContact[]) => {
    response.forEach((item, index) => {
      this.addItem(item, index)
    })
  }

  addItem = (response: IResponsePointOfContact, index: number) => {
    this._itemsMap.set(index, new PointsOfContactItem(response))
  }

  get payload() {
    return this.items.map((item) => item.origin)
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  get firstContact() {
    return this.items[0] as PointsOfContactItem | undefined
  }

  get secondContact() {
    return this.items[1] as PointsOfContactItem | undefined
  }
}
