import { makeAutoObservable, runInAction } from 'mobx'
import { logger } from 'shared/lib'

type LoadingStatusType = 'idle' | 'pending' | 'rejected' | 'fulfilled'
type StatusType = {
  isIdle: boolean
  isLoading: boolean
  isFailed: boolean
  isSuccess: boolean
  error: string
}

export class RequestLoadingStatus {
  private _loadingStatus: LoadingStatusType = 'idle'
  private _error = ''

  constructor() {
    makeAutoObservable(this)
  }

  loadData = async <T>(fn: (v: StatusType) => Promise<T>, errorHandler?: (v: unknown) => void) => {
    try {
      this._loadingStatus = 'pending'
      const result = await fn(this.status)

      runInAction(() => {
        this._loadingStatus = 'fulfilled'
      })

      return result
    } catch (e: unknown) {
      errorHandler?.(e)
      runInAction(() => {
        this._loadingStatus = 'rejected'
        this._error = (e as Error).message
      })
      logger.error(e)
    }
  }

  blockingLoadData = (
    fn: (v: StatusType) => Promise<void>,
    errorHandler?: (v: unknown) => void
  ) => {
    if (this._loadingStatus === 'pending') {
      return
    }

    return this.loadData(fn, errorHandler)
  }

  setError = (error: Error | string) => {
    runInAction(() => {
      this._loadingStatus = 'rejected'
      this._error = typeof error === 'string' ? error : error.message
    })
  }

  get status(): StatusType {
    return {
      isIdle: this._loadingStatus === 'idle',
      isLoading: this._loadingStatus === 'pending',
      isFailed: this._loadingStatus === 'rejected',
      isSuccess: this._loadingStatus === 'fulfilled',
      error: this._error,
    }
  }
}
