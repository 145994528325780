import { FC } from 'react'
import { ContactsTagsDropdown } from 'widgets/ContactsTagsModal'
import type { ImportContactsStore } from 'pages/contacts/ui/ImportContacts/store/ImportContactsStore'
import styles from './styles.module.scss'

type IApplyTagsModalActionsProps = {
  importContactsStore: ImportContactsStore
}

export const ApplyTagsModalContent: FC<IApplyTagsModalActionsProps> = ({ importContactsStore }) => {
  const { contactsTagsModalStore } = importContactsStore

  return (
    <>
      <div className={styles.tagBlock}>
        <div className={styles.label}>Tags (Optional)</div>
        <ContactsTagsDropdown contactsTagsModalStore={contactsTagsModalStore} />
      </div>
    </>
  )
}
