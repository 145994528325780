import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { PowerDialerAccessLayout } from 'pages/powerDialer/layout/powerDialerAccessLayout'
import { PowerDialerListPage } from 'pages/powerDialer/pages/list'
import { PowerDialerLayout } from 'pages/powerDialer/layout/PowerDialerLayout'

export const PowerDialerRouter = observer(() => {
  return (
    <Routes>
      <Route element={<PowerDialerAccessLayout />}>
        <Route element={<PowerDialerLayout />}>
          <Route index element={<PowerDialerListPage />} />
        </Route>
      </Route>
    </Routes>
  )
})
