import { FC } from 'react'
import { Alert } from 'shared/ui'
import { type ImportContactsStore } from 'pages/contacts/ui/ImportContacts/store/ImportContactsStore'
import { ProviderImportContacts } from 'pages/contacts/ui/ImportContacts/context/ImportContactsContext'
import { MapFieldsTable } from './MapFieldsTable/MapFieldsTable'

type IMapFieldsModalContentProps = {
  store: ImportContactsStore
}

export const MapFieldsModalContent: FC<IMapFieldsModalContentProps> = ({ store }) => {
  return (
    <ProviderImportContacts value={store}>
      <div>
        <Alert
          item={{
            type: 'infoBlue',
            desc: <>Match each column from the spreadsheet to a Salesmsg field</>,
          }}
        />
        <MapFieldsTable />
      </div>
    </ProviderImportContacts>
  )
}
