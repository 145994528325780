import { makeAutoObservable } from 'mobx'
import { logger } from 'shared/lib'
import { Contact } from 'entities/Contacts/model/Contact'
import {
  IIntegrationMeeting,
  Integration,
  IntegrationKey,
  IntegrationsApi,
} from 'entities/Integrations'

export type MeetingsProps = {
  contact?: Contact | null
  integration?: Integration | null
  onClose?: () => void
  onAddMeetingLink?: (link: string) => void
  onNecessaryReconnect?: (status: boolean) => void
}
export class MeetingsStore {
  contact: Contact | null
  constructor({ contact }: MeetingsProps) {
    this.contact = contact || null

    makeAutoObservable(this)
  }

  private _search = ''
  private _loading = false
  private _meetings: IIntegrationMeeting[] | null = null
  private _selectedMeeting: IIntegrationMeeting | null = null
  private _selectedIntegration: Integration | null = null
  private _isNecessaryReconnect = false

  loadMeetings = async () => {
    try {
      this._loading = true

      const { data } = await IntegrationsApi.getIntegrationMeetings(IntegrationKey.hubspot)
      if (data) {
        if (data.data) this.setMeetings(data.data)
        if (data.error) {
          this._isNecessaryReconnect = true
        }
      }
    } catch (e) {
      logger.error(e)
    } finally {
      this._loading = false
    }
  }

  setMeetings = (meetings: IIntegrationMeeting[]) => {
    this._meetings = meetings
  }

  setSearch = async (search: string) => {
    this._search = search
  }

  onSelectMeeting = (meeting: IIntegrationMeeting) => {
    this._selectedMeeting = meeting
  }

  onBack = () => {
    this._selectedMeeting = null
    this._selectedIntegration = null
    this._search = ''
  }

  onSelectedIntegration = (integration: Integration) => {
    this._selectedIntegration = integration
    this.loadMeetings()
  }

  get isShowBack() {
    return this.selectedMeeting || this.selectedIntegration
  }

  get filteredMeetings() {
    if (this._search) {
      return this._meetings?.filter((meeting) =>
        meeting.name?.toLowerCase().includes(this._search.toLowerCase())
      )
    }

    return this._meetings
  }

  get search() {
    return this._search
  }

  get loading() {
    return this._loading
  }

  get selectedMeeting() {
    return this._selectedMeeting
  }

  get selectedIntegration() {
    return this._selectedIntegration
  }
  get isNecessaryReconnect() {
    return this._isNecessaryReconnect
  }
}
