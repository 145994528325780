// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HggWPMsE4ZovlxhvTmvR{position:relative;display:flex;flex-grow:1;margin:0 16px}", "",{"version":3,"sources":["webpack://./src/pages/powerDialer/pages/list/ui/PowerDialerTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,aAAA","sourcesContent":[".wrap {\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  margin: 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "HggWPMsE4ZovlxhvTmvR"
};
export default ___CSS_LOADER_EXPORT___;
