import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, FC } from 'react'
import {
  Alert,
  Avatar,
  Button,
  EnumSpinnerLoaderPosition,
  SpinnerLoader,
  Typography,
} from 'shared/ui'
import { links } from 'shared/constants/links'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import { type ContactsMergeModalStore } from 'widgets/ContactsMergeModal'
import styles from './styles.module.scss'

type IContactsMergeModalContentProps = {
  store: ContactsMergeModalStore
}

export const ContactsMergeModalContent: FC<IContactsMergeModalContentProps> = observer(
  ({ store }) => {
    const {
      conflictsCount,
      itemsPairList,
      firstLoading,
      selectedItemId,
      loadingDuplicate,
      loadingMerge,
      selectItem,
      changeFirstLoading,
    } = store

    useEffect(() => {
      if (!loadingDuplicate && !loadingMerge && itemsPairList.length) {
        changeFirstLoading(false)
      }
      return () => {
        changeFirstLoading(true)
      }
    }, [])

    return (
      <div className={styles.container}>
        {firstLoading && <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />}

        <Alert
          item={{
            type: 'infoLight',
            title: `These contacts have conflicting values on ${conflictsCount} fields`,
            desc: 'Choose the primary contact before you merge the contacts',
          }}
          rightAction={
            <a href={links.dupleMergeLearnMore} target={'_blank'} rel='noreferrer'>
              <Button text='Learn more' typeBtn='outlined' size='small' contained='secondary' />
            </a>
          }
        />
        <div className={styles.cardsWrapper}>
          {itemsPairList.map((item, idx) => (
            <div
              key={item.id}
              className={classNames(styles.card, {
                [styles.active]: selectedItemId === item.id,
                [styles.inActive]: selectedItemId !== item.id,
              })}
              onClick={() => selectItem(item.id)}
            >
              <div className={styles.tag}>
                <Typography variant={'text-input-helper'} ariaLabel={'Current'}>
                  {idx === 0 ? 'Current' : 'New'}
                </Typography>
              </div>
              <div className={styles.avatar}>
                <Avatar
                  size={40}
                  info={{
                    firstName: item.first_name,
                    lastName: item.last_name,
                    image: item.photo_url,
                    color: item.color,
                    number: item.number || item.formatted_number || item.national_number,
                  }}
                />
              </div>

              <Typography
                ellipsis
                tag='div'
                variant={'body-rg-medium'}
                color='var(--content-primary-primary)'
                ariaLabel={'full_name'}
              >
                {item.full_name || '-'}
              </Typography>

              <Typography
                tag='div'
                ellipsis
                variant={'body-md-regular'}
                color='var(--content-primary-secondary)'
                ariaLabel={'number'}
              >
                {(item.number && getLabelAsNumberInternationalFormat(item.number)) ||
                  item.formatted_number ||
                  item.national_number ||
                  '-'}
              </Typography>

              <Typography
                tag='div'
                ellipsis
                variant={'body-md-regular'}
                color='var(--content-primary-secondary)'
                ariaLabel={'email'}
              >
                {item.email || '-'}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    )
  }
)
