import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { uiStore } from 'shared/store/uiStore'
import { Alert, Button } from 'shared/ui'
import { ContactsPath } from 'pages/contacts/route/contactsPath'
import { CleanupAlertStore } from 'pages/contacts/ui/CleanupAlert/store/cleanupAlertStore'

export const CleanupAlert = observer(() => {
  const [store] = useState(() => new CleanupAlertStore())

  useEffect(() => {
    store.init()
  }, [])

  if (store.loading) return null
  if (!store.hasItems) return null

  return (
    <Alert
      marginBottom={16}
      marginInline={20}
      item={{
        desc: store.title,
        type: 'warning',
      }}
      rightAction={
        <Button
          typeBtn={'action'}
          size={'small'}
          contained={'primary'}
          text={'Resolve'}
          onClick={() => {
            uiStore.changeRoute({
              path: ContactsPath.cleanup,
              options: {
                state: store.isExistOnlyUnlinked ? 'isUnlinked' : null,
              },
            })
          }}
        />
      }
    />
  )
})
