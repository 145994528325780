import { makeAutoObservable, runInAction } from 'mobx'
import type {
  IHubspotSettingsDict,
  IIntegrationSettingsProperties,
  Integration,
} from 'entities/Integrations'
import { type CustomField, customFieldOperation, CustomFieldsStore } from 'entities/CustomField'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import type {
  SettingsPropertyItemType,
  SettingsPropertyOptionType,
} from 'pages/settings/pages/integrations/pages/integrationSettings/model/types'
import type { IntegrationCustomSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCustomSettingsStore'
import { SettingsProperty } from 'pages/settings/pages/integrations/pages/integrationSettings/model/SettingsProperty'

const FIELDS_LIMIT = 150

export class IntegrationSettingsCustomPropertyStore {
  private _properties = new Map<number | string, SettingsProperty>()
  private _customFields = new CustomFieldsStore(FIELDS_LIMIT)
  private _customOptions: SettingsPropertyOptionType[] = []
  private _usedOptionsIds = new Set<string | number>()

  constructor(
    private _integration: Integration,
    private _customSetting: IntegrationCustomSettingsStore<IHubspotSettingsDict>,
    private _bannedIds: string[]
  ) {
    makeAutoObservable(this)
  }

  async init() {
    this._customFields.loadData()

    const fullDataTimeout = setInterval(() => {
      if (!this.customFieldsLoading) {
        clearInterval(fullDataTimeout)
        this._customFieldsInitialization()
      }
    }, 200)
  }

  private _customFieldsInitialization = async () => {
    const data = await allMergeFieldsStore.getIntegrationFields(this._integration.key)

    runInAction(() => {
      this._customOptions = data
        .filter((item) => item.groupName !== 'salesmsg')
        .map((item) => ({
          id: item.name,
          label: item.label,
          value: item.name,
          type: item.type,
        }))
    })

    this._customSetting.getValue('properties_mapping')?.forEach((item) => {
      const customField = this._customFields.fields.find(
        ({ key }) => key === item.custom_field_name
      )

      this._setProperty({
        id: item.custom_field_name,
        fieldName: customField?.name || '',
        value: item.integration_field_name,
        disabled: false,
        type: 'custom',
        fieldType: customField?.type,
        options: this._customOptions,
        integrationName: this._integration.name,
      })
    })
  }

  private _patchCustomParams = async (patchAll = false) => {
    const integrationFields = allMergeFieldsStore.mergeFieldsIntegrationsMap.get(
      this._integration.key
    )

    const customProperties = this.rows.reduce((acc, item) => {
      const checkTemporaryFile = patchAll || item.type !== 'temporary'
      if (item.type !== 'default' && item.value && checkTemporaryFile) {
        acc.push({
          custom_field_name: item.id as string,
          integration_field_name: item.value,
          integration_field_type: integrationFields?.get(item.value)?.type || '',
        })
      }
      return acc
    }, [] as IIntegrationSettingsProperties[])

    // avoiding server request for temporary fields
    if (
      !patchAll &&
      this._customSetting
        .getValue('properties_mapping')
        ?.every(
          ({ custom_field_name, integration_field_name }) =>
            this._properties.get(custom_field_name)?.value === integration_field_name
        )
    ) {
      // mock success status
      return { status: 200 }
    }

    return await this._customSetting.patchData([
      {
        key: 'properties_mapping',
        value: customProperties,
      },
    ])
  }

  private _patchData = async (newData: SettingsPropertyItemType) => {
    const item = this._properties.get(newData.id)
    if (!item) return

    this._setProperty(newData)

    const response = await this._patchCustomParams()

    if (!(response?.status === 200)) {
      this._setProperty(item)
    }
  }

  private _deleteData = async (data: SettingsPropertyItemType) => {
    runInAction(() => {
      this._usedOptionsIds.delete(data.value || '')
      this._properties.delete(data.id)
    })
  }

  private _setProperty = (data: SettingsPropertyItemType) => {
    runInAction(() => {
      this._properties.set(
        data.id,
        new SettingsProperty<string>(data, {
          onDelete: this._deleteData,
          onChange: this._patchData,
          initialBannedIds: this._usedOptionsIds,
        })
      )
    })
    this._updateBannedIds()
  }

  private _updateBannedIds = () => {
    runInAction(() => {
      this._usedOptionsIds.clear()
      this._bannedIds.forEach((key) => {
        this._usedOptionsIds.add(key)
      })
      this._properties.forEach((item) => {
        this._usedOptionsIds.add(item.value || '')
      })
    })
  }

  refreshStore = () => {
    this._properties.clear()
    this._customFieldsInitialization()
  }

  searchFields = (term = '') => {
    this._customFields.searchFields(term)
  }

  clearSearch = () => {
    this._customFields.clearSearch()
  }

  createField = async () => {
    const field = await customFieldOperation.createField()
    if (!field) return null

    await this._customFields.reloadData()

    return field
  }

  addNewProperty = (field: CustomField) => {
    this._setProperty({
      id: field.key,
      fieldName: field.name,
      value: null,
      disabled: false,
      type: 'temporary',
      fieldType: field.type,
      options: this._customOptions,
      integrationName: this._integration.name,
    })
  }

  saveNewProperties = async () => {
    if (this.isValidForm) {
      await this._patchCustomParams(true)
      this.refreshStore()
    } else {
      this._properties.forEach((item) => {
        if (!item.mapped) {
          this._setProperty({ ...item.original, showValidation: true })
        }
      })
    }
  }

  get customFields() {
    return this._customFields.termFields
      .reduce(
        (acc, field) => {
          const targetFields = field.key.startsWith('custom.') ? acc[1] : acc[0]
          targetFields.fields.push(field)
          return acc
        },
        [
          {
            id: 'defaultFields',
            groupName: 'Default fields',
            fields: [] as CustomField[],
          },
          {
            id: 'customFields',
            groupName: 'Custom fields',
            fields: [] as CustomField[],
          },
        ]
      )
      .filter((acc) => acc.fields.length)
  }

  get rows() {
    return Array.from(this._properties.values())
  }

  get mappedLength() {
    return this.rows.filter((item) => item.mapped).length
  }

  get isValidForm() {
    return this.mappedLength === this.rows.length
  }

  get customFieldsLoading() {
    return (
      this._customFields.loading ||
      allMergeFieldsStore.loading ||
      this._customSetting.status.isIdle ||
      this._customSetting.status.isLoading
    )
  }

  get loading() {
    return this._customSetting.status.isLoading
  }

  get isExistNewProperties() {
    return this.rows.some((item) => item.type === 'temporary')
  }

  get isRemovedOldProperties() {
    return !!this._customSetting
      .getValue('properties_mapping')
      ?.some((item) => !Boolean(this._properties.get(item.custom_field_name)))
  }
}
