// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PMmZiVSaH5_qLkAB2aNa{display:flex;align-items:center;cursor:pointer;border-radius:6px;padding:4px 4px 4px 6px}.PMmZiVSaH5_qLkAB2aNa:hover{background:var(--gray-10)}.DKQ8MJRCsg8ZACulCFx6{margin-right:4px}", "",{"version":3,"sources":["webpack://./src/pages/contacts/ui/ContactsMenu/ui/ContactsFiltersHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,uBAAA,CAEA,4BACI,yBAAA,CAGR,sBACI,gBAAA","sourcesContent":[".dropdownTriggerComponent {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    border-radius: 6px;\n    padding: 4px 4px 4px 6px;\n\n    &:hover {\n        background: var(--gray-10);\n    }\n}\n.headerName {\n    margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownTriggerComponent": "PMmZiVSaH5_qLkAB2aNa",
	"headerName": "DKQ8MJRCsg8ZACulCFx6"
};
export default ___CSS_LOADER_EXPORT___;
