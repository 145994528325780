import { makeAutoObservable, runInAction } from 'mobx'
import { RequestLoadingStatus } from 'shared/store/RequestLoadingStatus'
import { showToast } from 'shared/ui'
import { getEntries } from 'shared/lib'
import {
  IntegrationsApi,
  type Integration,
  type ISettingRequestBySettingDict,
} from 'entities/Integrations'

const ERROR_NOTIFICATION = 'We couldn’t update your preference. Please try again later.'

export class IntegrationCustomSettingsStore<T extends object> {
  private _status = new RequestLoadingStatus()
  private _requestController = new AbortController()
  private _settingValues = new Map<keyof T, T[keyof T]>()
  private _settingsOptions = new Map<keyof T, { value: T[keyof T]; label: string }[]>()

  constructor(private _integration: Integration) {
    makeAutoObservable(this)
  }

  async init() {
    this.getInitialData()
  }

  getInitialData = () =>
    this._status.blockingLoadData(async () => {
      const [propsList, propsValue] = await Promise.all([
        IntegrationsApi.getIntegrationSettingsList<T>(this._integration.key),
        IntegrationsApi.getIntegrationSettings<T>(this._integration.key),
      ])

      propsValue.data.data.forEach(({ key, value }) => {
        this._settingValues.set(key, value)
      })

      Object.values(propsList.data).forEach((options) => {
        getEntries(options).forEach(([key, value]) => {
          this._settingsOptions.set(key, value)
        })
      })
    })

  patchData = (data: ISettingRequestBySettingDict<T>) => {
    runInAction(() => {
      data.forEach(({ key, value }) => {
        this._settingValues.set(key, value)
      })
    })

    return this._status.loadData(
      async () => {
        this._requestController.abort()
        this._requestController = new AbortController()
        const response = await IntegrationsApi.putIntegrationSettings<T>(
          this._integration.key,
          data,
          {
            signal: this._requestController.signal,
          }
        )
        runInAction(() => {
          response.data.data.forEach(({ key, value }) => {
            if (this._settingValues.get(key) !== value) {
              this._settingValues.set(key, value)
            }
          })
        })
        return response
      },
      (e) => {
        if ((e as Error)?.message === 'canceled') return

        showToast({
          type: 'error',
          title: ERROR_NOTIFICATION,
        })
      }
    )
  }

  getValue = <K extends keyof T>(key: K) => this._settingValues.get(key) as T[K]

  getOptions = <K extends keyof T>(key: K) =>
    this._settingsOptions.get(key) as Array<{ value: T[K]; label: string }>

  getValueOrFirstOption = <K extends keyof T>(key: K) => {
    return this.getValue(key) || this.getOptions(key)?.[0]?.value
  }

  get status() {
    return this._status.status
  }
}
