// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C8heO03bJWf_NWOEMnfQ{display:flex;flex-direction:column;border-right:1px solid var(--border-neutral-default);padding:20px 12px;width:260px;gap:2px}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/detail/CampaignDetail/ui/Steps/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,oDAAA,CACA,iBAAA,CACA,WAAA,CACA,OAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  border-right: 1px solid var(--border-neutral-default);\n  padding: 20px 12px;\n  width: 260px;\n  gap: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "C8heO03bJWf_NWOEMnfQ"
};
export default ___CSS_LOADER_EXPORT___;
