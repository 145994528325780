export const campaignActiveFilter = [
  {
    custom_filters: [],
    filter_tags: [],
    filters: [
      {
        key: 'campaigns.status',
        operator: 'is_any',
        value: ['active'],
      },
    ],
  },
]
