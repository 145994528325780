import { runInAction, makeAutoObservable } from 'mobx'
import { userSettingsStore } from 'entities/Settings'
import type {
  IHubspotSettingsDict,
  IntegrationSettingsTimelineLogCheckboxes,
} from 'entities/Integrations'
import type { IntegrationCustomSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCustomSettingsStore'

const LOG_KEYS: IntegrationSettingsTimelineLogCheckboxes[] = [
  'log_calls',
  'log_messages',
  'log_notes',
  'log_voicemails',
]

export class IntegrationHubspotSettingsTimelineStore {
  dailyBundles = false
  timelineAssociations = false

  constructor(private _customSetting: IntegrationCustomSettingsStore<IHubspotSettingsDict>) {
    makeAutoObservable(this)
  }

  init() {
    const settings = userSettingsStore.settings
    runInAction(() => {
      // used daily-bundles inverse value for get and send data
      this.dailyBundles = !settings?.['daily-bundles']?.settings?.hubspot
      this.timelineAssociations = !!settings?.['timeline-associations']?.settings?.active
    })
  }

  onDailyBundlesToggle = () => {
    runInAction(() => {
      this.dailyBundles = !this.dailyBundles
      this._customSetting.patchData(
        LOG_KEYS.map((key) => ({
          key,
          value: this.dailyBundles ? 1 : 0,
        }))
      )
    })

    userSettingsStore.updateSetting({
      settingsKey: 'hubspot',
      value: !this.dailyBundles,
      featureKey: 'daily-bundles',
    })
  }

  onTimelineAssociationsToggle = () => {
    this.timelineAssociations = !this.timelineAssociations

    userSettingsStore.updateSetting({
      settingsKey: 'active',
      value: this.timelineAssociations,
      featureKey: 'timeline-associations',
    })
  }

  onLogeChange = (key: IntegrationSettingsTimelineLogCheckboxes) => (event: boolean) => {
    this._customSetting.patchData([
      {
        key,
        value: event ? 1 : 0,
      },
    ])
  }

  getLog = (type: IntegrationSettingsTimelineLogCheckboxes) => {
    return Boolean(this._customSetting.getValue(type))
  }
}
