import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Alert, DropdownPlaceholder, EnumDropdownItemVariant } from 'shared/ui'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { type IResponseContactUnlinkedConflictItem } from 'entities/Contacts/api/types'
import { type LinkContactStore } from 'pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable/ui/LinkContact'
import styles from './styles.module.scss'

type ILinkWithPhonesModalContentProps = {
  store: LinkContactStore
  contact: IResponseContactUnlinkedConflictItem
}

export const LinkWithPhonesModalContent: FC<ILinkWithPhonesModalContentProps> = observer(
  ({ contact, store }) => {
    return (
      <div className={classnames(styles.contentWrap)}>
        <Alert
          item={{
            desc: 'Select the phone number you want to use for this contact',
            type: 'infoLight',
          }}
        />
        <DropdownPlaceholder
          items={[
            {
              id: 1,
              label: `Phone: ${contact.phone || '-'}`,
              activeValue: store.selectedMatchedPhone === contact.phone,
              value: contact.phone,
              variant: EnumDropdownItemVariant.CheckedRight,
            },
            {
              id: 2,
              label: `Mobile Phone: ${contact.mobile_phone || '-'}`,
              activeValue: store.selectedMatchedPhone === contact.mobile_phone,
              value: contact.mobile_phone || '',
              variant: EnumDropdownItemVariant.CheckedRight,
            },
          ]}
          onChange={({ value }) => {
            if (value) {
              store.handleSelectedMatchedPhone(value)
            }
          }}
          widthDropdown={440}
          placeholder={
            `${contact.phone === store.selectedMatchedPhone ? 'Phone:' : 'Mobile Phone:'} ${
              store.selectedMatchedPhone
            }` || '-'
          }
          textPlaceholderProps={{
            variant: 'stroke',
            size: 'small',
            rightActions: [makeTextFieldIcon({ icon: 'chevronDown' })],
          }}
        />
      </div>
    )
  }
)
