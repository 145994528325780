import classNames from 'classnames'
import { IDropdownItem } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import styles from './styles.module.scss'

type IUseCaseSelectProps = {
  items: IDropdownItem[]
  value: string
  setValue: (useCase: string) => void
}

export const UseCaseSelect = ({ items, value, setValue }: IUseCaseSelectProps) => {
  return (
    <SelectField
      labelText='Use case'
      items={items}
      setValue={setValue}
      value={value}
      btnProps={{
        size: 'extraLarge',
        className: classNames(styles.select, styles.useCaseSelect),
      }}
    />
  )
}
