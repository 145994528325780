import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Table } from 'shared/ui'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { type ContactsUnlinkedTableStore } from 'pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable'
import { columns } from './columns'
import styles from './styles.module.scss'

type IContactsUnlinkedTableProps = {
  store: ContactsUnlinkedTableStore
}

export const ContactsUnlinkedTable: FC<IContactsUnlinkedTableProps> = observer(({ store }) => {
  const { list, total, paginationData, loading, changePagination, noResults } = store

  return (
    <div className={styles.tableContainer}>
      {noResults && !loading ? (
        <NoResultsFound label={'No unlinked contacts found'} />
      ) : (
        <Table
          sxBox={{ padding: '0 16px' }}
          columns={columns(store)}
          rows={list}
          limit={paginationData.length}
          rowsCount={total}
          isLoading={loading}
          onChangePagination={changePagination}
        />
      )}
    </div>
  )
})
