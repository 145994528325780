import { useState, FC } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { ExportButton, type ILoadedData, SpinnerLoader } from 'shared/ui'
import { links } from 'shared/constants/links'
import { sizeAlertCreator } from 'shared/lib/sizeAlertCreator'
import { type ImportContactsStore } from 'pages/contacts/ui/ImportContacts/store/ImportContactsStore'
import styles from './styles.module.scss'

type IImportContactsModalContentProps = {
  store: ImportContactsStore
}

export const ImportContactsModalContent: FC<IImportContactsModalContentProps> = observer(
  ({ store }) => {
    const {
      isLoading,
      addContacts,
      setHeaders,
      uploadImportContactsFile,
      setImportModalError,
      setLoading,
      loadImportFields,
    } = store

    const [dragActive, setDragActive] = useState(false)

    const onFileLoaded = ({ contacts, headers }: ILoadedData) => {
      addContacts(contacts)
      setHeaders(headers)
    }

    const onHandleChange = (file: File) => {
      uploadImportContactsFile(file)
      loadImportFields()
    }

    return (
      <div
        className={classNames(styles.contentContainer, dragActive && styles.activeDragContainer)}
        onDragEnter={() => {
          setDragActive(true)
        }}
      >
        {isLoading && (
          <div className={styles.loadingBg}>
            <div className={styles.spinner}>
              <SpinnerLoader size={16} />
            </div>
            <p className={styles.loadingText}>Importing your data</p>
          </div>
        )}
        <ExportButton
          dragActive={dragActive}
          onHandleChange={onHandleChange}
          setDragActive={setDragActive}
          onFileLoaded={onFileLoaded}
          setLoading={setLoading}
          itemLimit={100 * 1000}
          wrongTypeHandler={(name: string) => {
            setImportModalError({
              title: `File “${name}“ is not supported for import`,
              desc: 'Please upload only .csv files',
            })
          }}
          limitErrorHandler={(name: string) => {
            setImportModalError({
              title: sizeAlertCreator({ label: name, limit: 100 * 1000 }).title,
              desc: 'Please upload .csv file with less than 100,000 rows',
            })
          }}
        />

        <p className={styles.descriptionLine}>or drag and drop a file here</p>
        <p className={styles.secondDescriptionLine}>Acceptable file types: CSV</p>
        <p className={styles.thirdDescriptionLine}>
          Please review our
          <a
            href={links.importYourContacts}
            target='_blank'
            className={styles.guide}
            rel='noreferrer'
          >
            import guidelines
          </a>
          before continuing
        </p>
      </div>
    )
  }
)
