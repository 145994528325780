import { IMenuItemNavProps } from 'shared/ui/Menu/ui/MenuItem/MenuItemNav/MenuItemNav'
import { Status, Typography } from 'shared/ui'
import { IMenuItemDefaultProps } from 'shared/ui/Menu/ui/MenuItem/MenuItemDefault/MenuItemDefault'
import { EnumUserRoles } from 'entities/Users'
import { MediaLibraryStore } from 'widgets/MediaLibrary'
import { ComplianceRoutes } from 'pages/settings/pages/compliance'
import { KnowledgeBaseRoutes } from 'pages/settings/pages/knowledgeBase'
import { SettingsRoutes } from '../route/type'
import styles from './styles.module.scss'

type GetWorkspaceAllItemsParams = {
  canManageMembers: boolean
  canManageBillingSettings: boolean
  canAddSubscription: boolean
  canManageIntegrations: boolean
}

export const getWorkspaceAllItems = ({
  canManageMembers,
  canManageBillingSettings,
  canAddSubscription,
  canManageIntegrations,
}: GetWorkspaceAllItemsParams): IMenuItemNavProps[] => {
  const items: IMenuItemNavProps[] = [
    {
      key: 'ceneral',
      type: 'nav',
      to: SettingsRoutes.general,
      label: 'General',
      icon: 'building2',
    },
    {
      key: 'inboxes',
      type: 'nav',
      to: SettingsRoutes.inboxes,
      label: 'Inboxes',
      icon: 'inboxOpen',
    },
    {
      key: 'numbers',
      type: 'nav',
      to: SettingsRoutes.numbers,
      label: 'Numbers',
      icon: 'phone',
    },
    {
      key: 'teams',
      type: 'nav',
      to: SettingsRoutes.teams,
      label: 'Teams',
      icon: 'user2',
    },
  ]

  if (canManageIntegrations) {
    items.push({
      key: 'app_marketplace',
      type: 'nav',
      to: SettingsRoutes.integrations,
      label: 'App Marketplace',
      icon: 'plug',
    })
  }

  items.push({
    key: 'compliance',
    type: 'nav',
    to: ComplianceRoutes.root,
    label: 'Compliance',
    icon: 'shield',
  })

  if (canManageMembers) {
    items.splice(1, 0, {
      key: 'members',
      type: 'nav',
      to: SettingsRoutes.members,
      label: 'Members',
      icon: 'users',
    })
  }

  if (canManageBillingSettings) {
    items.push({
      key: 'plan_billing',
      type: 'nav',
      to: SettingsRoutes.planAndBilling,
      label: 'Plan & Billing',
      icon: 'card',
    })
  }

  if (canAddSubscription) {
    items.push({
      key: 'add_subscription',
      type: 'nav',
      to: SettingsRoutes.addSubscription,
      label: 'Add subscription',
      icon: 'card',
    })
  }

  return items
}

export const getProductAllItems = (
  mediaLibraryStore: MediaLibraryStore
): (IMenuItemDefaultProps | IMenuItemNavProps)[] => [
  {
    key: 'tags',
    type: 'nav',
    to: SettingsRoutes.tags,
    label: 'Tags',
    icon: 'tag',
  },
  {
    key: 'custom_fields',
    type: 'nav',
    to: SettingsRoutes.customFields,
    label: 'Custom Fields',
    icon: 'textInput2',
  },
  {
    key: 'url_shortener',
    type: 'nav',
    to: SettingsRoutes.urlShortener,
    label: 'URL Shortener',
    icon: 'link',
  },
  {
    key: 'website_chat_widget',
    type: 'nav',
    to: SettingsRoutes.websiteChatWidget,
    label: 'Website Chat Widget',
    icon: 'messageTextCircle02',
  },
  {
    key: 'ai_knowledge_base',
    type: 'nav',
    to: KnowledgeBaseRoutes.root,
    label: (
      <Typography
        variant={'body-md-regular'}
        className={styles.linkWithBadge}
        ariaLabel={'AI knowledge base'}
      >
        AI knowledge base
      </Typography>
    ),
    icon: 'bulb',
    rightContent: <Status title={'Beta'} intent={'warning'} emphasis={'high'} size={'label'} />,
  },
  {
    key: 'quick_links',
    type: 'nav',
    to: SettingsRoutes.quickLinks,
    label: (
      <Typography
        className={styles.linkWithBadge}
        variant={'body-md-regular'}
        ariaLabel={'Quick links'}
      >
        Quick links
      </Typography>
    ),
    icon: 'linkExternal',
    rightContent: <Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />,
  },
  {
    key: 'media_library',
    type: 'default',
    label: 'Media library',
    variant: 'nav',
    onClick: mediaLibraryStore.handleOpenMediaLibraryModal,
    icon: 'attachment',
  },
]

export const getPersonalAllItems = (): IMenuItemNavProps[] => [
  {
    key: 'account',
    type: 'nav',
    to: SettingsRoutes.account,
    label: 'Account',
    icon: 'userFilled',
  },
  {
    key: 'referrals',
    type: 'nav',
    to: SettingsRoutes.referrals,
    label: 'Referrals',
    icon: 'dollar',
  },
]

export const getDeveloperItems = (): IMenuItemNavProps[] => [
  {
    key: 'OAuth_applications',
    type: 'nav',
    to: SettingsRoutes.oauthApplications,
    label: 'OAuth Applications',
    icon: 'container',
  },
  {
    key: 'personal_access_tokens',
    type: 'nav',
    to: SettingsRoutes.personalAccessTokens,
    label: 'Personal Access Tokens',
    icon: 'container',
  },
  {
    key: 'public_api_tokens',
    type: 'nav',
    to: SettingsRoutes.publicApiTokens,
    label: 'Public API Tokens',
    icon: 'container',
  },
  {
    key: 'authorized_apps',
    type: 'nav',
    to: SettingsRoutes.authorizedApps,
    label: 'Authorized Apps',
    icon: 'container',
  },
]

export const workspaceRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutes.integrations],
  member: [SettingsRoutes.inboxes, SettingsRoutes.numbers, SettingsRoutes.integrations],
  manager: [
    SettingsRoutes.general,
    SettingsRoutes.inboxes,
    SettingsRoutes.numbers,
    SettingsRoutes.integrations,
  ],
  admin: [],
  owner: [],
}

export const canceledWorkspaceRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutes.integrations],
  member: [SettingsRoutes.inboxes, SettingsRoutes.numbers, SettingsRoutes.integrations],
  manager: [
    SettingsRoutes.general,
    SettingsRoutes.inboxes,
    SettingsRoutes.numbers,
    SettingsRoutes.integrations,
  ],
  admin: [SettingsRoutes.general, ComplianceRoutes.root, SettingsRoutes.addSubscription],
  owner: [SettingsRoutes.general, ComplianceRoutes.root, SettingsRoutes.addSubscription],
}

export const productRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutes.tags, SettingsRoutes.customFields],
  member: [SettingsRoutes.tags, SettingsRoutes.customFields, SettingsRoutes.quickLinks],
  manager: [SettingsRoutes.tags, SettingsRoutes.customFields, SettingsRoutes.quickLinks],
  admin: [],
  owner: [],
}

export const canceledProductRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutes.tags, SettingsRoutes.customFields],
  member: [SettingsRoutes.tags, SettingsRoutes.customFields, SettingsRoutes.quickLinks],
  manager: [SettingsRoutes.tags, SettingsRoutes.customFields, SettingsRoutes.quickLinks],
  admin: [SettingsRoutes.urlShortener, SettingsRoutes.websiteChatWidget, SettingsRoutes.quickLinks],
  owner: [SettingsRoutes.urlShortener, SettingsRoutes.websiteChatWidget, SettingsRoutes.quickLinks],
}

export const personalRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutes.account],
  member: [SettingsRoutes.account],
  manager: [SettingsRoutes.account],
  admin: [],
  owner: [],
}

export const canceledPersonalRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutes.account],
  member: [SettingsRoutes.account],
  manager: [SettingsRoutes.account],
  admin: [SettingsRoutes.account, SettingsRoutes.referrals],
  owner: [SettingsRoutes.account, SettingsRoutes.referrals],
}

export const developerRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [],
  member: [],
  manager: [],
  admin: [],
  owner: [],
}

export const canceledDeveloperRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [
    SettingsRoutes.oauthApplications,
    SettingsRoutes.personalAccessTokens,
    SettingsRoutes.publicApiTokens,
  ],
  member: [
    SettingsRoutes.oauthApplications,
    SettingsRoutes.personalAccessTokens,
    SettingsRoutes.publicApiTokens,
  ],
  manager: [
    SettingsRoutes.oauthApplications,
    SettingsRoutes.personalAccessTokens,
    SettingsRoutes.publicApiTokens,
  ],
  admin: [
    SettingsRoutes.oauthApplications,
    SettingsRoutes.personalAccessTokens,
    SettingsRoutes.publicApiTokens,
  ],
  owner: [
    SettingsRoutes.oauthApplications,
    SettingsRoutes.personalAccessTokens,
    SettingsRoutes.publicApiTokens,
  ],
}

const reachItems = (items: (IMenuItemNavProps | IMenuItemDefaultProps)[]) =>
  items.map((item) => {
    if (item.type === 'default') return item
    return {
      ...item,
      to: `/${SettingsRoutes.root}/${item.to}`,
      checkActive: (pathname: string) => item.to && pathname.includes(item.to),
    }
  })

export const getMenuItemsForRole = (
  role: EnumUserRoles,
  items: (IMenuItemNavProps | IMenuItemDefaultProps)[],
  roleMapping: Record<EnumUserRoles, string[]>
): (IMenuItemNavProps | IMenuItemDefaultProps)[] => {
  const allowedRoutes = roleMapping[role]

  const filteredItems = items.filter((item) => {
    return item.type === 'default' || (item.to && allowedRoutes.includes(item.to))
  })

  if (allowedRoutes.length === 0) return reachItems(items)

  return reachItems(filteredItems)
}
