import { observer } from 'mobx-react-lite'
import React from 'react'
import { Typography } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { useCampaignStepContext } from 'pages/campaigns/ui/detail/CampaignStep/context/context'
import { CampaignContactsStatisticsTable } from 'pages/campaigns/ui/detail/CampaignContactsStatisticsTable'
import styles from './styles.module.scss'

export const CampaignStepUi = observer(() => {
  const { campaignContactsStatisticsTableStore, total, loading } = useCampaignStepContext()

  return (
    <>
      {!loading && (
        <div className={styles.wrapHeader}>
          <Typography variant={'heading-sm'} ariaLabel={'contactsCount'} className={styles.title}>
            {numberFormat({ value: total })} contacts
          </Typography>
        </div>
      )}
      <CampaignContactsStatisticsTable store={campaignContactsStatisticsTableStore} />
    </>
  )
})
