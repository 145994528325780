import { makeAutoObservable, runInAction } from 'mobx'
import { logger } from 'shared/lib'
import { ContactsApi } from 'entities/Contacts'
import { History } from 'pages/contacts/pages/history/model/History'

const defaultPagination = {
  page: 1,
  limit: 10,
}

export class ContactsImportModuleStore {
  private _total = 0
  private _paginationData = defaultPagination
  private _loading = false

  constructor() {
    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  get totalHistory() {
    return this._total
  }

  get paginationData() {
    return this._paginationData
  }

  getImportContactProgress = async (id: number, callback?: (history: History) => void) => {
    try {
      const { data: history } = await ContactsApi.getImportContactProgress(id)
      const newHistory = new History(history)

      callback?.(newHistory)

      if (newHistory.startedAt && !newHistory.finishedAt) {
        setTimeout(() => {
          this.getImportContactProgress(id, callback)
        }, 60 * 1000)
      }
    } catch (e) {
      logger.error(e)
    }
  }

  loadImportHistory = async (
    callbackReset?: () => void,
    callbackAdd?: (history: History) => void
  ) => {
    try {
      this.setIsLoading(true)
      const { data } = await ContactsApi.loadImportHistory(this._paginationData)

      runInAction(() => {
        this._total = data.meta.total
      })

      callbackReset?.()

      data.data.forEach((history) => {
        callbackAdd?.(new History(history))
      })
    } finally {
      this.setIsLoading(false)
    }
  }

  setTotal = (total: number) => {
    this._total = total
  }

  changePagination = (page: number, limit: number) => {
    this._paginationData = {
      page,
      limit,
    }
  }

  setIsLoading = (status: boolean) => {
    this._loading = status
  }

  dispose = () => {
    this._paginationData = defaultPagination
    this._total = 0
  }
}
