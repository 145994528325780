import { makeAutoObservable, reaction, runInAction, type IReactionDisposer } from 'mobx'
import { debounce } from 'lodash'
import { AxiosError } from 'axios'
import { nanoid } from 'nanoid'
import { isLink, checkIsEmail, logger } from 'shared/lib'
import { toastStore } from 'shared/ui'
import { UsersApi } from 'entities/Users'
import { type IParamsUpdateMessageSignature } from 'entities/Users/api/types'
import { type MessageFieldStore } from 'widgets/MessageField'

export enum IMessageSignatureErrorType {
  Length = 'length',
  Link = 'link',
}

type IMessageSignatureConfig = {
  parent: MessageFieldStore
}

export class MessageSignatureStore {
  private _isLoadingMessageSignature = false
  private _isActiveMessageSignature = false
  private _isFocusMessageSignature = false
  private _isInitCompleted = false
  private _messageSignatureError: IMessageSignatureErrorType | null = null
  private _messageSignature = ''
  private _messageSignatureMaxLength = 98
  private _debounceMessageSignature
  private _parent: MessageFieldStore
  private _focusTrigger = ''
  private _disposeMessageSignatureText: IReactionDisposer | null = null
  private _disposeDisablesSend: IReactionDisposer | null = null

  constructor({ parent }: IMessageSignatureConfig) {
    makeAutoObservable(this)
    this._debounceMessageSignature = debounce(this.updateMessageSignatureAction, 1000)
    this._parent = parent

    this.reactionMessageSignatureText()
    this.reactionDisablesSend()
  }

  reactionMessageSignatureText = () => {
    this._disposeMessageSignatureText?.()
    this._disposeMessageSignatureText = reaction(
      () => this.messageSignatureText,
      (text) => {
        this._parent.setAdditionalText(text)
      }
    )
  }

  reactionDisablesSend = () => {
    this._disposeDisablesSend?.()
    this._disposeDisablesSend = reaction(
      () => this.disablesSend,
      (disabled) => {
        this._parent.setMessageSignatureSendDisabled(disabled)
      }
    )
  }

  initMessageSignature = async () => {
    if (this._isInitCompleted) return

    try {
      runInAction(() => {
        this._isLoadingMessageSignature = true
        this._isInitCompleted = true
      })

      const { data } = await UsersApi.getMessageSignature()

      runInAction(() => {
        if (Object.keys(data).length) {
          this._isActiveMessageSignature = data.is_active
          this._messageSignature = data.signature?.trim()
        }
      })
    } catch (e) {
      if (e instanceof AxiosError) {
        runInAction(() => {
          this._isInitCompleted = false
        })

        toastStore.add({
          type: 'error',
          title: e.response?.data.message,
        })
        logger.error(e)
      }
    } finally {
      runInAction(() => {
        this._isLoadingMessageSignature = false
      })
    }
  }

  updateMessageSignatureAction = async () => {
    if (this.messageSignatureLengthError) return

    const params = {
      is_active: this._isActiveMessageSignature,
      signature: this._messageSignature,
    }
    try {
      await UsersApi.updateMessageSignature(params)
    } catch (e) {
      if (e instanceof AxiosError) {
        toastStore.add({
          type: 'error',
          title: e.response?.data.message,
        })
        logger.error(e)
      }
    }
  }

  setFocus = (value: boolean) => {
    this._isFocusMessageSignature = value
  }

  setMessageSignatureError = (value: IMessageSignatureErrorType | null) => {
    this._messageSignatureError = value
  }

  disabledSignatureChange = false

  handleUpdateMessageSignatureProperties = (params: IParamsUpdateMessageSignature) => {
    if (this.disabledSignatureChange) {
      return
    }
    const { is_active, signature, is_on_blur } = params
    if (typeof is_active === 'boolean') {
      if (is_active) {
        this._focusTrigger = nanoid()
      }
      this._isActiveMessageSignature = is_active
    }
    if (typeof signature === 'string') this._messageSignature = signature
    this.handleChangeMessageSignature()
    if (is_on_blur) {
      this._focusTrigger = ''
      this.disabledSignatureChange = true
      setTimeout(() => {
        this.disabledSignatureChange = false
      }, 200)
    }
  }

  handleChangeMessageSignature = async () => {
    await this._debounceMessageSignature()
  }

  get messageSignatureLength() {
    return this._messageSignature.length ? this.messageSignatureWithExtraSpace.length : 0
  }

  get messageSignatureLengthError() {
    return this._messageSignatureMaxLength - this.messageSignatureLength <= 0
  }

  get messageSignatureLinkError() {
    return Boolean(this.links.length) && this.isEmailOnly
  }

  get messageSignatureWithExtraSpace() {
    return '\n\n' + this._messageSignature
  }

  get disablesSend() {
    if (!this._isActiveMessageSignature) {
      return false
    }
    return this.messageSignatureLengthError || this.messageSignatureLinkError
  }

  get messageSignatureText() {
    return this._isActiveMessageSignature && !this._parent.isModeNote
      ? this.messageSignatureWithExtraSpace
      : ''
  }

  get messageSignatureErrorMessage() {
    if (this._messageSignatureError === IMessageSignatureErrorType.Length) {
      return 'Your signature must be under 100 characters'
    }

    if (this._messageSignatureError === IMessageSignatureErrorType.Link) {
      return 'Your signature can’t contain links'
    }

    return ''
  }

  get links() {
    const links: string[] = []
    this._messageSignature.split(/\n/).forEach((text) =>
      text.split(' ').forEach((word) => {
        if (isLink(word)) {
          links.push(word)
        }
      })
    )
    return links
  }

  get isEmailOnly() {
    const emails: string[] = []
    this.links.forEach((link) => {
      if (checkIsEmail(link)) {
        emails.push(link)
      }
    })

    return emails.length === this.links.length
  }

  get isActiveMessageSignature() {
    return this._isActiveMessageSignature
  }

  get focusTrigger() {
    return this._focusTrigger
  }

  get isFocusMessageSignature() {
    return this._isFocusMessageSignature
  }

  get messageSignature() {
    return this._messageSignature
  }

  get messageSignatureMaxLength() {
    return this._messageSignatureMaxLength
  }

  get messageSignatureError() {
    return this._messageSignatureError
  }

  get parent() {
    return this._parent
  }
}
