import type { AxiosResponse } from 'axios'
import type {
  AxiosOptions,
  IParamsSaveColumns,
  IResponseFilters,
  IResponseSaveColumns,
} from 'shared/api'
import { baseApi } from 'entities/Auth'
import type {
  IParamsCreatePowerDialer,
  IResponsePowerDialer,
  PowerDialerStatus,
  ContactFinalStatus,
  IResponsePowerDialerList,
  IParamsGetPowerDialerList,
} from 'entities/PowerDialer/api/types'

class Api {
  createPowerDialer(
    params: IParamsCreatePowerDialer
  ): Promise<AxiosResponse<{ data: IResponsePowerDialer }>> {
    return baseApi.post('/pd-campaigns', params)
  }

  getPowerDialerList(
    params: IParamsGetPowerDialerList,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponsePowerDialerList>> {
    return baseApi.get('/pd-campaigns/list', { params, ...options })
  }

  getFilters(): Promise<AxiosResponse<IResponseFilters>> {
    return baseApi.get('pd-campaigns/filters')
  }

  saveColumns = (params: IParamsSaveColumns): Promise<AxiosResponse<IResponseSaveColumns>> => {
    return baseApi.post('users/ui-settings/sync', params)
  }

  getColumns = (): Promise<AxiosResponse<IResponseSaveColumns>> => {
    return baseApi.get('users/ui-settings/pd-campaigns-table-columns')
  }

  getPowerDialerById(id: number): Promise<AxiosResponse<{ data: IResponsePowerDialer }>> {
    return baseApi.get(`/pd-campaigns/${id}`)
  }

  editPowerDialer(
    id: number,
    params: Partial<{ data: IResponsePowerDialer }>
  ): Promise<AxiosResponse<IResponsePowerDialer>> {
    return baseApi.put(`/pd-campaigns/${id}`, params)
  }

  changePowerDialerStatus(
    id: number,
    status: PowerDialerStatus
  ): Promise<AxiosResponse<{ data: IResponsePowerDialer }>> {
    return baseApi.put(`/pd-campaigns/${id}/${status}`)
  }

  resolveContactInPowerDialer(
    id: number,
    contactId: number,
    status: ContactFinalStatus
  ): Promise<AxiosResponse<{ data: IResponsePowerDialer }>> {
    return baseApi.put(`/pd-campaigns/${id}/${contactId}`, { status })
  }

  keepPowerDialerAlive(id: number): Promise<AxiosResponse<boolean>> {
    return baseApi.post(`/pd-campaigns/${id}/keep-alive`)
  }

  deletePowerDialer(id: number): Promise<void> {
    return baseApi.delete(`/pd-campaigns/${id}`)
  }
}

export const PowerDialerApi = new Api()
