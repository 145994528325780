import { CancelTokenSource } from 'axios'
import { type IReactionDisposer, makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import type { IDropdownItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { ComplianceApi } from 'entities/Compliance'
import type {
  ICompanyLocation,
  IResponseCountryState,
  IResponseTwilioServiceError,
} from 'entities/Compliance/api/complianceType'
import { organizationStore } from 'entities/Organization'
import { OrganizationBusinessProfile } from 'entities/Compliance/model'
import { BusinessProfileRoutes } from 'pages/settings/pages/compliance/pages/businessProfile/route/type'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import { GeneralInfoStore } from 'pages/settings/pages/compliance/pages/businessProfile/pages/GeneralInfo/store/generalInfoStore'
import { BusinessInfoStore } from 'pages/settings/pages/compliance/pages/businessProfile/pages/BusinessInfo/store/businessInfoStore'
import { PeopleToContactStore } from 'pages/settings/pages/compliance/pages/businessProfile/pages/PeopleToContact/store/peopleToContactStore'
import { CompanyLocationStore } from 'pages/settings/pages/compliance/pages/businessProfile/pages/CompanyLocation/store/CompanyLocationStore'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { TollFreeRoutes } from 'pages/settings/pages/compliance/pages/tollFree'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'
import { BusinessProfileNavigationStore } from './businessProfileNavigationStore'

const titleMap = {
  [BusinessProfileRoutes.root]: '',
  [BusinessProfileRoutes.companyLocation]: 'Company location',
  [BusinessProfileRoutes.businessInfo]: 'Business info',
  [BusinessProfileRoutes.generalInfo]: 'General info',
  [BusinessProfileRoutes.peopleToContact]: 'People to contact',
  [BusinessProfileRoutes.termsAndPrivacy]: 'Terms and Privacy',
}

export class BusinessProfileStepsStore {
  private readonly _businessProfileNavigationStore: BusinessProfileNavigationStore

  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    makeAutoObservable(this)

    this._businessProfileNavigationStore = new BusinessProfileNavigationStore(
      this._complianceLayerStore
    )
    this.init()
  }

  private _generalInfoStore = new GeneralInfoStore(this)
  private _businessInfoStore = new BusinessInfoStore(this)
  private _peopleToContactStore = new PeopleToContactStore(this)
  private _companyLocationStore = new CompanyLocationStore(this)

  private _disposePageReaction: IReactionDisposer | null = null
  private _disposeChangeStep: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _states: IResponseCountryState[] = []
  private _generalInfoActiveTab = 1
  private _isTermsAndPrivacyChecked = false
  private _fieldsError: Record<string, string> | null = null

  private _modalIdDoNotHaveEI = 'modal_id_do_not_have_EIN'

  private _errors: IResponseTwilioServiceError[] = []

  get businessProfileNavigationStore() {
    return this._businessProfileNavigationStore
  }

  get complianceLayerStore() {
    return this._complianceLayerStore
  }

  get companyLocationStore() {
    return this._companyLocationStore
  }

  get generalInfoStore() {
    return this._generalInfoStore
  }

  get peopleToContactStore() {
    return this._peopleToContactStore
  }

  get businessInfoStore() {
    return this._businessInfoStore
  }

  get generalInfoActiveTab() {
    return this._generalInfoActiveTab
  }

  get states(): IDropdownItem[] {
    return this._states.map((item) => ({ label: item.name, id: item.id }))
  }

  get isTermsAndPrivacyChecked() {
    return this._isTermsAndPrivacyChecked
  }

  get stepLoading() {
    return this._complianceLayerStore.stepsStore.stepLoading
  }

  get errors() {
    return this._errors
  }

  get errorMessage() {
    return this.errors.map((error) => error.error_message).join('\n')
  }

  get fieldsError() {
    return this._fieldsError
  }

  get fieldsErrorCommonText() {
    if (this.fieldsError && Object.keys(this.fieldsError).length) {
      return Object.values(this.fieldsError).map((error, index) => (
        <div key={index} style={{ marginBottom: '8px' }}>
          {error}
        </div>
      ))
    }

    return null
  }

  get allPaths() {
    return Object.values(BusinessProfileRoutes)
  }

  init = () => {
    const { businessInformation } = this.complianceLayerStore
    this._generalInfoStore.setEinSearch(businessInformation?.registration_number || '')
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  getStep = (path: BusinessProfileRoutes, direction: -1 | 1): BusinessProfileRoutes | '' => {
    const allPaths = this.allPaths
    const currentIndex = allPaths.indexOf(path)
    const nextIndex = currentIndex + direction

    if (currentIndex !== -1 && nextIndex >= 0 && nextIndex < allPaths.length) {
      return allPaths[nextIndex]
    }

    return path
  }

  getLastStep = (path: BusinessProfileRoutes): BusinessProfileRoutes | '' => {
    return this.getStep(path, -1)
  }

  getNextStep = (path: BusinessProfileRoutes): BusinessProfileRoutes | '' => {
    return this.getStep(path, 1)
  }

  getSettingsStep = (path: BusinessProfileRoutes) => {
    const allPaths = this.allPaths

    return allPaths.indexOf(path)
  }

  deleteErrorValue = (keyName: string) => {
    if (this.fieldsError) {
      delete this.fieldsError[keyName]
    }
  }

  setFieldsError = (fieldError: Record<string, string> | null) => {
    this._fieldsError = fieldError
  }

  setStepLoading = (value: boolean) => {
    this.complianceLayerStore.stepsStore.setStepLoading(value)
  }

  handleClose = (modalId: string) => {
    modalStore.removeModal(modalId)
  }

  openDoNotHaveEINModal = () => {
    const title = this._companyLocationStore.isUsaLocation
      ? 'No United States EIN?'
      : 'No Canadian Business Number?'
    const desc = this._companyLocationStore.isUsaLocation
      ? 'If you don’t have a United States EIN, your only eligible option is to use toll-free numbers'
      : 'If you don’t have a Canadian Business Number, your only eligible option is to use toll-free numbers'

    modalStore.addModal({
      id: this._modalIdDoNotHaveEI,
      type: ModalTypeList.INFO,
      onClose: () => {
        this.handleClose(this._modalIdDoNotHaveEI)
      },
      title,
      desc,
      primaryAction: {
        text: 'Continue with toll-free',
        onAction: async () => {
          uiStore.changeRouteViaVue({
            path: `${CompliancePath.compliance.root}/${TollFreeRoutes.root}`,
          })
          modalStore.removeModal(this._modalIdDoNotHaveEI)
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => modalStore.closeModal(this._modalIdDoNotHaveEI),
      },
    })
  }

  setStates = (states: IResponseCountryState[]) => {
    this._states = states
  }

  loadBusinessProfileStates = async () => {
    try {
      const { data } = await ComplianceApi.getBpStates()

      const states = data.filter(
        (item) => item.country_id === this._companyLocationStore.companyLocation
      )

      this.setStates(states)
    } catch (e) {
      logger.error(e)
    }
  }

  setGeneralInfoActiveTab = (value: number) => {
    this._generalInfoActiveTab = value
  }

  changeGeneralInfoTab = (value: number) => {
    const isEinNumberExist = this.complianceLayerStore.businessInformation?.registration_number

    if (!isEinNumberExist && value === 1) {
      this._generalInfoStore.resetData()
    }
    this.setGeneralInfoActiveTab(value)
  }

  toggleIsTermsAndPrivacyChecked = () => {
    this._isTermsAndPrivacyChecked = !this._isTermsAndPrivacyChecked
  }

  getBusinessInformation = (step: number) => {
    const { einItem } = this._generalInfoStore

    const { id: organizationId } = organizationStore || {}

    if (!einItem || !organizationId || !this._companyLocationStore.companyLocation) return null

    const {
      websiteUrl,
      noWebsiteUrl,
      activeRegionOfOperationIds,
      activeBusinessTypeId,
      socialMediaUrl,
    } = this._businessInfoStore

    const getFormStep = (): string => {
      return `form_step_${step}`
    }

    return {
      business_information: {
        business_name: einItem.name,
        business_type_id: activeBusinessTypeId,
        industry_id: this._businessInfoStore.activeBusinessIndustryId || 0,
        city: einItem.city,
        country: this._companyLocationStore.companyLocation,
        organization_id: organizationId,
        regions_of_operation: activeRegionOfOperationIds,
        reg_progress_status: getFormStep(),
        registration_number: this._generalInfoStore.einFromSearch,
        irs_ein_document: this._generalInfoStore.irsEinAttachment,
        registration_type_id: this._companyLocationStore.isUsaLocation ? 2 : 1,
        state_id: einItem.state,
        street_address_1: einItem.street1,
        street_address_2: einItem.street2 || null,
        website_url: noWebsiteUrl ? null : websiteUrl,
        without_website: noWebsiteUrl,
        social_media_url: noWebsiteUrl ? socialMediaUrl : null,
        zip_code: einItem.zip,
      },
      step,
    }
  }

  prepareFieldsErrorAndSave = (data: Record<string, string>) => {
    const result = {} as Record<string, string>

    Object.keys(data).forEach((key) => {
      let value = data[key]

      if (Array.isArray(value)) {
        value = value[0]
      }

      if (key.startsWith('business_information.') || key.startsWith('points_of_contact')) {
        const newKey = key.replace('business_information.', '').replace('points_of_contact.', '')
        result[newKey] = value
      } else {
        result[key] = value
      }
    })

    this.setFieldsError(result)
  }

  submitTermsAndPrivacy = async () => {
    try {
      this.setStepLoading(true)

      const { data } = await ComplianceApi.setBpTerms({
        terms_accepted: this.isTermsAndPrivacyChecked,
        step: 5,
      })

      this.complianceLayerStore.setOrganizationBusinessProfile(
        new OrganizationBusinessProfile(data)
      )

      uiStore.changeRoute({
        path: `/${SettingsPath.compliance.root}`,
      })
    } catch (e) {
      logger.error(e)
    } finally {
      this.setStepLoading(false)
    }
  }

  getProgressInfoTitle: (pathname: BusinessProfileRoutes) => string = (pathname) => {
    return titleMap[pathname]
  }

  setDataFromBusinessProfileRequest = ({
    businessInformation,
    pointsOfContact,
  }: OrganizationBusinessProfile) => {
    if (businessInformation) {
      this.complianceLayerStore.setBusinessInformation(businessInformation.origin)
      this._companyLocationStore.setCompanyLocation(businessInformation.country as ICompanyLocation)
      this._generalInfoStore.setEinItem({
        city: businessInformation.city,
        ein: businessInformation.registration_number,
        ein_formatted: businessInformation.registration_number,
        name: businessInformation.business_name,
        state: businessInformation.state_id,
        street1: businessInformation.street_address_1,
        street2: businessInformation.street_address_2,
        zip: businessInformation.zip_code,
      })

      this._generalInfoStore.setEinSearch(businessInformation.registration_number)
      this._generalInfoStore.setIrsEinAttachment(businessInformation.irs_ein_document)
      businessInformation.irs_ein_document &&
        this._generalInfoStore.setIrsEinAttachment(businessInformation.irs_ein_document)

      this._businessInfoStore.setBusinessIndustry(businessInformation.industry_id)
      this._businessInfoStore.setBusinessType(businessInformation.business_type_id)
      this._businessInfoStore.setActiveRegionOfOperationIds(
        businessInformation.regions_of_operation.map(({ id }) => id)
      )
      this._businessInfoStore.setWebsiteUrl(
        businessInformation.website_url,
        Boolean(businessInformation.website_url?.length)
      )
      this._businessInfoStore.setNoWebsiteUrl(Boolean(businessInformation.social_media_url))
      this._businessInfoStore.setSocialMediaUrl(businessInformation.social_media_url || null)
    }

    if (pointsOfContact) {
      const firstContact = pointsOfContact.firstContact
      const secondContact = pointsOfContact.secondContact

      if (firstContact) {
        this._peopleToContactStore.setPrimaryFirstName(firstContact.first_name)
        this._peopleToContactStore.setPrimaryLastName(firstContact.last_name)
        this._peopleToContactStore.setPrimaryBusinessTitle(firstContact.business_title)
        this._peopleToContactStore.setPrimaryJobPosition(Number(firstContact.job_position_id))
        this._peopleToContactStore.setPrimaryEmail(firstContact.email)
        this._peopleToContactStore.phoneInputStore.setNumber(firstContact.phone_number)
      }

      if (secondContact) {
        this._peopleToContactStore.setSecondContact(true)
        this._peopleToContactStore.setSecondaryFirstName(secondContact.first_name)
        this._peopleToContactStore.setSecondaryLastName(secondContact.last_name)
        this._peopleToContactStore.setSecondaryBusinessTitle(secondContact.business_title)
        this._peopleToContactStore.setSecondaryJobPosition(Number(secondContact.job_position_id))
        this._peopleToContactStore.setSecondaryEmail(secondContact.email)
        this._peopleToContactStore.secondPhoneInputStore.setNumber(secondContact.phone_number)
      }
    }
  }

  setErrors = (errors: IResponseTwilioServiceError[] | undefined) => {
    if (errors) {
      this._errors = errors
    }
  }

  decreaseStep = (path: BusinessProfileRoutes) => {
    const lastStep = this.getLastStep(path)

    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.businessProfile.root}/${lastStep}`,
    })
  }

  closeSetting = () => {
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.businessProfile.root}`,
    })
  }
}
