import { observer } from 'mobx-react-lite'
import { MouseEventHandler } from 'react'
import { makeTextFieldError, TextFieldPlaceholder } from 'shared/ui/TextField'
import { Icon, ITooltipProps, Tooltip } from 'shared/ui'
import { EnrollmentPreview } from 'widgets/ContactEnrollment/ui/EnrollmentList'
import { EnrollmentFilters } from 'widgets/ContactEnrollment/models/EnrollmentFilters'
import styles from './styles.module.scss'

export type IEnrolmentStepProps = {
  tooltipLabel: ITooltipProps['label']
  enrollmentFilters: EnrollmentFilters
  error?: string | null
  onClick: MouseEventHandler<HTMLLabelElement>
  errorPlacement?: ITooltipProps['placement']
}

export const EnrolmentStep = observer(
  ({
    tooltipLabel,
    enrollmentFilters,
    error,
    onClick,
    errorPlacement = 'bottom',
  }: IEnrolmentStepProps) => {
    return (
      <div className={styles.container}>
        <span className={styles.title}>
          Enroll Your Contacts
          <Tooltip label={tooltipLabel} placement={'top'} breakWord>
            <Icon icon={'question'} color={'var(--content-neutral-primary)'} />
          </Tooltip>
        </span>
        <TextFieldPlaceholder
          className={styles.placeholder}
          variant='outline'
          size='small'
          onClick={onClick}
          placeholder={
            <div className={styles.preview}>
              {!!enrollmentFilters.allSize ? (
                <EnrollmentPreview filters={enrollmentFilters} />
              ) : (
                'Enroll contacts'
              )}
            </div>
          }
          rightActions={[
            makeTextFieldError({ placement: errorPlacement, anchor: 'container', offset: [0, 0] }),
          ]}
          error={error}
        />
      </div>
    )
  }
)
