import { useEffect, FC } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useMenuAIAutomationLayoutContext } from 'widgets/Menu/ui/MenuAIAutomation'
import {
  KnowledgeBaseViewProvider,
  useKnowledgeBaseViewContext,
} from 'pages/settings/pages/knowledgeBase/context/knowledgeBaseViewContext'
import { useKnowledgeBaseViewEventDocumentUpload } from 'pages/settings/pages/knowledgeBase/events/useKnowledgeBaseViewDocumentUpload'

const KnowledgeBasePageLayoutDefaultContent = () => {
  const store = useKnowledgeBaseViewContext()

  useKnowledgeBaseViewEventDocumentUpload(store)
  useEffect(() => {
    store.loadKnowledgeBaseViewInitialData()
  }, [])

  return <Outlet />
}

const KnowledgeBasePageLayoutCollapseContent = () => {
  const menuLayoutStore = useMenuAIAutomationLayoutContext()
  const store = useKnowledgeBaseViewContext()

  useKnowledgeBaseViewEventDocumentUpload(store)
  useEffect(() => {
    store.loadKnowledgeBaseViewInitialData()
    store.setPageLayoutStore(menuLayoutStore.pageLayoutStore)
  }, [])

  return <Outlet />
}

export type IKnowledgeBasePageLayoutProps = {
  type?: 'default' | 'collapse'
}

export const KnowledgeBasePageLayout: FC<IKnowledgeBasePageLayoutProps> = observer(({ type }) => {
  if (type === 'collapse') {
    return (
      <KnowledgeBaseViewProvider>
        <KnowledgeBasePageLayoutCollapseContent />
      </KnowledgeBaseViewProvider>
    )
  }

  return (
    <KnowledgeBaseViewProvider>
      <KnowledgeBasePageLayoutDefaultContent />
    </KnowledgeBaseViewProvider>
  )
})
