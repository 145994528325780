import React, { ReactNode } from 'react'
import type { Property } from 'csstype'
import type {
  IButtonProps,
  IDropdownProps,
  IIconButtonProps,
  IIconsVector,
  ITooltipProps,
} from 'shared/ui'
import { IconButton } from 'shared/ui/IconButton'
import { Dropdown } from 'shared/ui/Dropdown'
import { Action } from 'shared/ui/Actions/Action'
import { ActionsCustomComponent } from 'shared/ui/Actions/ActionsCustomComponent'
import { getAriaLabel, truthy } from 'shared/lib'

import styles from './styles.module.scss'

export type ActionItem = {
  text?: string
  onAction?: () => Promise<void> | void
  icon?: IIconsVector
  iconButtonComponent?: ReactNode
  buttonComponent?: ReactNode
  dropdownItemComponent?: (onCloseMenu?: () => void) => ReactNode
  medium?: boolean
  buttonProps?: IButtonProps
  iconButtonProps?: IIconButtonProps
  link?: string
  navLink?: string
  disabled?: boolean
}

type IVariantActionsProps = 'headerMedium' | 'headerSmall' | 'table'

export type IActionsProps = {
  actions: Array<ActionItem | false | undefined | null>
  showItems?: number
  isStrictShowItems?: boolean
  moreDropdownComponent?: ((onCloseMenu?: () => void) => ReactNode) | null
  setOnCloseMoreDropdown?: (fn: () => void) => void
  onToggle?: (value: boolean) => void
  moreActionsTooltipProps?: ITooltipProps
  placementDropdown?: IDropdownProps['placement']
  iconButtonProps?: Partial<IIconButtonProps>
  commonButtonProps?: Partial<IButtonProps>
  gap?: number
  justifyContent?: Property.JustifyContent
  noVisibleActions?: boolean
  onChangeOpen?: ((status: boolean) => void) | undefined
  isButton?: boolean
  parentsDropdownId?: string[]
  dropdownAriaLabel?: string
  noTooltip?: boolean
  disabled?: boolean
}

export const variantActionsProps: { [key in IVariantActionsProps]: Partial<IActionsProps> } = {
  headerMedium: {
    isButton: true,
    gap: 8,
    showItems: 2,
    iconButtonProps: {
      variant: 'contained',
      ariaLabel: getAriaLabel('Actions'),
    },
  },
  headerSmall: {
    isStrictShowItems: true,
    isButton: true,
    gap: 8,
    showItems: 3,
    commonButtonProps: {
      size: 'small',
      contained: 'tertiary',
    },
    iconButtonProps: {
      color: 'tertiary',
      size: 'small',
      variant: 'contained',
      ariaLabel: getAriaLabel('Actions'),
    },
  },
  table: {
    showItems: 0,
  },
}

export type MoreDropdownProps = {
  onCloseMenu?: () => void
}

export const Actions: React.FC<IActionsProps> = (props) => {
  const {
    actions: propsActions,
    showItems = 2,
    moreDropdownComponent,
    setOnCloseMoreDropdown,
    onToggle,
    placementDropdown = 'bottom-end',
    iconButtonProps = null,
    gap,
    justifyContent,
    noVisibleActions,
    onChangeOpen,
    isButton,
    parentsDropdownId,
    dropdownAriaLabel,
    commonButtonProps,
    isStrictShowItems,
    noTooltip = false,
    disabled = false,
    moreActionsTooltipProps,
  } = props

  const actionsCount = noVisibleActions ? 0 : showItems

  const actions: ActionItem[] = propsActions.filter(truthy)

  const visibleActions = actions.slice(0, actionsCount)
  const moreActions = actions.slice(actionsCount)
  if (moreActions.length === 1 && !noVisibleActions && !isStrictShowItems) {
    visibleActions.push(moreActions[0])
  }
  const actionsIconButtonProps: IIconButtonProps = {
    fontSize: 20,
    variant: 'icon',
    size: 'medium',
    ariaLabel: 'Actions_showActions',
    ...iconButtonProps,
  }
  const actionsTooltipProps: ITooltipProps | undefined = noTooltip
    ? undefined
    : {
        label: 'Show actions',
        placement: 'top-end',
        ...moreActionsTooltipProps,
      }
  if (disabled) {
    return (
      <div className={styles.wrapActions} style={{ gap, justifyContent }}>
        <IconButton
          tag='span'
          icon={'moreHorizontal'}
          tooltipProps={actionsTooltipProps}
          disabled
          {...actionsIconButtonProps}
        />
      </div>
    )
  }

  return (
    <div className={styles.wrapActions} style={{ gap, justifyContent }}>
      {visibleActions.map((action, index) => (
        <Action
          key={index}
          fromDropdown={false}
          action={action}
          isButton={isButton}
          commonButtonProps={commonButtonProps}
        />
      ))}
      {(moreActions.length > 1 ||
        (moreActions.length === 1 && (noVisibleActions || isStrictShowItems))) && (
        <Dropdown
          parentsDropdownId={parentsDropdownId}
          onToggle={onToggle}
          icon={'moreHorizontal'}
          tooltipProps={actionsTooltipProps}
          onChangeOpen={onChangeOpen}
          iconButtonProps={actionsIconButtonProps}
          placement={placementDropdown}
          customComponent={(onCloseMenu) => (
            <ActionsCustomComponent
              onCloseMenu={onCloseMenu}
              moreActions={moreActions}
              moreDropdownComponent={moreDropdownComponent}
              setOnCloseMoreDropdown={setOnCloseMoreDropdown}
            />
          )}
          ariaLabel={dropdownAriaLabel || 'Actions'}
        />
      )}
    </div>
  )
}
