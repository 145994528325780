import { createContext, useContext } from 'react'
import { type CampaignDetailStore } from '../store/CampaignDetailStore'

export const CampaignDetailContext = createContext<CampaignDetailStore | null>(null)

export const useCampaignDetailContext = () => {
  const context = useContext(CampaignDetailContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CampaignDetailContext')

  return context
}
