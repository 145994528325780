import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import { Button, Dropdown, IDropdownItem, Tooltip, Typography } from 'shared/ui'
import type { IResponseGetHubspotContactsList } from 'entities/Integrations'
import styles from './styles.module.scss'

export type IHubspotListDropdownProps = {
  allLists: IResponseGetHubspotContactsList[]
  setList: (item: IDropdownItem) => void
  setAllLists: () => void
  isListsEmpty: boolean
  isImportedListsEmpty: boolean
}

export const HubspotListDropdown = observer(
  ({ allLists, setList, isListsEmpty, isImportedListsEmpty }: IHubspotListDropdownProps) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false)
    const [search, onSearch] = useState('')

    const filteredLists = useMemo(() => {
      if (!search) return allLists
      const localSearch = search.toLocaleLowerCase()

      return allLists.filter(({ name }) => name.toLocaleLowerCase().includes(localSearch))
    }, [allLists, search])

    return (
      <Tooltip
        label={
          isImportedListsEmpty && isListsEmpty ? 'You have no lists' : 'You have added all lists'
        }
        placement={'top'}
        visibility={isListsEmpty ? 'visible' : 'hidden'}
      >
        <Dropdown
          ariaLabel={'HubspotAddLists'}
          width={264}
          placement={'top-end'}
          show={isDropdownOpen}
          disabled={isListsEmpty}
          triggerComponent={() => (
            <Button
              text='Add list'
              icon='add'
              typeBtn='contained'
              contained='secondary'
              active={isDropdownOpen}
              disabled={isListsEmpty}
            />
          )}
          onToggle={(value) => {
            setDropdownOpen(value)
          }}
          onClose={() => {
            onSearch('')
          }}
          propsDropdownList={{
            onSearch: onSearch,
            search,
            maxHeight: 232,

            notFoundText: 'List doesn’t exist',
            className: styles.dropdownList,
            onChange(item) {
              setList(item)
              setDropdownOpen(!isDropdownOpen)
            },
          }}
          placeholder='Search'
          withSearch
          items={filteredLists.map((item) => ({
            id: item.listId,
            labelWidth: '200px',
            label: item.name,
            value: String(item.size),
            labelRight: (
              <Typography variant={'body-sm-regular'} ariaLabel={'size'} color={'tertiary'}>
                {item.size}
              </Typography>
            ),
          }))}
        />
      </Tooltip>
    )
  }
)
