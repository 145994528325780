import { FC } from 'react'
import { type IIconsVector, Icon, Box, type IBoxProps } from 'shared/ui'
import styles from './styles.module.scss'

type INoResultsFoundProps = {
  label?: string
  icon?: IIconsVector
  boxProps?: IBoxProps
}

export const NoResultsFound: FC<INoResultsFoundProps> = ({
  label = 'No results found',
  icon = 'search',
  boxProps,
}) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      height='100%'
      width='100%'
      {...boxProps}
    >
      <Icon color='var(--content-primary-tertiary)' fontSize={24} icon={icon} />
      <p className={styles.messageText}>{label}</p>
    </Box>
  )
}
