import { uiStore } from 'shared/store/uiStore'
import { Button } from 'shared/ui'
import { links } from 'shared/constants/links'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { callPopUpGlobalStore } from 'widgets/CallPopUp'
import styles from './styles.module.scss'

export const usePowerDialerAvailability = () => {
  const inbox = inboxesStore.currentInbox
  const sharedInboxes = inboxesStore.sharedInboxes
  const sharedInbox = inbox?.type === 'inbox' ? inbox : null

  const countInActiveOutboundCallsInboxes = sharedInboxes.filter(
    (item) => !item.isNumberOutboundCalls
  )
  const countAircallInboxes = sharedInboxes.filter(
    (item) => item.isCallViaAircall || item.is_aircall
  )
  const hasAllAircall = countAircallInboxes.length === sharedInboxes.length
  const hasAllInActiveOutboundCalls =
    countInActiveOutboundCallsInboxes.length === sharedInboxes.length
  const hasCurrentInActiveOutboundCalls = !sharedInbox?.isNumberOutboundCalls

  const hideButton = Boolean(usersStore.user?.isViewOnlyRole || hasAllAircall)

  const isCallOutboundDisabled = sharedInbox
    ? hasCurrentInActiveOutboundCalls
    : hasAllInActiveOutboundCalls
  const disabled =
    isCallOutboundDisabled ||
    callPopUpGlobalStore.hasPowerDialerV2 ||
    !featureFlagsStore.inteliquent_calling
  const isAdminOrOwnerRole =
    usersStore.user?.isAdminRole ||
    usersStore.user?.isOwnerRole ||
    (inbox?.type === 'inbox' && inbox?.ownerId === usersStore.user?.id)

  const getTooltipAvailability = () => {
    if (isCallOutboundDisabled) {
      return (
        <div
          onClick={(event) => {
            event.stopPropagation()
          }}
          className={styles.tooltipContainer}
        >
          {isAdminOrOwnerRole ? (
            <>
              Outbound calling is disabled
              <br />
              <Button
                tag='a'
                size='medium'
                text='Enable it here'
                typeBtn='link'
                className={styles.linkButton}
                onClick={() => {
                  uiStore.changeRoute({
                    path: `/settings/organization/inboxes/all-inboxes/${inbox?.id}/calling`,
                    type: 'vue',
                  })
                }}
              />
            </>
          ) : (
            'Outbound calling is disabled. Contact your admin to enable it.'
          )}
        </div>
      )
    }

    return ''
  }

  const getTooltipInfo = () => {
    return (
      <div
        onClick={(event) => {
          event.stopPropagation()
        }}
        className={styles.tooltipContainer}
      >
        Autodial multiple contacts <br />
        <Button
          tag='a'
          size='medium'
          text='Learn more'
          typeBtn='link'
          href={links.powerDialer}
          target='_blank'
          rel='noreferrer'
          className={styles.linkButton}
        />
      </div>
    )
  }

  return {
    disabled,
    hideButton,
    getTooltipAvailability,
    getTooltipInfo,
  }
}
