import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IStepUiProps = {
  title: string
  isActive: boolean
  setActiveStep: () => void
  leftContent?: ReactNode
  rightContent?: ReactNode
}

export const StepUi = observer(
  ({ title, isActive, setActiveStep, leftContent, rightContent }: IStepUiProps) => {
    return (
      <div
        onClick={setActiveStep}
        className={classNames(styles.wrap, { [styles.active]: isActive })}
      >
        <div className={styles.leftBox}>{leftContent}</div>
        <div className={styles.wrapTitle}>
          <Typography
            ariaLabel={'stepTitle'}
            variant={'body-md-regular'}
            ellipsis
            tooltipProps={{
              label: title,
            }}
            tag={'div'}
          >
            {title}
          </Typography>
        </div>

        <div className={styles.rightContent}>{rightContent}</div>
      </div>
    )
  }
)
