import { type IAvatarInfo } from 'shared/ui'
import { Base } from 'models'
import type { IResponseContactStatistic } from 'entities/ContactStatistic'

export class ContactStatistic extends Base implements IResponseContactStatistic {
  id: number
  color: string | null
  color_index: number | null
  email: string | null
  first_name: string | null
  formatted_number: string | null
  full_name: string | null
  last_name: string | null
  message_sent_at: string | null
  message_received_at: string | null
  message_replied_at: string | null
  message_opted_out_at: string | null
  short_url_last_clicked_at: string | null
  last_converted_at: string | null
  message_failed_reason: string | null
  national_number: string | null
  number: string | null
  organization_id: number
  owner_id: number
  photo_url: string | null
  archived_at: string | null
  isDeleted: boolean
  avatarInfo: IAvatarInfo
  conversation_id: number | null
  created_at: string | null
  is_subscribed: boolean
  enrolled_at: string | null

  constructor(response: IResponseContactStatistic) {
    super(response.id)
    this.id = response.id
    this.color = response.color
    this.color_index = response.color_index
    this.email = response.email
    this.first_name = response.first_name
    this.formatted_number = response.formatted_number
    this.full_name = response.full_name
    this.last_name = response.last_name
    this.message_sent_at = response.message_sent_at
    this.message_received_at = response.message_received_at
    this.message_replied_at = response.message_received_at
    this.message_opted_out_at = response.message_opted_out_at
    this.last_converted_at = response.last_converted_at
    this.short_url_last_clicked_at = response.short_url_last_clicked_at
    this.message_failed_reason = this.transformFailedReason(response.message_failed_reason || '')
    this.national_number = response.national_number
    this.number = response.number
    this.organization_id = response.organization_id
    this.owner_id = response.owner_id
    this.photo_url = response.photo_url
    this.archived_at = response.archived_at
    this.isDeleted = !!response.archived_at
    this.conversation_id = response.conversation_id
    this.created_at = response.created_at
    this.is_subscribed = response.is_subscribed
    this.enrolled_at = response.enrolled_at

    const isPhoneAbbr = !response.first_name && !response.last_name
    this.avatarInfo = this.isDeleted
      ? {
          icon: 'delete',
          color: 'var(--gray-a-15)',
          tooltipProps: {
            placement: 'top',
            label: 'Deleted contact',
          },
        }
      : {
          isPhoneAbbr,
          color: response.color || '',
          firstName: response.first_name || '',
          lastName: response.last_name || '',
          number: response.formatted_number || '',
          image: response.photo_url || '',
        }
  }

  get isDisabled() {
    return this.isDeleted
  }

  transformFailedReason = (error: string): string => {
    const regExp = new RegExp('Learn more <a (.*?)>here</a>')
    return error.replace(regExp, '<a target="_blank" $1>Learn more</a>')
  }
}
