import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { Campaign } from 'entities/Campaign'
import { CampaignOverviewStore } from './store/CampaignOverviewStore'
import { CampaignOverviewContext } from './context/context'
import { CampaignOverviewUi } from './ui/CampaignOverviewUi'

type ICampaignOverviewProps = {
  campaign: Campaign
}

export const CampaignOverview = observer(({ campaign }: ICampaignOverviewProps) => {
  const overviewTableStore = useMemo(() => new CampaignOverviewStore(campaign), [campaign])

  useEffect(() => {
    return overviewTableStore.dispose
  }, [])
  return (
    <CampaignOverviewContext.Provider value={overviewTableStore}>
      <CampaignOverviewUi />
    </CampaignOverviewContext.Provider>
  )
})
