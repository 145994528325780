import { makeAutoObservable, runInAction } from 'mobx'
import { logger } from 'shared/lib'
import {
  type IResponseKnowledgeBase,
  KnowledgeBase,
  KnowledgeBaseApi,
} from 'entities/KnowledgeBase'

export class ChatbotKnowledgeBaseStore {
  private _isKnowledgeBaseInitLoading = false
  private _knowledgeBase: KnowledgeBase | null = null

  constructor() {
    makeAutoObservable(this)
  }

  updateKnowledgeBase = (knowledgeBaseResponse: IResponseKnowledgeBase) => {
    this._knowledgeBase = new KnowledgeBase(knowledgeBaseResponse)
  }

  initKnowledgeBase = async () => {
    try {
      runInAction(() => {
        this._isKnowledgeBaseInitLoading = true
      })
      const { data } = await KnowledgeBaseApi.getKnowledgeBase()
      this.updateKnowledgeBase(data)
    } catch (error) {
      logger.error(error)
    } finally {
      runInAction(() => {
        this._isKnowledgeBaseInitLoading = false
      })
    }
  }

  get isKnowledgeBaseInitLoading() {
    return this._isKnowledgeBaseInitLoading
  }

  get knowledgeBase() {
    return this._knowledgeBase
  }
}
