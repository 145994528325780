import { IDropdownItem } from 'shared/ui'
import { IInboxCombine, inboxesStore } from 'entities/Inbox'

export const typeRename = 'rename'

type IActionRename = {
  inbox: IInboxCombine
  item: IDropdownItem
}

export const menuRename = (): IDropdownItem => {
  return {
    id: typeRename,
    iconL: 'edit',
    label: 'Rename',
  }
}

export const actionRename = async ({ inbox, item }: IActionRename) => {
  if (item.id === typeRename) {
    inboxesStore.handleRenameGroup(inbox, true)
  }
}
