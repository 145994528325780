import { MouseEvent, FC } from 'react'
import { Button } from 'shared/ui'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { useConversationListContext } from 'widgets/ConversationList/context'

type IButtonCloseProps = {
  conversation: Conversation
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  clickCallback?: () => void
}

export const ButtonClose: FC<IButtonCloseProps> = ({
  conversation,
  onMouseEnter,
  onMouseLeave,
  clickCallback,
}) => {
  const store = useConversationListContext()
  const { onOpen, onManualClose } = store

  const handleClick = async (e: MouseEvent) => {
    e.stopPropagation()

    clickCallback?.()

    await (conversation.isClosed ? onOpen : onManualClose)(conversation.id)
  }

  return (
    <Button
      fontWeight={400}
      onClick={handleClick}
      typeBtn='menuItem'
      icon={conversation.isClosed ? 'back' : 'check1'}
      text={conversation.isClosed ? 'Reopen' : 'Close'}
      fullWidth
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}
