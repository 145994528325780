// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wY4cv1NlfE_FR8nombmq{margin-top:24px;display:flex;flex-direction:column;padding-top:4px;padding-inline:16px;flex-grow:1;padding-bottom:24px}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/compliance/pages/TenDLCDetails/ui/TenDLCLogTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CACA,mBAAA,CACA,WAAA,CACA,mBAAA","sourcesContent":[".wrap {\n  margin-top: 24px;\n  display: flex;\n  flex-direction: column;\n  padding-top: 4px;\n  padding-inline: 16px;\n  flex-grow: 1;\n  padding-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wY4cv1NlfE_FR8nombmq"
};
export default ___CSS_LOADER_EXPORT___;
