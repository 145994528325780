// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xwZNkZbsW4V4ATl15wGp{display:flex;flex-direction:column}.xwZNkZbsW4V4ATl15wGp.SVG3hBL6NdCwVS5l0yUi{padding:12px}.xwZNkZbsW4V4ATl15wGp .zcDZRWC62licz8_0VGBS{padding:12px;padding-top:0}.xwZNkZbsW4V4ATl15wGp .YYdWuesHtGweASbnnGYs{margin-top:20px;display:flex}.xwZNkZbsW4V4ATl15wGp .YYdWuesHtGweASbnnGYs button{position:relative;margin-left:-12px;z-index:1}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsNotesForm/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAEA,2CACE,YAAA,CAGF,4CACE,YAAA,CACA,aAAA,CAGF,4CACE,eAAA,CACA,YAAA,CAEA,mDACE,iBAAA,CACA,iBAAA,CACA,SAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n\n  &.fullView {\n    padding: 12px;\n  }\n\n  .messageField {\n    padding: 12px;\n    padding-top: 0;\n  }\n\n  .showMore {\n    margin-top: 20px;\n    display: flex;\n\n    button {\n      position: relative;\n      margin-left: -12px;\n      z-index: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "xwZNkZbsW4V4ATl15wGp",
	"fullView": "SVG3hBL6NdCwVS5l0yUi",
	"messageField": "zcDZRWC62licz8_0VGBS",
	"showMore": "YYdWuesHtGweASbnnGYs"
};
export default ___CSS_LOADER_EXPORT___;
