import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { IDropdownItem } from 'shared/ui'
import { logger, numberFormat } from 'shared/lib'
import { BillingApi, billingStore } from 'entities/Billing'
import { IParamsAutorecharge } from 'entities/Billing/api/types'
import {
  AutoRechargeModalAction,
  AutoRechargeModalContent,
  IAutoRechargeWidgetStoreOptions,
} from 'widgets/AutoRechargeModal'
import { DropdownCardsStore } from 'widgets/DropdownCards'

export class AutoRechargeWidgetStore {
  constructor(options?: IAutoRechargeWidgetStoreOptions) {
    this._options = options || null

    makeAutoObservable(this)
  }

  private _options: IAutoRechargeWidgetStoreOptions | null = null
  private _modalId = ''
  private _primaryDropdownCardsStore = new DropdownCardsStore({
    labelText: 'Payment method',
    enableAch: true,
  })
  private _backupDropdownCardsStore = new DropdownCardsStore({
    labelText: 'Backup payment method',
    labelTooltip: {
      label: 'Use this card if the primary one fails',
      placement: 'top',
    },
    withoutDefaultPresetCard: true,
  })
  private _threshold_in_credits = billingStore.autorecharge?.threshold_in_credits || 100
  private _amount_in_credits = billingStore.autorecharge?.amount_in_credits || 1000
  private _amountItems: IDropdownItem[] = []

  private get params(): IParamsAutorecharge {
    return {
      source: this._primaryDropdownCardsStore.activeId || null,
      backup: this._backupDropdownCardsStore.activeId || null,
      amount: this._amount_in_credits,
      threshold: this._threshold_in_credits,
      is_active: true,
    }
  }
  private getAdditionalCreditPrice = async () => {
    try {
      const { data } = await BillingApi.getAdditionalCreditPrice()
      this._amountItems = data.map((item) => ({
        id: item.credits,
        label: numberFormat({ value: item.credits }),
        labelBrackets: numberFormat({ value: item.price, currency: 'USD' }),
        selectedLabelRight: numberFormat({ value: item.price, currency: 'USD' }),
      }))
    } catch (e) {
      logger.error(e)
    }
  }

  get disabledAutoRecharge() {
    return !this._primaryDropdownCardsStore?.activeId
  }

  onEnableAutoRecharge = async () => {
    billingStore.autorecharge?.handleUpdateBillingAutoRecharge(this.params, this.closeModal)
  }

  init = () => {
    this.getAdditionalCreditPrice()
    this._threshold_in_credits = billingStore.autorecharge?.threshold_in_credits || 100
    this._amount_in_credits = billingStore.autorecharge?.amount_in_credits || 1000
    this._primaryDropdownCardsStore.setActiveId(billingStore.autorecharge?.source || '')
    this._backupDropdownCardsStore.setActiveId(billingStore.autorecharge?.backup || '')
  }

  openModal = () => {
    this.init()
    this._modalId = nanoid()

    modalStore.addModal({
      id: this._modalId,
      width: 480,
      title: 'Configure Auto-Recharge',
      ModalActions: AutoRechargeModalAction,
      ModalContent: AutoRechargeModalContent,
      ModalContentProps: {
        autoRechargeWidgetStore: this,
      },
      zIndex: this._options?.zIndex,
    })
  }

  closeModal = () => {
    modalStore.closeModal(this._modalId)
  }

  set_threshold_in_credits = (value: typeof this._threshold_in_credits) => {
    this._threshold_in_credits = value
  }

  set_amount_in_credits = (value: typeof this._amount_in_credits) => {
    this._amount_in_credits = value
  }

  get threshold_in_credits() {
    return this._threshold_in_credits
  }

  get amountItems() {
    return this._amountItems
  }

  get amount_in_credits() {
    return this._amount_in_credits
  }

  get primaryDropdownCardsStore() {
    return this._primaryDropdownCardsStore
  }

  get backupDropdownCardsStore() {
    return this._backupDropdownCardsStore
  }

  get options() {
    return this._options
  }
}
