import { createContext, useContext } from 'react'
import type { PowerDialerPageStore } from 'pages/powerDialer/store/powerDialerPageStore'

export const PowerDialerPageContext = createContext<PowerDialerPageStore | null>(null)

export const usePowerDialerPageContext = () => {
  const context = useContext(PowerDialerPageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with PowerDialerPageContext')

  return context
}
