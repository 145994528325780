import { nanoid } from 'nanoid'
import { ActionItem, showToast, toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import type { PowerDialer } from 'entities/PowerDialer/model/PowerDialer'
import { PowerDialerStatus } from 'entities/PowerDialer/api/types'
import { PowerDialerApi } from 'entities/PowerDialer/api/powerDialer'
import { callPopUpGlobalStore } from 'widgets/CallPopUp'
import { getToastConfigByPowerDialerStatus } from 'widgets/PowerDialer/lib/getToastConfigByPowerDialerStatus'
import { PowerDialerEditorStore } from 'widgets/PowerDialer/ui/PowerDialerEditor/store/powerDialerEditorStore'

const makeStartAction = (powerDialer: PowerDialer): ActionItem => ({
  icon: 'play',
  text: 'Start',
  onAction: async () => {
    try {
      await callPopUpGlobalStore.openPowerDialer(powerDialer)
    } catch (e) {
      logger.error(e)
    }
  },
})

const makeResumeAction = (powerDialer: PowerDialer): ActionItem => ({
  icon: 'play',
  text: 'Resume',
  onAction: async () => {
    try {
      await callPopUpGlobalStore.openPowerDialer(powerDialer)
      showToast(getToastConfigByPowerDialerStatus(PowerDialerStatus.InProgress))
    } catch (e) {
      logger.error(e)
    }
  },
})

const makeStopAction = (powerDialer: PowerDialer): ActionItem => {
  const onStop = () => {
    const id = nanoid()
    modalStore.addModal({
      id,
      type: ModalTypeList.ALERT,
      title: 'Are you sure?',
      desc: "If stopped you won't be able to continue the session",
      primaryAction: {
        text: 'Stop anyway',
        onAction: async () => {
          try {
            await PowerDialerApi.changePowerDialerStatus(powerDialer.id, PowerDialerStatus.Finished)
            showToast(getToastConfigByPowerDialerStatus(PowerDialerStatus.Finished))
            modalStore.closeModal(id)
          } catch (e) {
            logger.error(e)
          }
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(id)
        },
      },
    })
  }
  return {
    icon: 'stop',
    text: 'Stop',
    onAction: onStop,
  }
}

const makeEditAction = (): ActionItem => {
  return {
    icon: 'edit',
    text: 'Edit',
    onAction: () => {},
  }
}

const makeDuplicateAction = (powerDialer: PowerDialer): ActionItem => ({
  icon: 'copy',
  text: 'Duplicate',
  onAction: async () => await new PowerDialerEditorStore().handleDuplicatePowerDialer(powerDialer),
})

const makeDeleteAction = (powerDialer: PowerDialer, onSuccess: () => void): ActionItem => {
  const onDelete = () => {
    const id = nanoid()
    modalStore.addModal({
      id,
      type: ModalTypeList.ALERT,
      title: 'Delete Power Dialer campaign?',
      desc: 'This action cannot be undone',
      primaryAction: {
        text: 'Delete',
        onAction: async () => {
          try {
            await PowerDialerApi.deletePowerDialer(powerDialer.id)
            toastStore.add({
              type: 'success',
              title: 'Power Dialer campaign deleted',
            })
            modalStore.closeModal(id)
            onSuccess && onSuccess()
          } catch (e) {
            logger.error(e)
          }
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(id)
        },
      },
    })
  }
  return {
    icon: 'delete',
    text: 'Delete',
    onAction: onDelete,
  }
}

export const getPowerDialerActions = (
  powerDialer: PowerDialer,
  onSuccessDelete: () => void
): ActionItem[] => {
  const startAction = makeStartAction(powerDialer)
  const resumeAction = makeResumeAction(powerDialer)
  const stopAction = makeStopAction(powerDialer)
  const editAction = makeEditAction()
  const duplicateAction = makeDuplicateAction(powerDialer)
  const deleteAction = makeDeleteAction(powerDialer, onSuccessDelete)

  switch (powerDialer.status) {
    case 'paused':
      return [resumeAction, stopAction, duplicateAction, deleteAction]
    case 'finished':
      return [duplicateAction, deleteAction]
    case 'draft':
      return [startAction, editAction, duplicateAction, deleteAction]
    default:
      return []
  }
}
