import { observer } from 'mobx-react-lite'
import { BreadcrumbsUi, ContainerHeader, Divider, variantActionsProps } from 'shared/ui'
import { CampaignStatus } from 'entities/Campaign/ui/CampaignStatus'
import { CampaignRoutes } from 'pages/campaigns/types'
import { Steps } from 'pages/campaigns/ui/detail/CampaignDetail/ui/Steps/Steps'
import { Content } from 'pages/campaigns/ui/detail/CampaignDetail/ui/Content/Content'
import { useCampaignDetailContext } from 'pages/campaigns/ui/detail/CampaignDetail/context/context'
import styles from './styles.module.scss'

export const CampaignDetailUi = observer(() => {
  const { campaign, pageLayoutStore } = useCampaignDetailContext()
  const { titleIconProps, headerMenuTooltipIconProps, headerMenuIconAction } = pageLayoutStore
  return (
    <>
      <BreadcrumbsUi
        path={`/${CampaignRoutes.root}/${CampaignRoutes.all}`}
        label={'All campaigns'}
      />
      <ContainerHeader
        title={campaign.name}
        titleVariant={'heading-md'}
        actionsProps={{
          ...variantActionsProps['headerMedium'],
          actions: campaign.actions,
        }}
        titleIconProps={titleIconProps}
        actionIconTooltipProps={headerMenuTooltipIconProps}
        titleIconAction={headerMenuIconAction}
        className={styles.header}
      />
      <div className={styles.wrapStatus}>
        <CampaignStatus status={campaign.status} />
      </div>
      <Divider sx={{ marginLeft: '20px' }} />
      <div className={styles.content}>
        <Steps />
        <Content />
      </div>
    </>
  )
})
