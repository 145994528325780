export const links = {
  support: 'https://help.salesmessage.com',
  website: 'https://www.salesmessage.com',
  feedback: 'https://feedback.salesmessage.com',
  featureRequests: 'https://feedback.salesmessage.com/feature-requests',
  failedMessages:
    'https://help.salesmessage.com/en/articles/6458077-understanding-message-statuses?_ga=2.248595143.1147785932.1692521653-61008499.1688465089',
  enableMicrophone:
    'https://help.salesmessage.com/en/articles/5463800-inbound-outbound-calling-with-salesmsg?&_ga=2.106169539.1569855396.1700134216-710223279.1678805118#h_313708a719',
  importYourContacts: 'https://help.salesmessage.com/en/articles/3192286-import-your-contacts',
  useCustomTriggers: 'https://help.salesmessage.com/en/articles/4232459-how-to-use-custom-triggers',
  termsConditions: 'https://www.salesmessage.com/terms-conditions',
  privacyPolicy: 'https://www.salesmessage.com/privacy-policy',
  conversionRevenueTracking:
    'https://help.salesmessage.com/en/articles/6142934-conversion-revenue-tracking',
  dupleMergeLearnMore: 'https://help.salesmessage.com/en/articles/6896247-contact-cleanup',
  smartInboxes: 'https://help.salesmessage.com/en/articles/6188086-smart-inboxes',
  massMessagingBestPracticesGuidelines:
    'https://help.salesmessage.com/en/articles/3279821-mass-messaging-best-practices-guidelines',
  chatbotGuidelines: 'https://help.salesmessage.com/en/articles/9675140-salesmsg-ai-textbot',
  powerDialer: 'https://help.salesmessage.com/en/articles/9706177-salesmsg-power-dialer',
  phoneСheckr: 'https://help.salesmessage.com/en/articles/5803296-phonecheckr',
  broadcastGuidelines:
    'https://help.salesmessage.com/en/articles/3279821-mass-messaging-best-practices-guidelines',
  appStore: 'https://apps.apple.com/us/app/salesmsg-business-texting/id1450828104',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.salesmessage.arcadia.app',
  workflowHelpGuide:
    'https://help.salesmessage.com/en/articles/7851828-new-salesmsg-workflows-beta',
  knowledgeBaseHelpGuide:
    'https://help.salesmessage.com/en/articles/10214182-salesmsg-ai-knowledge-base',
  complianceLearnMoreLink:
    'https://help.salesmessage.com/en/articles/5205228-register-for-a2p-10dlc',
  complianceOptInConsentLearnMoreLink:
    'https://help.salesmessage.com/en/articles/8287513-sms-opt-in-guides',
  complianceCallingLearnMoreLink:
    'https://help.salesmessage.com/en/articles/6010512-trusted-calling-stir-shaken-cnam',
  complianceTollFreeLearnMoreLink:
    'https://help.salesmessage.com/en/articles/6150476-toll-free-message-verification-usa-canada',
  complianceStirShakenPolicy: 'https://sti-ga.atis.org/',
  complianceStirShakenTelecom: 'https://tracebacks.org/',
  keywordsHelpGuide: 'https://help.salesmessage.com/en/articles/6255763-text-to-join-keywords',
  campaignsHelpGuide: 'https://help.salesmessage.com/en/articles/10430570-salesmsg-campaigns',
  hubspotGuide: 'https://help.salesmessage.com/en/articles/3188721-integrating-with-hubspot',
  salesforceGuide: 'https://help.salesmessage.com/en/articles/8261833-integrating-with-salesforce',
  activecampaignGuide:
    'https://help.salesmessage.com/en/articles/3188659-integrating-with-activecampaign',
  infusionsoftGuide:
    'https://help.salesmessage.com/en/articles/3188720-integrating-with-keap-infusionsoft',
  pipedriveGuide: 'https://help.salesmessage.com/en/articles/3193321-integrating-with-pipedrive',
  aircallGuide:
    'https://help.salesmessage.com/en/articles/10462271-integrate-your-messaging-with-aircall',
  zapierGuide: 'https://help.salesmessage.com/en/articles/3354852-integrating-with-zapier',
  makeGuide: 'https://help.salesmessage.com/en/articles/8140892-make-integration',
  slackGuide: 'https://help.salesmessage.com/en/articles/6600379-integration-for-slack',
  intercomGuide: 'https://help.salesmessage.com/en/articles/7436196-new-salesmsg-for-intercom',
  googleGuide:
    'https://help.salesmessage.com/en/articles/5565198-transferring-a-google-voice-number-to-salesmsg',
  webhookGuide: 'https://help.salesmessage.com/en/articles/6884649-salesmsg-webhooks',
  CarrierAndCTIAGuidelines:
    'https://www.ctia.org/the-wireless-industry/industry-commitments/messaging-interoperability-sms-mms',
  quickLinksGuide: 'https://help.salesmessage.com/en/articles/10531943-contact-quick-links,',
  throughputMessageGuide:
    'https://help.salesmessage.com/en/articles/6209896-message-throughput-mps-and-trust-scores-for-a2p-10dlc',
  calendlyGuide: 'https://help.salesmessage.com/en/articles/10834828-integrating-with-calendly',
}

export const HUBSPOT_CHANNEL_INFO_LINK =
  'https://help.salesmessage.com/en/articles/10265193-new-text-from-hubspot-inbox-help-desk-with-custom-channels'
export const INTEGRATION_PREMIUM_DOWNLOAD_LINK =
  'https://appexchange.salesforce.com/appxListingDetail?listingId=ff12551a-f9ad-44bb-a11f-cad4ac0af4d0'
export const SALESFORCE_PREMIUM_DOC =
  'https://help.salesmessage.com/en/articles/8261833-integrating-with-salesforce'
