import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'
import { ContactsMenuStore } from 'pages/contacts/ui/ContactsMenu'
import { SegmentModalStore } from 'pages/contacts/ui/ContactsMenu/ui/SegmentModal'

export class ContactsPageLayoutStore {
  private _pageLayoutStore = new PageLayoutStore()
  private _segmentModalStore = new SegmentModalStore()
  private _contactsMenuStore = new ContactsMenuStore(this._segmentModalStore, this._pageLayoutStore)

  constructor() {
    makeAutoObservable(this)
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get contactsMenuStore() {
    return this._contactsMenuStore
  }

  get segmentModalStore() {
    return this._segmentModalStore
  }

  dispose = () => {}
}
