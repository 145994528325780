import { makeAutoObservable } from 'mobx'
import { IParamsSendFrom } from 'entities/Inbox/api/types'
import type { Phone } from 'entities/Phone/model/Phone'
import { numbersStore } from 'entities/Phone'
import { inboxesStore } from 'entities/Inbox'
import { SendFromDropdownStore } from 'widgets/SendFromDropdown'

export class CampaignSendFromStore {
  sendFromDropdownStore
  constructor() {
    this.sendFromDropdownStore = new SendFromDropdownStore({
      excludeDeprioritizedNumbers: true,
      clearError: () => {
        this.setError('')
      },
    })
    this.sendFromDropdownStore.init()
    makeAutoObservable(this)
  }

  init = async (params: IParamsSendFrom) => {
    await this.sendFromDropdownStore.init(params)
    if (this.number && this.error === 'Please select the inbox to send from') {
      this.setError('')
    }
  }

  get params() {
    return this.sendFromDropdownStore.params
  }

  error = ''
  setError = (value: typeof this.error) => {
    this.error = value
  }

  get number(): Phone | undefined | null {
    const sendFrom = this.sendFromDropdownStore.params
    if (!sendFrom) return

    if (sendFrom.options.number_id) {
      return numbersStore.getItem(sendFrom?.options.number_id)
    }

    if (sendFrom.options.team_id) {
      const inbox = inboxesStore.getItem(sendFrom.options.team_id)

      if (inbox && inbox.type === 'inbox') {
        const numberId = inbox?.sendingOptions?.find(
          (item) => item.key === sendFrom?.options?.smart_option
        )?.number_ids[0]

        return numbersStore.getItem(numberId)
      }
    }
  }

  checkValidate = () => {
    let validate = true
    if (!this.params) {
      validate = false
      this.setError('Please select the inbox to send from')
    }
    return validate
  }
}
