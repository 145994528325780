import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Error } from 'shared/ui'
import { RinglessMessageFieldRecordAudio } from 'widgets/MessageFormFields/RinglessMessageField/ui/RinglessMessageFieldActions/actions/RinglessMessageFieldRecordAudio'
import { RinglessMessageFieldTextToSpeech } from 'widgets/MessageFormFields/RinglessMessageField/ui/RinglessMessageFieldActions/actions/RinglessMessageFieldTextToSpeech'
import { useRinglessMessageFieldContext } from 'widgets/MessageFormFields/RinglessMessageField'
import { RecordAudio } from 'widgets/RecordAudio/RecordAudio'
import { MediaLibraryRinglessSelectButton } from 'widgets/MediaLibrary'
import styles from './styles.module.scss'

export const RinglessMessageFieldActions = observer(() => {
  const { isRecordAudioState, recordAudioStore, error, onAddAttachmentAudio, clearError } =
    useRinglessMessageFieldContext()

  if (isRecordAudioState) {
    return (
      <div className={classNames(styles.recordAudio, styles.active)}>
        <RecordAudio
          store={recordAudioStore}
          cancelBtnType={'button'}
          cancelButtonProps={{ typeBtn: 'text' }}
        />
      </div>
    )
  }

  return (
    <>
      <div
        className={classNames(styles.wrap, {
          [styles.errorState]: error,
        })}
      >
        <RinglessMessageFieldRecordAudio />
        <RinglessMessageFieldTextToSpeech />
        <MediaLibraryRinglessSelectButton
          onAddAttachment={onAddAttachmentAudio}
          onClick={clearError}
        />
      </div>
      {error && <Error type={'text'} error={error} />}
    </>
  )
})
