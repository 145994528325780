import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Breadcrumbs, Alert, Button, ContainerHeader } from 'shared/ui'
import { userPermissionsStore, FeatureRestrictedModalStore } from 'entities/UsersPermissions'
import { useHistoryPageContext } from 'pages/contacts/pages/history/context/historyPageContext'
import { ContactHistoryTable } from './ContactHistoryTable'
import styles from './styles.module.scss'

export const ContactsHistory = observer(() => {
  const contactsHistoryStore = useHistoryPageContext()
  const {
    totalHistory,
    pendingId,
    loadImportHistory,
    getImportContactProgress,
    checkIsExistHistory,
    contactsTagsModalStore,
    importContactsStore,
    pageLayoutStore,
  } = contactsHistoryStore
  const { isImportAllowed, startImportContact, getStatusImportContact, checkIsImportDisabled } =
    importContactsStore
  const { selectedListIds } = contactsTagsModalStore

  useEffect(() => {
    loadImportHistory()
    !isImportAllowed && getStatusImportContact()
  }, [])

  useEffect(() => {
    if (isImportAllowed && pendingId && !checkIsExistHistory(pendingId)) {
      startImportContact(pendingId, selectedListIds, loadImportHistory, getImportContactProgress)
    }
  }, [isImportAllowed])

  const onImport = () => {
    userPermissionsStore.getItem('can_create_contacts')
      ? importContactsStore.openModal()
      : new FeatureRestrictedModalStore()
  }

  const checkImportDisabling = () => {
    return checkIsImportDisabled(totalHistory)
  }

  return (
    <div className={styles.historyContainer}>
      <Breadcrumbs />
      <ContainerHeader
        title='Import history'
        titleVariant={'heading-md'}
        titleIconProps={{
          icon: 'lineCollapseExpandLeft',
          fontSize: 20,
          color: 'var(--content-primary-tertiary)',
        }}
        titleIconAction={pageLayoutStore.handleToggleCollapse}
        actions={[
          {
            iconButtonComponent: (
              <Button
                text='Import'
                icon='upload'
                contained='tertiary'
                disabled={checkImportDisabling()}
                onClick={onImport}
              />
            ),
          },
        ]}
        paddingLeft={20}
      />

      {isImportAllowed === false && totalHistory > 0 && (
        <div className={styles.alertContainer}>
          <Alert
            item={{
              type: 'infoBlue',
              title:
                'Your import is under review, once processed you will be able to add more contacts',
            }}
          />
        </div>
      )}
      <ContactHistoryTable />
    </div>
  )
})
