import { observer } from 'mobx-react-lite'
import { Alert, Button, Typography } from 'shared/ui'
import { highlightUrls } from 'shared/lib'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'
import styles from './styles.module.scss'

export const RegistrationErrorAlert = observer(() => {
  const { complianceLayerStore, learnMoreButtonAction } = useLocalNumbersStepsContext()
  const { complianceServiceBrand, complianceServiceCampaign } = complianceLayerStore

  if (!complianceServiceBrand?.registrationError && !complianceServiceCampaign?.registrationError)
    return null

  const getErrorTitle = () => {
    if (complianceServiceBrand?.registrationError && complianceServiceCampaign?.registrationError) {
      return 'Your brand  and use case campaign application were rejected.'
    }

    return complianceServiceBrand?.registrationError
      ? 'Your brand application was rejected.'
      : 'Your use case campaign application was rejected.'
  }

  return (
    <Alert
      marginTop={20}
      item={{
        type: 'error',
        title: getErrorTitle(),
        desc: (
          <div className={styles.errorsContainer}>
            {complianceServiceBrand?.registrationError?.message && (
              <Typography ariaLabel='ServiceBrandErrorDesc'>
                {highlightUrls(complianceServiceBrand?.registrationError.message)}
              </Typography>
            )}
            {complianceServiceCampaign?.registrationError?.message && (
              <Typography ariaLabel='ServiceCampaignErrorDesc'>
                {highlightUrls(complianceServiceCampaign?.registrationError.message)}
              </Typography>
            )}
          </div>
        ),
      }}
      actions={
        <>
          <Button
            size={'small'}
            text={'Learn more'}
            typeBtn={'outlined'}
            contained={'secondary'}
            onClick={learnMoreButtonAction}
          />
        </>
      }
    />
  )
})
