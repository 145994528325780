import type { IDropdownItem } from 'shared/ui'

export enum IntegrationSyncType {
  oneWay = 'oneWay',
  twoWay = 'twoWay',
}

export const GET_SYNC_TYPE_DICT = (name: string) => ({
  [IntegrationSyncType.oneWay]: `Sync to Salesmsg: ${name} -> Salesmsg`,
  [IntegrationSyncType.twoWay]: 'Two-way sync',
})

export const GET_SYNC_TYPE_OPTIONS = (name: string): IDropdownItem[] => {
  const dict = GET_SYNC_TYPE_DICT(name)
  return [
    { id: IntegrationSyncType.oneWay, label: dict[IntegrationSyncType.oneWay] },
    { id: IntegrationSyncType.twoWay, label: dict[IntegrationSyncType.twoWay] },
  ]
}
