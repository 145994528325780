import { createContext, useContext, FC, ReactNode } from 'react'
import { type LinkContactStore } from 'pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable/ui/LinkContact'

export const LinkContactContext = createContext<LinkContactStore | null>(null)

export const useLinkContactContext = () => {
  const context = useContext(LinkContactContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with LinkContactContext')

  return context
}

type IProviderLinkContactProps = {
  value: LinkContactStore
  children: ReactNode
}

export const ProviderLinkContact: FC<IProviderLinkContactProps> = ({ value, children }) => {
  return <LinkContactContext.Provider value={value}>{children}</LinkContactContext.Provider>
}
