import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { showToast } from 'shared/ui'
import { logger, SingletonRequest } from 'shared/lib'
import { BillingApi } from 'entities/Billing/api/billing'
import { Source } from 'entities/Billing/model/Source'
import { CreditPrice } from 'entities/Billing/model/CreditPrice'
import { AutorechargeStore } from 'entities/Billing/store/autorechargeStore'
import {
  IParamsCreateSource,
  IParamsUpdateBillingCredits,
  IResponseBillingAreaCode,
  IResponseBillingComplianceInfo,
  IResponseBillingSource,
  IResponseBillingState,
} from 'entities/Billing/api/types'
import { BillingPlansStore } from './billingPlansStore'

export class BillingStore {
  private _creditsPriceMap: Map<number, CreditPrice> = new Map()
  private _creditsPriceLoading = false
  private _statesMap: Map<string, IResponseBillingState> = new Map()
  private _areaCodesMap: Map<number, IResponseBillingAreaCode> = new Map()
  private _sourcesMap: Map<string, Source> = new Map()
  private _sourcesLoading = false
  private _autorechargeStore: AutorechargeStore | null = null
  private _autorechargeLoading = false
  private _buyCreditsLoading = false
  private _areaCodesLoading = false
  private _statesLoading = false
  private _billingComplianceInfo: IResponseBillingComplianceInfo | null = null
  private _billingPlansStore = new BillingPlansStore()

  constructor() {
    makeAutoObservable(this)
  }

  get billingComplianceInfo() {
    return this._billingComplianceInfo
  }

  get isBillingComplianceMonthly() {
    return this._billingComplianceInfo?.interval === 'monthly'
  }

  get billingPlansStore() {
    return this._billingPlansStore
  }

  get creditsPriceLoading() {
    return this._creditsPriceLoading
  }

  get sourcesLoading() {
    return this._sourcesLoading
  }

  get autorecharge() {
    return this._autorechargeStore
  }

  get buyCreditsLoading() {
    return this._buyCreditsLoading
  }

  fetchPlans = async () => {
    await this._billingPlansStore.fetchPlans()
  }

  fetchBillingAdditionalCreditPrice = async () => {
    try {
      runInAction(() => {
        this._creditsPriceLoading = true
      })

      const { data } = await BillingApi.getBillingAdditionalCreditPrice()

      runInAction(() => {
        data.forEach((item) => {
          this._creditsPriceMap.set(item.credits, new CreditPrice(item))
        })
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._creditsPriceLoading = false
      })
    }
  }

  applyCoupon = async () => {
    try {
      const currentUrl = window.location.href
      const queryString = currentUrl.split('?')[1]
      const searchParams = new URLSearchParams(queryString)

      if (searchParams.has('c')) {
        const { data } = await BillingApi.applyCoupon(searchParams.get('c') ?? '')
        if (data.error) {
          showToast({
            type: 'error',
            title: data.error,
          })
        } else {
          showToast({
            type: 'success',
            title: 'Coupon successfully applied!',
          })
        }
      }
    } catch (e) {
      showToast({
        type: 'error',
        title: 'Error during coupon processing',
      })
    }
  }

  fetchBillingSources = async () => {
    try {
      runInAction(() => {
        this._sourcesLoading = true
      })

      const { data } = await BillingApi.getBillingSources()

      runInAction(() => {
        data.forEach((item) => {
          this._sourcesMap.set(item.id, new Source(item))
        })
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._sourcesLoading = false
      })
    }
  }

  createSource = async (params: IParamsCreateSource) => {
    try {
      const { data } = await BillingApi.createSource(params)
      if (params.backup) {
        this.setBackupSource(data.id)
      }
      return this.addSource(data)
    } catch (e) {
      logger.error(e)
    }
  }

  addSource = (sourceDate: IResponseBillingSource) => {
    const source = new Source(sourceDate)
    this._sourcesMap.set(source.id, source)
    if (source.primary) {
      this.setPrimarySource(source.id)
    }
    return source
  }

  fetchBillingAreaCodes = async () => {
    try {
      runInAction(() => {
        this._areaCodesLoading = true
      })

      const { data } = await BillingApi.getBillingAreaCodes()

      runInAction(() => {
        data.data.forEach((item) => {
          this._areaCodesMap.set(item.id, item)
        })
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._areaCodesLoading = false
      })
    }
  }

  fetchBillingStates = async () => {
    try {
      runInAction(() => {
        this._statesLoading = true
      })

      const { data } = await BillingApi.getBillingStates()

      runInAction(() => {
        data.data.forEach((item) => {
          this._statesMap.set(item.state_code, item)
        })
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._statesLoading = false
      })
    }
  }

  fetchAutorecharge = async () => {
    try {
      runInAction(() => {
        this._autorechargeLoading = true
      })

      const { data: autorecharge } = await BillingApi.getBillingAutoRecharge()

      runInAction(() => {
        this._autorechargeStore = new AutorechargeStore(autorecharge)
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._autorechargeLoading = false
      })
    }
  }

  private _fetchBillingComplianceInfo = async () => {
    try {
      const { data } = await BillingApi.getBillingComplianceInfo()

      this.setBillingComplianceInfo(data)
    } catch (e) {
      console.log(e)
    }
  }

  fetchBillingComplianceInfo = new SingletonRequest(this._fetchBillingComplianceInfo).request

  updateBillingComplianceInfo = async () => {
    try {
      const { data } = await BillingApi.updateBillingComplianceInfo()

      this.setBillingComplianceInfo(data)
    } catch (e) {
      console.log(e)
    }
  }

  setBillingComplianceInfo = (data: IResponseBillingComplianceInfo) => {
    this._billingComplianceInfo = data
  }

  handleBuyCredits = async (params: IParamsUpdateBillingCredits) => {
    try {
      runInAction(() => {
        this._buyCreditsLoading = true
      })

      return await BillingApi.updateBillingCredits(params)
    } catch (e) {
      logger.error(e)
      if (e instanceof AxiosError) {
        showToast({
          title: e.response?.data?.errors?.payment_method,
          type: 'error',
        })

        return false
      }
    } finally {
      runInAction(() => {
        this._buyCreditsLoading = false
      })
    }
  }

  getCreditsPrice = (id: number) => {
    return this._creditsPriceMap.get(id) || null
  }

  getSources = (id: string) => {
    return this._sourcesMap.get(id) || null
  }

  setPrimarySource = (id: string) => {
    this._sourcesMap.forEach((source) => {
      source.setPrimary(id === source.id)
    })
  }

  setBackupSource = (id: string) => {
    this._autorechargeStore?.setBackup(id)
  }

  get creditsPrice() {
    return Array.from(this._creditsPriceMap.values())
  }

  get sources() {
    return Array.from(this._sourcesMap.values()).sort((source) => (source.isPrimary ? -1 : 1))
  }

  get sourcesSimple() {
    return Array.from(this._sourcesMap.values()).filter((item) => item.object === 'card')
  }

  get sourcesAch() {
    return Array.from(this._sourcesMap.values()).filter((item) => item.object === 'payment_method')
  }

  get states() {
    return Array.from(this._statesMap.values())
  }

  get areaCodes() {
    return Array.from(this._areaCodesMap.values())
  }
}

export const billingStore = new BillingStore()
