// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EZ2oPpPAZiF12_ybhnka{border-bottom:none;padding:0}.w0OOPIroi8RCyYcs1A6a{margin-top:4px}.fGZeQve1XnEB3jyc7mUC{padding-left:15px;box-shadow:inset 0 0 0 1px var(--action-outlined-tertiary-border) !important}.BEP87dMD1AS535ZbUKry{box-shadow:inset 0 0 0 1px var(--content-negative-primary) !important}.OkOKuIrrGkjGYqJSTgEE{padding:8px 12px}", "",{"version":3,"sources":["webpack://./src/shared/ui/SearchDropdownInput/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,SAAA,CAEF,sBACE,cAAA,CAEF,sBACE,iBAAA,CACA,4EAAA,CAEA,sBACE,qEAAA,CAIJ,sBACE,gBAAA","sourcesContent":[".textInput {\n  border-bottom: none;\n  padding: 0;\n}\n.error {\n  margin-top: 4px;\n}\n.input {\n  padding-left: 15px;\n  box-shadow: inset 0 0 0 1px var(--action-outlined-tertiary-border) !important;\n\n  &Error {\n    box-shadow: inset 0 0 0 1px var(--content-negative-primary) !important;\n  }\n}\n\n.dropdownSearchInput {\n  padding: 8px 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textInput": "EZ2oPpPAZiF12_ybhnka",
	"error": "w0OOPIroi8RCyYcs1A6a",
	"input": "fGZeQve1XnEB3jyc7mUC",
	"inputError": "BEP87dMD1AS535ZbUKry",
	"dropdownSearchInput": "OkOKuIrrGkjGYqJSTgEE"
};
export default ___CSS_LOADER_EXPORT___;
