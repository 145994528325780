import React from 'react'
import { observer } from 'mobx-react-lite'
import { Status } from 'shared/ui'
import { type IStatusProps } from 'shared/ui/Status'
import { type ICampaignStatus } from 'entities/Campaign'
import config from './config'

export const CampaignStatus = observer(
  ({
    status,
    emphasis = 'high',
    onlyIcon,
  }: {
    status: ICampaignStatus
    emphasis?: IStatusProps['emphasis']
    onlyIcon?: IStatusProps['onlyIcon']
  }) => status && <Status {...config[status]} emphasis={emphasis} onlyIcon={onlyIcon} />
)
