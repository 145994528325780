import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { toastStore } from 'shared/ui'
import { ModalType, ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import { ContactsApi } from 'entities/Contacts'
import { IResponseFilterSegment } from 'entities/Segment'
import { IResponseShortcodeOptOut } from 'entities/Contacts/api/types'
import { ValidateActions } from '../ui/ValidateActions'
import { ValidateContent } from '../ui/ValidateContent'

type IIModalProps = {
  title: string
  width: number
  type?: ModalType['type']
  desc?: ModalType['desc']
  onClose?: ModalType['onClose']
  secondaryAction?: ModalType['secondaryAction']
  primaryAction?: ModalType['primaryAction']
  ModalActions?: ModalType['ModalActions']
  ModalContent?: ModalType['ModalContent']
}

export class SelectedModalStore {
  modalId = ''
  name = ''
  allCount = 0
  checkedCount = 0
  revalidate = false
  workflowId: string | null = null
  tollFreeActive: boolean | string = false
  shortCodeStatuses: Record<number, boolean> = {}
  bulkAllMode = false
  selectedIds: number[] = []
  search = ''
  filtersList: IResponseFilterSegment[] | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get currentWorkflowId() {
    return this.workflowId
  }

  get isRevalidateCheckboxActive() {
    return this.revalidate
  }

  get tollFreeCondition() {
    return this.tollFreeActive
  }

  get shortCodeConditions() {
    return this.shortCodeStatuses
  }

  reset = () => {
    this.checkedCount = 0
    this.allCount = 0
    this.revalidate = false
    this.tollFreeActive = false
  }

  closeModal = () => {
    this.name = ''

    modalStore.removeModal(this.modalId)
    this.reset()
  }

  handleClose = () => {
    modalStore.removeModal(this.modalId)
    this.reset()
  }

  openModal = (props: IIModalProps) => {
    modalStore.addModal({
      id: this.modalId,
      showHeader: true,
      showCloseButton: false,
      showCloseIcon: true,
      onClose: this.closeModal,
      ...props,
    })
  }

  openValidateModal = async ({
    bulkAllMode = false,
    selectedIds,
    search,
    filtersList = null,
  }: {
    bulkAllMode?: boolean
    selectedIds: number[]
    search: string
    filtersList?: IResponseFilterSegment[] | null
  }) => {
    this.modalId = 'validate_modal'
    this.bulkAllMode = bulkAllMode
    this.selectedIds = selectedIds
    this.search = search
    this.filtersList = filtersList

    const { data } = await ContactsApi.checkLookupStatistic({
      bulk_all: bulkAllMode,
      contact_ids: selectedIds,
      search,
      filtersList: filtersList || [],
    })

    this.allCount = data.all_count || 0
    this.checkedCount = data.checked_count || 0

    this.openModal({
      title: 'Validate',
      width: 540,
      ModalContent: ValidateContent,
      ModalActions: ValidateActions,
    })
  }

  openDeleteModal = async ({
    selectedIdsLength,
    primaryCallback = () => {},
    onCloseCallback = () => {},
  }: {
    selectedIdsLength: number
    primaryCallback?: () => void
    onCloseCallback?: () => void
  }) => {
    this.modalId = 'delete_modal'

    const contactWord = selectedIdsLength > 1 ? 'contacts' : 'contact'

    this.openModal({
      width: 280,
      type: ModalTypeList.ALERT,
      title: `Delete ${selectedIdsLength} ${contactWord}? `,
      desc: 'This action cannot be undone',
      onClose: () => {
        this.closeModal()
        onCloseCallback()
      },
      primaryAction: {
        text: 'Delete',
        onAction: primaryCallback,
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          this.handleClose()
          onCloseCallback()
        },
      },
    })
  }

  toggleRevalidate = () => {
    this.revalidate = !this.revalidate
  }

  validateContacts = async (
    bulkAllMode: boolean,
    selectedIds: number[],
    search: string,
    filtersList?: IResponseFilterSegment[] | null
  ) => {
    const { data } = await ContactsApi.bulkCheck({
      bulk_all: bulkAllMode,
      contact_ids: selectedIds,
      revalidate: this.isRevalidateCheckboxActive,
      filtersList: filtersList || [],
      search,
    })

    if (data.status === 'success') {
      toastStore.add({
        title: 'Contacts validation in progress',
        desc: 'Be patient, processing time depends on the phone numbers volume',
        type: 'success',
      })

      this.reset()
      this.handleClose()
    }
  }

  updateOptIn = async (
    bulkAllMode: boolean,
    selectedIds: number[],
    search: string,
    filtersList?: IResponseFilterSegment[],
    successCallback?: () => void
  ) => {
    const { data } = await ContactsApi.optInCheck({
      bulk_all: bulkAllMode,
      contacts: selectedIds,
      type: 'opt-out',
      search,
      filtersList: filtersList || [],
      opt_outs: {
        toll_free:
          typeof this.tollFreeCondition === 'string' || this.tollFreeCondition === false
            ? false
            : true,
        shortcodes: this.shortCodeConditions,
      },
    })

    if (data) {
      toastStore.add({
        title: 'Contact opt-in status changed',
        type: 'success',
      })

      this.reset()
      this.handleClose()
      successCallback && successCallback()
    }
  }

  deleteContacts = async ({
    bulkAllMode = false,
    ids,
    search,
    filtersList,
    successCallback = () => {},
    finallyCallback = () => {},
  }: {
    bulkAllMode?: boolean
    ids: number[]
    search: string
    filtersList?: IResponseFilterSegment[]
    successCallback?: () => void
    finallyCallback?: () => void
  }) => {
    try {
      const { data } = await ContactsApi.deleteContacts({
        bulk_all: bulkAllMode,
        ids,
        filtersList: filtersList || [],
        search,
      })

      const title = ids.length === 1 ? 'Contact deleted' : 'Contacts deleted'

      if (data) {
        toastStore.add({
          title,
          type: 'success',
        })

        this.reset()
        this.handleClose()
        successCallback()
      }
    } catch (e) {
      logger.error(e)

      return null
    } finally {
      finallyCallback()
    }
  }

  setWorkflowId = (id: string) => {
    this.workflowId = id
  }

  setTollFreeCondition = (condition: boolean | string) => {
    this.tollFreeActive = condition
  }

  initShortCodes = (shortCodes: IResponseShortcodeOptOut[]) => {
    shortCodes.forEach(({ id, value }) => {
      this.shortCodeStatuses[Number(id)] = value
    })
  }

  changeShortCodeCondition = (id: number, condition: boolean) => {
    this.shortCodeStatuses[id] = condition
  }
}

export const selectedModalStore = new SelectedModalStore()
