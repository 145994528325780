import { observer } from 'mobx-react-lite'
import { CampaignOverview } from 'pages/campaigns/ui/detail/CampaignOverview'
import { CampaignStep } from 'pages/campaigns/ui/detail/CampaignStep'
import { useCampaignDetailContext } from 'pages/campaigns/ui/detail/CampaignDetail/context/context'
import styles from './styles.module.scss'

export const Content = observer(() => {
  const { activeStep, campaign } = useCampaignDetailContext()
  return (
    <div className={styles.wrap}>
      {activeStep === null && <CampaignOverview campaign={campaign} />}
      {activeStep !== null && <CampaignStep campaign={campaign} activeStep={activeStep} />}
    </div>
  )
})
