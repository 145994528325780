import { observer } from 'mobx-react-lite'
import { Status } from 'shared/ui'
import type { IStatusProps } from 'shared/ui/Status'
import type { PowerDialerStatus } from 'entities/PowerDialer/api/types'
import { powerDialerStatusBadgeConfig } from 'widgets/PowerDialer/lib/powerDialerStatusBadgeConfig'

interface IPowerDialerStatusProps {
  status: PowerDialerStatus
  onlyIcon?: IStatusProps['onlyIcon']
}

export const PowerDialerStatusBadge = observer(({ status, onlyIcon }: IPowerDialerStatusProps) => (
  <Status {...powerDialerStatusBadgeConfig[status]} onlyIcon={onlyIcon} />
))
