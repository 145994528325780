import { useState } from 'react'
import classNames from 'classnames'
import { ISwitchProps, Switch } from 'shared/ui/Switch'
import { getAriaLabel } from 'shared/lib'

import { CircularProgress, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export interface ISwitchActionProps {
  active: boolean
  onTrigger: () => Promise<void>
  ariaLabel?: string
  disabled?: boolean
  size: ISwitchProps['size']
  label?: string
}

export const SwitchAction = ({
  active,
  onTrigger,
  disabled,
  ariaLabel = '',
  size,
  label,
}: ISwitchActionProps) => {
  const [updating, setUpdating] = useState(false)
  const switchAriaLabel = `SwitchAction${ariaLabel}`

  const getCircularProgressSize = () => {
    if (size === 'middle') {
      return 20
    }
    return 12
  }

  return (
    <div className={classNames(styles.action)}>
      <div
        className={classNames(styles.actionContent, styles[`size__${size}`])}
        onClick={(event) => {
          if (disabled) return
          event.preventDefault()
          event.stopPropagation()

          if (updating) return

          setUpdating(true)
          onTrigger().finally(() => setUpdating(false))
        }}
        aria-label={getAriaLabel(switchAriaLabel, `${active ? 'Active' : 'Disabled'}`)}
      >
        <div className={classNames(styles.actionControl, { [styles.updating]: updating })}>
          {updating && (
            <div className={styles.wrapLoading}>
              <CircularProgress
                size={getCircularProgressSize()}
                variant='indeterminate'
                customColor='var(--light-blue-70)'
                thickness={3}
              />
            </div>
          )}
          <div className={styles.wrapSwitch}>
            <Switch disabled={disabled || false} size={size} value={active} />
          </div>
        </div>
        {label && (
          <Typography ariaLabel={'status'} variant={'body-md-regular'}>
            {label}
          </Typography>
        )}
      </div>
    </div>
  )
}
