import { FC, MouseEvent, useState } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Avatar, Icon, IDropdownItemProps, Tooltip, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export const DropdownCardDefault: FC<IDropdownItemProps> = observer(
  ({ onChange, item, parent, isHover }) => {
    const [hover, setHover] = useState(false)
    const { onClick } = item
    const eventClick = (e: MouseEvent) => {
      e.stopPropagation()

      if (item.disabled) return
      if ((item.children?.length || item?.customChildren) && !item.isCardClick) return

      onClick?.()
      onChange?.(item, parent)
    }

    const onMouseEnter = () => {
      setHover(true)
    }
    const onMouseLeave = () => {
      setHover(false)
    }

    const label = item.labelContent || item.label

    const renderActive = () => {
      const isActiveValueBoolean = typeof item.activeValue === 'boolean'

      if (!isActiveValueBoolean && item.activeValue) {
        return (
          <div className={classnames(styles.icon__right, styles.active)}>
            <div className={styles.activeValue}>{item.activeValue}</div>
          </div>
        )
      }

      return null
    }

    const content = (
      <div
        className={classnames(styles.item, {
          [styles.item__disabled]: item.disabled,
          [styles['item--hover']]: isHover,
          [styles['item-selected']]: item.selected,
          ['hasDropdownCardChild']: parent,
        })}
        style={{
          height: item.height,
        }}
        onClick={eventClick}
      >
        <div className={styles.itemWrap} aria-label={getAriaLabel(item.ariaLabel || '')}>
          {item.iconL && (
            <div className={styles.icon}>
              <Icon
                icon={item.iconL}
                color={
                  item.customFields?.leftIconColor ? String(item.customFields?.leftIconColor) : ''
                }
              />
            </div>
          )}
          {item.avatarProps && (
            <div className={styles.icon}>
              <Avatar {...item.avatarProps} />
            </div>
          )}
          <div
            className={classnames(styles.title, {
              [styles.title__active]: item.activeValue,
              [styles.withRightContent]: item.labelRight,
            })}
            style={{ width: item.labelWidth }}
          >
            {label}
            {item.labelCount && (
              <Typography
                variant={'body-xs-medium'}
                color={'var(--content-neutral-primary)'}
                ariaLabel={'labelCount'}
              >
                {item.labelCount}
              </Typography>
            )}
            {item.labelBrackets && (
              <Typography
                variant={'body-md-regular'}
                color={'var(--content-primary-disabled)'}
                ariaLabel={'labelBrackets'}
              >
                ({item.labelBrackets})
              </Typography>
            )}
            {item.description && (
              <Typography
                ariaLabel={'description'}
                variant={'body-xs-regular'}
                color={'tertiary'}
                tag={'div'}
                className={styles.desc}
              >
                {item.description}
              </Typography>
            )}
          </div>
          {item.iconLabel && (
            <div className={styles.icon__iconLabel}>
              <Icon icon={item.iconLabel} />
            </div>
          )}
        </div>
        <div className={classnames(styles.itemWrap, styles.rightContent)}>
          {renderActive()}
          {item.iconR && (
            <div className={styles.icon__right}>
              <Icon
                icon={item.iconR}
                color={
                  item.customFields?.rightIconColor ? String(item.customFields?.rightIconColor) : ''
                }
              />
            </div>
          )}
          {item.labelRight && !item.tooltipLabelRightProps && (
            <Typography
              variant={item.labelRightVariant || 'body-xs-regular'}
              color={'var(--content-primary-tertiary)'}
              ariaLabel={'labelRight'}
            >
              {item.labelRight}
            </Typography>
          )}
          {item.labelRight && item.tooltipLabelRightProps && (
            <>
              <Tooltip {...item.tooltipLabelRightProps} open={hover}>
                <div className={styles.tooltipLabelRight} />
              </Tooltip>
              <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={styles.wrapLabelRight}
              >
                <Typography
                  variant={'body-xs-regular'}
                  color={'var(--content-primary-tertiary)'}
                  ariaLabel={'labelRight'}
                  ellipsis
                >
                  {item.labelRight}
                </Typography>
              </div>
            </>
          )}
          {item.hotKey && (
            <Typography
              tag={'div'}
              className={styles.hotKey}
              variant={'body-xs-medium'}
              ariaLabel={'hotKey'}
              width={16}
            >
              {item.hotKey}
            </Typography>
          )}
          {item.tooltipQuestionProps && (
            <div className={styles.tooltip}>
              <Tooltip {...item.tooltipQuestionProps}>
                <Icon icon={'question'} {...item.iconQuestionProps} />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    )

    if (item.link) {
      return (
        <a href={item.link} target={'_blank'} rel='noreferrer' className={'noTextDecoration'}>
          {content}
        </a>
      )
    }
    if (item.tooltipProps) {
      return (
        <Tooltip fullWidth {...item.tooltipProps}>
          {content}
        </Tooltip>
      )
    }

    return content
  }
)
