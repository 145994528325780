// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EyicwEx4bURFoIBvZBpB{display:flex;justify-content:space-between;width:100%;font-size:13px;line-height:16px}.oXeQNua1EYp8uqmVHehI{margin-right:8px}.LaD7Mpm3ExLGfe2KQUBK{color:var(--gray-100);text-decoration:underline;text-underline-offset:2px;margin-left:4px}", "",{"version":3,"sources":["webpack://./src/pages/contacts/ui/ImportContacts/ui/ApplyTagsModalActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,6BAAA,CACA,UAAA,CACA,cAAA,CACA,gBAAA,CAEJ,sBACI,gBAAA,CAEJ,sBACI,qBAAA,CACA,yBAAA,CACA,yBAAA,CACA,eAAA","sourcesContent":[".container {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    font-size: 13px;\n    line-height: 16px;\n}\n.backButton {\n    margin-right: 8px;\n}\n.agreedLink {\n    color: var(--gray-100);\n    text-decoration: underline;\n    text-underline-offset: 2px;\n    margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EyicwEx4bURFoIBvZBpB",
	"backButton": "oXeQNua1EYp8uqmVHehI",
	"agreedLink": "LaD7Mpm3ExLGfe2KQUBK"
};
export default ___CSS_LOADER_EXPORT___;
