import { observer } from 'mobx-react-lite'
import { useState, FC } from 'react'
import classNames from 'classnames'
import { MenuItem } from 'shared/ui'
import type { Segment } from 'entities/Segment/model/Segment'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useContactsMenuContext } from 'pages/contacts/ui/ContactsMenu/context/contactsMenuContext'
import { useDropdownItems } from '../../hooks/useDropdownItems'
import styles from './styles.module.scss'

type ISegmentDropdownProps = {
  segment: Segment
}

export const SegmentItem: FC<ISegmentDropdownProps> = observer(({ segment }) => {
  const contactsMenuStore = useContactsMenuContext()
  const { activeSegment } = contactsMenuStore
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const items = useDropdownItems(segment, contactsMenuStore)

  return (
    <MenuItem
      type={'default'}
      label={segment.name}
      icon={segment.hasHubspotFilter && featureFlagsStore.hubspot_pin_list ? 'hubspot' : 'segment'}
      key={segment.id}
      active={activeSegment?.id === segment.id}
      className={classNames(styles.segment, isOpenDropdown && styles.activeDropdown)}
      onClick={() => {
        contactsMenuStore.onActionChangeSegmentAsk(segment)
      }}
      isOpenDropdown={isOpenDropdown}
      items={items}
      dropdownProps={{
        ariaLabel: 'SegmentItem',
        onChangeOpen: setIsOpenDropdown,
        className: styles.moreButton,
        icon: 'moreHorizontal',
        placement: 'bottom-end',
        tooltipProps: {
          label: 'Show actions',
          placement: 'top-end',
        },
      }}
    />
  )
})
