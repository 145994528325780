import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import {
  Button,
  ContainerHeader,
  Icon,
  IconButton,
  ITabItem,
  Tabs,
  Tooltip,
  Typography,
} from 'shared/ui'
import { EnumIconButtonShape } from 'shared/ui/IconButton'
import { layoutStore } from 'shared/layout'
import { IS_APP_LIGHT } from 'shared/config'
import { inboxesStore } from 'entities/Inbox'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { UnifiedInbox } from 'entities/Inbox/model/UnifiedInbox'
import { GroupInbox } from 'entities/Inbox/model/GroupInbox'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { ConversationList, type ConversationListStore } from 'widgets/ConversationList'
import { ConversationHeaderListDropdown } from 'widgets/ConversationHeaderListDropdown'
import { CallHistory, type CallHistoryStore } from 'widgets/CallHistory'
import { type CallModalStore } from 'widgets/CallModal'
import { type ConversationMenuStore } from 'widgets/ConversationMenu'
import { type ConversationSearchStore } from 'widgets/ConversationHeaderSearch/store'
import { type ConversationLayoutStore } from 'widgets/ConversationLayout'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'
import styles from 'widgets/ConversationLayout/ui/styles.module.scss'
import { CallHistoryFilters } from 'widgets/CallHistoryFilters'
import { FeatureRestrictedModalStore } from 'widgets/FeatureRestrictedModal'

type IConversationLayoutProps = {
  callHistoryStore: CallHistoryStore
  callModalStore: CallModalStore
  conversationMenuStore: ConversationMenuStore
  conversationSearchStore: ConversationSearchStore
  conversationLayoutStore: ConversationLayoutStore
  conversationListStore: ConversationListStore
  contactCreateModalStore: ContactCreateModalStore
}

export const ConversationLayout: FC<IConversationLayoutProps> = observer(
  ({
    callHistoryStore,
    callModalStore,
    conversationMenuStore,
    conversationLayoutStore,
    conversationSearchStore,
    conversationListStore,
    contactCreateModalStore,
  }) => {
    const {
      headerMenuIconAction,
      headerMenuTitleIconProps,
      headerMenuTooltipIconProps,
      isCollapse,
    } = conversationMenuStore
    const { isMediumView, isLargeView, isMobileView } = layoutStore
    const { selectInbox } = inboxesStore
    const isShouldRequestAccess = !!usersStore.user?.isShouldRequestAccess
    const { selectTab, activeTab, isShowChats, isShowCalls, showChats } = conversationLayoutStore
    const tabs: ITabItem[] = [
      {
        key: 'chats',
        name: 'Chats',
        icon: 'conversation',
      },
      {
        key: 'calls',
        name: 'Calls',
        icon: 'phone',
      },
    ]

    const subtitle = useMemo(() => {
      if (selectInbox instanceof UnifiedInbox) {
        return 'All numbers'
      }
      let formatedNumbers: string[] = []
      if (selectInbox instanceof GroupInbox) {
        formatedNumbers = inboxesStore.getFormatedNumbersByTeamIds(selectInbox.teamsIds)
      }
      if (selectInbox instanceof Inbox) {
        formatedNumbers = selectInbox.formatedNumbers
      }
      return formatedNumbers.length === 1 ? formatedNumbers[0] : `${formatedNumbers.length} numbers`
    }, [inboxesStore.inboxesList, selectInbox])

    const customTitle = () => {
      if (
        conversationSearchStore.searchDropdownStore.hasFilters ||
        conversationSearchStore.opened
      ) {
        return (
          <Button
            typeBtn={'filter'}
            contained={'secondary'}
            text={'New filter'}
            hasCount
            count={conversationSearchStore.searchDropdownStore.filtersCount}
            icon={conversationSearchStore.opened ? 'chevronUp' : 'chevronDown'}
            size={'extraLarge'}
            badgeProps={{
              size: 'large',
            }}
            onClick={conversationSearchStore.onToggle}
            isRight
          />
        )
      }
      if (isShouldRequestAccess) {
        return 'Request access'
      }

      const titleContent = (
        <div className={styles.titleContent}>
          <Typography
            variant={'body-lg-medium'}
            ellipsis
            className={classnames({
              [styles.customTitle]: customTitle,
            })}
            ariaLabel={'title'}
            color={'var(--content-primary-primary)'}
          >
            {selectInbox?.name}
          </Typography>
          <Icon
            className={styles.ellipse}
            icon={'ellipse_6'}
            fontSize={2}
            color={'var(--content-neutral-primary)'}
          />
          <Typography
            variant={'body-md-regular'}
            ellipsis
            ariaLabel={'sub-title'}
            color={'tertiary'}
          >
            {subtitle}
          </Typography>
        </div>
      )

      if (IS_APP_LIGHT) {
        return (
          <Tooltip label={isCollapse ? 'Show inboxes' : 'Hide inboxes'}>
            <button className={styles.titleBtn} onClick={headerMenuIconAction}>
              <Icon {...headerMenuTitleIconProps} count={inboxesStore.unreadMessagesCount} />
              {titleContent}
            </button>
          </Tooltip>
        )
      } else {
        return <div className={styles.titleContainer}>{titleContent}</div>
      }
    }

    const isHideHeader =
      layoutStore.isMobileView && conversationSearchStore.searchDropdownStore.hasFilters

    return (
      <div className={classnames(styles.wrap)}>
        {!isHideHeader && (
          <ContainerHeader
            customTitle={customTitle()}
            titleIconProps={IS_APP_LIGHT ? undefined : headerMenuTitleIconProps}
            actionIconTooltipProps={IS_APP_LIGHT ? undefined : headerMenuTooltipIconProps}
            titleIconAction={IS_APP_LIGHT ? undefined : headerMenuIconAction}
            ellipsis
            isEmoji={!selectInbox?.isGroupInbox && !selectInbox?.isUnifiedInbox}
            isBorderBottom
            loading={inboxesStore.loading}
            paddingLeft={16}
            ariaLabel='ActiveConversationName'
            actions={
              isLargeView || isMobileView
                ? undefined
                : [
                    {
                      iconButtonComponent: (
                        <IconButton
                          onClick={() => {
                            isShowCalls && showChats()
                            isMediumView
                              ? conversationSearchStore.onOpen()
                              : conversationSearchStore.onToggle()
                          }}
                          icon={'search'}
                          disabled={isShouldRequestAccess}
                          transparentBackground={isShouldRequestAccess}
                          fontSize={20}
                          shape={EnumIconButtonShape.Rounded}
                          color={'secondary'}
                          variant={'icon'}
                          tooltipProps={
                            !isShouldRequestAccess
                              ? {
                                  placement: 'bottom',
                                  label: 'Search',
                                  disableInteractive: true,
                                }
                              : undefined
                          }
                          ariaLabel='ConversationLayout_search'
                        />
                      ),
                    },
                    {
                      iconButtonComponent: (
                        <ConversationHeaderListDropdown
                          disabled={isShouldRequestAccess}
                          ariaLabel='AddNewMessageCall'
                          onChangeCall={() => {
                            callModalStore.openModal()
                          }}
                          onChangeNew={() => {
                            conversationStore.handleOpenNewConversation()
                          }}
                          onChangeContactCreate={() => {
                            userPermissionsStore.getItem('can_create_contacts')
                              ? contactCreateModalStore.openModal()
                              : new FeatureRestrictedModalStore()
                          }}
                        />
                      ),
                    },
                  ]
            }
            actionsGap={8}
          />
        )}
        {featureFlagsStore.call_history && !isShouldRequestAccess && (
          <div className={styles.tabs}>
            <Tabs
              tabs={tabs}
              activeTabKey={activeTab}
              handleSelectTab={selectTab}
              variant={'simple'}
              tabHeight={40}
              isFullWidth
              paddingInlineWrap={isMobileView ? 8 : undefined}
            />
          </div>
        )}
        {isShowChats && <ConversationList store={conversationListStore} />}
        {isShowCalls &&
          (isMobileView ? (
            <CallHistory store={callHistoryStore} />
          ) : (
            <CallHistoryFilters store={callHistoryStore} />
          ))}
      </div>
    )
  }
)
