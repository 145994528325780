// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WzeKNC7b4YGkKfppSjKv{width:100%;height:100%;position:relative}.JpLGU8vr30BmzINsqzei{border-radius:4px;padding:2px;border:1px solid var(--action-outlined-tertiary-border);background:var(--background-primary-inverted-primary)}", "",{"version":3,"sources":["webpack://./src/pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,iBAAA,CAGJ,sBACI,iBAAA,CACA,WAAA,CACA,uDAAA,CACA,qDAAA","sourcesContent":[".tableContainer {\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n\n.icon {\n    border-radius: 4px;\n    padding: 2px;\n    border: 1px solid var(--action-outlined-tertiary-border);\n    background: var(--background-primary-inverted-primary);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "WzeKNC7b4YGkKfppSjKv",
	"icon": "JpLGU8vr30BmzINsqzei"
};
export default ___CSS_LOADER_EXPORT___;
