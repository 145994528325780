// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CKJ871gIhmc7JP2Fk1Dp{margin-top:24px}.P1hPqy0OjI6DCtAkIdwn{height:auto}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/localNumbers/pages/CampaignUseCase/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,WAAA","sourcesContent":[".useCaseDesc {\n  margin-top: 24px;\n}\n\n.customDropdown {\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"useCaseDesc": "CKJ871gIhmc7JP2Fk1Dp",
	"customDropdown": "P1hPqy0OjI6DCtAkIdwn"
};
export default ___CSS_LOADER_EXPORT___;
