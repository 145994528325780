import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { logger } from 'shared/lib'
import { AdminOrganizationsApi, IResponseAdminOrganizationById } from 'entities/Admin'
import { AdminEditLimitsContent } from 'pages/admin/pages/organizations/modals/AdminEditLimits/AdminEditLimitsContent'
import { AdminEditLimitsAction } from 'pages/admin/pages/organizations/modals/AdminEditLimits/AdminEditLimitsAction'

class AdminEditLimitsStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''

  organization: IResponseAdminOrganizationById | null = null
  broadcasts_long_code_limit = ''
  broadcasts_short_code_limit = ''
  broadcasts_toll_free_limit = ''
  contactImportLimit = ''

  onSuccess: (() => void) | null = null

  loadingSubmit = false

  get disabledSubmit() {
    return (
      this.broadcasts_long_code_limit === '0' ||
      this.broadcasts_short_code_limit === '0' ||
      this.broadcasts_toll_free_limit === '0' ||
      this.contactImportLimit === '0'
    )
  }

  reset = () => {
    this.modalId = ''
    this.organization = null
    this.broadcasts_long_code_limit = ''
    this.contactImportLimit = ''
  }

  set_broadcasts_long_code_limit = (credits: string) => {
    this.broadcasts_long_code_limit = credits
  }
  set_broadcasts_short_code_limit = (credits: string) => {
    this.broadcasts_short_code_limit = credits
  }
  set_broadcasts_toll_free_limit = (credits: string) => {
    this.broadcasts_toll_free_limit = credits
  }

  setContactImportLimit = (credits: string) => {
    this.contactImportLimit = credits
  }

  onSubmit = async () => {
    try {
      if (!this.organization?.id) {
        return
      }
      runInAction(() => {
        this.loadingSubmit = true
      })
      await AdminOrganizationsApi.createLimits({
        id: this.organization?.id,
        data: {
          import_limit: +this.contactImportLimit || 0,
          broadcasts_long_code_limit: +this.broadcasts_long_code_limit || 0,
          broadcasts_short_code_limit: +this.broadcasts_short_code_limit || 0,
          broadcasts_toll_free_limit: +this.broadcasts_toll_free_limit || 0,
        },
      })
      this.onSuccess && this.onSuccess()
      modalStore.closeModal(this.modalId)
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loadingSubmit = false
      })
    }
  }

  onOpenModal = (
    organization: IResponseAdminOrganizationById,
    onSuccess: typeof this.onSuccess
  ) => {
    this.reset()
    this.onSuccess = onSuccess
    this.organization = organization
    this.broadcasts_long_code_limit = String(
      organization.broadcast_limits?.broadcasts_long_code || ''
    )
    this.broadcasts_short_code_limit = String(
      organization.broadcast_limits?.broadcasts_short_code || ''
    )
    this.broadcasts_toll_free_limit = String(
      organization.broadcast_limits?.broadcasts_toll_free || ''
    )
    this.contactImportLimit = String(organization.import_limits || '')
    this.modalId = nanoid()
    modalStore.addModal({
      id: this.modalId,
      title: 'Edit limits',
      ModalContent: AdminEditLimitsContent,
      width: 480,
      ModalActions: AdminEditLimitsAction,
    })
  }
}

export const adminEditLimitsStore = new AdminEditLimitsStore()
