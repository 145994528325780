import classNames from 'classnames'
import { FC } from 'react'
import { type IIconsVector, Icon, layoutStore } from 'shared/ui'
import styles from './styles.module.scss'

type IDropdownTriggerComponentProps = {
  text?: string
  icon?: IIconsVector
}

export const DropdownTriggerComponent: FC<IDropdownTriggerComponentProps> = ({ text, icon }) => {
  const { isViewXs } = layoutStore

  return (
    <div
      className={classNames(
        styles.container,
        isViewXs && styles.containerXs,
        !text && styles.default
      )}
    >
      <div className={styles.textBlock}>
        {icon && (
          <div className={styles.iconContainer}>
            <Icon icon={icon} />
          </div>
        )}
        <div className={styles.text}>{text || 'Select'} </div>
      </div>
      <Icon icon='chevronDown' color='var(--content-primary-primary)' />
    </div>
  )
}
