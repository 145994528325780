import { useMemo } from 'react'
import { type PageLayoutStore } from 'shared/layout'
import type { IFullResponseCampaign } from 'entities/Campaign'
import { CampaignDetailContext } from './context/context'
import { CampaignDetailStore } from './store/CampaignDetailStore'
import { CampaignDetailUi } from './ui/CampaignDetailUi'

type ICampaignDetailProps = {
  response: IFullResponseCampaign
  pageLayoutStore: PageLayoutStore
}
export const CampaignDetail = ({ response, pageLayoutStore }: ICampaignDetailProps) => {
  const store = useMemo(() => new CampaignDetailStore(response, pageLayoutStore), [])
  return (
    <CampaignDetailContext.Provider value={store}>
      <CampaignDetailUi />
    </CampaignDetailContext.Provider>
  )
}
