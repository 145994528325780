import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import { AdminOrganizationsApi, IResponseAdminOrganizationById } from 'entities/Admin'

class AdminCancelSubscriptionStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''
  organization: IResponseAdminOrganizationById | null = null

  onSuccess: (() => void) | null = null

  reset = () => {
    this.modalId = ''
    this.organization = null
  }

  onCancelSubscription = async () => {
    try {
      if (!this.organization?.id) return
      await AdminOrganizationsApi.cancelSubscription(this.organization.id)
      modalStore.closeModal(this.modalId)
      this.onSuccess && this.onSuccess()
    } catch (e) {
      logger.error(e)
    }
  }

  onOpenModal = (
    organization: IResponseAdminOrganizationById,
    onSuccess: typeof this.onSuccess
  ) => {
    this.reset()
    this.modalId = nanoid()
    this.organization = organization
    this.onSuccess = onSuccess
    modalStore.addModal({
      id: this.modalId,
      title: 'Cancel subscription',
      desc: [
        'Are you sure you want to cancel',
        <br key={'br'} />,
        <b key={'b'}>{organization.name}</b>,
      ],
      type: ModalTypeList.INFO,
      primaryAction: {
        text: 'Yes, Cancel subscription',
        onAction: this.onCancelSubscription,
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => modalStore.closeModal(this.modalId),
      },
    })
  }
}

export const adminCancelSubscriptionStore = new AdminCancelSubscriptionStore()
