import { FC } from 'react'
import classNames from 'classnames'
import { Tooltip } from 'shared/ui/Tooltip'
import { Icon, IIconsVector } from 'shared/ui/Icon'
import {
  type automatedInfo,
  enumAutomatedInfoType,
  enumAutomatedInfoSource,
  enumAutomatedInfoIcon,
  internalAutomatedInfoType,
} from './types'

import styles from './styles.module.scss'

type IAutomatedAvatarProps = {
  size?: 'small'
  withTooltip?: boolean
  automatedInfo?: automatedInfo | null
}

export const AutomatedAvatar: FC<IAutomatedAvatarProps> = ({
  size,
  withTooltip,
  automatedInfo,
}) => {
  const iconSize = size === 'small' ? 16 : 22
  const defaultIcon: IIconsVector = 'bot'

  let icon: IIconsVector = defaultIcon
  let iconColor = undefined
  let tooltipLabel = 'Salesmsg bot'

  if (automatedInfo) {
    const { isInternal, type, source, name } = automatedInfo

    icon = isInternal
      ? (type && enumAutomatedInfoIcon[type]) || defaultIcon
      : (source && enumAutomatedInfoIcon[source]) || defaultIcon

    if (isInternal) iconColor = 'var(--content-brand-primary)'

    const typeLabel = type && enumAutomatedInfoType[type]

    let sourceLabel = null
    const excludedSourceLabels: internalAutomatedInfoType[] = [
      'ai',
      'broadcast',
      'keyword',
      'campaign',
    ]

    if (type && !excludedSourceLabels.includes(type)) {
      sourceLabel = source && enumAutomatedInfoSource[source]
    }

    tooltipLabel = `${sourceLabel ? `${sourceLabel} ` : ''}${typeLabel || ''}${
      name ? `: ${name}` : ''
    }`
  }

  const content = (
    <div className={classNames(styles.wrap, !automatedInfo && styles.bg, size && styles[size])}>
      <Icon icon={icon} fontSize={iconSize} color={iconColor} />
    </div>
  )

  if (withTooltip) {
    return (
      <Tooltip label={tooltipLabel} placement={'top'}>
        {content}
      </Tooltip>
    )
  }
  return content
}
