import { observer } from 'mobx-react-lite'
import { numberFormat } from 'shared/lib'
import { EmptyCell } from 'shared/ui'

export const NumberCell = observer(({ value }: { value: number | undefined | null }) => {
  if (value !== null && value !== undefined) {
    return <>{numberFormat({ value: value })}</>
  }
  return <EmptyCell />
})
