// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._ZXLyvBLWv6wLL7sCtFG{display:flex;justify-content:flex-start}.eshdZfNGXsFrGr6fXHlJ{display:flex;flex-direction:column;padding:16px;border-radius:8px;background:var(--background-neutral-subtle);margin-top:40px;margin-bottom:32px}.DePx55GVucfwsJrPLi8Q{height:1px;background-color:var(--black-20);margin-bottom:14px;margin-top:4px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/calling/ui/AddOnModalContentSecondStep/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,0BAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,iBAAA,CACA,2CAAA,CACA,eAAA,CACA,kBAAA,CAEF,sBACE,UAAA,CACA,gCAAA,CACA,kBAAA,CACA,cAAA","sourcesContent":[".header {\n  display: flex;\n  justify-content: flex-start;\n}\n.summary {\n  display: flex;\n  flex-direction: column;\n  padding: 16px;\n  border-radius: 8px;\n  background: var(--background-neutral-subtle);\n  margin-top: 40px;\n  margin-bottom: 32px;\n}\n.divider {\n  height: 1px;\n  background-color: var(--black-20);\n  margin-bottom: 14px;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "_ZXLyvBLWv6wLL7sCtFG",
	"summary": "eshdZfNGXsFrGr6fXHlJ",
	"divider": "DePx55GVucfwsJrPLi8Q"
};
export default ___CSS_LOADER_EXPORT___;
