import { createContext, useContext } from 'react'
import { type IFullResponseCampaign } from 'entities/Campaign'

export const CampaignsPageIDContext = createContext<IFullResponseCampaign | null>(null)

export const useCampaignsPageIDContext = () => {
  const context = useContext(CampaignsPageIDContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CampaignsPageContext')

  return context
}
