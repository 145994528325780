import { makeAutoObservable } from 'mobx'
import { MAX_CRM_LENGTH } from 'shared/constants/auth'
import { IResponseUsersProfilesUiSettings } from 'entities/Users/api/types'
import { IParamsRegisterAnswersStep2 } from 'entities/Auth'
import { ICrm, CrmEnum, FieldsEnum, ContactsCountsEnum } from './type'

export class Step2Store {
  private _crm: ICrm | null = null
  private _contactsCount = ''
  private _activeField: FieldsEnum | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get payload() {
    return {
      answer_integration: this.crm?.name || '',
      answer_contactsCount: this._contactsCount,
    }
  }

  get answers(): IParamsRegisterAnswersStep2 {
    return {
      crm: this.crm?.value || '',
      contacts_number: this._contactsCount,
    }
  }

  get isCompletedStep() {
    return !!this.crm && !!this.contactsCount
  }

  get isActiveCrmField() {
    return this._activeField === FieldsEnum.crm
  }

  reset = () => {
    this._crm = null
    this._contactsCount = ''
    this._activeField = null
  }

  initAnswers = ({ data }: IResponseUsersProfilesUiSettings) => {
    if (!data) return

    const { answer_integration, answer_contactsCount } = data.items.answers

    if (answer_integration) {
      const crm = this.crms.find((item) => item.name === answer_integration)
      if (crm) this._crm = crm

      if (crm) {
        this._crm = crm
      } else {
        this._crm = {
          id: CrmEnum.other,
          value: 'Other',
          name: answer_integration,
          icon: 'lightning2',
        }
      }
    }

    if (answer_contactsCount) this._contactsCount = answer_contactsCount
  }

  get crm() {
    return this._crm
  }

  get crmLength() {
    if (!this._crm) return 0
    return this._crm.name.length
  }

  get contactsCount() {
    return this._contactsCount
  }

  get crms(): ICrm[] {
    return [
      {
        id: CrmEnum.hubSpot,
        value: 'HubSpot',
        name: 'HubSpot',
        icon: 'hubspot',
      },
      {
        id: CrmEnum.activeCampaign,
        value: 'ActiveCampaign',
        name: 'ActiveCampaign',
        icon: 'activecampaign',
      },
      {
        id: CrmEnum.keap,
        value: 'Keap (Infusionsoft)',
        name: 'Keap',
        icon: 'infusionsoft',
      },
      {
        id: CrmEnum.salesforce,
        value: 'Salesforce',
        name: 'Salesforce',
        icon: 'salesforce',
      },
      {
        id: CrmEnum.pipedrive,
        value: 'Pipedrive',
        name: 'Pipedrive',
        icon: 'pipedrive',
      },
      {
        id: CrmEnum.none,
        value: 'None',
        name: 'None',
        icon: 'block',
      },
      {
        id: CrmEnum.other,
        value: 'Other',
        name: 'Other',
        icon: 'lightning2',
      },
    ]
  }

  get canSinchNumberVendorKey() {
    const availableSinchContactsCount = [
      ContactsCountsEnum.count100_500,
      ContactsCountsEnum.count501_1000,
      ContactsCountsEnum.count1001_2500,
      ContactsCountsEnum.count2501_10000,
    ]

    return availableSinchContactsCount.includes(this.contactsCount as ContactsCountsEnum)
  }

  get contactsCounts(): string[] {
    return [
      ContactsCountsEnum.count100_500,
      ContactsCountsEnum.count501_1000,
      ContactsCountsEnum.count1001_2500,
      ContactsCountsEnum.count2501_10000,
      ContactsCountsEnum.count10001_50000,
    ]
  }

  get contactsCountsDropdown(): string[] {
    return [
      ContactsCountsEnum.count50001_100000,
      ContactsCountsEnum.count100001_250000,
      ContactsCountsEnum.count250001_500000,
      ContactsCountsEnum.count500001_1M,
      ContactsCountsEnum.countMore1M,
    ]
  }

  get isSelectedContactsCountsDropdown() {
    return this.contactsCountsDropdown.includes(this._contactsCount)
  }

  get isNextDisabled() {
    return Boolean(!this._crm || this._crm.name.length > MAX_CRM_LENGTH || !this._contactsCount)
  }

  setContactsCount = (value: string) => {
    this._contactsCount = value
  }

  setCrm = (crm: ICrm | null) => {
    if (crm && crm.name.length > MAX_CRM_LENGTH) return
    this._crm = crm
  }

  handleBlur = () => {
    this._activeField = null
  }

  setActiveField = (value: FieldsEnum | null) => {
    this._activeField = value
  }

  handleCrmFieldFocus = () => {
    this.setActiveField(FieldsEnum.crm)
  }
}
