import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import { CampaignApi, IFullResponseCampaign } from 'entities/Campaign'
import { ContactsApi } from 'entities/Contacts'

type ICampaignEnrolmentStoreParams = {
  data: {
    name: string
    filters: IFullResponseCampaign['filters']
    id: number
  }
  onNext?: () => void
}

export class CampaignEnrolmentStore {
  constructor() {
    makeAutoObservable(this)
  }
  modalId = nanoid()

  init = async (params: ICampaignEnrolmentStoreParams) => {
    if (!params.data.filters) return params.onNext?.()
    try {
      const { data } = await ContactsApi.getContactsFiltersCount({
        filtersListGroups: params.data.filters,
      })
      if (data.count) {
        this._openModal({ ...params, enrolled_contacts_count: data.count })
      } else {
        params.onNext?.()
      }
    } catch (e) {
      logger.error(e)
      params.onNext?.()
    }
  }

  private _onClose = (onNext?: () => void) => {
    modalStore.closeModal(this.modalId)
    onNext?.()
  }

  private _openModal({
    data: { name, id },
    onNext,
    enrolled_contacts_count,
  }: ICampaignEnrolmentStoreParams & { enrolled_contacts_count: number }) {
    modalStore.addModal({
      id: this.modalId,
      type: ModalTypeList.INFO,
      disabledOnAllClose: true,
      disabledOnEscClose: true,
      disabledOverlayClose: true,
      title: `Campaign “${name}” activated`,
      desc: `Do you want to enroll your ${enrolled_contacts_count} contacts in your Campaign?`,
      primaryAction: {
        text: `Enroll ${enrolled_contacts_count} contacts`,
        onAction: async () => {
          try {
            await CampaignApi.enrolmentCampaign({ id })
            this._onClose(onNext)
          } catch (e) {
            logger.error(e)
          }
        },
      },
      secondaryAction: {
        text: 'Don’t enroll',
        onAction: () => this._onClose(onNext),
      },
    })
  }
}
