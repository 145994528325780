import { makeAutoObservable, runInAction } from 'mobx'
import { CanceledError } from 'axios'
import { totalsToPercents } from 'shared/lib/totalsToPercents'
import { logger } from 'shared/lib'
import { ChatbotStatus, ChatbotApi, IChatbotFullData } from 'entities/Chatbot'
import { chatbotOperation } from './chatbotOperationStore'

export class ChatbotAnalyticDetailStore {
  updating = false
  initialLoading = true

  name = ''
  active = false
  status = ChatbotStatus.Disabled

  completedCount = 0
  terminatedCount = 0
  fallbackCount = 0
  totalCount = 0
  inProgressCount = 0

  completedPercent = 0
  terminatedPercent = 0
  fallbackPercent = 0
  inProgressPercent = 0

  private _data: IChatbotFullData | null = null
  private _request: Promise<void> | null = null

  constructor(public id: number) {
    makeAutoObservable(this)
  }

  get data() {
    return this._data
  }

  init = () => {
    if (this._request) return this._request

    this._request = this._loadData()

    return this._request
  }

  updateStatus = async (isActive: boolean) => {
    this.updating = true

    try {
      const { data } = (await chatbotOperation.updateStatus(this.id, isActive)) ?? {}

      runInAction(() => {
        if (!data) return

        this._data = data
        this.active = isActive
        this.status = data.status
      })
    } catch (error) {
      logger.error(error)
    } finally {
      runInAction(() => {
        this.updating = false
      })
    }
  }

  delete = () => chatbotOperation.delete(this.id)

  dispose = () => {}

  private _loadData = async () => {
    this.initialLoading = true

    try {
      const { data } = await ChatbotApi.getChatbot(this.id)

      runInAction(() => {
        this._data = data
        this._setState(data)
        this._setTotals(data)
        this.initialLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this.initialLoading = error instanceof CanceledError
      })

      logger.error(error)
    }
  }

  private _setState = (data: IChatbotFullData) => {
    this.active = data.is_active
    this.name = data.name
    this.status = data.status
  }

  private _setTotals = (data: IChatbotFullData) => {
    this.completedCount = data.completed_contacts_count
    this.terminatedCount = data.terminated_contacts_count
    this.fallbackCount = data.fallback_contacts_count
    this.inProgressCount = data.in_progress_contacts_count
    this.totalCount = data.total_contacts_count

    const [completedPercent, terminatedPercent, fallbackPercent, inProgressPercent] =
      totalsToPercents(
        [this.completedCount, this.terminatedCount, this.fallbackCount, this.inProgressCount],
        this.totalCount
      )

    this.completedPercent = completedPercent
    this.terminatedPercent = terminatedPercent
    this.fallbackPercent = fallbackPercent
    this.inProgressPercent = inProgressPercent
  }
}
