import { observer } from 'mobx-react-lite'
import { Type, Typography } from 'shared/ui'
import type { ICreateCampaignStep } from 'entities/Campaign'
import { useCampaignDetailContext } from 'pages/campaigns/ui/detail/CampaignDetail/context/context'
import { StepUi } from './StepUi/StepUi'

type IStepProps = {
  step: ICreateCampaignStep
}

export const Step = observer(({ step }: IStepProps) => {
  const { activeStep, setActiveStep } = useCampaignDetailContext()
  return (
    <StepUi
      title={step.name}
      isActive={activeStep?.id === step.id}
      setActiveStep={() => setActiveStep(step)}
      leftContent={
        <Typography ariaLabel={'counter'} variant={'body-sm-medium'}>
          {step.order}
        </Typography>
      }
      rightContent={<Type type={step.type} />}
    />
  )
})
