import { observer } from 'mobx-react-lite'
import { Button, Icon } from 'shared/ui'
import { TemplateConfigList } from 'widgets/CreateWorkflowModal/config'
import { type ICreateWorkflowProps } from 'widgets/CreateWorkflowModal/types'

import styles from './styles.module.scss'

export const WorkflowTemplateModal = observer(
  ({ workflow, onCancel, onNext }: ICreateWorkflowProps) => (
    <div className={styles.container}>
      <header className={styles.info}>
        <h4 className={styles.infoTitle}>Select template</h4>
        <p className={styles.infoDescription}>You can select only one template</p>
      </header>
      <div className={styles.templates}>
        {TemplateConfigList.map((template) => (
          <section
            key={template.name}
            className={styles.template}
            data-disabled={template.disabled}
            data-selected={workflow.templateName === template.name ? true : undefined}
            onClick={() => workflow.setTemplateName(template.name)}
          >
            <div className={styles.templateIcon}>
              <Icon icon={template.icon} />
            </div>
            <div className={styles.templateContent}>
              <header className={styles.templateContentTitle}>{template.name}</header>
              <p className={styles.templateContentDescription}>{template.description}</p>
            </div>
          </section>
        ))}
      </div>
      <footer className={styles.footer}>
        <Button
          className={styles.helpButton}
          text='Cancel'
          size='medium'
          contained='secondary'
          onClick={onCancel}
        />
        <Button text='Next' size='medium' disabled={!workflow.templateName} onClick={onNext} />
      </footer>
    </div>
  )
)
