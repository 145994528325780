import { createContext, useContext } from 'react'
import { CampaignStepStore } from '../store/CampaignStepStore'

export const CampaignStepContext = createContext<CampaignStepStore | null>(null)

export const useCampaignStepContext = () => {
  const context = useContext(CampaignStepContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CampaignStepContext')

  return context
}
