import { ActionItem } from 'shared/ui'
import { Campaign } from 'entities/Campaign'
import { editCampaign } from 'pages/campaigns/actions/actionItems/editCampaign'
import { duplicateCampaign } from 'pages/campaigns/actions/actionItems/duplicateCampaign'
import { deleteCampaign } from 'pages/campaigns/actions/actionItems/deleteCampaign'
import { toggleActiveCampaign } from 'pages/campaigns/actions/actionItems/toggleActiveCampaign'

export const getCampaignActions = (
  campaign: Campaign,
  onSuccessDelete: () => void
): ActionItem[] => {
  const toggleActive = toggleActiveCampaign(campaign)
  const editAction = editCampaign(campaign)
  const duplicateAction = duplicateCampaign(campaign)
  const deleteAction = deleteCampaign(campaign, onSuccessDelete)

  if (campaign.status === 'active' || campaign.status === 'disabled') {
    return [toggleActive, editAction, duplicateAction, deleteAction]
  }
  if (campaign.status === 'draft') {
    return [editAction, duplicateAction, deleteAction]
  }
  return []
}
