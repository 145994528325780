import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { ComplianceApi, complianceStore } from 'entities/Compliance'
import { SettingsPath } from 'pages/settings/route/settingsPath'

export class StirShakenStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _isTermsAndPrivacyChecked = false
  private _loading = false

  get isTermsAndPrivacyChecked() {
    return this._isTermsAndPrivacyChecked
  }

  get loading() {
    return this._loading
  }

  toggleIsTermsAndPrivacyChecked = (value: boolean) => {
    this._isTermsAndPrivacyChecked = value
  }

  submitStirShaken = async () => {
    try {
      this._loading = true
      const { data } = await ComplianceApi.updateOrganizationShakenStirTerms({
        terms_accepted: this._isTermsAndPrivacyChecked,
      })

      complianceStore.setOrganizationShakenStir(data)

      uiStore.changeRoute({
        path: `/${SettingsPath.compliance.calling.root}`,
      })
    } catch (e) {
      logger.error(e)
    } finally {
      this._loading = false
    }
  }
}
