import { makeAutoObservable } from 'mobx'
import { Status } from 'shared/ui'
import { subscriptionStore } from 'entities/Subscription'
import {
  EnumMenuOutboundItemsKey,
  menuItems,
} from 'widgets/Menu/ui/MenuOutbound/constants/menuItems'
import { MenuStore } from 'widgets/Menu/store/menuStore'
import { getStateAccessOutbound } from 'widgets/Menu/ui/MenuOutbound/lib/getPathOutbound'

export class MenuOutboundStore {
  private readonly _menuStore: MenuStore

  constructor(key: string) {
    makeAutoObservable(this)

    this._menuStore = new MenuStore({
      key: key,
    })
  }

  hideStateAccess = (key: EnumMenuOutboundItemsKey) => {
    if (subscriptionStore.isAgencyWithoutSubscription) return true

    switch (key) {
      case 'broadcasts_all':
      case 'broadcasts_onetime':
      case 'broadcasts_recurring':
        return !getStateAccessOutbound(key)
      case 'triggers_all':
      case 'triggers_sms':
      case 'triggers_ringless':
        return !getStateAccessOutbound(key)
      default:
        return false
    }
  }

  disabledStateAccess = (key: EnumMenuOutboundItemsKey) => {
    switch (key) {
      case 'power_dialer':
        return !getStateAccessOutbound(key)
      default:
        return false
    }
  }

  get menuStore() {
    return this._menuStore
  }

  get menuItems() {
    return menuItems
      .map((item) => {
        if (item.type === 'nav') {
          const disabled = this.disabledStateAccess(item.key)

          item.disabled = disabled
          item.rightContent = disabled ? (
            <Status title={'Coming soon'} intent={'white_label'} emphasis={'high'} size={'label'} />
          ) : (
            item.rightContent
          )
        }

        return item
      })
      .filter((item) => {
        if (item.type === 'sub') {
          item.items = item.items.filter((child) => {
            return !this.hideStateAccess(child.key)
          })

          return Boolean(item.items.length)
        }

        return !this.hideStateAccess(item.key)
      })
  }
}
