import { makeAutoObservable, runInAction } from 'mobx'
import { toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { logger } from 'shared/lib'
import { contactsStore } from 'entities/Contacts'
import { ChatbotApi, ChatbotStatus } from 'entities/Chatbot'
import type { Contact } from 'entities/Contacts/model/Contact'
import type { IFeatureFlagsStore } from 'entities/FeatureFlags'
import { ChatbotEnrollModal } from 'widgets/ChatbotEnroll/ui/ChatbotEnrollModal'

export class ChatbotEnrollStore {
  private _isActive = false
  private _isChecked = false
  private _chatbotEnrollModalId = 'chatbotEnrollModalId'

  constructor(private _featureFlags: IFeatureFlagsStore) {
    makeAutoObservable(this)
  }

  get isActive() {
    return this._isActive
  }

  get isChecked() {
    return this._isChecked
  }

  get isOperationEnabled() {
    return this._featureFlags.chatbot
  }

  getValidContactIds = async (contactIds: number[]) => {
    if (!this.isOperationEnabled) return null

    try {
      const contacts = (await contactsStore.getByIds(contactIds)) ?? []
      const blockReason = this.getBlockReason(...contacts)

      if (blockReason) {
        toastStore.add({
          type: 'error',
          title: blockReason,
        })

        return null
      }

      return contacts
        .filter((contact) => !contact.isOptOut && !contact.isBlocked)
        .map((contact) => contact.id)
    } catch (error) {
      logger.error(error)

      toastStore.add({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })

      return null
    }
  }

  getBlockReason = (...contacts: Contact[]) => {
    switch (true) {
      case this._isChecked && !this._isActive:
        return 'No active Textbot'
      case !contacts.length:
        return 'No selected contact'
      case contacts.length === 1 && contacts[0].isOptOut:
        return 'This contact has opted-out'
      case contacts.length === 1 && contacts[0].isBlocked:
        return 'This contact has been blocked'
      case contacts.every(({ isOptOut, isBlocked }) => isOptOut || isBlocked):
        return 'Contacts has opted-out or has been blocked'
      default:
        return null
    }
  }

  checkActivity = () => {
    if (!this.isOperationEnabled) return

    ChatbotApi.getChatbotList({ limit: 1, page: 1, 'filters[status]': ChatbotStatus.Active }).then(
      ({ data }) =>
        runInAction(() => {
          this._isActive = !!data.data.length
          this._isChecked = true
        }),
      () =>
        runInAction(() => {
          this._isActive = false
          this._isChecked = true
        })
    )
  }

  enrollContacts = (contactIds: number[]) => {
    const onClose = () => modalStore.removeModal(this._chatbotEnrollModalId)

    modalStore.addModal({
      id: this._chatbotEnrollModalId,
      title: 'Engage with Textbot',
      width: 560,
      showCloseButton: false,
      showCloseIcon: true,
      paddingContent: '25px',
      ModalContent: ChatbotEnrollModal,
      ModalContentProps: { contactIds, onClose },
      onClose: onClose,
    })
  }
}
