import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { logger } from 'shared/lib'
import { initPosthog } from 'entities/Posthog'
import { initCanny } from 'entities/Canny'
import { eventHeartbeat } from 'entities/DataLog/events/EventHeartbeatLog'
import { authStore } from 'entities/Auth'
import { initIntercom } from 'entities/Intercom'
import { numbersStore } from 'entities/Phone'
import { remindersStore } from 'widgets/Reminders'
import { Sidebar } from 'widgets/Sidebar'
import { getAppData } from '../lib/loader'

const AuthLayout = observer(() => {
  useEffect(() => {
    if (authStore.isLoggedIn) {
      getAppData()
        .catch(logger.error)
        .finally(() => {
          initPosthog()
          initCanny()
          initIntercom(true)
          numbersStore.initStartNumberModal()
          numbersStore.initConnectTwilioModal()
          remindersStore.init()
          eventHeartbeat()
        })
    }
  }, [authStore.isLoggedIn])

  return <Sidebar />
})

export default AuthLayout
