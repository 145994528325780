// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".j_vV1qqhXK8DCwmgq6Pc{width:100%;height:100%;padding:0 16px;position:relative}.j_vV1qqhXK8DCwmgq6Pc .tfHnDDLxA2ZjwoZ5wO_o{width:100%;height:100%;position:relative}", "",{"version":3,"sources":["webpack://./src/pages/contacts/ui/ContactsTable/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CAEA,4CACI,UAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".tableContainer {\n    width: 100%;\n    height: 100%;\n    padding: 0 16px;\n    position: relative;\n\n    .table {\n        width: 100%;\n        height: 100%;\n        position: relative;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "j_vV1qqhXK8DCwmgq6Pc",
	"table": "tfHnDDLxA2ZjwoZ5wO_o"
};
export default ___CSS_LOADER_EXPORT___;
