import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import type { IResponseFilter } from 'entities/Contacts/api/filterTypes'
import type { IFilterValue, IWidgetProps } from 'widgets/FilterEditor/types'

export const WidgetHubspot = observer(
  ({ filterItem, expandByDefault = false, parentsDropdownId }: IWidgetProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const filterConfig = filterItem.config as IResponseFilter<IFilterValue>
    const filterValues = useMemo(() => filterConfig.values ?? [], [filterConfig.values])
    const selectedItem = useMemo(
      () =>
        filterValues.find(({ key }) =>
          filterItem.payload.value ? key === +filterItem.payload.value : false
        ),
      [filterValues, filterItem.payload.value]
    )

    const filterValuesTopSelect = useMemo(
      () =>
        selectedItem
          ? [selectedItem, ...filterValues.filter((item) => item !== selectedItem)]
          : filterValues,
      [filterValues, selectedItem]
    )

    const handleSave = (value: string) => {
      setIsOpen(false)
      filterItem.payload.updateHard({ value })
    }

    useEffect(() => {
      setTimeout(() => setIsOpen(expandByDefault), 100)
    }, [expandByDefault])

    return (
      <Dropdown
        ariaLabel='HubspotSelectDropdown'
        placement='bottom-start'
        margin={10}
        width={240}
        parentsDropdownId={parentsDropdownId}
        show={isOpen}
        withSearch
        maxHeight={340}
        onToggle={(value) => {
          if (value) setIsOpen(true)
        }}
        items={filterValuesTopSelect.map((item) => ({
          id: item.key,
          label: item.name || '',
          parentTooltipProps: {
            fullWidth: true,
            label: [
              <>
                <Typography
                  tag={'div'}
                  key={'hubSpot_list_tooltip'}
                  ariaLabel={'HubSpotListTooltip'}
                  variant={'body-md-regular'}
                  color={'var(--content-primary-primary-inverted)'}
                  marginBottom={2}
                >
                  {item.name || ''}
                </Typography>
                <Typography
                  tag={'div'}
                  key={'time'}
                  variant={'body-sm-regular'}
                  color={'var(--content-primary-tertiary-inverted'}
                  ariaLabel={'SyncedAt'}
                >
                  Last sync -&nbsp;
                  {(item.synced_at &&
                    uiStore.dayjs(item.synced_at).format(DayjsFormats.fullWithAtDash2)) ||
                    'Never synced'}
                </Typography>
              </>,
            ],
          },
          labelBrackets: String(item.count || 0),
          onClick: () => handleSave(String(item.key)),
        }))}
        triggerComponent={() => <span>{selectedItem?.name || 'select'}</span>}
        propsDropdownList={{
          textFieldProps: {
            InputProps: {
              placeholder: 'Search HubSpot lists',
            },
          },
        }}
      />
    )
  }
)
