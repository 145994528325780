import { observer } from 'mobx-react-lite'
import { KeyboardEvent } from 'react'
import classNames from 'classnames'
import { Typography, Error, Button } from 'shared/ui'
import { makeTextFieldAutoFocus, makeInputPattern, TextField } from 'shared/ui/TextField'
import { type LoginStore } from 'widgets/Login/store/loginStore'

import styles from './styles.module.scss'

const InputCode = makeInputPattern({
  format: '######',
  placeholder: '000000',
})

type IProps = {
  store: LoginStore
}

export const TwoFaCodeModalContent = observer(({ store }: IProps) => {
  const {
    setTwoFaCode,
    twoFaCode,
    loading,
    twoFaCodeError,
    isValidTwoFaCode,
    onVerifyCode,
    twoFaLabel,
    canResendTwoFaCode,
    sendTwoFaCode,
  } = store

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isValidTwoFaCode) {
      event.preventDefault()
      onVerifyCode()
    }
  }

  return (
    <div className={styles.wrap}>
      <Typography
        color={'var(--content-primary-primary)'}
        variant={'body-md-medium'}
        ariaLabel={'twoFaCodeModalTitle'}
      >
        Enter the 6-digit code
      </Typography>
      <Typography
        color={'var(--content-primary-tertiary)'}
        variant={'body-md-regular'}
        ariaLabel={'twoFaCodeModalLabel'}
      >
        {twoFaLabel}
      </Typography>
      <TextField
        className={classNames(styles.textField, {
          [styles.error]: twoFaCodeError,
        })}
        Input={InputCode}
        InputProps={{ onKeyDown }}
        timedError={!!twoFaCodeError}
        variant='stroke'
        size='medium'
        value={twoFaCode}
        disabled={loading}
        onChange={setTwoFaCode}
        mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
      />
      <Error type={'text'} error={twoFaCodeError} />
      {canResendTwoFaCode && (
        <div className={styles.wrapResend}>
          <Typography
            color={'var(--content-primary-tertiary)'}
            variant={'body-md-regular'}
            ariaLabel={'resendTwoFaCode'}
          >
            Haven’t received it?
          </Typography>
          <Button
            className={classNames(styles.button)}
            typeBtn='link'
            type='button'
            onClick={sendTwoFaCode}
            disabled={loading}
            text={'Resend new code'}
          />
        </div>
      )}
    </div>
  )
})
