import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Box } from '@mui/material'
import { useBlocker } from 'react-router-dom'
import { SpinnerLoader, Tabs, Typography } from 'shared/ui'
import { useTriggerViewContext } from 'widgets/TriggerView/context/triggerViewContext'
import { TriggerName } from 'widgets/TriggerView/ui/TriggerName/TriggerName'
import { TriggerViewPreview } from 'widgets/TriggerView/ui/TriggerViewPreview/TriggerViewPreview'
import { TriggerSMS } from 'widgets/TriggerView/ui/TriggerSMS/TriggerSMS'
import { IntegrationDropdown } from 'widgets/IntegrationDropdown'
import { SendFromDropdown } from 'widgets/SendFromDropdown'
import { AdvancedTrackConversions } from 'widgets/AdvancedTrackConversions/AdvancedTrackConversions'
import { TriggerActions } from 'widgets/TriggerView/ui/TriggerActions/TriggerActions'
import { TagsDropdown } from 'widgets/TriggerView/ui/TagsDropdown/TagsDropdown'
import { RinglessMessageField } from 'widgets/MessageFormFields/RinglessMessageField'
import styles from './styles.module.scss'

export const TriggerViewContent = observer(() => {
  const {
    loading,
    mode,
    type,
    tabs,
    selectTypeTab,
    ringlessMessageFieldStore,
    integrationDropdownStore,
    handleShowKeepEditingModal,
    sendFromDropdownStore,
    trackConversionStore,
    tagsControlStore,
    isOpenAdvanced,
    isExistChanges,
    setOpenAdvanced,
    reset,
  } = useTriggerViewContext()
  const blocker = useBlocker(() => isExistChanges())
  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  useEffect(() => {
    handleShowKeepEditingModal(blocker)
  }, [blocker])

  if (loading) {
    return (
      <div className={styles.loading}>
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <div className={classnames(styles.box, styles.box__main)}>
        <Typography variant={'body-xs-regular'} className={styles.label} ariaLabel={'NewTrigger'}>
          {`${mode === 'edit' ? 'Edit' : 'New'} trigger`}
        </Typography>
        <TriggerName />
        <div className={styles.tabs}>
          <Tabs
            variant={'simple'}
            tabs={tabs}
            activeTabKey={type}
            handleSelectTab={selectTypeTab}
          />
        </div>
        {type === 'sms' && <TriggerSMS />}
        {type === 'ringless' && <RinglessMessageField store={ringlessMessageFieldStore} />}
        <div className={styles.section}>
          <div className={styles.section__box}>
            <IntegrationDropdown store={integrationDropdownStore} />
          </div>
          <div className={styles.section__box}>
            <SendFromDropdown store={sendFromDropdownStore} />
          </div>
          <div className={styles.section__box}>
            <TagsDropdown store={tagsControlStore} />
          </div>

          {type === 'sms' && (
            <>
              <Box height={26} />
              <AdvancedTrackConversions
                trackConversionStore={trackConversionStore}
                isOpenAdvanced={isOpenAdvanced}
                setOpenAdvanced={setOpenAdvanced}
              />
            </>
          )}

          <TriggerActions />
        </div>
      </div>

      <div className={classnames(styles.box, styles.box__preview)}>
        <TriggerViewPreview />
      </div>
    </div>
  )
})
