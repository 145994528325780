import { createContext, useContext, FC, ReactNode } from 'react'
import { type CleanupPageStore } from 'pages/contacts/pages/cleanup/store/cleanupPageStore'

export const CleanupPageContext = createContext<CleanupPageStore | null>(null)

export const useCleanupPageContext = () => {
  const context = useContext(CleanupPageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CleanupPageContext')

  return context
}

type IProviderCleanupPageProps = {
  value: CleanupPageStore
  children: ReactNode
}

export const ProviderCleanupPage: FC<IProviderCleanupPageProps> = ({ value, children }) => {
  return <CleanupPageContext.Provider value={value}>{children}</CleanupPageContext.Provider>
}
