import { observer } from 'mobx-react-lite'
import { useBroadcastViewContext } from 'widgets/BroadcastView/context/broadcastViewContext'
import { UiContactsTrigger } from 'widgets/BroadcastView/ui/ContactsContent/ui/UiContactsTrigger'

export const ContactsTrigger = observer(() => {
  const { broadcastContactsStore, errorsMap, setError } = useBroadcastViewContext()
  const { editEnrollment, skipped, allTotal, loading, showZeroState } = broadcastContactsStore

  const error = errorsMap.get('contacts')

  const handleClick = () => {
    if (loading) return

    setError('contacts', '')
    editEnrollment()
  }

  return (
    <UiContactsTrigger
      onClick={handleClick}
      total={allTotal}
      skipped={skipped}
      error={error}
      loading={loading}
      showZeroState={showZeroState}
    />
  )
})
