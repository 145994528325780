import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { ComplianceApi, type IResponseSampleMessagesData } from 'entities/Compliance'
import { type LocalNumbersStepsStore } from 'pages/settings/pages/compliance/pages/localNumbers/store/localNumbersStepsStore'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers'
import { ExampleModalContent } from 'pages/settings/pages/compliance/ui/ExampleModalContent/ExampleModalContent'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { sampleMessageExamples } from 'pages/settings/pages/compliance/pages/localNumbers/pages/Messages/lib/constants'

export const sampleMessageMinTextLength = 20
export const sampleMessageMaxTextLength = 1024

export class MessagesStore {
  constructor(private _localNumbersStore: LocalNumbersStepsStore) {
    makeAutoObservable(this)
  }

  private _includeEmbeddedLinks = false
  private _includePhoneNumbers = false
  private _includeAgeGatedContent = false
  private _sampleMessage1 = ''
  private _sampleMessage2 = ''

  get includeEmbeddedLinks() {
    return this._includeEmbeddedLinks
  }

  get includePhoneNumbers() {
    return this._includePhoneNumbers
  }

  get includeAgeGatedContent() {
    return this._includeAgeGatedContent
  }

  get sampleMessage1() {
    return this._sampleMessage1
  }

  get sampleMessage2() {
    return this._sampleMessage2
  }

  get localNumbersStore() {
    return this._localNumbersStore
  }

  get isStep2Disabled() {
    const isWithinLimits = (message: string) =>
      message.length >= sampleMessageMinTextLength && message.length <= sampleMessageMaxTextLength

    return !isWithinLimits(this._sampleMessage1) || !isWithinLimits(this._sampleMessage2)
  }

  init = (sampleMessagesData: IResponseSampleMessagesData | null) => {
    if (!sampleMessagesData) return

    this._includeAgeGatedContent = sampleMessagesData.includeAgeGatedContent
    this._includeEmbeddedLinks = sampleMessagesData.includeEmbeddedLinks
    this._includePhoneNumbers = sampleMessagesData.includePhoneNumbers
    this.setSampleMessage1(sampleMessagesData.sampleMessage1)
    this.setSampleMessage2(sampleMessagesData.sampleMessage2)
  }

  campaignMessagesNextClick = () => {
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.localNumbers.root}/${LocalNumbersRoutes.optInMethod}`,
    })
  }

  setIncludeEmbeddedLinks = () => {
    this._includeEmbeddedLinks = !this.includeEmbeddedLinks
  }

  setIncludePhoneNumbers = () => {
    this._includePhoneNumbers = !this.includePhoneNumbers
  }

  setIncludeAgeGatedContent = () => {
    this._includeAgeGatedContent = !this.includeAgeGatedContent
  }

  setSampleMessage1 = (sampleMessage1: string) => {
    this._sampleMessage1 = sampleMessage1
  }

  setSampleMessage2 = (sampleMessage2: string) => {
    this._sampleMessage2 = sampleMessage2
  }

  openSampleMessageModal = () => {
    const id = 'sampleMessageModal'
    const { activeUseCaseTitle, activeUseCaseValue } = this.localNumbersStore.useCaseStore
    const getExampleMessage = () => {
      const { fullMessage, includeLinksMessage, includePhoneNumbersMessage, defaultMessage } =
        sampleMessageExamples[activeUseCaseValue as string]

      if (this.includeEmbeddedLinks && this.includePhoneNumbers) return fullMessage
      if (this.includeEmbeddedLinks) return includeLinksMessage
      if (this.includePhoneNumbers) return includePhoneNumbersMessage

      return defaultMessage
    }

    modalStore.addModal({
      id,
      width: 540,
      title: 'Sample message example',
      hiddenContent: true,
      ModalContent: () => (
        <ExampleModalContent
          activeUseCase={activeUseCaseTitle || ''}
          example={getExampleMessage()}
          withMustInclude
          includesLinks={this.includeEmbeddedLinks}
          includesPhoneNumbers={this.includePhoneNumbers}
        />
      ),
      primaryAction: {
        text: 'Done',
        onAction: () => modalStore.closeModal(id),
      },
      showCloseButton: false,
    })
  }

  campaignMassagesNextClick = async () => {
    if (!this.localNumbersStore.campaignId) return

    try {
      this.localNumbersStore.stepsStore.setStepLoading(true)

      const { data } = await ComplianceApi.updateComplianceServicesCampaign({
        step: 2,
        sampleMessagesData: {
          sampleMessage1: this.sampleMessage1,
          sampleMessage2: this.sampleMessage2,
          includeAgeGatedContent: this.includeAgeGatedContent,
          includeEmbeddedLinks: this.includeEmbeddedLinks,
          includePhoneNumbers: this.includePhoneNumbers,
        },
        campaignId: this.localNumbersStore.campaignId,
      })

      this.localNumbersStore.complianceLayerStore.set10DLCServiceCampaign(data)

      uiStore.changeRoute({
        path: `/${SettingsPath.compliance.localNumbers.root}/${LocalNumbersRoutes.optInMethod}`,
      })
    } catch (e) {
      logger.error(e)
    } finally {
      this._localNumbersStore.stepsStore.setStepLoading(false)
    }
  }
}
