import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import callingStirShaken from 'shared/assets/images/compliance/callingStirShaken.png'
import { InputCheckbox } from 'shared/ui'
import { getCDNAssetURL } from 'shared/lib'
import { links } from 'shared/constants/links'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import styles from './styles.module.scss'
import { useCallingStepsContext } from '../../context/callingStepsContext'

export const EnableStirShaken = observer(() => {
  const {
    stirShakenStore: {
      isTermsAndPrivacyChecked,
      loading,
      toggleIsTermsAndPrivacyChecked,
      submitStirShaken,
    },
  } = useCallingStepsContext()

  return (
    <div>
      <PageDescription>Increase answer rates by showing “Caller Verified”</PageDescription>

      <img
        src={getCDNAssetURL(callingStirShaken)}
        alt={'callingStirShaken_image'}
        className={styles.callingStirShakenImage}
      />
      <InputCheckbox
        className={styles.declaration}
        checked={isTermsAndPrivacyChecked}
        onChecked={() => toggleIsTermsAndPrivacyChecked(!isTermsAndPrivacyChecked)}
        color='var(--content-primary-tertiary)'
        label={
          <>
            I certify that the associated Business Profile is the originator of the phone calls and
            certify that I will participate in traceback efforts, including those initiated by the
            <a
              href={links.complianceStirShakenPolicy}
              target='_blank'
              className={styles.linkTag}
              rel='noreferrer'
            >
              Secure Telephony Identity Policy Administrator (STI-PA)
            </a>
            and the
            <a
              href={links.complianceStirShakenTelecom}
              target='_blank'
              className={classNames(styles.linkTag, styles.secondLinkTag)}
              rel='noreferrer'
            >
              US Telecom Traceback Group
            </a>
            .
          </>
        }
      />

      <NextButton
        text='Submit for review'
        loading={loading}
        disabled={!isTermsAndPrivacyChecked || loading}
        onClick={submitStirShaken}
      />
    </div>
  )
})
