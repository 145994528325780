// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KCtiMGfWEjw8Wax1tUSs{height:100%;overflow:auto}.OV4IwZp9YqPannvBc1ZI{display:grid;grid-template-rows:34px 16px max-content;width:760px;padding:64px 20px 0;margin:0 auto}.oFmzSPxHFs0wzj8WiDx9{margin-left:4px}.SnQDybxGERdGB5PXpXut{display:inline-flex;align-items:start;margin-right:4px}.nkUUZ8jokxUCy_ZgEJSW{margin-right:4px}.bI0NuhqvEyjYA3aC32pQ{margin:0 2px}.u_1BGpXBzZ8egS0uKPUA{margin-right:8px}.cD0vCjzYM64q4H3odNNo{display:flex;align-items:center}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,aAAA,CAGF,sBACE,YAAA,CACA,wCAAA,CACA,WAAA,CACA,mBAAA,CACA,aAAA,CAGF,sBACE,eAAA,CAGF,sBACE,mBAAA,CACA,iBAAA,CACA,gBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,YAAA,CAEF,sBACE,gBAAA,CAEF,sBACE,YAAA,CACA,kBAAA","sourcesContent":[".wrap {\n  height: 100%;\n  overflow: auto;\n}\n\n.content {\n  display: grid;\n  grid-template-rows: 34px 16px max-content;\n  width: 760px;\n  padding: 64px 20px 0;\n  margin: 0 auto;\n}\n\n.editBtn {\n  margin-left: 4px;\n}\n\n.tollFreeVerificationDesc {\n  display: inline-flex;\n  align-items: start;\n  margin-right: 4px;\n}\n\n.flag {\n  margin-right: 4px;\n}\n\n.arrow {\n  margin: 0 2px;\n}\n.trustedCallingTitleText {\n  margin-right: 8px;\n}\n.trustedCallingTitle {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "KCtiMGfWEjw8Wax1tUSs",
	"content": "OV4IwZp9YqPannvBc1ZI",
	"editBtn": "oFmzSPxHFs0wzj8WiDx9",
	"tollFreeVerificationDesc": "SnQDybxGERdGB5PXpXut",
	"flag": "nkUUZ8jokxUCy_ZgEJSW",
	"arrow": "bI0NuhqvEyjYA3aC32pQ",
	"trustedCallingTitleText": "u_1BGpXBzZ8egS0uKPUA",
	"trustedCallingTitle": "cD0vCjzYM64q4H3odNNo"
};
export default ___CSS_LOADER_EXPORT___;
