import { makeAutoObservable, reaction, type IReactionDisposer, runInAction } from 'mobx'
import { RequestLoadingStatus } from 'shared/store/RequestLoadingStatus'
import { uiStore } from 'shared/store/uiStore'
import { showToast } from 'shared/ui'
import { TableStore } from 'shared/ui/Table'
import { usersStore } from 'entities/Users'
import { IntegrationCrmUser } from 'entities/Integrations/model/IntegrationCrmUser'
import {
  type ICrmUser,
  type ICrmUserIntegrationMeta,
  Integration,
  IntegrationsApi,
  type IParamsPagination,
} from 'entities/Integrations'

const defaultPagination = {
  page: 1,
  limit: 10,
}

export class IntegrationSettingsUsersStore {
  private _status = new RequestLoadingStatus()
  private _isLoading = false
  private _updateStatus = new RequestLoadingStatus()
  private _disposeChangePagination: IReactionDisposer | null = null
  private _disposeSearch: IReactionDisposer | null = null
  private _userCount = 0
  private _mappedUsersCount = 0
  private _total = 0
  private _paginationData = defaultPagination
  private _search = ''
  private _users = new Map<number, IntegrationCrmUser<ICrmUserIntegrationMeta>>()
  private _tableStore = new TableStore<IntegrationCrmUser<ICrmUserIntegrationMeta>>({
    withoutDefaultManageColumns: true,
  })

  constructor(private _integration: Integration) {
    makeAutoObservable(this)
  }

  init() {
    this.getUsersData()
    this._reactionChange()
    usersStore.initUsersOrganization()
  }

  private _reactionChange = () => {
    this._disposeChangePagination?.()
    this._disposeSearch?.()
    this._disposeChangePagination = reaction(
      () => this._paginationData,
      () => this.getUsersData()
    )
    this._disposeSearch = reaction(
      () => this.search,
      () =>
        this.getUsersData({
          page: 1,
          limit: this._paginationData.limit,
          query: this._search,
        }),
      {
        delay: 500,
      }
    )
  }

  dispose = () => {
    this._paginationData = defaultPagination
    this._total = 0
    this._disposeChangePagination?.()
    this._disposeSearch?.()
  }

  getUsersData = (
    params: IParamsPagination = {
      ...this._paginationData,
      query: this._search,
    }
  ) =>
    this._status.loadData(async () => {
      if (!this.search) {
        delete params.query
      }

      const { data } = await IntegrationsApi.getIntegrationUsersList(this._integration.key, params)

      runInAction(() => {
        this._users.clear()
        this._total = data.total
        this._mappedUsersCount = data.meta.mapped
        this._userCount = data.meta.total_items
        data.data.forEach((user) => {
          this.setUser(user)
        })
      })
    })

  updateUserBinding = (userId: number, memberId: number) =>
    this._updateStatus.loadData(async () => {
      const { data } = await IntegrationsApi.patchIntegrationUserFieldValue(
        this._integration.key,
        userId,
        {
          attribute: 'user_id',
          value: memberId,
        }
      )

      runInAction(() => {
        this.setUser(data)
        if (data.user_id) {
          this._mappedUsersCount += 1
        } else {
          this._mappedUsersCount -= 1
        }
      })
      showToast({
        type: 'success',
        title: data.user_id ? 'Account Linked' : 'Account Unlinked',
      })
    })

  setUser(user: ICrmUser<ICrmUserIntegrationMeta>) {
    this._users.set(user.id, new IntegrationCrmUser<ICrmUserIntegrationMeta>(user))
  }

  sendRefreshRequest = () => {
    this._isLoading = true
    IntegrationsApi.activateIntegrationRefresh(this._integration.key)
  }

  refreshData = async () => {
    await Promise.all([usersStore.initUsersOrganization(), this.getUsersData()])
    this._isLoading = false
    showToast({
      type: 'success',
      title: 'Users refreshed',
    })
  }

  onChangePagination = (page: number, limit: number) => {
    this._paginationData = {
      page,
      limit,
    }
  }

  onSearchChange = (search: string) => {
    this._search = search
  }

  onUserChange = (userId: number) => (memberId: number) => {
    const row = this._users.get(userId)
    if (this._tableStore.selectedIds.includes(userId) && row) {
      this._tableStore.toggleSelected(row, false)
    }

    this.updateUserBinding(userId, memberId)
  }

  private _onInvite = (userIds: number[]) => {
    uiStore.changeRouteOnlyVue('push', {
      path: '/settings/organization/members/active/invite',
      name: 'settings.organization.members.invite',
      params: {
        preselect: JSON.stringify({
          userIds: userIds,
          integrationKey: this._integration.key,
        }),
        tab: 'active',
      },
    })
  }

  onGroupInvite = () => {
    this._onInvite(this._tableStore.selectedIds as number[])
  }

  onUserInvite = (userId: number) => {
    this._onInvite([userId])
  }

  onManageInvite = (userId: number) => {
    uiStore.changeRouteViaVue({
      path: '/settings/organization/members/pending',
      query: `?uid=${userId}`,
    })
  }

  get search() {
    return this._search
  }

  get users() {
    return Array.from(this._users.values())
  }

  get organizationMembers() {
    return usersStore.users.filter((user) => Boolean(user.email))
  }

  get status() {
    return this._status.status
  }

  get total() {
    return this._total
  }

  get usersCount() {
    return this._userCount
  }

  get mappedUsersCount() {
    return this._mappedUsersCount
  }

  get integrationName() {
    return this._integration?.name || ''
  }

  get userInfo() {
    return this.usersCount
      ? {
          subtitle: `(${this.mappedUsersCount}/${this.usersCount})`,
          label: `There are ${this.usersCount - this.mappedUsersCount} more ${
            this._integration.name
          } users that don’t have a Salesmsg account`,
        }
      : {
          subtitle: '(0)',
          label: `There are no ${this._integration.name} users`,
        }
  }

  get pagination() {
    return this._paginationData
  }

  get tableStore() {
    return this._tableStore
  }

  get isLoading() {
    return this._isLoading || this._status.status.isLoading
  }
}
