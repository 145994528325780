// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Kj0exNpg3wZB9aJhgGkw{min-height:unset;padding:16px 20px 12px 20px}.C9bjm6OU0TA0khxbl7Nb{padding-left:20px;margin-bottom:32px}.LsfhHjaerR41P_5_7nIw{display:flex;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/detail/CampaignDetail/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,2BAAA,CAEF,sBACE,iBAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,WAAA","sourcesContent":[".header {\n  min-height: unset;\n  padding: 16px 20px 12px 20px;\n}\n.wrapStatus {\n  padding-left: 20px;\n  margin-bottom: 32px;\n}\n.content {\n  display: flex;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Kj0exNpg3wZB9aJhgGkw",
	"wrapStatus": "C9bjm6OU0TA0khxbl7Nb",
	"content": "LsfhHjaerR41P_5_7nIw"
};
export default ___CSS_LOADER_EXPORT___;
