import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { PageLayoutStore } from 'shared/layout'
import { toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { Segment } from 'entities/Segment/model/Segment'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { IResponseFilterSegment, segmentsStore } from 'entities/Segment'
import { SegmentModalStore } from 'pages/contacts/ui/ContactsMenu/ui/SegmentModal'
import { ContactsPath } from 'pages/contacts/route/contactsPath'
import { urlSegmentId } from 'pages/contacts/constants/constants'
import { menuSegmentsStore } from './menuSegmentsStore'

type IContactsMenuStoreConfig = {
  setActiveSegment: (segment: Segment) => void
  changeActiveSegment: (segment: Segment) => void
  resetActive: () => void
  onActionAllContacts: () => void
  onActionChangeSegment: () => void
  onActionFilterSegmentBoxSetHasChanges: (value: boolean) => void
  onActionFilterOpen: (value: boolean) => void
  onChangeTitle?: (value: string) => void
}

export class ContactsMenuStore {
  initPromise
  setFinishInit: ((value?: unknown) => void) | null = null
  private _search = ''
  private _segmentsSearch = ''
  private _hubspotListsSearch = ''
  private _favoritesSearch = ''
  private _activeSegment: Segment | null = null
  private _config: IContactsMenuStoreConfig | null = null
  private _noEmptyFilters: IResponseFilterSegment[] = []
  private _segmentBoxHasChanges = false

  constructor(
    private _segmentModalStore: SegmentModalStore,
    private _pageLayoutStore: PageLayoutStore
  ) {
    this.initPromise = new Promise((resolve) => {
      this.setFinishInit = resolve
    })

    makeAutoObservable(this)
  }

  get title() {
    if (!this.activeSegment) return 'All contacts'
    if (this.activeSegment.id === -1) return 'New filter'

    return this.activeSegment.name
  }

  get search() {
    return this._search
  }

  get segmentBoxHasChanges() {
    return this._segmentBoxHasChanges
  }

  get segmentsSearch() {
    return this._segmentsSearch
  }

  get hubspotListsSearch() {
    return this._hubspotListsSearch
  }

  get favoritesSearch() {
    return this._favoritesSearch
  }

  get segmentsAll() {
    return menuSegmentsStore.items.filter((filter) =>
      filter.name
        .toLocaleLowerCase()
        .includes(this._segmentsSearch.toLocaleLowerCase() || this._search.toLocaleLowerCase())
    )
  }

  get segments() {
    return menuSegmentsStore.items
      .filter((filter) => !filter.isFavorite)
      .filter((filter) => !filter.hasHubspotFilter)
      .filter((filter) =>
        filter.name
          .toLocaleLowerCase()
          .includes(this._segmentsSearch.toLocaleLowerCase() || this._search.toLocaleLowerCase())
      )
  }

  get hubspotLists() {
    return this.hasHubspotConnected()
      ? menuSegmentsStore.items
          .filter((filter) => !filter.isFavorite)
          .filter((filter) => filter.hasHubspotFilter)
          .filter((filter) =>
            filter.name
              .toLocaleLowerCase()
              .includes(
                this._hubspotListsSearch.toLocaleLowerCase() || this._search.toLocaleLowerCase()
              )
          )
      : []
  }

  get favorites() {
    return menuSegmentsStore.items
      .filter((filter) => {
        if (!this.hasHubspotConnected()) {
          return !filter.hasHubspotFilter
        }

        return true
      })
      .filter((filter) => filter.isFavorite)
      .filter((filter) =>
        filter.name
          .toLocaleLowerCase()
          .includes(this._favoritesSearch.toLocaleLowerCase() || this._search.toLocaleLowerCase())
      )
  }

  get activeSegment() {
    return this._activeSegment
  }

  get isNewSegment() {
    return this._activeSegment?.id === -1
  }

  get activeSegmentId() {
    return this._activeSegment?.id ?? null
  }

  setSegmentBoxHasChanges = (value: boolean) => {
    this._segmentBoxHasChanges = value
  }

  setNoEmptyFilters = (value: IResponseFilterSegment[]) => {
    this._noEmptyFilters = value
  }

  setConfig = (config: IContactsMenuStoreConfig) => {
    this._config = config
  }

  setActiveSegment = (segment: Segment | null) => {
    this._activeSegment = segment
    if (segment) this._config?.setActiveSegment(segment)
  }

  changeActiveSegment = (segment: Segment) => {
    this.setActiveSegment(segment)
    this._config?.setActiveSegment(segment)
  }

  dispose = () => {}

  resetActive = () => {
    this._config?.resetActive()
  }

  private _navToAllContacts = () => {
    this.resetActive()
    this._config?.onActionAllContacts()

    uiStore.changeRoute({
      path: ContactsPath.root,
    })
  }

  navToAllContactsAsk = () => {
    if (!this.isNewSegment && this._segmentBoxHasChanges) {
      this._segmentModalStore.openAskIfSaveModal({
        onToggle: this._navToAllContacts,
        noSetActive: true,
        filtersList: this._noEmptyFilters,
      })
    } else {
      this._navToAllContacts()
    }
  }

  private _onActionChangeSegment = (segment: Segment) => {
    uiStore.changeRoute({
      path: `${ContactsPath.root}?${urlSegmentId}=${segment.id}`,
    })

    this.changeActiveSegment(segment)
    this._config?.onActionChangeSegment()
  }

  onActionChangeSegmentAsk = (segment: Segment) => {
    if (this.activeSegment?.id === segment.id) return

    if (!this.isNewSegment && this._segmentBoxHasChanges) {
      this._segmentModalStore.openAskIfSaveModal({
        onToggle: () => {
          this._onActionChangeSegment(segment)
        },
        noSetActive: true,
        filtersList: this._noEmptyFilters,
      })
    } else {
      this._onActionChangeSegment(segment)
    }
  }

  onActionFilterSegmentBoxSetHasChanges = (value: boolean) => {
    this._config?.onActionFilterSegmentBoxSetHasChanges(value)
  }

  onActionFilterOpen = (value: boolean) => {
    this._config?.onActionFilterOpen(value)
  }

  setSearch = (value: string) => {
    this._search = value
  }

  setSegmentsSearch = (value: string) => {
    this._segmentsSearch = value
  }
  setHubspotListsSearch = (value: string) => {
    this._hubspotListsSearch = value
  }
  setFavoritesSearch = (value: string) => {
    this._favoritesSearch = value
  }

  hasHubspotConnected = () => {
    const integration = integrationsStore.getIntegration(IntegrationKey.hubspot)

    return !!integration?.isConnected
  }

  editFavorite = async ({ afterEdit, segment }: { afterEdit?: () => void; segment: Segment }) => {
    if (!segment) return

    try {
      await segmentsStore.updateItem(segment.id, {
        is_favorite: !segment.isFavorite,
      })

      afterEdit?.()
    } catch (e) {
      this.errorHandler(e as AxiosError<{ name?: string[] }>)
    }
  }

  errorHandler = (error: AxiosError<{ name?: string[] }>) => {
    const errorMessage = error.response?.data?.name ? error.response.data.name[0] : error.message

    toastStore.add({
      title: errorMessage,
      type: 'error',
    })
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get segmentModalStore() {
    return this._segmentModalStore
  }
}
