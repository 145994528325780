import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { logger, validPhoneNumber } from 'shared/lib'
import { links } from 'shared/constants/links'
import { toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { callStore } from 'entities/Call'
import { contactsStore } from 'entities/Contacts'
import { conversationStore } from 'entities/Conversation'
import { Contact } from 'entities/Contacts/model/Contact'
import { type IParamsConversationCreate } from 'entities/Conversation/api/types'
import { type IParamsCreateContact } from 'entities/Contacts/api/types'
import { subscriptionStore } from 'entities/Subscription'
import { inboxesStore } from 'entities/Inbox'
import { CreateContactErrors } from 'entities/Subscription/ui/TrialContactsLimitModal'
import { userPermissionsStore, FeatureRestrictedModalStore } from 'entities/UsersPermissions'
import { remindersStore } from 'widgets/Reminders'
import { NumbersInboxesListStore } from 'widgets/NumbersInboxesList'
import { CallModalActions } from 'widgets/CallModal/ui/CallModalActions'
import { CallModalContent } from 'widgets/CallModal/ui/CallModalContent'
import { EnumNumbersInboxesDropDown } from 'widgets/NumbersInboxesList/store'
import { type ICallModalStoreConfig } from './types'

export class CallModalStore {
  private _idModal = 'call_modal'
  private _loadingDeviceSettings = false
  private _contact: Contact | null = null
  private _contactId: number | null = null
  private _inboxId: string | number | null = null
  private _numberId: number | null = null
  private _numberError = ''
  private _search = ''
  private _loading = false
  private _disposeSearch: IReactionDisposer | null = null
  private _numbersInboxesListStore = new NumbersInboxesListStore({
    variant: EnumNumbersInboxesDropDown.Call,
  })
  private _onSubmit: ICallModalStoreConfig['onSubmit'] | null = null
  public setHideTrialAlertNotForOwner:
    | ICallModalStoreConfig['setHideTrialAlertNotForOwner']
    | null = null

  constructor(config?: ICallModalStoreConfig) {
    makeAutoObservable(this)

    this.setConfig(config)
  }

  setConfig = (config?: ICallModalStoreConfig) => {
    this._onSubmit = config?.onSubmit
    this.setHideTrialAlertNotForOwner = config?.setHideTrialAlertNotForOwner
  }

  openModal = () => {
    modalStore.addModal({
      id: this._idModal,
      title: 'Make a call',
      showCloseButton: false,
      showCloseIcon: false,
      ModalActions: CallModalActions,
      ModalContent: CallModalContent,
      ModalContentProps: {
        callModalStore: this,
      },
      onClose: this.closeModal,
    })
  }

  closeModal = (reset = true) => {
    this._contact = null
    this._contactId = null
    this._inboxId = null
    this._numberId = null
    this._search = ''
    this._numberError = ''
    this._disposeSearch?.()

    if (reset) {
      this.reset()
    }

    modalStore.removeModal(this._idModal)
  }

  init = async () => {
    if (inboxesStore.currentInbox && inboxesStore.currentInbox.type === 'inbox') {
      this.handleSetActiveNumber(
        inboxesStore.currentInbox.id,
        inboxesStore.currentInbox.isSmartInbox ? 0 : inboxesStore.currentInbox.numberId
      )
    }

    try {
      this._loadingDeviceSettings = true

      const isAllowed = await callStore.deviceSettingsStore.checkPermissions(true)

      if (isAllowed) {
        await callStore.deviceSettingsStore.syncDevicesStream()

        callStore.reactionSelectedAudioOutput()
        callStore.reactionSelectedAudioInput()
        this.reactionSearch()
      } else {
        toastStore.add({
          type: 'error',
          title: 'Microphone access required',
          desc: 'To enable recording, please allow Salesmsg to access your microphone',
          action: {
            link: links.enableMicrophone,
            text: 'Learn more',
          },
        })
      }
    } catch (e) {
      logger.error(e)
    } finally {
      this._loadingDeviceSettings = false
    }
  }

  reset = () => {
    callStore.deviceSettingsStore.removeStream()
    callStore.stopStreamInputDeviceTrack()
  }

  handleClearValue = () => {
    this._contact = null
    this._contactId = null
    this._search = ''
  }

  handleChangeValue = (value: string, contact?: Contact | null) => {
    this._contact = contact || null
    this._contactId = contact?.id || null
    this._search = value
  }

  handleChangeInbox = (inboxId: string | number, numberId: number) => {
    this._inboxId = inboxId
    this._numberId = numberId
  }

  handleSubmit = async () => {
    try {
      this._loading = true
      this._numberError = ''

      if (!this._search) return

      if (this._search) {
        const isValidNumber = validPhoneNumber(this._search)

        if (!isValidNumber) {
          this._numberError = 'Please enter a valid phone number'

          return
        }
      }

      if (!this._contactId) {
        try {
          const fromConversation = uiStore.pathName.includes('conversations')
          const isNoSearchInAllContacts = fromConversation && subscriptionStore.isTrial
          let contact =
            !isNoSearchInAllContacts && (await contactsStore.getContactByNumber(this._search))

          if (!contact) {
            if (userPermissionsStore.getItem('can_create_contacts')) {
              const params: IParamsCreateContact = {
                number: this._search,
                fromConversation,
              }

              contact = await contactsStore.createContact(
                params,
                fromConversation
                  ? {
                      catchError: CreateContactErrors.trialLimit,
                    }
                  : undefined
              )
            } else {
              modalStore.setHideModalId(this._idModal)
              new FeatureRestrictedModalStore(() => {
                modalStore.removeHideModalId(this._idModal)
              })
            }
          }

          this._contactId = contact ? contact.id : null
        } catch (e) {
          logger.error(e)
        }
      }

      if (!this._inboxId) {
        this._numbersInboxesListStore?.setError(true)

        return
      }

      if (this._contactId) {
        const params: IParamsConversationCreate = {
          contact_id: this._contactId,
          team_id: this._inboxId,
        }

        if (this._numberId) {
          params.number_id = this._numberId
        }

        const conversation = await conversationStore.createConversation(params)

        if (conversation) {
          this._onSubmit?.(conversation.inbox_id)
          this.closeModal(false)
          await callStore.connectTwilio(conversation.id)
        }
      }
    } catch (e) {
      logger.error(e)
    } finally {
      this._loading = false
    }
  }

  reactionSearch = () => {
    this._disposeSearch?.()
    this._disposeSearch = reaction(
      () => this._search,
      (value) => {
        this._numberError = ''

        if (!value) {
          this._contact = null
        }
      }
    )
  }

  getIsAircallInbox = () => {
    const inbox = inboxesStore.getItem(this._inboxId)
    return inbox?.type === 'inbox' && inbox?.isAircall
  }

  handleSetActiveNumber = (inboxId: number, numberId: number) => {
    this.handleChangeInbox(inboxId, numberId)
    this._numbersInboxesListStore.setActiveNumber(inboxId, numberId)
  }

  get disabledButton() {
    const isAircallInbox = this.getIsAircallInbox()
    const isOutOfMoney = remindersStore.creditsStore.isOutOfMoney
    if (this._loading) return true
    if (isOutOfMoney && !isAircallInbox) return true
    if (callStore.isBusy) return true
    if (this.isNumberNotVerifiedCallerId) return true

    return !this._search.length && !this._contact
  }

  get callButtonTooltip() {
    const isAircallInbox = this.getIsAircallInbox()
    const isOutOfMoney = remindersStore.creditsStore.isOutOfMoney
    if (this.callerIdFailedReason) {
      return {
        label: this.callerIdFailedReason,
        fullWidth: true,
      }
    }

    return isOutOfMoney && !isAircallInbox
      ? {
          label: 'Your organization is out of message credits',
          fullWidth: true,
        }
      : undefined
  }

  get contact() {
    return this._contact
  }

  get numberError() {
    return this._numberError
  }

  get numbersInboxesListStore() {
    return this._numbersInboxesListStore
  }

  get isNumberNotVerifiedCallerId() {
    const inbox = inboxesStore.getItem(this._inboxId)

    return inbox?.type === 'inbox' && inbox?.isNumberNotVerifiedCallerId
  }

  get callerIdFailedReason() {
    const inbox = inboxesStore.getItem(this._inboxId)

    return inbox?.type === 'inbox' ? inbox?.callerIdFailedReason : null
  }
}
