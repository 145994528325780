import { ReactNode, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DropdownCard, type IDropdownItem, type IIconsVector, Status } from 'shared/ui'
import { type IContactsDataParams } from 'entities/Contacts/api/types'
import { usePowerDialerAvailability } from 'widgets/PowerDialer/ui/PowerDialerEditor/hooks/usePowerDialerAvailability'
import { PowerDialerEditorStore } from 'widgets/PowerDialer/ui/PowerDialerEditor/store/powerDialerEditorStore'
import styles from './styles.module.scss'

type IPowerDialerActionDropdownItemProps = {
  onClick?: () => void
  contactsData?: IContactsDataParams
  variant: 'dropdownItem' | 'button'
}

function getTooltipProps(options: {
  fromContacts: boolean
  tooltipInfo?: ReactNode
  tooltipAvailability: ReactNode
}) {
  const { tooltipInfo, tooltipAvailability, fromContacts } = options

  if (fromContacts && tooltipAvailability) {
    return {
      tooltipProps: {
        placement: 'right' as const,
        label: tooltipAvailability,
      },
    }
  }

  if (!fromContacts) {
    return {
      iconQuestionProps: {
        fontSize: 14,
      },
      tooltipQuestionProps: {
        margin: 18,
        placement: 'right' as const,
        label: tooltipAvailability || tooltipInfo,
      },
    }
  }

  return {}
}

export const PowerDialerEditorAction = observer(
  ({ onClick, contactsData, variant }: IPowerDialerActionDropdownItemProps) => {
    const text = 'Power Dialer'
    const icon: IIconsVector = 'phoneRightArrows'

    const fromContacts = !!contactsData
    const [store] = useState(() => new PowerDialerEditorStore())

    const [loading, setLoading] = useState(false)

    const { hideButton, disabled, getTooltipAvailability, getTooltipInfo } =
      usePowerDialerAvailability()
    const isDropdownItemDisabled = disabled || store.loading
    const tooltipAvailability = getTooltipAvailability()
    const tooltipInfo = getTooltipInfo()

    if (hideButton) return null

    const handleClick = async () => {
      setLoading(true)
      if (fromContacts) {
        await store.handleStartPowerDialerEditorFromContacts(contactsData)
      } else {
        store.handleStartPowerDialerEditorFromFilters()
      }
      setLoading(false)
      onClick?.()
    }

    if (variant === 'dropdownItem') {
      const item: IDropdownItem = {
        id: 'power_dialer',
        iconL: icon,
        label: text,
        disabled: isDropdownItemDisabled,
        labelContent: (
          <div className={styles.dropdownLabel}>
            Power Dialer
            <Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />
          </div>
        ),
        ...getTooltipProps({
          tooltipInfo,
          tooltipAvailability,
          fromContacts,
        }),
      }
      return <DropdownCard item={item} onChange={handleClick} />
    }

    return (
      <Button
        text={text}
        icon={icon}
        typeBtn='contained'
        contained='tertiary'
        size='small'
        onClick={handleClick}
        loading={loading}
        disabled={isDropdownItemDisabled}
        textRight={<Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />}
        {...getTooltipProps({
          tooltipInfo,
          tooltipAvailability,
          fromContacts,
        })}
      />
    )
  }
)
