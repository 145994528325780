// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OO2lXQSKd_e4TnYrX00U{padding:0 24px 16px 24px;display:flex;flex-direction:column;gap:8px}.r3XRaeO2SckOH5MXpMxZ{display:flex;justify-content:flex-end;gap:8px;padding:16px 24px 24px 24px}", "",{"version":3,"sources":["webpack://./src/entities/Campaign/ui/EnrollInCampaignModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CACA,OAAA,CACA,2BAAA","sourcesContent":[".content {\n  padding: 0 24px 16px 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.actions {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n  padding: 16px 24px 24px 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "OO2lXQSKd_e4TnYrX00U",
	"actions": "r3XRaeO2SckOH5MXpMxZ"
};
export default ___CSS_LOADER_EXPORT___;
