// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hxLtEVJTy3hZXWcVgRTM{visibility:hidden}.KOcWUMhCpeUGJDSwIOzv{margin-bottom:2px}.KOcWUMhCpeUGJDSwIOzv:hover .hxLtEVJTy3hZXWcVgRTM{visibility:visible}.Z0zFWBrLwmS3ZE20b55d .hxLtEVJTy3hZXWcVgRTM{visibility:visible}", "",{"version":3,"sources":["webpack://./src/pages/contacts/ui/ContactsMenu/ui/SegmentItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAEJ,sBACI,iBAAA,CAEA,kDACI,kBAAA,CAIJ,4CACI,kBAAA","sourcesContent":[".moreButton {\n    visibility: hidden;\n}\n.segment {\n    margin-bottom: 2px;\n\n    &:hover .moreButton {\n        visibility: visible;\n    }\n}\n.activeDropdown {\n    .moreButton {\n        visibility: visible;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moreButton": "hxLtEVJTy3hZXWcVgRTM",
	"segment": "KOcWUMhCpeUGJDSwIOzv",
	"activeDropdown": "Z0zFWBrLwmS3ZE20b55d"
};
export default ___CSS_LOADER_EXPORT___;
