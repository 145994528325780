import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { toastStore } from 'shared/ui'
import { Deferred, logger } from 'shared/lib'
import { WorkflowEngine, WorkflowsApi } from 'entities/Workflow'
import { WorkflowTemplateModal, WorkflowConfigModal } from 'widgets/CreateWorkflowModal/ui'
import { type IWorkflowTemplate, WorkflowTemplate } from 'widgets/CreateWorkflowModal/model'

export class CreateWorkflowStore {
  private _templateModalId = 'createWorkflowTemplateModal'
  private _configModalId = 'configWorkflowNameModal'

  constructor() {
    makeAutoObservable(this)
  }

  create = async (workflow = WorkflowTemplate.Create()) => {
    const deferred = new Deferred<boolean>()
    this._showTemplateStep(workflow, deferred)

    try {
      const completed = await deferred.future
      if (!completed || !workflow.templateName) return null

      const uiSettings = WorkflowEngine.getUiSettingsByName(workflow.templateName)
      if (!uiSettings) return null

      const definition = WorkflowEngine.uiSettingsToDefinition(uiSettings.data.children)

      const { data } = await WorkflowsApi.createWorkflow({
        description: '',
        multiple_executions: false,
        name: workflow.name,
        definition: definition,
        ui_settings: uiSettings,
      })

      return data.payload.id
    } catch (error) {
      toastStore.add({
        title: 'Failed to create workflow',
        type: 'error',
      })

      logger.error(error)

      return null
    }
  }

  private _showTemplateStep = (workflow: IWorkflowTemplate, deferred: Deferred<boolean>) => {
    const onNext = () => {
      modalStore.removeModal(this._templateModalId)
      this._showConfigStep(workflow, deferred)
    }

    const onCancel = () => {
      deferred.complete(false)
      modalStore.removeModal(this._templateModalId)
    }

    const onClose = () => {
      deferred.complete(false)
      modalStore.removeModal(this._templateModalId)
    }

    modalStore.addModal({
      id: this._templateModalId,
      title: 'Choose template',
      pureContent: true,
      ModalContent: WorkflowTemplateModal,
      ModalContentProps: { workflow, onNext, onCancel },
      width: 480,
      onClose,
    })
  }

  private _showConfigStep = (workflow: IWorkflowTemplate, deferred: Deferred<boolean>) => {
    const onNext = () => {
      modalStore.removeModal(this._configModalId)
      deferred.complete(true)
    }

    const onCancel = () => {
      modalStore.removeModal(this._configModalId)
      this._showTemplateStep(workflow, deferred)
    }

    const onClose = () => {
      deferred.complete(false)
      modalStore.removeModal(this._configModalId)
    }

    modalStore.addModal({
      id: this._configModalId,
      title: 'Create workflow',
      pureContent: true,
      ModalContent: WorkflowConfigModal,
      ModalContentProps: { workflow, onCancel, onNext },
      width: 480,
      onClose,
    })
  }
}
