import { ReactNode } from 'react'
import { makeAutoObservable, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import { debounce } from 'lodash'
import { dropDownStore, IDropdownItem } from 'shared/ui'
import { logger } from 'shared/lib'
import { IHubspotPropertiesObjectType, IntegrationsApi } from 'entities/Integrations'

import { IUiSettingsSourceType } from 'entities/Users/api/types'
import { MergeField } from 'widgets/MergeField/types/MergeField'
import { type HubspotIntegrationStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/store/hubspotIntegrationStore'
import { type IViewProperty } from 'widgets/ContactsDetails/ui/ContactsIntegrations/types'

export class NewHubspotPropertiesDropdownStore {
  private _search = ''
  private _error: ReactNode = null
  private _dropdownId: string | null = null
  private _itemsMap: Map<string, IDropdownItem> = new Map()
  private _cancelTokenSource: CancelTokenSource | null = null
  private _loading = true
  private _source_type: IUiSettingsSourceType | null = null

  constructor(private _hubspotIntegrationStore: HubspotIntegrationStore) {
    makeAutoObservable(this)
  }

  searchFields = (
    term: string,
    immediate = false,
    source_type: IUiSettingsSourceType,
    objectType?: IHubspotPropertiesObjectType,
    objectId?: number
  ) => {
    this._search = term
    this._loading = true
    this._source_type = source_type

    const makeRequest = async () => {
      runInAction(() => this._itemsMap.clear())

      const { data: fields } =
        objectType && objectId
          ? await IntegrationsApi.getHubspotFieldsByType({
              objectType,
              objectId,
              search: this._search,
            })
          : await IntegrationsApi.getHubspotFields(
              {
                contact_id: this._hubspotIntegrationStore.contact.integration_vendor_id,
                search: this._search,
              },
              { cancelToken: this._cancelTokenSource?.token }
            )

      runInAction(() => {
        fields
          .map((field) => new MergeField(field))
          .forEach((mergeField) => {
            this._itemsMap.set(mergeField.id, {
              id: mergeField.id,
              label: mergeField.label,
              labelRight: mergeField.value,
              labelWidth: mergeField.value ? 140 : '100%',
              value: mergeField.name,
              iconL: 'hubspot',
              data: mergeField,
              tooltipLabelRightProps: {
                label: mergeField.value,
                placement: 'left',
              },
            } as IDropdownItem)
          })
      })
    }

    immediate ? this._tryMakeRequest(makeRequest) : this._tryMakeRequestDelay(makeRequest)
  }

  private _initCancelTokenSource = () => {
    if (this._cancelTokenSource) this._cancelTokenSource.cancel()

    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _tryMakeRequest = async (action: () => Promise<void>) => {
    runInAction(() => {
      this._loading = true
      this._initCancelTokenSource()
    })

    try {
      await action()
      runInAction(() => {
        this._loading = false
      })
    } catch (error) {
      runInAction(() => {
        this._loading = error instanceof CanceledError
      })

      logger.error(error)
    }
  }

  private _tryMakeRequestDelay = debounce(this._tryMakeRequest, 500)

  setDropdownId = (id: string) => {
    this._dropdownId = id
  }

  hideDropDown = () => {
    if (this._dropdownId) {
      dropDownStore.hide(this._dropdownId)
    }
  }

  addToSelectedFields = async (key: string | number) => {
    const field = this._itemsMap.get(key.toString())
    const { name, label, value } = field?.data as MergeField

    const property: IViewProperty = { label, value, key: name }

    if (this._source_type) {
      try {
        this._hubspotIntegrationStore?.addDraggableHubspotItem?.(property, this._source_type)
      } catch (e) {
        logger.error(e)
      }
    }

    this.hideDropDown()
  }

  reset = () => {
    this._cancelTokenSource?.cancel()
    this._itemsMap.clear()
    this._loading = true
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  get properties() {
    return this.items.filter((item) => {
      const { name } = item?.data as MergeField
      if (this._source_type === 'hubspot-contacts-draggable-items') {
        return !this._hubspotIntegrationStore.draggableContactsItemsMap.includes(name)
      }

      if (this._source_type === 'hubspot-deals-draggable-items') {
        return !this._hubspotIntegrationStore.draggableDealsItemsMap.includes(name)
      }

      if (this._source_type === 'hubspot-companies-draggable-items') {
        return !this._hubspotIntegrationStore.draggableCompaniesItemsMap.includes(name)
      }

      if (this._source_type === 'hubspot-tickets-draggable-items') {
        return !this._hubspotIntegrationStore.draggableTicketsItemsMap.includes(name)
      }
    })
  }

  get loading() {
    return this._loading || this._hubspotIntegrationStore.loadingFields
  }

  get disabledAddButton() {
    return this._hubspotIntegrationStore.loading
  }

  get search() {
    return this._search
  }

  get error() {
    return {
      message: this._error,
    }
  }
}
