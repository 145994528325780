import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { logger } from 'shared/lib'
import { complianceStore } from 'entities/Compliance/store/complianceStore'
import { TenDLCStatus } from 'entities/Compliance/ui/TenDLCStatus'
import { adminTenDLCCampaignStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/Campaign/store/adminTenDLCCampaignStore'
import { AdminDetailCard, IAdminDetailCardRowProps } from 'pages/admin'
import { adminTenDLCDetailStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/adminTenDLCDetailStore'
import { getRejectReasonRow } from 'pages/admin/pages/compliance'

export const Campaign = observer(() => {
  const { setTenDLCData } = adminTenDLCDetailStore
  const {
    status,
    isForEdit,
    isSaveLoading,
    getError,
    registrationError,
    isRejected,

    vendor_id,
    edit_vendor_id,
    is_edit_vendor_id,
    set_edit_vendor_id,

    use_case,
    edit_use_case,
    is_edit_use_case,
    set_edit_use_case,

    use_case_description,
    edit_use_case_description,
    is_edit_use_case_description,
    set_edit_use_case_description,

    messageFlow,
    edit_messageFlow,
    is_edit_messageFlow,
    set_edit_messageFlow,

    sample_message_1,
    edit_sample_message_1,
    is_edit_sample_message_1,
    set_edit_sample_message_1,

    sample_message_2,
    edit_sample_message_2,
    is_edit_sample_message_2,
    set_edit_sample_message_2,

    opt_int_types,
    edit_opt_int_types,
    is_edit_opt_int_types,
    set_edit_edit_opt_int_types,

    opt_in_methods,
    edit_opt_in_methods,
    is_edit_opt_in_methods,
    remove_opt_in_attachment,
    add_opt_in_file,

    onEdit,
    isEdit,
    onSave,
    onClose,
  } = adminTenDLCCampaignStore

  const data: IAdminDetailCardRowProps[] = [
    {
      title: 'Twilio id',
      value: vendor_id,
      editValue: edit_vendor_id,
      isUnsaved: is_edit_vendor_id,
      onChange: set_edit_vendor_id,
      editError: getError('vendor_id'),
    },
    ...getRejectReasonRow(isRejected, registrationError),
    {
      title: 'Campaign category',
      value: use_case,
      editValue: edit_use_case,
      isUnsaved: is_edit_use_case,
      onChange: set_edit_use_case,
      typeEdit: 'select',
      options: complianceStore.tenDLCUseCases,
      loadOptions: complianceStore.loadTenDLCUseCases,
      editError: getError('use_case_id'),
    },
    {
      typeEdit: 'textarea',
      title: 'Campaign description',
      value: use_case_description,
      editValue: edit_use_case_description,
      isUnsaved: is_edit_use_case_description,
      onChange: set_edit_use_case_description,
      editError: getError('useCaseData.description'),
    },
    {
      typeEdit: 'textarea',
      title: 'Message flow',
      value: messageFlow,
      editValue: edit_messageFlow,
      isUnsaved: is_edit_messageFlow,
      onChange: set_edit_messageFlow,
      editError: getError('messageFlow'),
    },
    {
      typeEdit: 'textarea',
      title: 'Sample message 1',
      value: sample_message_1,
      editValue: edit_sample_message_1,
      isUnsaved: is_edit_sample_message_1,
      onChange: set_edit_sample_message_1,
      editError: getError('sample_message_1'),
    },
    {
      typeEdit: 'textarea',
      title: 'Sample message 2',
      value: sample_message_2,
      editValue: edit_sample_message_2,
      isUnsaved: is_edit_sample_message_2,
      onChange: set_edit_sample_message_2,
      editError: getError('sample_message_2'),
    },
    {
      title: 'Opt-in method',
      value: opt_int_types,
      editValue: edit_opt_int_types,
      isUnsaved: is_edit_opt_int_types,
      onChange: set_edit_edit_opt_int_types,
      typeEdit: 'multiSelect',
      options: complianceStore.optInTenDLCTypes,
      loadOptions: complianceStore.loadOptInTenDLCTypes,
      editError: getError('opt_int_types'),
    },
    ...(isEdit
      ? (edit_opt_in_methods.map((item) => ({
          typeEdit: 'attachments',
          title: item.title,
          editValue: item.attachments,
          isUnsaved: is_edit_opt_in_methods.includes(item.id),
          removeAttachments: (ids: Array<string | number>) => {
            remove_opt_in_attachment(item.id, ids[0])
          },
          onAddFile: (file: File) => {
            add_opt_in_file(item.id, file)
          },
          value: item.attachments,
        })) as IAdminDetailCardRowProps[])
      : (opt_in_methods.map((item) => ({
          typeEdit: 'attachments',
          title: item.title,
          value: item.attachments,
        })) as IAdminDetailCardRowProps[])),
  ]

  const handleSave = async () => {
    try {
      const res = await onSave()
      if (res?.data) {
        setTenDLCData(res?.data)
      }
      onClose()
    } catch (e) {
      logger.error(e)
    }
  }

  return (
    <AdminDetailCard
      title={'Campaign'}
      data={data}
      additionalTitleContent={status && <TenDLCStatus status={status} />}
      padding={'24px 16px'}
      isEdit={isEdit}
      actions={
        isForEdit &&
        (!isEdit ? (
          <Button
            text={'Edit'}
            contained={'secondary'}
            size={'medium'}
            icon={'edit'}
            onClick={onEdit}
          />
        ) : (
          <>
            <Button text={'Cancel'} contained={'secondary'} size={'medium'} onClick={onClose} />
            <Button
              text={'Save'}
              contained={'primary'}
              size={'medium'}
              onClick={handleSave}
              disabled={isSaveLoading}
            />
          </>
        ))
      }
    />
  )
})
