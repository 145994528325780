import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { uiStore } from 'shared/store/uiStore'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { IntegrationKey } from 'entities/Integrations'
import { useIntegrationDetailsContext } from 'pages/settings/pages/integrations/context/integrationDetailsContext'
import { IntegrationsRoutes } from 'pages/settings/pages/integrations/route/IntegrationsRoutes'
import { SettingsRoutes } from 'pages/settings/route/type'

export const IntegrationDetailsIndexRedirect = observer(() => {
  const { integration } = useIntegrationDetailsContext()
  const { salesmsg_design_v2_salesforce } = featureFlagsStore

  useEffect(() => {
    if (!integration) return

    if (integration.key === IntegrationKey.webhook) {
      uiStore.changeRouteViaVue({
        path: `/${SettingsRoutes.root}/${SettingsRoutes.integrations}/${IntegrationsRoutes.webhooks}`,
        method: 'replace',
      })
      return
    }

    if (
      integration.isConnectedSettingsAvailable ||
      (integration.key === 'salesforce' && salesmsg_design_v2_salesforce)
    ) {
      uiStore.changeRouteViaVue({
        path: `/${SettingsRoutes.root}/${SettingsRoutes.integrations}/${integration.key}/${IntegrationsRoutes.settings}`,
        method: 'replace',
      })
    } else {
      uiStore.changeRouteViaVue({
        path: `/${SettingsRoutes.root}/${SettingsRoutes.integrations}/${integration.key}/${IntegrationsRoutes.description}`,
        method: 'replace',
      })
    }
  }, [integration])

  return null
})
