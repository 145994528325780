import { useMemo } from 'react'
import { IActionsProps, variantActionsProps } from 'shared/ui'
import { PowerDialer } from 'entities/PowerDialer/model/PowerDialer'
import { getPowerDialerActions } from 'widgets/PowerDialer/lib/getPowerDialerActions'
import { usePowerDialerListContext } from 'pages/powerDialer/pages/list/context/powerDialerListContext'

type IPowerDialerWithActionsRow = PowerDialer & {
  actionsProps: IActionsProps
}

export const usePowerDialerWithActionsRows: () => IPowerDialerWithActionsRow[] = () => {
  const { items, onDelete } = usePowerDialerListContext()

  return useMemo(() => {
    return items.map((item) => {
      const actionDisabled = item.status === 'in_progress'
      const actions = getPowerDialerActions(item, () => onDelete(item.id))
      return {
        ...item,
        actionsProps: {
          ...variantActionsProps.table,
          actions,
          disabled: actionDisabled,
          moreActionsTooltipProps: {
            ...(actionDisabled && {
              label: 'Power Dialer session in progress',
              maxWidth: '150px',
            }),
          },
        },
      }
    })
  }, [items])
}
