// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IPC0xtVq858eYyRGYhUY{padding:0 44px;overflow-y:auto;overflow-x:hidden}.o2HNNFobWBFXuA_omFZS{padding:0}.luJ5VLLQSrnjVFJfj389{display:flex;flex-direction:column;justify-content:center;flex:1;width:100%;max-width:760px;margin:0 auto;padding:0 16px}.o2HNNFobWBFXuA_omFZS .luJ5VLLQSrnjVFJfj389{max-width:100%}.luJ5VLLQSrnjVFJfj389.A36xH_WoHNgOaYJltmrj{max-width:492px;padding:0}.o2HNNFobWBFXuA_omFZS .luJ5VLLQSrnjVFJfj389.A36xH_WoHNgOaYJltmrj{padding:0 16px;justify-content:flex-start;max-width:100%}.bdpDb1R0Z87FB1IZjIMw{padding:10px}._tJMPMi7mdxyYb5300ir{display:flex;flex-direction:column;align-items:center}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CAEA,sBACE,SAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,MAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CACA,cAAA,CAEA,4CACE,cAAA,CAGF,2CACE,eAAA,CACA,SAAA,CAEA,iEACE,cAAA,CACA,0BAAA,CACA,cAAA,CAKN,sBACE,YAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA","sourcesContent":[".container {\n  padding: 0 44px;\n  overflow-y: auto;\n  overflow-x: hidden;\n\n  &--typeCollapse {\n    padding: 0;\n  }\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  flex: 1;\n  width: 100%;\n  max-width: 760px;\n  margin: 0 auto;\n  padding: 0 16px;\n\n  .container--typeCollapse & {\n    max-width: 100%;\n  }\n\n  &.isEmptyContent {\n    max-width: 492px;\n    padding: 0;\n\n    .container--typeCollapse & {\n      padding: 0 16px;\n      justify-content: flex-start;\n      max-width: 100%;\n    }\n  }\n}\n\n.dropZoneContainer {\n  padding: 10px;\n}\n\n.dropZoneInfo {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "IPC0xtVq858eYyRGYhUY",
	"container--typeCollapse": "o2HNNFobWBFXuA_omFZS",
	"content": "luJ5VLLQSrnjVFJfj389",
	"isEmptyContent": "A36xH_WoHNgOaYJltmrj",
	"dropZoneContainer": "bdpDb1R0Z87FB1IZjIMw",
	"dropZoneInfo": "_tJMPMi7mdxyYb5300ir"
};
export default ___CSS_LOADER_EXPORT___;
