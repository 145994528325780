import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { PageLayoutStore } from 'shared/layout'
import { Campaign, type ICreateCampaignStep, type IFullResponseCampaign } from 'entities/Campaign'
import { CampaignRoutes } from 'pages/campaigns/types'

export class CampaignDetailStore {
  campaign
  constructor(response: IFullResponseCampaign, public pageLayoutStore: PageLayoutStore) {
    const onSuccessDelete = () => {
      uiStore.changeRoute({
        path: `${CampaignRoutes.root}/${CampaignRoutes.all}`,
      })
    }

    this.campaign = new Campaign(response, onSuccessDelete)
    makeAutoObservable(this)
  }

  activeStep: ICreateCampaignStep | null = null
  setActiveStep = (activeStep: typeof this.activeStep) => {
    this.activeStep = activeStep
  }
}
