import { nanoid } from 'nanoid'
import { ActionItem, toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import { Campaign, CampaignApi } from 'entities/Campaign'

export const deleteCampaign = (campaign: Campaign, onSuccess: () => void): ActionItem => {
  const onDelete = () => {
    const id = nanoid()
    modalStore.addModal({
      id,
      type: ModalTypeList.ALERT,
      title: 'Delete campaign?',
      desc: 'This action cannot be undone',
      primaryAction: {
        text: 'Delete',
        onAction: async () => {
          try {
            await CampaignApi.delete(campaign.id)
            toastStore.add({
              type: 'success',
              title: 'Campaign deleted',
            })
            modalStore.closeModal(id)
            onSuccess && onSuccess()
          } catch (e) {
            logger.error(e)
          }
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(id)
        },
      },
    })
  }
  return {
    icon: 'delete',
    onAction: onDelete,
    text: 'Delete',
  }
}
