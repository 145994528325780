import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import type { IKeyword } from 'entities/Keywords'
import { KeywordsApi } from 'entities/Keywords/api/keywords'
import { DetailsKeywordModal } from 'pages/keywords/pages/all/ui/KeywordsModals'

export class KeywordActionsStore {
  constructor() {
    makeAutoObservable(this)
  }

  updateStatus = async (keyword: IKeyword) => {
    try {
      const { data } = await KeywordsApi.toggleKeywordStatus(keyword.id)
      keyword.syncOrigin(data)

      if (!data.is_active)
        toastStore.add({
          type: 'info',
          title: 'Keyword disabled',
        })

      return data
    } catch (error) {
      logger.error(error)
      return Promise.reject()
    }
  }

  onDetails = (keyword: IKeyword) => {
    modalStore.addModal({
      id: nanoid(),
      width: 540,
      position: 'right',
      type: ModalTypeList.DEFAULT,
      ModalContentProps: { keyword },
      ModalContent: DetailsKeywordModal,
      title: 'Details',
      pureContent: true,
      fullHeight: true,
    })
  }

  onDelete = (keyword: IKeyword, onSuccess?: () => void) => {
    const id = nanoid()
    modalStore.addModal({
      id: id,
      type: ModalTypeList.ALERT,
      title: 'Delete keyword?',
      desc: 'This action cannot be undone',
      primaryAction: {
        text: 'Delete',
        onAction: async () => {
          try {
            await KeywordsApi.deleteKeyword(keyword.id)
            toastStore.add({
              type: 'success',
              title: 'Keyword deleted',
            })
            onSuccess && onSuccess()
            modalStore.closeModal(id)
          } catch (error) {
            logger.error(error)
          }
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(id)
        },
      },
    })
  }
}
