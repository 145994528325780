import { makeAutoObservable, runInAction } from 'mobx'
import { groupBy } from 'lodash'
import type { IDropdownItem } from 'shared/ui'
import {
  apiOptionsToDropdownItems,
  apiOptionToDropdownItem,
  logger,
  numberFormat,
  SingletonRequest,
} from 'shared/lib'
import { ComplianceApi } from 'entities/Compliance/api/compliance'
import { TenDlcApi } from 'entities/TenDlc'
import {
  ComplianceServicesList,
  OrganizationBusinessProfile,
  OrganizationShakenStir,
  OrganizationTollFree,
  OrganizationVoiceIntegrity,
  Summary,
} from 'entities/Compliance/model'
import type {
  IOptInMethodNames,
  IOptInMethodTypes,
  IResponseOrganizationShakenStir,
  IResponseTenDlcVoiceIntegrity,
} from 'entities/Compliance'
import { CnamStore } from 'entities/Compliance/store/cnamStore'
import { numbersStore } from 'entities/Phone'
import { Phone } from 'entities/Phone/model/Phone'

const countryMap: { [key: string]: IDropdownItem } = {
  US: {
    id: 'US',
    label: 'United States',
  },
  CA: {
    id: 'CA',
    label: 'Canada',
  },
}

const twoFaVerificationStatusMap: { [key: string]: IDropdownItem } = {
  pending: {
    id: 'pending',
    label: 'Pending 2FA Email to be Sent',
  },
  action_required: {
    id: 'action_required',
    label: 'Action Required: Check Email',
  },
  approved: {
    id: 'approved',
    label: 'Approved',
  },
}

class ComplianceStore {
  private _tenDLCUseCases: IDropdownItem[] = []
  private _tollFreeUseCases: IDropdownItem[] = []
  private _optInTenDLCTypes: IDropdownItem[] = []
  private _optInMethodNames: IOptInMethodNames[] = []

  private _businessProfileStatesGroup: { [key: string]: Array<IDropdownItem> } = {}
  private _complianceCountries: IDropdownItem[] = Object.values(countryMap)
  private _businessProfileBusinessTypes: IDropdownItem[] = []
  private _businessProfileJobPositions: IDropdownItem[] = []
  private _businessProfileIndustries: IDropdownItem[] = []
  private _businessProfileRegionsOfOperation: IDropdownItem[] = []
  private _businessProfileBusinessRegistrationTypes: IDropdownItem[] = []
  private _businessProfileStockExchanges: IDropdownItem[] = []

  private _providers: IDropdownItem[] = []

  private _tollFreeMessageVolume: IDropdownItem[] = []
  private _optInTollFreeTypes: IDropdownItem[] = []
  private _businessInformationBrandTwoFaVerificationStatus: IDropdownItem[] = Object.values(
    twoFaVerificationStatusMap
  )

  private _summary: Summary | null = null
  private _organizationBusinessProfile: OrganizationBusinessProfile | null = null
  private _complianceServicesList: ComplianceServicesList | null = null
  private _organizationTollFree: OrganizationTollFree | null = null
  private _organizationVoiceIntegrity: OrganizationVoiceIntegrity | null = null
  private _organizationShakenStir: OrganizationShakenStir | null = null
  private _cnamStore = new CnamStore()
  private _unverifiedTollFreeNumbers: Map<number, Phone> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  get cnamStore() {
    return this._cnamStore
  }

  get unverifiedTollFreeNumbers() {
    return Array.from(this._unverifiedTollFreeNumbers.values())
  }

  get organizationShakenStir() {
    return this._organizationShakenStir
  }

  get organizationVoiceIntegrity() {
    return this._organizationVoiceIntegrity
  }

  get optInTollFreeTypes() {
    return this._optInTollFreeTypes
  }

  get tollFreeUseCases() {
    return this._tollFreeUseCases
  }

  get tollFreeMessageVolume() {
    return this._tollFreeMessageVolume
  }

  get tenDLCUseCases() {
    return this._tenDLCUseCases
  }

  get optInTenDLCTypes() {
    return this._optInTenDLCTypes
  }

  get optInMethodNames() {
    return this._optInMethodNames
  }

  get businessProfileRegionsOfOperation() {
    return this._businessProfileRegionsOfOperation
  }

  get businessProfileBusinessTypes() {
    return this._businessProfileBusinessTypes
  }

  get businessProfileJobPositions() {
    return this._businessProfileJobPositions
  }

  get businessProfileStockExchanges() {
    return this._businessProfileStockExchanges
  }

  get businessProfileBusinessRegistrationTypes() {
    return this._businessProfileBusinessRegistrationTypes
  }

  get complianceCountries() {
    return this._complianceCountries
  }

  get businessInformationBrandTwoFaVerificationStatus() {
    return this._businessInformationBrandTwoFaVerificationStatus
  }

  get businessProfileIndustries() {
    return this._businessProfileIndustries
  }

  get summary() {
    return this._summary
  }

  get complianceServicesList() {
    return this._complianceServicesList
  }

  get organizationBusinessProfile() {
    return this._organizationBusinessProfile
  }

  get organizationTollFree() {
    return this._organizationTollFree
  }

  get isQuestionnaire() {
    return Boolean(this._summary?.organization_id)
  }

  loadTenDLCUseCases = async () => {
    if (this._tenDLCUseCases.length) {
      return
    }
    try {
      const response = await TenDlcApi.getTenDlcUseCases()
      runInAction(() => {
        this._tenDLCUseCases = apiOptionsToDropdownItems(
          response.data.map((item) => ({ ...item, id: item.value }))
        )
      })
    } catch (e) {
      logger.error(e)
    }
  }
  loadOptInTenDLCTypes = async () => {
    if (this._optInTenDLCTypes.length) {
      return
    }
    try {
      const response = await ComplianceApi.getOptInMethodNames()

      runInAction(() => {
        this._optInMethodNames = response.data
        this._optInTenDLCTypes = response.data.map((item) => ({
          id: item.value,
          label: item.title,
        }))
      })
    } catch (e) {
      logger.error(e)
    }
  }

  loadBusinessProfileStates = async () => {
    if (Object.values(this._businessProfileStatesGroup).length) {
      return
    }
    try {
      const response = await ComplianceApi.getBpStates()
      const group = groupBy(response.data, 'country_id')
      runInAction(() => {
        this._businessProfileStatesGroup = Object.fromEntries(
          Object.entries(group).map(([key, value]) => [
            key,
            value.map(apiOptionToDropdownItem) as IDropdownItem[],
          ])
        )
      })
    } catch (e) {
      logger.error(e)
    }
  }

  loadBusinessProfileBusinessTypes = async () => {
    if (this._businessProfileBusinessTypes.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileBusinessTypes()
      runInAction(() => {
        this._businessProfileBusinessTypes = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      logger.error(e)
    }
  }

  loadBusinessProfileJobPositions = async () => {
    if (this._businessProfileJobPositions.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileJobPositions()
      runInAction(() => {
        this._businessProfileJobPositions = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      logger.error(e)
    }
  }

  loadBusinessProfileIndustries = async () => {
    if (this._businessProfileIndustries.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileIndustries()
      runInAction(() => {
        this._businessProfileIndustries = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      logger.error(e)
    }
  }

  loadBusinessProfileRegionsOfOperation = async () => {
    if (this._businessProfileRegionsOfOperation.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileRegionsOfOperation()
      runInAction(() => {
        this._businessProfileRegionsOfOperation = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      logger.error(e)
    }
  }

  loadBusinessProfileBusinessRegistrationTypes = async () => {
    if (this._businessProfileBusinessRegistrationTypes.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileBusinessRegistrationTypes()
      runInAction(() => {
        this._businessProfileBusinessRegistrationTypes = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      logger.error(e)
    }
  }
  loadGetBusinessProfileStockExchanges = async () => {
    if (this._businessProfileStockExchanges.length) {
      return
    }
    try {
      const response = await ComplianceApi.getBusinessProfileStockExchanges()
      runInAction(() => {
        this._businessProfileStockExchanges = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      logger.error(e)
    }
  }

  loadProviders = async () => {
    if (this._providers.length) {
      return
    }
    try {
      const response = await ComplianceApi.getProviders()
      runInAction(() => {
        this._providers = response.data.map((item) => ({
          id: item.value,
          label: item.label === 'Tcr' ? 'TCR' : item.label,
        }))
      })
    } catch (e) {
      logger.error(e)
    }
  }

  getCountryLabel = (id: string) => {
    if (id === 'usa') {
      return countryMap['US'].label
    }
    return countryMap[id]?.label || id
  }

  getStates = (countryId: string | null): IDropdownItem[] => {
    return this._businessProfileStatesGroup[countryId || 'US'] || []
  }

  getOptInMethodName = (value: IOptInMethodTypes) => {
    return this.optInMethodNames.find((item) => item.value === value)?.title || ''
  }

  loadTollFreeFormFields = async () => {
    if (this._tollFreeMessageVolume.length || this._tollFreeUseCases.length) {
      return
    }
    try {
      const response = await ComplianceApi.getOrganizationTollFreeFormFields()
      runInAction(() => {
        this._tollFreeMessageVolume = response.data?.monthly_values.map((item) => ({
          id: item,
          label: numberFormat({ value: item }),
        }))
        this._tollFreeUseCases = apiOptionsToDropdownItems(response.data?.use_cases)
      })
    } catch (e) {
      logger.error(e)
    }
  }
  loadOptInTollFreeTypes = async () => {
    if (this._optInTollFreeTypes.length) {
      return
    }
    try {
      const response = await ComplianceApi.getOptInTypes({ filter: 'toll-free' })
      runInAction(() => {
        this._optInTollFreeTypes = apiOptionsToDropdownItems(response.data)
      })
    } catch (e) {
      logger.error(e)
    }
  }

  // Summary
  private _fetchSummary = async () => {
    try {
      const { data } = await ComplianceApi.getComplianceSummary()

      const summary = new Summary(data)

      this.setSummary(summary)
    } catch (e) {
      logger.error(e)
    }
  }

  fetchSummary = new SingletonRequest(this._fetchSummary, { cache: true }).request

  setSummary = (data: Summary) => {
    this._summary = data
  }

  // OrganizationBusinessProfile
  private _fetchOrganizationBusinessProfile = async () => {
    try {
      const { data } = await ComplianceApi.getBusinessProfileData()

      const organizationBusinessProfile = new OrganizationBusinessProfile(data)

      this.setOrganizationBusinessProfile(organizationBusinessProfile)
    } catch (e) {
      logger.error(e)
    }
  }

  fetchOrganizationBusinessProfile = new SingletonRequest(this._fetchOrganizationBusinessProfile, {
    cache: true,
  }).request

  setOrganizationBusinessProfile = (data: OrganizationBusinessProfile) => {
    this._organizationBusinessProfile = data

    return this._organizationBusinessProfile
  }

  // ComplianceServicesList (10DLC)
  private _fetchComplianceServicesList = async () => {
    try {
      const { data } = await ComplianceApi.getComplianceServicesList()

      const complianceServicesList = new ComplianceServicesList(data)

      return this.setComplianceServicesList(complianceServicesList)
    } catch (e) {
      logger.error(e)
    }
  }

  fetchComplianceServicesList = new SingletonRequest(this._fetchComplianceServicesList).request

  setComplianceServicesList = (data: ComplianceServicesList) => {
    this._complianceServicesList = data

    return this._complianceServicesList
  }

  // OrganizationTollFree
  private _fetchOrganizationTollFree = async () => {
    try {
      const { data } = await ComplianceApi.getOrganizationTollFree()

      const organizationTollFree = new OrganizationTollFree(data)

      return this.setOrganizationTollFree(organizationTollFree)
    } catch (e) {
      logger.error(e)
    }
  }

  fetchOrganizationTollFree = new SingletonRequest(this._fetchOrganizationTollFree, { cache: true })
    .request

  setOrganizationTollFree = (data: OrganizationTollFree) => {
    this._organizationTollFree = data

    return this._organizationTollFree
  }

  // Calling VoiceIntegrity
  private _fetchOrganizationVoiceIntegrity = async () => {
    try {
      const { data } = await ComplianceApi.getVoiceIntegrity()

      this.setOrganizationVoiceIntegrity(data)
    } catch (e) {
      logger.error(e)
    }
  }

  fetchOrganizationVoiceIntegrity = new SingletonRequest(this._fetchOrganizationVoiceIntegrity)
    .request

  setOrganizationVoiceIntegrity = (data: IResponseTenDlcVoiceIntegrity) => {
    this._organizationVoiceIntegrity = new OrganizationVoiceIntegrity({
      ...this._organizationVoiceIntegrity?.origin,
      ...data,
    })

    return this._organizationVoiceIntegrity
  }

  // Calling Shaken Stir
  private _fetchOrganizationShakenStir = async () => {
    try {
      const { data } = await ComplianceApi.getOrganizationShakenStir()

      this.setOrganizationShakenStir(data)
    } catch (e) {
      logger.error(e)
    }
  }

  fetchOrganizationShakenStir = new SingletonRequest(this._fetchOrganizationShakenStir).request

  setOrganizationShakenStir = (data: IResponseOrganizationShakenStir) => {
    this._organizationShakenStir = new OrganizationShakenStir(data)

    return this._organizationShakenStir
  }

  getUnverifiedTollFreeNumbers = () =>
    [...numbersStore.userNumbers, ...numbersStore.unassignedNumbersUser].forEach((item) => {
      if (item.isTollFree && item.isStatusUnverified) {
        this._unverifiedTollFreeNumbers.set(item.id, item)
      }
    })
}

export const complianceStore = new ComplianceStore()
