import type { IToastShow } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { PowerDialerStatus } from 'entities/PowerDialer/api/types'
import { PowerDialerPath } from 'entities/ExportRouters'

interface PowerDialerToastConfig {
  isStartedFromScratch?: boolean
  isFinishedByFlow?: boolean
}

export const getToastConfigByPowerDialerStatus = (
  status: PowerDialerStatus,
  { isStartedFromScratch = false, isFinishedByFlow = false }: PowerDialerToastConfig = {}
): IToastShow => {
  const action = {
    onAction: () =>
      uiStore.changeRouteViaVue({
        path: `/${PowerDialerPath.root}`,
      }),
    text: 'Power Dialer campaigns',
  }

  switch (status) {
    case PowerDialerStatus.Draft:
      return {
        type: 'info',
        title: 'Power Dialer campaign saved',
        desc: 'You can start dialing anytime from the Power Dialer campaigns page',
        action,
      }
    case PowerDialerStatus.InProgress:
      return {
        type: 'info',
        title: isStartedFromScratch
          ? 'Power Dialer campaign started'
          : 'Power Dialer campaign resumed',
      }
    case PowerDialerStatus.Paused:
      return {
        type: 'info',
        title: 'Power Dialer campaign paused',
        desc: 'You can resume anytime from the Power Dialer campaigns page',
        action,
      }
    case PowerDialerStatus.Finished:
      return {
        type: 'info',
        title: isFinishedByFlow
          ? 'Power Dialer campaign finished'
          : 'Power Dialer campaign stopped',
      }
  }
}
