import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { FC, useMemo, useState, useEffect } from 'react'
import {
  makeTextFieldAutoFocus,
  makeTextFieldClear,
  makeTextFieldElement,
  TextField,
} from 'shared/ui/TextField'
import {
  Button,
  IconButton,
  type MoreDropdownProps,
  SpinnerLoader,
  toastStore,
  Typography,
} from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { logger, writeTextToClipboard } from 'shared/lib'
import { IIntegrationMeeting, Integration } from 'entities/Integrations'
import { type MeetingsProps, MeetingsStore } from 'widgets/Meetings/store/meetingsStore'
import { DropdownIntegrationActionItem } from 'widgets/DropdownIntegrationActionItem'
import styles from './styles.module.scss'

export const MeetingsContent: FC<MoreDropdownProps & MeetingsProps> = observer((props) => {
  const { contact, integration, onCloseMenu, onClose, onAddMeetingLink, onNecessaryReconnect } =
    props

  const [meetingsStore] = useState(() => new MeetingsStore({ contact }))

  if (!integration) return null

  const {
    loading,
    search,
    selectedMeeting,
    filteredMeetings,
    selectedIntegration,
    onSelectMeeting,
    onSelectedIntegration,
    setSearch,
    onBack,
    isShowBack,
    isNecessaryReconnect,
  } = meetingsStore

  useEffect(() => {
    onNecessaryReconnect?.(isNecessaryReconnect)
  }, [isNecessaryReconnect])

  const handleSelectedIntegration = (integration: Integration) => {
    if (integration.connected && !isNecessaryReconnect) {
      onSelectedIntegration(integration)
    } else {
      onCloseMenu?.()
      onClose?.()
      uiStore.changeRoute({
        path: '/settings/integrations',
      })
    }
  }

  const handleSelectedMeeting = (meeting: IIntegrationMeeting) => {
    onCloseMenu?.()
    onSelectMeeting(meeting)
    onAddMeetingLink?.(meeting.link)
  }

  const onCopy = async (link: string) => {
    try {
      await writeTextToClipboard(link)
      toastStore.add({
        title: 'Copied to clipboard',
        type: 'info',
      })
    } catch (e) {
      logger.error(e)
    }
  }

  const content = useMemo(() => {
    if (loading) {
      return (
        <div className={classNames(styles.loading)}>
          <SpinnerLoader size={16} />
        </div>
      )
    }
    if (!isNecessaryReconnect && (selectedIntegration || search)) {
      return (
        <div className={styles.list}>
          {!filteredMeetings?.length && (
            <div className={styles.wrapEmpty}>
              <Typography
                variant={'body-sm-regular'}
                color={'var(--content-primary-tertiary)'}
                ariaLabel={'No results found'}
              >
                No results found
              </Typography>
            </div>
          )}
          {filteredMeetings?.map((meeting) => (
            <Button
              key={meeting.name}
              className={styles.meetingButton}
              onClick={() => handleSelectedMeeting(meeting)}
              typeBtn={'menuItem'}
              aria-label={meeting.name}
              titleContent={
                <div className={styles.meetingTitle}>
                  <Typography
                    variant={'body-md-regular'}
                    ellipsis
                    tag={'div'}
                    className={styles.leftContent}
                    ariaLabel={'label'}
                    tooltipProps={{
                      label: meeting.name,
                      placement: 'top',
                    }}
                  >
                    {meeting.name}
                  </Typography>
                  <div className={styles.rightContent}>
                    <IconButton
                      key='copy'
                      onClick={(e) => {
                        e.stopPropagation()
                        onCopy(meeting.link)
                      }}
                      icon={'copy'}
                      variant={'icon'}
                      color={'secondary'}
                      size={'small'}
                      tooltipProps={{
                        label: 'Copy link',
                        placement: 'top',
                      }}
                      ariaLabel='MeetingsActionTooltip_copy_link'
                    />
                  </div>
                </div>
              }
            />
          ))}
        </div>
      )
    }

    return (
      <DropdownIntegrationActionItem
        integration={integration}
        ariaLabel={'Meetings'}
        customLabel={'meetings'}
        isDefaultIntegrationBtn={false}
        isNecessaryReconnect={isNecessaryReconnect}
        onClickIntegrationBtn={(integration) => {
          handleSelectedIntegration(integration)
        }}
      />
    )
  }, [
    loading,
    integration,
    selectedMeeting,
    selectedIntegration,
    filteredMeetings,
    search,
    isNecessaryReconnect,
  ])

  return (
    <div
      className={classNames(styles.wrap, 'top-dropdown-column-reverse-content', {
        [styles.reconnect]: !integration.isConnected || isNecessaryReconnect,
      })}
    >
      {!isNecessaryReconnect && selectedIntegration && (
        <>
          <div className={styles.header}>
            <TextField
              className={styles.textInput}
              size='small'
              variant='integrated'
              ariaLabel='MeetingsSearch'
              InputProps={{
                placeholder: 'Search',
              }}
              value={search}
              onChange={setSearch}
              mainActions={[makeTextFieldAutoFocus({ withFocus: !loading })]}
              leftActions={[
                makeTextFieldElement({
                  element: isShowBack ? (
                    <IconButton
                      tag='div'
                      icon={'chevronLeft'}
                      size={'small'}
                      variant={'icon'}
                      color={'secondary'}
                      fontSize={15}
                      onClick={onBack}
                      ariaLabel='MeetingsContent_back'
                    />
                  ) : undefined,
                }),
              ]}
              rightActions={[makeTextFieldClear()]}
            />
          </div>
          <div className={styles.divider} />
        </>
      )}
      <div className={styles.content}>{content}</div>
    </div>
  )
})
