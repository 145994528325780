import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import { CallingStepsStore } from 'pages/settings/pages/compliance/pages/calling/store/callingStepsStore'

const CallingStepsContext = createContext<CallingStepsStore | null>(null)

export const useCallingStepsContext = () => {
  const context = useContext(CallingStepsContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with CallingStepsProvider')

  return context
}

export const CallingStepsProvider = ({
  complianceLayerStore,
  children,
}: {
  complianceLayerStore: ComplianceLayerStore
  children: ReactNode
}) => {
  const store = useMemo(() => new CallingStepsStore(complianceLayerStore), [])

  useEffect(() => store.dispose, [])

  return <CallingStepsContext.Provider value={store}>{children}</CallingStepsContext.Provider>
}
