import { observer } from 'mobx-react-lite'
import { useEffect, FC } from 'react'
import { Button, Dropdown } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { SearchElement, FilterEditorAdd } from 'widgets/FilterEditor'
import { FilterEditorProvider, useFilterEditorContext } from 'widgets/FilterEditor/context'
import { type ContactsFilterStore } from 'pages/contacts/ui/ContactsFilters'
import { SegmentModalStore } from 'pages/contacts/ui/ContactsMenu/ui/SegmentModal'
import { FiltersContent } from './FiltersContent'
import styles from './styles.module.scss'

type IContactsFiltersBodyProps = {
  segmentModalStore: SegmentModalStore
  contactsFilterStore: ContactsFilterStore
}

const ContactsFiltersBody: FC<IContactsFiltersBodyProps> = observer(
  ({ segmentModalStore, contactsFilterStore }) => {
    const { opened, onOpen, clearSegment, initFilter } = contactsFilterStore
    const filterEditorStore = useFilterEditorContext()
    const hasFilters = Boolean(filterEditorStore.groups.count)

    useEffect(() => {
      initFilter()
    }, [])

    const handleClearSearch = () => {
      clearSegment()
      contactsFilterStore.changeSearchAndResetPaginationPage('')
    }

    if (filterEditorStore.loading) return null

    return (
      <div className={styles.searchWrapper}>
        <div className={styles.searchInputWrap}>
          {hasFilters ? (
            <Dropdown
              ariaLabel={'ContactsFilters'}
              show={opened}
              width={contactsFilterStore.tableWidth || undefined}
              overlayModalsIds={[segmentModalStore.idModal]}
              triggerComponent={(open) => (
                <SearchElement active={open} count={filterEditorStore.groups.count} />
              )}
              placement={'bottom-start'}
              onClose={() => onOpen(false)}
              onToggle={onOpen}
              customComponent={(onCloseMenu, dropdownId) => {
                return (
                  <FiltersContent
                    contactsFilterStore={contactsFilterStore}
                    filterEditorStore={filterEditorStore}
                    parentsDropdownId={[dropdownId]}
                    onCloseMenu={onCloseMenu}
                    segmentModalStore={segmentModalStore}
                    onClearFilters={handleClearSearch}
                  />
                )
              }}
            />
          ) : (
            <>
              <FilterEditorAdd
                store={filterEditorStore}
                triggerComponent={(open) => (
                  <SearchElement active={open} count={filterEditorStore.groups.count} />
                )}
                onChange={() => {
                  onOpen(true)
                  contactsFilterStore.setNewSegment()
                }}
              />
            </>
          )}

          <TextField
            ariaLabel='Contacts_SearchField'
            className={styles.textField}
            variant='integrated'
            InputProps={{
              placeholder: 'Search contacts...',
            }}
            onChange={contactsFilterStore.changeSearchAndResetPaginationPage}
            value={contactsFilterStore.search}
            mainActions={[makeTextFieldAutoFocus({ withFocus: !contactsFilterStore.search })]}
          />
        </div>

        {(hasFilters || !!contactsFilterStore.search) && (
          <Button
            contained='secondary'
            tooltipProps={{
              label: 'Clear all',
              disableInteractive: true,
            }}
            typeBtn='text'
            text='Clear all'
            onClick={handleClearSearch}
          />
        )}
      </div>
    )
  }
)

type IContactsFiltersProps = {
  segmentModalStore: SegmentModalStore
  contactsFilterStore: ContactsFilterStore
}

export const ContactsFilters: FC<IContactsFiltersProps> = observer(
  ({ segmentModalStore, contactsFilterStore }) => (
    <FilterEditorProvider store={contactsFilterStore.filterEditorStore}>
      <ContactsFiltersBody
        segmentModalStore={segmentModalStore}
        contactsFilterStore={contactsFilterStore}
      />
    </FilterEditorProvider>
  )
)
