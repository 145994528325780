import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { Button } from 'shared/ui'
import { makeTextFieldAutoFocus, makeTextFieldHighlightLimit, TextField } from 'shared/ui/TextField'
import { PresetErrorContainer } from 'shared/Preset'
import { Warning } from 'shared/ui/Warning'
import type { IQuickLinkPayload } from 'entities/QuickLink'
import { MessageField } from 'widgets/MessageField'
import { QuickLinkUrlStore } from 'pages/settings/pages/quickLinks/store/QuickLinkUrlStore'

import styles from './styles.module.scss'

export interface IQuickLinkModalProps {
  completeText: string
  payload: IQuickLinkPayload
  onComplete: () => Promise<void>
  onClose: () => void
}

const LABEL_LIMIT = 35

export const QuickLinkModal = observer(
  ({ completeText, payload, onComplete, onClose }: IQuickLinkModalProps) => {
    const [isBusy, setBusy] = useState(false)
    const [labelLimitReached, setLabelLimitReached] = useState(false)
    const urlStore = useMemo(() => new QuickLinkUrlStore(payload), [payload])

    const handleComplete = () => {
      setBusy(true)

      onComplete().then(() => {
        setBusy(false)
      })
    }

    useEffect(() => {
      urlStore.sync()

      return () => urlStore.dispose()
    }, [payload])

    return (
      <div className={styles.content}>
        <div className={styles.contentRows}>
          <div className={styles.contentRow}>
            <span className={styles.contentRowTitle}>Label</span>
            <TextField
              size='medium'
              variant='integrated'
              InputProps={{ placeholder: 'Enter label' }}
              value={payload.label}
              onChange={(value) => payload.setLabel(value)}
              mainActions={[
                makeTextFieldAutoFocus({ withFocus: true }),
                makeTextFieldHighlightLimit({ limit: LABEL_LIMIT, onChange: setLabelLimitReached }),
              ]}
              className={styles.labelField}
            />
            <PresetErrorContainer>
              {labelLimitReached && <Warning text={`${LABEL_LIMIT}-character limit reached`} />}
            </PresetErrorContainer>
          </div>
          <div className={styles.contentRow}>
            <span className={styles.contentRowTitle}>URL</span>
            <MessageField messageFieldStore={urlStore.urlField} />
          </div>
        </div>
        <footer className={styles.contentFooter}>
          <Button text='Cancel' size='medium' contained='secondary' onClick={onClose} />
          <Button
            text={completeText}
            loading={isBusy}
            onClick={handleComplete}
            disabled={payload.isEmpty}
          />
        </footer>
      </div>
    )
  }
)
