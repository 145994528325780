import { observer } from 'mobx-react-lite'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { type CampaignSelectStore } from 'entities/Campaign/ui'

type ICampaignSelectProps = {
  store: CampaignSelectStore
}

export const CampaignSelect = observer(({ store }: ICampaignSelectProps) => {
  const {
    selectedItem,
    dropDownItems,
    setDropdownItem,
    onChangeOpen,
    onLoadMore,
    loading,
    loadingMore,
    search,
    setSearch,
  } = store
  return (
    <SelectField
      selectProps={{
        variant: 'stroke',
        textFieldPlaceholderProps: {
          size: 'medium',
        },
      }}
      labelProps={{
        label: {
          text: 'Campaign',
        },
      }}
      item={selectedItem}
      items={dropDownItems}
      search={search}
      loading={loading}
      onSearch={setSearch}
      onLoadMore={onLoadMore}
      setValue={setDropdownItem}
      dropdownProps={{
        onChangeOpen,
        ariaLabel: 'Campaign',
        propsDropdownList: {
          loadingMore,
        },
      }}
    />
  )
})
