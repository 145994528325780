import { makeAutoObservable, reaction, IReactionDisposer, runInAction } from 'mobx'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import { logger, numberFormat } from 'shared/lib'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { IRadioBoxProps } from 'shared/ui/Radio'
import { organizationStore } from 'entities/Organization'
import { ContactsApi } from 'entities/Contacts'
import {
  broadcastBadNumberOptions,
  BroadcastBadNumbersModalActions,
  BroadcastBadNumbersModalContent,
  IBroadcastBadNumberOptionTypes,
} from 'widgets/BroadcastView/ui/BroadcastBadNumbers'

export class BroadcastBadNumberStore {
  private _modalId = 'badNumberModal'
  private _checked = false
  private _disposeAllTotal: IReactionDisposer | null = null
  private _disposeOneTotal: IReactionDisposer | null = null
  private _total = 0
  private _allTotal = 0
  private _loading = false
  private _optionsMap: Map<
    IBroadcastBadNumberOptionTypes,
    IRadioBoxProps<IBroadcastBadNumberOptionTypes>
  > = new Map()
  private _activeOption: IBroadcastBadNumberOptionTypes | null = null

  constructor() {
    makeAutoObservable(this)

    this.reactionAllTotal()
    this.reactionOneTotal()
    this.initOptions()
    this.setChecked(organizationStore.isPhoneCheckerEnabled)
  }

  initAllTotal = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data } = await ContactsApi.getContacts({
        opt_out: true,
      })

      this.setAllTotal(data.total)
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  initOptions = () => {
    broadcastBadNumberOptions.forEach((item) => {
      this._optionsMap.set(item.value, item)
    })
  }

  resetReactions = () => {
    this._disposeAllTotal?.()
    this._disposeOneTotal?.()
  }

  reset = () => {
    this.resetReactions()
  }

  reactionAllTotal = () => {
    this._disposeAllTotal?.()
    this._disposeAllTotal = reaction(
      () => this._allTotal,
      (value) => {
        const item = this._optionsMap.get('all')

        if (item) {
          item.text = `Validate all contacts (${numberFormat({ value: value })})`
          this._optionsMap.set(item.value, item)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionOneTotal = () => {
    this._disposeOneTotal?.()
    this._disposeOneTotal = reaction(
      () => this._total,
      (value) => {
        const item = this._optionsMap.get('one')

        if (item) {
          item.text = `Validate broadcast contacts (${numberFormat({ value: value })})`
          this._optionsMap.set(item.value, item)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  setChecked = (value: boolean, type?: 'manual' | 'auto') => {
    this._checked = value

    if (value && type === 'manual') {
      this.openModal()
    }
  }

  setTotal = (value: number) => {
    this._total = value
  }

  setAllTotal = (value: number) => {
    this._allTotal = value
  }

  setActiveOption = (value: IBroadcastBadNumberOptionTypes) => {
    this._activeOption = value
  }

  handlePhoneCheckerToggle = async (status: boolean) => {
    try {
      runInAction(() => {
        this._loading = true
      })

      await organizationStore.updatePhoneCheckerEnable(status)

      toastStore.add({
        type: 'success',
        title: 'PhoneCheckr is active',
      })

      modalStore.closeModal(this._modalId)
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  openModal = () => {
    if (!this.isAvailablePhoneCheckerModal) return

    this.initAllTotal()

    try {
      modalStore.addModal({
        id: this._modalId,
        type: ModalTypeList.DEFAULT,
        title: 'Validate numbers',
        ModalContent: BroadcastBadNumbersModalContent,
        ModalActions: BroadcastBadNumbersModalActions,
        width: 540,
        paddingContent: '8px 24px 13px 24px',
        showCloseButton: false,
        ModalContentProps: {
          store: this,
        },
      })
    } catch (e) {
      logger.error(e)
    }
  }

  onClose = () => {
    this.setChecked(!this._checked)
    this._activeOption = null
    modalStore.removeModal(this._modalId)
  }

  onEnable = () => {
    this.handlePhoneCheckerToggle(this.activeOption === 'all')
  }

  get isAvailablePhoneCheckerModal() {
    return !organizationStore.isPhoneCheckerEnabled
  }

  get checked() {
    return this._checked
  }

  get disabled() {
    return this._loading || !this._activeOption
  }

  get options() {
    return Array.from(this._optionsMap.values())
  }

  get activeOption() {
    return this._activeOption
  }
}
