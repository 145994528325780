import { makeAutoObservable } from 'mobx'
import type { Integration, ISalesForceSettingsDict } from 'entities/Integrations'
import { IntegrationSalesforceSettingsPropertyStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/store/IntegrationSalesforceSettingsPropertyStore'
import { IntegrationSalesforceSettingsTimelineStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/store/IntegrationSalesforceSettingsTimelineStore'
import { IntegrationSalesforceSettingsInstallationStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/store/IntegrationSalesforceSettingsInstallationStore'
import { IntegrationSalesforceSettingsSyncStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/store/IntegrationSalesforceSettingsSyncStore'
import { IntegrationCollapseSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCollapseSettingsStore'
import { IntegrationCustomSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCustomSettingsStore'
import { IntegrationSettingsUsersStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationSettingsUsersStore'
import { IntegrationWebhookStore } from 'pages/settings/pages/integrations/store/IntegrationWebhookStore'

export class IntegrationSalesforceSettingsStore {
  private _collapseStore
  private _webhookStore
  private _installationStore
  private _customSettingsStore
  private _timelineStore
  private _syncStore
  private _userStore
  private _propertyStore

  constructor(integration: Integration) {
    this._collapseStore = new IntegrationCollapseSettingsStore()
    this._webhookStore = new IntegrationWebhookStore()
    this._customSettingsStore = new IntegrationCustomSettingsStore<ISalesForceSettingsDict>(
      integration
    )
    this._userStore = new IntegrationSettingsUsersStore(integration)
    this._installationStore = new IntegrationSalesforceSettingsInstallationStore(integration)
    this._timelineStore = new IntegrationSalesforceSettingsTimelineStore(this._customSettingsStore)
    this._syncStore = new IntegrationSalesforceSettingsSyncStore(
      integration,
      this._customSettingsStore,
      this._installationStore
    )
    this._propertyStore = new IntegrationSalesforceSettingsPropertyStore(
      integration,
      this._customSettingsStore,
      this._installationStore
    )

    makeAutoObservable(this)
  }

  init = (integration: Integration) => {
    if (!integration.isConnected) {
      this._installationStore.init()
    } else {
      this._customSettingsStore.init()
      this._webhookStore.initIntegrationWebhookState(integration)
      this._installationStore.init()
      this._syncStore.init()
      this._timelineStore.init()
      this._userStore.init()
      this._propertyStore.init()
    }
  }

  get collapseStore() {
    return this._collapseStore
  }

  get installationStore() {
    return this._installationStore
  }

  get webhookStore() {
    return this._webhookStore
  }

  get syncStore() {
    return this._syncStore
  }

  get userStore() {
    return this._userStore
  }

  get timelineStore() {
    return this._timelineStore
  }

  get propertyStore() {
    return this._propertyStore
  }
}
