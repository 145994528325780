import React, { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { CollapseWithIcon } from 'shared/ui'
import { Integration } from 'entities/Integrations'
import { useIntegrationStoreMemo } from 'pages/settings/pages/integrations/pages/integrationSettings/hooks/useIntegrationStoreMemo'
import { IntegrationSalesforceSettingsProperty } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/ui/IntegrationSalesforceSettingsProperty/IntegrationSalesforceSettingsProperty'
import { IntegrationSettingsUsers } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSettingsUsers/IntegrationSettingsUsers'
import { IntegrationSettingsWebhook } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSettingsWebhook/IntegrationSettingsWebhook'
import { IntegrationSalesforceSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/store/IntegrationSalesforceSettingsStore'
import { IntegrationSalesforceSettingsSync } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/ui/IntegrationSalesforceSettingsSync/IntegrationSalesforceSettingsSync'
import { IntegrationSalesforceSettingsInstallation } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/ui/IntegrationSalesforceSettingsInstallation/IntegrationSalesforceSettingsInstallation'
import { IntegrationSalesforceSettingsTimeline } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/ui/IntegrationSalesforceSettingsTimeline/IntegrationSalesforceSettingsTimeline'
import styles from './styles.module.scss'

type IIntegrationHubspotSettingsProps = {
  integration: Integration
}

export const IntegrationSalesforceSettings: FC<IIntegrationHubspotSettingsProps> = observer(
  ({ integration }) => {
    const {
      collapseStore,
      installationStore,
      webhookStore,
      syncStore,
      timelineStore,
      userStore,
      propertyStore,
    } = useIntegrationStoreMemo(IntegrationSalesforceSettingsStore, integration)
    const isConnected = integration.isConnected

    useEffect(() => {
      if (isConnected) {
        collapseStore.onToggle(null)()
      }
      if (!isConnected && !collapseStore.opened('installation')) {
        collapseStore.onToggle('installation')()
      }
    }, [isConnected, collapseStore])

    return (
      <div>
        <CollapseWithIcon
          opened={collapseStore.opened('installation')}
          onClick={collapseStore.onToggle('installation')}
          icon={'settingsOutline'}
          title={'Installation'}
          label={`Send ${integration.name} contacts into your Salesmsg account`}
        >
          <IntegrationSalesforceSettingsInstallation store={installationStore} />
        </CollapseWithIcon>
        {isConnected && (
          <>
            <CollapseWithIcon
              opened={collapseStore.opened('webhook')}
              onClick={collapseStore.onToggle('webhook')}
              icon={'webhookOutline'}
              title={'Webhook'}
              label={`Send ${webhookStore.integrationName} contacts into your Salesmsg account`}
            >
              <IntegrationSettingsWebhook store={webhookStore} />
            </CollapseWithIcon>
            <CollapseWithIcon
              opened={collapseStore.opened('users')}
              onClick={collapseStore.onToggle('users')}
              icon={'userLine'}
              title={'Users'}
              subtitle={userStore.userInfo.subtitle}
              label={userStore.userInfo.label}
            >
              <IntegrationSettingsUsers store={userStore} />
            </CollapseWithIcon>
            <CollapseWithIcon
              opened={collapseStore.opened('sync')}
              onClick={collapseStore.onToggle('sync')}
              icon={'arrowHorizontalOutline'}
              title={'Contact syncing'}
              label={syncStore.getLabel}
            >
              <IntegrationSalesforceSettingsSync store={syncStore} />
            </CollapseWithIcon>
            <CollapseWithIcon
              opened={collapseStore.opened('property')}
              onClick={collapseStore.onToggle('property')}
              icon={'workflowOutline'}
              title={'Field mapping'}
              label={`${propertyStore.mappedLength} fields mapped`}
            >
              <IntegrationSalesforceSettingsProperty store={propertyStore} />
            </CollapseWithIcon>
            <CollapseWithIcon
              className={styles.withoutBorder}
              opened={collapseStore.opened('timeline')}
              onClick={collapseStore.onToggle('timeline')}
              icon={'listLayout'}
              title={'Timeline events'}
              label={'Track of every interaction directly in your Salesforce'}
            >
              <IntegrationSalesforceSettingsTimeline store={timelineStore} />
            </CollapseWithIcon>
          </>
        )}
      </div>
    )
  }
)
