// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UE3J6RGGCOmeqB8xdtAZ{margin-right:8px}", "",{"version":3,"sources":["webpack://./src/pages/contacts/ui/ContactsTable/ui/TableActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,gBAAA","sourcesContent":[".exportButton {\n    margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exportButton": "UE3J6RGGCOmeqB8xdtAZ"
};
export default ___CSS_LOADER_EXPORT___;
