import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { bannerStore, EnumAlertBannerVariant } from 'shared/ui'
import { storeCache } from 'shared/api/storeCache'
import { uiStore } from 'shared/store/uiStore'
import {
  type IParamsLogin,
  type IParamsRegister,
  type IParamsSendTwoFaCode,
  type IParamsVerifyTwoFaCode,
  type IResponseToken,
} from 'entities/Auth'
import { AuthRoutesEnum } from 'entities/Auth/routes/authRoutesEnum'
import { AuthApi } from 'entities/Auth/api'
import { getAssumeToken, removeTokenLocalStorage, setToken } from 'entities/Auth/lib/setToken'
import { websocket } from 'entities/WebSocket'
import { resetPosthog } from 'entities/Posthog'
import { sessionManager } from '../lib/sessionManager'
import { GoogleAuthStore } from './googleAuth'
import { IResponseGoogleLogin } from './types'

export class AuthStore {
  constructor() {
    makeAutoObservable(this)
    this.checkAssuming()
    this.reactionOnLoginFromAppLight()
  }

  error = ''
  loading = true
  isAssume = false
  private _isLoggedIn = false
  get isLoggedIn() {
    return this._isLoggedIn
  }

  socialLoginCallback = (payload: IResponseGoogleLogin) => {
    this.socialLogin(payload.token)
  }

  isNewSession = false
  googleAuthStore: GoogleAuthStore = new GoogleAuthStore(this.socialLoginCallback)

  external_source = ''
  closeAfterLogin = false

  private _disposeReactionOnLoginFromAppLight: IReactionDisposer | null = null

  reactionOnLoginFromAppLight = () => {
    this._disposeReactionOnLoginFromAppLight?.()
    this._disposeReactionOnLoginFromAppLight = reaction(
      () => this.isLoggedIn,
      (isLoggedIn) => {
        if (isLoggedIn) {
          if (this.closeAfterLogin) {
            window.close()
          }
        }
      }
    )
  }

  setSuccessAuthToken = (token: string) => {
    this.setLoggedIn(true)
    this.isNewSession = sessionManager.syncSession(true)
    this.setToken(token)
  }

  async login(body: IParamsLogin) {
    const { data } = await AuthApi.login(body)

    if (data.data.is_2fa) {
      return data.data.settings
    }

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }
  }

  socialLogin(data: IResponseToken) {
    this.setSuccessAuthToken(data.access_token)
  }

  setGoogleAuthStore = (data: IResponseToken) => {
    this.setSuccessAuthToken(data.access_token)
  }

  async register(body: IParamsRegister) {
    const { data } = await AuthApi.register(body)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  checkAssuming = () => {
    this.isAssume = !!getAssumeToken()

    if (this.isAssume) {
      bannerStore.add({
        id: nanoid(),
        action: {
          text: 'Stop assuming',
          onAction: () => {
            window.close()
          },
        },
        alert: {
          title: 'You have assumed another user’s account',
          variant: EnumAlertBannerVariant.Warning,
        },
      })
    }
  }

  setToken = (token: string) => {
    setToken(token)
  }
  removeToken = () => {
    removeTokenLocalStorage(this.isAssume)
  }
  setLoggedIn = (value: boolean) => {
    this._isLoggedIn = value
  }
  logout = async () => {
    storeCache.dispose()

    if (this.isAssume) {
      window.close()
      return
    }
    try {
      localStorage.removeItem('showedAwayStatusDate')
      localStorage.removeItem('contactDetailsOpenStatus')
      await AuthApi.logout()
      this.removeToken()

      websocket.disconnect()
      resetPosthog()
    } catch (e) {
      console.error(e)
    } finally {
      if (!window.location.href.includes('/login')) {
        const redirect = encodeURIComponent(`${location.pathname}${location.search}`)
        uiStore.changeRoute({
          path: `/${AuthRoutesEnum.login}?redirect=${redirect}`,
          type: 'vue',
        })
        window.location.reload()
      }
      runInAction(() => {
        this.isNewSession = sessionManager.syncSession(false)
      })
    }
  }

  refresh = async ({ enableLoader = true } = {}) => {
    try {
      if (enableLoader) {
        runInAction(() => {
          this.loading = true
        })
      }

      const { data } = await AuthApi.updateAuthRefresh()
      const isLogin = Boolean(data.is_login)

      runInAction(() => {
        this.setLoggedIn(isLogin)

        if (enableLoader) {
          this.isNewSession = sessionManager.isNewSession()
          sessionManager.syncSession(false)
        }
      })

      return data
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.error = e.stack || 'Error'
    } finally {
      if (enableLoader) {
        runInAction(() => {
          this.loading = false
        })
      }
    }
  }

  sendTwoFaCode = async (params: IParamsSendTwoFaCode) => {
    const { data } = await AuthApi.sendTwoFaCode(params)
    return data
  }

  verifyTwoFaCode = async (params: IParamsVerifyTwoFaCode) => {
    const { data } = await AuthApi.verifyTwoFaCode(params)

    if (data.token) {
      this.setSuccessAuthToken(data.token.access_token)
    }

    return data
  }

  setExternalSource = (external_source: string) => {
    this.external_source = external_source
  }
  setCloseAfterLogin = (value: boolean) => {
    this.closeAfterLogin = value
  }
}

export const authStore = new AuthStore()
