import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { SALESFORCE_PREMIUM_DOC } from 'shared/constants/links'
import { Button, Status, Tooltip, Typography } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { type IntegrationSalesforceSettingsInstallationStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/store/IntegrationSalesforceSettingsInstallationStore'
import { TitledRow } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/TitledRow/TitledRow'
import styles from './styles.module.scss'

type IIntegrationSalesforceSettingsInstallationProps = {
  store: IntegrationSalesforceSettingsInstallationStore
}

export const IntegrationSalesforceSettingsInstallation: FC<IIntegrationSalesforceSettingsInstallationProps> =
  observer(({ store }) => {
    const {
      isConnected,
      setPackageType,
      packageType,
      hasConnectionLimit,
      currentConnection,
      selectItems,
    } = store

    return (
      <div className={styles.root}>
        <div className={styles.info}>
          <div className={styles.block}>
            <TitledRow
              title={'Salesforce premium'}
              subtitle={<Status emphasis='high' intent='positive' title='Recommended' />}
              titleVariant={'body-lg-semibold'}
              label={
                <div className={styles.label}>
                  <div className={styles.subtitle}>
                    <div>Additional fees apply.</div>
                    <a
                      href={SALESFORCE_PREMIUM_DOC}
                      rel={'noreferrer'}
                      target={'_blank'}
                      className={'noTextDecoration'}
                    >
                      <Typography variant={'body-md-regular'} link ariaLabel={'RowCell'}>
                        Learn more
                      </Typography>
                    </a>
                  </div>
                  <div>Install from AppExchange</div>
                  <div>Seamless updates</div>
                  <div>Separate API limits</div>
                </div>
              }
            />
          </div>
          <div className={styles.block}>
            <TitledRow
              title={'Salesforce freemium'}
              titleVariant={'body-lg-semibold'}
              label={
                <div className={styles.label}>
                  <div className={styles.subtitle}>No changes in price</div>
                  <div>Install from link</div>
                  <div>Manual updates</div>
                  <div>Organization API limits</div>
                </div>
              }
            />
          </div>
        </div>

        <div className={styles.control}>
          <TitledRow
            title={'Select your package'}
            label={'Update the contact owner in Salesforce if '}
          >
            <Tooltip
              width={200}
              label={'You need to disconnect to be able to change the package'}
              placement={'left'}
              disableHoverListener={!isConnected}
              disableFocusListener={!isConnected}
            >
              <SelectField
                className={styles.select}
                withSearch={false}
                value={packageType}
                setValue={setPackageType}
                disabled={isConnected}
                dropdownProps={{
                  propsDropdownList: {
                    className: styles.dropdown,
                  },
                }}
                items={selectItems}
                size={'medium'}
              />
            </Tooltip>
          </TitledRow>
          <TitledRow
            title={'Installation'}
            label={'Latest Salesmsg Salesforce package to begin installation '}
          >
            <Button
              className={'noTextDecoration'}
              tag='a'
              target='_blank'
              rel='noreferrer'
              text='Download'
              typeBtn='contained'
              contained='secondary'
              size='large'
              href={currentConnection.downloadLink}
            />
          </TitledRow>
          {!isConnected && (
            <TitledRow
              title={'Connect'}
              label={'Post-installation, connect Salemsg to Salesforce effortlessly '}
            >
              <Button
                className={'noTextDecoration'}
                tag='a'
                target='_blank'
                rel='noreferrer'
                disabled={hasConnectionLimit}
                text='Connect'
                icon='plug'
                typeBtn='contained'
                contained='primary'
                size='large'
                href={currentConnection.connectionLink}
              />
            </TitledRow>
          )}

          <TitledRow title={'Setup'} label={'Customize Salemsg settings in Salesforce as desired '}>
            <Button
              className={'noTextDecoration'}
              tag='a'
              target='_blank'
              rel='noreferrer'
              text='Setup'
              typeBtn='contained'
              contained='secondary'
              size='large'
              disabled={!isConnected || !currentConnection.settingsLink}
              href={currentConnection.settingsLink}
            />
          </TitledRow>
        </div>
      </div>
    )
  })
