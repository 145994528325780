import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { type ContactsMergeModalStore } from 'widgets/ContactsMergeModal'
import styles from './styles.module.scss'

type IContactsMergeModalActionsProps = {
  store: ContactsMergeModalStore
}

export const ContactsMergeModalActions: FC<IContactsMergeModalActionsProps> = observer(
  ({ store }) => {
    const {
      isLoading,
      loadingMergeClick,
      activeItemIdx,
      loadingDuplicateClick,
      nextItem,
      changeLoadingDuplicateClick,
      changeMergeLoadingClick,
      config,
      total,
    } = store
    const onMergeAction = config?.onMergeAction

    return (
      <div className={styles.actionsWrapper}>
        <Typography
          variant={'body-md-regular'}
          color='var(--content-primary-disabled)'
          ariaLabel={'total'}
        >
          {`${activeItemIdx + 1} of ${total}`}
        </Typography>
        <div className={styles.actions}>
          <Button
            loading={loadingDuplicateClick}
            disabled={isLoading}
            size={'medium'}
            contained={'secondary'}
            text={'Keep both'}
            onClick={() => {
              nextItem()
              changeLoadingDuplicateClick(true)
            }}
          />

          <Button
            loading={loadingMergeClick}
            disabled={isLoading}
            size={'medium'}
            contained={'primary'}
            text={'Merge'}
            onClick={() => {
              onMergeAction?.()
              changeMergeLoadingClick(true)
            }}
          />
        </div>
      </div>
    )
  }
)
