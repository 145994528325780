import { isEqual } from 'lodash'
import { makeAutoObservable, reaction, type IReactionDisposer } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { type IResponseFiltersListSegment, SegmentBox, segmentsStore } from 'entities/Segment'
import { ContactFiltersApi } from 'entities/Contacts/api/contactsFilters'
import { Segment } from 'entities/Segment/model/Segment'
import { checkHubspotList, filterGroupsHubspot } from 'widgets/FilterEditor/integration'
import { FilterEditorStore } from 'widgets/FilterEditor'
import { type IFilterGroup } from 'widgets/FilterEditor/types'
import { type FiltersConfig } from 'widgets/FilterEditor/model'
import { urlSegmentId } from 'pages/contacts/constants/constants'
import { ContactsPath } from 'pages/contacts/route/contactsPath'

type IContactsFilterStoreProps = {
  changeSearchAndResetPaginationPage: (value: string) => void
  changeSegmentBoxHasChanges: (value: boolean) => void
  changeSegment: (value: Segment | null) => void
  changeSegmentFilter: (value: IResponseFiltersListSegment) => void
  changeFilterGroups?: (value: IFilterGroup[]) => void
  changeFilterConfig?: (value: FiltersConfig) => void
  changeFilterGroupsOrigin?: (value: IFilterGroup[]) => void
}

export class ContactsFilterStore {
  private _opened = false
  private _segmentBox = new SegmentBox()
  private _filterEditorStore = new FilterEditorStore({
    makeRequest: () => {
      return ContactFiltersApi.getFilters({
        withCustomFields: true,
        withIntegrationList: true,
        skipValues: ['tag'],
      }).then(({ data }) => data)
    },
    showSpawned: true,
    mutateFilterGroups: (groups, filterGroups) => {
      const is_hubspot = checkHubspotList(groups)

      if (is_hubspot) {
        this._filterEditorStore.setBlock(
          <div>
            HubSpot Lists cannot <br /> be combined with <br /> other filters
          </div>
        )
      } else {
        this._filterEditorStore.setBlock(null)
      }

      return filterGroupsHubspot(groups, filterGroups)
    },
  })
  private _disposerChangeFilter: IReactionDisposer | null = null
  private _disposerWatchChanges: IReactionDisposer | null = null
  private _disposeHasChanges: IReactionDisposer | null = null
  private _disposeFilterGroups: IReactionDisposer | null = null
  private _disposeFilterConfig: IReactionDisposer | null = null
  private _disposeFilterGroupsOrigin: IReactionDisposer | null = null
  private _config: IContactsFilterStoreProps | null = null
  private _search = ''
  private _tableWidth: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setConfig = (config: IContactsFilterStoreProps) => {
    this._config = config
  }

  changeSearchAndResetPaginationPage = (value: string) => {
    this._config?.changeSearchAndResetPaginationPage(value)
    this.setSearch(value)
  }

  setSearch = (value: string) => {
    this._search = value
  }

  setTableWidth = (value: number) => {
    this._tableWidth = value
  }

  initFilter = async () => {
    try {
      await this._filterEditorStore.initFilters()

      if (this._segmentBox.segment) {
        this._filterEditorStore.groups.populateSegment(this._segmentBox.segment)
      }
    } catch (e) {
      console.log(e)
    }
  }

  initPreselectFilters = () => {
    const noSavedFiltersFromUrl = this.getNoSavedFilters()

    if (noSavedFiltersFromUrl) {
      this.addFiltersFromUrl(noSavedFiltersFromUrl)
    }

    const activeSegmentId = this.getActiveSegmentIdFromUrl()

    if (activeSegmentId) {
      const activeSegment = segmentsStore.getItem(activeSegmentId)

      activeSegment && this._segmentBox.putSegment(activeSegment)
    }
  }

  setNewSegment = () => {
    const filters = this._filterEditorStore.groups.toSegmentFilters()
    const newSegment = new Segment({
      id: -1,
      name: '',
      organization_id: -1,
      filters: filters,
      is_favorite: false,
    })

    this._segmentBox.putSegment(newSegment)
  }

  setFilters = (item: Segment) => {
    this._filterEditorStore.dispose()
    this._filterEditorStore.groups.populateSegment(item)
  }

  setSegment = (item: Segment) => {
    this.setFilters(item)
    this._segmentBox.putSegment(item)
  }

  clearSegment = () => {
    this._filterEditorStore.dispose()
    this._segmentBox.removeSegment()
    this._segmentBox.setHasChanges(false)
  }

  get search() {
    return this._search
  }

  get tableWidth() {
    return this._tableWidth
  }

  get config() {
    return this._config
  }

  get segmentBox() {
    return this._segmentBox
  }

  get segmentFilter() {
    return this._filterEditorStore.groups.toSegmentFilters()
  }

  get segment() {
    return this._segmentBox.segment
  }

  get activeSegmentFilters() {
    if (!this.segment) return []

    return this.segment.filtersList
  }

  get hasFilters() {
    return Array.isArray(this.activeSegmentFilters) && this.activeSegmentFilters.length !== 0
  }

  get opened() {
    return this._opened
  }

  get filterEditorStore() {
    return this._filterEditorStore
  }

  get isNeedSaveChangesModal(): boolean {
    return this._opened && this.segment?.id !== -1
  }

  addFiltersFromUrl = (filters: Record<string, unknown>[][]) => {
    if (!filters.length) return

    this._filterEditorStore.groups.populateJson(filters)
    this.segmentBox.putSegment(this._filterEditorStore.groups.toSegment())
  }

  onOpen = (condition: boolean) => {
    this._opened = condition
  }

  onToggle = () => {
    this._opened = !this._opened
  }

  getEntriesFromUrl = () => {
    const currentUrl = window.location.href
    const queryString = currentUrl.split('?')[1]
    const searchParams = new URLSearchParams(queryString)

    const paramsIterator = searchParams.entries()
    const firstEntry = searchParams.entries().next().value || []
    const entriesArray = [...paramsIterator].slice(1)

    return {
      firstEntry: {
        key: firstEntry[0],
        value: Number(firstEntry[1]),
      },
      entriesArray,
    }
  }

  getNoSavedFilters = () => {
    const { firstEntry, entriesArray } = this.getEntriesFromUrl()

    if (firstEntry.key === urlSegmentId && firstEntry.value === 0) {
      const filterList: Record<string, unknown>[][] = []

      for (const [index, value] of entriesArray) {
        if (!filterList[Number(index)]) {
          filterList[Number(index)] = []
        }
        filterList[Number(index)].push(JSON.parse(value))
      }

      if (entriesArray.length) {
        this.onToggle()
      }

      return filterList
    }
  }

  getActiveSegmentIdFromUrl = () => {
    const { firstEntry } = this.getEntriesFromUrl()

    if (firstEntry.key === urlSegmentId) {
      return firstEntry.value
    }
  }

  reactionChangeFilter = () => {
    this._disposerChangeFilter?.()
    this._disposerChangeFilter = reaction(
      () => this.segmentFilter,
      (segmentFilters) => {
        const segmentOriginFilters = this.segmentBox.segment?.filtersParams
        const hasChange = !isEqual(segmentFilters, segmentOriginFilters)

        this._config?.changeSegmentFilter(segmentFilters)

        if (this.segmentBox.segment) {
          this.segmentBox.setHasChanges(hasChange)
        } else {
          this.segmentBox.setHasChanges(false)
        }
      }
    )
  }

  reactionWatchChanges = () => {
    this._disposerWatchChanges?.()
    this._disposerWatchChanges = reaction(
      () => this.segment,
      (active) => {
        this._config?.changeSegment(active)

        if (!active) return
        if (active.id === -1) {
          const params = new URLSearchParams()

          active.filtersList.forEach((filterBlock, blockIndex) => {
            if (filterBlock.filters.length) {
              filterBlock.filters.forEach((filter) => {
                params.append(String(blockIndex), JSON.stringify(filter))
              })
            }

            if (filterBlock.custom_filters.length) {
              filterBlock.custom_filters.forEach((filter) => {
                params.append(String(blockIndex), JSON.stringify(filter))
              })
            }
          })

          uiStore.changeRoute({
            path: `/${ContactsPath.root}?${urlSegmentId}=0&${params}`,
          })
        }
      }
    )
  }

  reactionHasChanges = () => {
    this._disposeHasChanges?.()
    this._disposeHasChanges = reaction(
      () => this.segmentBox.hasChanges,
      (value) => {
        this._config?.changeSegmentBoxHasChanges(value)
      }
    )
  }

  reactionFilterGroups = () => {
    this._disposeFilterGroups?.()
    this._disposeFilterGroups = reaction(
      () => this._filterEditorStore.filterGroups,
      (value) => {
        this._config?.changeFilterGroups?.(value)
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionFilterConfig = () => {
    this._disposeFilterConfig?.()
    this._disposeFilterConfig = reaction(
      () => this._filterEditorStore.config,
      (value) => {
        this._config?.changeFilterConfig?.(value)
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionFilterGroupsOrigin = () => {
    this._disposeFilterGroupsOrigin?.()
    this._disposeFilterGroupsOrigin = reaction(
      () => this._filterEditorStore.filterGroupsOrigin,
      (value) => {
        this._config?.changeFilterGroupsOrigin?.(value)
      },
      {
        fireImmediately: true,
      }
    )
  }

  watchChanges = () => {
    this._opened = false

    this.reactionChangeFilter()
    this.reactionWatchChanges()
    this.reactionHasChanges()
    this.reactionFilterGroups()
    this.reactionFilterConfig()
    this.reactionFilterGroupsOrigin()

    return () => {
      this._disposerChangeFilter?.()
      this._disposerWatchChanges?.()
      this._disposeHasChanges?.()
      this._disposeFilterGroups?.()
      this._disposeFilterConfig?.()
      this._disposeFilterGroupsOrigin?.()
      this._filterEditorStore.dispose()
      this._filterEditorStore.clearFutureData()
      this._segmentBox.setHasChanges(false)
      this._segmentBox.removeSegment()
    }
  }
}
