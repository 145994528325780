import { makeAutoObservable } from 'mobx'
import { logger } from 'shared/lib'
import {
  IResponseMergeField,
  IntegrationsApi,
  integrationsStore,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { Integration } from 'entities/Integrations/model/Integration'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { MergeField } from 'widgets/MergeField/types/MergeField'

export type MergeFieldProps = {
  contact?: Contact | null
  integration?: Integration | null
  onAddMergeField?: ((field: MergeField) => void | boolean) | null
  isAllIntegration?: boolean
  isAllConnectedOrDisconnectedIntegration?: boolean
  isSelectDefault?: boolean
  useAllMergeFields?: boolean
  onClose?: () => void
  zIndex?: number
}

export class MergeFieldsStore {
  contact: Contact | null
  integration: Integration | null
  onAddMergeField: ((field: MergeField) => void | boolean) | null
  constructor({
    contact,
    integration,
    onAddMergeField,
    isAllIntegration,
    isAllConnectedOrDisconnectedIntegration,
    isSelectDefault,
    useAllMergeFields,
  }: MergeFieldProps) {
    this.contact = contact || null
    this.integration = integration || null
    this.onAddMergeField = onAddMergeField || null
    this.isAllIntegration = isAllIntegration || isAllConnectedOrDisconnectedIntegration || false
    this.isAllConnectedOrDisconnectedIntegration = isAllConnectedOrDisconnectedIntegration || false
    this.isSelectDefault = isSelectDefault || false
    this._useAllMergeFields = useAllMergeFields || false
    if (!this.integration) {
      void this.loadMergeFields()
    }
    makeAutoObservable(this)
  }

  search = ''
  loading = false
  selectedIntegration: Integration | null = null
  isSelectDefault = false
  isAllIntegration = false
  isAllConnectedOrDisconnectedIntegration = false
  private _useAllMergeFields = false

  mergeFields: MergeField[] = []

  get allIntegrations(): Integration[] {
    const contactIntegration: Integration[] = []

    const allIntegrationsKeys = [
      IntegrationKey.hubspot,
      IntegrationKey.activecampaign,
      IntegrationKey.infusionsoft,
      IntegrationKey.pipedrive,
      IntegrationKey.salesforce,
    ]

    allIntegrationsKeys.forEach((key) => {
      const integration = integrationsStore.getIntegration(key)
      if (integration) {
        if (this.isAllConnectedOrDisconnectedIntegration) {
          const isConnectedOrDisconnected = !!integration.connected || !!integration.disconnected
          if (isConnectedOrDisconnected) {
            contactIntegration.push(integration)
          }
        } else {
          contactIntegration.push(integration)
        }
      }
    })

    return contactIntegration
  }

  get filteredMergeFields() {
    if (this.isAllIntegration) {
      let fields: MergeField[] = []
      if (!this.isSelectedIntegration) {
        fields = allMergeFieldsStore.allFields
      }
      if (this.isSelectDefault) {
        const map = allMergeFieldsStore.mergeFieldsIntegrationsMap.get(IntegrationKey.salesmessage)
        if (map) {
          fields = Array.from(map.values())
        }
      }
      if (this.selectedIntegration) {
        const map = allMergeFieldsStore.mergeFieldsIntegrationsMap.get(this.selectedIntegration.key)
        if (map) {
          fields = Array.from(map.values())
        }
      }
      return fields.filter((field) =>
        this.search ? field.label?.toLowerCase().includes(this.search.toLowerCase()) : true
      )
    }
    if (this.isSelectDefault) {
      if (this._useAllMergeFields) {
        let fields: MergeField[] = []
        const map = allMergeFieldsStore.mergeFieldsIntegrationsMap.get(IntegrationKey.salesmessage)
        if (map) {
          fields = Array.from(map.values())
        }

        return fields.filter((field) =>
          this.search ? field.label?.toLowerCase().includes(this.search.toLowerCase()) : true
        )
      }
      return this.mergeFields.filter(
        (field) => !field.integrationKey || field.integrationKey === IntegrationKey.salesmessage
      )
    }
    if (this.selectedIntegration) {
      return this.mergeFields.filter(
        (field) => field.integrationKey && field.integrationKey !== IntegrationKey.salesmessage
      )
    }

    return this.mergeFields
  }

  get isSelectedIntegration() {
    if (this._useAllMergeFields) return null

    return this.isSelectDefault || this.selectedIntegration
  }

  get useAllMergeFields() {
    return this._useAllMergeFields
  }

  reset = () => {
    this.mergeFields = []
    this.search = ''
  }

  setMergeFields = (mergeFields: IResponseMergeField[]) => {
    this.mergeFields = mergeFields.map((mergeField) => new MergeField(mergeField))
  }

  loadMergeFields = async () => {
    if (!this.contact) {
      return
    }
    try {
      this.loading = true
      const { data } = await IntegrationsApi.getIntegrationsContactMergeFields(
        this.contact.id,
        this.search
      )
      if (data) {
        this.setMergeFields(data)
      }
    } catch (e) {
      logger.error(e)
    } finally {
      this.loading = false
    }
  }
  setSearch = async (search: string) => {
    this.search = search
    if (!this.isAllIntegration) {
      void this.loadMergeFields()
    }
  }
  onSelectedIntegration = (integration: Integration) => {
    this.selectedIntegration = integration
    void this.loadMergeFields()
  }
  onSelectedDefault = (isSelectDefault: boolean) => {
    this.isSelectDefault = isSelectDefault
    void this.loadMergeFields()
  }
  onBack = () => {
    this.selectedIntegration = null
    this.isSelectDefault = false
    this.search = ''
  }
}
