import { makeAutoObservable } from 'mobx'
import Cookies from 'js-cookie'
import { AxiosError } from 'axios'
import { showToast } from 'shared/ui'
import { REGISTER_DATA_COOKIES, EXPIRES_REGISTER_DATA_COOKIES_IN } from 'shared/constants/cookies'
import { GoogleAuthTypesEnum, IResponseGoogleRegister, IResponseGoogleLogin } from './types'
import { AuthApi } from '../api/auth'
export class GoogleAuthStore {
  constructor(loginCallback: (payload: IResponseGoogleLogin) => void) {
    makeAutoObservable(this)
    this.initProcessRegister()
    this.initProcessLogin(loginCallback)
  }

  private _loading = false
  private _authType: GoogleAuthTypesEnum | null = null
  private _registerCallback:
    | ((data: IResponseGoogleRegister | null, error: string | null) => void)
    | null = null

  initProcessRegister = () => {
    window.processSocialRegister = async (payload: IResponseGoogleRegister) => {
      Cookies.set(REGISTER_DATA_COOKIES, JSON.stringify(payload), {
        expires: EXPIRES_REGISTER_DATA_COOKIES_IN,
      })

      this._loading = false
      const validateEmailData = await this.validateEmail(payload)

      if (!validateEmailData.isValid && this._registerCallback) {
        this._registerCallback(null, validateEmailData.error)
      }

      if (validateEmailData.isValid && this._registerCallback) {
        this._registerCallback(payload, null)

        showToast({
          type: 'success',
          title: 'We need a few more things!',
        })
      }

      this.reset()
    }
  }

  initProcessLogin = (loginCallback: (payload: IResponseGoogleLogin) => void) => {
    window.processSocialLogin = async (payload: IResponseGoogleLogin) => {
      loginCallback(payload)
      this.reset()
    }
  }

  validateEmail = async (authData: IResponseGoogleRegister) => {
    try {
      await AuthApi.validateEmail({ email: authData.email, checkDomain: true })

      return {
        isValid: true,
        error: null,
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data?.email?.[0] || error.response?.data?.error

        return {
          isValid: false,
          error: errorMessage,
        }
      }

      Cookies.remove(REGISTER_DATA_COOKIES)

      return {
        isValid: false,
        error: 'Error',
      }
    }
  }

  get loading() {
    return this._loading
  }

  onRegister = async (
    callback?: (data: IResponseGoogleRegister | null, error: string | null) => void
  ) => {
    if (callback) this._registerCallback = callback

    await this.onAuth(GoogleAuthTypesEnum.register)
  }

  onLogin = async () => {
    await this.onAuth(GoogleAuthTypesEnum.login)
  }

  onAuth = async (authType: GoogleAuthTypesEnum, isMergeLogin?: boolean) => {
    this._loading = true
    this._authType = authType

    const authWindow = window.open(
      `/app/auth/google/${isMergeLogin ? 'merge-and-login' : 'login'}`,
      '_blank',
      'menubar=yes,location=no,toolbar=yes,scrollbars=yes,resizable=yes,directories=no,status=yes'
    )

    const authTimer = setInterval(() => {
      if ((authWindow?.closed && this.loading) || (authWindow === null && this.loading)) {
        clearInterval(authTimer)
        this._loading = false
        showToast({
          type: 'warning',
          title: 'Something went wrong. Please try again...',
        })
      }
    }, 500)
  }

  reset = () => {
    this._authType = null
    this._loading = false
    this._registerCallback = null
  }
}
