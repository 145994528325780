import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { AuthRoutesEnum, authStore } from 'entities/Auth'
import { IntegrationKey } from 'entities/Integrations'
import { ConversationRoutes } from 'pages/conversations'
import { SettingsRoutes } from 'pages/settings'
import { IntegrationsRoutes } from 'pages/settings/pages/integrations/route/IntegrationsRoutes'

export const LoginLayout = observer(() => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const utmSource = searchParams.get('utm_source')
    const isHubSpotAuth = utmSource === IntegrationKey.hubspot

    if (authStore.isLoggedIn) {
      if (authStore.closeAfterLogin) {
        setTimeout(() => {
          window.close()
        }, 100)
      } else {
        window.stop()
        window.location.href = isHubSpotAuth
          ? `/${SettingsRoutes.root}/${SettingsRoutes.integrations}/${IntegrationKey.hubspot}/${IntegrationsRoutes.description}`
          : `/${ConversationRoutes.root}?${searchParams}`
      }
    } else if (authStore.hasOid === false) {
      if (window.location.pathname === `/${AuthRoutesEnum.register}`) return

      uiStore.changeRoute({
        path: `/${AuthRoutesEnum.register}?${searchParams}`,
      })
    }
  }, [authStore.isLoggedIn, authStore.hasOid])

  return <Outlet />
})
