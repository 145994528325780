import { makeAutoObservable } from 'mobx'
import { toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { ComplianceApi, IResponseOptInUpload, type IResponseOptInData } from 'entities/Compliance'
import { type IResponseMedia } from 'entities/Attachment'
import {
  TollFreeRoutesSteps,
  IOptInMethodsMap,
  IOptInYTypeMap,
} from 'pages/settings/pages/compliance/pages/tollFree/route/type'
import type { TollFreeStepsStore } from 'pages/settings/pages/compliance/pages/tollFree/store/tollFreeStepsStore'
import { CommonOptInContentStore } from 'pages/settings/pages/compliance/ui/CommonOptInContent/store/commonOptInContentStore'

const maxAttachmentsLength = 3

export class OptInConsentStore {
  private _attachmentError: Map<TollFreeRoutesSteps, { title?: string; desc?: string } | null> =
    new Map()
  private _attachmentErrorTimeouts: Map<TollFreeRoutesSteps, number | null> = new Map()
  private _optInImages: Map<TollFreeRoutesSteps, IResponseOptInUpload[] | null> = new Map()
  private _filesPreview: File[] = []
  private readonly _commonOptInConsentStore: CommonOptInContentStore

  constructor(private _tollFreeStepsStore: TollFreeStepsStore) {
    makeAutoObservable(this)
    this._commonOptInConsentStore = new CommonOptInContentStore(
      this._tollFreeStepsStore.complianceLayerStore
    )
  }

  get commonOptInConsentStore() {
    return this._commonOptInConsentStore
  }

  get complianceLayerStore() {
    return this._tollFreeStepsStore.complianceLayerStore
  }

  get isOnlineFormNextDisabled() {
    return Boolean(
      !this.commonOptInConsentStore.termsAndPrivacyAgreement ||
        this.getOptInAttachmentError(TollFreeRoutesSteps.optInOnlineFormContent) ||
        !this.getOptInImages(TollFreeRoutesSteps.optInOnlineFormContent)?.length ||
        !this.commonOptInConsentStore.isOnlineFormUrl
    )
  }

  init = (optInData: IResponseOptInData[] | null) => {
    if (!optInData) return

    if (optInData) {
      optInData.forEach((item) => {
        this.commonOptInConsentStore.setOnlineFormUrl(
          item.onlineFormUrl || '',
          Boolean(item.onlineFormUrl?.length)
        )
        this.commonOptInConsentStore.setNoPublishedOnlineFormUrl(
          item.noPublishedOnlineFormUrl || false
        )

        if (item.optInImages?.length) {
          this.setOptInImages(
            IOptInYTypeMap[item.optInMethod] as TollFreeRoutesSteps,
            item.optInImages
          )
        }
      })
    }
  }

  getOptInAttachmentError = (type: TollFreeRoutesSteps) => {
    return this._attachmentError.get(type) || null
  }

  getIsNextDisabled = (type: TollFreeRoutesSteps) => {
    return this.getOptInImages(type)?.length === 0
  }

  clearAttachmentError = (type: TollFreeRoutesSteps) => {
    this._attachmentError.set(type, null)

    const timeout = this._attachmentErrorTimeouts.get(type)
    if (timeout) {
      clearTimeout(timeout)
      this._attachmentErrorTimeouts.set(type, null)
    }
  }

  getOptInImages = (type: TollFreeRoutesSteps) => {
    return this._optInImages.get(type) || []
  }

  getOptInImagesAsAttach = (type: TollFreeRoutesSteps) => {
    const previews = this._filesPreview.map(
      (file) =>
        new Attachment({
          file,
          loading: true,
        })
    )

    const getOptInImages = this.getOptInImages(type).map((attachment) => {
      return new Attachment({
        responseMedia: {
          size: attachment.size,
          source: attachment.sourceUrl,
          name: attachment.name,
          content_type: 'image',
        } as IResponseMedia,
      })
    })

    return [...getOptInImages, ...previews]
  }

  setOptInImages = (type: TollFreeRoutesSteps, data: IResponseOptInUpload[] | null) => {
    this._optInImages.set(type, data)
  }

  addOptInImage = (type: TollFreeRoutesSteps, data: IResponseOptInUpload) => {
    const lastImages = this._optInImages.get(type) || []

    this._optInImages.set(type, [...lastImages, data])
    this._filesPreview = this._filesPreview.filter((tempFile) => tempFile.name !== data.name)
  }

  setAttachmentError = (
    type: TollFreeRoutesSteps,
    error: { title?: string; desc?: string } | null
  ) => {
    this.clearAttachmentError(type)
    this._attachmentError.set(type, error)

    if (error === null) return

    const timer = window.setTimeout(() => {
      this._attachmentError.set(type, null)
    }, 5000)

    this._attachmentErrorTimeouts.set(type, timer)
  }

  getUploadError = (files: File[], type: TollFreeRoutesSteps) => {
    const lastImages = this._optInImages.get(type) || []

    const sizeLimits = {
      'image/png': 10 * 1024 * 1024, // 10 MB
      'image/jpeg': 10 * 1024 * 1024, // 10 MB
    }

    const allowedTypes = ['image/png', 'image/jpeg']
    const noCorrectType = files.every((file) => !allowedTypes.includes(file.type))

    if (files.length + lastImages.length > maxAttachmentsLength) {
      return {
        desc: 'Please upload up to 3 images per opt-in method',
      }
    }

    if (noCorrectType) {
      return {
        title: 'The file is not supported for upload',
        desc: 'Please upload only: jpg, png',
      }
    }

    const maxSizeFile = files.find(
      (file) =>
        allowedTypes.includes(file.type) &&
        file.size > sizeLimits[file.type as keyof typeof sizeLimits]
    )

    if (maxSizeFile) {
      return {
        desc: 'The image file is too large. It must not exceed 10 MB',
      }
    }

    return null
  }

  uploadOptInImageAttachment = async (files: File[], type: TollFreeRoutesSteps) => {
    const error = this.getUploadError(files, type)

    if (error) {
      this.setAttachmentError(type, error)
      return
    }

    try {
      await Promise.all(
        files.map(async (file) => {
          this._filesPreview.push(file)

          const { data } = await ComplianceApi.setCampaignOptInFiles(file)

          this.addOptInImage(type, data[0])
          return { file, data }
        })
      )

      toastStore.add({
        title: `${files.length} file${files.length > 1 ? 's' : ''} uploaded`,
        type: 'success',
      })
    } catch (error) {
      logger.error(error)
    } finally {
      this._filesPreview = []
    }
  }

  nextStepClick = async (path: TollFreeRoutesSteps) => {
    const optInImages = this.getOptInImages(path)

    if (!IOptInMethodsMap[path] || !optInImages) return

    uiStore.changeRoute({
      path: this._tollFreeStepsStore.tollFreeNavigationStore.getNextUrl,
    })
  }

  deleteAttachment = async (sourceUrl: string, type: TollFreeRoutesSteps) => {
    try {
      const { data } = await ComplianceApi.deleteAttachment({ sourceUrl })

      if (data.deleted) {
        const filteredAttachments =
          this.getOptInImages(type)?.filter((a) => a.sourceUrl !== sourceUrl) || []

        this.setOptInImages(type, filteredAttachments)
      }
    } catch (e) {
      logger.error(e)
    }
  }
}
