import { useEffect, useState } from 'react'
import { Navigate, Outlet, useParams } from 'react-router'
import { observer } from 'mobx-react-lite'
import { EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import { logger } from 'shared/lib'
import { CampaignApi, IFullResponseCampaign } from 'entities/Campaign'
import { CampaignsPageIDContext } from 'pages/campaigns/context/context_id'

export const CampaignIdLayout = observer(() => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState<IFullResponseCampaign | null>(null)
  useEffect(() => {
    if (id) {
      const getCampaign = async () => {
        try {
          const { data } = await CampaignApi.getById(id)
          setResponse({ ...data.data, steps: data.data.steps.sort((a, b) => a.order - b.order) })
        } catch (e) {
          logger.error(e)
        } finally {
          setLoading(false)
        }
      }
      getCampaign()
    }
  }, [id])
  if (loading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }
  if (response) {
    return (
      <CampaignsPageIDContext.Provider value={response}>
        <Outlet />
      </CampaignsPageIDContext.Provider>
    )
  }
  return <Navigate to={'/'} />
})
