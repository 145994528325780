import React from 'react'
import classNames from 'classnames'
import { IconButton } from 'shared/ui/IconButton/ui/IconButton'
import type { ITooltipProps } from 'shared/ui/Tooltip/ui/Tooltip'
import styles from '../styles.module.scss'
import { useCopyToClipboard } from '../useCopyToClipboard'

type IIconCopyProps = {
  value: string | number | undefined
  tooltipProps?: ITooltipProps
  showTooltip?: boolean
  show?: boolean
  onClose?: () => void
  onOpen?: () => void
}

export const IconCopyButton: React.FC<IIconCopyProps> = ({
  value,
  showTooltip = false,
  show = true,
  onClose,
  onOpen,
  tooltipProps,
}) => {
  const { onCopy } = useCopyToClipboard(value)

  const tooltipInfo: ITooltipProps = {
    label: 'Copy',
    placement: 'top',
    disableInteractive: true,
    onClose: onClose || (() => {}),
    onOpen: onOpen || (() => {}),
    ...(tooltipProps && tooltipProps),
  }

  return (
    <div
      className={classNames(styles.rightIcon, {
        [styles.hidden]: !show,
      })}
    >
      <IconButton
        icon='copy'
        variant='icon'
        color='secondary'
        size='small'
        fontSize={16}
        onClick={onCopy}
        tooltipProps={showTooltip ? tooltipInfo : undefined}
        ariaLabel='IconCopyButton'
      />
    </div>
  )
}
