import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import { AdminOrganizationsApi, IResponseAdminOrganizationById } from 'entities/Admin'
import { AdminUpdateTaxFreeContent } from '../ui/AdminUpdateTaxFreeContent'
import { AdminUpdateTaxFreeActions } from '../ui/AdminUpdateTaxFreeActions'

class AdminUpdateTaxFreeModal {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''
  organization: IResponseAdminOrganizationById | null = null
  isTaxFree = false
  loading = false

  onSuccess: (() => void) | null = null

  get disabled() {
    return this.organization?.is_tax_free === this.isTaxFree
  }

  reset = () => {
    this.modalId = ''
    this.organization = null
    this.isTaxFree = false
    this.loading = false
  }

  toggleTaxFree = () => {
    this.isTaxFree = !this.isTaxFree
  }

  onSaveTaxFree = async () => {
    try {
      if (!this.organization?.id) return
      this.loading = true
      await AdminOrganizationsApi.updateTaxFree(this.organization.id, {
        is_tax_free: this.isTaxFree,
      })
      this.organization.is_tax_free = this.isTaxFree
      modalStore.closeModal(this.modalId)
      this.onSuccess && this.onSuccess()
    } catch (e) {
      logger.error(e)
    } finally {
      this.loading = false
    }
  }

  onCloseModal = () => {
    modalStore.closeModal(this.modalId)
    this.reset()
  }

  onOpenModal = (
    organization: IResponseAdminOrganizationById,
    onSuccess: typeof this.onSuccess
  ) => {
    this.reset()
    this.modalId = nanoid()
    this.organization = organization
    this.onSuccess = onSuccess
    this.isTaxFree = organization.is_tax_free

    modalStore.addModal({
      width: 320,
      id: this.modalId,
      title: 'Set Tax Free',
      ModalContent: AdminUpdateTaxFreeContent,
      ModalActions: AdminUpdateTaxFreeActions,
      type: ModalTypeList.DEFAULT,
      showCloseButton: false,
    })
  }
}

export const adminUpdateTaxFreeModal = new AdminUpdateTaxFreeModal()
