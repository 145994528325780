import { nanoid } from 'nanoid'
import { ActionItem, DividerCustom, Switch, toastStore } from 'shared/ui'
import { SwitchAction } from 'shared/ui/SwitchAction'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import { Campaign, CampaignApi } from 'entities/Campaign'
import { CampaignEnrolmentStore } from 'pages/campaigns/ui/CampaignEnrolment'

export const toggleActiveCampaign = (campaign: Campaign): ActionItem => {
  const onDisabled = () => {
    const id = nanoid()
    modalStore.addModal({
      id,
      type: ModalTypeList.ALERT,
      title: 'Disable Campaign?',
      desc: 'This will end all active conversations between the Campaign and enrolled contacts',
      primaryAction: {
        text: 'Disable',
        onAction: async () => {
          try {
            const { data } = await CampaignApi.patch(campaign.id, {
              status: 'disabled',
            })
            campaign.syncOrigin(data.data)
            modalStore.closeModal(id)
          } catch (e) {
            logger.error(e)
          }
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(id)
        },
      },
    })
  }

  const onActivate = async () => {
    try {
      const { data } = await CampaignApi.patch(campaign.id, {
        status: 'active',
      })
      campaign.syncOrigin(data.data)

      const campaignEnrolmentStore = new CampaignEnrolmentStore()
      await campaignEnrolmentStore.init({
        data: {
          name: data.data.name,
          id: data.data.id,
          filters: data.data.filters,
        },
      })
    } catch (e) {
      logger.error(e)
    }
  }

  const handleToggle = async (onCloseMenu?: () => void) => {
    if (campaign.status === 'active') {
      onCloseMenu?.()
      onDisabled()
    }
    if (campaign.status === 'disabled') {
      await onActivate()
      onCloseMenu?.()
    }
  }
  return {
    dropdownItemComponent: (onCloseMenu) => (
      <>
        <SwitchAction
          size={'xSmall'}
          active={campaign.status === 'active'}
          onTrigger={() => handleToggle(onCloseMenu)}
          label={campaign.status === 'active' ? 'Active' : 'Disabled'}
        />
        <DividerCustom variant={'dropdown'} />
      </>
    ),
    buttonComponent: (
      <SwitchAction
        active={campaign.status === 'active'}
        onTrigger={() => handleToggle()}
        size={'middle'}
      />
    ),
  }
}
