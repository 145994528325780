import { CSSProperties, ReactNode } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'
import tableStyles from '../Table.module.scss'

interface IPinnedCellProps {
  children: ReactNode
  isHeader?: boolean
  rowClassNames?: string
  isScrollLeft: boolean
  width?: CSSProperties['width']
  withEmptyCell?: boolean
}

export const PinnedCell = ({
  children,
  isHeader,
  rowClassNames,
  isScrollLeft,
  width,
  withEmptyCell,
}: IPinnedCellProps) => {
  const commonClassNames = classNames(styles.pinnedRow, styles.table, rowClassNames, {
    [styles.borderRight]: isScrollLeft,
  })
  if (isHeader) {
    return (
      <th className={classNames(commonClassNames, tableStyles.pinnedRowHead)} style={{ width }}>
        {/* will be removed after the Table refactoring */}
        <table>
          <thead>
            <tr>
              {withEmptyCell && <th style={{ width: 2 }} />}
              {children}
            </tr>
          </thead>
        </table>
      </th>
    )
  }

  return (
    <td className={classNames(commonClassNames, tableStyles.pinnedRow)} style={{ width }}>
      {/* will be removed after the Table refactoring */}
      <table>
        <tbody>
          <tr>
            {withEmptyCell && <th style={{ width: 2 }} />}
            {children}
          </tr>
        </tbody>
      </table>
    </td>
  )
}
