import callingCnamCallerId from 'shared/assets/images/compliance/callingCnamCallerId.png'
import { getCDNAssetURL } from 'shared/lib'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { useCallingStepsContext } from 'pages/settings/pages/compliance/pages/calling/context/callingStepsContext'
import styles from './styles.module.scss'

export const EnableCnamCallerId = () => {
  const {
    cnamCallerIdStore: { moveToSecondStep },
  } = useCallingStepsContext()
  return (
    <div>
      <PageDescription>{"Brand your outgoing calls with your company's name."}</PageDescription>
      <img
        src={getCDNAssetURL(callingCnamCallerId)}
        alt={'callingCnamCallerId_image'}
        className={styles.callingCnamCallerIdImage}
      />

      <NextButton text='Next' onClick={moveToSecondStep} />
    </div>
  )
}
