import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { Dropdown, DropdownContent, IconButton, Scrollbar, Typography } from 'shared/ui'
import {
  TextField,
  makeTextFieldAutoFocus,
  makeTextFieldBlurOnEnter,
  makeTextFieldClear,
  makeTextFieldElement,
} from 'shared/ui/TextField'
import type { IWidgetProps } from 'widgets/FilterEditor/types'

import { NumberOperators } from './definition'
import styles from './styles.module.scss'

export const WidgetMultiText = observer(
  ({ filterItem, expandByDefault = false, parentsDropdownId }: IWidgetProps) => {
    const [isFirstUpdate, setIsFirstUpdate] = useState(true)
    const [inputValue, setInputValue] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    const isNumberOperator = useMemo(
      () => !!filterItem.operator && NumberOperators.has(filterItem.operator?.key),
      [filterItem.operator?.key]
    )

    const filterValues = Array.isArray(filterItem.payload.value)
      ? filterItem.payload.value
      : filterItem.payload.value != null
      ? [filterItem.payload.value as string]
      : []

    const isNumberValue =
      filterItem.key === 'number' ||
      filterItem.key === 'area_code' ||
      filterItem.type === 'number' ||
      isNumberOperator

    const onDelete = (removeIndex: number) => {
      const value = Array.isArray(filterItem.payload.value)
        ? filterItem.payload.value.filter((_, index) => index !== removeIndex)
        : []

      filterItem.payload.updateHard({ value })
    }

    const handleSave = () => {
      setIsFirstUpdate(false)

      if (inputValue === filterItem.payload.value || !inputValue.length) return

      filterItem.payload.updateHard({ value: [...filterValues, inputValue] })
      setInputValue('')
    }

    const checkInputValue = (value: string) => {
      if (isNumberValue) {
        const cleanedValue = value.replace(/\D/g, '')
        setInputValue(cleanedValue)

        return
      }

      setInputValue(value)
    }

    const triggerText = () => {
      if (filterValues.length === 0) return 'enter value'
      if (filterValues.length === 1) return `${filterValues.length} value`

      return `${filterValues.length} values`
    }

    const isValidValue = () => {
      if (isFirstUpdate) return true

      return !filterItem.isEmpty
    }

    useEffect(() => {
      setTimeout(() => setIsOpen(expandByDefault), 100)
    }, [expandByDefault])

    return (
      <Dropdown
        ariaLabel='MultiTextDropdown'
        placement='bottom-start'
        margin={10}
        width={240}
        withTextInput
        parentsDropdownId={parentsDropdownId}
        show={isOpen}
        onClose={handleSave}
        onToggle={(value) => {
          if (value) setIsOpen(true)
        }}
        triggerComponent={() => (
          <span
            className={classNames(
              styles.dropdownTriggerComponent,
              !isValidValue() && styles.warning
            )}
          >
            {triggerText()}
          </span>
        )}
        customComponent={() => (
          <DropdownContent noPadding>
            <TextField
              size='medium'
              variant='integrated'
              ariaLabel='MultiTextField'
              className={styles.textField}
              value={inputValue}
              onChange={(value) => checkInputValue(value)}
              onBlur={handleSave}
              InputProps={{ placeholder: `Enter ${isNumberValue ? 'number' : 'text'} value` }}
              mainActions={[
                makeTextFieldAutoFocus({ withFocus: true }),
                makeTextFieldBlurOnEnter(),
              ]}
              rightActions={[
                makeTextFieldElement({
                  element: (
                    <span className={styles.rightText}>{isNumberOperator ? 'days ago' : ''}</span>
                  ),
                }),
                makeTextFieldClear(),
              ]}
            />
            <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={300}>
              <div
                className={classNames(styles.textValues, {
                  [styles.emptyList]: filterValues.length === 0,
                })}
              >
                {filterValues.map((item, index) => (
                  <div key={item} className={styles.value}>
                    <Typography
                      ellipsis
                      color='var(--content-primary-primary)'
                      variant='body-rg-regular'
                      ariaLabel='MultiTextDropdownItem'
                    >
                      {item}
                    </Typography>
                    <IconButton
                      icon='close'
                      variant='icon'
                      color='secondary'
                      size='small'
                      ariaLabel='MultiTextRemoveButton'
                      fontSize={16}
                      tooltipProps={{
                        label: 'Remove',
                        placement: 'top',
                      }}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        onDelete(index)
                      }}
                    />
                  </div>
                ))}
              </div>
            </Scrollbar>
          </DropdownContent>
        )}
      />
    )
  }
)
