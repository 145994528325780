import { makeAutoObservable, runInAction } from 'mobx'
import { updateDesignV2Api } from 'shared/lib'
import { FeaturesFlagsApi } from 'entities/FeatureFlags/api/featuresFlags'
import { userSettingsStore } from 'entities/Settings'

export type IFeatureFlagsStore = FeatureFlagsStore

class FeatureFlagsStore {
  initPromise
  private _setFinishInit: ((value?: unknown) => void) | null = null
  loading = false

  groups_flow = false
  workflows = false
  call_history = false
  message_deduplication = false
  call_distribution = false
  allNumbersCalls = false
  chatbot = false
  show_new_design_only = false
  call_via_aircall = false
  media_library_v2 = false
  inteliquent_calling = false
  rag = false
  hubspot_pin_list = false
  salesmsg_design_v2_broadcasts = false
  salesmsg_design_v2_triggers = false
  salesmsg_design_v2_integrations = false
  salesmsg_design_v2_hubspot_page = false
  salesmsg_design_v2_salesforce = false
  salesmsg_design_v2_keywords = false
  salesmsg_design_v2_compliance_toll_free = false
  salesmsg_design_v2_compliance_calling = false
  salesmsg_design_v2_new_sidebar_menu = false
  campaigns: boolean | null = null
  aircall_new_api = false
  quick_links = false
  salesmsg_power_dialer_v2 = false
  salesmsg_design_v2_calendly_page = false

  constructor() {
    this.initPromise = new Promise((resolve) => {
      this._setFinishInit = resolve
    })

    makeAutoObservable(this)
  }

  private _fetchFeatureFlags = async () => {
    try {
      this.loading = true

      const { data: featureFlags } = await FeaturesFlagsApi.getFeatureFlags()

      runInAction(() => {
        this.groups_flow = featureFlags.groups_flow
        this.workflows = featureFlags.workflows
        this.call_history = featureFlags.call_history
        this.message_deduplication = featureFlags.message_deduplication
        this.call_distribution = featureFlags.call_distribution
        this.allNumbersCalls = featureFlags.allNumbersCalls
        this.chatbot = featureFlags.chatbot
        this.show_new_design_only = featureFlags.show_new_design_only
        this.call_via_aircall = featureFlags.call_via_aircall
        this.media_library_v2 = featureFlags.media_library_v2
        this.inteliquent_calling = featureFlags.inteliquent_calling
        this.rag = featureFlags.rag
        this.hubspot_pin_list = featureFlags.hubspot_pin_list
        this.salesmsg_design_v2_broadcasts = featureFlags.salesmsg_design_v2_broadcasts
        this.salesmsg_design_v2_triggers = featureFlags.salesmsg_design_v2_triggers
        this.salesmsg_design_v2_integrations = featureFlags.salesmsg_design_v2_integrations
        this.salesmsg_design_v2_hubspot_page = featureFlags.salesmsg_design_v2_hubspot_page
        this.salesmsg_design_v2_salesforce = featureFlags.salesmsg_design_v2_salesforce
        this.salesmsg_design_v2_keywords = featureFlags.salesmsg_design_v2_keywords
        this.salesmsg_design_v2_compliance_toll_free =
          featureFlags.salesmsg_design_v2_compliance_toll_free
        this.salesmsg_design_v2_compliance_calling =
          featureFlags.salesmsg_design_v2_compliance_calling
        this.salesmsg_design_v2_new_sidebar_menu = featureFlags.new_navigation_menu
        this.salesmsg_power_dialer_v2 = featureFlags.salesmsg_power_dialer_v2
        this.salesmsg_design_v2_calendly_page = featureFlags.salesmsg_design_v2_calendly_page
        this.campaigns = featureFlags.campaigns
        this.aircall_new_api = featureFlags.aircall_new_api
        this.quick_links = featureFlags.quick_links
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })

      updateDesignV2Api({
        is_enable_new_design: this.show_new_design_only ?? userSettingsStore.isSalesmsgDesignV2,
        salesmsg_design_v2_broadcasts: this.salesmsg_design_v2_broadcasts,
        salesmsg_design_v2_triggers: this.salesmsg_design_v2_triggers,
        salesmsg_design_v2_integrations: this.salesmsg_design_v2_integrations,
        salesmsg_design_v2_hubspot_page: this.salesmsg_design_v2_hubspot_page,
        salesmsg_design_v2_salesforce: this.salesmsg_design_v2_salesforce,
        salesmsg_design_v2_keywords: this.salesmsg_design_v2_keywords,
        salesmsg_design_v2_compliance_toll_free: this.salesmsg_design_v2_compliance_toll_free,
        salesmsg_design_v2_compliance_calling: this.salesmsg_design_v2_compliance_calling,
        salesmsg_design_v2_calendly_page: this.salesmsg_design_v2_calendly_page,
        campaigns: this.campaigns || false,
        chatbot: this.chatbot,
        rag: this.rag,
      })
    }
  }

  fetchFeatureFlags = async () => {
    await this._fetchFeatureFlags()
    this._setFinishInit?.()
  }

  get canOpenWorkflow() {
    return this.workflows
  }
}

export const featureFlagsStore = new FeatureFlagsStore()
