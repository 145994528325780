import { observer } from 'mobx-react-lite'
import { useCampaignsPageContext } from 'pages/campaigns/context/context'
import { CampaignDetail } from 'pages/campaigns/ui/detail/CampaignDetail'
import { useCampaignsPageIDContext } from 'pages/campaigns/context/context_id'

export const DetailCampaign = observer(() => {
  const { pageLayoutStore } = useCampaignsPageContext()
  const response = useCampaignsPageIDContext()
  if (!response) return null
  return <CampaignDetail response={response} pageLayoutStore={pageLayoutStore} />
})
