import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { CallingRoutesSteps } from 'pages/settings/pages/compliance/pages/calling/route/type'

export class CnamCallerIdStore {
  constructor() {
    makeAutoObservable(this)
  }

  moveToSecondStep = () => {
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.calling.root}/${CallingRoutesSteps.configure}`,
    })
  }
}
