import { makeAutoObservable, runInAction } from 'mobx'
import { getEntries, logger, SingletonRequest } from 'shared/lib'
import {
  type IIntegrationKey,
  IntegrationKey,
  IntegrationsApi,
  integrationsStore,
} from 'entities/Integrations'
import { MergeField } from 'widgets/MergeField/types/MergeField'

class AllMergeFieldsStore {
  private _loading = false
  private _mergeFieldsIntegrationsMap = new Map<IIntegrationKey, Map<string, MergeField>>()

  constructor() {
    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  get mergeFieldsIntegrationsMap() {
    return this._mergeFieldsIntegrationsMap
  }

  get mergeFieldsIntegrationsList() {
    return Array.from(this._mergeFieldsIntegrationsMap.values()).flatMap((integration) => {
      return Array.from(integration.values())
    })
  }

  get allFields(): MergeField[] {
    let fields: MergeField[] = []
    this._mergeFieldsIntegrationsMap.forEach((map) => {
      if (map) {
        fields = [...fields, ...Array.from(map.values())]
      }
    })
    return fields.filter((field) =>
      field.integrationKey === IntegrationKey.salesmessage
        ? true
        : !!integrationsStore.getIntegration(field.integrationKey)?.isConnected
    )
  }

  getIntegrationFields = async (key: IIntegrationKey) => {
    const integration = this._mergeFieldsIntegrationsMap.get(key)
    if (integration && integration.size) {
      return Array.from(integration.values())
    }
    const map = await this.updateIntegrationFields(key)
    return Array.from(map?.values() || [])
  }

  isMergeField = (match: string) => {
    const integrationKeyList = Object.values(IntegrationKey)

    integrationKeyList.forEach((key) => {
      if (match.includes(key)) return true
    })

    return this.mergeFieldsIntegrationsList.find((mergeField) => {
      const matchKey = match.split(' ')?.[1]
      if (!matchKey) return false
      return mergeField.template === `{ ${matchKey} }`
    })
  }

  updateIntegrationFields = async (key: IIntegrationKey) => {
    try {
      this._loading = true

      const { data } = await IntegrationsApi.getIntegrationsByKeyFields(key)
      const map = new Map(
        data.map((field) => {
          const newField = new MergeField(field)
          return [newField.name, newField]
        })
      )

      runInAction(() => {
        this._mergeFieldsIntegrationsMap.set(key, map)
      })
      return map
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  private _loadAllFields = async () => {
    try {
      this._loading = true

      const { data } = await IntegrationsApi.getAllFields()

      runInAction(() => {
        this._mergeFieldsIntegrationsMap.clear()
        getEntries(data).forEach(([key, fields]) => {
          const map = new Map(
            fields.map((field) => {
              const newField = new MergeField(field)
              return [newField.name, newField]
            })
          )
          this._mergeFieldsIntegrationsMap.set(key, map)
        })
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  loadAllFields = new SingletonRequest(this._loadAllFields).request

  getMergeFieldInfoByTemplate = (
    template: string
  ): {
    mergeField: MergeField | null
    fallback: string
    mergeFieldKey: string
    integrationKey: string
    fullMergeField: string
  } => {
    const newText = template.replace(/{|}/g, '').trim()
    const split = newText.split('|')
    const fullMergeField = split[0].trim()
    const fallback = (split[1] || '').replace(/&#39;/g, '').trim()
    const splitKey = fullMergeField.split('.')
    const integrationKey = Object.values(IntegrationKey).includes(splitKey[0] as IntegrationKey)
      ? splitKey[0]
      : IntegrationKey.salesmessage

    const mergeFieldKey = (splitKey[1] || splitKey[0] || '').trim()
    const integrationMergeFieldMap =
      integrationKey && this._mergeFieldsIntegrationsMap.get(integrationKey as IntegrationKey)
    const mergeField =
      integrationMergeFieldMap &&
      (integrationMergeFieldMap.get(mergeFieldKey) || integrationMergeFieldMap.get(fullMergeField))

    return {
      mergeField: mergeField || null,
      fallback,
      mergeFieldKey,
      integrationKey,
      fullMergeField,
    }
  }
}

export default new AllMergeFieldsStore()
