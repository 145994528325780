import { observer } from 'mobx-react-lite'
import type { PowerDialerStatus } from 'entities/PowerDialer/api/types'
import type { IWidgetProps } from 'widgets/FilterEditor/types'
import { WidgetStatus } from 'widgets/FilterEditor/Widget/WidgetStatus'
import { powerDialerStatusBadgeConfig } from 'widgets/PowerDialer/lib/powerDialerStatusBadgeConfig'

export const WidgetPowerDialerStatus = observer((props: IWidgetProps) => {
  return (
    <WidgetStatus
      getConfigByStatus={(status) => powerDialerStatusBadgeConfig[status as PowerDialerStatus]}
      {...props}
    />
  )
})
