import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Alert, Button, toastStore } from 'shared/ui'
import { logger } from 'shared/lib'
import vCardStore from 'entities/VCard/store/vCardStore'
import { CompareVCard } from 'entities/VCard/ui/CompareVCardsContent/CompareVCard'
import { ContactsApi } from 'entities/Contacts'
import { IParamsContact } from 'entities/Contacts/api/types'
import styles from './styles.module.scss'

export const CompareVCardsContent = observer(() => {
  const { onCloseModal } = vCardStore

  const [loading, setLoading] = useState(false)

  if (!vCardStore.modalVCard) {
    return <></>
  }

  const {
    currentVCard,
    newVCard,
    modalVCard,
    isCurrentSelected,
    setCurrentSelected,
    duplicateContacts,
  } = vCardStore

  const onMerge = async () => {
    if (!duplicateContacts.length) return
    try {
      setLoading(true)
      const params: IParamsContact = isCurrentSelected
        ? {
            email: currentVCard?.email || newVCard?.email,
            number: currentVCard?.phone || newVCard?.phone,
            first_name: currentVCard?.first_name || newVCard?.first_name,
            last_name: currentVCard?.last_name || newVCard?.last_name,
          }
        : {
            email: newVCard?.email || currentVCard?.email,
            number: newVCard?.phone || currentVCard?.phone,
            first_name: newVCard?.first_name || currentVCard?.first_name,
            last_name: newVCard?.last_name || currentVCard?.last_name,
          }
      await ContactsApi.updateContact(duplicateContacts[0].id, params)
      vCardStore.onCloseModal()
      toastStore.add({
        title: 'Contact saved',
        type: 'success',
      })
    } catch (e) {
      logger.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.wrap}>
      <Alert
        item={{
          type: 'infoLight',
          desc: (
            <>
              A contact with the phone number <b>{modalVCard.phone}</b> already exists. Do you want
              to keep your current contact or update it with the new one?
            </>
          ),
        }}
      />
      <div className={styles.wrapCards}>
        {currentVCard && (
          <CompareVCard
            vCard={currentVCard}
            onCardClick={() => setCurrentSelected(true)}
            selected={isCurrentSelected}
            status={'Current'}
          />
        )}
        {newVCard && (
          <CompareVCard
            vCard={newVCard}
            onCardClick={() => setCurrentSelected(false)}
            selected={!isCurrentSelected}
            status={'New'}
          />
        )}
      </div>

      <div className={styles.actions}>
        <Button contained={'secondary'} size={'medium'} text={'Cancel'} onClick={onCloseModal} />
        <Button
          contained={'primary'}
          size={'medium'}
          text={'Merge'}
          onClick={onMerge}
          disabled={loading}
          loading={loading}
        />
      </div>
    </div>
  )
})
