import { makeAutoObservable, runInAction } from 'mobx'
import { RequestLoadingStatus } from 'shared/store/RequestLoadingStatus'
import {
  IHubspotSettingsDict,
  Integration,
  IntegrationSettingsHubspotCheckboxes,
} from 'entities/Integrations'
import { HUBSPOT_SYNC_CHECKBOX_KEYS } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/lib'
import type { IntegrationCustomSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCustomSettingsStore'
import {
  IntegrationSyncType,
  GET_SYNC_TYPE_DICT,
  GET_SYNC_TYPE_OPTIONS,
} from 'pages/settings/pages/integrations/pages/integrationSettings/lib'

export class IntegrationHubspotSettingsSyncStore {
  private _status = new RequestLoadingStatus()
  syncType: IntegrationSyncType = IntegrationSyncType.oneWay

  constructor(
    private _integration: Integration,
    private _customSetting: IntegrationCustomSettingsStore<IHubspotSettingsDict>
  ) {
    makeAutoObservable(this)
  }

  init() {
    const timeout = setInterval(() => {
      if (this._customSetting.status.isSuccess) {
        clearInterval(timeout)

        if (HUBSPOT_SYNC_CHECKBOX_KEYS.some((key) => Boolean(this._customSetting.getValue(key)))) {
          runInAction(() => {
            this.syncType = IntegrationSyncType.twoWay
          })
        }
      }
    }, 200)
  }

  updateSyncData = (keys: IntegrationSettingsHubspotCheckboxes[], value: boolean) => {
    this._customSetting.patchData(
      keys.map((key) => ({
        key,
        value: value ? 1 : 0,
      }))
    )
  }

  setSyncType = (type: IntegrationSyncType) => {
    this.syncType = type

    if (
      type === IntegrationSyncType.oneWay &&
      HUBSPOT_SYNC_CHECKBOX_KEYS.some((key) => Boolean(this._customSetting.getValue(key)))
    ) {
      this.updateSyncData(HUBSPOT_SYNC_CHECKBOX_KEYS, false)
    }
  }

  setCheckbox = (type: IntegrationSettingsHubspotCheckboxes) => async (value: boolean) => {
    if (type === 'update_contacts' && value) {
      this.updateSyncData(['update_owner', type], true)
    } else {
      this.updateSyncData([type], value)
    }
  }

  getCheckbox = (type: IntegrationSettingsHubspotCheckboxes) => {
    return Boolean(this._customSetting.getValue(type))
  }

  get isTwoWaySync() {
    return this.syncType === IntegrationSyncType.twoWay
  }

  get getLabel() {
    return GET_SYNC_TYPE_DICT(this._integration.name)[this.syncType]
  }

  get selectItems() {
    return GET_SYNC_TYPE_OPTIONS(this._integration.name)
  }

  get status() {
    return this._status.status
  }
}
