import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Tabs, type ITabItem, Alert, Scrollbar } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { type LinkContactStore } from 'pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable/ui/LinkContact'
import {
  ProviderLinkContact,
  useLinkContactContext,
} from 'pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable/ui/LinkContact/context/linkContactContext'
import { LinkManualTab } from './LinkManualTab/LinkManualTab'
import { LinkMatchingTab } from './LinkMatchingTab/LinkMatchingTab'
import styles from './styles.module.scss'

const LinkContactModal = observer(() => {
  const { handleActiveTab, activeTab, matchedCount, integrationName } = useLinkContactContext()
  const { isHoverModal } = modalStore
  const tabs: ITabItem[] = [
    {
      name: 'Matching',
      key: 'matching',
    },
    {
      name: 'Link manually',
      key: 'manually',
    },
  ]

  useEffect(() => {
    return () => {
      handleActiveTab('matching')
    }
  }, [])

  return (
    <Scrollbar
      hideTrack={!isHoverModal}
      autoHide={false}
      autoHeight
      autoHeightMin={0}
      autoHeightMax={632}
    >
      <div className={classnames(styles.wrap)}>
        <Alert
          item={{
            desc: `We found ${matchedCount} matching ${
              matchedCount === 1 ? 'contact' : 'contacts'
            } in ${integrationName} with the same phone number`,
            type: 'infoLight',
          }}
        />
        <div className={styles.tabs}>
          <Tabs
            tabs={tabs}
            activeTabKey={activeTab}
            handleSelectTab={(tab) => handleActiveTab(tab.key)}
          />
        </div>
        {activeTab === 'manually' && <LinkManualTab />}
        {activeTab === 'matching' && <LinkMatchingTab />}
      </div>
    </Scrollbar>
  )
})

type ILinkContactModalContentProps = {
  store: LinkContactStore
}

export const LinkContactModalContent: FC<ILinkContactModalContentProps> = observer(({ store }) => {
  return (
    <ProviderLinkContact value={store}>
      <LinkContactModal />
    </ProviderLinkContact>
  )
})
