import { type AxiosRequestConfig, AxiosResponse } from 'axios'
import type { AxiosOptions, IResponseStatus } from 'shared/api'
import { API_URL_MICROSERVICE } from 'shared/config'
import { baseApi } from 'entities/Auth'
import type { IResponseContact, IResponseContactsSearch } from 'entities/Contacts/api/types'
import type { Integration } from 'entities/Integrations/model/Integration'
import { MICROSERVICE_INTEGRATIONS } from 'entities/Integrations/lib/constants'
import type {
  IConnectTwilioParams,
  IIntegrationKey,
  IIntegrationLinkDTO,
  IIntegrationSearchMatchingContactsParams,
  IParamsCreateHubspotExtensionInvitationRequest,
  IParamsGetHubspotExtensionUserDetect,
  IParamsPagination,
  IParamsIntegrationActivecampaignTagsApply,
  IParamsIntegrationContact,
  IParamsIntegrationInfusionsoftTagsApply,
  IParamsIntegrations,
  IParamsIntegrationsByKeyFields,
  IParamsIntegrationsContactsSearch,
  IParamsSearchAssociatedIntegrationContact,
  IResponseCheckIntegrationConnectById,
  IResponseConnectTwilio,
  IResponseCreateHubspotExtensionInvitationRequest,
  IResponseGetHubspotExtensionUserDetect,
  IResponseGetHubspotLists,
  IResponseGetIntegrationSettings,
  IResponseGetIntegrationUsers,
  IResponseInfusionsoftInfo,
  IResponseInfusionsoftTags,
  IResponseIntegration,
  IResponseIntegrationActivecampaignTagsApply,
  IResponseIntegrationCategory,
  IResponseIntegrationContact,
  IResponseIntegrationContactsMatchingSearch,
  IResponseIntegrationInfusionsoftTagsApply,
  IResponseIntegrations,
  IResponseIntegrationsContactsActiveCampaignInfo,
  IResponseIntegrationsContactsHubspotInfo,
  IResponseIntegrationsContactsPipedriveInfo,
  IResponseIntegrationsContactsSalesforceInfo,
  IResponseMergeField,
  IResponseSearchAssociatedIntegrationContact,
  IResponseGetIntegrationMeetings,
  IParamsConnectActiveCampaign,
  IResponseUserIntegration,
  IHubspotPropertiesObjectType,
  ICrmUser,
  ICrmUserIntegrationMeta,
  IParamsPatchIntegrationUserFieldValue,
  IResponseIntegrationsContactsHubspotInfoByType,
  IResponseGetIntegrationChannels,
  IIntegrationOrganizationSetting,
  IIntegrationOrganizationSettingsResponse,
  IGetIntegrationVersionResponse,
  IResponseGetHubspotContactsList,
  IParamsSetHubspotContactsLists,
  IParamsGetTwilioAccount,
  IResponseGetTwilioAccount,
  ISettingRequestBySettingDict,
  ISettingRequestOptionsBySettingDict,
} from './types'

class Api {
  getIntegrations(
    params?: IParamsIntegrations,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseIntegrations>> {
    return baseApi.get('integrations/list', { params, ...options })
  }

  getIntegrationByKey(key: IIntegrationKey): Promise<AxiosResponse<IResponseIntegration>> {
    return baseApi.get(`integrations/${key}`)
  }

  getIntegrationsCategory(): Promise<AxiosResponse<IResponseIntegrationCategory>> {
    return baseApi.get('/integrations/category')
  }

  checkIntegrationConnectById(
    integrationKey: IIntegrationKey
  ): Promise<AxiosResponse<IResponseCheckIntegrationConnectById>> {
    return baseApi.get(`integrations/${integrationKey}/check`)
  }

  getIntegrationsContactsActivecampaign(
    params: IParamsIntegrationsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('integrations/contacts/activecampaign', { params, ...options })
  }

  getIntegrationsContactsInfusionsoft(
    params: IParamsIntegrationsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('integrations/contacts/infusionsoft', { params, ...options })
  }

  getIntegrationsContactsPipedrive(
    params: IParamsIntegrationsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('integrations/contacts/pipedrive', { params, ...options })
  }

  getIntegrationsContactsSalesforce(
    params: IParamsIntegrationsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('integrations/contacts/salesforce', { params, ...options })
  }

  getIntegrationsContactsHubspot(
    params: IParamsIntegrationsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('integrations/contacts/hubspot', { params, ...options })
  }
  getIntegrationsContactMergeFields(
    id: number,
    query?: string
  ): Promise<AxiosResponse<IResponseMergeField[]>> {
    return baseApi.get(`integrations/${id}/merge-fields`, { params: { query } })
  }

  getAllFields(): Promise<AxiosResponse<{ [key in IIntegrationKey]: IResponseMergeField[] }>> {
    return baseApi.get('integrations/all-fields')
  }

  getIntegrationsByKeyFields(
    key: IParamsIntegrationsByKeyFields
  ): Promise<AxiosResponse<IResponseMergeField[]>> {
    return baseApi.get(`integrations/${key}/fields`)
  }

  getHubspotFields(
    params: { contact_id: string | number; search: string },
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseMergeField[]>> {
    return baseApi.get('integrations/hubspot/fields', {
      ...options,
      params: { ...params, is_ui_setting: 1 },
    })
  }

  getHubspotFieldsByType(
    params: { search: string; objectType: IHubspotPropertiesObjectType; objectId: number },
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseMergeField[]>> {
    const { objectType, objectId, search } = params
    return baseApi.get(`integrations/hubspot/${objectType}/${objectId}/fields`, {
      ...options,
      params: { search },
    })
  }

  getContactsIntegrationsHubspotInfoByType(params: {
    id: number
    objectType: IHubspotPropertiesObjectType
  }): Promise<AxiosResponse<IResponseIntegrationsContactsHubspotInfoByType>> {
    const { id, objectType } = params
    return baseApi.get(`integrations/hubspot/${objectType}/${id}/info`)
  }

  getContactsIntegrationsHubspotInfo(params: {
    id: number
    use_contact_card_properties?: boolean
  }): Promise<AxiosResponse<IResponseIntegrationsContactsHubspotInfo>> {
    const { id, use_contact_card_properties } = params
    return baseApi.get(`hubspot/contact/${id}/info`, {
      params: { use_contact_card_properties: Number(use_contact_card_properties) },
    })
  }

  getContactsIntegrationsActiveCampaignInfo(
    id: number
  ): Promise<AxiosResponse<IResponseIntegrationsContactsActiveCampaignInfo>> {
    return baseApi.get(`activecampaign/contact/${id}/info`)
  }

  getContactsIntegrationsPipedriveInfo(
    id: number
  ): Promise<AxiosResponse<IResponseIntegrationsContactsPipedriveInfo>> {
    return baseApi.get(`pipedrive/extension/contact/${id}/info`)
  }

  getContactsIntegrationsSalesforceInfo(
    id: number
  ): Promise<AxiosResponse<IResponseIntegrationsContactsSalesforceInfo>> {
    return baseApi.get(`salesforce/contact/${id}/info`)
  }

  getContactsIntegrationsInfusionsoftInfo(
    id: number
  ): Promise<AxiosResponse<IResponseInfusionsoftInfo>> {
    return baseApi.get(`infusionsoft/contact/${id}/info`)
  }

  unlinkContactsByIdIntegrations(id: number): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.put(`integrations/contacts/${id}/unlink`)
  }

  createContactsIntegrationsActiveCampaignTag(
    data: IParamsIntegrationActivecampaignTagsApply
  ): Promise<AxiosResponse<IResponseIntegrationActivecampaignTagsApply>> {
    return baseApi.put('/integrations/activecampaign/tags/apply', data)
  }

  createContactsIntegrationsInfusionsoftTag(
    data: IParamsIntegrationInfusionsoftTagsApply
  ): Promise<AxiosResponse<IResponseIntegrationInfusionsoftTagsApply>> {
    return baseApi.put('/integrations/infusionsoft/tags/apply', data)
  }

  getContactsIntegrationsInfusionsoftTags(
    value: string
  ): Promise<AxiosResponse<IResponseInfusionsoftTags>> {
    return baseApi.get(`integrations/infusionsoft/tags?query=${value}`)
  }

  linkContactsIntegrations(data: IIntegrationLinkDTO): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.post(`contacts/${data.contact_id}/link`, data)
  }

  searchMatchingContactsIntegrations(
    params: IIntegrationSearchMatchingContactsParams
  ): Promise<AxiosResponse<IResponseIntegrationContactsMatchingSearch>> {
    return baseApi.get('/integrations/contacts/search', { params })
  }

  connectTwilio(params: IConnectTwilioParams): Promise<AxiosResponse<IResponseConnectTwilio>> {
    return baseApi.post('integrations/number-vendors/twilio-integration/connect', params)
  }

  getHubspotLists(): Promise<AxiosResponse<IResponseGetHubspotLists[]>> {
    return baseApi.get('hubspot/lists/contact/processed')
  }

  getHubspotContactsLists(): Promise<AxiosResponse<IResponseGetHubspotContactsList[]>> {
    return baseApi.get('hubspot/lists/contact')
  }

  resyncHubspotLists(listId: number): Promise<AxiosResponse<IResponseGetHubspotLists>> {
    return baseApi.post(`hubspot/lists/resync/${listId}`)
  }

  setHubspotContactsLists(
    params: IParamsSetHubspotContactsLists
  ): Promise<AxiosResponse<IResponseConnectTwilio>> {
    return baseApi.post('hubspot/lists/process', params)
  }

  getIntegrationContact(
    params: IParamsIntegrationContact
  ): Promise<AxiosResponse<IResponseIntegrationContact>> {
    return baseApi.get('extension/contact', {
      params,
    })
  }

  linkIntegrationContact(
    id: number,
    params: IParamsIntegrationContact
  ): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.post(`extension/contact/${id}/link`, params)
  }
  searchAssociatedIntegrationContact(
    integrationKey: IIntegrationKey,
    params: IParamsSearchAssociatedIntegrationContact,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseSearchAssociatedIntegrationContact>> {
    return baseApi.get(`integrations/contacts/${integrationKey}`, { params, ...options })
  }
  getHubspotExtensionUserDetect(
    params: IParamsGetHubspotExtensionUserDetect
  ): Promise<AxiosResponse<IResponseGetHubspotExtensionUserDetect>> {
    return baseApi.get('hubspot/extension/user/detect', {
      params,
    })
  }
  createHubspotExtensionInvitationRequest(
    params: IParamsCreateHubspotExtensionInvitationRequest
  ): Promise<AxiosResponse<IResponseCreateHubspotExtensionInvitationRequest>> {
    return baseApi.post('hubspot/extension/user/invitation/request', params)
  }

  getIntegrationMeetings(
    integrationKey: IIntegrationKey
  ): Promise<AxiosResponse<IResponseGetIntegrationMeetings>> {
    return baseApi.get(`integrations/${integrationKey}/meeting`)
  }

  getConnectIntegrationRedirectUrl(integrationKey: IIntegrationKey): string {
    if (MICROSERVICE_INTEGRATIONS.includes(integrationKey)) {
      return `${API_URL_MICROSERVICE}integration/connect/${integrationKey}`
    }

    return `${API_URL_MICROSERVICE}core/integrations/${integrationKey}/connect`
  }

  connectActiveCampaign({
    integrationKey,
    apiUrl,
    apiKey,
  }: IParamsConnectActiveCampaign): Promise<AxiosResponse<IResponseUserIntegration>> {
    return baseApi.post(`integrations/${integrationKey}/connect`, {
      url: apiUrl,
      api_key: apiKey,
    })
  }

  disconnectIntegration(integration: Integration): Promise<AxiosResponse<IResponseStatus>> {
    const integrationKey = integration.key
    const userIntegrationId = integration.connected?.id
    const params = userIntegrationId ? { id: userIntegrationId } : null

    if (MICROSERVICE_INTEGRATIONS.includes(integrationKey)) {
      return baseApi.put(`integration/disconnect/${integrationKey}`, params)
    }

    return baseApi.put(`integrations/${integrationKey}/disconnect`, params)
  }
  getIntegrationSettings<T>(
    integrationKey: IIntegrationKey
  ): Promise<AxiosResponse<IResponseGetIntegrationSettings<T>>> {
    return baseApi.get(`integration/settings/${integrationKey}`)
  }
  putIntegrationSettings<T>(
    integrationKey: IIntegrationKey,
    data: ISettingRequestBySettingDict<T>,
    config: AxiosRequestConfig<ISettingRequestBySettingDict<T>>
  ): Promise<AxiosResponse<IResponseGetIntegrationSettings<T>>> {
    return baseApi.put(`integration/settings/${integrationKey}`, data, config)
  }
  getIntegrationUsersList(
    integrationKey: IIntegrationKey,
    params: IParamsPagination
  ): Promise<AxiosResponse<IResponseGetIntegrationUsers>> {
    return baseApi.get(`integrations/${integrationKey}/users`, {
      params,
    })
  }
  patchIntegrationUserFieldValue(
    integrationKey: IIntegrationKey,
    userId: number,
    data: IParamsPatchIntegrationUserFieldValue
  ): Promise<AxiosResponse<ICrmUser<ICrmUserIntegrationMeta>>> {
    return baseApi.patch(`integrations/${integrationKey}/users/${userId}`, data)
  }
  getIntegrationSettingsList<T>(
    integrationKey: IIntegrationKey
  ): Promise<AxiosResponse<ISettingRequestOptionsBySettingDict<T>>> {
    return baseApi.get(`integration/settings/${integrationKey}/list`)
  }
  getIntegrationChannels(
    integrationKey: IIntegrationKey,
    params: IParamsPagination
  ): Promise<AxiosResponse<IResponseGetIntegrationChannels>> {
    return baseApi.get(`integrations/${integrationKey}/channels`, {
      params,
    })
  }
  activateIntegrationRefresh(integrationKey: IIntegrationKey): Promise<AxiosResponse> {
    return baseApi.post('integrations/refresh', {
      integration: integrationKey,
    })
  }

  getTwilioAccount(
    params: IParamsGetTwilioAccount
  ): Promise<AxiosResponse<IResponseGetTwilioAccount>> {
    return baseApi.get('addons/twilio', {
      params,
    })
  }
  getIntegrationOrganizationSetting(): Promise<
    AxiosResponse<IIntegrationOrganizationSettingsResponse>
  > {
    return baseApi.get('integration/organization-settings')
  }
  putIntegrationOrganizationSetting(
    data: IIntegrationOrganizationSetting
  ): Promise<AxiosResponse<IIntegrationOrganizationSettingsResponse>> {
    return baseApi.put('integration/organization-settings', data)
  }
  getIntegrationVersion(
    integrationKey: IIntegrationKey
  ): Promise<AxiosResponse<IGetIntegrationVersionResponse>> {
    return baseApi.get(`integrations/${integrationKey}/versions/active`)
  }
}

export const IntegrationsApi = new Api()
