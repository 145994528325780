import { makeAutoObservable, runInAction } from 'mobx'
import { RequestLoadingStatus } from 'shared/store/RequestLoadingStatus'
import { API_URL_MICROSERVICE } from 'shared/config'
import {
  type IIntegrationOrganizationSettingsResponse,
  IIntegrationOrganizationSettingType,
  Integration,
  IntegrationsApi,
  integrationsStore,
} from 'entities/Integrations'
import { subscriptionStore } from 'entities/Subscription'
import { SALESFORCE_CONNECTION_CONFIG } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/lib'

const DEFAULT_PACKAGE_TYPE = 'premium'

export class IntegrationSalesforceSettingsInstallationStore {
  private _status = new RequestLoadingStatus()
  private _packageType: IIntegrationOrganizationSettingType = DEFAULT_PACKAGE_TYPE
  private _connectionOptions = SALESFORCE_CONNECTION_CONFIG

  constructor(private _integration: Integration) {
    makeAutoObservable(this)
  }

  init = () => {
    this._getData()
  }

  private _getData = () =>
    this._status.blockingLoadData(async () => {
      const [settings, version] = await Promise.all([
        IntegrationsApi.getIntegrationOrganizationSetting(),
        IntegrationsApi.getIntegrationVersion(this._integration.key),
      ])

      runInAction(() => {
        this._connectionOptions = SALESFORCE_CONNECTION_CONFIG.map((config) => ({
          ...config,
          connectionLink: this._getConnectUrl(config.id === 'sandbox'),
          settingsLink: this._getSettingUrl(),
          ...(config.versionKey ? { downloadLink: String(version.data[config.versionKey]) } : {}),
        }))
      })

      this._setPackageTypeFromRequest(settings.data)
    })

  private _updateData = (value: IIntegrationOrganizationSettingType) =>
    this._status.loadData(async () => {
      const response = await IntegrationsApi.putIntegrationOrganizationSetting({
        key: 'salesforce_package_type',
        value,
      })

      if (response.status !== 200) {
        this._setPackageTypeFromRequest(response.data)
      }
    })

  private _getConnectUrl(isSandbox = false) {
    let url = `${API_URL_MICROSERVICE}core/integrations/${this._integration.key}/connect`
    if (this._integration.key === 'salesforce') {
      url = `${API_URL_MICROSERVICE}integration/connect/${this._integration.key}`
    }
    if (isSandbox) {
      url += '?is_sandbox=true'
    }
    return url
  }

  private _getSettingUrl() {
    let url = ''

    if (this._integration.connected) {
      url = `${
        new URL(this._integration.connected.url).origin
      }/lightning/n/Salesmsg_App__Salesmsg_Home`
    }

    return url
  }

  private _setPackageTypeFromRequest = (response: IIntegrationOrganizationSettingsResponse) => {
    const item = response.data[0]
    if (item && item.key === 'salesforce_package_type') {
      runInAction(() => {
        this._packageType = item.value
      })
    }
  }

  setPackageType = (value: IIntegrationOrganizationSettingType) => {
    this._packageType = value
    this._updateData(value)
  }

  get packageType() {
    return this._packageType
  }

  get hasConnectionLimit() {
    return (
      (subscriptionStore.isTrial || subscriptionStore.isPAYGPlan) &&
      !!integrationsStore.connectedIntegrations.length
    )
  }

  get currentConnection() {
    return (
      this._connectionOptions.find((item) => item.id === this.packageType) ||
      this._connectionOptions[0]
    )
  }

  get selectItems() {
    return this._connectionOptions.map((item) => ({
      id: item.id,
      label: item.label,
    }))
  }

  get isConnected() {
    return this._integration.isConnected
  }

  get isLimitedIntegration() {
    const packageType = this._packageType
    return packageType === 'sandbox'
  }
}
