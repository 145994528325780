import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { EmptyState, Table } from 'shared/ui'
import { ContactStatisticBulkActions } from 'widgets/ContactStatistic'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { type CampaignContactsStatisticsTableStore } from 'pages/campaigns/ui/detail/CampaignContactsStatisticsTable'
import styles from './styles.module.scss'
import { getColumns } from './getColumns'

type ICampaignContactsStatisticsProps = {
  store: CampaignContactsStatisticsTableStore
}

export const CampaignContactsStatisticsTable = observer(
  ({ store }: ICampaignContactsStatisticsProps) => {
    const {
      items,
      total,
      loading,
      onPaginationModelChange,
      tableStore,
      contactsTagsModalStore,
      scrollToTopTrigger,
      page,
      limit,
      onSuccessDelete,
      search,
      onOpenContactsDetails,
      isNoData,
      variant,
      isShowReply,
    } = store

    const columns = useMemo(
      () => getColumns(onOpenContactsDetails, variant, isShowReply),
      [onOpenContactsDetails, isShowReply]
    )

    if (isNoData) {
      return (
        <EmptyState
          text={'No contacts found'}
          noIcon
          boxProps={{ flexGrow: 1, height: undefined }}
        />
      )
    }

    if (!loading && !items.length) {
      return <NoResultsFound boxProps={{ flexGrow: 1, height: undefined }} />
    }

    return (
      <div className={styles.wrapTable}>
        <Table
          columns={columns}
          rows={items}
          rowsCount={total}
          isLoading={loading}
          onChangePagination={onPaginationModelChange}
          initPage={page}
          limit={limit}
          startPaginationSize={10}
          sxTable={{ marginRight: 2, width: 'calc(100% - 16px)' }}
          scrollToTOpTrigger={scrollToTopTrigger}
          withCheckbox
          store={tableStore}
          headerLeftContentProps={{
            actionsContent: (
              <ContactStatisticBulkActions
                contactsTagsModalStore={contactsTagsModalStore}
                selectedContactIds={tableStore.selectedRows.map((row) => row.contact.id)}
                search={search}
                onSuccessDelete={onSuccessDelete}
                withNewActions
              />
            ),
          }}
        />
      </div>
    )
  }
)
