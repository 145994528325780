import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { logger, numberFormat } from 'shared/lib'
import { AdminOrganizationsApi, IResponseAdminOrganizationById } from 'entities/Admin'
import { ManageCreditsContent } from 'pages/admin/pages/organizations'
import { ManageCreditsAction } from 'pages/admin/pages/organizations/modals/ManageCredits/ManageCreditsAction'

class ManageCreditsStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''

  organization: IResponseAdminOrganizationById | null = null
  planCredits = ''
  purchasedCredits = ''
  rollover = ''

  loadingSubmit = false

  onSuccess: (() => void) | null = null

  get disabledSubmit() {
    return !this.planCredits && !this.purchasedCredits && !this.rollover
  }

  get organizationTotalCredits() {
    return numberFormat({ value: this.organization?.credits.account_credits || 0 })
  }

  get organizationPlanCredits() {
    return numberFormat({ value: this.organization?.credits.plan_credits || 0 })
  }

  get organizationPurchasedCredits() {
    return numberFormat({ value: this.organization?.credits.purchased || 0 })
  }

  get organizationRollover() {
    return numberFormat({ value: this.organization?.credits.rollover_credits || 0 })
  }

  reset = () => {
    this.modalId = ''
    this.organization = null
    this.planCredits = ''
    this.purchasedCredits = ''
    this.rollover = ''
  }

  setPlanCredits = (credits: string) => {
    this.planCredits = credits
  }

  setPurchasedCredits = (credits: string) => {
    this.purchasedCredits = credits
  }

  setRollover = (rollover: string) => {
    this.rollover = rollover
  }

  onSubmit = async () => {
    try {
      if (!this.organization?.id) {
        return
      }
      runInAction(() => {
        this.loadingSubmit = true
      })
      await AdminOrganizationsApi.createCredits({
        id: this.organization?.id,
        data: {
          plan_credits: +this.planCredits || 0,
          purchased: +this.purchasedCredits || 0,
          rollover_credits: +this.rollover || 0,
        },
      })
      this.onSuccess && this.onSuccess()
      modalStore.closeModal(this.modalId)
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loadingSubmit = false
      })
    }
  }

  onOpenModal = (
    organization: IResponseAdminOrganizationById,
    onSuccess: typeof this.onSuccess
  ) => {
    this.reset()
    this.onSuccess = onSuccess
    this.organization = organization
    this.modalId = nanoid()
    modalStore.addModal({
      id: this.modalId,
      title: 'Manage credits',
      ModalContent: ManageCreditsContent,
      width: 480,
      ModalActions: ManageCreditsAction,
    })
  }
}

export const manageCreditsStore = new ManageCreditsStore()
