import { makeAutoObservable, reaction, type IReactionDisposer, runInAction } from 'mobx'
import { RequestLoadingStatus } from 'shared/store/RequestLoadingStatus'
import { showToast } from 'shared/ui'
import {
  type IIntegrationChannel,
  type IParamsPagination,
  type Integration,
  IntegrationsApi,
} from 'entities/Integrations'

const defaultPagination = {
  page: 1,
  limit: 10,
}

export class IntegrationHubspotSettingsChannelsStore {
  private _status = new RequestLoadingStatus()
  private _disposeChangePagination: Null<IReactionDisposer> = null
  private _disposeSearch: Null<IReactionDisposer> = null
  private _total = 0
  private _paginationData = defaultPagination
  private _search = ''
  private _channels = new Map<number, IIntegrationChannel>()

  constructor(private _integration: Integration) {
    makeAutoObservable(this)
  }

  init() {
    this.getData(this._paginationData)
    this._reactionChange()
  }

  private _reactionChange = () => {
    this._disposeChangePagination?.()
    this._disposeSearch?.()
    this._disposeChangePagination = reaction(
      () => this._paginationData,
      () => {
        this.getData({
          ...this._paginationData,
          search: this._search,
        })
      }
    )
    this._disposeSearch = reaction(
      () => this.search,
      () =>
        this.getData({
          page: 1,
          limit: this._paginationData.limit,
          search: this._search,
        }),
      {
        delay: 500,
      }
    )
  }

  dispose = () => {
    this._paginationData = defaultPagination
    this._total = 0
    this._disposeChangePagination?.()
    this._disposeSearch?.()
  }

  getData = (params: IParamsPagination) =>
    this._status.loadData(async () => {
      if (!this.search) {
        delete params.search
      }

      const { data } = await IntegrationsApi.getIntegrationChannels(this._integration.key, params)

      runInAction(() => {
        this._channels.clear()
        this._total = data.total
        data.data.forEach((chanel) => {
          this._channels.set(chanel.id, chanel)
        })
      })
    })

  refreshData = async () => {
    await this.getData({
      ...this._paginationData,
      search: this._search,
    })
    showToast({
      type: 'success',
      title: 'Custom channels refreshed',
    })
  }

  onChangePagination = (page: number, limit: number) => {
    this._paginationData = {
      page,
      limit,
    }
  }

  onSearchChange = (search: string) => {
    this._search = search
  }

  get search() {
    return this._search
  }

  get channels() {
    return Array.from(this._channels.values())
  }

  get status() {
    return this._status.status
  }

  get total() {
    return this._total
  }

  get pagination() {
    return this._paginationData
  }
}
