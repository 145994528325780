import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { MenuItem } from 'shared/ui'
import { integrationsStore } from 'entities/Integrations'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useContactsMenuContext } from 'pages/contacts/ui/ContactsMenu/context/contactsMenuContext'
import { SegmentMenuItem } from 'pages/contacts/ui/ContactsMenu/ui/SegmentMenuItem/SegmentMenuItem'
import styles from './styles.module.scss'

export const SegmentMenu = observer(() => {
  const contactsMenuStore = useContactsMenuContext()
  const {
    segmentsAll,
    segments,
    activeSegment,
    segmentsSearch,
    setSegmentsSearch,
    hubspotLists,
    hubspotListsSearch,
    setHubspotListsSearch,
    favorites,
    favoritesSearch,
    setFavoritesSearch,
  } = contactsMenuStore
  const [isAllContactsActive, setIsAllContactsActive] = useState<boolean>(false)

  useEffect(() => {
    integrationsStore.fetchIntegrations()

    return () => {
      contactsMenuStore.dispose()
    }
  }, [])

  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    const isActiveAllContacts =
      (pathname === '/contacts' || pathname.startsWith('/contacts?')) && !activeSegment

    setIsAllContactsActive(isActiveAllContacts)
  }, [location, activeSegment])

  return (
    <>
      <MenuItem
        key={'all_contacts'}
        type={'default'}
        label='All contacts'
        icon='userFilled'
        className={styles.allContacts}
        active={isAllContactsActive}
        onClick={() => {
          contactsMenuStore.navToAllContactsAsk()
        }}
      />
      <div className={styles.folders}>
        {featureFlagsStore.hubspot_pin_list ? (
          <>
            <SegmentMenuItem
              placeholder={'Search favorites'}
              title={'Favorites'}
              segments={favorites}
              search={favoritesSearch}
              setSearch={setFavoritesSearch}
            />

            <SegmentMenuItem
              placeholder={'Search segments'}
              title={'Segments'}
              segments={segments}
              search={segmentsSearch}
              setSearch={setSegmentsSearch}
            />

            <SegmentMenuItem
              placeholder={'Search hubspot lists'}
              title={'HubSpot lists'}
              segments={hubspotLists}
              search={hubspotListsSearch}
              setSearch={setHubspotListsSearch}
            />
          </>
        ) : (
          <SegmentMenuItem
            placeholder={'Search segments'}
            title={'Segments'}
            segments={segmentsAll}
            search={segmentsSearch}
            setSearch={setSegmentsSearch}
          />
        )}
      </div>
    </>
  )
})
