import { makeAutoObservable, runInAction } from 'mobx'
import type { Integration, ISalesForceSettingsDict } from 'entities/Integrations'
import {
  SALESFORCE_CONDITIONAL_PROPERTIES,
  SALESFORCE_PROPERTIES,
} from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/lib'
import type { SettingsPropertyItemType } from 'pages/settings/pages/integrations/pages/integrationSettings/model/types'
import type { IntegrationCustomSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCustomSettingsStore'
import { SettingsProperty } from 'pages/settings/pages/integrations/pages/integrationSettings/model/SettingsProperty'

export class IntegrationSalesforceSettingsPropertyStore {
  private _properties = new Map<
    number | string,
    SettingsProperty<string, ISalesForceSettingsDict>
  >()

  constructor(
    private _integration: Integration,
    private _customSetting: IntegrationCustomSettingsStore<ISalesForceSettingsDict>
  ) {
    makeAutoObservable(this)
  }

  async init() {
    const customSettingTimeout = setInterval(() => {
      if (this._customSetting.status.isSuccess) {
        clearInterval(customSettingTimeout)
        this._fieldsInitialization(SALESFORCE_PROPERTIES)

        if (this.numberEnabled) {
          this._fieldsInitialization(SALESFORCE_CONDITIONAL_PROPERTIES)
        }
      }
    }, 200)
  }

  private _fieldsInitialization = (
    fields: SettingsPropertyItemType<string, ISalesForceSettingsDict>[]
  ) => {
    fields.forEach((item) => {
      let property: SettingsPropertyItemType<string, ISalesForceSettingsDict> = item

      if (property.customSettingId) {
        property = {
          ...property,
          integrationName: this.integrationName,
          value: this._customSetting.getValue(property.customSettingId),
          options: this._customSetting.getOptions(property.customSettingId)?.map((item) => ({
            value: item.value,
            label: item.label,
            id: item.value,
          })),
        }
      }
      this._setProperty(property)
    })
  }

  private _patchData = async (
    newData: SettingsPropertyItemType<string, ISalesForceSettingsDict>
  ) => {
    const item = this._properties.get(newData.id)
    if (!item) return

    this._setProperty(newData)

    if (newData.customSettingId) {
      const response = await this._customSetting.patchData([
        {
          key: newData.customSettingId,
          value: String(newData.value),
        },
      ])

      if (!(response?.status === 200)) {
        this._setProperty(item)
      }
    }
  }

  private _setProperty = (data: SettingsPropertyItemType<string, ISalesForceSettingsDict>) => {
    runInAction(() => {
      this._properties.set(
        data.id,
        new SettingsProperty<string, ISalesForceSettingsDict>(data, {
          onChange: this._patchData,
        })
      )
    })
  }

  setNumberEnabled = (checked: boolean) => {
    if (checked) {
      this._fieldsInitialization(SALESFORCE_CONDITIONAL_PROPERTIES)
      this._customSetting.patchData([
        {
          key: 'is_custom_phone_number_enabled',
          value: 1,
        },
        {
          key: 'lead_custom_phone_number',
          value: this._customSetting.getValueOrFirstOption('lead_custom_phone_number'),
        },
        {
          key: 'contact_custom_phone_number',
          value: this._customSetting.getValueOrFirstOption('contact_custom_phone_number'),
        },
      ])
    } else {
      SALESFORCE_CONDITIONAL_PROPERTIES.forEach(({ id }) => {
        this._properties.delete(id)
      })
      this._customSetting.patchData([
        {
          key: 'is_custom_phone_number_enabled',
          value: 0,
        },
      ])
    }
  }

  get numberEnabled() {
    return Boolean(this._customSetting.getValue('is_custom_phone_number_enabled'))
  }

  get integrationName() {
    return this._integration?.name || ''
  }

  get rows() {
    return Array.from(this._properties.values())
  }

  get mappedLength() {
    return this._properties.size
  }

  get loading() {
    return this._customSetting.status.isLoading
  }
}
