import { observer } from 'mobx-react-lite'
import { CampaignStatusConfig } from 'entities/Campaign/ui/CampaignStatus'
import type { ICampaignStatus } from 'entities/Campaign'
import type { IWidgetProps } from 'widgets/FilterEditor/types'
import { WidgetStatus } from 'widgets/FilterEditor/Widget/WidgetStatus'

export const WidgetCampaignStatus = observer((props: IWidgetProps) => {
  return (
    <WidgetStatus
      getConfigByStatus={(status) => CampaignStatusConfig[status as ICampaignStatus]}
      {...props}
    />
  )
})
