import { makeAutoObservable, runInAction } from 'mobx'
import { uniq } from 'lodash'
import { errorHandler } from 'shared/api'
import { getContentType, getFileWithTruncatedName, logger } from 'shared/lib'
import { AttachmentsApi } from 'entities/Attachment/api/attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import type { ILimitSizeError, IUploadInfoItem, IUploadInfoKeys } from 'entities/Attachment'

import { getFileType } from '../lib/getFileType'
import { fileTypeToLabelConverter } from '../lib/fileTypeToLabelConverter'

class AttachmentStore {
  constructor() {
    makeAutoObservable(this)
  }
  previousAttachmentMap: Map<string, Attachment | null> = new Map()
  uploadInfoMap: Map<IUploadInfoKeys, IUploadInfoItem> = new Map()
  loadingUploadInfo = false

  checkPreviousAttachment(links: string[]) {
    const linksFroUpload: string[] = []
    uniq(links).forEach((link) => {
      if (!this.previousAttachmentMap.has(link)) {
        linksFroUpload.push(link)
        this.previousAttachmentMap.set(link, null)
      }
    })
    if (linksFroUpload.length) {
      AttachmentsApi.preview(linksFroUpload)
        .then(({ data }) => {
          runInAction(() => {
            if (Array.isArray(data)) {
              data?.forEach((attachment) => {
                if (attachment) {
                  this.previousAttachmentMap.set(
                    attachment.source_short,
                    new Attachment({
                      responseUpload: attachment,
                    })
                  )
                }
              })
            }
          })
        })
        .catch((e) => logger.error(e))
    }
  }

  setPreviousAttachments(attachments: Attachment[]) {
    attachments.forEach((attachment) => {
      if (attachment.source_short && !this.previousAttachmentMap.get(attachment.source_short)) {
        this.previousAttachmentMap.set(attachment.source_short, attachment)
      }
    })
  }

  loadUploadInfo = async () => {
    if (this.loadingUploadInfo) return
    try {
      this.loadingUploadInfo = true
      const { data } = await AttachmentsApi.getUploadInfo()
      Object.entries(data).forEach(([key, info]) => {
        this.uploadInfoMap.set(key as IUploadInfoKeys, info)
      })
    } catch (error) {
      logger.error(error)
    } finally {
      runInAction(() => {
        this.loadingUploadInfo = false
      })
    }
  }

  getSizeLimit = (key: IUploadInfoKeys): number => this.uploadInfoMap.get(key)?.size || 0

  addMediaFiles = async ({
    files,
    setLimitSizeError,
    addAttachment,
    setExtError,
  }: {
    files: File[]
    setLimitSizeError: (limitError: ILimitSizeError, file: File) => void
    addAttachment: (attachment: Attachment, update?: boolean) => void
    setExtError: (error: string) => void
  }) => {
    const uploadFiles: File[] = []
    const limitRejectedFiles: Record<string, { file: File; limitError: ILimitSizeError }> = {}

    files.forEach((file) => {
      const updatedFile = getFileWithTruncatedName(file)

      const type = getFileType(file) as IUploadInfoKeys
      const limit = this.getSizeLimit(type)
      const label = fileTypeToLabelConverter[type]

      if (limit && updatedFile.size > limit * 1024) {
        if (!limitRejectedFiles[type]) {
          limitRejectedFiles[type] = {
            file: updatedFile,
            limitError: { limit, label },
          }
        }
      } else {
        uploadFiles.push(updatedFile)
      }
    })

    Object.keys(limitRejectedFiles).forEach((type) => {
      setLimitSizeError(limitRejectedFiles[type].limitError, limitRejectedFiles[type].file)
    })

    if (uploadFiles.length) {
      const requestPromise = []
      const requestUpload = async (file: File) => {
        try {
          const type = await getContentType(file)
          const item = await AttachmentsApi.uploadLink(file, {
            name: file.name,
            content_type: type,
          })
          const responseAttachment = item.data.attachment

          responseAttachment.source = URL.createObjectURL(file)
          const attachment = new Attachment({
            responseMedia: responseAttachment,
          })
          attachment.setLoading(true)

          addAttachment(attachment)
        } catch (e) {
          const { type, error } = await errorHandler<{ [key: string]: string }>(e as Error)

          if (type === 'axios-error') {
            if (error.response?.data) {
              for (const key in error.response.data) {
                const res = error.response.data[key]

                if (Array.isArray(res)) {
                  res.forEach((error) => {
                    setExtError(String(error))
                  })
                } else {
                  setExtError(String(res))
                }
              }
            }
          }
        }
      }

      for (const file of uploadFiles) {
        requestPromise.push(requestUpload(file))
      }

      Promise.all(requestPromise)
    }
  }
}

export const attachmentStore = new AttachmentStore()
