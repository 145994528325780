import { makeAutoObservable, runInAction } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { ComplianceApi, type INumberType, type ITargetCountry } from 'entities/Compliance'
import { complianceStore } from 'entities/Compliance/store/complianceStore'
import { Summary } from 'entities/Compliance/model'
import { numbersStore } from 'entities/Phone'
import { type IQuestionnaireStepsSaveSummary } from 'entities/Compliance/store/questionnaireStepsStore/types'

export class QuestionnaireStepsStore {
  private _targetCountry: ITargetCountry | null = null
  private _isTaxId: boolean | null = null
  private _numberType: INumberType | null = null
  private _loadingUpdate = false

  constructor() {
    makeAutoObservable(this)
  }

  get disabledNumberType() {
    return !this._numberType || this._loadingUpdate
  }

  get disabledTargetCountry() {
    return this._targetCountry === null
  }

  get disabledIsTaxId() {
    return this._isTaxId === null
  }

  get targetCountry() {
    return this._targetCountry
  }

  get isTaxId() {
    return this._isTaxId
  }

  get numberType() {
    return this._numberType
  }

  setTargetCountry = (country: ITargetCountry | null) => {
    this._targetCountry = country
  }

  setIsTaxId = (isTaxId: boolean | null) => {
    this._isTaxId = isTaxId
  }

  setNumberType = (numberType: INumberType | null) => {
    this._numberType = numberType
  }

  handleSetTargetCountry = (country: ITargetCountry) => {
    this.setTargetCountry(country)

    if (!this.isTaxId) {
      this.setNumberType(null)
    }
  }

  handleSetIsTaxId = (isTaxId: boolean) => {
    this.setIsTaxId(isTaxId)

    if (!isTaxId) {
      this.setNumberType(null)
    }
  }

  saveSummary = async ({
    isRedirect = true,
    isOpenModalSelectNumber = true,
  }: IQuestionnaireStepsSaveSummary = {}) => {
    if (!this._targetCountry || this._isTaxId === null || !this._numberType) return

    try {
      runInAction(() => {
        this._loadingUpdate = true
      })

      const { data } = await ComplianceApi.saveComplianceSummary({
        target_country: this._targetCountry,
        have_tax_ein: this._isTaxId,
        numbers_type: this._numberType,
      })

      const summary = new Summary(data)

      complianceStore.setSummary(summary)

      if (isRedirect) {
        uiStore.changeRoute({
          path: 'settings/organization/compliance',
        })
      }

      if (isOpenModalSelectNumber) {
        numbersStore.initSelectNumberModal()
      }
    } catch (error) {
      logger.error(error)
    } finally {
      runInAction(() => {
        this._loadingUpdate = false
      })
    }
  }
}
