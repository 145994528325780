import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import dayjs from 'dayjs'
import { TokenResult } from '@stripe/stripe-js'
import { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import { showToast } from 'shared/ui'
import { REGISTER_DATA_COOKIES, EXPIRES_REGISTER_DATA_COOKIES_IN } from 'shared/constants/cookies'
import { authStore, IParamsRegister, IParamsRegisterAnswers } from 'entities/Auth'
import { AuthApi } from 'entities/Auth/api'
import { IRegisterCookies } from 'entities/Auth/store/types'
import { IResponseUser } from 'entities/Users/api/types'
import { BillingApi } from 'entities/Billing'
import { RegisterStepEnum } from 'widgets/Register/store/type'
import { StepSignUpStore } from 'widgets/Register/ui/StepSignUp/store/stepSignUpStore'
import { Step1Store } from 'widgets/Register/ui/Step1/store/step1Store'
import { Step2Store } from 'widgets/Register/ui/Step2/store/step2Store'
import { Step3Store } from 'widgets/Register/ui/Step3/store/step3Store'
import { Step4Store } from 'widgets/Register/ui/Step4/store/step4Store'

export class RegisterStore {
  private _step1Store: Step1Store = new Step1Store()
  private _step2Store: Step2Store = new Step2Store()
  private _step3Store: Step3Store = new Step3Store()
  private _step4Store: Step4Store = new Step4Store()
  private _stepSignUpStore: StepSignUpStore = new StepSignUpStore(this._step4Store)
  private _step: RegisterStepEnum = RegisterStepEnum.stepSignUp
  private _loading = false
  private _preloading = false

  private _multiOrganizationRegistration = false
  private _crmId = 0
  private _terms = true
  private _smsCode = ''
  private _city = ''
  private _region = ''
  private _noCardTest = false
  private _timezone = ''
  private _newBilling = true
  private _numberVendorKey = ''

  // Infusionsoft
  private _leadSource = ''
  private _gaSource = ''
  private _gaMedium = ''
  private _gaTerm = ''
  private _gaContent = ''
  private _gaCampaign = ''
  private _gaReferurl = ''
  private _gaIp = ''

  // UTM
  private _utmCampaign = ''
  private _utmContent = ''
  private _utmMedium = ''
  private _utmSource = ''

  // STRIPE
  private _token = ''
  private _plan = ''

  // Coupon
  private _disposeCoupon: IReactionDisposer | null = null
  private _couponTitle = ''

  constructor() {
    makeAutoObservable(this)
    this.init()
    this.initCookies()
    this.reactionCoupon()
  }

  get couponTitle() {
    return this._couponTitle
  }

  get preloading() {
    return this._preloading
  }

  get stepSignUpStore() {
    return this._stepSignUpStore
  }

  get step1Store() {
    return this._step1Store
  }

  get step2Store() {
    return this._step2Store
  }

  get step3Store() {
    return this._step3Store
  }

  get step4Store() {
    return this._step4Store
  }

  get step() {
    return this._step
  }

  get loading() {
    return this._loading
  }

  get steps() {
    return [
      RegisterStepEnum.stepSignUp,
      RegisterStepEnum.step1,
      RegisterStepEnum.step2,
      RegisterStepEnum.step3,
      RegisterStepEnum.step4,
    ]
  }

  get posthogSessionId() {
    return window.posthog?.get_session_id() || ''
  }

  get sessionId() {
    return this.posthogSessionId
  }

  get hasNextStep() {
    return this.step !== RegisterStepEnum.step4
  }

  get hasPrevStep() {
    return this.step !== RegisterStepEnum.stepSignUp
  }

  get progressPercent() {
    switch (this._step) {
      case RegisterStepEnum.stepSignUp:
        return 0
      case RegisterStepEnum.step1:
        return 20
      case RegisterStepEnum.step2:
        return 40
      case RegisterStepEnum.step3:
        return 60
      case RegisterStepEnum.step4:
        return 80
      default:
        return 0
    }
  }

  get coupon() {
    const currentUrl = window.location.href
    const queryString = currentUrl.split('?')[1]
    const searchParams = new URLSearchParams(queryString)

    return searchParams.get('c') || ''
  }

  get hasCoupon() {
    return !!this.coupon
  }

  get registerAnswerStep() {
    switch (this._step) {
      case RegisterStepEnum.stepSignUp:
        return '0'
      case RegisterStepEnum.step1:
        return '1'
      case RegisterStepEnum.step2:
        return '2'
      case RegisterStepEnum.step3:
        return '3'
      case RegisterStepEnum.step4:
        return '4'
      default:
        return '0'
    }
  }

  setCouponTitle = (value: string) => {
    this._couponTitle = value
  }

  initStartStep = () => {
    let startStep = RegisterStepEnum.stepSignUp

    if (!this._stepSignUpStore.isCompletedStep) return
    startStep = RegisterStepEnum.step1

    if (!this._step1Store.isCompletedStep) {
      this._step = startStep
      return
    }
    startStep = RegisterStepEnum.step2

    if (!this._step2Store.isCompletedStep) {
      this._step = startStep
      return
    }
    startStep = RegisterStepEnum.step3

    if (!this._step3Store.isCompletedStep) {
      this._step = startStep
      return
    }
    startStep = RegisterStepEnum.step4

    this._step = startStep
  }

  initCookies = async () => {
    const cookiesJSON = Cookies.get(REGISTER_DATA_COOKIES)

    if (cookiesJSON) {
      const cookies = JSON.parse(cookiesJSON) as IRegisterCookies
      if (!cookies) return
      this._preloading = true
      this._stepSignUpStore.initCookies(cookies)
      this._step1Store.initCookies(cookies)
      this._step2Store.initCookies(cookies)
      this._step4Store.initCookies(cookies)
      await this._step3Store.init()
      this._step3Store.initCookies(cookies)
      this._preloading = false

      this.initStartStep()
    }
  }

  setCookies = () => {
    const cookiesData: IRegisterCookies = {
      ...this.stepSignUpStore.payload,
      ...this._step1Store.payload,
      ...this._step2Store.payload,
      ...this._step3Store.payload,
      ...this._step4Store.payload,
    }

    Cookies.set(REGISTER_DATA_COOKIES, JSON.stringify(cookiesData), {
      expires: EXPIRES_REGISTER_DATA_COOKIES_IN,
    })
  }

  init = () => {
    this._newBilling = window.Config?.new_billing_registration === '1'
    this._timezone = dayjs.tz.guess()

    const utmJson = sessionStorage.getItem('utm')
    const utmData = utmJson ? JSON.parse(utmJson) : null
    this._utmCampaign = utmData?.utm_campaign || ''

    this._stepSignUpStore.setHandleNextStep(this.handleNextStep)
  }

  handleRegisterAnswers = async (data?: IResponseUser) => {
    try {
      const payload: IParamsRegisterAnswers = {
        step: this.registerAnswerStep,
        email: this.stepSignUpStore.email,
        session_id: this.sessionId,
        posthog_session_id: this.posthogSessionId,
        profile_id: data?.profile_id || 0,
        user_id: data?.id || 0,
        coupon_applied: this.hasCoupon ? 'yes' : 'no',
        ...this.step1Store.answers,
        ...this.step2Store.answers,
        ...this.step3Store.answers,
      }

      await AuthApi.logRegisterAnswers(payload)
    } catch (error) {
      console.error(error)
    }
  }

  handleNextStep = () => {
    if (!this.hasNextStep) {
      this.handleSignUp()
      return
    }

    this.handleRegisterAnswers()
    this.setCookies()
    const nextStepIdx = this.steps.indexOf(this.step) + 1
    const nextStep = this.steps[nextStepIdx]
    this._step = nextStep
  }

  handlePrevStep = () => {
    if (!this.hasPrevStep) return
    const prevStepIdx = this.steps.indexOf(this.step) - 1
    const prevStep = this.steps[prevStepIdx]

    if (prevStep === RegisterStepEnum.stepSignUp && this.stepSignUpStore.isGoogleAuth) {
      this.stepSignUpStore.clearGoogleAuth()
    }

    this._step = prevStep
  }

  get stripePayload() {
    return {
      token: this._token,
      plan: this._plan,
      coupon: this.coupon,
    }
  }

  get utmPayload() {
    return {
      utm_campaign: this._utmCampaign,
      utm_content: this._utmContent,
      utm_medium: this._utmMedium,
      utm_source: this._utmSource,
    }
  }

  get infusionsoftPayload() {
    return {
      lead_source: this._leadSource,
      ga_source: this._gaSource,
      ga_medium: this._gaMedium,
      ga_term: this._gaTerm,
      ga_content: this._gaContent,
      ga_campaign: this._gaCampaign,
      ga_referurl: this._gaReferurl,
      ga_ip: this._gaIp,
    }
  }

  get registerPayload(): IParamsRegister {
    return {
      // Initial data
      multiorganization_registration: this._multiOrganizationRegistration,
      crm_id: this._crmId,
      terms: this._terms,
      smsCode: this._smsCode,
      city: this._city,
      region: this._region,
      noCardTest: this._noCardTest,
      timezone: this._timezone,
      new_billing: this._newBilling,
      number_vendor_key: this._numberVendorKey,
      // Stripe
      ...this.stripePayload,
      // Infusionsoft
      ...this.infusionsoftPayload,
      // UTM
      ...this.utmPayload,
      // Step Sign Up
      ...this._stepSignUpStore.payload,
      // Step 1
      ...this.step1Store.payload,
      // Step 2
      ...this._step2Store.payload,
      // Step 3
      ...this.step3Store.payload,
      // Step 4
      ...this._step4Store.payload,
    }
  }

  private _onSubmitCard: (() => Promise<TokenResult | undefined>) | null = null

  setOnSubmit = (onSubmit: () => Promise<TokenResult | undefined>) => {
    this._onSubmitCard = onSubmit
  }

  onAddCard = async () => {
    if (this._step4Store.cardError) return
    if (this._onSubmitCard) {
      try {
        const res = await this._onSubmitCard()
        if (!res || res?.error) {
          runInAction(() => {
            this._step4Store.setCardError(
              res?.error.message || 'Please provide another credit card'
            )
          })
          return
        }

        this._token = res.token.id
        this._step4Store.setCardError(null)
      } catch (e) {
        console.error(e)
      }
    }
  }

  handleSignUp = async () => {
    try {
      this.setCookies()
      runInAction(() => {
        this._loading = true
      })

      const {
        data: { valid },
      } = await AuthApi.validateRegister(this.registerPayload)

      if (!valid) return
      await this.onAddCard()
      const {
        data: { user },
      } = await authStore.register(this.registerPayload)
      this.handleRegisterAnswers(user)
      Cookies.remove(REGISTER_DATA_COOKIES)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 500) this.step4Store.setShowRetryError(true)

        const data = error.response?.data
        if (!data) return

        const errorKeys = Object.keys(data)
        errorKeys.map((errorKey) => {
          const error = data[errorKey]?.[0] || data[errorKey]

          if (typeof error === 'string') {
            if (errorKey === 'number') {
              this.step4Store.setNumberRequestError(error)
            } else {
              showToast({
                type: 'error',
                title: error,
              })
            }
          }
        })
      }

      console.error(error)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  handleCoupon = async () => {
    try {
      const { data } = await BillingApi.getCoupon(this.coupon)
      if (data) {
        this.setCouponTitle(`Coupon applied! Enjoy ${data.percent_off}% off the first month.`)
      }
    } catch (e) {
      console.error(e)
    }
  }

  reactionCoupon = () => {
    this._disposeCoupon?.()
    this._disposeCoupon = reaction(
      () => this.hasCoupon,
      (hasCoupon) => {
        if (hasCoupon) this.handleCoupon()
      },
      {
        fireImmediately: true,
      }
    )
  }
}
