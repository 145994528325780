import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import urlRegex from 'url-regex'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { get_cookie, isEmail, logger, SingletonRequest } from 'shared/lib'
import { type IResponseUrlShortenerDomains } from 'entities/ShortLink'
import { ShortLinkApi } from 'entities/ShortLink/api/shortLink'
import type { IResponseCustomDomain, IResponseShortLink } from 'entities/ShortLink/api/types'
import { ShortLinkContent } from 'entities/ShortLink/ui/ShortLinkContent/ShortLinkContent'

export class ShortLinkStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''
  editLink: HTMLAnchorElement | null = null

  onAddLink: ((link: string) => void) | null = null
  onSuccess: (() => void) | null = null
  isShowCustomShortUrlBanner = false
  messageText = ''
  currentDomain = ''
  allDomain: IResponseUrlShortenerDomains | null = null
  shortenDomains: string[] = []
  isMarketing = false
  private _disposeMessageText: IReactionDisposer | null = null
  private _disposeIsMarketing: IReactionDisposer | null = null

  onOpenModal = ({
    onAddLink,
    editLink,
    onSuccess,
  }: {
    onAddLink: ((link: string) => void) | null
    editLink?: HTMLAnchorElement
    onSuccess?: () => void
  }) => {
    this.onAddLink = onAddLink
    this.onSuccess = onSuccess || null
    this.editLink = editLink || null
    this.modalId = nanoid()

    this.initAllDomains()

    modalStore.addModal({
      id: this.modalId,
      title: editLink ? 'Edit URL' : 'Shorten URL',
      pureContent: true,
      ModalContentProps: {
        store: this,
      },
      ModalContent: ShortLinkContent,
      width: 502,
    })
  }

  get manualDomains() {
    return this.allDomain?.manualDomains ?? []
  }

  get marketingDomains() {
    return this.allDomain?.marketingDomains ?? []
  }

  get currentDomains() {
    return this.isMarketing ? this.marketingDomains : this.manualDomains
  }

  reset = () => {
    this.isMarketing = false
    this.messageText = ''
    this.currentDomain = ''
  }

  init = (status: boolean) => {
    this.setIsMarketing(status)
    this.handleReactionMessageText()
  }

  handleReactionMessageText = () => {
    this._disposeMessageText?.()
    this._disposeIsMarketing?.()

    this._disposeMessageText = reaction(
      () => this.messageText,
      () => {
        this.initCurrentDomain()
      }
    )

    this._disposeIsMarketing = reaction(
      () => this.isMarketing,
      () => {
        this.currentDomain = ''
        this.initCurrentDomain()
      }
    )
  }

  setIsMarketing = (status: boolean) => {
    this.isMarketing = status
  }

  setMessageText = (value: string) => {
    this.messageText = value
  }

  canShortUrl(shortenUrl: string) {
    if (!this.allDomain) return false

    try {
      const url = new URL(shortenUrl)

      return !this.shortenDomains.includes(url.hostname)
    } catch (_) {
      return false
    }
  }

  private _initAllDomains = async () => {
    if (this.allDomain) return
    try {
      const { data } = await ShortLinkApi.getUrlShortenerDomains()

      this.initDomains(data)
    } catch (e) {
      logger.error(e)
    }
  }

  initAllDomains = new SingletonRequest(this._initAllDomains).request

  initDomains = (data: IResponseUrlShortenerDomains) => {
    this.shortenDomains = [...new Set([...data.oldDomains, ...data.supportedDomains])]
    this.allDomain = data
  }

  get urls() {
    try {
      let urls = this.messageText.match(urlRegex({ strict: false }))?.map((item) => item) || []
      const messageArr = this.messageText.trim().replace(/\s+/g, ' ').split(' ')

      urls = urls.filter((item) => !isEmail(item))
      urls = messageArr.filter((n) => urls.indexOf(n) !== -1)
      urls = urls.map((url) => new URL(url).hostname)

      return urls
    } catch (e) {
      logger.error(e)
      return []
    }
  }

  async initCurrentDomain() {
    if (this.currentDomain) return

    const currentDomain = this.currentDomains.find((domain) =>
      this.urls.find((url) => url === domain)
    )

    if (currentDomain) {
      this.currentDomain = currentDomain
    }
  }

  createShortLink = async (link: string): Promise<IResponseShortLink | undefined> => {
    try {
      const { data } = await ShortLinkApi.createShort({
        urls: [link],
        ...(this.currentDomain ? { short_domain: this.currentDomain } : null),
        isMarketing: Boolean(this.isMarketing),
      })
      const shortLink: IResponseShortLink | undefined = data.find(
        (item) => item.originalURL === link
      )
      if (shortLink) {
        const shortUrlBanner = get_cookie('not_show_short_url_banner')
        if (shortUrlBanner) {
          this.isShowCustomShortUrlBanner = false
        } else {
          const response = await this.getCustomDomain()
          if (response?.domain) {
            this.isShowCustomShortUrlBanner = false
          } else {
            this.isShowCustomShortUrlBanner = true
            document.cookie = 'not_show_short_url_banner=true; path=/;'
          }
        }
        return shortLink
      }
    } catch (e) {
      logger.error(e)
    }
  }

  getCustomDomain = async (): Promise<IResponseCustomDomain | undefined> => {
    try {
      const { data } = await ShortLinkApi.getCustomDomain()
      return data
    } catch (e) {
      logger.error(e)
    }
  }

  handleAddShortLink = (link: IResponseShortLink) => {
    this.onAddLink && this.onAddLink(link.shortURL)
    modalStore.closeModal(this.modalId)
  }

  handleShortenLink = (link: IResponseShortLink) => {
    if (this.editLink) {
      this.editLink.href = link.shortURL
      this.editLink.innerText = link.shortURL
      this.onSuccess && this.onSuccess()
    }
    modalStore.closeModal(this.modalId)
  }

  handleSaveLink = (link: string) => {
    if (this.editLink) {
      this.editLink.href = link
      this.editLink.innerText = link
      this.onSuccess && this.onSuccess()
    }
    modalStore.closeModal(this.modalId)
  }

  handleCloseCustomShortUrlBanner = () => {
    this.isShowCustomShortUrlBanner = false
  }
}

export const shortLinkStore = new ShortLinkStore()
