import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import classNames from 'classnames'
import fullLogoImg from 'shared/assets/images/fullLogo.png'
import { Alert, Button, Typography } from 'shared/ui'
import { getAriaLabel, getCDNAssetURL } from 'shared/lib'

import { links } from 'shared/constants/links'
import styles from './styles.module.scss'

type IStepFormLayout = {
  form: ReactNode
  title: string
  centered: boolean
  subtitle?: ReactNode | string
  onBack?: () => void
  coupon?: string
}

export const StepFormLayout = observer(
  ({ form, title, subtitle, onBack, centered, coupon }: IStepFormLayout) => {
    return (
      <div className={styles.wrap}>
        <div
          className={classNames(styles.content, {
            [styles.centered]: centered,
          })}
        >
          <div className={styles.logoWrap}>
            <a href={links.website} target='_blank' rel='noreferrer'>
              <img className={styles.logo} alt='logo' src={getCDNAssetURL(fullLogoImg)} />
            </a>
          </div>
          {onBack && (
            <Button
              className={styles.backButton}
              icon={'chevronLeft'}
              typeBtn={'stroke'}
              text={'Back'}
              onClick={onBack}
            />
          )}
          {coupon && (
            <Alert
              className={classNames(styles.coupon, {
                [styles.withBackButton]: !!onBack,
              })}
              item={{
                type: 'success',
                desc: coupon,
              }}
            />
          )}
          <Typography
            className={classNames(styles.title, {
              [styles.withBackButton]: !!onBack,
              [styles.withCoupon]: !!coupon,
            })}
            ariaLabel={getAriaLabel('signUp-step-subtitle')}
            variant={'body-lg-regular'}
            color={'var(--content-primary-primary)'}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              className={styles.subtitle}
              ariaLabel={getAriaLabel('signUp-step-subtitle')}
              variant={'body-lg-regular'}
              color={'var(--content-primary-tertiary)'}
            >
              {subtitle}
            </Typography>
          )}
          <div className={styles.form}>{form}</div>
        </div>
      </div>
    )
  }
)

export default StepFormLayout
