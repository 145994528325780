import type { Blocker } from 'react-router'
import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { type CampaignViewStore } from 'pages/campaigns/ui/CampaignView/store/CampaignViewStore'

export class CampaignBlockerStore {
  _cacheParams
  constructor(private campaignViewStore: CampaignViewStore) {
    this._cacheParams = JSON.stringify(campaignViewStore.params)
    makeAutoObservable(this)
  }

  get isExistChanges() {
    return this._cacheParams !== JSON.stringify(this.campaignViewStore.params)
  }
  _modalId = nanoid()

  updateCash = () => {
    this._cacheParams = JSON.stringify(this.campaignViewStore.params)
  }

  handleShowBlockerModal = (blocker: Blocker) => {
    if (blocker.state !== 'blocked') return

    const onNext = () => {
      const path = blocker.location.pathname
      blocker.proceed()

      uiStore.setRoutesBlocker(null)
      uiStore.changeRoute({
        path,
        type: 'vue',
        method: 'replace',
      })
      modalStore.closeModal(this._modalId)
    }

    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.INFO,
      title: 'Save campaign before closing?',
      primaryAction: {
        text: 'Save as draft',
        onAction: async () => {
          try {
            await this.campaignViewStore.onSaveDraft()
            onNext()
          } catch (e) {
            logger.error(e)
            modalStore.closeModal(this._modalId)
          }
        },
      },
      additionalSecondaryAction: {
        text: 'Don’t save',
        onAction: onNext,
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(this._modalId)
        },
      },
    })
  }
}
