import { makeAutoObservable, reaction, runInAction, type IReactionDisposer } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { toastStore } from 'shared/ui'
import { logger } from 'shared/lib'
import { ContactsApi } from 'entities/Contacts'
import type { IContactDuplicate, IContactDuplicatePair } from 'entities/Contacts/api/types'
import { ContactsMergeModalContent } from 'widgets/ContactsMergeModal/ui/ContactsMergeModalContent'
import { ContactsMergeModalActions } from 'widgets/ContactsMergeModal/ui/ContactsMergeModalActions'

type IContactsMergeModalStoreConfig = {
  onMergeAction: () => void
}

export class ContactsMergeModalStore {
  private _idModal: string | null = null
  private _itemsMap: Map<number, IContactDuplicate> = new Map()
  private _itemsPairMap: Map<number, IContactDuplicatePair> = new Map()
  private _selectedItemId: number | null = null
  private _activeItemId = 0
  private _offset = 0
  private _updated = false
  private _refreshCallback: null | (() => void) = null
  private _firstLoading = true
  private _loadingDuplicate = false
  private _loadingDuplicateClick = false
  private _loadingMerge = false
  private _loadingMergeClick = false
  private _disposeClose: IReactionDisposer | null = null
  private _disposeItemUpdate: IReactionDisposer | null = null
  private _config: IContactsMergeModalStoreConfig | null = null
  private _total = 0

  constructor() {
    makeAutoObservable(this)

    this.reactionItemUpdate()
    this.reactionClose()
  }

  setTotal = (value: number) => {
    this._total = value
  }

  get total() {
    return this._total
  }

  get config() {
    return this._config
  }

  get loadingMerge() {
    return this._loadingMerge
  }

  get loadingDuplicate() {
    return this._loadingDuplicate
  }

  get selectedItemId() {
    return this._selectedItemId
  }

  get firstLoading() {
    return this._firstLoading
  }

  get loadingMergeClick() {
    return this._loadingMergeClick
  }

  get loadingDuplicateClick() {
    return this._loadingDuplicateClick
  }

  get isLoading() {
    return this._loadingDuplicate || this._loadingMerge || this._firstLoading
  }

  get itemsList() {
    return Array.from(this._itemsMap.values())
  }

  get itemsPairList() {
    return Array.from(this._itemsPairMap.values())
  }

  get itemsCount() {
    return this.itemsList.length
  }

  get activeItem() {
    return this._itemsMap.get(this._activeItemId)
  }

  get lastItemId() {
    return this.itemsList[this.itemsCount - 1]?.id
  }

  get isLastItem() {
    return this.lastItemId === this._activeItemId
  }

  get conflictsCount() {
    const firstItem = this.itemsPairList?.[0]
    const secondItem = this.itemsPairList?.[1]

    if (!firstItem || !secondItem) return 0

    let conflictCounter = 0

    for (const [key, value] of Object.entries(firstItem)) {
      if (secondItem[key as keyof IContactDuplicatePair] !== value) conflictCounter++
    }

    return conflictCounter
  }

  get activeItemIdx() {
    return this.itemsList.findIndex((item) => item.id === this._activeItemId)
  }

  get nextItemId() {
    if (this.isLastItem) {
      return this.itemsList[0].id
    }

    return this.itemsList[this.activeItemIdx + 1].id
  }

  get internalCount() {
    if (!this.activeItem) return 0

    return this.activeItem.count - 2
  }

  get hasNextInternalItem() {
    return this.internalCount - this._offset > 0
  }

  get requestParams() {
    if (!this.activeItem) return null

    return {
      offset: this._offset,
      number: this.activeItem.formatted_number,
    }
  }

  get mergeParams() {
    const secondaryContactId = this.itemsPairList.find(
      (item) => item.id !== this._selectedItemId
    )?.id

    if (!this._selectedItemId || !secondaryContactId) return null

    return {
      main_contact_id: this._selectedItemId,
      secondary_contact_id: secondaryContactId,
    }
  }

  setConfig = (config: IContactsMergeModalStoreConfig) => {
    this._config = config
  }

  init = (items: IContactDuplicate[], id: number, refreshCallback?: () => void) => {
    this.addItems(items)
    this._activeItemId = id
    this._offset = 0
    if (refreshCallback) {
      this._refreshCallback = refreshCallback
    }

    this.openModal()
  }

  addItems = (items: IContactDuplicate[]) => {
    items.forEach((item) => {
      this._itemsMap.set(item.id, item)
    })
  }

  addItemsPair = (items: IContactDuplicatePair[]) => {
    items.forEach((item) => {
      this._itemsPairMap.set(item.id, item)
    })
  }

  openModal = () => {
    this._idModal = nanoid()

    modalStore.addModal({
      id: this._idModal,
      showCloseButton: false,
      showCloseIcon: true,
      showHeader: true,
      title: 'Duplicates',
      width: 600,
      ModalContentProps: {
        store: this,
      },
      ModalContent: ContactsMergeModalContent,
      ModalActions: ContactsMergeModalActions,
    })
  }

  closeModal = () => {
    if (!this._idModal) return
    if (this._refreshCallback && this._updated) this._refreshCallback()
    modalStore.removeModal(this._idModal)
    this.reset()
  }

  reset = () => {
    this._disposeClose?.()
    this._disposeItemUpdate?.()
    this._idModal = null
    this._itemsMap.clear()
    this._itemsPairMap.clear()
    this._activeItemId = 0
    this._offset = 0
    this._updated = false
  }

  changeFirstLoading = (value: boolean) => {
    this._firstLoading = value
  }

  changeLoadingDuplicateClick = (value: boolean) => {
    this._loadingDuplicateClick = value
  }

  changeMergeLoadingClick = (value: boolean) => {
    this._loadingMergeClick = value
  }

  nextItem = () => {
    if (this.hasNextInternalItem) {
      this._offset += 1
      return
    }
    this._offset = 0

    this._activeItemId = this.nextItemId
  }

  selectItem = (id: number) => {
    this._selectedItemId = id
  }

  load = async () => {
    if (!this.requestParams) return
    try {
      this._loadingDuplicate = true
      const { data } = await ContactsApi.getContactsDuplicatePair(this.requestParams)

      this._itemsPairMap.clear()
      this._selectedItemId = data[0].id
      this.addItemsPair(data)
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loadingDuplicate = false
        this._loadingDuplicateClick = false

        if (this._firstLoading) {
          this.changeFirstLoading(false)
        }
      })
    }
  }

  merge = async (successMergeHandler: (id: number) => void, duplicatesLength: number) => {
    if (!this.mergeParams || !this.activeItem) return

    try {
      this._loadingMerge = true
      await ContactsApi.updateContactsDuplicatePair(this.mergeParams)

      toastStore.add({
        title: 'Contacts merged',
        type: 'success',
      })

      successMergeHandler(this._activeItemId)
      if (duplicatesLength === 1) {
        this.closeModal()
      }

      this._updated = true
      if (this.activeItem.count === 2) {
        const mergedItemId = this.activeItem.id
        this.nextItem()
        this._itemsMap.delete(mergedItemId)
      } else {
        this._itemsMap.set(this.activeItem.id, {
          ...this.activeItem,
          count: this.activeItem.count - 1,
        })
        this.load()
      }
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loadingMerge = false
        this._loadingMergeClick = false
      })
    }
  }

  reactionClose = () => {
    this._disposeClose?.()
    this._disposeClose = reaction(
      () => this.itemsCount,
      () => {
        if (this.itemsCount === 0) {
          this.closeModal()
        }
      }
    )
  }

  reactionItemUpdate = () => {
    this._disposeItemUpdate?.()
    this._disposeItemUpdate = reaction(
      () => this._offset || this._activeItemId,
      () => {
        if (this.itemsCount !== 0) {
          this.load()
        }
      }
    )
  }
}
