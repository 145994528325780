import { useEventPowerDialerCreated } from 'entities/PowerDialer/events/EventPowerDialerCreated'
import { useEventPowerDialerUpdated } from 'entities/PowerDialer/events/EventPowerDialerUpdated'
import { PowerDialerListStore } from 'pages/powerDialer/pages/list/store/PowerDialerListStore'

export const usePowerDialerListEvent = (store: PowerDialerListStore) => {
  const { resetPageAndFilters, updateListItem } = store

  useEventPowerDialerCreated(() => {
    resetPageAndFilters()
  })

  useEventPowerDialerUpdated((powerDialerResponse) => {
    updateListItem(powerDialerResponse)
  })
}
