import { makeAutoObservable, runInAction } from 'mobx'
import { type PageLayoutStore } from 'shared/layout'
import { logger } from 'shared/lib'
import { KnowledgeBaseStore } from './knowledgeBaseStore'
import { KnowledgeBaseDocumentsStore } from './knowledgeBaseDocumentsStore'
import { KnowledgeBaseNotificationStore } from './knowledgeBaseNotificationStore'

export class KnowledgeBaseViewStore {
  private _isInitializing = false
  private readonly _knowledgeBaseStore: KnowledgeBaseStore
  private readonly _documentsStore: KnowledgeBaseDocumentsStore
  private readonly _notificationStore: KnowledgeBaseNotificationStore
  private _pageLayoutStore: PageLayoutStore | null = null

  constructor() {
    this._isInitializing = true
    this._knowledgeBaseStore = new KnowledgeBaseStore()
    this._notificationStore = new KnowledgeBaseNotificationStore()
    this._documentsStore = new KnowledgeBaseDocumentsStore(
      this._knowledgeBaseStore,
      this._notificationStore
    )

    makeAutoObservable(this)
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get isTypeCollapse() {
    return Boolean(this._pageLayoutStore)
  }

  setPageLayoutStore = (pageLayoutStore: PageLayoutStore) => {
    this._pageLayoutStore = pageLayoutStore
  }

  loadKnowledgeBaseViewInitialData = async () => {
    runInAction(() => {
      this._isInitializing = true
    })
    try {
      await Promise.all([
        this._knowledgeBaseStore.loadKnowledgeBase(),
        this._documentsStore.loadDocuments(),
      ])
    } catch (error) {
      logger.error(error)
    } finally {
      runInAction(() => {
        this._isInitializing = false
      })
    }
  }

  get isBaseDataLoading() {
    return this._isInitializing || this._knowledgeBaseStore.isKnowledgeBaseLoading
  }

  get isPageSpinnerShown() {
    return this.isBaseDataLoading || this._documentsStore.isDocumentsUploading
  }

  get isUploadDisabled() {
    return (
      this._documentsStore.isDocumentsLoading || this._knowledgeBaseStore.isKnowledgeBaseLoading
    )
  }

  get knowledgeBaseStore() {
    return this._knowledgeBaseStore
  }

  get notificationStore() {
    return this._notificationStore
  }

  get documentsStore() {
    return this._documentsStore
  }

  dispose = () => {
    this._knowledgeBaseStore.dispose()
    this._documentsStore.dispose()
    this._notificationStore.dispose()
  }
}
