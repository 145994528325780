import type { IStatusProps } from 'shared/ui/Status'
import { ICampaignStatus } from 'entities/Campaign'

const statuses: Record<ICampaignStatus, IStatusProps> = {
  active: {
    title: 'Active',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  },
  draft: {
    title: 'Draft',
    icon: 'draft',
    emphasis: 'high',
    intent: 'neutral',
  },
  disabled: {
    title: 'Disabled',
    icon: 'minusCircle',
    emphasis: 'high',
    intent: 'neutral',
  },
}

export default statuses
