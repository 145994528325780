import React from 'react'
import { observer } from 'mobx-react-lite'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { Icon, IDropdownItem, type IIconsVector } from 'shared/ui'
import { IntegrationSyncType } from 'pages/settings/pages/integrations/pages/integrationSettings/lib'
import styles from './styles.module.scss'

type IIntegrationSyncDropdownProps = {
  syncType: IntegrationSyncType
  setSyncType: (v: IntegrationSyncType) => void
  isTwoWaySync: boolean
  selectItems: IDropdownItem[]
  integrationIcon: IIconsVector
  iconSize?: number
}

export const IntegrationSyncDropdown: React.FC<IIntegrationSyncDropdownProps> = observer(
  ({ isTwoWaySync, setSyncType, syncType, selectItems, integrationIcon, iconSize = 98 }) => {
    return (
      <div className={styles.root}>
        <div className={styles.integrationIcon}>
          <Icon fontSize={iconSize} icon={integrationIcon} tertiary />
        </div>
        <div className={styles.typeIcon}>
          <Icon
            fontSize={16}
            icon={isTwoWaySync ? 'arrowHorizontalOutline' : 'arrowRight'}
            tertiary
          />
        </div>
        <div className={styles.integrationIcon}>
          <Icon fontSize={114} icon={'integrationSalessms'} tertiary />
        </div>
        <SelectField
          className={styles.select}
          withSearch={false}
          value={syncType}
          setValue={setSyncType}
          items={selectItems}
          size={'medium'}
        />
      </div>
    )
  }
)
