import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import {
  type ActionItem,
  ContainerHeader,
  Icon,
  type PropsSectionHeader,
  Tooltip,
  Typography,
} from 'shared/ui'
import { KnowledgeBaseActions } from 'pages/settings/pages/knowledgeBase/pages/knowledgeBaseView/ui/KnowledgeBaseActions/KnowledgeBaseActions'
import { useKnowledgeBaseViewContext } from 'pages/settings/pages/knowledgeBase/context/knowledgeBaseViewContext'
import styles from './styles.module.scss'

export const KnowledgeBaseHeader = observer(() => {
  const {
    isBaseDataLoading,
    documentsStore: { isEmpty },
    pageLayoutStore,
  } = useKnowledgeBaseViewContext()

  const sectionHeaderProps: PropsSectionHeader = {
    ...(pageLayoutStore
      ? {
          titleIconProps: pageLayoutStore.titleIconProps,
          titleIconAction: pageLayoutStore.handleToggleCollapse,
          paddingLeft: 4,
          paddingRight: 4,
          actionsGap: 8,
        }
      : {
          paddingTop: 64,
          paddingLeft: 4,
          paddingRight: 4,
          paddingBottom: 40,
        }),
  }

  const actions: ActionItem[] = useMemo(() => {
    if (isEmpty) {
      return []
    }
    return [
      {
        iconButtonComponent: <KnowledgeBaseActions />,
      },
    ]
  }, [isEmpty])

  return (
    <ContainerHeader
      customTitle={
        <div className={styles.header}>
          <Typography variant='heading-lg' ariaLabel='KnowledgeBase_title'>
            AI knowledge base
          </Typography>
          <Typography
            className={styles.subtitle}
            variant='body-md-regular'
            ariaLabel='KnowledgeBase_subtitle'
            color='tertiary'
          >
            Upload documents up to 10 MB
            <Tooltip
              label={
                <div className={styles.tooltipContent}>
                  <Typography
                    variant='body-md-regular'
                    ariaLabel='RagUploadSizeLimit_header'
                    color='var(--content-primary-primary-inverted)'
                  >
                    Supported file formats (Max 10 MB)
                  </Typography>
                  <Typography
                    variant='body-sm-regular'
                    ariaLabel='RagUploadSizeLimit_content'
                    color='var(--content-primary-tertiary-inverted)'
                  >
                    PDF, MD, TXT
                    <br />
                    DOC, DOCX, HTML
                    <br />
                    CSV, XLS, XLSX
                  </Typography>
                </div>
              }
              placement='top'
            >
              <Icon icon='question' tertiary />
            </Tooltip>
          </Typography>
        </div>
      }
      actions={isBaseDataLoading ? [] : actions}
      {...sectionHeaderProps}
    />
  )
})
