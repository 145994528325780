import { IIconsVector } from 'shared/ui'

export enum CrmEnum {
  hubSpot = 'hubSpot',
  activeCampaign = 'activeCampaign',
  keap = 'keap',
  salesforce = 'salesforce',
  pipedrive = 'pipedrive',
  none = 'none',
  other = 'other',
}

export enum ContactsCountsEnum {
  count100_500 = '100 - 500',
  count501_1000 = '501 - 1,000',
  count1001_2500 = '1,001 - 2,500',
  count2501_10000 = '2,501 - 10,000',
  count10001_50000 = '10,001 - 50,000',
  count50001_100000 = '50,001 - 100,000',
  count100001_250000 = '100,001 - 250,000',
  count250001_500000 = '250,001 - 500,000',
  count500001_1M = '500,001 - 1M',
  countMore1M = '1M+',
}

export type ICrm = {
  id: CrmEnum
  name: string
  value: string
  icon: IIconsVector
}

export enum FieldsEnum {
  crm = 'crm',
}
