import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useContactsPageLayoutContext } from 'pages/contacts/context/contactsPageLayoutContext'
import { HistoryPageStore } from 'pages/contacts/pages/history/store/historyPageStore'
import { ProviderHistoryPage } from 'pages/contacts/pages/history/context/historyPageContext'

const HistoryLayoutContent = observer(() => {
  return <Outlet />
})

export const HistoryLayout = observer(() => {
  const { pageLayoutStore } = useContactsPageLayoutContext()
  const [store] = useState(() => new HistoryPageStore(pageLayoutStore))

  return (
    <ProviderHistoryPage value={store}>
      <HistoryLayoutContent />
    </ProviderHistoryPage>
  )
})
