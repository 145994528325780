import { ITabItem } from 'shared/ui'
import { Attachment } from 'entities/Attachment/model/Attachment'
import type { MessageFieldStore } from 'widgets/MessageField'

export type IMediaLibraryViewType = 'grid' | 'list'

export type IMediaLibraryType = 'all' | 'images' | 'videos' | 'audio' | 'files'

export const mediaLibraryTabs: ITabItem<IMediaLibraryType>[] = [
  {
    name: 'All',
    key: 'all',
  },
  {
    name: 'Images',
    key: 'images',
  },
  {
    name: 'Videos',
    key: 'videos',
  },
  {
    name: 'Audio',
    key: 'audio',
  },
  {
    name: 'Files',
    key: 'files',
  },
]

export type IOnAddAttachment = (attachment: Attachment, update?: boolean) => void

export interface IMediaLibraryStoreProps {
  mediaLibraryVariant: MediaLibraryVariant
  onAddAttachment: IOnAddAttachment
  messageFieldStore?: MessageFieldStore
  selectMode?: MediaLibrarySelectMode
}

export enum MediaLibrarySelectMode {
  Single = 'single',
  Multiple = 'multiple',
}

export enum MediaLibraryVariant {
  MessageField = 'messageField',
  Default = 'Default',
  RinglessSelect = 'ringlessSelect',
}
