import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown, Icon, Box } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { FeatureRestrictedModalStore } from 'widgets/FeatureRestrictedModal'
import { ContactsTableStore } from 'pages/contacts/ui/ContactsTable/store/ContactsTableStore'
import { ImportContactsStore } from 'pages/contacts/ui/ImportContacts/store/ImportContactsStore'
import { ContactsPath } from 'pages/contacts/route/contactsPath'
import styles from './AddFirstContactContent.module.scss'

type IAddFirstContactContentProps = {
  contactsTableStore: ContactsTableStore
  importContactsStore: ImportContactsStore
}

export const AddFirstContactContent: FC<IAddFirstContactContentProps> = observer(
  ({ contactsTableStore, importContactsStore }) => {
    const { addNewContactManually } = contactsTableStore
    const { importAllowed, openModal, contactsImportModuleStore } = importContactsStore
    const { handleChangeCollapse } = contactsDetailsGlobalStore
    const { totalHistory } = contactsImportModuleStore

    const addNewContact = () => {
      addNewContactManually()
      handleChangeCollapse(false)
    }

    const onClickImport = () => {
      userPermissionsStore.getItem('can_create_contacts')
        ? openModal()
        : new FeatureRestrictedModalStore()
    }

    const onAddNewContact = () => {
      userPermissionsStore.getItem('can_create_contacts')
        ? addNewContact()
        : new FeatureRestrictedModalStore()
    }

    const showImportButton = !importAllowed && totalHistory === 1

    return (
      <Box
        display='flex'
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
        height='100%'
        width='100%'
      >
        <Icon color='var(--content-primary-tertiary)' fontSize={24} icon='contacts' />

        <h4 className={styles.headerText}>Add your first contacts</h4>
        <p className={styles.additionText}>Import or add manually</p>

        <Box display='flex' gap={1}>
          <Button
            text='Import'
            typeBtn='contained'
            contained='secondary'
            icon='upload'
            onClick={onClickImport}
          />
          <Button
            text='Create'
            typeBtn='contained'
            contained='secondary'
            icon='add'
            onClick={onAddNewContact}
          />
          {showImportButton && (
            <Dropdown
              ariaLabel={'Import history'}
              icon='moreHorizontal'
              sizeTriggerButton='medium'
              placement='bottom-start'
              items={[
                {
                  id: 0,
                  label: 'Import history',
                  iconL: 'clockBackward',
                  onClick: () => uiStore.changeRoute({ path: ContactsPath.history }),
                },
              ]}
            />
          )}
        </Box>
      </Box>
    )
  }
)
