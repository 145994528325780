import { makeAutoObservable, reaction, runInAction, type IReactionDisposer } from 'mobx'
import axios, { CancelTokenSource } from 'axios'
import { nanoid } from 'nanoid'
import { type ICardFilter } from 'shared/ui'
import { TableStore } from 'shared/ui/Table'
import { logger, removeSpacesFromNumericString } from 'shared/lib'
import { PageLayoutStore } from 'shared/layout'
import type {
  IParamsGetBroadcastContacts,
  IResponseGetBroadcastContacts,
  IResponseOneTimeBroadcast,
} from 'entities/Broadcast/api/types'
import { BroadcastOneTime } from 'entities/Broadcast'
import { ContactStatistic } from 'entities/ContactStatistic'
import { BroadcastApi } from 'entities/Broadcast/api/broadcast'
import { type IOpenContactsDetails } from 'widgets/ContactsDetails'
import { ContactsDetailsManageStore } from 'widgets/ContactsDetails/store'
import { ContactsTagsModalStore } from 'widgets/ContactsTagsModal'

export class BroadcastDetailOneTimeStore {
  private _tableStore = new TableStore<ContactStatistic>({
    element: 'contact',
    withoutDefaultManageColumns: true,
  })
  private _broadcast: BroadcastOneTime
  private _contactsDetailsManageStore = new ContactsDetailsManageStore()
  private _page = 1
  private _limit = 10
  private _scrollToTOpTrigger = ''

  private _loading = false
  private _search = ''
  private _total = 0

  private _filter: IParamsGetBroadcastContacts['filter'] = 'sent_to'
  private _itemsMap: Map<number, ContactStatistic> = new Map<number, ContactStatistic>()
  private _disposeParamsWithoutPage: IReactionDisposer | null = null
  private _disposeParams: IReactionDisposer | null = null
  private _contactsTagsModalStore = new ContactsTagsModalStore()

  constructor(
    broadcastResponse: IResponseOneTimeBroadcast,
    private _pageLayoutStore: PageLayoutStore
  ) {
    makeAutoObservable(this)
    this._broadcast = new BroadcastOneTime(broadcastResponse)

    this.loadData()
    this.reactionParamsWithoutPage()
    this.reactionParams()
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get contactsTagsModalStore() {
    return this._contactsTagsModalStore
  }

  get broadcast() {
    return this._broadcast
  }

  get tableStore() {
    return this._tableStore
  }

  get limit() {
    return this._limit
  }

  get scrollToTOpTrigger() {
    return this._scrollToTOpTrigger
  }

  get page() {
    return this._page
  }

  get loading() {
    return this._loading
  }

  get total() {
    return this._total
  }

  get search() {
    return this._search
  }

  reactionParams = () => {
    this._disposeParams?.()
    this._disposeParams = reaction(() => this.params, this.loadData, {
      delay: 500,
    })
  }

  reactionParamsWithoutPage = () => {
    this._disposeParamsWithoutPage?.()
    this._disposeParamsWithoutPage = reaction(
      () => this.paramsWithoutPage,
      () => {
        this._loading = true
        this._page = 1
      }
    )
  }

  reset = () => {
    this._disposeParams?.()
    this._disposeParamsWithoutPage?.()
    this._contactsDetailsManageStore.reset()
  }

  init = () => {
    this._contactsDetailsManageStore.init()
  }

  get onOpenContactsDetails(): IOpenContactsDetails {
    return {
      disabled: this._contactsDetailsManageStore.disabled,
      open: (data) => {
        this._contactsDetailsManageStore.onOpenContactDetails(
          {
            contactId: data.contactId,
            inboxId: this._broadcast.inboxId,
          },
          'broadcast',
          false
        )
      },
    }
  }

  get contactsDetailsStore() {
    return this._contactsDetailsManageStore.contactsDetailsStore
  }

  get isNoData() {
    return !this._loading && !this._itemsMap.size && !this._search
  }

  get items(): ContactStatistic[] {
    return Array.from(this._itemsMap.values())
  }

  private cancelTokenSource: CancelTokenSource | null = null

  get paramsWithoutPage(): Omit<IParamsGetBroadcastContacts, 'page'> {
    return {
      search: removeSpacesFromNumericString(this._search),
      length: this._limit,
      filter: this._filter,
      sortBy: this._tableStore.sortBy || 'created_at',
      sortOrder: this._tableStore.sortOrder,
    }
  }

  get params(): IParamsGetBroadcastContacts {
    return {
      page: this._page,
      ...this.paramsWithoutPage,
    }
  }

  get name() {
    return this._broadcast.name
  }
  get status() {
    return this._broadcast.status
  }
  get type() {
    return this._broadcast.type
  }
  get failed_reason() {
    return this._broadcast.failed_reason
  }

  get statistics() {
    return this._broadcast.statistics
  }

  setLoading = (loading: boolean) => {
    this._loading = loading
  }

  onActiveFilter = (filter: ICardFilter<IParamsGetBroadcastContacts['filter']>) => {
    this._filter = filter.key
  }

  private initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  private setData = (data: IResponseGetBroadcastContacts) => {
    this._itemsMap.clear()
    data.data.forEach((item) => {
      this._itemsMap.set(item.id, new ContactStatistic(item))
    })

    this._total = data.total
  }

  private loadData = async () => {
    try {
      this.initCancelTokenSource()
      this._loading = true
      const { data } = await BroadcastApi.getBroadcastContacts(this._broadcast.id, this.params)
      this.setData(data)
      this._scrollToTOpTrigger = nanoid()
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }
  setSearch = (value: string) => {
    this._search = value
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this._page = page
    this._limit = limit
  }
  get filters() {
    if (!this.statistics) return []
    let filters: ICardFilter<IParamsGetBroadcastContacts['filter']>[] = this.statistics?.filters
    if (this._broadcast.type === 'ringless') {
      const ringlessColumnsIds = ['sent_to', 'success', 'skipped', 'replied', 'failed']
      filters = this.statistics?.filters?.filter((column) =>
        this._broadcast.type === 'ringless' ? ringlessColumnsIds.includes(column.key) : true
      ) as ICardFilter<IParamsGetBroadcastContacts['filter']>[]
    }

    if (this._broadcast.status === 'pause' || this._broadcast.status === 'stopped') {
      return filters
    }
    return filters.filter((filter) => filter.key !== 'skipped')
  }

  get filter() {
    return this._filter
  }

  onSuccessDelete = () => {
    this.loadData()
    this._tableStore.unselectAllIds()
  }
}
