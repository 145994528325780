// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZxOG3ZMHzUgBAU3eaMIl{display:flex;align-items:flex-start;flex-direction:column;padding-top:24px}.bMYC9Lv9VJqJoWbSOTKW{width:100%}", "",{"version":3,"sources":["webpack://./src/widgets/FeatureRestrictedModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,qBAAA,CACA,gBAAA,CAGF,sBACE,UAAA","sourcesContent":[".content {\n  display: flex;\n  align-items: flex-start;\n  flex-direction: column;\n  padding-top: 24px;\n}\n\n.alert {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "ZxOG3ZMHzUgBAU3eaMIl",
	"alert": "bMYC9Lv9VJqJoWbSOTKW"
};
export default ___CSS_LOADER_EXPORT___;
