import { makeAutoObservable, runInAction } from 'mobx'
import type { IHubspotSettingsDict, Integration } from 'entities/Integrations'
import { IntegrationSettingsCustomPropertyStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationSettingsCustomPropertyStore'
import {
  HUBSPOT_BANNED_IDS,
  HUBSPOT_PROPERTIES,
} from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationHubspotSettings/lib'
import type { SettingsPropertyItemType } from 'pages/settings/pages/integrations/pages/integrationSettings/model/types'
import type { IntegrationCustomSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCustomSettingsStore'
import { SettingsProperty } from 'pages/settings/pages/integrations/pages/integrationSettings/model/SettingsProperty'

export class IntegrationHubspotSettingsPropertyStore {
  private _properties = new Map<number | string, SettingsProperty>()
  private _customPropertyStore

  constructor(
    private _integration: Integration,
    private _customSetting: IntegrationCustomSettingsStore<IHubspotSettingsDict>
  ) {
    this._customPropertyStore = new IntegrationSettingsCustomPropertyStore(
      _integration,
      _customSetting,
      HUBSPOT_BANNED_IDS
    )
    makeAutoObservable(this)
  }

  async init() {
    const customSettingTimeout = setInterval(() => {
      if (this._customSetting.status.isSuccess) {
        clearInterval(customSettingTimeout)
        this._defaultFieldsInitialization()
      }
    }, 200)
    this._customPropertyStore.init()
  }

  private _defaultFieldsInitialization = () => {
    HUBSPOT_PROPERTIES.forEach((item) => {
      let property: SettingsPropertyItemType = item
      if (property.id === 'number') {
        property = {
          ...property,
          value: this._customSetting.getValue('phone_number_priority'),
          options: this._customSetting.getOptions('phone_number_priority')?.map((item) => ({
            value: item.value,
            label: item.label,
            id: item.value,
          })),
        }
      }
      this._setProperty(property)
    })
  }

  private _refreshStore = () => {
    this._properties.clear()
    this._defaultFieldsInitialization()
    this._customPropertyStore.refreshStore()
  }

  private _patchData = async (newData: SettingsPropertyItemType) => {
    const item = this._properties.get(newData.id)
    if (!item) return

    this._setProperty(newData)

    const response = await this._customSetting.patchData([
      {
        key: 'phone_number_priority',
        value: String(newData.value),
      },
    ])

    if (!(response?.status === 200)) {
      this._setProperty(item)
    }
  }

  private _setProperty = (data: SettingsPropertyItemType) => {
    runInAction(() => {
      this._properties.set(
        data.id,
        new SettingsProperty<string>(data, {
          onChange: this._patchData,
        })
      )
    })
  }

  saveNewProperties = async () => {
    this._customPropertyStore.saveNewProperties()
  }

  clearNewProperties = () => {
    this._refreshStore()
  }

  get rows() {
    return Array.from(this._properties.values()).concat(this._customPropertyStore.rows)
  }

  get showSaveControl() {
    if (this.customFieldsLoading) {
      return false
    }

    return (
      this._customPropertyStore.isExistNewProperties ||
      this._customPropertyStore.isRemovedOldProperties
    )
  }

  get mappedLength() {
    return this._properties.size + this._customPropertyStore.mappedLength
  }

  get customFieldsLoading() {
    return this._customPropertyStore.loading
  }

  get loading() {
    return this._customSetting.status.isLoading
  }

  get customPropertyStore() {
    return this._customPropertyStore
  }

  get integrationName() {
    return this._integration?.name || ''
  }
}
