import { createContext, useContext, FC, ReactNode } from 'react'
import { type ImportContactsStore } from 'pages/contacts/ui/ImportContacts/store/ImportContactsStore'

export const ImportContactsContext = createContext<ImportContactsStore | null>(null)

export const useImportContactsContext = () => {
  const context = useContext(ImportContactsContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ImportContactsContext')

  return context
}

type IProviderImportContactsProps = {
  value: ImportContactsStore
  children: ReactNode
}

export const ProviderImportContacts: FC<IProviderImportContactsProps> = ({ value, children }) => {
  return <ImportContactsContext.Provider value={value}>{children}</ImportContactsContext.Provider>
}
