import { ReactNode } from 'react'
import {
  Avatar,
  Box,
  CellWithoutContent,
  EmptyCell,
  type IAvatarInfo,
  IconButton,
  type ITableConfig,
  ItemsWithCount,
  Tooltip,
  Typography,
} from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import type { Contact } from 'entities/Contacts/model/Contact'
import { TagCard } from 'entities/Tags'
import type { ContactIntegration } from 'entities/Integrations/model/ContactIntegration'
import type { Tag } from 'entities/Tags/model/Tag'
import { TrialContactsLimitTooltip } from 'entities/Subscription/ui/TrialContactsLimitTooltip'
import type { IOpenContactsDetails } from 'widgets/ContactsDetails'

type IGetCellForName = {
  row: { id: number; isDisabled: boolean; avatarInfo: IAvatarInfo; fullName: string }
  onOpenContactsDetails: IOpenContactsDetails
  config?: ITableConfig
}

export const getCellForName = ({ row, onOpenContactsDetails, config }: IGetCellForName) => {
  const newContact = row.id === -1
  const showSendMessageBubble = !newContact && !row.isDisabled && !config?.hideSendMessage

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box
        sx={{
          alignItems: 'center',
          width: '80%',
          cursor: row.isDisabled ? 'default' : 'pointer',
          height: '32px',
        }}
        gap={1}
        display='flex'
      >
        <TrialContactsLimitTooltip show={row.isDisabled} />
        {!row.isDisabled && <Avatar info={row.avatarInfo} size={24} />}

        {row.fullName ? (
          <Typography
            pointer={!row.isDisabled}
            ellipsis
            variant={'body-md-regular'}
            tag={'div'}
            ariaLabel={'formattedValue'}
          >
            {row.fullName}
          </Typography>
        ) : (
          <EmptyCell />
        )}
      </Box>

      {showSendMessageBubble && (
        <Tooltip label='Conversation' placement='top'>
          <IconButton
            icon='chatRead'
            variant='icon'
            color='tertiary'
            transparentInactive
            className={config?.hidden || ''}
            disabled={onOpenContactsDetails.disabled}
            onClick={(event) => {
              event.stopPropagation()
              onOpenContactsDetails.open({
                contactId: row.id,
              })
            }}
            ariaLabel='ContactRow_sendMessage'
          />
        </Tooltip>
      )}
    </Box>
  )
}

export const getCellForTags = (row: { tags: Tag[]; isDisabled: boolean }) => {
  if (!row.tags.length) {
    return <EmptyCell />
  }

  return (
    <ItemsWithCount
      items={row.tags}
      itemComponent={({ item }) => <TagCard tag={item} />}
      containerWidth={362}
      withLeftIcon
      width={164}
      disabled={row.isDisabled}
    />
  )
}

export const getCellNumber = (row: Contact): ReactNode => {
  if (row.id === -1) {
    return <CellWithoutContent />
  }

  return row.number && getLabelAsNumberInternationalFormat(row.number)
}

export const getIntegrationCellMobileNumber = (row: ContactIntegration): ReactNode => {
  if (row.id === -1) {
    return <CellWithoutContent />
  }

  return row.mobile_phone_number && getLabelAsNumberInternationalFormat(row.mobile_phone_number)
}

export const getIntegrationCellNumber = (row: ContactIntegration): ReactNode => {
  if (row.id === -1) {
    return <CellWithoutContent />
  }

  return row.phone_number && getLabelAsNumberInternationalFormat(row.phone_number)
}
