import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats, logger } from 'shared/lib'
import { BroadcastApi } from 'entities/Broadcast/api/broadcast'
import { organizationStore } from 'entities/Organization'
import { billingStore } from 'entities/Billing'
import { subscriptionStore } from 'entities/Subscription'
import { type BroadcastViewStore, type IEstimatedDateProps } from 'widgets/BroadcastView'
import { ReviewBroadcastModalContent } from 'widgets/BroadcastView/ui/ReviewBroadcast/ReviewBroadcastModalContent'
import { ReviewBroadcastModalAction } from 'widgets/BroadcastView/ui/ReviewBroadcast/ReviewBroadcastModalAction'
import { AutoRechargeWidgetStore } from 'widgets/AutoRechargeModal'

export class BroadcastReviewStore {
  broadcastViewStore: BroadcastViewStore
  autoRechargeWidgetStore = new AutoRechargeWidgetStore()

  private _modalId = 'reviewBroadcastModalId'
  private _estimatedCount = 0

  constructor(broadcastViewStore: BroadcastViewStore) {
    this.broadcastViewStore = broadcastViewStore

    makeAutoObservable(this)
  }

  get isRingless() {
    return this.broadcastViewStore.type === 'ringless'
  }

  get estimateType(): 'sms' | 'mms' {
    if (this.isRingless) {
      return 'mms'
    }
    return this.broadcastViewStore.broadcastSMSStore.messageFieldStore.isMMS ? 'mms' : 'sms'
  }

  get package_count() {
    return this.broadcastViewStore.package_count
  }

  get segments_count() {
    if (this.isRingless) {
      return 1
    }
    return this.broadcastViewStore.broadcastSMSStore.segments_count
  }

  get loadingCreate() {
    return this.broadcastViewStore.loadingCreate
  }

  get isImmediately() {
    return this.broadcastViewStore.isImmediately
  }

  async open() {
    try {
      await this.estimateBroadcast()
      modalStore.addModal({
        id: this._modalId,
        type: ModalTypeList.DEFAULT,
        title: this.isImmediately ? 'Review & send' : 'Review & schedule',
        ModalContent: ReviewBroadcastModalContent,
        ModalActions: ReviewBroadcastModalAction,
        width: 540,
        ModalContentProps: {
          store: this,
        },
      })
    } catch (e) {
      logger.error(e)
    }
  }

  close = () => {
    modalStore.closeModal(this._modalId)
  }

  handleCreateBroadcast = async () => {
    await this.broadcastViewStore.handleCreateBroadcast(() => {
      uiStore.changeRoute({ path: '/broadcasts' })
    })

    this.close()
  }

  estimateBroadcast = async () => {
    const { data } = await BroadcastApi.estimateBroadcast({
      package_count: this.package_count,
      filtersListGroups: this.broadcastViewStore.params.filters.filtersListGroups,
      send_from: this.broadcastViewStore.params.send_from,
      type: this.estimateType,
      segments_count: this.segments_count,
      number_id: this.broadcastViewStore.params.send_from?.options.number_id || null,
    })
    this._estimatedCount = data.count
  }

  get notEnoughCredits() {
    const creditsCount = subscriptionStore.isTrial
      ? organizationStore.formattedTrialCredits
      : organizationStore.accountCredits
    return (Number(creditsCount) - this._estimatedCount) * -1
  }

  get isAutoRecharge() {
    return billingStore.autorecharge?.is_active
  }

  get isNotEnoughCredits() {
    if (this.isAutoRecharge) {
      return false
    }
    return this.notEnoughCredits > 0
  }

  get isOverDailyLimit() {
    const { package_count } = this.broadcastViewStore

    return this.broadcastViewStore.broadcastContactsStore.total > package_count
  }

  get isRecurring() {
    return this.broadcastViewStore.broadcastScheduleStore.scheduleStore.type === 'recurring'
  }

  get isAutoRechargeAlert() {
    if (this.isAutoRecharge) {
      return false
    }
    return !this.isImmediately
  }

  get isOverTrialLimits() {
    return subscriptionStore.isTrial && this.isNotEnoughCredits
  }

  get totalContactsCount() {
    return this.broadcastViewStore.broadcastContactsStore.total
  }

  get disabledCreate() {
    if (subscriptionStore.isTrial && this.totalContactsCount > 25) return true
    if (this.isNotEnoughCredits) return true

    if (!subscriptionStore.isTrial) {
      return this.isAutoRechargeAlert
    }

    return false
  }
  get is_contact_timezone() {
    return !!this.broadcastViewStore.params.is_contact_timezone
  }

  // // === For Broadcast Estimated completion === ////

  get estimatedDateText() {
    if (this.isOverDailyLimit) {
      return ''
    }
    if (!this.isImmediately) {
      return ''
    }
    return this.toEstimatedDate(this.estimatedTime)
  }

  private toEstimatedDate = (value: IEstimatedDateProps) => {
    let fullCount = 0

    if (!value) {
      return uiStore.dayjs().format(DayjsFormats.fullWithAtDash3)
    }
    if (value.hubspotList) {
      const hours = Math.floor(value.hubspotList / 3600)
      const minutes = Math.floor((value.hubspotList - hours * 3600) / 60)
      const seconds = value.hubspotList - hours * 3600 - minutes * 60

      let count = 0

      if (hours > 0) {
        count += hours * 3600
      }

      if (minutes > 0) {
        count += minutes * 60 + (seconds > 30 ? 60 : 0)
      }

      if (minutes === 0 && seconds > 0) {
        count += 60
      }

      fullCount += count
    }

    if (value.integrationMergeFields) {
      const hours = Math.floor(value.integrationMergeFields / 3600)
      const minutes = Math.floor((value.integrationMergeFields - hours * 3600) / 60)
      const seconds = value.integrationMergeFields - hours * 3600 - minutes * 60

      let count = 0

      if (hours > 0) {
        count += hours * 3600
      }

      if (minutes > 0) {
        count += minutes * 60 + (seconds > 30 ? 60 : 0)
      }

      if (minutes === 0 && seconds > 0) {
        count += 60
      }

      fullCount += count
    }

    if (value.general) {
      const hours = Math.floor(value.general / 3600)
      const minutes = Math.floor((value.general - hours * 3600) / 60)
      const seconds = value.general - hours * 3600 - minutes * 60

      let count = 0

      if (hours > 0) {
        count += hours * 3600
      }

      if (minutes > 0) {
        count += minutes * 60 + (seconds > 30 ? 60 : 0)
      }

      if (minutes === 0 && seconds > 0) {
        count += 60
      }

      fullCount += count
    }

    return uiStore
      .dayjs()
      .add(fullCount / 60, 'minutes')
      .format(DayjsFormats.fullWithAtDash3)
  }

  private get estimatedTime(): IEstimatedDateProps {
    const contactNumber = this.isImmediately
      ? Math.min(this.package_count, this.broadcastViewStore.broadcastContactsStore.total)
      : this.broadcastViewStore.broadcastContactsStore.total
    const segments_count = this.segments_count
    const number = this.broadcastViewStore.number

    if (!number || segments_count === 0 || contactNumber === 0) {
      return null
    }

    const integrationMergeFields = this.broadcastViewStore.params.message.match(
      /\{(\D{1})?hubspot\.(.+?)(\D{1})?\}/gi
    )
    const isHubspotList = JSON.stringify(
      this.broadcastViewStore.params.filters.filtersListGroups
    ).includes('"integration_key":"hubspot"')

    const baseToPerSecond = {
      local: 1,
      tollFree: 3,
      shortCode: 25,
    }

    const base = number.is_toll_free ? 'tollFree' : number.isShortCode ? 'shortCode' : 'local'
    const perSecond = baseToPerSecond[base]

    const result: IEstimatedDateProps = {
      general: 0,
      integrationMergeFields: 0,
      hubspotList: 0,
      total: 0,
    }
    result.general = Math.ceil(((contactNumber * segments_count) / perSecond) * 1.1)

    if (integrationMergeFields) {
      result.integrationMergeFields = Math.ceil(result.general * 0.1)
    }

    if (isHubspotList) {
      result.hubspotList = Math.ceil((result.general + (result.integrationMergeFields || 0)) * 0.1)
    }

    result.total = Math.max(
      result.general + (result.integrationMergeFields || 0) + (result.hubspotList || 0),
      60
    )

    return result
  }

  get allTotal() {
    return this.broadcastViewStore.broadcastContactsStore.allTotal
  }

  get skipped() {
    return this.broadcastViewStore.broadcastContactsStore.skipped
  }

  get estimatedCount() {
    return this._estimatedCount
  }

  // TODO: for future
  // private get sentDaysPerWeek() {
  //   if (this.broadcastContactsStore.total > this.package_count) {
  //     return this.broadcastScheduleStore.scheduleStore.advancedSchedule?.days.length || 1
  //   }
  //
  //   return 1
  // }
  //
  // private get estimatedTimeInDays() {
  //   if (this.broadcastContactsStore.total > this.package_count) {
  //     return Math.floor(this.broadcastContactsStore.total / this.package_count)
  //   }
  //
  //   return 0
  // }
  //
  // private get addedEstimatedTimeInWeeks() {
  //   if (this.estimatedTimeInDays > this.sentDaysPerWeek) {
  //     return Math.floor(this.estimatedTimeInDays / this.sentDaysPerWeek)
  //   }
  //
  //   return 0
  // }
  //
  // private get addedEstimatedTimeInDays() {
  //   if (this.estimatedTimeInDays < this.sentDaysPerWeek) {
  //     return this.estimatedTimeInDays
  //   } else {
  //     return this.estimatedTimeInDays % this.sentDaysPerWeek
  //   }
  // }
  //
  // private get addedEstimatedTimeInSeconds() {
  //   const contactNumber =
  //     this.broadcastContactsStore.total > this.package_count
  //       ? this.broadcastContactsStore.total % this.package_count
  //       : this.broadcastContactsStore.total
  //
  //   let executiveTime = this.segments_count * contactNumber
  //   if (!executiveTime) {
  //     return executiveTime
  //   }
  //
  //   // add 10 percents
  //   executiveTime += Math.ceil((executiveTime / 100) * 10)
  //   if (executiveTime < 60) {
  //     executiveTime = 60
  //   }
  //
  //   return executiveTime
  // }
}
