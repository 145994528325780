import React from 'react'
import { EmptyCell, type IColumn } from 'shared/ui'
import { getLabelAsNumberInternationalFormat } from 'shared/lib'
import {
  getCellForName,
  getCellForTags,
} from 'widgets/ContactEnrollment/ui/EnrollmentContactsTable'
import type { IOpenContactsDetails } from 'widgets/ContactsDetails'
import type { CampaignExecution } from '../store/CampaignExecution'

export const getColumns = (
  onOpenContactsDetails: IOpenContactsDetails,
  variant: 'overview' | 'step',
  isShowReply: boolean
) => {
  const nameColumn: IColumn<CampaignExecution> = {
    field: 'full_name',
    name: 'Name',
    renderRowCell: (row, _, config) =>
      getCellForName({
        row: row.contact,
        onOpenContactsDetails,
        config,
      }),
  }

  const numberColumn: IColumn<CampaignExecution> = {
    field: 'number',
    name: 'Number',
    renderRowCell: (row) =>
      row.contact.number && getLabelAsNumberInternationalFormat(row.contact.number),
  }

  const emailColumn: IColumn<CampaignExecution> = {
    field: 'email',
    name: 'Email',
  }

  const stepsCompleted: IColumn<CampaignExecution> = {
    field: 'steps_count',
    name: 'Steps completed',
  }

  const replied: IColumn<CampaignExecution> = {
    field: 'contact_has_replied',
    name: 'Replied',
    renderRowCell: (row) => (row.contact_has_replied ? 'Yes' : <EmptyCell />),
  }
  const tags: IColumn<CampaignExecution> = {
    field: 'tags',
    name: 'Tags',
    renderRowCell: (row) => getCellForTags(row.contact),
  }

  if (variant === 'step') {
    return [nameColumn, numberColumn, emailColumn, tags]
  }

  const columns = [nameColumn, numberColumn, emailColumn, stepsCompleted]

  if (isShowReply) {
    columns.push(replied)
  }

  return columns
}
