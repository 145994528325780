import { observer } from 'mobx-react-lite'
import React from 'react'
import { Table } from 'shared/ui'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { usePowerDialerListContext } from 'pages/powerDialer/pages/list/context/powerDialerListContext'
import { usePowerDialerWithActionsRows } from 'pages/powerDialer/pages/list/ui/PowerDialerTable/usePowerDialerWithActionsRows'
import { columns } from './columns'
import styles from './styles.module.scss'

export const PowerDialerTable = observer(() => {
  const { limit, page, loading, total, tableStore, onPaginationModelChange, noSearchResults } =
    usePowerDialerListContext()

  const itemsWithActions = usePowerDialerWithActionsRows()

  if (noSearchResults) {
    return <NoResultsFound />
  }

  return (
    <div className={styles.wrap}>
      <Table
        pinnedColumnNames={['name']}
        store={tableStore}
        columns={columns}
        rows={itemsWithActions}
        rowsCount={total}
        isLoading={loading}
        onChangePagination={onPaginationModelChange}
        initPage={page}
        limit={limit}
        startPaginationSize={10}
      />
    </div>
  )
})
