import { makeAutoObservable } from 'mobx'
import { type IResponsePowerDialer, PowerDialerStatus } from 'entities/PowerDialer/api/types'
import type { IParamsSendFrom, ISendFromNumbersProps } from 'entities/Inbox/api/types'
import { Phone } from 'entities/Phone/model/Phone'

export class PowerDialer {
  id: number
  name: string
  status: PowerDialerStatus
  sendFrom: IParamsSendFrom
  sendFromNumbersProps: ISendFromNumbersProps | null = null
  number: Phone | null

  contactsIdUnprocessed: number[]
  contactsIdEnrolled: number[]

  startedBy: number
  createdAt: string
  updatedAt: string

  enrolled: number
  answered: number
  noAnswered: number
  contacted: number
  noContacted: number
  skipped: number

  constructor(item: IResponsePowerDialer) {
    this.id = item.id
    this.name = item.name
    this.status = item.status
    this.sendFrom = item.send_from
    this.number = item.number ? new Phone(item.number) : null
    this.sendFromNumbersProps = this.getSendFromNumbersProps()

    this.contactsIdUnprocessed = item.contacts_id_unprocessed
    this.contactsIdEnrolled = item.contacts_id_enrolled

    this.startedBy = item.started_by
    this.createdAt = item.created_at
    this.updatedAt = item.updated_at

    this.enrolled = item.enrolled
    this.answered = item.answered
    this.noAnswered = item.no_answered
    this.contacted = item.contacted
    this.noContacted = item.no_contacted
    this.skipped = item.skipped

    makeAutoObservable(this)
  }

  getSendFromNumbersProps = (): ISendFromNumbersProps | null => {
    if (!this.number) return null
    return {
      icon: 'inboxOpen',
      name: this.number.numberable?.name,
      value: this.number.formatted_number,
    }
  }

  syncOrigin = (item: IResponsePowerDialer) => {
    this.status = item.status
    this.contactsIdUnprocessed = item.contacts_id_unprocessed
  }
}
