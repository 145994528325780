import { makeAutoObservable } from 'mobx'
import type { IHubspotSettingsDict } from 'entities/Integrations'
import type { ICustomFieldType } from 'entities/CustomField'
import { PROPERTY_MAPPING_RULES } from 'pages/settings/pages/integrations/pages/integrationSettings/lib'
import type {
  SettingsPropertyItemType,
  SettingsPropertyOptionType,
} from 'pages/settings/pages/integrations/pages/integrationSettings/model/types'

type IAction<T extends string | number = string, D extends object = IHubspotSettingsDict> = (
  v: SettingsPropertyItemType<T, D>
) => void | Promise<void>

type IAdditionalSettingData<
  T extends string | number = string,
  D extends object = IHubspotSettingsDict
> = {
  onChange?: IAction<T, D>
  onDelete?: IAction<T, D>
  initialBannedIds?: Set<string | number>
}

export class SettingsProperty<
  T extends string | number = string,
  D extends object = IHubspotSettingsDict
> {
  private _search = ''
  private _options: SettingsPropertyOptionType[] = []
  private _onChange: Nil<IAction<T, D>>
  private _onDelete: Nil<IAction<T, D>>
  private _showValidation = false
  private _bannedIds: Nil<Set<string | number>> = null
  id: string | number
  fieldName: string
  integrationName: string
  value: Nil<T>
  disabled: boolean
  type: SettingsPropertyItemType<T, D>['type']
  fieldType?: ICustomFieldType
  original: SettingsPropertyItemType<T, D>

  constructor(item: SettingsPropertyItemType<T, D>, additional?: IAdditionalSettingData<T, D>) {
    this.id = item.id
    this.fieldName = item.fieldName
    this.integrationName = item.integrationName
    this.value = item.value
    this.disabled = item.disabled
    this.type = item.type
    this.original = item
    this.fieldType = item.fieldType
    this._showValidation = item.showValidation || false
    this._bannedIds = this.type === 'default' ? null : additional?.initialBannedIds || null
    this._onChange = additional?.onChange
    this._onDelete = additional?.onDelete

    this.setOptions(item.options)
    makeAutoObservable(this)
  }

  setOptions(options?: SettingsPropertyOptionType[]) {
    if (this.value && this.disabled && !options) {
      this._options = [
        {
          id: this.value,
          label: String(this.value),
          value: String(this.value),
        },
      ]
      return
    }

    if (options && this.fieldType) {
      const availableTypes = PROPERTY_MAPPING_RULES[this.fieldType]
      this._options = options.filter(({ type }) => !!type && availableTypes.includes(type))
      return
    }

    this._options = options || []
  }

  onSearch = (search = '') => {
    this._search = search
  }

  onChange = (value: T) => {
    this._onChange?.({ ...this.original, value })
  }

  onDelete = () => {
    this._onDelete?.(this.original)
  }

  private get _filteredOptions() {
    if (this.type === 'default') {
      return this._options
    }

    return this._options.filter((item) => item.id === this.value || !this._bannedIds?.has(item.id))
  }

  get mapped() {
    return this.type === 'default' || !!this.value
  }

  get options() {
    if (!this._search) {
      return this._filteredOptions
    }
    const localSearch = this._search.toLocaleLowerCase()

    return this._filteredOptions.filter((option) =>
      option.label.toLocaleLowerCase().includes(localSearch)
    )
  }

  get error() {
    if (!this._showValidation) {
      return undefined
    }

    return this.mapped ? undefined : `Please select the ${this.integrationName} property`
  }
}
