import { observer } from 'mobx-react-lite'
import React from 'react'
import { Alert, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export const FeatureRestrictedModalContent = observer(() => {
  return (
    <div className={styles.content}>
      <Typography
        variant={'heading-md'}
        marginBottom={32}
        color={'var(--content-primary-primary)'}
        ariaLabel={'This feature is restricted'}
      >
        This feature is restricted
      </Typography>
      <Alert
        className={styles.alert}
        item={{
          type: 'infoBlue',
          title: 'Ask one of your admins to give you this permission:',
          desc: 'Can create Contacts',
        }}
      />
    </div>
  )
})
