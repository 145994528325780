import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { KnowledgeBaseViewPage } from 'pages/settings/pages/knowledgeBase/pages'
import { KnowledgeBaseAccessLayout } from 'pages/settings/pages/knowledgeBase/layout/KnowledgeBaseAccessLayout'
import {
  type IKnowledgeBasePageLayoutProps,
  KnowledgeBasePageLayout,
} from 'pages/settings/pages/knowledgeBase/layout/KnowledgeBasePageLayout'

export type IKnowledgeBaseRouterProps = IKnowledgeBasePageLayoutProps

export const KnowledgeBaseRouter: FC<IKnowledgeBaseRouterProps> = observer(({ type }) => {
  return (
    <Routes>
      <Route element={<KnowledgeBaseAccessLayout />}>
        <Route element={<KnowledgeBasePageLayout type={type} />}>
          <Route path={'/'} element={<KnowledgeBaseViewPage />} />
        </Route>
      </Route>
    </Routes>
  )
})
