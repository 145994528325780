// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KIwgGP_go18jqp27b49z{display:flex;justify-content:space-between;margin-bottom:13px}.ehl1FR4yZRu0sAIy6z6n{display:flex;align-items:center;gap:4px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/SummaryLine/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".summaryLine {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 13px;\n}\n.lineDesc {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summaryLine": "KIwgGP_go18jqp27b49z",
	"lineDesc": "ehl1FR4yZRu0sAIy6z6n"
};
export default ___CSS_LOADER_EXPORT___;
