import type { IIntegrationKey } from 'entities/Integrations'

export const integrationSortOrder: Partial<Record<IIntegrationKey, number>> = {
  hubspot: 0,
  salesforce: 1,
  activecampaign: 2,
  infusionsoft: 3,
  marketo: 4,
  pipedrive: 5,
  slack: 6,
  aircall: 7,
  google: 8,
  zipwhip: 9,
  intercom: 10,
  zapier: 11,
  make: 12,
  calendly: 13,
  webhook: 14,
}
