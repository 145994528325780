import { FC, useMemo, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Button, Icon, IconButton, IIconsVector, SpinnerLoader, Typography } from 'shared/ui'
import icons from 'shared/ui/Icon/icons'
import { MoreDropdownProps } from 'shared/ui/Actions/Actions'
import { uiStore } from 'shared/store/uiStore'
import {
  TextField,
  makeTextFieldAutoFocus,
  makeTextFieldClear,
  makeTextFieldElement,
} from 'shared/ui/TextField'
import { Integration, IntegrationKey } from 'entities/Integrations/model/Integration'
import { MergeFieldContentItem } from 'widgets/MergeField/ui/MergeFieldContentItem'
import { MergeFieldProps, MergeFieldsStore } from 'widgets/MergeField/store/mergeFieldsStore'
import styles from './styles.module.scss'

export const MergeFieldContent: FC<MoreDropdownProps & MergeFieldProps> = observer((props) => {
  const { contact, onAddMergeField, integration, onCloseMenu } = props
  const [mergeFieldStore] = useState(
    () =>
      new MergeFieldsStore({
        contact,
        onAddMergeField,
        integration,
        isAllIntegration: props.isAllIntegration,
        isAllConnectedOrDisconnectedIntegration: props.isAllConnectedOrDisconnectedIntegration,
      })
  )

  const isAllIntegration = mergeFieldStore.isAllIntegration

  const {
    setSearch,
    loading,
    search,
    filteredMergeFields,
    onSelectedIntegration,
    onSelectedDefault,
    selectedIntegration,
    isSelectDefault,
    isSelectedIntegration,
    onBack,
    allIntegrations,
  } = mergeFieldStore

  const handleSearch = async (search: string) => {
    await setSearch(search)
  }

  const getMergeFieldIconByKey = (key: IIconsVector): IIconsVector => {
    if (key in IntegrationKey) {
      if (icons[key]) {
        return key
      }
    }
    return 'salesmsg'
  }

  const handleSelectedIntegration = (integration: Integration) => {
    if (integration?.is_connected) {
      onSelectedIntegration(integration)
    } else {
      onCloseMenu?.()
      uiStore.changeRoute({
        path: '/settings/integrations',
        type: 'vue',
      })
    }
  }

  const IntegrationBtn = observer(({ integration }: { integration: Integration }) => (
    <Button
      key={integration.key}
      onClick={() => handleSelectedIntegration(integration)}
      typeBtn={'menuItem'}
      icon={getMergeFieldIconByKey(integration.icon)}
      paddingInline={8}
      iconClassName={styles.icon}
      titleContent={
        <div className={styles.title}>
          <div className={'row4'}>
            <Typography variant={'body-md-regular'} ariaLabel={'name'}>
              {integration.name}
            </Typography>
            {!integration.is_connected && <Icon icon={'alertTriangleYellow'} />}
          </div>

          {integration.is_connected ? (
            <Icon icon={'chevronRight'} fontSize={16} tertiary />
          ) : (
            <Typography
              variant={'body-xs-regular'}
              color={'var(--content-primary-tertiary)'}
              ariaLabel={'Reconnect'}
            >
              Reconnect
            </Typography>
          )}
        </div>
      }
    />
  ))

  const content = useMemo(() => {
    if (loading) {
      return (
        <div
          className={classNames(styles.loading, {
            [styles.withIntegration]:
              integration && !search && !isSelectedIntegration && !isSelectDefault,
          })}
        >
          <SpinnerLoader size={16} />
        </div>
      )
    }
    if (
      (!integration && !isAllIntegration) ||
      ((integration || isAllIntegration) && (isSelectDefault || selectedIntegration)) ||
      !allIntegrations.length ||
      search
    ) {
      return (
        <div className={styles.list}>
          {!filteredMergeFields.length && (
            <div className={styles.wrapEmpty}>
              <Typography
                variant={'body-sm-regular'}
                color={'var(--content-primary-tertiary)'}
                ariaLabel={'No results found'}
              >
                No results found
              </Typography>
            </div>
          )}
          {filteredMergeFields.map((field) => (
            <MergeFieldContentItem
              {...props}
              field={field}
              key={field.id}
              mergeFieldStore={mergeFieldStore}
              onCloseMenu={onCloseMenu}
            />
          ))}
        </div>
      )
    }
    if (integration) {
      return (
        <>
          <Button
            key={'salesmsg'}
            onClick={() => onSelectedDefault(true)}
            typeBtn={'menuItem'}
            icon={getMergeFieldIconByKey('salesmsg')}
            paddingInline={8}
            iconClassName={styles.icon}
            titleContent={
              <div className={styles.title}>
                <Typography variant={'body-md-regular'} ariaLabel={'Salesmsg'}>
                  Salesmsg
                </Typography>
                <Icon icon={'chevronRight'} fontSize={16} tertiary />
              </div>
            }
          />
          <IntegrationBtn integration={integration} />
        </>
      )
    }
    if (isAllIntegration) {
      return (
        <>
          <Button
            key={'salesmsg'}
            onClick={() => onSelectedDefault(true)}
            typeBtn={'menuItem'}
            icon={getMergeFieldIconByKey('salesmsg')}
            paddingInline={8}
            iconClassName={styles.icon}
            titleContent={
              <div className={styles.title}>
                <Typography variant={'body-md-regular'} ariaLabel={'Salesmsg'}>
                  Salesmsg
                </Typography>
                <Icon icon={'chevronRight'} fontSize={16} tertiary />
              </div>
            }
          />
          {allIntegrations.map((integration) => (
            <IntegrationBtn key={integration.key} integration={integration} />
          ))}
        </>
      )
    }
    return <></>
  }, [
    loading,
    integration,
    isSelectDefault,
    selectedIntegration,
    filteredMergeFields,
    search,
    allIntegrations,
  ])

  return (
    <div className={classNames(styles.wrap, 'top-dropdown-column-reverse-content')}>
      <div className={styles.header}>
        <TextField
          className={styles.textInput}
          size='small'
          variant='integrated'
          InputProps={{
            placeholder: 'Search',
          }}
          value={search}
          onChange={handleSearch}
          mainActions={[makeTextFieldAutoFocus({ withFocus: !loading })]}
          leftActions={[
            makeTextFieldElement({
              element: isSelectedIntegration && (
                <IconButton
                  tag='div'
                  icon={'chevronLeft'}
                  size={'small'}
                  variant={'icon'}
                  color={'secondary'}
                  fontSize={15}
                  onClick={onBack}
                  ariaLabel='MergeFieldContent_back'
                />
              ),
            }),
          ]}
          rightActions={[makeTextFieldClear()]}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.content}>{content}</div>
    </div>
  )
})
