import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { getHref, logger } from 'shared/lib'
import { complianceStore } from 'entities/Compliance/store/complianceStore'
import { AdminDetailCard, IAdminDetailCardRowProps } from 'pages/admin'
import { adminTollFreeDetailStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails'
import { adminTollFreeBusinessInfoStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/BusinessInfo/store/adminTollFreeBusinessInfoStore'

export const BusinessInfo = observer(() => {
  const { setData } = adminTollFreeDetailStore

  const {
    isForEdit,
    isSaveLoading,
    getError,

    street_address_1,
    edit_street_address_1,
    is_edit_street_address_1,
    set_edit_street_address,

    street_address_2,
    edit_street_address_2,
    is_edit_street_address_2,
    set_edit_street_address_2,

    city,
    edit_city,
    is_edit_city,
    set_edit_city,

    state,
    edit_state,
    is_edit_state,
    set_edit_state,

    postal_code,
    edit_postal_code,
    is_edit_postal_code,
    set_edit_postal_code,

    country,
    edit_country,
    is_edit_country,
    set_edit_country,

    business_name,
    edit_business_name,
    is_edit_business_name,
    set_edit_business_name,

    website_url,
    edit_website_url,
    is_edit_website_url,
    set_edit_website_url,

    onEdit,
    isEdit,
    onSave,
    onClose,
  } = adminTollFreeBusinessInfoStore

  const data: IAdminDetailCardRowProps[] = [
    {
      title: 'Street address 1',
      value: street_address_1,
      editValue: edit_street_address_1,
      isUnsaved: is_edit_street_address_1,
      onChange: set_edit_street_address,
      editError: getError('street_address'),
    },
    {
      title: 'Street address 2 (optional)',
      value: street_address_2,
      editValue: edit_street_address_2,
      isUnsaved: is_edit_street_address_2,
      onChange: set_edit_street_address_2,
      editError: getError('street_address_2'),
    },
    {
      title: 'City',
      value: city,
      editValue: edit_city,
      isUnsaved: is_edit_city,
      onChange: set_edit_city,
      editError: getError('city'),
    },
    {
      title: 'State/province/region',
      value: state,
      editValue: edit_state,
      isUnsaved: is_edit_state,
      onChange: set_edit_state,
      typeEdit: 'select',
      options: complianceStore.getStates(isEdit ? edit_country : country),
      loadOptions: complianceStore.loadBusinessProfileStates,
      editError: getError('state_id'),
    },
    {
      title: 'Postal code',
      value: postal_code,
      editValue: edit_postal_code,
      isUnsaved: is_edit_postal_code,
      onChange: set_edit_postal_code,
      editError: getError('postal_code'),
    },
    {
      title: 'Country',
      value: country && complianceStore.getCountryLabel(country),
      editValue: edit_country,
      isUnsaved: is_edit_country,
      onChange: set_edit_country,
      typeEdit: 'selectValue',
      options: complianceStore.complianceCountries,
      editError: getError('country'),
    },
    {
      title: 'Business name',
      value: business_name,
      editValue: edit_business_name,
      isUnsaved: is_edit_business_name,
      onChange: set_edit_business_name,
      editError: getError('business_name'),
    },

    {
      title: 'Business website',
      value: website_url,
      editValue: edit_website_url,
      isUnsaved: is_edit_website_url,
      onChange: set_edit_website_url,
      editError: getError('website_url'),
      renderValue: () =>
        website_url && (
          <Typography
            variant={'body-md-regular'}
            link
            textDecoration={'none'}
            pointer
            ariaLabel={'website_url'}
          >
            <a href={getHref(website_url)} target={'_blank'} rel='noreferrer'>
              {website_url}
            </a>
          </Typography>
        ),
    },
  ]

  const handleSave = async () => {
    try {
      const res = await onSave()
      if (res?.data) {
        setData(res?.data)
      }
      onClose()
    } catch (e) {
      logger.error(e)
    }
  }

  return (
    <AdminDetailCard
      title={'Business info'}
      data={data}
      isEdit={isEdit}
      actions={
        isForEdit &&
        (!isEdit ? (
          <Button
            text={'Edit'}
            contained={'secondary'}
            size={'medium'}
            icon={'edit'}
            onClick={onEdit}
          />
        ) : (
          <>
            <Button text={'Cancel'} contained={'secondary'} size={'medium'} onClick={onClose} />
            <Button
              text={'Save'}
              contained={'primary'}
              size={'medium'}
              onClick={handleSave}
              disabled={isSaveLoading}
            />
          </>
        ))
      }
    />
  )
})
