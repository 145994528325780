import { type ActionItem } from 'shared/ui'
import { type History } from 'pages/contacts/pages/history/model/History'
import { type HistoryPageStore } from 'pages/contacts/pages/history/store/historyPageStore'

export const getHistoryActions = (history: History, store: HistoryPageStore): ActionItem[] => {
  const { deleteHistory } = store

  return [
    {
      text: 'Delete',
      icon: 'delete',
      onAction: () => {
        deleteHistory(history)
      },
    },
  ]
}
