import { type IColumn, Avatar, Typography, Button, Box } from 'shared/ui'
import { getLabelAsNumberInternationalFormat, formatPhoneNumberToInternational } from 'shared/lib'
import { type IContactDuplicate } from 'entities/Contacts/api/types'
import { type ContactsDuplicateTableStore } from 'pages/contacts/pages/cleanup/ui/ContactsDuplicateTable'
import styles from './styles.module.scss'

const ContactRowCell = ({ contact }: { contact: IContactDuplicate }) => {
  const colors = ['#6372A5', '#1D95F2', '#10D0AD', '#E13466', '#FFC422', '#FF9423', '#A233E1']
  const color = colors[contact.id % colors.length]

  const number = formatPhoneNumberToInternational(contact.formatted_number)

  const avatarInfo = {
    firstName: contact.number || '#',
    lastName: '',
    color,
    number: number,
    image: undefined,
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        height: '32px',
      }}
      gap={1}
      display='flex'
    >
      <Avatar info={avatarInfo} size={24} />
      <Typography ellipsis variant={'body-md-regular'} tag={'div'} ariaLabel={'formatted_number'}>
        {getLabelAsNumberInternationalFormat(number)}
      </Typography>
    </Box>
  )
}

const DuplicateRowCell = ({ contact }: { contact: IContactDuplicate }) => {
  return <div className={styles.duplicatesCount}>{contact.count - 1}</div>
}

const ActionsRowCell = ({
  contact,
  store,
}: {
  contact: IContactDuplicate
  store: ContactsDuplicateTableStore
}) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '32px',
      }}
      gap={1}
      display='flex'
    >
      <Button
        size={'medium'}
        contained={'tertiary'}
        text={'Review'}
        onClick={() => {
          store.contactsMergeModalStore.init(store.list, contact.id, store.reset)
        }}
      />
    </Box>
  )
}

export const columns = (store: ContactsDuplicateTableStore): IColumn<IContactDuplicate>[] => {
  return [
    {
      field: 'name',
      name: 'Name',
      fullName: 'Name',
      renderRowCell: (row) => <ContactRowCell contact={row} />,
      maxWidth: 365,
    },
    {
      field: 'duplicates',
      name: 'Duplicates',
      renderRowCell: (row) => <DuplicateRowCell contact={row} />,
    },
    {
      field: 'actions',
      name: ' ',
      renderRowCell: (row) => <ActionsRowCell contact={row} store={store} />,
    },
  ]
}
