import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { type IType } from 'shared/ui'
import { CAMPAIGN_NAME_LIMIT } from 'shared/constants/limits'
import type { IParamsTestTrigger } from 'entities/Trigger/api/type'
import { TriggerApi } from 'entities/Trigger/api/trigger'
import { type ICreateCampaignStep } from 'entities/Campaign'
import { NameInputStore } from 'features/createOrEdit/NameInput'
import { CampaignScheduleStore } from 'pages/campaigns/ui/CampaignSchedule'
import { CampaignSMSStore } from 'pages/campaigns/ui/CampaignSMS'
import { CampaignRinglessStore } from 'pages/campaigns/ui/CampaignRingless'
import { CampaignSendFromStore } from 'pages/campaigns/ui/CampaignSendFrom'

export class CampaignMessageStore {
  nameInputStore
  campaignScheduleStore
  campaignSMSStore
  campaignRinglessStore
  campaignSendFromStore
  constructor(
    private _onDuplicate: (message: CampaignMessageStore) => void,
    private _onDelete: (uid: string) => void
  ) {
    this.nameInputStore = new NameInputStore({
      limit: CAMPAIGN_NAME_LIMIT,
    })
    this.campaignScheduleStore = new CampaignScheduleStore()
    this.campaignSMSStore = new CampaignSMSStore()
    this.campaignRinglessStore = new CampaignRinglessStore()
    this.campaignSendFromStore = new CampaignSendFromStore()

    makeAutoObservable(this)
  }

  get name() {
    return this.nameInputStore.name
  }
  get scheduleText() {
    return this.campaignScheduleStore.scheduleText
  }

  get params(): ICreateCampaignStep {
    return {
      id: this.id,
      name: this.name,
      send_from: this.sendFromParams,
      type: this.type,
      schedule: this.campaignScheduleStore.params,
      payload: this.typeParams,
      order: this.step,
    }
  }
  get typeParams() {
    if (this.type === 'sms') {
      return this.campaignSMSStore.messageDataParams
    }
    return {
      message: '',
      media_url: this.campaignRinglessStore.ringlessMessageDataParams.media_url,
    }
  }

  get sendFromParams() {
    return this.campaignSendFromStore.params
  }

  init = (input: ICreateCampaignStep) => {
    this.id = input.id
    this.nameInputStore.init(input.name)
    this.campaignScheduleStore.init(input.schedule)
    if (input.send_from) {
      this.campaignSendFromStore.init(input.send_from)
    }
    this.type = input.type
    if (input.type === 'ringless') {
      if (input.payload.media_url?.[0]) {
        this.campaignRinglessStore.init(input.payload.media_url[0])
      }
    }
    if (input.type === 'sms' && 'message' in input.payload) {
      this.campaignSMSStore.init(input.payload.message, input.payload.media_url)
    }
  }

  id: number | null = null
  uid: string = nanoid()
  step = 0
  setStep = (step: number) => {
    this.step = step
    if (!this.nameInputStore.name) {
      this.nameInputStore.setName(`Step ${this.step}`)
    }
  }

  type: IType = 'sms'
  setType = (value: IType) => {
    this.type = value
  }

  onDuplicate = () => {
    this._onDuplicate(this)
  }
  onDelete = () => {
    this._onDelete(this.uid)
  }

  onTestSMS = (params: IParamsTestTrigger) => TriggerApi.createTest(params)

  isCollapse = false
  setCollapse = (value: boolean) => {
    this.isCollapse = value
  }

  private get isError() {
    if (this.nameInputStore.error) {
      return true
    }
    if (this.type === 'sms') {
      if (this.campaignSMSStore.error) {
        return true
      }
    }
    if (this.type === 'ringless') {
      if (this.campaignRinglessStore.error) {
        return true
      }
    }
    if (this.campaignSendFromStore.error) {
      return true
    }
  }
  get error() {
    if (this.isError) {
      return 'Please complete all the fields'
    }
  }
  checkValidate = () => {
    let validate = true
    if (!this.nameInputStore.name.trim()) {
      validate = false
      this.nameInputStore.setError('Please enter message name')
    }
    if (this.type === 'sms') {
      if (!this.campaignSMSStore.checkValidate()) {
        validate = false
      }
    }
    if (this.type === 'ringless') {
      if (!this.campaignRinglessStore.checkValidate()) {
        validate = false
      }
    }

    if (!this.campaignSendFromStore.checkValidate()) {
      validate = false
    }

    return validate
  }

  setError = (path: string[], error: string) => {
    if (path[0] === 'name') {
      this.nameInputStore.setError(error)
    }
    if (path[0] === 'send_from') {
      this.campaignSendFromStore.setError(error)
    }
    if (path[0] === 'payload') {
      if (this.type === 'sms') {
        this.campaignSMSStore.setError(error)
      }
      if (this.type === 'ringless') {
        this.campaignRinglessStore.setError(error)
      }
    }
  }
}
