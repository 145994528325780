import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ProviderCleanupPage } from 'pages/contacts/pages/cleanup/context/cleanupContext'
import { useContactsPageLayoutContext } from 'pages/contacts/context/contactsPageLayoutContext'
import { CleanupPageStore } from 'pages/contacts/pages/cleanup/store/cleanupPageStore'

const CleanupLayoutContent = observer(() => {
  return <Outlet />
})

export const CleanupLayout = observer(() => {
  const { pageLayoutStore } = useContactsPageLayoutContext()
  const [store] = useState(() => new CleanupPageStore(pageLayoutStore))

  return (
    <ProviderCleanupPage value={store}>
      <CleanupLayoutContent />
    </ProviderCleanupPage>
  )
})
