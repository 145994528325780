import { useState } from 'react'
import { Button, type IButtonProps } from 'shared/ui/Button'
import { IconButton } from 'shared/ui/IconButton'
import { NavLink } from 'shared/ui/NavLink'

import { logger } from 'shared/lib'
import type { ActionItem } from './Actions'

export type ActionProps = {
  action: ActionItem
  fromDropdown: boolean
  fontSize?: number
  isButton?: boolean
  onCloseMenu?: () => void
  commonButtonProps?: IButtonProps
}

export const Action = ({
  fromDropdown,
  onCloseMenu,
  action,
  fontSize = 20,
  isButton,
  commonButtonProps,
}: ActionProps) => {
  const [loading, setLoading] = useState(false)
  const {
    onAction,
    text,
    icon,
    dropdownItemComponent,
    iconButtonComponent,
    buttonComponent,
    medium,
    buttonProps,
    iconButtonProps,
    link,
    navLink,
  } = action

  const handleAction = async () => {
    try {
      setLoading(true)
      if (onAction) {
        await onAction()
      }
      onCloseMenu && onCloseMenu()
    } catch (e) {
      logger.error(e)
    } finally {
      setLoading(false)
    }
  }
  if (fromDropdown) {
    if (dropdownItemComponent) {
      return <>{dropdownItemComponent(onCloseMenu)}</>
    }
    const btn = (
      <Button
        text={text}
        typeBtn={'menuItem'}
        icon={icon}
        onClick={handleAction}
        disabled={loading}
      />
    )
    if (link) {
      return (
        <a href={link} target={'_blank'} rel='noreferrer'>
          {btn}
        </a>
      )
    }
    if (navLink) {
      return (
        <NavLink to={navLink} rel='noreferrer'>
          {btn}
        </NavLink>
      )
    }
    return (
      <Button
        text={text}
        typeBtn={'menuItem'}
        icon={icon}
        onClick={handleAction}
        loading={loading}
        {...buttonProps}
      />
    )
  }

  if (medium || isButton) {
    if (buttonComponent) {
      return <>{buttonComponent}</>
    }
    const btn = (
      <Button
        text={text}
        contained={'secondary'}
        size={'medium'}
        icon={icon}
        onClick={handleAction}
        loading={loading}
        {...buttonProps}
        {...commonButtonProps}
      />
    )
    if (link) {
      return (
        <a href={link} target={'_blank'} rel='noreferrer'>
          {btn}
        </a>
      )
    }
    if (navLink) {
      return (
        <NavLink to={navLink} rel='noreferrer'>
          {btn}
        </NavLink>
      )
    }
    return btn
  }

  if (iconButtonComponent) {
    return <>{iconButtonComponent}</>
  }

  return (
    <IconButton
      icon={icon}
      color={'secondary'}
      variant={'icon'}
      onClick={handleAction}
      disabled={loading}
      fontSize={fontSize}
      ariaLabel='Action_Button'
      tooltipProps={{
        label: text,
        placement: 'top',
        ...iconButtonProps?.tooltipProps,
      }}
      {...iconButtonProps}
    />
  )
}
