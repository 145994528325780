import { makeAutoObservable, runInAction } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import type { IDropdownItem } from 'shared/ui'
import { logger } from 'shared/lib'
import { Phone } from 'entities/Phone/model/Phone'
import { numbersStore, PhoneApi } from 'entities/Phone'
import type {
  IMultiselectedItem,
  INumbersGroup,
} from 'pages/settings/pages/compliance/ui/MultiSelectNumbers/type'
import { type TollFreeStepsStore } from 'pages/settings/pages/compliance/pages/tollFree/store/tollFreeStepsStore'

export class NumbersStore {
  private _isLoading = false
  private _search = ''
  private _assignedListMap: Map<number, Phone> = new Map()
  private _unnassignedListMap: Map<number, Phone> = new Map()
  private _assignedDropdownMap: Map<number, IDropdownItem> = new Map()
  private _unnassignedDropdownMap: Map<number, IDropdownItem> = new Map()
  private _multiSelectedValuesMap: Map<number, IMultiselectedItem> = new Map()

  constructor(private _tollFreeStepsStore: TollFreeStepsStore) {
    makeAutoObservable(this)
  }

  init = async () => {
    runInAction(() => {
      this._isLoading = true
    })
    await Promise.all([await numbersStore.fetchNumbers(), this.loadUnnassignedNumbers()])

    this.setAssignedNumbers()

    runInAction(() => {
      this._isLoading = false
    })
  }

  get isLoading() {
    return this._isLoading
  }

  get assignedList() {
    return Array.from(this._assignedListMap.values())
  }

  get assignedDropdownNumbers() {
    return Array.from(this._assignedDropdownMap.values())
  }

  get unnassignedList() {
    return Array.from(this._unnassignedListMap.values())
  }

  get unnassignedDropdownNumbers() {
    return Array.from(this._unnassignedDropdownMap.values())
  }

  get search() {
    return this._search
  }

  get multiSelectedKeys() {
    return Array.from(this._multiSelectedValuesMap.keys())
  }

  get multiSelectedValues() {
    return Array.from(this._multiSelectedValuesMap.values())
  }

  get isDeselectState() {
    return this.itemsCount === this.multiSelectedKeys.length
  }

  get itemsCount() {
    return this.assignedList.length + this.unnassignedList.length
  }

  get isStepDisabled() {
    return !this.multiSelectedKeys.length
  }

  get numbersGroups(): INumbersGroup[] {
    const items: INumbersGroup[] = []

    if (this.assignedDropdownNumbers.length) {
      items.push({
        key: 'assigned',
        title: '',
        items: this.getItems(this.assignedDropdownNumbers),
      })
    }

    if (this.unnassignedDropdownNumbers.length) {
      items.push({
        key: 'unnassigned',
        title: '',
        items: this.getItems(this.unnassignedDropdownNumbers),
      })
    }

    if (this._search) {
      return items.filter((group) => group.items.length)
    }

    return items
  }

  getItems = (numbersList: IDropdownItem[]): IDropdownItem[] => {
    return numbersList
      .filter((item) => {
        const numberItem = item.data as Phone
        if (this._multiSelectedValuesMap.has(numberItem.id)) return

        const searchByName = numberItem.numberable?.name
          .toLowerCase()
          .includes(this._search.toLowerCase())
        const searchByNumbers =
          numberItem.number.includes(this._search.toLowerCase()) ||
          numberItem.formatted_number.includes(this._search.toLowerCase()) ||
          numberItem.national_number.includes(this._search.toLowerCase())

        return Boolean(searchByName || searchByNumbers)
      }, [])
      .sort((a, b) => {
        const numberA = a.data as Phone
        const numberB = b.data as Phone

        const nameA = numberA.numberable?.name?.toLowerCase() || ''
        const nameB = numberB.numberable?.name?.toLowerCase() || ''
        const formattedNumberA = numberA.formatted_number
        const formattedNumberB = numberB.formatted_number

        if (nameA === nameB) {
          return formattedNumberA.localeCompare(formattedNumberB)
        }

        return nameA.localeCompare(nameB)
      })
  }

  loadUnnassignedNumbers = async () => {
    try {
      const { data } = await PhoneApi.getUnnassignedNumbers({
        page: 1,
        limit: 500,
      })

      data?.data.forEach((item) => {
        const phone = new Phone(item)
        if (phone.isTollFree && phone.isStatusUnverified) {
          this._unnassignedListMap.set(item.id, phone)
          this.setDropdownItem(phone, this._unnassignedDropdownMap)
        }
      })
    } catch (e) {
      logger.error(e)
    }
  }

  setAssignedNumbers = () => {
    numbersStore.userNumbers?.forEach((item) => {
      if (item.isTollFree && item.isStatusUnverified) {
        this._assignedListMap.set(item.id, item)
        this.setDropdownItem(item, this._assignedDropdownMap)
      }
    })
  }

  setDropdownItem = (number: Phone, list: Map<number, IDropdownItem>) => {
    const item = {
      id: String(number.id),
      label: number.formatted_number,
      data: number,
      labelRight: number.numberable?.name || 'Unnassigned',
      iconLabel: number?.is_aircall ? 'aircall' : undefined,
      ariaLabel: 'MultiSelectDropdownNumber',
      labelRightVariant: 'body-md-regular',
    } as IDropdownItem

    list.set(number.id, item)
  }

  setMultipleItem = (item: IDropdownItem) => {
    const phone = item.data as Phone
    const numberId = Number(item.id)
    const name = phone?.numberable?.name || 'Unnassigned'
    const label = phone.formatted_number
    const isAssignedNumber = !!phone?.numberable?.name

    this._multiSelectedValuesMap.set(numberId, {
      id: numberId,
      name,
      label,
      data: phone,
    } as IMultiselectedItem)

    this.filterMultiselectListNumbers(isAssignedNumber)
  }

  handleMultiSelect = () =>
    [...this._assignedDropdownMap, ...this._unnassignedDropdownMap].forEach(([_, item]) =>
      this.setMultipleItem(item)
    )

  setRemovedItemToList = (item: IMultiselectedItem) => {
    if (item.name === 'Unnassigned') {
      this.setDropdownItem(item.data, this._unnassignedDropdownMap)
    } else this.setDropdownItem(item.data, this._assignedDropdownMap)
  }

  handleMultiDeselect = () => {
    this.multiSelectedValues.forEach((item) => {
      this.setRemovedItemToList(item)
    })
    this._multiSelectedValuesMap.clear()
  }

  removeMultiSelectedItem = (item: IMultiselectedItem) => {
    this._multiSelectedValuesMap.delete(item.id)
    this.setRemovedItemToList(item)
  }

  filterMultiselectListNumbers = (isAssignedNumber: boolean) => {
    const list = isAssignedNumber ? this._assignedDropdownMap : this._unnassignedDropdownMap
    const filteredMap = new Map(
      Array.from(list).filter(([key]) => !this.multiSelectedKeys.includes(key))
    )

    isAssignedNumber
      ? (this._assignedDropdownMap = filteredMap)
      : (this._unnassignedDropdownMap = filteredMap)
  }

  setSearch = (value: string) => {
    this._search = value
  }

  numbersNextClick = () => {
    uiStore.changeRoute({
      path: this._tollFreeStepsStore.tollFreeNavigationStore.getNextUrl,
    })
  }
}
