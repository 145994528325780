import { makeAutoObservable, runInAction } from 'mobx'
import { prepareSearchRegex } from 'shared/lib/prepareSearch'

import { logger } from 'shared/lib'
import { CustomField } from '../model'
import { CustomFieldApi, ICustomFieldsResponse } from '../api'

export class CustomFieldsStore {
  private _page = 0
  private _total = 0
  private _limit = 100

  loading = true
  fields: CustomField[] = []
  termFields: CustomField[] = []

  constructor(limit = 100) {
    this._limit = limit

    makeAutoObservable(this)
  }

  loadData = async () => {
    this.loading = true

    try {
      const { data } = await CustomFieldApi.getCustomFields({
        length: this._limit,
        page: this._page + 1,
      })

      this._addData(data)

      runInAction(() => {
        this.loading = false
      })
    } catch (error) {
      logger.error(error)
    }
  }

  reloadData = async () => {
    this.loading = true

    try {
      const { data } = await CustomFieldApi.getCustomFields({
        length: this._limit,
        page: 1,
      })

      this._setData(data)

      runInAction(() => {
        this.loading = false
      })
    } catch (error) {
      logger.error(error)
    }
  }

  searchFields(search: string) {
    const term = search.trim()

    if (!term) return this.clearSearch()

    const searchRegex = prepareSearchRegex(term)

    this.termFields = this.fields.filter((field) =>
      field.searchFragments.some((fragment) => searchRegex.test(fragment))
    )
  }

  clearSearch() {
    this.termFields = this.fields
  }

  private _addData = ({ data, total, current_page }: ICustomFieldsResponse) => {
    this.fields = [...this.fields, ...data.map((item) => new CustomField(item))]

    this._page = current_page
    this._total = total
  }

  private _setData = ({ data, total, current_page }: ICustomFieldsResponse) => {
    this.fields = data.map((item) => new CustomField(item))

    this._page = current_page
    this._total = total
  }
}
