import { makeAutoObservable, IReactionDisposer, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'shared/constants/auth'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { AuthApi } from 'entities/Auth/api'
import { AuthRoutesEnum, authStore } from 'entities/Auth'
import { ConversationRoutes } from 'entities/ExportRouters'
import { FieldsEnum } from './type'

export class ResetPasswordStore {
  private _password = ''
  private _token = ''
  private _email = ''
  private _confirm_password = ''
  private _loading = false
  private _preloading = true
  private _isValidToken = false
  private _activeField: FieldsEnum | null = null
  private _error: null | string = null
  private _showConstantError = false

  private _disposePasswordValidation: IReactionDisposer | null = null
  private _disposePasswordValidationTrigger: IReactionDisposer | null = null
  private _passwordError: string | null = null
  private _confirmPasswordError: string | null = null
  private _passwordValidationTrigger: string | null = null

  constructor(token: string) {
    makeAutoObservable(this)
    this._token = token
    this.reactionPasswordValidation()
    this.reactionPasswordValidationTrigger()
    this.validateToken()
  }

  get error() {
    return this._error
  }

  get showConstantError() {
    return this._showConstantError
  }

  get isPasswordsEqual() {
    return this._confirm_password === this._password
  }

  get email() {
    return this._email
  }

  get isValidPassword() {
    if (!this.isPasswordsEqual) return false
    return this.isValidPasswordLength
  }

  get isValidPasswordLength() {
    return (
      this._password.length <= MAX_PASSWORD_LENGTH && this._password.length >= MIN_PASSWORD_LENGTH
    )
  }

  get loading() {
    return this._loading
  }

  get preloading() {
    return this._preloading
  }

  get password() {
    return this._password
  }

  get passwordLength() {
    return this.password.length
  }

  get confirmPassword() {
    return this._confirm_password
  }

  get confirmPasswordLength() {
    return this.confirmPassword.length
  }

  get passwordError() {
    return this._passwordError
  }

  get confirmPasswordError() {
    return this._confirmPasswordError
  }

  get isValidToken() {
    return this._isValidToken
  }

  get payload() {
    return {
      token: this._token,
      password: this._password,
      password_confirmation: this._confirm_password,
    }
  }

  get isMaxPassword() {
    return this.password.length === MAX_PASSWORD_LENGTH
  }

  get isMaxConfirmPassword() {
    return this.confirmPassword.length === MAX_PASSWORD_LENGTH
  }

  get isActivePasswordField() {
    return this._activeField === FieldsEnum.password
  }

  get isActiveConfirmPasswordField() {
    return this._activeField === FieldsEnum.confirmPassword
  }

  handleResetPassword = () => {
    this.triggerPasswordValidation()
    this._showConstantError = true
  }

  focusConfirmPasswordField = () => {
    this.setActiveField(FieldsEnum.confirmPassword)
  }

  blurConfirmPasswordField = () => {
    this.blurActiveField(FieldsEnum.confirmPassword)
  }

  focusPasswordField = () => {
    this.setActiveField(FieldsEnum.password)
  }

  blurPasswordField = () => {
    this.blurActiveField(FieldsEnum.password)
  }

  blurActiveField = (value: FieldsEnum) => {
    if (this._activeField !== value) return
    this._activeField = null
  }

  setActiveField = (value: FieldsEnum) => {
    this._activeField = value
  }

  validateToken = async () => {
    try {
      this._preloading = true
      const {
        data: { is_valid },
      } = await AuthApi.validateResetPasswordToken({ token: this._token })
      this._isValidToken = is_valid
    } catch (error) {
      logger.error(error)
      this._isValidToken = false
    } finally {
      this._preloading = false
    }
  }

  resetPassword = async () => {
    if (!this.isValidPassword) return
    this.clearErrors()
    try {
      this.setLoading(true)
      await AuthApi.resetPassword(this.payload)

      if (authStore.isLoggedIn) {
        uiStore.changeRoute({
          path: `/${ConversationRoutes.root}`,
        })
        return
      }
      uiStore.changeRoute({
        path: `/${AuthRoutesEnum.login}`,
        options: {
          state: {
            alert: 'The new password was saved.',
          },
        },
      })
    } catch (error) {
      logger.error(error)
      if (error instanceof AxiosError) {
        this._error = error.response?.data?.password?.[0] || error.response?.data?.status || ''
      }
    } finally {
      this.setLoading(false)
    }
  }

  clearErrors = () => {
    if (this._passwordError) this._passwordError = null
    if (this._confirmPasswordError) this._confirmPasswordError = null
    if (this._error) this._error = null
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  setPassword = (value: string) => {
    if (value.length > MAX_PASSWORD_LENGTH) return
    this.clearErrors()
    this._showConstantError = false
    this._password = value
  }

  setConfirmPassword = (value: string) => {
    if (value.length > MAX_PASSWORD_LENGTH) return
    this.clearErrors()
    this._showConstantError = false
    this._confirm_password = value
  }

  triggerPasswordValidation = () => {
    this._passwordValidationTrigger = nanoid()
  }

  reactionPasswordValidation = () => {
    this._disposePasswordValidation?.()
    this._disposePasswordValidation = reaction(
      () => [this._passwordValidationTrigger, this.isValidPassword],
      ([trigger, isValid]) => {
        if (isValid) this.clearErrors()
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionPasswordValidationTrigger = () => {
    this._disposePasswordValidationTrigger?.()
    this._disposePasswordValidationTrigger = reaction(
      () => this._passwordValidationTrigger,
      (value) => {
        if (!value) return
        this.clearErrors()

        if (this.isValidPassword) {
          if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
          this.resetPassword()
          return
        }

        if (!this.isValidPasswordLength) {
          this._passwordError = `Password must contain at least ${MIN_PASSWORD_LENGTH} characters.`
          return
        }

        if (!this.isPasswordsEqual) this._confirmPasswordError = 'Passwords don’t match.'
      },
      {
        fireImmediately: true,
      }
    )
  }
}
