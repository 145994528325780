import { makeAutoObservable, runInAction } from 'mobx'
import { EnumAlertBannerVariant, type IAlertBannerProps } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { subscriptionStore } from 'entities/Subscription/store/subscription'
import { organizationStore } from 'entities/Organization'
import { numbersStore } from 'entities/Phone'
import { usersStore } from 'entities/Users'
import { ComplianceApi } from 'entities/Compliance'

export class TrialSubscriptionBannerStore {
  private _loading = true

  constructor() {
    makeAutoObservable(this)
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await this.trialSubscription.init()

    runInAction(() => {
      this._loading = false
    })
  }

  get trialSubscription() {
    return subscriptionStore.trialSubscriptionStore
  }

  get stateTollFree() {
    return this.trialSubscription.stateTollFree
  }

  get stateBusinessProfile10DLC() {
    return this.trialSubscription.stateBusinessProfile10DLC
  }

  get state() {
    return this.trialSubscription.state
  }

  get configUpdate(): Omit<IAlertBannerProps, 'ariaLabel'> {
    const firstLogout = Boolean(localStorage.getItem(`first_logout_${usersStore.user_owner_id}`))
    const isComplianceIsNotRequired = this.trialSubscription.isComplianceNotRequired
    const title = (() => {
      if (this.trialSubscription.isAdminOrOwnerRole) {
        if (firstLogout) {
          return 'Upgrade to get access to all features'
        }

        if (isComplianceIsNotRequired) {
          return 'Woohoo! You’re all set to send texts. Upgrade anytime to access all the features.'
        }

        return 'Woohoo! You’ve been approved! You’re all set to send texts. Upgrade anytime to access all the features.'
      }

      return 'You’re all set to send texts. Ask one of your admins to upgrade to get access to all features.'
    })()
    const variant = (() => {
      if (this.trialSubscription.isAdminOrOwnerRole) {
        if (firstLogout) return EnumAlertBannerVariant.Warning

        return EnumAlertBannerVariant.Success
      }

      return EnumAlertBannerVariant.Warning
    })()

    const action = this.trialSubscription.isAdminOrOwnerRole
      ? {
          text: 'Upgrade',
          onAction: () => {
            uiStore.changeRoute({
              path: '/settings/billing/overview/upgrade',
              type: 'vue',
            })
          },
        }
      : undefined

    return {
      title: title,
      variant: variant,
      actionPrimary: action,
    }
  }

  get configBusinessProfileAnd10DLC(): Omit<IAlertBannerProps, 'ariaLabel'> | null {
    if (this.stateBusinessProfile10DLC === 'step_1_upgrade') {
      return this.configUpdate
    }

    if (this.stateBusinessProfile10DLC === 'step_1_reject') {
      return {
        title: this.trialSubscription.isAdminOrOwnerRole
          ? 'Uh oh! Your application was rejected. Please update and resubmit to get approved to send texts.'
          : 'Uh oh! Your application was rejected. Ask one of your admins to update and resubmit to get approved to send texts.',
        variant: EnumAlertBannerVariant.Error,
        actionPrimary: this.trialSubscription.isAdminOrOwnerRole
          ? {
              text: 'Update',
              onAction: () => {
                if (this.trialSubscription.businessProfile?.isStatusFailed) {
                  uiStore.changeRouteViaVue({
                    path: '/settings/organization/compliance/business-profile',
                  })
                } else if (this.trialSubscription.compliance10DLCServicesList?.isStatusFailed) {
                  uiStore.changeRouteViaVue({
                    path: '/settings/organization/compliance/local-numbers',
                  })
                } else {
                  uiStore.changeRouteViaVue({
                    path: '/settings/organization/compliance',
                  })
                }
              },
            }
          : undefined,
        actionClose: !subscriptionStore.isTrial
          ? {
              showCloseBtn: true,
              onAction: () => {
                ComplianceApi.closeComplianceBannersSummary()
              },
            }
          : undefined,
      }
    }

    if (this.stateBusinessProfile10DLC === 'step_4') {
      return {
        title:
          'Almost there! Your business application is still under review. Please give the mobile carriers 3 business days to review your application.',
        variant: EnumAlertBannerVariant.InfoLight,
        actionPrimary: this.trialSubscription.isAdminOrOwnerRole
          ? {
              text: 'Check status',
              onAction: () => {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance',
                })
              },
            }
          : undefined,
        actionClose: {
          closeId: `local_check_status_4_${usersStore.user_owner_id}`,
        },
      }
    }

    if (this.stateBusinessProfile10DLC === 'step_3') {
      return {
        title:
          'Great! Your business application is under review. The mobile carriers can take up to 6 business days to review your application, so hang tight.',
        variant: EnumAlertBannerVariant.InfoLight,
        actionPrimary: this.trialSubscription.isAdminOrOwnerRole
          ? {
              text: 'Check status',
              onAction: () => {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance',
                })
              },
            }
          : undefined,
        actionClose: {
          closeId: `local_check_status_3_${usersStore.user_owner_id}`,
        },
      }
    }

    if (this.stateBusinessProfile10DLC === 'step_2') {
      return {
        title: this.trialSubscription.isAdminOrOwnerRole
          ? 'Keep going! Finish up your business application, so we can get you approved.'
          : 'Keep going! Ask one of your admins to finish your business application, so we can get you approved.',
        variant: EnumAlertBannerVariant.Info,
        actionPrimary: this.trialSubscription.isAdminOrOwnerRole
          ? {
              text: 'Continue',
              onAction: () => {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/local-numbers/campaign-use-cases',
                })
              },
            }
          : undefined,
        actionClose: !subscriptionStore.isTrial
          ? {
              showCloseBtn: true,
              onAction: () => {
                ComplianceApi.closeComplianceBannersSummary()
              },
            }
          : undefined,
      }
    }

    if (this.stateBusinessProfile10DLC === 'step_1') {
      return {
        title: this.trialSubscription.isAdminOrOwnerRole
          ? 'Making progress! Complete your business application, so we can wrap up your approval.'
          : 'Making progress! Ask one of your admins to complete your business application, so we can wrap up your approval.',
        variant: EnumAlertBannerVariant.Info,
        actionPrimary: this.trialSubscription.isAdminOrOwnerRole
          ? {
              text: 'Continue',
              onAction: () => {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/business-profile/company-location',
                })
              },
            }
          : undefined,
        actionClose: !subscriptionStore.isTrial
          ? {
              showCloseBtn: true,
              onAction: () => {
                ComplianceApi.closeComplianceBannersSummary()
              },
            }
          : undefined,
      }
    }

    if (this.stateBusinessProfile10DLC === 'step_0') {
      return {
        title: this.trialSubscription.isAdminOrOwnerRole
          ? 'Ready for the next step? Start your business application to get the approval process rolling.'
          : 'Ready for the next step? Ask one of your admins to start your business application to get the approval process rolling.',
        variant: EnumAlertBannerVariant.Info,
        actionPrimary: this.trialSubscription.isAdminOrOwnerRole
          ? {
              text: 'Get approved',
              onAction: () => {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/business-profile/company-location',
                })
              },
            }
          : undefined,
        actionClose: !subscriptionStore.isTrial
          ? {
              showCloseBtn: true,
              onAction: () => {
                ComplianceApi.closeComplianceBannersSummary()
              },
            }
          : undefined,
      }
    }

    return null
  }

  get configToolFree(): Omit<IAlertBannerProps, 'ariaLabel'> {
    if (this.stateTollFree === 'step_1_upgrade') {
      return this.configUpdate
    }

    if (this.stateTollFree === 'step_2_reject') {
      return {
        title: this.trialSubscription.isAdminOrOwnerRole
          ? 'Uh oh! Your application was rejected. Please contact us for more information.'
          : 'Uh oh! Your application was rejected. Ask one of your admins to contact us for more information.',
        variant: EnumAlertBannerVariant.Error,
        actionPrimary: this.trialSubscription.isAdminOrOwnerRole
          ? {
              text: 'See details',
              onAction: () => {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/toll-free',
                })
              },
            }
          : undefined,
        actionClose: !subscriptionStore.isTrial
          ? {
              showCloseBtn: true,
              onAction: () => {
                ComplianceApi.closeComplianceBannersSummary()
              },
            }
          : undefined,
      }
    }

    if (this.stateTollFree === 'step_1_reject') {
      return {
        title: this.trialSubscription.isAdminOrOwnerRole
          ? 'Uh oh! Your application was rejected. Please update and resubmit to get approved to send texts.'
          : 'Uh oh! Your application was rejected. Ask one of your admins to update and resubmit to get approved to send texts.',
        variant: EnumAlertBannerVariant.Error,
        actionPrimary: this.trialSubscription.isAdminOrOwnerRole
          ? {
              text: 'See details',
              onAction: () => {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/toll-free',
                })
              },
            }
          : undefined,
        actionClose: !subscriptionStore.isTrial
          ? {
              showCloseBtn: true,
              onAction: () => {
                ComplianceApi.closeComplianceBannersSummary()
              },
            }
          : undefined,
      }
    }

    if (this.stateTollFree === 'step_2') {
      return {
        title:
          'Great! Your business application is under review. The mobile carriers can take up to 4 business days to review your application, so hang tight.',
        variant: EnumAlertBannerVariant.InfoLight,
        actionPrimary: this.trialSubscription.isAdminOrOwnerRole
          ? {
              text: 'Check status',
              onAction: () => {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/toll-free',
                })
              },
            }
          : undefined,
        actionClose: {
          closeId: `toll_free_check_status_2_${usersStore.user_owner_id}`,
        },
      }
    }

    if (this.stateTollFree === 'step_1') {
      return {
        title: this.trialSubscription.isAdminOrOwnerRole
          ? 'Making progress! Complete your business application, so we can wrap up your approval.'
          : 'Making progress! Ask one of your admins to complete your business application, so we can wrap up your approval.',
        variant: EnumAlertBannerVariant.Info,
        actionPrimary: this.trialSubscription.isAdminOrOwnerRole
          ? {
              text: 'Continue',
              onAction: () => {
                uiStore.changeRouteViaVue({
                  path: '/settings/organization/compliance/toll-free',
                })
              },
            }
          : undefined,
        actionClose: {
          showCloseBtn: true,
          onAction: () => {
            ComplianceApi.closeComplianceBannersSummary()
          },
        },
      }
    }

    return {
      title: this.trialSubscription.isAdminOrOwnerRole
        ? 'Ready for the next step? Start your business application to get the approval process rolling.'
        : 'Ready for the next step? Ask one of your admins to start your business application to get the approval process rolling.',
      variant: EnumAlertBannerVariant.Info,
      actionPrimary: this.trialSubscription.isAdminOrOwnerRole
        ? {
            text: 'Get approved',
            onAction: () => {
              uiStore.changeRouteViaVue({
                path: '/settings/organization/compliance/toll-free',
              })
            },
          }
        : undefined,
      actionClose: {
        showCloseBtn: true,
        onAction: () => {
          ComplianceApi.closeComplianceBannersSummary()
        },
      },
    }
  }

  get config(): Omit<IAlertBannerProps, 'ariaLabel'> | null {
    // Number selected (local|tollfree)(step 3)
    if (this.state === 'number_local_owner' || this.state === 'number_local_member') {
      return this.configBusinessProfileAnd10DLC
    }

    if (this.state === 'number_tollfree_owner' || this.state === 'number_tollfree_member') {
      return this.configToolFree
    }

    // The questionnaire is filled out (step 1)
    if (this.state === 'questionnaire_ready_owner') {
      return {
        title: 'Time to choose your texting number! Pick one to kick off the approval process.',
        variant: EnumAlertBannerVariant.Info,
        actionPrimary: {
          text: 'Choose number',
          onAction: () => {
            uiStore.changeRouteViaVue({
              path: '/settings/organization/numbers',
            })

            numbersStore.initSelectNumberModal()
          },
        },
      }
    }

    if (this.state === 'questionnaire_ready_member') {
      return {
        title:
          'Time to choose your texting number! Ask one of your admins to pick a number and kick off the approval process.',
        variant: EnumAlertBannerVariant.Info,
      }
    }

    // The questionnaire is not filled in (step 0)
    if (this.state === 'questionnaire_not_ready_owner') {
      return {
        title: "Let's get you approved! Once your business is good to go, you'll be ready to text.",
        variant: EnumAlertBannerVariant.Info,
        actionPrimary: {
          text: 'Start now',
          onAction: () => {
            uiStore.changeRouteViaVue({
              path: '/settings/organization/compliance/questionnaire',
              name: 'settings.organization.compliance.questionnaire',
              options: {
                state: 'started',
              },
            })
          },
        },
      }
    }

    if (this.state === 'questionnaire_not_ready_member') {
      return {
        title:
          'Let’s get you approved! Ask one of your admins to start the approval process so you’ll be ready to start texting.',
        variant: EnumAlertBannerVariant.Info,
      }
    }

    // default state with FF disabled (featureFlagsStore.salesmsg_design_v2_settings_compliance)
    if (this.state === 'default_owner') {
      return {
        title: `Your trial will expire in ${organizationStore.trialEndsAtString}`,
        variant: EnumAlertBannerVariant.Warning,
        actionPrimary: {
          text: 'Upgrade now',
          onAction: () => {
            uiStore.changeRoute({
              path: '/settings/billing/overview/upgrade',
              type: 'vue',
            })
          },
        },
      }
    }

    return {
      title: `Your trial will expire in ${organizationStore.trialEndsAtString}`,
      variant: EnumAlertBannerVariant.Warning,
    }
  }

  hide = (state: 'started' | undefined, pathname?: string) => {
    if (this._loading) return true
    if (this.trialSubscription.summary?.isBannersClosed) return true

    if (/compliance\/calling\/(.+)/.test(pathname || '')) return true
    if (/compliance\/local-numbers\/(.+)/.test(pathname || '')) return true
    if (/compliance\/business-profile\/(.+)/.test(pathname || '')) return true
    if (/compliance\/toll-free\/(.+)/.test(pathname || '')) return true
    if (pathname?.includes('register/multiorg')) return true
    if (state === 'started') return true

    return this.trialSubscription.hide
  }
}
