import { makeAutoObservable } from 'mobx'
import type { ITenDlcLog } from 'entities/TenDlc/api/type'
import { TenDlcApi } from 'entities/TenDlc'

export type ITenDlcLogAsRow = {
  id: number
  part: string
  status: string
  statusTitle: string
  actionBy: string
  date: string
}

const rejectedStatuses = [
  'REJECTED',
  'DEACTIVATED',
  'VENDOR_IMPORT_DECLINED',
  'SHARE_REQUEST_DECLINED',
]
const publishedStatuses = ['PUBLISHED', 'SUBMITTED', 'UPDATED']
const reviewedStatuses = [
  'FORM_FILLING',
  'REGISTERED_IN_TCR',
  'REGISTERED_IN_TWILIO',
  'VENDOR_IMPORT_STARTED',
  'SHARE_REQUEST_SEND',
]
const approvedStatuses = ['APPROVED']

export class TenDlcLogsStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _id: number | null = null
  private _tenDlcLogs: ITenDlcLog['data'] = []
  private _page = 1
  private _limit = 10
  private _total = 0

  get tenDlcLogs() {
    return this._tenDlcLogs
  }

  get tenDlcLogsAsRow() {
    return this.tenDlcLogs.map((item) => ({
      id: item.id,
      part: item.entity.title,
      status: this.getStatus(item.action.type),
      statusTitle: item.action.title,
      actionBy: item.action.by,
      date: item.action.date,
    }))
  }

  get id() {
    return this._id
  }

  get total() {
    return this._total
  }

  get limit() {
    return this._limit
  }

  init() {
    this.getTenDlcLogs()
  }

  getStatus = (statusType: string) => {
    switch (true) {
      case rejectedStatuses.includes(statusType):
        return 'rejected'
      case publishedStatuses.includes(statusType):
        return 'published'
      case reviewedStatuses.includes(statusType):
        return 'reviewed'
      case approvedStatuses.includes(statusType):
        return 'approved'
      default:
        return 'rejected'
    }
  }

  setIdAndInit(id: number) {
    this._id = id

    this.init()
  }

  getTenDlcLogs = async () => {
    if (!this.id) return

    const { data } = await TenDlcApi.getTenDlcLogs({
      organization_id: String(this.id),
      page: this._page,
      per_page: this._limit,
    })

    this._tenDlcLogs = data.data
    this._total = data.meta.total
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this._page = page
    this._limit = limit

    this.getTenDlcLogs()
  }
}
