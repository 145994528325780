import { nanoid } from 'nanoid'
import { configStore } from 'entities/Config'
import { organizationStore } from 'entities/Organization'
import { usersStore } from 'entities/Users'
import { numbersStore } from 'entities/Phone'
import { subscriptionStore } from 'entities/Subscription'

const payload = () => {
  const user = usersStore.user
  const number = numbersStore.organizationNumber
  const config = configStore.config
  const plan = subscriptionStore.plan

  if (!user || !number || !config || !plan || !organizationStore.id) {
    return {
      anonymous_id: nanoid(),
      company: {
        id: 0,
      },
    }
  }

  const company = {
    id: organizationStore.id,
    name: organizationStore.name,
    createdAt: organizationStore.created_at,
    ownerFirstName: user.first_name,
    ownerLastName: user.last_name,
    ownerName: user.full_name,
    phone: number?.formatted_number,
    plan: plan.id,
    planStatus: subscriptionStore.status,
  }

  return {
    email: user.email,
    id: String(user.id),
    user_id: String(user.id),
    created_at: user.createdAt,
    name: user.name,
    phone: number.formatted_number,
    avatar: user.avatarInfo.image,
    user_hash: config.intercom,
    company,
  }
}

export const initIntercom = () => {
  if (!window.dataLayer) return

  window.dataLayer.push({ event: 'intercom_init', intercom_payload: payload() })
}

export const updateIntercom = () => {
  if (!window.Intercom) return

  window.Intercom('update', payload())
}
