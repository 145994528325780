import { makeAutoObservable } from 'mobx'
import { debounce } from 'lodash'
import { AxiosError } from 'axios'
import { EnumDropdownItemVariant, type IDropdownItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { isLink, logger } from 'shared/lib'
import type {
  IResponseCountryState,
  IResponseEinItem,
} from 'entities/Compliance/api/complianceType'
import { ComplianceApi } from 'entities/Compliance'
import { AuthApi } from 'entities/Auth/api'
import type { IResponseCountry } from 'entities/Auth'
import { EinItem } from 'pages/settings/pages/compliance/ui/EinItem/EinItem'
import type { TollFreeStepsStore } from 'pages/settings/pages/compliance/pages/tollFree/store/tollFreeStepsStore'
import styles from './styles.module.scss'

const maxZipLength = 5
const maxPostCodeLength = 6

export class BusinessInfoStore {
  private _generalInfoActiveTab = 2
  private _errorMessage = ''
  private _zipLocalErrorMessage: string | null = null
  private _einItem: IResponseEinItem | null = null
  private _einItems: IResponseEinItem[] = []
  private _einSearch = ''
  private _maxEinLength = 9
  private _websiteUrl: string | null = null
  private _socialMediaUrl: string | null = null
  private _noWebsiteUrl = false
  private _websiteUrlLocalError = ''
  private _socialMediaUrlError = ''
  private _activeCountryId = ''
  private _activeCountryLabel: string | null = ''
  private _states: IResponseCountryState[] = []
  private _countries: IResponseCountry[] = []
  private _debouncedFetchEinData: (search: string) => void

  constructor(private _tollFreeStepsStore: TollFreeStepsStore) {
    makeAutoObservable(this)
    this._debouncedFetchEinData = debounce(this.fetchEinData, 300)
  }

  init = async () => {
    await this.loadBusinessInfoStates()
    await this.loadBusinessInfoCountries()
  }

  get einItem() {
    return this._einItem
  }

  get errorMessage() {
    return this._errorMessage
  }

  get zipLocalErrorMessage() {
    return this._zipLocalErrorMessage
  }

  get generalInfoActiveTab() {
    return this._generalInfoActiveTab
  }

  get isUSLocation() {
    return this._tollFreeStepsStore.complianceLayerStore?.businessInformation?.country === 'US'
  }

  get isHideAutoBusinessTab() {
    if (this._tollFreeStepsStore.complianceLayerStore?.businessInformation !== null) {
      return (
        !this.isUSLocation || !this._tollFreeStepsStore.complianceLayerStore.summary?.has_tax_id
      )
    }
    return !this._tollFreeStepsStore.complianceLayerStore.summary?.has_tax_id
  }

  get einItemList(): IDropdownItem[] {
    if (this._einItems.length) {
      return [
        {
          id: 0,
          label: this._einItems[0].name,
          className: styles.einItem,
          variant: EnumDropdownItemVariant.Custom,
          renderOption: () => <EinItem einItem={this._einItems[0]} />,
        },
      ]
    }
    return []
  }

  get einSearch() {
    if (this._einSearch.length <= 2) {
      return this._einSearch
    }

    return `${this._einSearch.slice(0, 2)}-${this._einSearch.slice(2)}`
  }

  get einFromSearch() {
    return this._einSearch
  }

  get websiteUrl() {
    return this._websiteUrl
  }

  get socialMediaUrl() {
    return this._socialMediaUrl
  }

  get noWebsiteUrl() {
    return this._noWebsiteUrl
  }

  get countries(): IDropdownItem[] {
    return this._countries.map((item) => ({ label: item.label, id: item.key }))
  }

  get states(): IDropdownItem[] {
    return this._states
      .filter((item) => item.country_id === this._activeCountryId)
      .map((item) => ({ label: item.name, id: item.id }))
  }

  get isWebsiteNeedFilled() {
    const socialMediaUrlRequired = this._noWebsiteUrl && !this._socialMediaUrl
    const noRequiredWebsiteUrl = !this._noWebsiteUrl && !this._websiteUrl

    if (
      this._websiteUrlLocalError ||
      this._socialMediaUrlError ||
      socialMediaUrlRequired ||
      noRequiredWebsiteUrl
    ) {
      return true
    }
    return false
  }
  get isNextDisabled() {
    if (this.generalInfoActiveTab === 2) {
      return !this.isManuallyItemFulled
    }
    if (this.generalInfoActiveTab === 1) {
      return this._einSearch.length !== 9 || this.isWebsiteNeedFilled
    }

    return false
  }

  get isManuallyItemFulled() {
    const keys = ['city', 'name', 'state', 'street1'] as const

    if (!this.isZipFulled || this._errorMessage || this.isWebsiteNeedFilled) return false

    return keys.every((key) => this._einItem?.[key]?.length)
  }

  get websiteUrlLocalError() {
    return this._websiteUrlLocalError
  }

  get socialMediaUrlError() {
    return this._socialMediaUrlError
  }

  get activeCountryId() {
    return this._activeCountryId
  }

  get activeCountryLabel() {
    return this._activeCountryLabel
  }

  get isActiveCountryUS() {
    return this._activeCountryLabel === 'United States'
  }

  get isActiveCountryCA() {
    return this._activeCountryLabel === 'Canada'
  }

  get activeCountryInfo() {
    if (this.isActiveCountryUS) {
      return {
        placeholder: 'e.g. Los Angeles',
        state: 'State',
        statePlaceholer: 'Select',
        code: 'ZIP code',
        codePlaceholder: 'e.g. 90001',
      }
    }
    if (this.isActiveCountryCA) {
      return {
        placeholder: 'e.g. Toronto',
        state: 'Province',
        statePlaceholer: 'Select',
        code: 'Postal code',
        codePlaceholder: 'e.g. M4R',
      }
    }
    return {
      placeholder: 'Enter city',
      state: 'Region',
      statePlaceholer: 'Enter region',
      code: 'Postal code',
      codePlaceholder: 'Enter postal code',
    }
  }

  setGeneralInfoActiveTab = (value: number) => {
    this._generalInfoActiveTab = value
  }

  changeGeneralInfoTab = (value: number) => {
    const isEinNumberExist =
      this._tollFreeStepsStore.complianceLayerStore.businessInformation?.registration_number
    if (!isEinNumberExist && value === 1) {
      this.resetData()
    }
    this.setGeneralInfoActiveTab(value)
  }

  resetData = () => {
    this._einItems = []
    this._websiteUrl = null
    this._socialMediaUrl = null
    this._einItem = null
    this._einSearch = ''
    this._errorMessage = ''
    this._zipLocalErrorMessage = null
    this._websiteUrlLocalError = ''
    this._socialMediaUrlError = ''
    this._noWebsiteUrl = false
  }

  setEinItem = (value: IResponseEinItem | null) => {
    this._einItem = value
  }

  setFirstEinItem = () => {
    if (this._einItems.length) {
      this.setEinItem(this._einItems[0])
    }
  }

  setEinSearch = (search: string) => {
    const stringWithoutDashes = search.replace(/[^0-9]/g, '')

    const isUsaLocationAuto = true && this.generalInfoActiveTab === 1
    const isNeedClear = stringWithoutDashes.length < this._maxEinLength && isUsaLocationAuto
    if (isNeedClear) {
      this._einItems = []
      this.setEinItem(null)
    }

    this.setErrorMessage('')

    if (stringWithoutDashes.length > this._maxEinLength) return

    this._einSearch = stringWithoutDashes

    if (this.isNeedCheckEin(stringWithoutDashes)) {
      this.checkEinNumber()
    }
  }

  setErrorMessage = (message: string) => {
    this._errorMessage = message
  }

  isNeedCheckEin = (stringWithoutDashes: string) => {
    const isUsaLocationAuto = true && this.generalInfoActiveTab === 1

    return isUsaLocationAuto && stringWithoutDashes.length === this._maxEinLength
  }

  checkEinNumber = () => {
    if (this._einSearch.length && !this._einItem?.ein) this._debouncedFetchEinData(this._einSearch)
  }

  setWebsiteUrlLocalError = (error: string) => {
    this._websiteUrlLocalError = error
  }

  setSocialMediaUrlError = (error: string) => {
    this._socialMediaUrlError = error
  }

  setWebsiteUrl = (url: string | null) => {
    this._tollFreeStepsStore?.deleteErrorValue('website_url')

    if (this._websiteUrlLocalError) {
      this.setWebsiteUrlLocalError('')
    }

    this._websiteUrl = url
  }

  handleSetNoWebsiteUrl = () => {
    this.setWebsiteUrlLocalError('')
    this.setSocialMediaUrlError('')

    this.setWebsiteUrl(null)
    this.setSocialMediaUrl(null)
    this.setNoWebsiteUrl(!this._noWebsiteUrl)
  }

  validateWebsiteUrl = (url: string, type: 'websiteUrl' | 'socialMediaUrl') => {
    const isWebsite = isLink(url, 3)
    const errorText = 'Please enter a valid url (e.g. https://www.example.com/)'

    if (!isWebsite) {
      type === 'websiteUrl'
        ? this.setWebsiteUrlLocalError(errorText)
        : this.setSocialMediaUrlError(errorText)
    }
  }

  setSocialMediaUrl = (url: string | null) => {
    this._tollFreeStepsStore?.deleteErrorValue('social_media_url')
    if (this._socialMediaUrlError) {
      this.setSocialMediaUrlError('')
    }

    this._socialMediaUrl = url
  }

  setNoWebsiteUrl = (value: boolean) => {
    this._noWebsiteUrl = value
  }

  addBusinessNameManually = (name: string) => {
    this._tollFreeStepsStore?.deleteErrorValue('business_name')

    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }
    this._einItem.name = name
  }

  addBusinessAddressManually = (street: string) => {
    this._tollFreeStepsStore?.deleteErrorValue('street_address_1')

    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }
    this._einItem.street1 = street
  }

  addBusinessAddress2Manually = (street: string) => {
    this._tollFreeStepsStore?.deleteErrorValue('street_address_2')

    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }
    this._einItem.street2 = street
  }

  addCityManually = (city: string) => {
    this._tollFreeStepsStore?.deleteErrorValue('city')

    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }

    this._einItem.city = city
  }

  setActiveCountry = (countryId: string) => {
    this._activeCountryId = countryId
    this._activeCountryLabel = this.countries.find((country) => country.id === countryId)
      ?.label as string

    this.resetAdressError()
  }

  resetAdressError = () => {
    this._tollFreeStepsStore?.deleteErrorValue('street_address_1')
    this._tollFreeStepsStore?.deleteErrorValue('street_address_2')
    this._tollFreeStepsStore?.deleteErrorValue('city')
    this._tollFreeStepsStore?.deleteErrorValue('zip_code')
    this.setZipLocalErrorMessage('')
  }

  get isZipFulled() {
    if (this.isActiveCountryUS) {
      return this._einItem?.zip?.length === maxZipLength
    }
    if (this.isActiveCountryCA) {
      return this._einItem?.zip?.length === maxPostCodeLength
    }
    return (this._einItem?.zip?.length as number) >= 1
  }

  get zipCode() {
    const zip = this._einItem?.zip

    if (this.isActiveCountryUS || !zip) {
      return zip
    }

    if (this.isActiveCountryCA) {
      return zip.length < 4 ? zip : `${zip.slice(0, 3)} ${zip.slice(3)}`
    }

    return zip
  }

  addEinManually = (ein: string) => {
    this._einSearch = ein
  }

  addBusinessStateManually = (state: string) => {
    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }

    this._einItem.state = state
  }

  addZipManually = (zip: string) => {
    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }

    this._tollFreeStepsStore?.deleteErrorValue('zip_code')
    this.setZipLocalErrorMessage(null)

    if (this.isActiveCountryUS) {
      const clearedZip = zip.replace(/[^0-9]/g, '')

      if (clearedZip.length > maxZipLength) return

      this._einItem.zip = clearedZip
    } else if (this.isActiveCountryCA) {
      const clearedZip = zip.replace(/\s+/g, '')

      if (clearedZip.length > maxPostCodeLength) return

      this._einItem.zip = clearedZip
    } else {
      this._einItem.zip = zip
    }
  }

  setZipLocalErrorMessage = (message: string | null) => {
    this._zipLocalErrorMessage = message
  }

  checkZipLengthManually = () => {
    const zipLength = this._einItem?.zip?.length
    const validLength = this.isActiveCountryUS ? maxZipLength : maxPostCodeLength

    if ((!this.isActiveCountryCA || !this.isActiveCountryUS) && zipLength === 0) {
      this.setZipLocalErrorMessage('Please enter a valid code')
    }

    if ((this.isActiveCountryCA || this.isActiveCountryUS) && zipLength !== validLength) {
      this.setZipLocalErrorMessage('Please enter a valid zip code')
    }
  }

  setStates = (states: IResponseCountryState[]) => {
    this._states = states
  }

  setCountries = (countries: IResponseCountry[]) => {
    this._countries = countries
  }

  fetchEinData = async (search: string) => {
    try {
      const { data } = await ComplianceApi.getEinData({ ein: search })

      this.addEin(data.data)
      this.setErrorMessage('')
    } catch (error) {
      if (error instanceof AxiosError) {
        this.setErrorMessage(error.response?.data.message || '')
      }
    }
  }

  addEin = (einList: IResponseEinItem[]) => {
    this._einItems = einList
  }

  loadBusinessInfoStates = async () => {
    try {
      const { data } = await ComplianceApi.getBpStates()
      this.setStates(data)
    } catch (e) {
      logger.error(e)
    }
  }

  loadBusinessInfoCountries = async () => {
    try {
      const { data } = await AuthApi.getCountries()
      this.setCountries(data)
    } catch (e) {
      logger.error(e)
    }
  }

  nextStepClick = async () => {
    uiStore.changeRoute({
      path: this._tollFreeStepsStore.tollFreeNavigationStore.getNextUrl,
    })
  }
}
