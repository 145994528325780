import { ContactsRoutes } from './type'

const contacts = `${ContactsRoutes.root}`
const contactsHistory = `${contacts}/${ContactsRoutes.history}`
const contactsCleanup = `${contacts}/${ContactsRoutes.cleanup}`

export const ContactsPath = {
  root: contacts,
  history: contactsHistory,
  cleanup: contactsCleanup,
}
