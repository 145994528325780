import { logger } from 'shared/lib'
import { UpgradePlanModalStore } from 'entities/Billing/ui/UpgradePlanModal'
import {
  complianceStore,
  type IResponseOrganizationShakenStir,
  type IResponseTenDlcVoiceIntegrity,
  type IResponseTollFree,
} from 'entities/Compliance'
import { billingStore } from 'entities/Billing'
import type { IResponseBillingComplianceInfo } from 'entities/Billing/api/types'
import type { IResponseOrganizationCnam } from 'entities/Compliance/api/cnamType'
import { TrialNumbersLimitModalStore } from 'entities/Subscription/ui/TrialNumbersLimitModal'
import { TrialInboxesLimitModalStore } from 'entities/Subscription/ui/TrialInboxesLimitModal'
import {
  GoToQuestionnaireModalStore,
  IGoToQuestionnaireModalType,
} from 'pages/settings/pages/compliance/ui/GoToQuestionnaireModal'

window.publicApiReact = {
  openGoToQuestionnaireModal: (type: IGoToQuestionnaireModalType) => {
    if (!type) {
      return logger.error(
        "type: numbers | inboxes | members (example: openGoToQuestionnaireModal('members'))"
      )
    }

    new GoToQuestionnaireModalStore().open(type)
  },

  openUpgradePlanModalFinishStore: (planId: string) => {
    if (!planId) {
      return logger.error(
        "planId: string (example: openUpgradePlanModalFinishStore('pro-yearly-3000'))"
      )
    }

    new UpgradePlanModalStore().openFinishModal(planId)
  },

  openTrialNumbersLimitModal: () => {
    new TrialNumbersLimitModalStore().open()
  },

  openTrialInboxesLimitModal: () => {
    new TrialInboxesLimitModalStore().open()
  },

  Compliance: {
    addTollFreeProfiles: (responseItems?: IResponseTollFree[]) => {
      if (!responseItems?.length) return

      complianceStore.organizationTollFree?.addItems(responseItems)
    },

    addTollFreeProfile: (responseItem?: IResponseTollFree) => {
      if (!responseItem) return

      complianceStore.organizationTollFree?.addItem(responseItem)
    },

    deleteTollFreeProfile: (id: number) => {
      complianceStore.organizationTollFree?.deleteItem(id)
    },

    updateOrganizationVoiceIntegrity: (data: IResponseTenDlcVoiceIntegrity) => {
      complianceStore.setOrganizationVoiceIntegrity(data)
    },

    updateOrganizationShakenStir: (data: IResponseOrganizationShakenStir) => {
      complianceStore.setOrganizationShakenStir(data)
    },

    updateOrganizationCham: (data: IResponseOrganizationCnam) => {
      complianceStore.cnamStore.organizationCnam?.syncData(data)
    },

    deleteCnamItem: (id: number) => {
      complianceStore.cnamStore.organizationCnam?.deleteItem(id)
    },
  },
  Billing: {
    updateBillingComplianceInfo: (response: IResponseBillingComplianceInfo) => {
      billingStore.setBillingComplianceInfo(response)
    },
  },
}
