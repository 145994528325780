import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { PageLayout } from 'shared/ui'
import { contactsStore } from 'entities/Contacts'
import { ContactsMenu } from 'pages/contacts/ui/ContactsMenu'
import {
  ProviderContactsPageLayout,
  useContactsPageLayoutContext,
} from 'pages/contacts/context/contactsPageLayoutContext'
import { ContactsPageLayoutStore } from 'pages/contacts/store/contactsPageLayoutStore'

const ContactsLayoutContent = observer(() => {
  const { contactsMenuStore, pageLayoutStore } = useContactsPageLayoutContext()

  useEffect(() => {
    return () => {
      contactsStore.reset()
    }
  }, [])

  return (
    <PageLayout store={pageLayoutStore} leftContent={<ContactsMenu store={contactsMenuStore} />} />
  )
})

export const ContactsLayout = () => {
  const contactsPageLayoutStore = useMemo(() => new ContactsPageLayoutStore(), [])

  return (
    <ProviderContactsPageLayout value={contactsPageLayoutStore}>
      <ContactsLayoutContent />
    </ProviderContactsPageLayout>
  )
}
