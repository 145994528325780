import { makeAutoObservable } from 'mobx'
import { formatPhoneNumberNationalNumber } from 'shared/lib'
import { type IAvatarInfo } from 'shared/ui'
import { type ICampaignExecution } from 'entities/Campaign'
import { Tag } from 'entities/Tags/model/Tag'

export class CampaignContact {
  tags
  constructor(public origin: ICampaignExecution['contact']) {
    this.tags = origin.tags.map((item) => new Tag(item))
    makeAutoObservable(this)
  }

  get id() {
    return this.origin.id
  }

  get number() {
    return this.origin.number
  }

  get fullName() {
    return this.origin.full_name
  }

  get avatarInfo(): IAvatarInfo {
    return {
      firstName: this.origin.first_name,
      lastName: this.origin.last_name,
      number: this.origin.number && formatPhoneNumberNationalNumber(this.origin.number),
      image: this.origin.photo_url,
      color: this.origin.color,
    }
  }

  get isDisabled() {
    return !!this.origin.archived_at
  }
}
