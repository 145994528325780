import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Switch, Typography } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { TextField } from 'shared/ui/TextField'
import type { IntegrationWebhookStore } from 'pages/settings/pages/integrations/store/IntegrationWebhookStore'
import { TitledRow } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/TitledRow/TitledRow'
import { WebhookCopyButton } from './WebhookCopyButton'
import styles from './styles.module.scss'

type IIntegrationSettingsWebhookProps = {
  store: IntegrationWebhookStore
  withLeadOwner?: boolean
}

export const IntegrationSettingsWebhook: FC<IIntegrationSettingsWebhookProps> = observer(
  ({ store, withLeadOwner = false }) => {
    const {
      webhookUrl,
      sendFromLeadOwner,
      handleSendFromLeadOwnerToggle,
      phoneFieldsItems,
      handleSelectedPhoneFieldChange,
      selectedPhoneField,
      integrationName,
    } = store

    return (
      <div className={styles.root}>
        <TitledRow
          title={`Use this Webhook URL on ${integrationName} to send Contacts into your Salesmsg account. This will:`}
          label={
            <ul>
              <li>Create a Contact on your account if one does not exist.</li>
              <li>Link the Contact to it&apos;s counterpart on {integrationName}.</li>
            </ul>
          }
        />
        <div />
        {withLeadOwner && (
          <>
            <TitledRow
              title={'Create Contact on Lead Owner'}
              tooltip={`You can choose to have this Contact assigned to it's owner on ${integrationName} by selecting Lead Owner toggle below. Otherwise the Contact will be assigned to you.`}
            >
              <Switch
                size='middle'
                value={sendFromLeadOwner}
                onChange={handleSendFromLeadOwnerToggle}
              />
            </TitledRow>
            <div className={styles.divider} />
          </>
        )}
        <TitledRow
          title={'Phone field'}
          tooltip={
            'Be default we have selected the standard phone number field. If you want this Webhook URL to use a different field, select a new one from the dropdown before copying the URL.'
          }
          align={'normal'}
        >
          <SelectField
            size={'medium'}
            withSearch={false}
            items={phoneFieldsItems}
            value={selectedPhoneField}
            setValue={handleSelectedPhoneFieldChange}
            selectProps={{
              placeholder: selectedPhoneField,
            }}
          />
        </TitledRow>
        <div className={styles.divider} />
        <Typography ariaLabel={'label'} variant={'body-md-medium'}>
          Webhook URL
        </Typography>
        <div className={styles.urlForm}>
          <TextField
            className={styles.input}
            size={'medium'}
            variant={'stroke'}
            value={webhookUrl}
            InputProps={{
              placeholder: 'Webhook URL',
            }}
          />
          <WebhookCopyButton url={webhookUrl} />
        </div>
      </div>
    )
  }
)
