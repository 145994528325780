import { makeAutoObservable, runInAction } from 'mobx'
import { logger } from 'shared/lib'
import {
  IParamsCreateCustomVoice,
  IParamsCreateSpeech,
  IParamsGetSpeechPreview,
  IResponseVoice,
  ISpeechPreview,
  IVoiceType,
  TextToSpeechApi,
} from 'entities/TextToSpeech'

class TextToSpeechStore {
  constructor() {
    makeAutoObservable(this)
  }

  basicVoices: IResponseVoice[] = []
  premiumVoices: IResponseVoice[] = []
  customVoices: IResponseVoice[] = []

  loadingBasic = false
  loadingPremium = false
  loadingCustom = false

  previewMap: Map<string, string> = new Map()
  loadingPreviewMap: Map<string, ReturnType<(typeof TextToSpeechApi)['getVoicePreview']>> =
    new Map()
  loadingPreviewSpeechesMap: Map<string, ReturnType<(typeof TextToSpeechApi)['getSpeechPreview']>> =
    new Map()

  speechesPreviewMap: Map<string, ISpeechPreview> = new Map()

  loadBasicVoices = async () => {
    if (this.basicVoices.length) return
    if (this.loadingBasic) return
    try {
      this.loadingBasic = true
      const { data } = await TextToSpeechApi.getBasicVoices()
      runInAction(() => {
        this.basicVoices = data.payload
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loadingBasic = false
      })
    }
  }
  loadPremiumVoices = async () => {
    if (this.premiumVoices.length) return
    if (this.loadingPremium) return
    try {
      this.loadingPremium = true
      const { data } = await TextToSpeechApi.getPremiumVoices()
      runInAction(() => {
        this.premiumVoices = data.payload
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loadingPremium = false
      })
    }
  }
  loadCustomVoices = async () => {
    if (this.customVoices.length) return
    if (this.loadingCustom) return
    try {
      this.loadingCustom = true
      const { data } = await TextToSpeechApi.getCustomVoices()
      runInAction(() => {
        this.customVoices = data.payload
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loadingCustom = false
      })
    }
  }

  getVoicePreview = async (id: string, type: IVoiceType): Promise<string | undefined> => {
    const promise = this.loadingPreviewMap.get(id)
    if (promise) {
      await promise
    }
    const preview = this.previewMap.get(id)
    if (preview) return preview
    try {
      const promise = TextToSpeechApi.getVoicePreview(id, type)
      this.loadingPreviewMap.set(id, promise)
      const { data } = await promise
      runInAction(() => {
        this.previewMap.set(id, data.payload)
        this.loadingPreviewMap.delete(id)
      })

      return data.payload
    } catch (e) {
      logger.error(e)
    }
  }

  markAsInvalidPreview = (id: string) => {
    this.previewMap.delete(id)
  }

  getSpeechPreview = async (
    params: IParamsGetSpeechPreview
  ): Promise<ISpeechPreview | undefined> => {
    const speechKey = `${params.voice_id}_${params.text.trim()}`
    const promise = this.loadingPreviewSpeechesMap.get(speechKey)
    if (promise) {
      await promise
    }
    const audioSpeech = this.speechesPreviewMap.get(speechKey)
    if (audioSpeech) return audioSpeech
    try {
      const promise = TextToSpeechApi.getSpeechPreview(params)
      this.loadingPreviewSpeechesMap.set(speechKey, promise)
      const { data } = await promise
      const src = URL.createObjectURL(data)
      const preview: ISpeechPreview = {
        src,
        duration: 0,
      }
      runInAction(() => {
        this.speechesPreviewMap.set(speechKey, preview)
        const audio = new Audio()

        audio.onloadedmetadata = () => {
          runInAction(() => {
            this.speechesPreviewMap.set(speechKey, {
              src,
              duration: audio.duration,
            })
          })
        }
        audio.src = src

        this.loadingPreviewSpeechesMap.delete(speechKey)
      })

      return preview
    } catch (e) {
      logger.error(e)
    }
  }

  createSpeech = async (params: IParamsCreateSpeech): Promise<File | undefined> => {
    try {
      const {
        data: { payload },
      } = await TextToSpeechApi.createSpeech(params)
      const { data } = await TextToSpeechApi.streamSpeech(payload.id)
      return data
    } catch (e) {
      logger.error(e)
    }
  }

  createCustomVoice = async (
    params: IParamsCreateCustomVoice
  ): Promise<IResponseVoice | undefined> => {
    try {
      const {
        data: { payload },
      } = await TextToSpeechApi.createCustomVoice(params)
      if (payload) {
        runInAction(() => {
          this.customVoices = [...this.customVoices, payload]
        })
      }
      return payload
    } catch (e) {
      logger.error(e)
    }
  }

  deleteCustomVoice = async (voice_id: string): Promise<void> => {
    try {
      await TextToSpeechApi.deleteCustomVoice(voice_id)
      runInAction(() => {
        this.customVoices = this.customVoices.filter((voice) => voice.id !== voice_id)
      })
    } catch (e) {
      logger.error(e)
    }
  }
}

export const textToSpeechStore = new TextToSpeechStore()
