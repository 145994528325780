import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import classnames from 'classnames'
import { Alert, Button, ITabItem, Tabs } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { SegmentBox } from 'entities/Segment'
import { type FilterEditorStore } from 'widgets/FilterEditor'
import { EnumEnrollmentMode } from 'widgets/ContactEnrollment/store/types'
import { type EnrollmentListStore } from '../store'
import { EnrollmentContactsTable } from './EnrollmentContactsTable'
import { EnrollmentInclude } from './EnrollmentInclude/EnrollmentInclude'
import styles from './styles.module.scss'
import { EnrollmentActionsProvider } from './EnrollmentActions'

export const getSelectTitle = (count: number) => {
  switch (true) {
    case count === 1:
      return 'Select contact'
    case count > 1:
      return `Select ${count} contacts`
    default:
      return 'Select contacts'
  }
}

export interface IEnrollmentModalProps {
  enrollmentListStore: EnrollmentListStore
  filterEditorStore: FilterEditorStore
  segmentBox: SegmentBox
  onConfirm: () => void
  onCancel: () => void
  onClose: () => void
  mode: EnumEnrollmentMode
  allowEmptyCount?: boolean
}

export const EnrollmentModal = observer(
  ({
    mode,
    allowEmptyCount,
    enrollmentListStore,
    filterEditorStore,
    segmentBox,
    onConfirm,
    onCancel,
    onClose,
  }: IEnrollmentModalProps) => {
    const includeTab = useMemo<ITabItem<'include'>>(
      () => ({
        key: 'include',
        name: 'Include',
      }),
      []
    )
    const contactTab = useMemo<ITabItem<'contacts'>>(
      () => ({
        key: 'contacts',
        name: 'Contacts',
        count: enrollmentListStore.allTotalCount,
        isFullCount: true,
      }),
      [
        enrollmentListStore.allTotalCount,
        enrollmentListStore.existHubspotFilter,
        enrollmentListStore.hubspotFiltersCount,
      ]
    )
    const [tabActive, setTabActive] = useState<'include' | 'contacts'>('include')
    const selectTitle = getSelectTitle(enrollmentListStore.allTotalCount)

    const isEmptyFilters = !enrollmentListStore.filters.allSize
    const isEmptyCount = allowEmptyCount ? false : !enrollmentListStore.allTotalCount
    const selectDisabled =
      enrollmentListStore.hasHubspotFilterSelected && isEmptyCount && !enrollmentListStore.loading
        ? false
        : isEmptyCount || isEmptyFilters || enrollmentListStore.loading

    return (
      <EnrollmentActionsProvider
        onCancel={onCancel}
        onConfirm={onConfirm}
        selectTitle={selectTitle}
        selectDisabled={selectDisabled}
      >
        {mode === EnumEnrollmentMode.ViewOnly ? (
          <div className={styles.rootContainer}>
            <EnrollmentInclude
              filterEditorStore={filterEditorStore}
              enrollmentListStore={enrollmentListStore}
              segmentBox={segmentBox}
              mode={mode}
              showList
            />
          </div>
        ) : (
          <div className={classnames(styles.rootContainer)}>
            <Tabs
              tabs={[includeTab, contactTab]}
              activeTabKey={tabActive}
              marginInlineTab={24}
              variant={'divider'}
              handleSelectTab={(value) => setTabActive(value.key)}
            />
            {enrollmentListStore.hasHubspotFilterSelected && (
              <div className={classnames(styles.bannerContainer)}>
                <Alert
                  item={{
                    type: 'infoLight',
                    title: 'Your HubSpot list may be outdated',
                    desc: 'The number of contacts shown is based on the last sync. If you’ve updated the list in HubSpot, sync now to see the latest count. Otherwise, the sync will happen automatically when you send the broadcast.',
                  }}
                  rightAction={
                    <Button
                      size='small'
                      typeBtn='outlined'
                      contained='secondary'
                      text='Manage HubSpot lists'
                      className={styles.alertButton}
                      onClick={() => {
                        uiStore.changeRoute({
                          path: '/settings/integrations/hubspot/settings',
                        })
                        onClose()
                      }}
                    />
                  }
                />
              </div>
            )}
            {tabActive === 'include' && (
              <EnrollmentInclude
                filterEditorStore={filterEditorStore}
                enrollmentListStore={enrollmentListStore}
                segmentBox={segmentBox}
                mode={mode}
                showList
              />
            )}
            {tabActive === 'contacts' && (
              <EnrollmentContactsTable enrollmentListStore={enrollmentListStore} />
            )}
          </div>
        )}
      </EnrollmentActionsProvider>
    )
  }
)
