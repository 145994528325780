import { isCancel } from 'axios'
import pino from 'pino'

const log = pino({ browser: { asObject: true, serialize: true } })

export const logger = {
  info: (value: string) => {
    log.info(value)
  },

  error: (value: string | unknown) => {
    if (isCancel(value)) return
    log.error(value)
  },

  warn: (value: string) => {
    log.warn(value)
  },

  debug: (value: string) => {
    log.debug(value)
  },

  trace: (value: string) => {
    log.trace(value)
  },
}
