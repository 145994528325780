import { makeAutoObservable } from 'mobx'
import { BreakpointsEnum } from 'shared/constants/breakpoints'

export class LayoutStore {
  activeBreakpoint: BreakpointsEnum | null = null

  constructor() {
    makeAutoObservable(this)
  }

  handleActiveBreakpoint = (breakpoint: BreakpointsEnum) => {
    this.activeBreakpoint = breakpoint
  }

  get isViewXs() {
    return this.activeBreakpoint === BreakpointsEnum.XS
  }

  get isMobileView() {
    return (
      this.activeBreakpoint === BreakpointsEnum.XS ||
      this.activeBreakpoint === BreakpointsEnum.S ||
      this.activeBreakpoint === BreakpointsEnum.M
    )
  }
  get isLargeView() {
    return (
      this.activeBreakpoint === BreakpointsEnum.L || this.activeBreakpoint === BreakpointsEnum.XL
    )
  }
  get isMobileViewWithoutMSize() {
    return (
      this.activeBreakpoint === BreakpointsEnum.XS || this.activeBreakpoint === BreakpointsEnum.S
    )
  }
}

export const layoutStore = new LayoutStore()
