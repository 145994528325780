import { captureMessage, isInitialized } from '@sentry/react'

export type ISentrySentEventProps = {
  event: string
  level: 'fatal' | 'error' | 'warning' | 'log' | 'debug' | 'info'
  name: string
  options?: {
    [key in string]: string | boolean | number
  }
}

export const sentEventSentry = ({ event, level, name, options }: ISentrySentEventProps) => {
  if (!isInitialized()) return null

  captureMessage(event, {
    level: level,
    tags: {
      'app.service': name,
      ...options,
    },
  })
}
