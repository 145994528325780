import { PropsWithChildren } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

export const RootLayout = ({ children }: PropsWithChildren) => (
  <section className={styles.rootLayout}>{children}</section>
)

export const DialogLayout = ({ children, active }: PropsWithChildren & { active?: boolean }) => (
  <section className={styles.dialogLayout} {...(active ? { ['data-active']: '' } : {})}>
    <div className={styles.dialogContent}>{children}</div>
  </section>
)

export const ChatbotLayout = ({ children }: PropsWithChildren) => (
  <section className={styles.chatbotLayout}>{children}</section>
)

export const ChatbotLayoutWrapper = ({ children }: PropsWithChildren) => (
  <div className={styles.layoutWrapper}>{children}</div>
)

interface IChatbotLayoutContentProps extends PropsWithChildren {
  isDraggingCondition?: boolean
}

export const ChatbotLayoutContent = ({
  children,
  isDraggingCondition,
}: IChatbotLayoutContentProps) => (
  <section
    className={classNames(styles.layoutContent, { [styles.isDragging]: isDraggingCondition })}
  >
    {children}
  </section>
)

export const ChatbotLayoutHeader = ({ children }: PropsWithChildren) => (
  <header className={styles.layoutHeader}>{children}</header>
)

export const ChatbotLayoutHead = ({ children }: PropsWithChildren) => (
  <div className={styles.layoutHead}>{children}</div>
)

export const ChatbotLayoutFooter = ({ children }: PropsWithChildren) => (
  <footer className={styles.layoutFooter}>{children}</footer>
)
