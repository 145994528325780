import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type SegmentModalStore } from 'pages/contacts/ui/ContactsMenu/ui/SegmentModal'
import { ModalActions } from './ModalActions'

type IEditSegmentActionsProps = {
  segmentModalStore: SegmentModalStore
}

export const EditSegmentActions: FC<IEditSegmentActionsProps> = observer(
  ({ segmentModalStore }) => {
    return (
      <ModalActions
        successText='Save'
        successAction={() => {
          segmentModalStore.onActionEdit()
        }}
        segmentModalStore={segmentModalStore}
      />
    )
  }
)
