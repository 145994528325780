import { makeAutoObservable } from 'mobx'

type SettingSectionUnion =
  | 'sync'
  | 'webhook'
  | 'users'
  | 'property'
  | 'timeline'
  | 'inbox'
  | 'installation'
  | 'lists'

export class IntegrationCollapseSettingsStore {
  private _openedSection: Null<SettingSectionUnion> = null

  constructor() {
    makeAutoObservable(this)
    this.init()
  }

  private init = () => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    this._openedSection = (params.get('opened') as SettingSectionUnion) || null
  }

  onToggle = (key: Null<SettingSectionUnion>) => () => {
    this._openedSection = this._openedSection === key ? null : key
  }

  opened = (key: SettingSectionUnion) => {
    return key === this._openedSection
  }
}
