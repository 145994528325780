import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { showToast } from 'shared/ui'
import { logger } from 'shared/lib'
import type { Contact } from 'entities/Contacts/model/Contact'
import type { IParamsFiltersListGroups } from 'entities/Broadcast/api/types'
import { IContactsDataParams, IParamsContacts } from 'entities/Contacts/api/types'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { numbersStore } from 'entities/Phone'
import { inboxesStore } from 'entities/Inbox'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import { eventLogStore } from 'entities/EventLog'
import { ContactFiltersApi } from 'entities/Contacts/api/contactsFilters'
import { PowerDialerApi } from 'entities/PowerDialer/api/powerDialer'
import { PowerDialerStatus } from 'entities/PowerDialer/api/types'
import { PowerDialer } from 'entities/PowerDialer/model/PowerDialer'
import { PowerDialerEditorModal } from 'widgets/PowerDialer/ui/PowerDialerEditor/ui/PowerDialerEditorModal/PowerDialerEditorModal'
import { callPopUpGlobalStore } from 'widgets/CallPopUp'
import { EnrollmentFilters, EnrollmentStore } from 'widgets/ContactEnrollment'
import { NumbersInboxesListStore } from 'widgets/NumbersInboxesList'
import { EnumNumbersInboxesDropDown } from 'widgets/NumbersInboxesList/store'
import { getToastConfigByPowerDialerStatus } from 'widgets/PowerDialer/lib/getToastConfigByPowerDialerStatus'
import {
  DEFAULT_CONTACTS_LIMIT,
  DEFAULT_CONTACTS_LIMIT_LABEL,
  POWER_DIALER_EMPTY_NAME_ERROR,
  TRIAL_CONTACTS_LIMIT,
  TRIAL_CONTACTS_LIMIT_LABEL,
} from 'widgets/PowerDialer/lib/constants'

type IPowerDialerEditorType = 'fromContacts' | 'fromFilters'

export class PowerDialerEditorStore {
  private _type: IPowerDialerEditorType = 'fromContacts'
  private _contactsMap: Map<number, Contact> = new Map()
  private _name = ''
  private _nameError = ''
  private _modalId = ''
  private _showAlert = true
  private _loading = false
  private _draftLoading = false
  private _callLoading = false
  private _step = 1

  private _enrollmentFilters = EnrollmentFilters.Empty()
  private _enrollmentStore = new EnrollmentStore({
    filterConfig: {
      makeRequest: async () => {
        const { data } = await ContactFiltersApi.getFilters({
          withCustomFields: true,
          workflowSource: 'core',
          skipValues: ['tag'],
        })
        return data
      },
    },
  })

  private _numbersInboxesListStore = new NumbersInboxesListStore({
    variant: EnumNumbersInboxesDropDown.Call,
    excludeSIPTrunk: true,
    excludeAircall: true,
  })

  private _disposeChangeTitleModal: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)

    this._reactionChangeTitleModal()
  }

  private _reactionChangeTitleModal = () => {
    this._disposeChangeTitleModal?.()
    this._disposeChangeTitleModal = reaction(
      () => this.titleModal,
      (value) => {
        this._enrollmentStore.setTitleModal(value)
        this._setTitleModal(value)
      }
    )
  }

  private _setTitleModal = ({
    title,
    titleDividerContent,
  }: {
    title: string
    titleDividerContent: string
  }) => {
    const modal = modalStore.getModal(this._modalId)

    if (modal) {
      if ('title' in modal) {
        modal.title = title
      }
      if ('titleDividerContent' in modal) {
        modal.titleDividerContent = titleDividerContent
      }
    }
  }

  private _openPowerDialerEditorModal = () => {
    this._modalId = nanoid()

    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.DEFAULT,
      title: this.titleModal.title,
      titleDividerContent: this.titleModal.titleDividerContent,
      width: 560,
      showCloseButton: false,
      showCloseIcon: false,
      paddingContent: '0px 24px 24px',
      ModalContent: PowerDialerEditorModal,
      ModalContentProps: {
        powerDialerStore: this,
      },
      onClose: () => {
        this.reset()
        modalStore.removeModal(this._modalId)
      },
    })
  }

  private _fetchContactsList = async (params: IParamsContacts) => {
    try {
      const { data } = await ContactsApi.getContactsList(params)
      return contactsStore.addItems(data.data)
    } catch (e) {
      console.log(e)

      return []
    }
  }

  private _fetchContactsByIds = async (contactIds: number[]) => {
    try {
      return (await contactsStore.getByIds(contactIds)) ?? []
    } catch (e) {
      console.log(e)

      return []
    }
  }

  private _isLimitExceeded = (total: number) => {
    if (subscriptionStore.isTrial && total > TRIAL_CONTACTS_LIMIT) {
      showToast({
        title: TRIAL_CONTACTS_LIMIT_LABEL,
        type: 'error',
      })

      return true
    }

    if (total > DEFAULT_CONTACTS_LIMIT) {
      showToast({
        title: DEFAULT_CONTACTS_LIMIT_LABEL,
        type: 'error',
      })

      return true
    }
  }

  private _setType = (value: IPowerDialerEditorType) => {
    this._type = value
  }

  private _setSelectedContacts = (contacts: Contact[]) => {
    contacts.forEach((contact) => this._contactsMap.set(contact.id, contact))
  }

  onChangeShowAlert = (value: boolean) => {
    this._showAlert = value
  }

  private _setDefaultInbox = () => {
    const inbox = inboxesStore.currentInbox
    if (!inbox) return

    if (inbox.type === 'inbox') {
      const numberId = inbox.isSmartInbox ? 0 : inbox.numberId
      if (inbox.is_aircall || inbox.isCallViaAircall) return

      this._numbersInboxesListStore.setActiveNumber(inbox.id, numberId)
    }
  }

  deleteSelectedId = (id: number) => {
    this._contactsMap.delete(id)
  }

  private _handlePowerDialerEditorAction = async (
    status: PowerDialerStatus.Draft | PowerDialerStatus.InProgress
  ) => {
    if (!this._name) {
      this.setNameError(POWER_DIALER_EMPTY_NAME_ERROR)
      return
    }
    if (!this._teamId || !this._numberId) {
      this._numbersInboxesListStore.setError(true)
      return
    }

    const team = inboxesStore.getItem(this._teamId)
    const number = numbersStore.getItem(this._numberId)

    if (!team || !(team instanceof Inbox) || !number || !this._callFrom) return

    eventLogStore.logEvent(
      'Power Dialer Used',
      {
        event_id: 'power_dialer_used',
        action: this._type === 'fromContacts' ? 'created in contacts' : 'created in conversations',
      },
      { groupId: organizationStore.id }
    )

    runInAction(() => {
      status === PowerDialerStatus.Draft ? (this._draftLoading = true) : (this._callLoading = true)
    })
    try {
      const {
        data: { data: powerDialerResponse },
      } = await PowerDialerApi.createPowerDialer({
        name: this._name,
        contacts_id: this._contactsIds,
        status,
        send_from: this._callFrom,
      })

      const powerDialer = new PowerDialer(powerDialerResponse)

      if (status === PowerDialerStatus.InProgress) {
        await callPopUpGlobalStore.openPowerDialer(powerDialer)
      } else {
        showToast(getToastConfigByPowerDialerStatus(status))
      }
      modalStore.closeModal(this._modalId)
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._draftLoading = false
        this._callLoading = false
      })
    }
  }

  handleSaveDraft = () => this._handlePowerDialerEditorAction(PowerDialerStatus.Draft)
  handleCall = () => this._handlePowerDialerEditorAction(PowerDialerStatus.InProgress)

  handleDuplicatePowerDialer = async (powerDialer: PowerDialer) => {
    try {
      const contacts = await this._fetchContactsByIds(powerDialer.contactsIdEnrolled)
      const inboxId = powerDialer.number?.numberable?.id
      const numberId = powerDialer?.number?.id

      this._setType('fromContacts')
      this._setSelectedContacts(contacts)
      this.setName(powerDialer.name)
      if (inboxId && numberId) {
        this._numbersInboxesListStore.setActiveNumber(inboxId, numberId)
      }
      this._openPowerDialerEditorModal()
    } catch (e) {
      logger.error(e)
    }
  }

  handleStartPowerDialerEditorFromContacts = async (contactsData: IContactsDataParams) => {
    const { total = 0, contactsParams, selectedIds, bulkAll } = contactsData
    eventLogStore.logEvent(
      'Power Dialer Used',
      {
        event_id: 'power_dialer_used',
        action: 'clicked in contacts',
      },
      { groupId: organizationStore.id }
    )

    const count = bulkAll ? total : selectedIds.length
    const length = bulkAll ? 100 : selectedIds.length

    if (this._isLimitExceeded(count)) return

    runInAction(() => {
      this._loading = true
    })

    try {
      const contacts = bulkAll
        ? await this._fetchContactsList({ ...contactsParams, length })
        : await this._fetchContactsByIds(selectedIds)

      this._setType('fromContacts')
      this._setSelectedContacts(contacts)
      this._setDefaultInbox()
      this._openPowerDialerEditorModal()
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  private _fetchFilters = () => this._enrollmentStore.filterEditorStore.initFilters()

  handleStartPowerDialerEditorFromFilters = () => {
    eventLogStore.logEvent(
      'Power Dialer Used',
      {
        event_id: 'power_dialer_used',
        action: 'clicked in conversations',
      },
      { groupId: organizationStore.id }
    )

    this._setType('fromFilters')

    const filters = this._enrollmentFilters.clone()

    const cancelFilters = () => Promise.resolve(true)
    const confirmFilters = (newFilters: EnrollmentFilters, total: number, skipped: number) => {
      return this.confirmFiltersEnrollment(total, skipped, newFilters)
    }

    this._fetchFilters()
    this._enrollmentStore.openModal({
      filters: filters,
      action: {
        cancel: cancelFilters,
        confirm: confirmFilters,
      },
      title: this.titleModal.title,
      titleDividerContent: this.titleModal.titleDividerContent,
    })
  }

  confirmFiltersEnrollment = (total: number, skipped: number, newFilters: EnrollmentFilters) => {
    return new Promise<boolean>(async (resolve) => {
      this._enrollmentStore.setLoading(true)
      this._enrollmentFilters = newFilters

      const params = {
        page: 1,
        length: 100,
        search: '',
        sortOrder: 'desc',
        sortBy: ['created_at'],
        filtersListGroups: this._paramsFilters.filtersListGroups,
      }

      if (this._isLimitExceeded(total + skipped)) {
        this._enrollmentStore.setLoading(false)

        return resolve(false)
      }

      try {
        const contacts = await this._fetchContactsList(params)
        runInAction(() => {
          this._step = 2
        })
        this._setSelectedContacts(contacts)
        this._setDefaultInbox()
        this._openPowerDialerEditorModal()
        resolve(true)
      } catch (error) {
        logger.error(error)
        resolve(false)
      } finally {
        this._enrollmentStore.setLoading(false)
      }
    })
  }

  setName = (name: string) => {
    this._name = name
  }

  setNameError = (nameError: string) => {
    this._nameError = nameError
  }

  private get _paramsFilters(): IParamsFiltersListGroups {
    return {
      filtersListGroups: {
        contacts: this._enrollmentFilters.contacts.map((contact) => contact.id) ?? [],
        segments: this._enrollmentFilters.segments?.map((segment) => segment.id) ?? [],
        tags: this._enrollmentFilters.tags?.map((tag) => tag.id),
        advanced: this._enrollmentFilters.filters?.map((filter) => filter.filtersParams) ?? [],
      },
    }
  }

  private get _isNumberNotVerifiedCallerId() {
    const inbox = inboxesStore.getItem(this._teamId)

    return inbox?.type === 'inbox' && inbox?.isNumberNotVerifiedCallerId
  }

  private get _isSmartInboxSelected() {
    return this._numberId === 0 && this._teamId !== 0
  }

  private get _teamId() {
    return this._numbersInboxesListStore.activeInboxId
  }

  private get _numberId() {
    return this._numbersInboxesListStore.activeNumberId
  }

  private get _contactsIds() {
    return this.contacts.map((contact) => contact.id)
  }

  private get _callFrom() {
    return this._numbersInboxesListStore.sendFromParams
  }

  get editorActionButtonDisabled() {
    return Boolean(
      !this.contactsCount || this._isSmartInboxSelected || this._isNumberNotVerifiedCallerId
    )
  }

  get titleModal() {
    if (this._type === 'fromContacts')
      return {
        title: 'Power Dialer',
        titleDividerContent: '',
      }

    return {
      title: 'Power Dialer',
      titleDividerContent: `${this._step} of 2`,
    }
  }

  get showAlert() {
    if (this._type === 'fromFilters') return false

    return this._showAlert
  }

  get contacts() {
    return [...this._contactsMap.values()]
  }

  get contactsCount() {
    return this._contactsMap.size
  }

  get loading() {
    return this._loading
  }

  get draftLoading() {
    return this._draftLoading
  }

  get callLoading() {
    return this._callLoading
  }

  get name() {
    return this._name
  }

  get nameError() {
    return this._nameError
  }

  get numbersInboxesListStore() {
    return this._numbersInboxesListStore
  }

  get editorActionTooltip() {
    const inbox = inboxesStore.getItem(this._teamId)

    const callerIdFailedReason = inbox?.type === 'inbox' && inbox?.callerIdFailedReason

    return callerIdFailedReason || null
  }

  reset = () => {
    this._contactsMap.clear()
    this._showAlert = true
    this._step = 1
    this._name = ''
    this._numbersInboxesListStore.reset()
    this._enrollmentFilters = EnrollmentFilters.Empty()
    this._disposeChangeTitleModal?.()
  }
}
