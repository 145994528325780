import { observer } from 'mobx-react-lite'
import React from 'react'
import { Actions, EmptyState } from 'shared/ui'
import powerDialerPreviewImg from 'shared/assets/images/power_dialer_preview.png'
import { getCDNAssetURL } from 'shared/lib'
import {
  PowerDialerHelpGuideAction,
  PowerDialerNewSessionAction,
} from 'pages/powerDialer/pages/list/ui/PowerDialerActions/PowerDialerActions'

export const PowerDialerListEmpty = observer(() => {
  const actions = [
    {
      iconButtonComponent: <PowerDialerNewSessionAction />,
    },
    {
      iconButtonComponent: <PowerDialerHelpGuideAction />,
    },
  ]

  return (
    <EmptyState
      variant={'preview'}
      previewImg={<img alt='Power Dialer preview' src={getCDNAssetURL(powerDialerPreviewImg)} />}
      title={'Power Dialer'}
      subtitle={'Your fastest route to productivity'}
      actions={<Actions actions={actions} gap={8} />}
    />
  )
})
