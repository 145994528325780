// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mofYS5yxIUKU7yIU8DQH{margin-top:40px}.K3oboyrW9RGjenO7EG2I{margin-top:24px;align-items:flex-start;width:452px}.VDHEfdpV2IkxUgZzxc53{text-decoration:none;color:var(--content-brand-primary);margin:0 4px}.lFAhm8BQigN1XhAQLAVu{margin-right:0}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/pages/calling/ui/EnableStirShaken/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEF,sBACE,eAAA,CACA,sBAAA,CACA,WAAA,CAEF,sBACE,oBAAA,CACA,kCAAA,CACA,YAAA,CAEF,sBACE,cAAA","sourcesContent":[".callingStirShakenImage {\n  margin-top: 40px;\n}\n.declaration {\n  margin-top: 24px;\n  align-items: flex-start;\n  width: 452px;\n}\n.linkTag {\n  text-decoration: none;\n  color: var(--content-brand-primary);\n  margin: 0 4px;\n}\n.secondLinkTag {\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"callingStirShakenImage": "mofYS5yxIUKU7yIU8DQH",
	"declaration": "K3oboyrW9RGjenO7EG2I",
	"linkTag": "VDHEfdpV2IkxUgZzxc53",
	"secondLinkTag": "lFAhm8BQigN1XhAQLAVu"
};
export default ___CSS_LOADER_EXPORT___;
