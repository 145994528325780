import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { billingStore } from 'entities/Billing'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { AddOnModalContentFirstStep } from 'pages/settings/pages/compliance/pages/calling/ui/AddOnModalContentFirstStep/AddOnModalContentFirstStep'
import { AddOnModalContentSecondStep } from 'pages/settings/pages/compliance/pages/calling/ui/AddOnModalContentSecondStep/AddOnModalContentSecondStep'

export class AddOnModalStore {
  private _regStep = 1
  private _modalId = nanoid()

  constructor() {
    makeAutoObservable(this)
  }

  get regStep() {
    return this._regStep
  }

  get isFirstStep() {
    return this.regStep === 1
  }

  setRegStep(step: number) {
    this._regStep = step
  }

  firstStepAction = () => {
    this.closeGetAddOnModal()
    this.setRegStep(2)

    this.openGetAddOnModalSecondStep()
  }

  secondStepAction = async () => {
    this.setRegStep(1)
    this.closeGetAddOnModal()

    await billingStore.updateBillingComplianceInfo()

    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.calling.root}`,
    })
  }

  closeGetAddOnModal = () => {
    this.setRegStep(1)
    modalStore.closeModal(this._modalId)
  }

  returnToFirstStep = () => {
    this.setRegStep(1)

    this.openGetAddOnModal()
  }

  openGetAddOnModalSecondStep = () => {
    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.DEFAULT,
      width: 540,
      title: 'Details',
      closeText: 'Back',
      onClose: this.returnToFirstStep,
      primaryAction: {
        text: 'Turn ON',
        onAction: this.secondStepAction,
      },
      ModalContent: () => <AddOnModalContentSecondStep />,
    })
  }

  openGetAddOnModal = () => {
    modalStore.addModal({
      id: this._modalId,
      type: ModalTypeList.DEFAULT,
      width: 540,
      title: 'Details',
      primaryAction: {
        text: 'Continue',
        onAction: this.firstStepAction,
      },
      ModalContent: () => <AddOnModalContentFirstStep />,
    })
  }
}
