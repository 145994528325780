import { observer } from 'mobx-react-lite'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CleanupRouter } from 'pages/contacts/pages/cleanup'
import { HistoryRouter } from 'pages/contacts/pages/history'
import { ContactsPage } from '../ui/contactsPage'
import { ContactsLayout } from '../layout/ContactsLayout'
import { ContactsPageLayout } from '../layout/ContactsPageLayout'
import { ContactsRoutes } from './type'

export const ContactsRouter = observer(() => {
  return (
    <Routes>
      <Route element={<ContactsLayout />}>
        <Route element={<ContactsPageLayout />}>
          <Route path={'/'} element={<ContactsPage />} />
          <Route path={'/:id'} element={<ContactsPage />} />
          <Route path={`${ContactsRoutes.all}`} element={<ContactsPage />} />
        </Route>
        <Route path={`${ContactsRoutes.history}`} element={<HistoryRouter />} />
        <Route path={`${ContactsRoutes.cleanup}`} element={<CleanupRouter />} />
        {/* ContactRouts.DUPLICATE and ContactRouts.UNLINKED are the old routs for cleanup in 1.0 */}
        <Route
          path={`${ContactsRoutes.duplicate}`}
          element={<Navigate to={`/${ContactsRoutes.root}/${ContactsRoutes.cleanup}`} />}
        />
        <Route
          path={`${ContactsRoutes.unlinked}`}
          element={<Navigate to={`/${ContactsRoutes.root}/${ContactsRoutes.cleanup}`} />}
        />
        <Route
          path={'*'}
          element={<Navigate to={`/${ContactsRoutes.root}/${ContactsRoutes.all}`} />}
        />
      </Route>
    </Routes>
  )
})
