// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PvI9VbFuRYHtg9WsUf7g{padding:20px 20px 4px 20px}.PvI9VbFuRYHtg9WsUf7g .M6dIZ1XijIcudkaXlE0u{line-height:30px}", "",{"version":3,"sources":["webpack://./src/pages/campaigns/ui/detail/CampaignStep/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CACA,4CACE,gBAAA","sourcesContent":[".wrapHeader {\n  padding: 20px 20px 4px 20px;\n  .title {\n    line-height: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapHeader": "PvI9VbFuRYHtg9WsUf7g",
	"title": "M6dIZ1XijIcudkaXlE0u"
};
export default ___CSS_LOADER_EXPORT___;
