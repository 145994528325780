import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DropdownCard, type IDropdownItem, type IIconsVector, Status } from 'shared/ui'
import { type IContactsDataParams } from 'entities/Contacts/api/types'
import { EnrollInCampaignActionStore } from './store/EnrollInCampaignActionStore'
import styles from './styles.module.scss'

type IEnrollInCampaignActionProps = {
  contactsData: IContactsDataParams
  variant: 'dropdownItem' | 'button'
  onClick?: () => void
}

export const EnrollInCampaignAction = observer(
  ({ contactsData, variant, onClick }: IEnrollInCampaignActionProps) => {
    const text = 'Enroll in Campaign'
    const icon: IIconsVector = 'campaigns'

    const { onAction, loading } = useMemo(() => new EnrollInCampaignActionStore(), [])

    const handleClick = async () => {
      await onAction(contactsData)
      onClick?.()
    }

    if (variant === 'dropdownItem') {
      const item: IDropdownItem = {
        id: 'enroll_in_campaign',
        iconL: icon,
        label: text,
        disabled: loading,
        labelContent: (
          <div className={styles.dropdownLabel}>
            {text}
            <Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />
          </div>
        ),
      }
      return <DropdownCard item={item} onChange={handleClick} />
    }
    return (
      <Button
        text={text}
        icon={icon}
        typeBtn='contained'
        contained='tertiary'
        size='small'
        onClick={handleClick}
        loading={loading}
        textRight={<Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />}
      />
    )
  }
)
