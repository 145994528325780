import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import type {
  IResponseContactUnlinkedSource,
  IResponseContactUnlinkedConflictItem,
} from 'entities/Contacts/api/types'
import type { IntegrationKey } from 'entities/Integrations'
import {
  ProviderLinkContact,
  useLinkContactContext,
} from 'pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable/ui/LinkContact/context/linkContactContext'
import { type LinkContactStore } from '../store/linkContactStore'

type ILinkContactContentProps = {
  contact: IResponseContactUnlinkedSource
  matchedContacts: IResponseContactUnlinkedConflictItem[]
  integration: IntegrationKey | null
}

const LinkContactContent: FC<ILinkContactContentProps> = observer(
  ({ contact, matchedContacts, integration }) => {
    const linkContactStore = useLinkContactContext()

    const handleLink = () => {
      linkContactStore.init(contact, matchedContacts, integration)
      linkContactStore.openLinkContactModal()
    }

    return <Button size={'medium'} contained={'tertiary'} text={'Link'} onClick={handleLink} />
  }
)

type ILinkContactProps = {
  store: LinkContactStore
  contact: IResponseContactUnlinkedSource
  matchedContacts: IResponseContactUnlinkedConflictItem[]
  integration: IntegrationKey | null
}

export const LinkContact: FC<ILinkContactProps> = observer(
  ({ contact, matchedContacts, integration, store }) => {
    return (
      <ProviderLinkContact value={store}>
        <LinkContactContent
          contact={contact}
          matchedContacts={matchedContacts}
          integration={integration}
        />
      </ProviderLinkContact>
    )
  }
)
