import { observer } from 'mobx-react-lite'
import { DividerCustom } from 'shared/ui'
import { SwitchAction } from 'shared/ui/SwitchAction'
import { ITrigger } from 'entities/Trigger/model/Trigger'

import type { IResponseTrigger } from 'entities/Trigger/api/type'

type TriggerActionProps = {
  trigger: ITrigger
  toggleTriggerStatus: (trigger: ITrigger) => Promise<IResponseTrigger>
}

export const TriggerSwitchAction = observer(
  ({ trigger, toggleTriggerStatus }: TriggerActionProps) => (
    <>
      <SwitchAction
        size={'xSmall'}
        label={trigger.status === 'active' ? 'Active' : 'Disabled'}
        active={trigger.status === 'active'}
        disabled={trigger.isInvalid}
        onTrigger={() =>
          toggleTriggerStatus(trigger).then((updatedTrigger) => trigger.syncOrigin(updatedTrigger))
        }
      />
      <DividerCustom variant={'dropdown'} />
    </>
  )
)

export const TriggerSwitch = observer(({ trigger, toggleTriggerStatus }: TriggerActionProps) => {
  return (
    <SwitchAction
      size={'middle'}
      active={trigger.status === 'active'}
      disabled={trigger.isInvalid}
      onTrigger={() =>
        toggleTriggerStatus(trigger).then((updatedTrigger) => trigger.syncOrigin(updatedTrigger))
      }
    />
  )
})
