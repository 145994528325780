import { makeAutoObservable, IReactionDisposer, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import { MAX_PASSWORD_LENGTH, TWO_FA_CODE_LENGTH } from 'shared/constants/auth'
import { isEmail } from 'shared/lib'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { authStore, IResponse2FASettings } from 'entities/Auth'
import { ITwoFaMethod, ITwoFaStep } from './type'
import { TwoFaMethodModalContent } from '../ui/LoginForm/TwoFaModal/Method/TwoFaMethodModalContent'
import { TwoFaMethodModalActions } from '../ui/LoginForm/TwoFaModal/Method/TwoFaMethodModalActions'
import { TwoFaCodeModalContent } from '../ui/LoginForm/TwoFaModal/Code/TwoFaCodeModalContent'
import { TwoFaCodeModalActions } from '../ui/LoginForm/TwoFaModal/Code/TwoFaCodeModalActions'

export class LoginStore {
  private _email = ''
  private _password = ''
  private _loading = false
  private _error = ''
  private _alertErrors: string[] = []
  private _twoFaSettings: IResponse2FASettings | null = null
  private _twoFaMethod: ITwoFaMethod | null = null
  private _twoFaStep: ITwoFaStep = 'method'
  private _disposeTwoFaSettings: IReactionDisposer | null = null
  private _twoFaModalId = ''
  private _twoFaCode = ''
  private _twoFaCodeError = ''

  // Email validation
  private _disposeEmailValidation: IReactionDisposer | null = null
  private _disposeEmailValidationTrigger: IReactionDisposer | null = null
  private _emailError: string | null = null
  private _emailValidationTrigger: string | null = null

  // Password validation
  private _disposePasswordValidation: IReactionDisposer | null = null
  private _disposePasswordValidationTrigger: IReactionDisposer | null = null
  private _passwordError: string | null = null
  private _passwordValidationTrigger: string | null = null

  constructor() {
    makeAutoObservable(this)
    this.reactionEmailValidation()
    this.reactionPasswordValidation()
    this.reactionEmailValidationTrigger()
    this.reactionPasswordValidationTrigger()
    this.reactionTwoFaSettings()
  }

  get twoFaMethod() {
    return this._twoFaMethod
  }

  get canResendTwoFaCode() {
    return this.twoFaMethod?.type === 'email' || this.twoFaMethod?.type === 'sms'
  }

  get isValidTwoFaCode() {
    return this._twoFaCode.replace(/[^-.0-9]/g, '').length === TWO_FA_CODE_LENGTH
  }

  get twoFaStep() {
    return this._twoFaStep
  }

  get twoFaCode() {
    return this._twoFaCode
  }

  get twoFaCodeError() {
    return this._twoFaCodeError
  }

  get twoFaSettings(): ITwoFaMethod[] | undefined {
    return this._twoFaSettings
      ?.filter((item) => !item.disabled)
      .map((item) => {
        if (item.type === 'app') {
          return {
            ...item,
            title: 'Authenticator app',
            desc: 'Receive a one-time passcode via an authenticator app',
            icon: 'qr',
          }
        }

        if (item.type === 'email') {
          return {
            ...item,
            title: 'Email',
            desc: 'Receive a one-time passcode via email',
            icon: 'email',
          }
        }

        if (item.type === 'sms') {
          return {
            ...item,
            title: 'SMS',
            desc: 'Receive a one-time passcode via SMS',
            icon: 'testSMS',
          }
        }

        return item
      })
  }

  get isValidEmail() {
    return isEmail(this._email)
  }

  get hasAlertErrors() {
    return !!this.alertErrors.length
  }

  get alertErrors() {
    return this._alertErrors
  }

  get isMaxPassword() {
    return this.password.length === MAX_PASSWORD_LENGTH
  }

  get hasEmail() {
    return !!this.email
  }

  get twoFaLabel() {
    switch (this.twoFaMethod?.type) {
      case 'app': {
        return 'We’ve sent it to the authenticator app'
      }
      case 'email': {
        return `We’ve sent it to ${this.twoFaMethod.label}`
      }
      case 'sms': {
        return `We’ve sent it to ${this.twoFaMethod.label}`
      }
    }
  }

  get hasPassword() {
    return !!this.password
  }

  get email() {
    return this._email
  }

  get password() {
    return this._password
  }

  get loading() {
    return this._loading
  }

  get emailError() {
    return this._emailError
  }

  get passwordError() {
    return this._passwordError
  }

  get error() {
    return this._error
  }

  get twoFaModalId() {
    return this._twoFaModalId
  }

  setTwoFaCode = (value: string) => {
    this.clearTwoFaCodeError()
    this._twoFaCode = value
  }

  sendTwoFaCode = async () => {
    if (!this.twoFaMethod || !this.canResendTwoFaCode) return
    this.clearTwoFaCodeError()
    try {
      const params = {
        type: this.twoFaMethod.type as 'sms' | 'email',
      }

      await authStore.sendTwoFaCode(params)
    } catch (e) {
      console.error(e)
    }
  }

  clearTwoFaCodeError = () => {
    if (!!this.twoFaCodeError) this._twoFaCodeError = ''
  }

  onVerifyCode = async () => {
    if (!this.twoFaMethod || !this.isValidTwoFaCode) return
    this.clearTwoFaCodeError()

    try {
      this.setLoading(true)
      const params = {
        type: this.twoFaMethod.type,
        code: this.twoFaCode,
        value: true,
      }
      await authStore.verifyTwoFaCode(params)
      modalStore.removeModal(this._twoFaModalId)
    } catch (error) {
      if (error instanceof AxiosError) {
        this._twoFaCodeError = error.response?.data?.message || ''
      }
      console.error(error)
    } finally {
      this.setLoading(false)
    }
  }

  removeTwoFaModal() {
    modalStore.removeModal(this.twoFaModalId)
    this._twoFaModalId = ''
  }

  setTwoFaStep = (value: ITwoFaStep) => {
    this._twoFaStep = value
  }

  setTwoFaMethod = (value: ITwoFaMethod | null) => {
    this._twoFaMethod = value
  }

  setTwoFaSettings = (value: IResponse2FASettings | null) => {
    this._twoFaSettings = value
  }

  clearAlertErrors = () => {
    if (this.hasAlertErrors) this._alertErrors = []
  }

  clearTwoFaSettings = () => {
    this.setTwoFaSettings(null)
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  setEmail = (value: string) => {
    this.clearAlertErrors()
    this._email = value
  }

  setPassword = (value: string) => {
    this.clearAlertErrors()
    this._password = value
  }

  triggerEmailValidation = () => {
    this._emailValidationTrigger = nanoid()
  }

  triggerPasswordValidation = () => {
    this._passwordValidationTrigger = nanoid()
  }

  openCodeModal = async (store: LoginStore) => {
    this.setTwoFaStep('code')
    modalStore.removeModal(this.twoFaModalId)
    this._twoFaModalId = nanoid()
    this.sendTwoFaCode()

    modalStore.addModal({
      id: this.twoFaModalId,
      type: ModalTypeList.DEFAULT,
      title: 'Verification required',
      ModalContent: () => <TwoFaCodeModalContent store={store} />,
      ModalActions: () => <TwoFaCodeModalActions store={store} />,
      onClose: () => {
        this.setTwoFaCode('')
        this.clearTwoFaCodeError()
        modalStore.removeModal(this.twoFaModalId)
      },
      width: 360,
    })
  }

  signIn = async (store?: LoginStore) => {
    this.clearAlertErrors()
    this.clearTwoFaSettings()

    if (!this.hasPassword || !this.isValidEmail) {
      this.triggerEmailValidation()
      this.triggerPasswordValidation()
      return
    }

    try {
      this.setLoading(true)

      const data = await authStore.login({
        email: this.email,
        password: this.password,
        remember: false,
      })

      if (data && store) {
        this.setTwoFaSettings(data)
        this.setTwoFaStep('method')
        this._twoFaModalId = nanoid()

        modalStore.addModal({
          id: this.twoFaModalId,
          type: ModalTypeList.DEFAULT,
          title: 'Verification method',
          ModalContent: () => <TwoFaMethodModalContent store={store} />,
          ModalActions: () => <TwoFaMethodModalActions store={store} />,
          width: 540,
        })
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const data = error.response?.data
        if (!data) return

        const errorKeys = Object.keys(data)
        errorKeys.map((errorKey) => {
          const error = data[errorKey]?.[0] || data[errorKey]
          if (typeof error === 'string') {
            this._alertErrors.push(error)
          }
        })
      }
    } finally {
      this.setLoading(false)
    }
  }

  reactionEmailValidation = () => {
    this._disposeEmailValidation?.()
    this._disposeEmailValidation = reaction(
      () => [this._emailValidationTrigger, this.isValidEmail],
      (isValid) => {
        if (isValid) this._emailError = null
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionEmailValidationTrigger = () => {
    this._disposeEmailValidationTrigger?.()
    this._disposeEmailValidationTrigger = reaction(
      () => this._emailValidationTrigger,
      (value) => {
        this._emailError =
          value && !this.isValidEmail
            ? this.hasEmail
              ? 'Please enter a valid email address'
              : 'Please enter email address'
            : null
        if (this.isValidEmail) {
          this._emailValidationTrigger = null
          if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionPasswordValidation = () => {
    this._disposePasswordValidation?.()
    this._disposePasswordValidation = reaction(
      () => this.hasPassword,
      (value) => {
        if (value) this._passwordError = null
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionPasswordValidationTrigger = () => {
    this._disposePasswordValidationTrigger?.()
    this._disposePasswordValidationTrigger = reaction(
      () => this._passwordValidationTrigger,
      (trigger) => {
        this._passwordError = trigger && !this.hasPassword ? 'Please enter password' : null
        if (this.hasPassword) {
          this._passwordValidationTrigger = null
          if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionTwoFaSettings = () => {
    this._disposeTwoFaSettings?.()
    this._disposeTwoFaSettings = reaction(
      () => this.twoFaSettings,
      (value) => {
        if (value && value[0]) this.setTwoFaMethod(value[0])
      },
      {
        fireImmediately: true,
      }
    )
  }
}
