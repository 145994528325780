import { observer } from 'mobx-react-lite'
import { ITextInputProps, TextInput } from 'shared/ui'
import { useNameInputContext } from 'features/createOrEdit/NameInput/context/context'
import styles from './styles.module.scss'

export type INameInputUiProps = {
  placeholder: ITextInputProps['placeholder']
} & Omit<ITextInputProps, 'value' | 'setValue' | 'limit'>

export const NameInputUi = observer((props: INameInputUiProps) => {
  const { name, setName, error, setError, focusOnMount, limit } = useNameInputContext()

  return (
    <TextInput
      size={'large'}
      value={name}
      setValue={setName}
      error={error}
      limit={limit}
      onFocus={() => setError('')}
      className={styles.name}
      isAutoFocus={focusOnMount}
      {...props}
    />
  )
})
