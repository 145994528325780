import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { toastStore } from 'shared/ui'
import { logger, replaceLink } from 'shared/lib'
import { CHAR_MAX_LENGTH } from 'shared/constants/limits'
import { type IParamsCreateCannedMessages, CannedMessagesApi } from 'entities/CannedMessages'
import { ShortenLinkIconAction } from 'entities/ShortLink/ui/actions/ShortenLinkIconAction'
import { CannedMessage } from 'entities/CannedMessages/model/CannedMessage'
import { MessageFieldStore } from 'widgets/MessageField/store/messageFieldStore'
import { MediaAction } from 'widgets/MessageField/ui/FieldActions/MediaAction/MediaAction'
import { EmojiAction } from 'widgets/MessageField/ui/FieldActions/EmojiAction'
import { MergeFieldsIconAction } from 'widgets/MergeField/ui/actions/MergeFieldsIconAction/MergeFieldsIconAction'
import { mergeFieldReplacer } from 'widgets/MessageField/lib'
import { MergeField } from 'widgets/MergeField/types/MergeField'
import { EnumVariantMessageField } from 'widgets/MessageField'
import { type INewSavedReplyStoreConfig } from './types'
import { NewSavedReplyContent } from '../ui/NewSavedReplyContent/NewSavedReplyContent'

export class NewSavedReplyStore {
  private _savedRepliesStore: INewSavedReplyStoreConfig['savedRepliesStore']
  private _modalId = ''
  private _messageFieldStore: MessageFieldStore
  private _title = ''
  private _isPublic = false
  private _is_favorite = false
  private _loading = false
  private _duplicate = false
  private _savedReplay: CannedMessage | null = null

  constructor(config: INewSavedReplyStoreConfig) {
    this._savedRepliesStore = config.savedRepliesStore
    this._messageFieldStore = new MessageFieldStore({
      fromSavedReply: true,
      placeholder: 'Add a response (some details)',
      styles: {
        minHeight: 64,
      },
      showActionsItems: 4,
      variant: EnumVariantMessageField.Underline,
      makeActions: () => [
        {
          iconButtonComponent: <MediaAction />,
        },
        {
          iconButtonComponent: <EmojiAction />,
        },
        {
          iconButtonComponent: (
            <MergeFieldsIconAction
              onAddMergeField={(value) => this.onAddMergeField(value)}
              onClose={() => modalStore.closeAllModals()}
              isAllIntegration
              {...config.mergeFieldProps}
            />
          ),
        },
        {
          iconButtonComponent: (
            <ShortenLinkIconAction
              onAddShortLink={(link: string) =>
                this._messageFieldStore.addContent &&
                this._messageFieldStore.addContent(replaceLink(link) + '&nbsp;')
              }
            />
          ),
        },
      ],
    })

    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  get isPublic() {
    return this._isPublic
  }

  get messageFieldStore() {
    return this._messageFieldStore
  }

  get title() {
    return this._title
  }

  get isEdit() {
    return this._savedReplay && !this._duplicate
  }

  get disabled() {
    return (
      this._title.length > CHAR_MAX_LENGTH ||
      this._loading ||
      this._messageFieldStore.loadingAttachment ||
      this._messageFieldStore.disablesSend ||
      !this._title.trim()
    )
  }

  get createSavedReplyRequest(): IParamsCreateCannedMessages {
    return {
      contact_id: this._savedRepliesStore.contact?.id,
      public: this._isPublic,
      is_favorite: this._is_favorite,
      media_url: this._messageFieldStore.messageRequestData.media_url,
      integration_id: null,
      title: this._title,
      message: this._messageFieldStore.messageRequestData.message,
    }
  }

  reset = () => {
    this._isPublic = false
    this._title = ''
    this._messageFieldStore.reset()
  }

  onAddMergeField = (mergeField: MergeField) => {
    if (this._messageFieldStore.addContent) {
      this._messageFieldStore.addContent(mergeFieldReplacer(mergeField.template) + '&nbsp;')
    }
  }

  handleNewSavedReply = (savedReplay?: CannedMessage | null, duplicate?: boolean) => {
    modalStore.setHideModalId(this._savedRepliesStore.modalId)
    this.reset()
    this._duplicate = duplicate || false
    this._savedReplay = savedReplay || null
    this._modalId = nanoid()

    if (savedReplay) {
      this._title = savedReplay.title + (duplicate ? ' (Copy)' : '')
      this._isPublic = this._savedRepliesStore.disabledVisibility ? false : savedReplay.public
      this._is_favorite = duplicate ? false : savedReplay.is_favorite
      this._messageFieldStore.setMessageData({
        attachments: savedReplay.attachments,
        message: savedReplay.message,
        isReset: false,
        isFocus: true,
        isReplace: true,
      })
    } else {
      this._isPublic = false
      this._is_favorite = false
    }

    const title = `${this.isEdit ? 'Edit' : 'New'} saved reply`

    modalStore.addModal({
      id: this._modalId,
      title,
      ModalContentProps: {
        newSavedReplyStore: this,
        disabledVisibility: this._savedRepliesStore.disabledVisibility,
      },
      ModalContent: NewSavedReplyContent,
      width: 720,
      pureContent: true,
      onClose: this.onCancel,
    })
  }

  setTitle = (title: string) => {
    this._title = title
  }

  setPublic = (isPublic: boolean) => {
    this._isPublic = isPublic
    toastStore.add({
      title: `Visibility changed to ${isPublic ? 'everyone' : 'personal'}`,
      type: 'info',
    })
  }

  onCancel = () => {
    this.reset()
    modalStore.removeModal(this._modalId)
    modalStore.removeHideModalId(this._savedRepliesStore.modalId)
  }

  onCreate = async () => {
    try {
      this._loading = true
      if (this.isEdit && this._savedReplay) {
        const { data } = await CannedMessagesApi.updateCannedMessagesById({
          ...this.createSavedReplyRequest,
          integration_id: this._savedReplay.integration_id,
          id: this._savedReplay.id,
        })
        this._savedRepliesStore.setSavedReplay(data)
        toastStore.add({
          title: 'Reply changes saved',
          type: 'success',
        })
      } else {
        await CannedMessagesApi.createCannedMessages(this.createSavedReplyRequest)
        toastStore.add({
          title: 'Saved reply created',
          type: 'success',
        })
        this._savedRepliesStore.loadData()
      }

      this.onCancel()
    } catch (e) {
      logger.error(e)
    } finally {
      this._loading = false
    }
  }
}
