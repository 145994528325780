import { makeAutoObservable } from 'mobx'
import { type IAvatarInfo } from 'shared/ui'
import type {
  ICampaignStatus,
  IFullResponseCampaign,
  IShortResponseCampaign,
} from 'entities/Campaign/api/types'
import { CampaignStatistics } from 'entities/Campaign/model/CampaignStatistics'
import { getCampaignActions } from 'pages/campaigns/actions'

export class Campaign {
  id: number
  name: string
  status: ICampaignStatus
  statistics: CampaignStatistics | null
  steps: IFullResponseCampaign['steps'] | null = null
  user: IShortResponseCampaign['user'] | null = null
  settings: IFullResponseCampaign['settings'] | null = null

  constructor(
    item: IFullResponseCampaign | IShortResponseCampaign,
    private onSuccessDelete: () => void
  ) {
    this.id = item.id
    this.name = item.name
    this.status = item.status
    if ('steps' in item) {
      this.steps = item.steps
    }
    if ('user' in item) {
      this.user = item.user
    }
    this.statistics = item.analytics ? new CampaignStatistics(item.analytics) : null
    if ('settings' in item) {
      this.settings = item.settings || null
    }
    makeAutoObservable(this)
  }

  syncOrigin = (item: IFullResponseCampaign) => {
    this.id = item.id
    this.name = item.name
    this.status = item.status
    this.steps = item.steps
    this.statistics = item.analytics ? new CampaignStatistics(item.analytics) : null
  }

  get actions() {
    return getCampaignActions(this, this.onSuccessDelete)
  }

  get userAvatarInfo(): IAvatarInfo | null {
    if (this.user) {
      return {
        firstName: this.user.first_name,
        lastName: this.user.last_name,
        number: this.user.number,
        image: this.user.photo_url,
      }
    }
    return null
  }

  get userDisplayInfo() {
    if (this.user) {
      const name = `${this.user.first_name || ''} ${this.user.last_name || ''}`.trim()
      return name || this.user?.number
    }
    return ''
  }

  get hasStatistics() {
    return Number.isFinite(this.statistics?.in_progress)
  }
}
