import { useMemo } from 'react'
import { CampaignView } from 'pages/campaigns/ui/CampaignView'
import { CampaignViewStore } from 'pages/campaigns/ui/CampaignView/store/CampaignViewStore'
import { useCampaignsPageContext } from 'pages/campaigns/context/context'
import { useCampaignsPageIDContext } from 'pages/campaigns/context/context_id'

export const EditCampaign = () => {
  const { pageLayoutStore } = useCampaignsPageContext()

  const response = useCampaignsPageIDContext()
  const store = useMemo(() => new CampaignViewStore(response), [])
  if (!response) return null

  return <CampaignView store={store} pageLayoutStore={pageLayoutStore} />
}
