import { makeAutoObservable } from 'mobx'
import { EnumDropdownItemVariant, IDropdownItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { IResponseTenDlcUseCases } from 'entities/TenDlc/api/type'
import { TenDlcApi } from 'entities/TenDlc'
import { ComplianceApi, complianceStore } from 'entities/Compliance'
import { OptionItem } from 'pages/settings/pages/compliance/ui/OptionItem/OptionItem'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { CallingRoutesSteps } from 'pages/settings/pages/compliance/pages/calling/route/type'
import styles from './styles.module.scss'

export class VoiceIntegrityStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _useCases: IResponseTenDlcUseCases[] = []

  private _useCaseValue: string | null = null
  private _companySize = ''
  private _callsPerDay = ''
  private _notes = ''
  private _loading = false

  get useCases() {
    return this._useCases
  }

  get activeUseCase() {
    return this.useCases.find((useCase) => useCase.value === this.useCaseValue)
  }

  get activeUseCaseTitle() {
    return this.activeUseCase?.title
  }

  get activeUseCaseValue() {
    return this.activeUseCase?.value
  }

  get useCaseValue() {
    return this._useCaseValue
  }

  get useCaseAsItems(): IDropdownItem[] {
    return this.useCases.map((useCase) => ({
      id: useCase.value,
      label: useCase.title || '',
      variant: EnumDropdownItemVariant.Custom,
      className: styles.customDropdown,
      renderOption: () => <OptionItem label={useCase.title} desc={useCase.description} />,
    }))
  }

  get companySize() {
    return this._companySize
  }

  get callsPerDay() {
    return this._callsPerDay
  }

  get notes() {
    return this._notes
  }

  get isSubmitDisabled() {
    return !this.useCaseValue || !this.companySize.length || !this.callsPerDay.length
  }

  get loading() {
    return this._loading
  }

  movetoVoiceIntegritySecondStep = () => {
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.calling.root}/${CallingRoutesSteps.companyInfo}`,
    })
  }

  setUseCase = (useCase: string) => {
    this._useCaseValue = useCase
  }

  loadUseCases = async () => {
    try {
      const { data } = await TenDlcApi.getTenDlcUseCases()

      this._useCases = data
    } catch (e) {
      logger.error(e)
    }
  }

  setCompanySize = (companySize: string) => {
    if (companySize === '' || /^[1-9]\d*$/.test(companySize)) {
      this._companySize = companySize
    }
  }

  setCallsPerDay = (callsPerDay: string) => {
    if (callsPerDay === '' || /^[1-9]\d*$/.test(callsPerDay)) {
      this._callsPerDay = callsPerDay
    }
  }

  setNotes = (notes: string) => {
    this._notes = notes
  }

  setLoading = (loading: boolean) => {
    this._loading = loading
  }

  submitVoiceIntegrity = async () => {
    try {
      this.setLoading(true)

      const { data } = await ComplianceApi.updateVoiceIntegrity({
        average_business_day_call_volume: this.callsPerDay,
        business_employee_count: this.companySize,
        notes: this.notes,
        use_case: this.activeUseCase?.title || '',
      })

      complianceStore.setOrganizationVoiceIntegrity(data)
      if (data) {
        uiStore.changeRoute({
          path: `/${SettingsPath.compliance.calling.root}`,
        })
      }
    } catch (e) {
      logger.error(e)
    } finally {
      this.setLoading(false)
    }
  }
}
