import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { Button, showToast } from 'shared/ui'
import { type IIntegrationLinkDTO } from 'entities/Integrations'
import { type Integration } from 'entities/Integrations/model/Integration'
import { type IResponseContactUnlinkedConflictItem } from 'entities/Contacts/api/types'
import { type LinkContactStore } from 'pages/contacts/pages/cleanup/ui/ContactsUnlinkedTable/ui/LinkContact'
import styles from './styles.module.scss'

type ILinkWithPhonesModalActionsProps = {
  store: LinkContactStore
  parentModalId: string
  modalId: string
  matchedContact: IResponseContactUnlinkedConflictItem
  hubspotIntegration: Integration
}

export const LinkWithPhonesModalActions: FC<ILinkWithPhonesModalActionsProps> = observer(
  ({ modalId, parentModalId, matchedContact, hubspotIntegration, store }) => {
    const { onLink, shouldUpdate, loading, selectedMatchedPhone, targetContact, config } = store

    const handleClosePhonesModal = () => {
      modalStore.removeModal(modalId)
    }

    const handleLinkMatching = async () => {
      if (!targetContact) return

      const data: IIntegrationLinkDTO = {
        integration_id: hubspotIntegration.id,
        integration_key: hubspotIntegration.key,
        integration_name: hubspotIntegration.name,
        contact_id: targetContact.id,
        contact_integration_id: matchedContact.id,
        first_name: matchedContact?.first_name || '',
        last_name: matchedContact?.last_name || '',
        email: matchedContact?.email || '',
        number: selectedMatchedPhone || '',
        update_contact: shouldUpdate,
      }

      const toast = await onLink(data)

      showToast(toast)

      if (toast.type === 'success') {
        handleClosePhonesModal()
        config?.load()
        modalStore.removeModal(parentModalId)
      }
    }

    return (
      <div className={classnames(styles.actionsWrap)}>
        <Button
          contained='secondary'
          text='Cancel'
          onClick={handleClosePhonesModal}
          disabled={loading}
        />
        <Button
          loading={loading}
          contained='primary'
          text='Confirm link'
          disabled={loading}
          onClick={handleLinkMatching}
        />
      </div>
    )
  }
)
