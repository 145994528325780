import { CancelTokenSource } from 'axios'
import { IReactionDisposer, makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import { CallingRoutesSteps } from 'pages/settings/pages/compliance/pages/calling/route/type'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { VoiceIntegrityStore } from './voiceIntegrityStore'
import { StirShakenStore } from './StirShakenStore'
import { CnamCallerIdStore } from './cnamCallerIdStore'

const titleMap: Record<string, string> = {
  [CallingRoutesSteps.enableVoiceIntegrity]: 'Enable Voice Integrity',
  [CallingRoutesSteps.companyInfo]: 'Company info',
  [CallingRoutesSteps.enableStirShaken]: 'Enable STIR / SHAKEN',
  [CallingRoutesSteps.enableCnamCallerId]: 'Enable CNAM Caller ID',
  [CallingRoutesSteps.configure]: 'Configure',
}

const layoutTitleMap: Record<string, string> = {
  [CallingRoutesSteps.enableVoiceIntegrity]: 'Voice Integrity',
  [CallingRoutesSteps.companyInfo]: 'Voice Integrity',
  [CallingRoutesSteps.enableStirShaken]: 'STIR / SHAKEN',
  [CallingRoutesSteps.enableCnamCallerId]: 'CNAM Caller ID',
  [CallingRoutesSteps.configure]: 'CNAM Caller ID',
}

const secondSteps: Record<string, string> = {
  [CallingRoutesSteps.companyInfo]: CallingRoutesSteps.enableVoiceIntegrity,
  [CallingRoutesSteps.configure]: CallingRoutesSteps.enableCnamCallerId,
}

export class CallingStepsStore {
  voiceIntegrityStore = new VoiceIntegrityStore()
  stirShakenStore = new StirShakenStore()
  cnamCallerIdStore = new CnamCallerIdStore()

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    makeAutoObservable(this)
  }

  get complianceLayerStore() {
    return this._complianceLayerStore
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  getProgressInfoTitle = (pathname: CallingRoutesSteps) => {
    return titleMap[pathname] || ''
  }

  getLayoutTitle = (pathname: CallingRoutesSteps) => {
    return layoutTitleMap[pathname] || ''
  }

  closeSetting = () => {
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.calling.root}`,
    })
  }

  getTotalSteps = (pathname: CallingRoutesSteps) => {
    return pathname === CallingRoutesSteps.enableStirShaken ? 1 : 2
  }

  decreaseStep = (pathname: string) => {
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.calling.root}/${secondSteps[pathname] || pathname}`,
    })
  }

  getStep = (pathname: CallingRoutesSteps) => {
    return Object.keys(secondSteps).includes(pathname) ? 2 : 1
  }
}
