import classnames from 'classnames'
import { Avatar, IconButton, Tooltip, Typography, type IColumn } from 'shared/ui'
import { DayjsFormats, getLabelAsNumberInternationalFormat } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { EmptyCell } from 'shared/ui/Table'
import { ContactStatistic, type IParamsGetContactsStatistic } from 'entities/ContactStatistic'
import { KeywordStatus } from 'entities/Keywords/ui'
import type { IOpenContactsDetails } from 'widgets/ContactsDetails'
import styles from './styles.module.scss'

type IFilter = 'sent_to' | 'subscribed' | 'opted_out'
type IColumnsToExclude = Record<IFilter, string[]>

const columnsToInclude: IColumnsToExclude = {
  sent_to: ['full_name', 'number', 'enrolled_at', 'is_subscribed'],
  subscribed: ['full_name', 'number', 'enrolled_at'],
  opted_out: ['full_name', 'number', 'enrolled_at'],
}

export const getColumns = (
  filter: IParamsGetContactsStatistic['filter'],
  onOpenContactsDetails: IOpenContactsDetails
) => {
  const includedFields = columnsToInclude[filter as IFilter] || []

  const nameColumn: IColumn<ContactStatistic> = {
    field: 'full_name',
    name: 'Name',
    renderRowCell: (row, _, config) => {
      if (!row) return null

      return (
        <div className={styles.contactColumn}>
          <div className={styles.contactColumnName}>
            <Avatar info={row.avatarInfo} size={24} />
            <Typography
              variant={'body-md-regular'}
              ellipsis
              tooltipProps={{ label: row.full_name }}
              ariaLabel={'ContactName'}
            >
              {row.full_name || '—'}
            </Typography>
          </div>
          <Tooltip label='Conversation' placement='top'>
            <IconButton
              icon='chatRead'
              variant='icon'
              color='tertiary'
              transparentInactive
              className={config?.hidden}
              ariaLabel='ContactRow_sendMessage'
              disabled={onOpenContactsDetails.disabled}
              onClick={(event) => {
                event.stopPropagation()
                onOpenContactsDetails.open({
                  contactId: row.id,
                })
              }}
            />
          </Tooltip>
        </div>
      )
    },
  }

  const numberColumn: IColumn<ContactStatistic> = {
    field: 'number',
    name: 'Number',
    renderRowCell: (row) =>
      row.number ? (
        <span aria-label='ContactNumber'>{getLabelAsNumberInternationalFormat(row.number)}</span>
      ) : (
        <EmptyCell />
      ),
  }

  const createdAtColumn: IColumn<ContactStatistic> = {
    field: 'enrolled_at',
    name: 'Created',
    renderRowCell: (row) =>
      row.enrolled_at ? (
        <span aria-label='ContactCreatedDate'>
          {uiStore.dayjs(row.enrolled_at).format(DayjsFormats.fullWithAtDash2)}
        </span>
      ) : (
        <EmptyCell />
      ),
  }

  const statusColumn: IColumn<ContactStatistic> = {
    field: 'is_subscribed',
    name: 'Status',
    renderRowCell: (row) => (
      <KeywordStatus
        className={classnames({ [styles.statusDisabled]: row.isDeleted })}
        status={row.is_subscribed ? 'active' : 'unsubscribed'}
      />
    ),
  }

  return [nameColumn, numberColumn, createdAtColumn, statusColumn].filter((column) =>
    includedFields.includes(column.field)
  )
}
