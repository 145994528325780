// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dj2upjSC6zU677fEscYi.dVmc99vt3bL6gUKyd01e{padding:11px 8px 11px 11px;height:40px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/UseCaseSelect/styles.module.scss"],"names":[],"mappings":"AAAA,2CACE,0BAAA,CACA,WAAA","sourcesContent":[".select.useCaseSelect {\n  padding: 11px 8px 11px 11px;\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "Dj2upjSC6zU677fEscYi",
	"useCaseSelect": "dVmc99vt3bL6gUKyd01e"
};
export default ___CSS_LOADER_EXPORT___;
