import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { isEqual } from 'lodash'
import { showToast } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import {
  ICustomPriceCreateForm,
  ICustomPriceForm,
  ICustomPriceUpdateForm,
  IResponseCustomPrice,
} from 'entities/Admin/customPrices/api/type'
import { AdminCustomPriceApi } from 'entities/Admin'
import { AdminCustomPricesForm } from 'pages/admin/pages/customPrices/models/adminCustomPricesForm'
import { AdminRoutes } from 'pages/admin'

class AdminCustomPricesUpdate {
  loading = false
  loadingUpdate = false
  form = new AdminCustomPricesForm()
  id: number | null = null
  customPriceData: IResponseCustomPrice | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setId = (id: number) => {
    this.reset()
    this.id = id
    this.loadData()
  }

  reset = () => {
    this.id = null
    this.loading = false
    this.form = new AdminCustomPricesForm()
  }

  get formData() {
    if (!this.customPriceData) return null

    return {
      name: this.customPriceData.name,
      price: this.customPriceData.price,
      credits: this.customPriceData.interval_credits,
      interval: this.customPriceData.interval,
      contract_term: this.customPriceData.contract_term || this.customPriceData.interval,
      additional_credit_price: Number(this.customPriceData.additional_credit_price),
      seats: this.customPriceData.seats,
      numbers: this.customPriceData.numbers,
      type: this.customPriceData.type,
      percent: this.customPriceData.percent,
    }
  }

  loadData = async () => {
    if (!this.id) {
      return
    }

    const { data } = await AdminCustomPriceApi.getCustomPrice(this.id)

    this.customPriceData = data

    this.form.preFillForm(this.formData as ICustomPriceForm)
  }

  updateCustomPrice = async () => {
    if (!this.id) {
      return
    }

    let form

    if (this.customPriceStatus === 'draft') {
      form = this.form.formToCreate as ICustomPriceCreateForm
    } else {
      form = this.form.formUpdate as ICustomPriceUpdateForm
    }

    try {
      this.loadingUpdate = true
      const { data } = await AdminCustomPriceApi.updateCustomPrice(this.id, form)

      this.customPriceData = data

      this.form.preFillForm(this.formData as ICustomPriceForm)

      showToast({
        type: 'success',
        title: 'Custom plan successfully updated',
      })
    } catch (error) {
      logger.error(error)
      if (error instanceof AxiosError) {
        showToast({
          type: 'error',
          title: error.response?.data.name[0] || 'Something went wrong. Please try again',
        })
      }
    } finally {
      this.loadingUpdate = false
    }
  }

  updateAndPublishCustomPrice = async () => {
    if (!this.id) {
      return
    }

    try {
      this.loadingUpdate = true

      await AdminCustomPriceApi.publishCustomPrice(this.id)

      showToast({
        type: 'success',
        title: 'Custom plan successfully published',
      })

      uiStore.navigate && uiStore.navigate(`admin/${AdminRoutes.customPrices}`)
    } catch (error) {
      logger.error(error)
      showToast({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })
    } finally {
      this.loadingUpdate = false
    }
  }

  get customPriceStatus() {
    return this.customPriceData?.status
  }

  get isSameForm() {
    return isEqual(this.form.formData, this.formData)
  }
}

export const adminCustomPricesUpdate = new AdminCustomPricesUpdate()
