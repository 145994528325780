import { makeAutoObservable } from 'mobx'
import { IFullResponseCampaign } from 'entities/Campaign'
import { CampaignMessageStore } from 'pages/campaigns/ui/CampaignMessage'

export class CampaignMessagesStore {
  messagesMap: Map<string, CampaignMessageStore>
  constructor() {
    const message = this._getNewMessage()
    message.setStep(1)
    this.messagesMap = new Map([[message.uid, message]])
    makeAutoObservable(this)
  }

  get params() {
    return this.messages.map((item) => item.params)
  }

  init = async (steps: IFullResponseCampaign['steps']) => {
    this.messagesMap.clear()
    await Promise.all(
      steps.map(async (step) => {
        const newStep = this._getNewMessage()
        await newStep.init(step)
        this.messagesMap.set(newStep.uid, newStep)
      })
    )
  }

  get messages() {
    return Array.from(this.messagesMap.values())
  }

  private _onDuplicate = (message: CampaignMessageStore) => {
    const newMessage = this._getNewMessage()
    newMessage.init({
      ...message.params,
      id: null,
      name: `Copy of ${message.params.name}`,
    })

    const messages = [...this.messages]
    messages.splice(message.step, 0, newMessage).map((message) => [message.id, message])
    this.messagesMap = new Map(messages.map((message) => [message.uid, message]))
  }
  private _onDelete = (uid: string) => {
    this.messagesMap.delete(uid)
  }

  private _getNewMessage = () => {
    return new CampaignMessageStore(this._onDuplicate, this._onDelete)
  }

  addMessage = () => {
    const newMessage = this._getNewMessage()
    const lastMessage = this.messages[this.messages.length - 1]
    if (lastMessage?.params.send_from) {
      newMessage.campaignSendFromStore.init(lastMessage.params.send_from)
    }

    this.messagesMap.set(newMessage.uid, newMessage)
  }

  reorderDraggableItems = (source: number, target: number) => {
    const items = [...this.messages]
    const [item] = items.splice(source, 1)
    items.splice(target, 0, item)

    this.messagesMap = new Map(items.map((item) => [item.uid, item]))
  }

  checkValidate = () => {
    let validate = true
    this.messages.forEach((message) => {
      if (!message.checkValidate()) {
        validate = false
      }
    })
    return validate
  }

  get limitAddMessage() {
    if (this.messagesMap.size >= 10) {
      return ['A maximum of 10 messages ', <br key={'br'} />, 'per Campaign is allowed']
    }
  }
}
