import { type IDropdownItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { type Segment } from 'entities/Segment/model/Segment'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { type ContactsMenuStore } from 'pages/contacts/ui/ContactsMenu'
import { ContactsPath } from 'pages/contacts/route/contactsPath'

export const useDropdownItems = (
  segment: Segment,
  contactsMenuStore: ContactsMenuStore
): IDropdownItem[] | undefined => {
  const segmentModalStore = contactsMenuStore.segmentModalStore
  const favorite: IDropdownItem[] = featureFlagsStore.hubspot_pin_list
    ? [
        {
          id: 0,
          iconL: segment.isFavorite ? 'starFilled1' : 'starLine',
          label: segment.isFavorite ? 'Unfavorite' : 'Favorite',
          onClick: () => {
            contactsMenuStore.editFavorite({ segment, afterEdit: () => {} })
            contactsMenuStore.pageLayoutStore?.handleToggleCollapseIsMobile()
          },
        },
      ]
    : []

  if (segment.hasHubspotFilter) return favorite

  return [
    ...favorite,
    {
      id: 1,
      iconL: 'filter',
      label: 'Edit filters',
      onClick: () => {
        const { pathName } = uiStore
        if (pathName !== `/${ContactsPath.root}`) {
          uiStore.changeRoute({ path: `/${ContactsPath.root}` })
        }

        contactsMenuStore.initPromise.then(() => {
          contactsMenuStore.onActionFilterOpen(true)
          contactsMenuStore.setActiveSegment(segment)
          contactsMenuStore.onActionFilterSegmentBoxSetHasChanges(false)
          contactsMenuStore.pageLayoutStore.handleToggleCollapseIsMobile()
        })
      },
    },
    {
      id: 2,
      iconL: 'edit',
      label: 'Rename',
      onClick: () => {
        segmentModalStore?.openRenameSegmentModal(segment.name)
        contactsMenuStore.setActiveSegment(segment)
        contactsMenuStore.pageLayoutStore.handleToggleCollapseIsMobile()
      },
    },
    {
      id: 3,
      iconL: 'delete',
      label: 'Delete',
      onClick: () => {
        contactsMenuStore.pageLayoutStore.handleToggleCollapseIsMobile()
        const deleteHandler = () => {
          if (contactsMenuStore.activeSegment?.id === segment.id) {
            uiStore.changeRoute({
              path: ContactsPath.root,
            })
            contactsMenuStore.resetActive()
          }
          contactsMenuStore.onActionFilterOpen(false)
          segmentModalStore.deleteSegment(segment.id)
          segmentModalStore.closeModal()
        }

        segmentModalStore.openDeleteSegmentModal(segment.name, deleteHandler)
      },
    },
  ]
}
