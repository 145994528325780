import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DropdownCard, type IDropdownItem, type IIconsVector, Status } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { type IContactsDataParams } from 'entities/Contacts/api/types'
import { ChatbotEnrollStore } from 'widgets/ChatbotEnroll/store/ChatbotEnrollStore'
import styles from './styles.module.scss'

type IChatbotEnrollButtonProps = {
  contactsData: IContactsDataParams
  variant: 'dropdownItem' | 'button'
  onClick?: () => void
}

export const ChatbotEnrollAction = observer(
  ({ contactsData, onClick, variant }: IChatbotEnrollButtonProps) => {
    const text = 'Enroll in Campaign'
    const icon: IIconsVector = 'campaigns'

    const [loading, setLoading] = useState(false)
    const store = useMemo(() => new ChatbotEnrollStore(featureFlagsStore), [])

    const { selectedIds } = contactsData

    const handleClick = () => {
      setLoading(true)
      store.getValidContactIds(selectedIds).then((validContactIds) => {
        setLoading(false)
        onClick?.()

        if (validContactIds) store.enrollContacts(validContactIds)
      })
    }

    useEffect(() => store.checkActivity(), [])

    if (!store.isOperationEnabled) return null

    if (variant === 'dropdownItem') {
      const item: IDropdownItem = {
        id: 'engage_in_textbot',
        iconL: icon,
        label: text,
        disabled: loading,
        labelContent: (
          <div className={styles.dropdownLabel}>
            {text}
            <Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />
          </div>
        ),
      }
      return <DropdownCard item={item} onChange={handleClick} />
    }

    return (
      <Button
        text='Engage with Textbot'
        icon='chatBot'
        typeBtn='contained'
        contained='tertiary'
        size='small'
        textRight={<Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />}
        onClick={handleClick}
        loading={loading}
      />
    )
  }
)
