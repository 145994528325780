import { useMemo, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { ProviderContactsPage } from 'pages/contacts/context/contactsPageContext'
import { ContactsPageStore } from 'pages/contacts/store/contactsPageStore'
import { useContactsPageLayoutContext } from 'pages/contacts/context/contactsPageLayoutContext'

export const ContactsPageLayout = () => {
  const contactsPageLayoutStore = useContactsPageLayoutContext()
  const contactsPageStore = useMemo(
    () =>
      new ContactsPageStore(
        contactsPageLayoutStore.pageLayoutStore,
        contactsPageLayoutStore.contactsMenuStore,
        contactsPageLayoutStore.segmentModalStore
      ),
    []
  )

  useEffect(() => {
    return () => {
      contactsPageLayoutStore.dispose()
    }
  }, [])

  return (
    <ProviderContactsPage value={contactsPageStore}>
      <Outlet />
    </ProviderContactsPage>
  )
}
