import { FC } from 'react'
import { Icon } from 'shared/ui'
import { useImportContactsContext } from 'pages/contacts/ui/ImportContacts/context/ImportContactsContext'
import styles from './styles.module.scss'

type IImportContactDropdownActions = {
  header: string
  onClose?: () => void
}

export const ImportContactDropdownActions: FC<IImportContactDropdownActions> = ({
  header,
  onClose,
}) => {
  const { createField, addDoNotImport, setNewFieldHeader } = useImportContactsContext()

  return (
    <div className={styles.container}>
      <div
        className={styles.dropdownAction}
        onClick={() => {
          onClose?.()
          createField()
          setNewFieldHeader(header)
        }}
      >
        <Icon icon='add' />
        <span className={styles.dropdownActionText}>New field</span>
      </div>
      <div
        className={styles.dropdownAction}
        onClick={() => {
          onClose && onClose()
          addDoNotImport(header)
        }}
      >
        <Icon icon='close' />
        <span className={styles.dropdownActionText}>Don’t import</span>
      </div>
    </div>
  )
}
