// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CR3EmmJbIXf3uOqL0oBa{margin-bottom:16px}.XkOxH11klJed_DP0NhEv{gap:8px;margin-bottom:16px}.i7myxWUeD5bLONEG4hw7{display:flex;align-items:center;gap:16px;padding-right:4px;margin-bottom:24px}.PW27NK0z_UW8CJst_KqM{height:334px;margin-bottom:32px;margin-right:-22px}.rT5qxctmbp3wvi9w8smg [aria-label=Scrollbar_View]{padding-right:20px}.HXd3AqBJu2MuiZRyLwFa{display:flex;justify-content:end;gap:8px}", "",{"version":3,"sources":["webpack://./src/widgets/PowerDialer/ui/PowerDialerEditor/ui/PowerDialerEditorModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,OAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CAGE,kDACE,kBAAA,CAKN,sBACE,YAAA,CACA,mBAAA,CACA,OAAA","sourcesContent":[".alert {\n  margin-bottom: 16px;\n}\n\n.name {\n  gap: 8px;\n  margin-bottom: 16px;\n}\n\n.section {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  padding-right: 4px;\n  margin-bottom: 24px;\n}\n\n.table {\n  height: 334px;\n  margin-bottom: 32px;\n  margin-right: -22px;\n\n  &__scroll {\n    [aria-label='Scrollbar_View'] {\n      padding-right: 20px;\n    }\n  }\n}\n\n.wrapBtn {\n  display: flex;\n  justify-content: end;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "CR3EmmJbIXf3uOqL0oBa",
	"name": "XkOxH11klJed_DP0NhEv",
	"section": "i7myxWUeD5bLONEG4hw7",
	"table": "PW27NK0z_UW8CJst_KqM",
	"table__scroll": "rT5qxctmbp3wvi9w8smg",
	"wrapBtn": "HXd3AqBJu2MuiZRyLwFa"
};
export default ___CSS_LOADER_EXPORT___;
