import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import type {
  IParamsGetTriggers,
  IParamsTestTrigger,
  IParamsTrigger,
  IResponseGetTriggers,
  IResponseTestTrigger,
  IResponseTrigger,
} from './type'

class Api {
  getTriggers(params: IParamsGetTriggers): Promise<AxiosResponse<IResponseGetTriggers>> {
    return baseApi.post('triggers/all', params)
  }

  createTrigger(params: IParamsTrigger): Promise<AxiosResponse<IResponseTrigger>> {
    return baseApi.post('triggers', params)
  }

  createTest(params: IParamsTestTrigger): Promise<AxiosResponse<IResponseTestTrigger>> {
    return baseApi.post('triggers/test', params)
  }

  deleteBulkTriggers(ids: number[], bulkAll: boolean): Promise<AxiosResponse> {
    return baseApi.delete('triggers', { params: { ids, bulk_all: bulkAll } })
  }
}

export const TriggerApi = new Api()
