import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Icon } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { Warning } from 'shared/ui/Warning'
import { Label } from 'shared/ui/Label'
import { type ICreateWorkflowProps } from 'widgets/CreateWorkflowModal/types'

import styles from './styles.module.scss'

const MAX_NAME_LENGTH = 50

export const WorkflowConfigModal = observer(
  ({ workflow, onCancel, onNext }: ICreateWorkflowProps) => {
    const [nameError, setNameError] = useState('')
    const [focused, setFocus] = useState(false)

    const limitReached = workflow.name.length >= MAX_NAME_LENGTH

    const handleFocus = () => {
      setFocus(true)
      setNameError('')
    }

    const handleBlur = () => {
      setFocus(false)
    }

    const validateName = () => {
      workflow.setName(workflow.name.trim())

      const isValid = !!workflow.name.length

      setNameError(isValid ? '' : 'Workflow name is required')

      return isValid
    }

    const handleChange = (name: string) => {
      const payload = name.substring(0, MAX_NAME_LENGTH)

      workflow.setName(payload)
    }

    const handleSave = () => {
      const isValid = validateName()

      if (isValid) onNext()
    }

    return (
      <div className={styles.container}>
        <section className={styles.name}>
          <Label
            label={{
              text: 'Workflow name',
              rightText: focused ? `${workflow.name.length} of ${MAX_NAME_LENGTH}` : null,
            }}
          />
          <TextField
            InputProps={{
              placeholder: 'e.g. Marketing',
            }}
            variant='stroke'
            size='medium'
            value={workflow.name}
            error={nameError}
            maxLength={MAX_NAME_LENGTH}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
          />
          {!!focused && !!limitReached && (
            <Warning text={`${MAX_NAME_LENGTH}-character limit reached`} />
          )}
          {!!nameError && (
            <div className={styles.error}>
              <Icon
                icon='alertTriangleSmall'
                color='var(--action-contained-destructive-primary-normal)'
              />
              <span className={styles.errorText}>{nameError}</span>
            </div>
          )}
        </section>
        <footer className={styles.footer}>
          <Button
            className={styles.helpButton}
            text='Back'
            size='medium'
            contained='secondary'
            onClick={onCancel}
          />
          <Button text='Save' size='medium' disabled={!workflow.name.length} onClick={handleSave} />
        </footer>
      </div>
    )
  }
)
