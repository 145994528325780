import React from 'react'
import { Banner, Modal, Toast } from 'shared/ui'
import { APP_TYPE } from 'shared/config'
import { GlobalEvents } from 'entities/WebSocket'
import { PresentationMode } from 'widgets/PresentationMode/ui/PresentationMode'
import { CallPopUp } from 'widgets/CallPopUp'
import { GlobalBanners } from 'widgets/GlobalBanners/GlobalBanners'
import { RetentlyLayout } from './RetentlyLayout'

export const CommonComponents = () => {
  const isModal = APP_TYPE === 'react' || APP_TYPE === 'light'
  const isCallPopUp = APP_TYPE === 'react' || APP_TYPE === 'light'
  return (
    <>
      <Banner globalBanners={<GlobalBanners />} />
      <GlobalEvents />
      <Toast />
      <PresentationMode />
      <RetentlyLayout />
      {isModal && <Modal />}
      {isCallPopUp && <CallPopUp />}
    </>
  )
}
