import { makeAutoObservable, runInAction } from 'mobx'
import { RequestLoadingStatus } from 'shared/store/RequestLoadingStatus'
import {
  Integration,
  ISalesForceSettingsDict,
  type ISettingRequestBySettingDict,
} from 'entities/Integrations'
import { IntegrationSalesforceSettingsInstallationStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/store/IntegrationSalesforceSettingsInstallationStore'
import { SALESFORCE_SYNC_CHECKBOX_KEYS } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/lib'
import type { IntegrationCustomSettingsStore } from 'pages/settings/pages/integrations/pages/integrationSettings/store/IntegrationCustomSettingsStore'
import {
  IntegrationSyncType,
  GET_SYNC_TYPE_DICT,
  GET_SYNC_TYPE_OPTIONS,
} from 'pages/settings/pages/integrations/pages/integrationSettings/lib'

export class IntegrationSalesforceSettingsSyncStore {
  private _status = new RequestLoadingStatus()
  syncType: IntegrationSyncType = IntegrationSyncType.oneWay

  constructor(
    private _integration: Integration,
    private _customSetting: IntegrationCustomSettingsStore<ISalesForceSettingsDict>,
    private _installationStore: IntegrationSalesforceSettingsInstallationStore
  ) {
    makeAutoObservable(this)
  }

  init() {
    const timeout = setInterval(() => {
      if (this._customSetting.status.isSuccess) {
        clearInterval(timeout)

        if (
          SALESFORCE_SYNC_CHECKBOX_KEYS.some((key) => Boolean(this._customSetting.getValue(key)))
        ) {
          runInAction(() => {
            this.syncType = IntegrationSyncType.twoWay
          })
        }
      }
    }, 200)
  }

  updateSyncData = (data: ISettingRequestBySettingDict<ISalesForceSettingsDict>) => {
    this._customSetting.patchData(data)
  }

  setSyncType = (type: IntegrationSyncType) => {
    this.syncType = type

    if (
      type === IntegrationSyncType.oneWay &&
      SALESFORCE_SYNC_CHECKBOX_KEYS.some((key) => Boolean(this._customSetting.getValue(key)))
    ) {
      this.updateSyncData([
        {
          key: 'update_contacts',
          value: 0,
        },
        {
          key: 'create_non_existing_contacts',
          value: 0,
        },
      ])
    }
  }

  setCheckbox = (key: keyof ISalesForceSettingsDict) => (value: boolean) => {
    const data = [
      { key, value: value ? 1 : 0 },
    ] as ISettingRequestBySettingDict<ISalesForceSettingsDict>
    this.updateSyncData(data)
  }

  getCheckbox = (type: keyof ISalesForceSettingsDict) => {
    return Boolean(this._customSetting.getValue(type))
  }

  getValue = (type: keyof ISalesForceSettingsDict) => {
    return this._customSetting.getValue(type)
  }

  setValue =
    (key: keyof ISalesForceSettingsDict) =>
    (value: ISalesForceSettingsDict[keyof ISalesForceSettingsDict]) => {
      const data = [{ key, value }] as ISettingRequestBySettingDict<ISalesForceSettingsDict>
      this.updateSyncData(data)
    }

  getSelectItems = (type: keyof ISalesForceSettingsDict) => {
    return (
      this._customSetting.getOptions(type)?.map((item) => ({
        label: item.label as string,
        id: item.value as string,
        value: item.value as string,
      })) || []
    )
  }

  get isTwoWaySync() {
    return this.syncType === IntegrationSyncType.twoWay
  }

  get getLabel() {
    return GET_SYNC_TYPE_DICT(this._integration.name)[this.syncType]
  }

  get selectItems() {
    return GET_SYNC_TYPE_OPTIONS(this._integration.name)
  }

  get status() {
    return this._status.status
  }

  get showOwnerAssigmentCheckbox() {
    return this._installationStore.isLimitedIntegration
  }
}
